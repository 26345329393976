import {
  ComponentFactoryResolver,
  ViewContainerRef,
  Injectable
} from '@angular/core';
import { ModalAttributes } from '@orlo/library/interfaces/modal-attributes';
import { ModalComponent } from '../../components/modal/modal.component';

@Injectable()
export class ModalFactoryService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private rootViewContainerRef: ViewContainerRef
  ) {}

  public create(
    attributes: ModalAttributes,
    suppliedParentContainer?: ViewContainerRef
  ): ModalComponent {
    return null;
    let parentContainer: ViewContainerRef;

    if (!parentContainer) {
      parentContainer = this.rootViewContainerRef;

      throw new Error(`Invalid container for modal`);
    } else {
      parentContainer = suppliedParentContainer;
    }

    const modal = this.componentFactoryResolver.resolveComponentFactory(
      ModalComponent
    );
    const modalReference = parentContainer.createComponent(modal);
    const modalInstance = modalReference.instance;

    Object.assign(modalReference.instance, attributes);
    modalInstance.componentReference = modalReference;

    return modalInstance;
  }
}
