import { Component, OnInit, Inject } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { API, Auth } from '@ui-resources-angular';
import { decodeStateParams } from '@orlo/common/utils';
import { SocketsService } from '@orlo/common/services/sockets/sockets.service';
import { DeviceService } from '@orlo/common/services/device/device.service';
import { AuthService } from '@orlo/common/services/auth/auth.service';

@Component({
  selector: 'ssi-saml',
  template: ``
})
export class SamlComponent implements OnInit {
  decodedStateParams: { [key: string]: any };

  constructor(
    private state: StateService,
    private api: API,
    private socket: SocketsService,
    private device: DeviceService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    this.api
      .post('user/samlToken', { jwt: this.decodedStateParams.jwt })
      .then(({ data: { permanent_token: token } }) => {
        this.authService.setToken({ access_token: token });
        this.socket.instantiate();
        return this.state.go(
          this.device.isMobile() ? 'auth.inbox' : 'auth.dashboard'
        );
      })
      .catch(() => {
        this.state.go('entry.landing.login');
      });
  }
}
