import { ViewEncapsulation } from '@angular/core';

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IftttService,
  IftttServiceModel
} from '../ifttt-utils/ifttt-service-model/ifttt-service-model';
import {
  StateService,
  TransitionService,
  StateDeclaration
} from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoModalComponent } from '../../../../common/components/video-modal/video-modal.component';

export async function servicesResolveFn(
  iftttServiceModel: IftttServiceModel
): Promise<IftttService[]> {
  try {
    return await iftttServiceModel.findAllActive();
  } catch (e) {
    console.log(e);
  }
}

interface VideoTutorial {
  url: string;
  label: string;
}

@Component({
  selector: 'ssi-manage-ifttt-applet',
  templateUrl: './manage-ifttt-applet.component.html',
  styleUrls: ['./manage-ifttt-applet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageIftttAppletComponent implements OnInit, OnDestroy {
  static resolve = [
    {
      token: 'services',
      resolveFn: servicesResolveFn,
      deps: [IftttServiceModel]
    }
  ];

  containerCssClass: string;

  deregisterTransitionListener: any = () => {};

  constructor(
    private state: StateService,
    private transition: TransitionService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    // this is shit, need a better way of doing it

    function getStateContainerClass(state: StateDeclaration): string {
      if (state.data && state.data.containerCssClass) {
        return state.data.containerCssClass;
      } else {
        return '';
      }
    }

    this.containerCssClass = getStateContainerClass(this.state.current);

    this.deregisterTransitionListener = this.transition.onSuccess(
      { to: 'auth.manageIftttApplet.**' },
      (transition) => {
        this.containerCssClass = getStateContainerClass(transition.to());
      }
    );
  }

  ngOnDestroy() {
    this.deregisterTransitionListener();
  }

  showVideo(video: VideoTutorial) {
    const modal = this.modal.open(VideoModalComponent, {
      windowClass: 'modal-vertical modal-border-none modal-background-none',
      size: 'lg'
    });
    modal.componentInstance.setVideoUrl(video.url);
  }
}
