import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '../../../../../../common/components/spinner/spinner.component';
import {
  Account,
  AccountModel,
  ProfileModel,
  ProfileSearchResult
} from '@ui-resources-angular';
import { API } from '@ui-resources-angular';
import { AccountTypeId } from '../../../../../../common/enums';
import {
  ListeningPage,
  ListeningStreamService
} from '../../../../../../common/services/api/listening-stream';
import { StreamBuilderService } from '../../../stream-builder/stream-builder.service';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '../../../../../../common/services/api';

@Component({
  selector: 'ssi-create-edit-hashtag-modal',
  templateUrl: './create-edit-hashtag-modal.component.html',
  styleUrls: ['./create-edit-hashtag-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateEditHashtagModalComponent implements OnInit {
  @Input() actionButtonText: string = 'Save';
  @Input() instagramHashtagId?: string;
  @Input() hashtagName?: string;
  @Input() accountId?: string;

  searching = false;
  accountTypeId: number = AccountTypeId.Facebook;
  instagramHashtag = '';
  showAccountsDropdown = false;

  @Output() onSave = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private listeningStreamService: ListeningStreamService,
    private profileModel: ProfileModel,
    private accountModel: AccountModel,
    private api: ApiService,
    private streamBuilderService: StreamBuilderService
  ) {}

  async ngOnInit() {
    // this.prepopulateForm();
  }

  async deleteHashtag() {
    try {
      const res = await this.api
        .delete(`${this.api.url}/monitoring/instagramHashtag`, {
          params: {
            instagram_id: this.instagramHashtagId,
            name: this.hashtagName,
            account_id: this.accountId
          }
        })
        .toPromise();
      this.onSave.emit(this.instagramHashtagId);
    } catch (e) {
      console.error('Error deleting Instagram Hashtag', e);
    }
  }

  async addHashtag() {
    try {
      const res = await this.api
        .post(`${this.api.url}/monitoring/instagramHashtag`, {
          query: this.instagramHashtag
        })
        .toPromise();
      this.onSave.emit(this.instagramHashtag);
    } catch (e) {
      console.error('Error adding Instagram Hashtag', e);
    }
  }
}
