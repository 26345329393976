import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { LiveChatMessageTagsComponent } from './live-chat-message-tags.component';

@NgModule({
  declarations: [LiveChatMessageTagsComponent],

  exports: [LiveChatMessageTagsComponent],

  imports: [ClickOutsideModule, CommonModule, TranslateModule]
})
export class LiveChatMessageTagsModule {}
