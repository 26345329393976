import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BigNumberComponent } from './big-number.component';
import { BigNumberPipe } from '@orlo/common/pipes/big-number/big-number.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [BigNumberComponent],
  providers: [DecimalPipe, BigNumberPipe],
  exports: [BigNumberComponent]
})
export class BigNumberModule {}
