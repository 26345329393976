import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { API } from '@ui-resources-angular';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../../../common/services/api';
import { NotesModelService, Note } from '../notes-model/notes-model.service';
import _ from 'lodash';
import {
  mentionRegex,
  sanitizeWithAllowedTags
} from '../../../../../../../common/components/notes-textarea/notes-textarea.component';

@Component({
  selector: 'ssi-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotesComponent implements OnInit {
  tagList: any[];
  teamTagList: any[];
  content: string;
  subject: string;
  colleagueNames: string[];
  tags: any = 'content';

  @Input() mentionlabelKey = 'mentionName';
  @Input() validation: any;
  @Input() notes: Note[];
  @Input() user: Colleague;
  @Input() colleagues: Colleague[];
  @Input() teams: Team[];
  @Input() disablePosting: boolean;
  @Input() theme: string;
  @Output() updateNotes: EventEmitter<any> = new EventEmitter();

  constructor(public api: API, public notesModelService: NotesModelService) {}

  ngOnInit() {
    this.subject = this.validation.outbox ? 'advert' : 'outbox';
    this.tagList = [...this.teams, ...this.colleagues];
    console.log('this.tagList in notes:', this.tagList);
  }

  public contentChange(value: string) {
    const matchedMentions = value.match(mentionRegex);
    let content = (' ' + value).slice(1);

    if (matchedMentions) {
      matchedMentions.map((tag) => {
        const mentionMatch = this.tagList.find(
          (tagItem) => tagItem['mentionName'] === tag.substring(1)
        );
        if (mentionMatch) {
          content = content.replace(
            tag,
            `<a ${mentionMatch.mentionAttribute}="${
              (mentionMatch as any).id
            }">@${mentionMatch['mentionName']}</a>`
          );
        }
      });
    }

    content = sanitizeWithAllowedTags(content);
    this.tags = content;
  }

  public authoredBy(note) {
    const author = _.find(
      this.colleagues,
      (colleague) => note.created_by === colleague.id
    );
    return author.id === this.user.id
      ? 'You'
      : `${author.first_name} ${author.last_name}`;
  }

  public async deleteNote(note) {
    const { data } = await this.notesModelService.deleteNote(
      note.id,
      this.validation.id,
      this.subject
    );
    if (data.success) {
      this.updateNotes.emit(note);
    }
  }

  public async saveNote() {
    const { data } = await this.notesModelService.saveNote(
      this.tags,
      this.validation.id,
      this.subject
    );
    this.content = '';
    this.tags = '';
    this.updateNotes.emit(data);
  }
}
