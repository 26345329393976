import { Component, OnInit, Input } from '@angular/core';
import {
  AccountModel,
  Account,
  NotificationPreferences,
  UserModel
} from '@ui-resources-angular';
import strings from '@orlo/library/constants/strings';

export async function accountsResolveFn(accountModel: AccountModel) {
  return await accountModel.findAccounts();
}

@Component({
  selector: 'ssi-notifications-settings',
  templateUrl: './notification-settings.component.html',
  styles: []
})
export class NotificationSettingsComponent implements OnInit {
  static resolve = [
    {
      token: 'accounts',
      resolveFn: accountsResolveFn,
      deps: [AccountModel]
    }
  ];

  @Input() accounts: Account[];

  public notifications;
  public configuredAccounts = [];
  public nonConfiguredAccounts = [];
  public selectedAccount: string = '';
  public searchText: string = null;
  public displayedConfiguredAccounts = [];

  constructor(public notificationPreferences: NotificationPreferences) {}

  async ngOnInit() {
    try {
      this.notifications = await this.notificationPreferences.fetch(
        this.accounts
      );
      this.configuredAccounts = this._getConfiguredAccounts();
      this.nonConfiguredAccounts = this._getNonConfiguredAccounts();
    } catch (error) {
      console.error('Error getting the notification preferences:', error);
    }
  }

  private _getConfiguredAccounts() {
    const configuredAccounts = this.notifications.accounts.configured.filter(
      (account) =>
        this._filteredAccountTypes().indexOf(
          account._account_type_name.toLowerCase()
        ) === -1
    );
    this.displayedConfiguredAccounts = configuredAccounts;
    return configuredAccounts;
  }

  private _getNonConfiguredAccounts() {
    return this.notifications.accounts.unconfigured.filter(
      (account) =>
        this._filteredAccountTypes().indexOf(
          account._account_type_name.toLowerCase()
        ) === -1
    );
  }

  private _filteredAccountTypes() {
    return [`${strings.live} ${strings.chat}`];
  }

  public filterConfiguredAccounts() {
    if (!this.searchText) {
      this.displayedConfiguredAccounts = this.configuredAccounts;
      return;
    }
    this.displayedConfiguredAccounts = this.configuredAccounts.filter(
      (account) => {
        const accountName = account.name.toUpperCase();
        const searchText = this.searchText.toUpperCase();
        return accountName.includes(searchText);
      }
    );
  }

  public async updateSubscriptions() {
    this.notifications = await this.notificationPreferences.fetch(
      this.accounts
    );
    this.configuredAccounts = this._getConfiguredAccounts();
  }
}
