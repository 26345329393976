import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash-es';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';

import {
  API,
  SocialNetwork,
  UserModel,
  socialNetworkSettings
} from '@ui-resources-angular';
import {
  IMAGE_SOURCES,
  MAX_FILE_SIZE_DEFAULT,
  ALL_FILE_SOURCES,
  FilestackService,
  FilestackPickOptions,
  FilestackSources,
  FilestackStorageLocations,
  FilestackClient,
  FilestackFile
} from '@orlo/common/services/filestack/filestack.service';
import { EditFileModalComponent } from './edit-file-modal/edit-file-modal.component';
import { ColleaguesService } from '@orlo/common/services/api';
import { PopupService } from '@orlo/common/services/popup/popup.service';
import { transformImage } from '@orlo/common/util';

const FILESTACK_CONTENT_LIBRARY_API_KEY = 'AgE0ZzitGQIeCBpcxo06cz';

@Component({
  selector: 'ssi-content-library',
  templateUrl: './content-library.component.html',
  styleUrls: ['./content-library.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentLibraryComponent implements OnInit {
  loadingTracker: AsyncTracker;
  activeFolderTree = [];
  transformImageParams: any = {
    resize: {
      width: 300,
      height: 300,
      fit: 'max'
    },
    output: {
      compress: true,
      quality: 80
    }
  };

  activeFolderContents;
  selectedFileCount: number;
  transformImage = transformImage;
  newFolderName = '';

  constructor(
    protected translate: TranslateService,
    protected modal: NgbModal,
    protected promiseTracker: AsyncTrackerFactory,
    protected popup: PopupService,
    protected api: API,
    protected filestack: FilestackService,
    protected userModel: UserModel,
    protected colleaguesService: ColleaguesService
  ) {
    this.loadingTracker = promiseTracker.create();
  }

  ngOnInit() {
    this.loadActiveFolderContents();
  }

  getActiveFolderPath() {
    return this.activeFolderTree.join('/');
  }

  loadActiveFolderContents() {
    const promise = this.api
      .get('company/file', { params: { path: this.getActiveFolderPath() } })
      .then(({ data }) => {
        this.activeFolderContents = data;
        this.activeFolderContents.folders = orderBy(
          this.activeFolderContents.folders,
          [(f) => f.name.toLowerCase()]
        );
        this.activeFolderContents.files = orderBy(
          this.activeFolderContents.files,
          [(f) => f.name.toLowerCase()]
        );
        this.selectedFileCount = 0;
      });
    this.loadingTracker.add(promise);
  }

  loadFolder(folder) {
    this.activeFolderTree.push(folder.name);
    this.loadActiveFolderContents();
  }

  breadCrumbClicked(index) {
    this.activeFolderTree = this.activeFolderTree.slice(0, index);
    this.loadActiveFolderContents();
  }

  deleteItemInActiveFolder(name, reloadFolderContents = true) {
    const path = `${this.getActiveFolderPath()}/${name}`;
    const promise = this.api
      .del('company/file', { params: { path } })
      .then(() => {
        if (reloadFolderContents) {
          return this.loadActiveFolderContents();
        }
      });
    this.loadingTracker.add(promise);
  }

  createFolderInActiveFolder(name) {
    const promise = this.api
      .post('company/file', {
        folder: { path: this.getActiveFolderPath(), name }
      })
      .then(() => {
        return this.loadActiveFolderContents();
      });
    this.loadingTracker.add(promise);
  }

  createFileInActiveFolder(uploadFileType: 'image' | 'video') {
    const filestackMediaOptions: {
      image: FilestackPickOptions;
      video: FilestackPickOptions;
    } = {
      image: {
        fromSources: IMAGE_SOURCES,
        accept: [],
        maxSize: MAX_FILE_SIZE_DEFAULT
      },
      video: {
        fromSources: ALL_FILE_SOURCES,
        accept: [],
        maxSize: 0
      }
    };

    const mediaTypes = Object.keys(filestackMediaOptions);
    Object.values(socialNetworkSettings).forEach((network: SocialNetwork) => {
      mediaTypes.forEach((mediaType) => {
        if (network.mediaRestrictions.post[mediaType].mimetypes) {
          network.mediaRestrictions.post[mediaType].mimetypes.forEach(
            (mimetype) => {
              if (!filestackMediaOptions[mediaType].accept.includes(mimetype)) {
                filestackMediaOptions[mediaType].accept.push(mimetype);
              }
            }
          );
        }
        if (network.mediaRestrictions.post[mediaType].size) {
          filestackMediaOptions[mediaType].maxSize = Math.max(
            filestackMediaOptions[mediaType].maxSize,
            network.mediaRestrictions.post[mediaType].size.max || 0
          );
        }
      });
    });

    const filestackOptionsBase: FilestackPickOptions = Object.assign(
      {},
      filestackMediaOptions[uploadFileType]
    );
    filestackOptionsBase.fromSources = filestackOptionsBase.fromSources.filter(
      (source) => source !== FilestackSources.CustomSource
    );

    const promise = Promise.all([
      this.api.get('company/fileUploadToken'),
      this.userModel.getAuthUser()
    ])
      .then(([token, user]) => {
        const signature = token.data.signature;
        const policy = token.data.policy;
        const files: Array<FilestackFile> = [];
        const basePath = `company-files/${user.company_id}/`;
        const path =
          `${basePath}${this.getActiveFolderPath()}` +
          (this.getActiveFolderPath() ? '/' : '');

        const filestackOptions: FilestackPickOptions = Object.assign(
          filestackOptionsBase,
          {
            disableStorageKey: true,
            maxFiles: 10000,
            lang: this.translate.currentLang,
            customSourcePath: basePath,
            onFileUploadFinished: (file: FilestackFile) => {
              files.push(file);
            },
            onClose: async () => {
              await this.api.post('company/file', {
                files: files.map((file) => ({
                  key: file.key.replace(basePath, '')
                }))
              });
              this.loadActiveFolderContents();
            },
            storeTo: {
              location: FilestackStorageLocations.S3,
              path
            },
            uploadConfig: {
              timeout: 1200000
            }
          }
        );

        return this.filestack
          .getClient(FILESTACK_CONTENT_LIBRARY_API_KEY, { signature, policy })
          .then((client: FilestackClient) => {
            return client.picker(filestackOptions).open();
          });
      })
      .catch((err) => {
        console.error('Content library initiation error:', err);
        this.popup.alert({
          message: this.translate.instant(
            'SORRY_BUT_SOMETHING_WENT_WRONG_WHEN_SAVING_THE_FILE_PLEASE_TRY_AGAIN_LATER'
          ),
          isError: true
        });
      });

    this.loadingTracker.add(promise);
  }

  updateFile(changes, reloadFolderContents = true) {
    const promise = this.api
      .post('company/file', changes, { params: { _method: 'PUT' } })
      .then(() => {
        if (reloadFolderContents) {
          return this.loadActiveFolderContents();
        }
      });
    this.loadingTracker.add(promise);
    return promise;
  }

  renameFile(file, newName) {
    const basePath = this.getActiveFolderPath();
    return this.updateFile({
      operation: 'rename',
      source: `${basePath}/${file.name}`,
      dest: {
        path: basePath,
        name: newName
      }
    });
  }

  moveSelectedFilesToChildFolder(folder) {
    const basePath = this.getActiveFolderPath();
    const promises = this.activeFolderContents.files
      .filter((iFile) => iFile.isSelected)
      .map((file) => {
        return this.updateFile(
          {
            operation: 'rename',
            source: `${basePath}/${file.name}`,
            dest: {
              path: `${basePath}/${folder.name}`,
              name: file.name
            }
          },
          false
        );
      });

    Promise.all(promises).then(() => {
      this.loadActiveFolderContents();
    });
  }

  moveSelectedFilesToParentFolder(folderIndex) {
    const basePath = this.getActiveFolderPath();
    const newBasePath = this.activeFolderTree.slice(0, folderIndex).join('/');
    const promises = this.activeFolderContents.files
      .filter((iFile) => iFile.isSelected)
      .map((file) => {
        return this.updateFile(
          {
            operation: 'rename',
            source: `${basePath}/${file.name}`,
            dest: {
              path: newBasePath,
              name: file.name
            }
          },
          false
        );
      });

    Promise.all(promises).then(() => {
      this.loadActiveFolderContents();
    });
  }

  editFile(file) {
    let extension = '';
    let name = file.name;
    const type = file.mimetype;

    if (file.name.includes('.')) {
      const parts = file.name.split('.');
      extension = parts.pop();
      name = parts.join('.');
    }

    const modal = this.modal.open(EditFileModalComponent, {
      windowClass: 'orlo-modal'
    });

    const resolve = {
      file: {
        name,
        type,
        metadata: Object.assign({}, file.metadata || {})
      }
    };
    if (!resolve.file.metadata.outbox_post) {
      resolve.file.metadata.outbox_post = { note: '', text: '' };
    }

    modal.componentInstance.resolve = resolve;

    modal.result.then((updatedFile) => {
      if (!updatedFile) {
        return;
      }

      const updatedFilename = extension
        ? `${updatedFile.name}.${extension}`
        : updatedFile.name;
      let renamed;
      if (updatedFilename === file.name) {
        renamed = Promise.resolve();
      } else {
        renamed = this.renameFile(file, updatedFilename);
      }
      renamed.then(() => {
        return this.updateFile({
          operation: 'metadata',
          file: `${this.getActiveFolderPath()}/${updatedFilename}`,
          metadata: updatedFile.metadata
        });
      });
    });
  }

  toggleFile(file) {
    if (!file.isSelected) {
      file.isSelected = true;
      this.selectedFileCount++;
    } else {
      file.isSelected = false;
      this.selectedFileCount--;
    }
  }

  deleteSelectedFiles() {
    const promises = this.activeFolderContents.files
      .filter((file) => file.isSelected)
      .map((file) => this.deleteItemInActiveFolder(file.name, false));

    Promise.all(promises).then(() => {
      this.loadActiveFolderContents();
    });
  }

  getName(userId: string): string {
    const colleague = this.colleaguesService.store.find(userId);
    return colleague && colleague.fullName;
  }
}
