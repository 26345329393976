import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { StateDeclaration, StateService } from '@uirouter/angular';

import { DeviceService } from '../device/device.service';

@Injectable()
export class RedirectService {
  constructor(
    private state: StateService,
    private location: Location,
    private device: DeviceService
  ) {}

  redirectToLogin(returnToState?: StateDeclaration): Promise<any> {
    if (
      !this.state.current.name ||
      this.state.current.name.startsWith('auth.') ||
      this.state.current.name === 'external'
    ) {
      return this.state.go('entry.landing.login', {
        returnTo: returnToState ? returnToState.name : undefined
      });
    } else {
      return Promise.resolve();
    }
  }

  handleSuccessfulLogin(returnToUrl?: string) {
    if (returnToUrl) {
      this.state.go(returnToUrl); // Redirect back to the original URL
    } else {
      // Redirect to a default state if no returnTo URL was stored
      return this.goToHomePage();
    }
  }

  goToHomePage(): Promise<any> {
    return this.state.go(
      this.device.isMobile() ? 'auth.inbox' : 'auth.dashboard'
    );
  }
}
