import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetInfluencersListComponent } from './widget-influencers-list.component';
import { InfluencersListModule } from '@orlo/common/components/influencers-list/influencers-list.module';

@NgModule({
  imports: [CommonModule, InfluencersListModule],
  declarations: [WidgetInfluencersListComponent],
  exports: [WidgetInfluencersListComponent]
})
export class WidgetInfluencersListModule {}
