<div class="text-light spacer-bottom row">
  <div class="col-xs-6">
    <!-- <a
      target="_blank"
      [href]="post.quoteRetweetUrl"
      class="btn btn-inverse btn-rounded btn-sm pull-left">
      <em>View tweet</em>
    </a> -->
  </div>
  <div class="col-xs-6 text-right">
    <i class="fa fa-facebook fa-3x"></i>
    <br>
    <span class="font-weight-normal">Share this to your Facebook page</span>
  </div>
</div>

<ssi-publisher-landing
  [textBoxOnly]="true"
  [disable]="false"
  [post]="post"
  [accounts]="[post.accounts[0]]"
  [campaigns]="[]"
  [slideAnimationCompleted]="true">
</ssi-publisher-landing>

<div class="row spacer-top">
  <div class="col-xs-6">
    <div class="d-flex quote-retweet-footer-date-picker">
      <div class="flex-1">
        <input
          class="form-control form-control-minimal"
          type="text"
          mwlFlatpickr
          [(ngModel)]="sendAtDate"
          [altInput]="true"
          [convertModelValue]="true"
          [enableTime]="true"
          dateFormat="Y-m-d H:i"
          [minDate]="now"
          [time24hr]="true"
          altFormat="M J Y, H:i"
          placeholder="Send at...">
      </div>
      <button
        *ngIf="sendAtDate"
        class="btn btn-default"
        type="button"
        (click)="sendAtDate = null">
        <i class="fa fa-times text-danger"></i>
      </button>
    </div>
  </div>
  <div class="col-xs-6 text-right quote-retweet-footer-btns">
    <button
      class="btn btn-danger btn-rounded btn-sm"
      (click)="activeModal.dismiss()">
      <em translate="CANCEL_SHARE"></em>
    </button>
    <button
      class="btn btn-primary btn-rounded btn-sm"
      (click)="publish()"
      [disabled]="loadingTracker.active || !post.validity.isValid">
      <i class="fa fa-spin fa-spinner" *ngIf="loadingTracker.active"></i>
      <em
        *ngIf="!sendAtDate"
        translate="SHARE_NOW"
      ></em>
      <em
        *ngIf="sendAtDate"
        translate="SCHEDULE_SHARE"
      ></em>
    </button>
  </div>
</div>
