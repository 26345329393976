<div
  class="selector-draft-container"
  *ngIf="post && accounts"
>
  <ssi-account-selector
    [disabled]="!!post.edit"
    [accounts]="accounts"
    [selectedAccounts]="selectedAccounts"
    (selectedAccountsChange)="selectedAccountsChanged.emit($event)"
    ></ssi-account-selector>
  <div
    class="draft-title-input"
    *ngIf="post?.isDraft"
  >
    <!-- <label for="draftTitle">DRAFT TITLE</label> -->
    <input
      placeholder="Name your draft..."
      id="draftTitle"
      type="text"
      [(ngModel)]="params?.draft.title"
      (ngModelChange)="draftTitleValueChanged()"
    >
  </div>
</div>
<div
  class="post-controls"
  *ngIf="post.accounts.length > 0"
  [class.active]="inputFocused"
>
  <div class="post-controls-reply"
    *ngIf="post.twitterAccountsOnly()"
  >
    <ssi-dropdown-select-2
      size="md"
      name="options"
      [required]="true"
      [multiple]="false"
      [filterable]="false"
      [closeOnSelect]="false"
      [options]="replyOptionsTwitterIterable"
      [(ngModel)]="selectedTwitterReplyOption"
      (ngModelChange)="selectedReplyOptionChanged($event)"
    >
      <ng-template
        ssiTemplateSelector="headButtonLabel"
        let-toggle="toggle"
        let-expanded="expanded"
      >
        <div class="custom-head-button-label">
          <i class="ssi {{selectedTwitterReplyOption.icon}}"></i>
          <span>{{selectedTwitterReplyOption.label}}</span>
        </div>
      </ng-template>

      <ng-template
        ssiTemplateSelector="bodyHeader"
        let-toggle="toggle"
      >
        <div class="menu-header">
          <strong><i class="ssi ssi-information-badge"></i><span>Who can reply?</span></strong>
          <div><span>{{selectedTwitterReplyOption.description}}</span></div>
        </div>
      </ng-template>
    </ssi-dropdown-select-2>
  </div>

  <div *ngIf="post.linkedInAccountsOnly()" class="post-controls-reply">
    <ssi-dropdown-select-2
      size="md"
      name="options"
      [required]="true"
      [multiple]="false"
      [filterable]="false"
      [closeOnSelect]="false"
      [options]="replyOptionsLinkedInIterable"
      [(ngModel)]="selectedLinkedInReplyOption"
      (ngModelChange)="selectedReplyOptionChanged($event)"
    >
      <ng-template
        ssiTemplateSelector="headButtonLabel"
        let-toggle="toggle"
        let-expanded="expanded"
      >
        <div class="custom-head-button-label">
          <i class="ssi {{selectedLinkedInReplyOption.icon}}"></i>
          <span>{{selectedLinkedInReplyOption.label}}</span>
        </div>
      </ng-template>

      <ng-template
        ssiTemplateSelector="bodyHeader"
        let-toggle="toggle"
      >
        <div class="menu-header">
          <strong><i class="ssi ssi-information-badge"></i><span>Who can reply?</span></strong>
          <div><span>{{selectedLinkedInReplyOption.description}}</span></div>
        </div>
      </ng-template>
    </ssi-dropdown-select-2>
  </div>

  <div *ngIf="post.instagramAccountsOnly()" class="post-controls-reply">
    <ssi-dropdown-select-2
      size="md"
      name="options"
      [required]="true"
      [multiple]="false"
      [filterable]="false"
      [closeOnSelect]="false"
      [options]="replyOptionsLinkedInIterable"
      [(ngModel)]="selectedInstagramReplyOption"
      (ngModelChange)="selectedReplyOptionChanged($event)"
    >
      <ng-template
        ssiTemplateSelector="headButtonLabel"
        let-toggle="toggle"
        let-expanded="expanded"
      >
        <div class="custom-head-button-label">
          <i class="ssi {{selectedInstagramReplyOption.icon}}"></i>
          <span>{{selectedInstagramReplyOption.label}}</span>
        </div>
      </ng-template>

      <ng-template
        ssiTemplateSelector="bodyHeader"
        let-toggle="toggle"
      >
        <div class="menu-header">
          <strong><i class="ssi ssi-information-badge"></i><span>Who can reply?</span></strong>
          <div><span>{{selectedInstagramReplyOption.description}}</span></div>
        </div>
      </ng-template>
    </ssi-dropdown-select-2>
  </div>

  <!-- Change to meta accounts only once fb reel videeo oriention issue is fixed -->
  <ssi-meta-post-options-select
    *ngIf="post.instagramAccountsOnly()"
    class="network-post-controls-select"
    [composer]="post"
    [mediaCategory]="post.mediaCategory"
    [hideStoryOption]="!post.instagramAccountsOnly()"
  ></ssi-meta-post-options-select>

  <!-- <ssi-twitter-post-options-select
    *ngIf="post.hasTwitterSelected()"
    class="network-post-controls-select"
    [selectedOption]="selectedTwitterReplyOption"
    (select)="onTwitterReplyOptionSelect($event)"
  ></ssi-twitter-post-options-select> -->

  <ssi-youtube-post-options-select
    *ngIf="post.hasYoutubeSelected()"
    class="network-post-controls-select"
    [composer]="post"
    [visibility]="post.youtubeVisibility"
  ></ssi-youtube-post-options-select>

  <ssi-checkbox
    *ngIf="post.instagramAccountsOnly() && post.mediaCategory === MediaCategory.Reel"
    [checkboxText]="'SHARE_TO_FEED' | translate"
    [(ngModel)]="post.igShareToFeed"
    [leadingIcon]="'ssi ssi-information-badge'"
  ></ssi-checkbox>

  <div
    class="toggle-label"
    [class.toggled]="post.isDraft"
  >
    <p>Draft Post</p>
    <ssi-toggle-switch-2
      [(ngModel)]="post.isDraft"
      (ngModelChange)="onDraftStatusChanged($event)"></ssi-toggle-switch-2>
  </div>
</div>

<ng-container *ngIf="post && post.accounts.length > 0">
  <ng-container *ngIf="shouldShowVideoTitleInput()">
    <div
      class="composer-video-title-container">
      <span><i class="ssi ssi-video-title"></i></span>
      <input
        id="publisherVideoTitle"
        name="publisherVideoTitle"
        type="text"
        class="form-control composer-video-title-form-control"
        placeholder="Type video title here..."
        [disabled]="post.files.length === 0 || disable?.editText || post.accounts.length === 0"
        [(ngModel)]="post?.files[0]?.video.title"
        required
      >
    </div>
  </ng-container>
  <div
    class="composer-video-title-container"
    *ngIf="post.files.length > 0 && post.files[0].type === OutboxFileType.Gif && post.mediaRestrictions.gif.title.length.min > 0"
  >
    <span><i class="ssi ssi-gif-badge"></i></span>
    <input
      class="form-control composer-video-title-form-control"
      type="text"
      required
      [maxlength]="post.mediaRestrictions.gif.title.length.max"
      [(ngModel)]="post?.files[0]?.gif.title"
      placeholder="Type gif title here..."
      ssiElementLifecycle
      (init)="refreshTextInputHighlight()"
      (destroy)="refreshTextInputHighlight()"
    >
  </div>
</ng-container>

<div
  *ngIf="post"
  class="composer-textarea-container"
  [class.active]="inputFocused"
  [class.disabled]="post.accounts.length === 0"
  ssiEmojiFormControlContainer
  ssiTextInputHighlightContainer
  [(formControlValue)]="post.text"
>
  <ng-container *ngIf="post.accounts.length > 0">
    <textarea
      #textarea
      class="composer-text-form-control"
      [disabled]="disable?.editText || post.accounts.length === 0"
      [placeholder]="post.accounts.length === 0 ? '' : (post.isSplit && post.splitPostAccount.socialNetwork.accountTypeGroupName === 'instagram' ? 'Type away your Instagram caption, but remember no more than 30 hashtags!' : 'Type away! Let’s get your creative juices flowing...')"
      [(ngModel)]="post.text"
      (ngModelChange)="textareaValueChanged()"
      id="publisherTextArea"
      name="publisherTextArea"
      ssiEmojiFormControl
      ssiTooltip
      [tooltipAppendToBody]="true"
      #linkHighlightTagHoverTooltip="ssiTooltip"
      tooltipTrigger="manual"
      [tooltipHost]="highlightTagHoverElement"
      tooltipWindowClass="tooltip-link-highlight"
      tooltipPlacement="bottom"
      [tooltipOffset]="[0, 0]"
      [tooltipTemplate]="linkHighlightTooltip"
      [tooltipDisabled]="!highlightTagHoverElement"
      ssiTextInputAutocomplete
      [findChoices]="getAutocompleteMentions"
      [getChoiceLabel]="getChoiceLabel"
      [menuComponent]="mentionsMenuComponent"
      [searchRegexp]="'^([-&.\\w]+ *){0,3}$'"
      (focus)="inputFocused = true"
      (blur)="inputFocused = false"
      [selectedChoices]="getSelectedChoices()"
      (selectedChoicesChange)="post.onSelectedChoicesChange($event)"
    ></textarea>

    <ssi-emoji-autocomplete [textInputElement]="textarea"></ssi-emoji-autocomplete>
    <!-- postt:{{post | json}} -->

    <ssi-text-input-highlight
      #textInputHighlight
      [tags]="post.highlightEntities"
      [blockedWordCssClass]="'blocked-word-mark'"
      [blockedWordMatches]="post?.blockingWordMatches"
      [textInputElement]="textarea"
      [textInputValue]="post.text"
      (tagMouseEnter)="highlightTagMouseEnter($event)"
      (tagMouseLeave)="highlightTagMouseLeave($event)"
      ssiTooltip
      [tooltipAppendToBody]="true"
      #linkHighlightTagHoverTooltip="ssiTooltip"
      tooltipTrigger="manual"
      [tooltipHost]="highlightTagHoverElement"
      tooltipWindowClass="tooltip-link-highlight"
      tooltipPlacement="bottom"
      [tooltipOffset]="[0, 0]"
      [tooltipTemplate]="linkHighlightTooltip"
      [tooltipDisabled]="!highlightTagHoverElement"
    ></ssi-text-input-highlight>

    <ng-template #linkHighlightTooltip>
      <div
        (mouseenter)="clearLinkTooltipInterval()"
        (mouseleave)="highlightTagMouseLeave()"
        class="tooltip-link-highlight-content"
      >
        <p
          *ngIf="activeLink.data.type === OutboxPublisherHighlightType.Link"
          (click)="openLinkHighlightModal()"
        >Edit Link</p>
        <p
          *ngIf="activeLink.data.type === OutboxPublisherHighlightType.Mention"
          (click)="editMention()"
        >Edit Handle</p>
        <a
          [href]="OutboxPublisherHighlightType.Mention ? activeLink.data.url : 'https://' + activeLink.data.url"
          target="_blank"
        >
          <i class="ssi ssi-new-tab"></i>
        </a>
      </div>
    </ng-template>

    <div class="composer-textarea-footer">
      <span
        class="post-control-item composer-textarea-character-count"
        [class.active]="post.text"
        [class.invalid]="post.charactersRemaining < 0"
      >
        {{ post.charactersRemaining }}
      </span>
      <ssi-emoji-form-control-trigger
        popoverPlacement="top"
        class="post-control-item"
      >
        <a
          href="javascript:;"
          class="composer-textarea-footer-right-icon"
          [hidden]="post.accounts.length === 0"
        >
          <img
            src="/assets/images/composer/emoji-picker-trigger-big.png"
            alt="Open emoji picker icon"
          >
          <span class="a11y-label">Open emoji picker</span>
        </a>
      </ssi-emoji-form-control-trigger>
      <ng-container>
        <a
          *ngIf="!post.hasInstagramSelected() && post.mediaRestrictions.gif.remaining > 0"
          [hidden]="!!fileUploadActive"
          [ssiTooltip]="'SELECT_A_GIF_FROM_GIPHY' | translate"
          [tooltipAppendToBody]="true"
          [tooltipWindowClass]="'tooltip-chunky'"
          href="javascript:;"
          class="post-control-item"
          ssiGifSearch
          (onGifFileUploaded)="gifUploadSuccess.emit($event)"
        >
          <img
            src="/assets/images/composer/giphy.svg"
            alt="Open Giphy picker icon"
          >
          <span class="a11y-label">{{'SELECT_A_GIF_FROM_GIPHY' | translate}}</span>
        </a>
        <ssi-menu
          *ngIf="post.accounts.length > 0 && post.metaAccountsWithoutThreads()"
          #actionsMenu
          class="post-control-item location-menu"
          [headAlignment]="'top right'"
          [bodyAlignment]="'bottom right'"
        >
          <ng-template ssiTemplateSelector="menuHead">
            <ng-container *ngIf="!selectedLocationProfile">
              <i
                [class.active]="selectedLocationProfile"
                class="ssi ssi-map-marker menu-trigger-icon"
                [ssiTooltip]="selectedLocationProfile ? selectedLocationProfile.location.fullLocation : 'Add location tag'"
                [tooltipAppendToBody]="true"
                [tooltipWindowClass]="'tooltip-chunky'"
              ></i>
              <span class="a11y-label">Add Location</span>
            </ng-container>
            <ng-container *ngIf="selectedLocationProfile">
              <div class="location-selected">
                <i class="ssi ssi-map-marker menu-trigger-icon"></i>
                <span>{{selectedLocationProfile.location.city}}</span>
                <i (click)="selectedLocationProfile = null" class="ssi ssi-delete-close"></i>
              </div>
            </ng-container>
          </ng-template>

          <ng-template ssiTemplateSelector="menuBody">
            <div class="location-search-container">
              <input
                    type="search"
                    [(ngModel)]="locationInput"
                    (ngModelChange)="searchLocation()"
                    [placeholder]="'Type a location & hit search...'">
              <i class="ssi ssi-search-small"></i>
              <!-- <div class="location-selected" *ngIf="selectedLocationProfile">
                <i class="ssi ssi-map-marker menu-trigger-icon"></i>
                <span>{{selectedLocationProfile.location.city}}</span>
                <i (click)="selectedLocationProfile = null" class="ssi ssi-delete-close"></i>
              </div> -->
            </div>
            <div class="location-default" *ngIf="locationInput.length === 0">
              <i class="ssi ssi-geo-tag-prompt"></i>
              <h3>Geo-Tag your post</h3>
              <p>Select one location tag for this post</p>
            </div>
            <div class="location-tags" *ngIf="locationInput.length > 0">
              <ssi-spinner [visible]="searchingLocation && !locationTags"></ssi-spinner>
              <p *ngIf="!searchingLocation && locationTags && locationTags.length === 0">No results for your search, try
                narrowing your search with a more specific location.</p>
              <ul>
                <li
                  *ngFor="let profile of locationTags"
                  (click)="setLocationTag(profile); actionsMenu.close()"
                  [class.selected]="selectedLocationProfile === profile"
                  title="{{profile.location.fullLocation}}"
                ><span>{{profile.location.fullLocation}}</span>
                  <div [class.selected]="selectedLocationProfile === profile" class="select-circle"></div>
                </li>
              </ul>
            </div>
          </ng-template>
        </ssi-menu>

        <ssi-menu
          *ngIf="post.accounts.length && (post.mediaRestrictions.image.remaining > 0 || post.mediaRestrictions.video.remaining > 0)"
          [hidden]="fileUploadActive"
          class="post-control-item"
          [headAlignment]="'top right'"
          [bodyAlignment]="'bottom right'"
          [onlyHideMenuBody]="true"
          [templateRefs]="{menuBody: mediaUploadOptionsMenuBodyTemplate}"
        >
          <ng-template ssiTemplateSelector="menuHead">
            <i
              class="ssi ssi-attach-small menu-trigger-icon"
              [ssiTooltip]="'Attach media'"
              [tooltipAppendToBody]="true"
              [tooltipWindowClass]="'tooltip-chunky'"
            ></i>
          </ng-template>
        </ssi-menu>
        <ssi-menu
          *ngIf="post.accounts.length > 0"
          class="post-control-item"
          [headAlignment]="'top right'"
          [bodyAlignment]="'bottom right'"
        >
          <ng-template ssiTemplateSelector="menuHead">
            <i
              class="ssi ssi-ai-writing-tool menu-trigger-icon"
              [ssiTooltip]="hasWritingToolEnabled ? ('LOST_FOR_WORDS_USE_THIS_TOOL_TO_WRITE_A_POST_REPHRASE_AN_EXISTING_ONE_AND_ADD_TOPICAL_HASHTAGS' | translate) : ('LOST_FOR_WORDS_WE_HAVE_JUST_THE_THING_CONTACT_YOUR_CUSTOMER_SUCCESS_MANAGER_TO_UPGRADE_YOUR_PACKAGE_TO_INCLUDE_GENERATE_OUR_AIPOWERED_CAPTION_WRITER' | translate)"
              tooltipTitle="Generate"
              [tooltipAppendToBody]="false"
              tooltipPlacement="top"
              tooltipTrailingIconClass="ssi ssi-enhance"
              [tooltipWindowClass]="'tooltip-chunky-big'"
            ></i>
          </ng-template>
          <ng-template ssiTemplateSelector="menuBody">
            <ul class="ai-writing-tool-context-menu">
              <li
                class="menu-item"
                (click)="openAiWritingToolModal('generate')"
              >
                <i class="ssi ssi-ai-writing-tool"></i>
                <span translate="GENERATE_POST"></span>
              </li>
              <li
                class="menu-item"
                (click)="openAiWritingToolModal('rephrase')"
                [class.disabled]="!post.text"
                [ssiTooltip]="!post.text ? 'Whoa there! Add some text in the compose area first.' : ''"
                [tooltipAppendToBody]="true"
                tooltipPlacement="top"
                [tooltipWindowClass]="'tooltip-chunky'"
              >
                <ssi-icon icon="reload" height="14" width="14" color="#838eab"></ssi-icon>
                <span translate="REPHRASE_POST"></span>
              </li>
              <li
                class="menu-item"
                (click)="openAiWritingToolModal('hashtags')"
                [class.disabled]="!post.text"
                [ssiTooltip]="!post.text ? 'Whoa there! Add some text in the compose area first.' : ''"
                [tooltipAppendToBody]="true"
                tooltipPlacement="top"
                [tooltipWindowClass]="'tooltip-chunky'"
              >
                <i class="ssi ssi-populate-hashtags"></i>
                <span translate="POPULATE_HASHTAGS"></span>
              </li>
            </ul>
          </ng-template>
        </ssi-menu>
      </ng-container>
    </div>

    <div
      class="account-controls"
      *ngIf="post.accounts.length > 1 || post.blockingWordMatches.length > 0"
      [class.active]="inputFocused"
    >
      <button
        class="acc-control-button post-errored"
        *ngIf="post.blockingWordMatches.length > 0"
        [ssiTooltip]="'THE_POST_MATCHES_THE_FOLLOWING_PROFANITIES_AND_WILL_BE_SENT_FOR_VALIDATION__MATCHEDWORDS_' | translate:{matchedWords: post.exactBlockingMatchesInText.join(', ')}"
        [tooltipWindowClass]="'tooltip-chunky-big'"
      >
        <i class="ssi ssi-warning"></i>
      </button>
      <button
        class="acc-control-button"
        *ngIf="!post.isSplit && post.features.changeAccounts && post.accounts.length > 1"
        (click)="setIsSplit(true)"
        [ssiTooltip]="'Split your post to have full network functionality for each channel selected'"
        tooltipTitle="Split posts"
        [tooltipWindowClass]="'tooltip-chunky-big'"
      >
        <i class="ssi ssi-split-post"></i><span>Split posts</span>
      </button>

      <ng-container *ngIf="post.isSplit">
        <button
          class="acc-control-button"
          *ngIf="post.features.combinePostText"
          (click)="setIsSplit(false)"
          [ssiTooltip]="'This auto selects the post with the least amount of characters due to network limits'"
          [tooltipWindowClass]="'tooltip-chunky-big'"
          tooltipTitle="Combine posts"
        ><i class="ssi ssi-combine-post"></i><span>Combine</span></button>

        <span *ngFor="let network of post.socialNetworks">
          <ssi-menu
            #networkGroupMenu
            *ngIf="network.accounts.length > 1"
            class="dashboard-templates-menu"
            [headAlignment]="'top left'"
            [bodyAlignment]="'bottom left'"
          >
            <ng-template ssiTemplateSelector="menuHead">
              <button
                class="acc-control-button"
                [ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
                [tooltipOffset]="[0, 20]"
                [tooltipWindowClass]="'tooltip-chunky-big'"
                [tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image?.min || network.config.mediaRestrictions.post.gif?.min || network.config.mediaRestrictions.post.video?.min)"
                [class.active]="post.splitPostAccount.socialNetwork === network.config"
                [class.split-post]="post.splitPostAccount.socialNetwork !== network.config"
              >
                <ssi-social-network-icon
                  class="network-icon"
                  [accountTypeId]="network.config.accountTypeId"
                  [size]="16"
                ></ssi-social-network-icon>
                <span *ngIf="post.splitPostAccount.socialNetwork === network.config">{{post.splitPostAccount.displayName
                  | truncate:14 }}</span>
                <span class="caret"></span>
              </button>
            </ng-template>

            <ng-template ssiTemplateSelector="menuBody">
              <ul class="g-list network-accounts-list">
                <li
                  class="g-list-item"
                  *ngFor="let account of network.accounts"
                  [class.active]="post.splitPostAccount === account"
                  (click)="networkGroupMenu.close(); post.splitPostAccount = account;"
                >
                  <ssi-social-network-icon
                    class="g-list-item-icon"
                    [accountTypeId]="network.config.accountTypeId"
                    [size]="16"
                  ></ssi-social-network-icon>
                  <span>{{ account.displayName }}</span>
                </li>
              </ul>
            </ng-template>
          </ssi-menu>

          <button
            *ngIf="network.accounts.length === 1"
            class="acc-control-button network-select"
            [ssiTooltip]="'THIS_CHANNEL_REQUIRES_MEDIA_TO_BE_ABLE_TO_POST_OUT_SO_WE_CANNOT_COMBINE_THIS_POST' | translate"
            [tooltipOffset]="[0, 20]"
            [tooltipWindowClass]="'tooltip-chunky-big'"
            [tooltipDisabled]="!(network.config.mediaRestrictions.post.min || network.config.mediaRestrictions.post.image.min || network.config.mediaRestrictions.post.gif.min || network.config.mediaRestrictions.post.video.min)"
            [class.active]="post.splitPostAccount.socialNetwork === network.config"
            [class.split-post]="post.splitPostAccount.socialNetwork !== network.config"
            (click)="post.splitPostAccount = network.accounts[0]"
          >
            <ssi-social-network-icon
              class="network-icon"
              [accountTypeId]="network.config.accountTypeId"
              [size]="16"
            ></ssi-social-network-icon>
          </button>
        </span>
      </ng-container>

    </div>
  </ng-container>


  <!-- No accounts prompt -->
  <div
    class="no-accounts-container"
    *ngIf="post.accounts.length === 0"
  >
    <i class="ssi ssi-select-microcopy"></i>
    <h4>Select an account</h4>
    <p>To get started, select an account to create your post</p>
  </div>

</div>

<ssi-context-menu
  #mentionClickContextMenu
  [offsetTop]="-55"
  [offsetLeft]="10"
>
  <ng-template let-ctx>
    <div class="composer-mention-click-context-menu">
      <button
        type="button"
        translate="EDIT_HANDLE"
        (click)="editMention()"
      ></button>
      <ng-container *ngIf="ctx.tag.data.url">
        <div class="vertical-line"></div>
        <a
          href="{{ctx.tag.data.url}}"
          target="_blank"
        >
          <i class="ssi ssi-new-tab"></i>
        </a>
      </ng-container>
    </div>
  </ng-template>
</ssi-context-menu>