import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import {
  InsightsService,
  InsightsWidget,
  sanitizeFiltersForAPI,
  dedupeAndMergeFiltersForAPI
} from '../../../insights.service';
import { widgetTypes, WidgetTypes } from '../../constants';

import { Sentiments, sentiments } from '../../../../../../common/constants';
import { animateScroll } from '../../../../../../common/components/treetable/common/utils-dom';
import { KtdGridComponent } from '../../../../../../common/components/ktd-grid/grid.component';
import { KtdGridItemComponent } from '../../../../../../common/components/ktd-grid/grid-item/grid-item.component';
import {
  KtdGridLayout,
  KtdGridLayoutItem
} from '../../../../../../common/components/ktd-grid/grid.definitions';
import { ktdTrackById } from '../../../../../../common/components/ktd-grid/utils/grid.utils';
import { PopupService } from '../../../../../../common/services/popup/popup.service';
import { NotificationService } from '../../../../../../common/services/notification/notification.service';
import { Filter } from '../../../reports/view/view-report.component';
import { Schema } from '../../../insights.service';

export const COLS = 4;
export const ROW_HEIGHT = 250;

export interface GridLayoutItem {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  apiData: InsightsWidget;
}

@Component({
  selector: 'ssi-widgets-grid',
  templateUrl: './widgets-grid.component.html',
  styles: [],
  preserveWhitespaces: false,
  styleUrls: ['./widgets-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetsGridComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /*
   * 'public' members in this component are accessed from the outside (parent) of this component
   */
  @Input() apiWidgets: InsightsWidget[] = [];
  @Input() viewMode = false;
  @Input() createMode = false;
  @Input() filtersBarVisible = false;
  @Input() activeFilters: Filter[] = [];
  @Input() streamIds: string[];
  @Input() templateMode = false;

  @Output() onApplyFilters = new EventEmitter();
  @Output() totalGridDimensions = new EventEmitter();
  @Output() onCustomWidgetEdit = new EventEmitter();
  @Output() updateWidgets = new EventEmitter();

  @ViewChild(KtdGridComponent) grid: KtdGridComponent;
  @ViewChild('gridContainer') gridContainer: ElementRef;
  // @ViewChildren(KtdGridItemComponent)
  // gridItems: QueryList<KtdGridItemComponent>;

  schema: Schema;

  cols = COLS;
  rowHeight = ROW_HEIGHT;

  responsiveWidth = 1200;
  trackById = ktdTrackById;
  loaders: boolean[] = [];

  public widgets: GridLayoutItem[] = [];
  widgetTypes: WidgetTypes = widgetTypes;
  sentiments: Sentiments = sentiments;
  globalFilters: Filter[] = [];
  widgetsInitialSnapshot: GridLayoutItem[];
  windowResizeEventForceFired = false;

  protected destroyed$ = new Subject<void>();

  constructor(
    protected popup: PopupService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected insightService: InsightsService,
    protected notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['apiWidgets'] && changes['apiWidgets'].currentValue) {
      this.widgets = this.toWidgets(this.apiWidgets);
    }

    if (changes.activeFilters && changes.activeFilters.currentValue) {
      if (this.apiWidgets) {
        this.globalFilters = sanitizeFiltersForAPI([...this.activeFilters]);
        this.globalFilters = dedupeAndMergeFiltersForAPI(this.globalFilters);
      }
    }
  }

  async ngOnInit() {
    this.listenWindowResizeEvents();

    this.schema = await this.insightService.getInsightSchema();
  }

  ngAfterViewInit(): void {
    if (window.innerWidth <= this.responsiveWidth) {
      this.setResponsiveLayout();
    }

    this.grid.resize();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  reRenderWidgets(): void {
    const widgets = this.widgets;
    this.widgets = [];
    this.changeDetectorRef.detectChanges();
    this.widgets = widgets;
  }

  setResponsiveLayout(): void {
    console.log('setting responsive layout...');

    this.widgetsInitialSnapshot = JSON.parse(JSON.stringify(this.widgets));

    this.widgets = this.widgets.map((widget, i) => {
      // make all widgets full width and set both y and x to be the same for all widgets,
      // it will cause widget position conflicts that will be resolved by the grid library itself - making them ordered each one below the other
      widget.w = this.cols;
      widget.h = widget.h;
      widget.y = 0;
      widget.x = 0;

      return widget;
    });
  }

  listenWindowResizeEvents(): void {
    merge(fromEvent(window, 'resize'), fromEvent(window, 'orientationchange'))
      .pipe(
        debounceTime(200),
        takeUntil(this.destroyed$)
        // filter(() => this.autoResize)
      )
      .subscribe((event: any) => {
        if (this.viewMode) {
          const screenWidth = event.target.innerWidth;
          if (screenWidth <= this.responsiveWidth) {
            if (!this.widgetsInitialSnapshot) {
              this.setResponsiveLayout();
            }
          } else {
            if (this.widgetsInitialSnapshot) {
              console.log('restoring initial layout...');

              // restore initial layout if screen size goes back to normal
              this.widgets = this.widgets.map((widget) => {
                const wSnapshot = this.widgetsInitialSnapshot.find(
                  (ws) => ws.id === widget.id
                );
                widget.w = wSnapshot.w;
                widget.h = wSnapshot.h;
                widget.y = wSnapshot.y;
                widget.x = wSnapshot.x;

                return widget;
              });

              this.widgetsInitialSnapshot = undefined;
            }
          }
        }

        this.grid.resize();

        if (!this.windowResizeEventForceFired) {
          this.windowResizeEventForceFired = true;
          setTimeout(() => {
            // so highcharts can recalculate width and height
            window.dispatchEvent(new Event('resize'));
          }, 400);
        } else {
          // reset it for the next resize event...
          // not an ideal solution, but couldn't think of a better way to re-fire window resize event in order for highcharts to properly resize
          this.windowResizeEventForceFired = false;
        }
      });
  }

  toWidget(apiWidget: InsightsWidget, idToAssign?: number): GridLayoutItem {
    let id;
    if (idToAssign) {
      id = idToAssign;
    } else {
      const maxId = this.widgets.length
        ? Math.max(...this.widgets.map((w) => Number(w.id)))
        : 0;

      id = maxId + 1;
    }

    return {
      id: String(id),
      x: apiWidget.display_properties.x,
      y: apiWidget.display_properties.y,
      w: apiWidget.display_properties.w,
      h: apiWidget.display_properties.h,
      apiData: apiWidget
    };
  }

  toApiWidget(widget: GridLayoutItem): InsightsWidget {
    const display_properties = {
      x: widget.x,
      y: widget.y,
      w: widget.w,
      h: widget.h
    };

    const apiWidget = { ...widget.apiData };
    apiWidget.display_properties = display_properties;

    if (widget.apiData.display_properties.annotations) {
      apiWidget.display_properties.annotations =
        widget.apiData.display_properties.annotations;
    }

    return apiWidget;
  }

  toWidgets(apiWidgets: InsightsWidget[]): GridLayoutItem[] {
    return apiWidgets.map((aw, i) => this.toWidget(aw, i + 1));
  }

  public toApiWidgets(widgets: GridLayoutItem[]): InsightsWidget[] {
    return widgets.map((w) => this.toApiWidget(w));
  }

  updateWidget(widget: GridLayoutItem, apiData: InsightsWidget): void {
    widget.apiData = { ...apiData };
  }

  onLayoutUpdated(layout: KtdGridLayout): void {
    console.log('UPDATED LAYOUT');
    this.widgets = layout.map((layoutItem: KtdGridLayoutItem) => {
      const oldWidget = this.widgets.find((w) => w.id === layoutItem.id);
      // a way to keep / bring back widget apiData (removed when layout gets updated) + re-map changed props
      return {
        ...layoutItem,
        ...{ apiData: this.toApiWidget({ ...oldWidget, ...layoutItem }) }
      };
    });

    console.log('onLayoutUpdated: ', this.widgets, this.grid._height);

    setTimeout(() => {
      // so highcharts can recalculate width and height
      window.dispatchEvent(new Event('resize'));
    }, 400);
  }

  onGridDimensionsUpdated(dimensions) {
    this.totalGridDimensions.emit(dimensions);
  }

  public addNewWidget(apiWidget: InsightsWidget): void {
    // Not necessarily the last row since a widget height from the row before can affect y axis but
    // the grid internally will fix collisions automatically
    const maxRow = this.widgets.length
      ? Math.max(...this.widgets.map((w) => Number(w.y)))
      : 0;
    const widgetToAdd = this.toWidget(apiWidget);
    widgetToAdd.w =
      apiWidget.display_properties.w > apiWidget.typeConst.defaultSize.w
        ? apiWidget.display_properties.w
        : apiWidget.typeConst.defaultSize.w;
    widgetToAdd.h =
      apiWidget.display_properties.h > apiWidget.typeConst.defaultSize.h
        ? apiWidget.display_properties.h
        : apiWidget.typeConst.defaultSize.h;
    widgetToAdd.x = 0;
    widgetToAdd.y = maxRow + 1;

    this.widgets = [...this.widgets, widgetToAdd];
    this.notificationService.open(
      `'${widgetToAdd.apiData.name}' widget has been added`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      1000
    );

    setTimeout(() => {
      animateScroll(
        this.gridContainer.nativeElement,
        this.gridContainer.nativeElement.scrollHeight
      );
    }, 200);
  }

  public addWidgets(apiWidgets: InsightsWidget[]): void {
    this.widgets = this.toWidgets(apiWidgets);

    this.notificationService.open(
      `You've just copied the report. Please make sure you Save your report.`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      2000
    );
  }

  async editWidget(widget: GridLayoutItem, index: number): Promise<void> {
    this.onCustomWidgetEdit.emit(widget);
  }

  async deleteWidget(widget: GridLayoutItem, index: number): Promise<void> {
    // TODO: not needed at this point - delete confirmation takes place only for custom made widgets and only if not already saved
    const shouldDelete = true;
    // const shouldDelete = await this.popup.confirm({
    //   title: 'Delete custom widget?',
    //   message: `This custom widget is not stored in any other report`,
    //   okText: 'Delete widget',
    //   windowClass: 'modal-rounded-corners',
    //   hideClose: true,
    //   iconClass: 'ssi ssi-bin',
    //   backdrop: true
    // });

    if (shouldDelete) {
      this.loaders.splice(index, 1); // remove flag to allow loader to be shown again after widget deletion
      this.widgets = this.widgets.filter((w) => w.id !== widget.id);
      setTimeout(() => {
        // hack to keep the layout updated in this component (after deleting a widget order might be changed)
        this.grid.layoutUpdated.emit(this.grid.layout);
      });
    }
  }

  async saveWidget(widget) {
    const index = this.widgets.findIndex(({ id }) => id === widget.id);
    this.widgets[index].apiData = widget.widget;
    this.updateWidgets.emit(this.toApiWidgets(this.widgets));
  }
}
