import 'exports-loader?trackJs!trackjs';

import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgModule, Injector, ErrorHandler } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  StateService,
  Transition,
  TransitionService,
  UIRouter,
  UIRouterModule,
  UrlService
} from '@uirouter/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { firebaseProject } from './library/constants/live-chat';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AgmCoreModule } from '@agm/core';
import { HotkeyModule } from 'angular2-hotkeys';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  LocalStorageModule,
  LocalStorageService
} from 'angular-2-local-storage';
import axios from 'axios';
import * as moment from 'moment';
import objectFitImages from 'object-fit-images';
import { TrackJsErrorHandler } from './trackJs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as webFont from 'webfontloader';

// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { appInjector } from './app-injector';
import {
  AccountModel,
  API,
  UIResourcesModule,
  UserModel
} from '@ui-resources-angular';

import { ServicesModule } from './common/services/services.module';
// import {
//   DEFAULT_TRANSLATIONS,
//   TranslateHttpLoaderService
// } from '@orlo/common/services/translate-http-loader/translate-http-loader.service';
import { MissingTranslationHandlerService } from '@orlo/common/services/missing-translation-handler/missing-translation-handler.service';
import { AuthService } from './common/services/auth/auth.service';
import {
  DEFAULT_TRANSLATE_LANGUAGE,
  STATIC_FILES_ENDPOINT,
  USER_LANGUAGE_STORAGE_KEY,
  WEEK_STARTS_ON,
  googleMapsAPIKey
} from '@orlo/common/constants';
import { API_ENDPOINT, environment } from 'src/environments/environment';
import {
  CampaignsService,
  ColleaguesService,
  TeamsService
} from './common/services/api';
import { ErrorHandlerService } from './common/services/error-handler/error-handler.service';
import { CompanyService } from './common/services/company/company.service';
import { RedirectService } from './common/services/redirect/redirect.service';
import { ServerService } from './common/services/server/server.service';
import { SocketsService } from './common/services/sockets/sockets.service';
import { UserPreferencesService } from './common/services/user-preferences/user-preferences.service';
import { WorkflowManagerService } from './common/services/workflow-manager/workflow-manager.service';

import { ModulesModule } from './modules/modules.module';

import {
  NgbDropdownModule,
  NgbModalModule,
  NgbAlertModule,
  NgbProgressbarModule,
  NgbPopoverModule,
  NgbTimepickerModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
  NgbTabsetModule
} from '@ng-bootstrap/ng-bootstrap';

declare const window: any;

// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular-app-with-ngx-translate
const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (
  http: HttpClient
) =>
  new TranslateHttpLoader(
    http,
    `${STATIC_FILES_ENDPOINT}/translations/`,
    '.json'
  );

export function uiRouterConfigFn(router: UIRouter) {
  // // Define a custom parameter type for strings without encoding
  // router.urlService.config.type('string', {
  //   encode: (val: any) => (val === null ? val : val.toString()), // No encoding
  //   decode: (val: any) => typeof val === 'string' ? decodeURIComponent(val) : val,
  //   is: (val: any) => val === null || typeof val === 'string', // Ensure it's a string or null
  //   pattern: /.*/, // Allow all strings
  //   equals: (a: any, b: any) => a === b // determine if the route has changed when navigating between states
  // });

  router.urlService.config.hashPrefix('');

  // Disable strict mode so it doesn't differentiate between trailing and non-trailing slashes
  router.urlService.config.strictMode(false);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    UIRouterModule.forRoot({ config: uiRouterConfigFn }),
    UIResourcesModule.forRoot(),
    TranslateModule.forRoot({
      // loader: {
      //   provide: TranslateLoader,
      //   useClass: TranslateHttpLoaderService
      // },
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHandlerService
      }
    }),
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    LocalStorageModule.withConfig(),
    ServicesModule.forRoot(),
    ModulesModule,
    AngularFireModule.initializeApp(
      firebaseProject.credentials.firebase,
      firebaseProject.name
    ),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    AgmCoreModule.forRoot({
      apiKey: googleMapsAPIKey
    }),
    HotkeyModule.forRoot(),
    // ServiceWorkerModule.register(`/firebase-messaging-sw.js`),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: ENVIRONMENT === EnvironmentType.Production
    // }),

    // Bootstrap Modules
    NgbDropdownModule,
    NgbModalModule,
    NgbAlertModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbTimepickerModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbTabsetModule
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    // {
    //   provide: NgModuleFactoryLoader,
    //   useClass: SystemJsNgModuleLoader
    // },
    // {
    //   provide: DEFAULT_TRANSLATIONS,
    //   useFactory: defaultTranslationsFactory
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: TrackJsErrorHandler
    }
  ],
  bootstrap: [AppComponent] // ngDoBoostrap doesn't fire if this is here!?
})
export class AppModule {
  constructor(
    protected api: API,
    protected injector: Injector,
    protected uiRouter: UIRouter,
    protected stateService: StateService,
    protected transitionService: TransitionService,
    protected translateService: TranslateService,
    protected urlService: UrlService,
    protected localStorageService: LocalStorageService,
    protected authService: AuthService,
    protected errorHandlerService: ErrorHandlerService,
    protected userModel: UserModel,
    protected campaignModel: CampaignsService,
    protected accountModel: AccountModel,
    protected teamsService: TeamsService,
    protected colleaguesService: ColleaguesService,
    protected companyService: CompanyService,
    protected redirectService: RedirectService,
    protected serverService: ServerService,
    protected socketService: SocketsService,
    protected userPreferencesService: UserPreferencesService,
    protected workflowManagerService: WorkflowManagerService
  ) {
    this.uiRouter.urlService.rules.otherwise((matchedPath: boolean) => {
      if (authService.isAuthenticated()) {
        redirectService.handleSuccessfulLogin();
      } else {
        redirectService.redirectToLogin();
      }
    });

    translateService.setDefaultLang(DEFAULT_TRANSLATE_LANGUAGE);
    const savedLanguage = localStorageService.get<string>(
      USER_LANGUAGE_STORAGE_KEY
    );
    if (savedLanguage) {
      translateService.use(savedLanguage);
    } else {
      translateService.use(DEFAULT_TRANSLATE_LANGUAGE);
    }

    translateService.use(translateService.currentLang);
    translateService.onLangChange.subscribe(({ lang }) => {
      translateService.use(lang);
      localStorageService.set(USER_LANGUAGE_STORAGE_KEY, lang);
    });

    api.log = undefined;
    api.error = undefined;
    api.basePath = `${API_ENDPOINT}/`;

    axios.interceptors.request.use((config) => {
      if (!config['skipAuthorization'] && authService.isAuthenticated()) {
        config.headers[authService.config.tokenHeader] = `${
          authService.config.tokenType
        } ${authService.getToken()}`;
      }
      return config;
    });

    errorHandlerService.addAngularInterceptors();

    // api.httpConfig.paramsSerializer = (params) => {
    //   return upgrade.$injector.get('$httpParamSerializerJQLike')(params);
    // };

    serverService.getSettings().then(({ oauth }) => {
      authService.config.providers.google.clientId = oauth['Google+'].clientId;
    });

    // since the app versioning method changed this won't work anymore (did it ever?)
    // if (environment.production) {
    //   updateManagerService.initialise();
    // }

    urlService.listen();
    urlService.sync();

    // ngDoBoostrap
    appInjector(this.injector);

    this.userChangesEvents();
    this.stateTransitionsEvents();

    window.trackJs.configure({
      version: environment.appVersion
    });

    moment.locale('en_gb', {
      week: {
        dow: WEEK_STARTS_ON // Monday is the first day of the week
      }
    } as any);

    // objectFitImages(); // might not be needed anymore - it's a polyfill for IE, Edge, etc.: https://github.com/fregante/object-fit-images

    this.stateService.defaultErrorHandler((error) => {
      const ignoreCodes = [
        2, // RejectType.SUPERSEDED
        3 // RejectType.ABORTED
      ];
      if (error && !ignoreCodes.includes(error.type)) {
        error.message = 'State change error: ' + error.message;
        console.error(error);
        // window['requestIdleCallback'](() => {
        //   trackJs.track(error);
        // });
      }
    });

    webFont.load({
      google: {
        families: [
          'Lato:100,100i,300,300i,400,400i,700,700i,900,900i',
          'Roboto:300,400,500,700,900'
        ]
      }
    });
  }

  ngDoBootstrap() {
    // doesn't fire if bootstrap: [] is in the @NgModule
  }

  userChangesEvents(): void {
    this.userModel.events.loggedIn.subscribe(({ token }) => {
      this.authService.setToken({ access_token: token });
    });

    this.userModel.events.loggedOut.subscribe(() => {
      if (this.authService.isAuthenticated()) {
        this.authService.logout();
      }

      this.redirectService.redirectToLogin();

      if (this.socketService) {
        this.socketService.disconnect();
      }

      this.userPreferencesService.clearCache();
    });

    this.userModel.events.changed.subscribe(async ({ user }) => {
      this.campaignModel.getAll();
      this.colleaguesService.getAll();
      this.teamsService.getAll();

      if (window.ga) {
        const gaCustomProperties = {
          dimension1: user.id,
          dimension2: user.company_id
        };
        window.ga('set', '&uid', user.id);
        window.ga('set', gaCustomProperties);
        window.ga('websiteTracker.set', 'userId', user.id);
        window.ga('websiteTracker.set', gaCustomProperties);
      }

      window.trackJs.configure({
        userId: user.id
      });

      const companyConfig = await this.companyService.getConfig();
      const fetchBrowserlessCookie = document.cookie.match(
        new RegExp('(^| )' + 'browserless_io' + '=([^;]+)')
      );
      const browserlessMode = fetchBrowserlessCookie
        ? fetchBrowserlessCookie[2] === 'true'
        : false;
      if (!browserlessMode) {
        window.pendo.initialize({
          excludeAllText: true,
          visitor: {
            id: user.id,
            billingPermission: user.permissions.company.billing
          },
          account: {
            id: user.company_name,
            companyID: user.company_id,
            creationDate: user.created_at,
            ADVERTS: companyConfig.feature_flags.includes('ADVERTS'),
            LIVECHAT: companyConfig.feature_flags.includes('LIVECHAT'),
            LINKEDIN_LINK_IMAGE_POST: companyConfig.feature_flags.includes(
              'LINKEDIN_LINK_IMAGE_POST'
            ),
            ORLO_PLUGIN: companyConfig.feature_flags.includes('ORLO_PLUGIN'),
            PREDICTIVE_TEXT:
              companyConfig.feature_flags.includes('PREDICTIVE_TEXT'),
            IS_PUBLIC_SECTOR_CLIENT: companyConfig.feature_flags.includes(
              'IS_PUBLIC_SECTOR_CLIENT'
            )
          }
        });

        window.aptrinsic(
          'identify',
          {
            // User Fields
            id: user.id, // Required for logged in app users
            email: user.email_address,
            firstName: user.first_name,
            lastName: user.last_name,
            billingPermission: user.permissions.company.billing
          },
          {
            // Account Fields
            id: user.company_id, // Required
            name: user.company_name,
            creationDate: user.created_at,
            livechat: companyConfig.feature_flags.includes('LIVECHAT'),
            insights: companyConfig.feature_flags.includes('ORLO_INSIGHTS'),
            socialListening:
              companyConfig.feature_flags.includes('SOCIAL_LISTENING'),
            isPublicSector: companyConfig.feature_flags.includes(
              'IS_PUBLIC_SECTOR_CLIENT'
            )
          }
        );
      }
    });
  }

  stateTransitionsEvents(): void {
    this.transitionService.onStart({}, (transition) => {
      const toStateData = transition.to().data;
      if (
        toStateData &&
        toStateData.permissions &&
        toStateData.permissions.company &&
        this.authService.isAuthenticated()
      ) {
        return this.userModel.getAuthUser().then((user) => {
          if (!user.hasCompanyPermission(toStateData.permissions.company)) {
            return this.redirectService.goToHomePage();
          }
        });
      }
    });

    this.transitionService.onStart(
      {
        to: 'auth.**'
      },
      (transition) => {
        const toStateData = transition.to().data;
        const isWorkflowPage = toStateData && toStateData.isWorkflowPage;

        if (this.authService.isAuthenticated() && !isWorkflowPage) {
          this.accountModel
            .findAccounts(this.workflowManagerService.getCurrentId())
            .then((accounts) => {
              if (accounts.length > 2000) {
                // too many accounts

                this.errorHandlerService.error({
                  message: this.translateService.instant(
                    'YOU_CAN_ONLY_LOAD_A_MAXIMUM_OF__MAXIMUMWORKFLOWACCOUNTS__ACCOUNTS_' +
                      'AT_ONCE_PLEASE_ORGANISE_YOUR_ACCOUNTS_INTO_A_WORKFLOW',
                    2000
                  )
                });
                this.stateService.go('auth.workflows');
              }
            });
        }
      }
    );

    const cancelListener = this.transitionService.onStart(
      {},
      (transition: Transition) => {
        cancelListener();
        if (this.authService.isAuthenticated()) {
          // doing a request to the server is the most accurate way of telling if
          // a user is logged in or not and should also prevent self DDoS if the API goes down
          return this.userModel
            .findAll({}, { autoError: false })
            .then(([authUser]) => {
              // don't return the promise, otherwise if it fails it will
              // log the user out or cause the page routing to fail
              // authUser.refreshAuthToken($auth);
            })
            .catch(() => {
              this.userModel.logout();
              // .finally(() => authService.clearJWT());
            });
        } else if (transition.to().name.startsWith('auth.')) {
          const targetState = transition.to();
          // not already trying to go to an unauthenticated page
          this.redirectService.redirectToLogin(targetState);
        }
      }
    );
  }
}
