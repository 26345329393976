import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import { Account, SocialNetwork } from '@ui-resources-angular';
import { ProfileHelperService } from '../../../../../../common/services/profile-helper/profile-helper.service';

@Component({
  selector: 'ssi-top-engagers',
  templateUrl: './top-engagers.component.html',
  styles: [],
  styleUrls: ['./top-engagers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopEngagersComponent implements OnInit {
  @Input() topEngagers: {
    account_id: string;
    author_id: string;
    count: number;
    name: string;
    username: string;
    account_type_id?: string;
    avatar?: string;
  }[];
  @Input() inboundCount: number;
  @Input() socialNetworks: Array<{
    socialNetwork: SocialNetwork;
    accounts: Account[];
    totals: { activity: number; conversation: number };
  }> = [];

  topUserInboundPercentage: number;
  topEngager: any;

  constructor(private profileHelper: ProfileHelperService) {}

  ngOnInit(): void {
    const topAuthorPostTotal = this.topEngagers.reduce(
      (runningtotal, author) => runningtotal + author.count,
      0
    );
    this.topUserInboundPercentage =
      (topAuthorPostTotal / this.inboundCount) * 100;

    this.topEngagers = this.topEngagers.map((user) => {
      const matchingNetwork = this.socialNetworks.find((network) =>
        network.accounts.some((acc) => acc.id === user.account_id)
      );

      return {
        ...user,
        networkIcon: matchingNetwork.socialNetwork.icon.web,
        avatar:
          user.avatar ||
          'https://images.socialsignin.net/avatar/avatar-outgoing.png'
      };
    });
    this.topEngager = this.topEngagers[0];
  }

  viewProfile(props: { accountId: string; profileId: string }) {
    this.profileHelper.viewProfile(props);
  }
}
