import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ManageAccountsComponent } from './manage-accounts.component';
import { TranslateModule } from '@ngx-translate/core';
import { ServicesModule } from '../../../../../common/services/services.module';
import { AlertsModule } from '../../../../../common/components/alerts/alerts.module';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '../../../../../common/directives/tooltip/tooltip.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RemoveAccountModalComponent } from './remove-account-modal/remove-account-modal.component';
import { AccountAuthService } from '../../../../../common/services/account-auth/account-auth.service';
import { AddCustomProviderModalComponent } from './add-custom-provider-modal/add-custom-provider-modal.component';
import { AddCustomBlueskyProviderModalComponent } from './add-custom-bluesky-provider-modal/add-custom-bluesky-provider-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AlertsModule,
    NgbTabsetModule.forRoot(),
    NgbModalModule.forRoot(),
    TooltipModule,
    ServicesModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageAccounts',
          url: '/manage/accounts',
          component: ManageAccountsComponent,
          data: {
            permissions: {
              company: 'administer_accounts'
            }
          },
          resolve: ManageAccountsComponent.resolve
        }
      ]
    })
  ],
  declarations: [
    ManageAccountsComponent,
    RemoveAccountModalComponent,
    AddCustomProviderModalComponent,
    AddCustomBlueskyProviderModalComponent
  ],
  entryComponents: [
    ManageAccountsComponent,
    RemoveAccountModalComponent,
    AddCustomProviderModalComponent,
    AddCustomBlueskyProviderModalComponent
  ],
  providers: [AccountAuthService]
})
export class ManageAccountsModule {}
