import { UAParser } from 'ua-parser-js';

import { Visitor as VisitorInterface } from '../../interfaces/live-chat/visitor';
import { Person } from './person';

const parser = new UAParser();

export class Visitor extends Person implements VisitorInterface {
  protected _browser: any;
  protected _data: VisitorInterface;
  protected _operatingSystem: string;
  protected _parsedUserAgent: any; // IUAParser.IResult

  public get browser() {
    return this._browser;
  }

  public get createdAt(): string {
    return this._data.createdAt || '';
  }

  public get email(): string {
    return this._data.email || '';
  }

  public get external() {
    return this._data.external;
  }

  public get isLoggedIn(): boolean {
    return !!this._data.isLoggedIn;
  }

  public get metadata() {
    return this._data.metadata;
  }

  public get operatingSystem() {
    return this._operatingSystem;
  }

  public get userAgent(): string {
    return this._data.userAgent || '';
  }

  public get userAgentLanguage(): string {
    return this._data.userAgentLanguage || '';
  }

  protected initialise() {
    try {
      super.initialise();

      if (!!this._data.userAgent) {
        parser.setUA(this._data.userAgent || '');
        this._parsedUserAgent = parser.getResult();
      }

      this._browser = !!this._data.userAgent
        ? `${this._parsedUserAgent.browser.name} ${
            this._parsedUserAgent.browser.major
          }`
        : '';

      this._operatingSystem = !!this._data.userAgent
        ? `${this._parsedUserAgent.os.name} ${this._parsedUserAgent.os.version}`
        : '';

      return this;
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}
