import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-marketing',
  templateUrl: './marketing.component.html',
  styles: [],
  styleUrls: ['./marketing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarketingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
