import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class HighchartsHelperService {
  get baseChartConfig() {
    return {
      global: {
        useUTC: false
      },
      chart: {
        defaultSeriesType: 'areaspline',
        zoomType: 'x',
        version: 2,
        plotBorderWidth: 0,
        plotBorderColor: '#efefef',
        spacingBottom: 25,
        spacingRight: 0,
        spacingLeft: 0,
        marginTop: 5,
        backgroundColor: '#fff',
        type: undefined
      },
      title: {
        align: 'left',
        style: {
          textAlign: 'left',
          color: '#333',
          fontWeight: 'normal',
          fontSize: '14px',
          margin: 0,
          padding: 0
        },
        y: 5,
        text: ''
      },
      xAxis: {
        dateTimeLabelFormats: {
          month: '%e %b',
          year: '%b'
        }
      },
      yAxis: {
        title: '',
        min: 0
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        borderWidth: 0,
        symbolPadding: 5,
        symbolWidth: 20,
        lineHeight: 16
      },
      plotOptions: {
        series: {
          stacking: false,
          point: {
            events: {
              click: ''
            }
          }
        },
        pie: {
          allowPointSelect: true,
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        },
        area: {
          stacking: 'normal',
          lineWidth: 1,
          lineColor: 'rgb(0, 0, 0, 0.3)',
          fillOpacity: 0.75,
          shadow: false,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 4,
            states: {
              hover: {
                enabled: true
              },
              select: {
                enabled: true
              }
            }
          },
          cursor: 'pointer'
        }
      },
      colors: [
        '#3d81c1',
        '#4e47c0',
        '#31ba8a',
        '#FFb343',
        '#3d81c1',
        '#4e47c0',
        '#31ba8a',
        '#FFb343',
        '#3d81c1',
        '#4e47c0',
        '#31ba8a',
        '#FFb343',
        '#3d81c1',
        '#4e47c0',
        '#31ba8a',
        '#FFb343'
      ]
    };
  }

  generateChart({
    type,
    series,
    xAxis = {},
    yAxis = {},
    title = {},
    ...rest
  }: any) {
    const options = this.baseChartConfig;
    options.chart.type = type;
    return Object.assign(
      options,
      {
        series,
        title: Object.assign({}, options.title, title),
        xAxis: Object.assign({}, options.xAxis, xAxis),
        yAxis: Object.assign({}, options.yAxis, yAxis)
      },
      rest
    );
  }

  generateWeekSummaryHeatmapChart({
    chartData,
    tooltipFormatter,
    includeEmptyValues = false
  }) {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(moment().hours(i).format('ha'));
    }

    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const seriesData = [];
    Object.keys(chartData).forEach((dayKey) => {
      const dayIndex = days.findIndex((dow) => dow.toLowerCase() === dayKey);
      if (dayIndex > -1) {
        Object.keys(chartData[dayKey]).forEach((hourIndex) => {
          const value = Math.round(chartData[dayKey][hourIndex].value);
          //   console.log('val: ', value);
          const data = chartData[dayKey][hourIndex].data;
          //   console.log('data: ', data);
          if (value > 0 || includeEmptyValues) {
            const point: {
              x: number;
              y: number;
              data: any;
              value: string | number;
              color?: string;
            } = {
              x: +hourIndex,
              y: dayIndex,
              data,
              value
            };

            if (value === 0) {
              point.value = '';
              point.color = 'white';
            }

            seriesData.push(point);
          }
        });
      }
    });

    const chartConfig = this.generateChart({
      type: 'heatmap',
      series: [
        {
          border: 1,
          data: seriesData,
          dataLabels: {
            enabled: true,
            color: 'black',
            style: {
              textOutline: false
            }
          }
        }
      ],
      xAxis: {
        categories: hours,
        min: 0,
        max: hours.length - 1
      },
      yAxis: {
        reversed: true,
        categories: days,
        min: 0,
        max: days.length - 1
      }
    });

    Object.assign(chartConfig, {
      colorAxis: {
        min: 0,
        minColor: '#FFFFFF',
        maxColor: chartConfig.colors[0]
      },
      tooltip: {
        formatter() {
          if (this.point.value) {
            return tooltipFormatter.apply(this);
          } else {
            return false;
          }
        },
        useHTML: true
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    });

    delete chartConfig.colors;

    return chartConfig;
  }

  apiIimeSeriesDataToHighcharts(apiData, seriesDuration) {
    const data = [];
    const [, bucketIntervalStep, bucketInterval] = apiData.bucket_size.match(
      /^(\d+)(\w+)$/
    );

    const valuesByDate = {};
    Object.entries(apiData.values).forEach(([dateString, amount]) => {
      valuesByDate[
        moment(dateString).startOf(bucketInterval).toString()
      ] = +amount;
    });

    const diffSize = moment(seriesDuration.end).diff(
      seriesDuration.start,
      bucketInterval
    );
    const dateCounter = moment(seriesDuration.start).startOf(bucketInterval);

    for (let i = 0; i <= diffSize; i++) {
      let value = valuesByDate[dateCounter.toString()];
      if (typeof value === 'undefined') {
        if (apiData.missing === 'previous' && i > 0) {
          value = data[i - 1][1]; // use previous value
        } else {
          value = 0;
        }
      }
      data.push([dateCounter.valueOf(), value]);
      dateCounter.add(+bucketIntervalStep, bucketInterval);
    }

    return data;
  }

  apiTimeSeriesDataToHighchartsMap(
    apiData: { [key: string]: { count: number } | number },
    period: { start: string; end: string }
  ): [number, number][] {
    const bucketSize = this.getBucketSize(Object.keys(apiData));

    const valuesByDate = {};
    Object.entries(apiData).forEach(([dateString, amount]: any) => {
      valuesByDate[moment(dateString).startOf(bucketSize).toString()] =
        +amount.count || amount;
    });

    const diffSize = moment(period.end).diff(period.start, bucketSize);
    const dateCounter = moment(period.start).startOf(bucketSize);

    const timeValuePairsData = [];
    for (let i = 0; i <= diffSize; i++) {
      let value = valuesByDate[dateCounter.toString()];
      if (typeof value === 'undefined') {
        value = 0;
      }
      timeValuePairsData.push([dateCounter.valueOf(), value]);
      dateCounter.add(1, bucketSize);
    }

    return timeValuePairsData;
  }

  getBucketSize(dates: Array<string | Date>): 'hour' | 'day' | 'month' {
    if (!Array.isArray(dates) || !dates.length) {
      // no backend data case - shoud rarely happen to customers using the platform for real
      // need this just to properly format the chart when there is no data
      return 'day';
    }

    const first10Dates = dates
      .slice(0, 10)
      .map((d) => moment(d).format('YYYY-MM-DD HH:mm:ss'));

    if (first10Dates.every((d) => d.split('-').pop() === '01 00:00:00')) {
      return 'month';
    }

    if (first10Dates.every((d) => d.split(' ').pop() === '00:00:00')) {
      return 'day';
    }

    return 'hour';
  }
}
