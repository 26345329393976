import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'ssi-advertising-boost-confirmed',
  templateUrl: './advertising-boost-confirmed.component.html',
  styles: [],
  styleUrls: ['./advertising-boost-confirmed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvertisingBoostConfirmedComponent implements OnInit {
  constructor(private state: StateService) {}

  ngOnInit() {}
}
