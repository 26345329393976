import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OrloComponentsModule } from 'orlo-components';

import { LinkifyModule } from '../../../pipes/linkify/linkify.module';
import { Nl2brModule } from '../../../pipes/nl2br/nl2br.module';

import { LiveChatNetworkStateModule } from '../live-chat-network-state/live-chat-network-state.module';

import { LiveChatMessageComponent } from './live-chat-message.component';

@NgModule({
  declarations: [LiveChatMessageComponent],

  exports: [LiveChatMessageComponent],

  imports: [
    CommonModule,
    LinkifyModule,
    LiveChatNetworkStateModule,
    Nl2brModule,
    TranslateModule,
    OrloComponentsModule
  ]
})
export class LiveChatMessageModule {}
