import { Component, OnInit, Input } from '@angular/core';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { authUser } from '../../../../common-resolves';
import { decodeStateParams } from '@orlo/common/utils';

@Component({
  selector: 'ssi-manage-team',
  templateUrl: './manage-team.component.html',
  styleUrls: []
})
export class ManageTeamComponent implements OnInit {
  static resolve = [authUser];

  @Input() authUser: any;
  settings: any;
  userCache: any;
  memberUsers;
  selectedUser;
  selectedUserIndex;
  userSearchText = '';
  searchText = '';
  decodedStateParams: { [key: string]: any };

  constructor(private api: API, private state: StateService) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    this.api
      .get('settings/groupPermission', {
        params: { group_id: this.decodedStateParams.teamId }
      })
      .then(({ data }) => {
        this.settings = data;
        this.userCache = data.users;
        this.memberUsers = data.users
          .filter((user) => user.is_active && user.is_member)
          .sort((a, b) =>
            a.first_name !== b.first_name
              ? a.first_name < b.first_name
                ? -1
                : 1
              : 0
          );
      });
  }

  filterUsers() {
    const userSearchTerm = this.userSearchText.toUpperCase();
    this.settings.users = this.userCache.filter((user) => {
      const userKeywords = [
        user.first_name ? user.first_name.toUpperCase() : '',
        user.last_name ? user.last_name.toUpperCase() : ''
      ].join(' ');
      return userKeywords.includes(userSearchTerm);
    });
  }

  togglePermission(params) {
    return this.api.post(
      'settings/groupPermission',
      Object.assign({}, params, { group_id: this.decodedStateParams.teamId })
    );
  }

  groupToggleUser(user, isMember) {
    this.api
      .post('settings/groupPermission', {
        user_id: user.id,
        group_id: this.decodedStateParams.teamId
      })
      .then(() => {
        this.settings.users.find((iUser) => iUser.id === user.id).is_member =
          isMember;
        this.selectedUser = undefined;
        this.selectedUserIndex = undefined;
        if (isMember) {
          this.memberUsers.push(user);
          return;
        }
        this.memberUsers = this.memberUsers.filter(
          (iUser) => iUser.id !== user.id
        );
      });
  }
}
