import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmLeaveModalComponent } from './confirm-leave-modal.component';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';
import { DropdownSelectModule } from '../dropdown-select/dropdown-select.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlatpickrModule,
    OrloComponentsModule,
    DropdownSelectModule,
    TranslateModule
  ],
  declarations: [ConfirmLeaveModalComponent],
  exports: [ConfirmLeaveModalComponent],
  entryComponents: [ConfirmLeaveModalComponent],
  providers: []
})
export class ConfirmLeaveModalModule {}
