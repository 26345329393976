import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';
import { Influencer } from '@orlo/common/components/influencers-list/influencers-list.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-modal-list',
  templateUrl: './modal-list.component.html',
  styles: [],
  styleUrls: ['./modal-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalListComponent {
  @Input() title: string;
  @Input() authorsByFollowers: Influencer[];
  @Input() authorsByEngagements: Influencer[];
  @Input() drillDownParams;

  constructor(public modalInstance: NgbActiveModal) {}
}
