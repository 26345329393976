import {
  Directive,
  Input,
  HostListener,
  ElementRef,
  Renderer2,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';

export const imgMap = {
  avatar: 'avatar.png',
  avatarRefresh: 'avatar-refresh-512px.png',
  avatarIncoming: 'avatar-incoming.png',
  avatarOutgoing: 'avatar-outgoing.png',
  brokenImage: 'broken-image-512px.png',
  brokenImageNew: 'broken-image-new-512px.png',
  brokenImageNew512x340: 'broken-image-new-512x340.png',
  activityMedia: 'activity-media.png',
  userAvatarDefault: 'user-avatar-default.png',
  mediaSourceAvatarDefault: 'media-source-avatar.png'
};

@Directive({
  selector: '[ssiFallbackImage]'
})
export class FallbackImageDirective implements OnChanges {
  @Input() imageSrc: string; // can't watch attributes

  @Input('ssiFallbackImage') fallbackSrc: string;

  @Output() fallbackImageSet = new EventEmitter<void>();

  constructor(private elm: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imageSrc) {
      if (!this.imageSrc) {
        this.setFallbackSrc();
      } else {
        this.renderer.setAttribute(
          this.elm.nativeElement,
          'src',
          this.imageSrc
        );
      }
    }
  }

  @HostListener('error')
  onError(): void {
    this.setFallbackSrc();
  }

  private setFallbackSrc(): void {
    const fallbackSrc = '/assets/images/fallback/' + imgMap[this.fallbackSrc] || this.fallbackSrc;
    this.renderer.setAttribute(this.elm.nativeElement, 'src', fallbackSrc);
    this.fallbackImageSet.emit();
  }
}
