import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';

import { Agent } from '@orlo/library/models/live-chat/agent';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { ConversationUserEvent } from '@orlo/library/models/live-chat/conversation-user-event';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'ssi-live-chat-conversation-indicators',
  templateUrl: './live-chat-conversation-indicators.component.html',
  styles: [],
  styleUrls: ['./live-chat-conversation-indicators.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatConversationIndicatorsComponent implements OnChanges {
  @Input() conversationEvents: ConversationUserEvent;
  @ViewChild('visitorTypingTemplate') visitorTypingTemplate: ElementRef;

  private _areTypingAgentsVisible = false;
  private _areViewingAgentsVisible = false;
  private _typingAgents: Agent[];
  private _viewingAgents: Agent[];

  constructor(private liveChatService: LiveChatService) {}

  public get areActiveEvents(): boolean {
    return (
      !!this.areMultipleAgentsTyping ||
      !!this.areMultipleAgentsViewing ||
      !!this.isSingleAgentTyping ||
      !!this.isSingleAgentViewing
    );
  }

  public get areAgentsTyping(): boolean {
    return !!this.typingAgents.length;
  }

  public get areAgentsViewing(): boolean {
    return !!this.viewingAgents.length;
  }

  public get areMultipleAgentsTyping(): boolean {
    return this.areAgentsTyping && this.typingAgents.length > 1;
  }

  public get areMultipleAgentsViewing(): boolean {
    return this.areAgentsViewing && this.viewingAgents.length > 1;
  }

  public get areTypingAgentsVisible(): boolean {
    return this._areTypingAgentsVisible;
  }

  public get areViewingAgentsVisible(): boolean {
    return this._areViewingAgentsVisible;
  }

  public get isSingleAgentTyping(): boolean {
    return !!this.areAgentsTyping && this.typingAgents.length === 1;
  }

  public get isSingleAgentViewing(): boolean {
    return !!this.areAgentsViewing && this.viewingAgents.length === 1;
  }

  public get isVisitorTyping(): boolean {
    return this.liveChatService.activeConversationEvents.isVisitorTyping;
  }

  public get typingAgents(): Agent[] {
    return this._typingAgents;
  }

  public get viewingAgents(): Agent[] {
    return this._viewingAgents;
  }

  public get visitorAvatar(): string {
    return this.liveChatService.activeVisitor.avatar;
  }

  ngOnChanges(changes) {
    if (
      !!changes.conversationEvents &&
      !!changes.conversationEvents.currentValue
    ) {
      const currentVersion = changes.conversationEvents
        .currentValue as ConversationUserEvent;

      if (
        !changes.conversationEvents.previousValue ||
        currentVersion !== changes.conversationEvents.previousVersion
      ) {
        const typingAgents = [];
        const viewingAgents = [];

        Object.keys(currentVersion.items.agents).forEach((agentId) => {
          if (agentId === this.liveChatService.agentModel.id) {
            return;
          }

          const model = this.liveChatService.agents.get(agentId);

          if (!!currentVersion.items.agents[agentId].lastTypedAt) {
            typingAgents.push(model);
          }

          if (!!currentVersion.items.agents[agentId].lastViewedAt) {
            viewingAgents.push(model);
          }
        });

        /*
        // Test data for easier testing of multiple agents.
        // Can be deleted whenever.

        const testAgent1 = {
          avatar: 'https://images.socialsignin.net/avatar/default_user.png',
          displayName: 'test 1'
        };

        const testAgent2 = {
          avatar: 'https://images.socialsignin.net/avatar/default_user.png',
          displayName: 'test 2'
        };

        const testAgent3 = {
          avatar: 'https://images.socialsignin.net/avatar/default_user.png',
          displayName: 'test 3'
        };

        const testAgent4 = {
          avatar: 'https://images.socialsignin.net/avatar/default_user.png',
          displayName: 'test 4'
        };

        typingAgents.push(testAgent1);
        typingAgents.push(testAgent2);
        typingAgents.push(testAgent3);
        typingAgents.push(testAgent4);

        viewingAgents.push(testAgent1);
        viewingAgents.push(testAgent2);
        viewingAgents.push(testAgent3);
        viewingAgents.push(testAgent4);
        */

        this._typingAgents = typingAgents;
        this._viewingAgents = viewingAgents;
      }
    }
  }

  toggleVisibilityOfTypingAgents() {
    this._areTypingAgentsVisible = !this._areTypingAgentsVisible;
  }

  toggleVisibilityOfViewingAgents() {
    this._areViewingAgentsVisible = !this._areViewingAgentsVisible;
  }
}
