<div class="insights-widget insights-widget-swot">
  <div class="insights-swot-wrapper">
    <ng-container *ngIf="loading">
      <div class="insights-swot-loading">
        <img src="assets/images/loaders/loading.png" alt="loading spinner">
        <h2>Hang tight</h2>
        <p>I'm searching for themes
          <ng-container *ngIf="(loading | typeof) === 'string'"> across 27,000 results, phew!</ng-container>
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <ssi-highchart [config]="swotConfig" [(updateFlag)]="updateFlag"></ssi-highchart>
    </ng-container>
  </div>
</div>