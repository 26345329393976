import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatAvatarModule } from '../live-chat-avatar/live-chat-avatar.module';
import { LiveChatNetworkStateComponent } from './live-chat-network-state.component';

@NgModule({
  declarations: [LiveChatNetworkStateComponent],

  exports: [LiveChatNetworkStateComponent],

  imports: [
    CommonModule,
    LiveChatAvatarModule,
    NgbDropdownModule.forRoot(),
    TranslateModule
  ]
})
export class LiveChatNetworkStateModule {}
