import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { OrloComponentsModule } from 'orlo-components';
import { ActionPadComponent } from './action-pad.component';

import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { InboxLanguageSelectorModule } from '../inbox-language-selector/inbox-language-selector.module';
import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { ConversationResolveModule } from '../conversation-resolve/conversation-resolve.module';
import { ConversationHoldModule } from '../conversation-hold/conversation-hold.module';
import { FallbackImageModule } from '../../directives/fallback-image/fallback-image.module';
import { InboxPriorityModule } from '../inbox-priority/inbox-priority.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AssignMessageModule } from '../assign-message/assign-message.module';
import { AssignMessageComponent } from '../assign-message/assign-message.component';
import { EmailToModalModule } from '../email-to-modal/email-to-modal.module';
import { EmailToModalComponent } from '../email-to-modal/email-to-modal.component';
import { ExportConversationComponent } from '../export-conversation/export-conversation.component';
import { ExportConversationModule } from '../export-conversation/export-conversation.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    OrloComponentsModule,
    TooltipModule,
    InboxLanguageSelectorModule,
    DropdownSelect2Module,
    OffClickModule,
    ConversationResolveModule,
    ConversationHoldModule,
    FallbackImageModule,
    InboxPriorityModule,
    NgbDropdownModule,
    EmailToModalModule,
    AssignMessageModule,
    ExportConversationModule
  ],
  declarations: [ActionPadComponent],
  exports: [ActionPadComponent],
  entryComponents: [
    AssignMessageComponent,
    EmailToModalComponent,
    ExportConversationComponent
  ]
})
export class ActionPadModule {}
