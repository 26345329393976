import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { API } from '@ui-resources-angular';
import { TeamsService } from '@orlo/common/services/api';
import { PopupService } from '@orlo/common/services/popup/popup.service';

export async function teamsFn(teams: TeamsService) {
  return teams.getAll();
}

@Component({
  selector: 'ssi-manage-teams',
  templateUrl: './manage-teams.component.html',
  styleUrls: []
})
export class ManageTeamsComponent implements OnInit {
  static resolve = [
    {
      token: 'teams',
      resolveFn: teamsFn,
      deps: [TeamsService]
    }
  ];

  @Input() teams: any;
  teamsCache: any;
  teamEditing: any;
  newTeam: any;
  searchText: string;
  trackById(index, choice) {
    return choice.id;
  }

  constructor(
    private api: API,
    private translate: TranslateService,
    private teamsService: TeamsService,
    private popup: PopupService
  ) {}

  ngOnInit() {
    this.teamsCache = this.teams;
  }

  editTeam(team) {
    this.teamEditing = Object.assign({}, team);
  }

  updateTeamsTable() {
    const searchTerm = this.searchText.toUpperCase();
    this.teams = this.teamsCache.filter((team) => {
      const teamKeywords = [
        team.name ? team.name.toUpperCase() : '',
        team.description ? team.description.toUpperCase() : ''
      ].join(' ');
      return teamKeywords.includes(searchTerm);
    });
  }

  async saveTeam(team, changes = {}) {
    const responseTeam = await this.teamsService.createOrUpdate(
      Object.assign({}, team, changes)
    );
    this.teams = await this.teamsService.getAll({ refreshStore: true });

    this.teamEditing = null;
    this.newTeam = null;
  }

  async deletePopup(team) {
    const confirmDelete = await this.popup.confirm({
      title: this.translate.instant('DELETE_GROUP'),
      message: this.translate.instant(
        'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GROUP'
      ),
      okText: this.translate.instant('DELETE')
    });
    if (confirmDelete) {
      await this.deleteTeam(team);
    }
  }

  async deleteTeam(team) {
    const response = await this.teamsService.delete(team.id);
    this.teams = await this.teamsService.getAll({ refreshStore: true });
  }
}
