/* tslint:disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { isEqual } from 'lodash-es';

import { KeyValueObject } from '../../util';

const isNotSet = (value) => !value;

const baseDateTimeFilter = {
  type: 'datetime',
  isDefault: isNotSet,
  serialize(value) {
    return value ? value.toISOString() : value;
  },
  deserialize(value) {
    return value ? new Date(value) : value;
  }
};

export interface QueryFilterOption<ValueType = any> {
  label: string;
  value: ValueType;
  trackBy?: string | number;
  iconClass?: string;
}

export interface QueryFilter {
  key: string;
  type: string;
  defaultValue: any;
  translateLabel: string;
  icon: string;
  options?: QueryFilterOption[];
  selectedText(value: any): string;
  isDefault(value: any): boolean;
  serialize?(value: any): any;
  deserialize?(value: any): any;
}

export interface QueryPreset {
  key: string;
  label: string;
  icon?: string;
  query: KeyValueObject;
  id?: string;
}

export abstract class AbstractQuery {
  filters: QueryFilter[] = [];
  presets: QueryPreset[] = [];
  customPresets: QueryPreset[] = [];
  params: KeyValueObject = {};
  queryState: {
    activeFilters?: QueryFilter[];
    activeFiltersText?: KeyValueObject<string>;
    activePreset?: QueryPreset;
    activePresetIsCustom?: boolean;
  } = {};
  apiQuery: KeyValueObject;

  serializeValue(filter, value) {
    return filter.serialize ? filter.serialize(value) : value;
  }

  deserializeValue(filter, value) {
    return filter.deserialize ? filter.deserialize(value) : value;
  }

  abstract search(offset?: number, limit?: number, queryOverrides?: any): void;

  get defaultQuery() {
    const query = {};
    const inboxQuery = this;
    this.filters.forEach((filter) => {
      query[filter.key] = inboxQuery.serializeValue(
        filter,
        filter.defaultValue
      );
    });
    return query;
  }

  reset() {
    this.apiQuery = this.defaultQuery;
    this.updateQueryState();
  }

  activatePreset(preset: Partial<QueryPreset>, isSocialPushMode?: boolean) {
    this.reset();
    const workflowSelected = !!localStorage.getItem('ls.currentWorkflowId');
    const presetHasAccounts =
      (preset.query.hasOwnProperty('ac') &&
        Object.keys(preset.query.ac).length > 0) ||
      (preset.query.hasOwnProperty('account_ids') &&
        Object.keys(preset.query.account_ids).length > 0);

    const workflowAccountsIds: any[] =
      workflowSelected && !isSocialPushMode
        ? JSON.parse(localStorage.getItem('workflowAccountIds'))
        : [];

    const presetAccountsIds: any[] = presetHasAccounts
      ? (preset.query.ac && Object.keys(preset.query.ac)) ||
        preset.query.account_ids
      : [];

    let accountsToQuery =
      workflowSelected && presetHasAccounts
        ? workflowAccountsIds.filter((x) =>
            presetAccountsIds.some((y) => y === x)
          )
        : presetAccountsIds.length
        ? presetAccountsIds
        : workflowAccountsIds;

    if (accountsToQuery.length === 0) {
      accountsToQuery = presetAccountsIds;
    }

    const accountsObj: {} = accountsToQuery.reduce(
      (obj, acc) => ({ ...obj, [acc]: '1' }),
      {}
    );

    if (preset.query.ac || preset.query.account_ids) {
      preset.query.ac = accountsObj;
      preset.query.account_ids = accountsToQuery;
    }

    Object.assign(this.apiQuery, preset.query);
    this.search();
  }

  updateQueryState() {
    try {
      const getQueryValue = (filter) =>
        this.deserializeValue(filter, this.apiQuery[filter.key]);

      const isPresetActive = (preset) =>
        isEqual(
          this.apiQuery,
          Object.assign(this.defaultQuery, preset.query)
        );

      const activeFilters = this.filters.filter((filter) => {
        return !filter.isDefault(getQueryValue(filter));
      });

      if (!(!!this.presets && Array.isArray(this.presets))) {
        throw new Error(
          `Value for 'query helper presets' not in expected format.`
        );
      }

      let activePreset = this.presets.find((preset) => isPresetActive(preset));
      let activePresetIsCustom = false;

      if (!activePreset) {
        if (!(!!this.presets && Array.isArray(this.presets))) {
          throw new Error(
            `Value for 'query helper custom presets' not in expected format.`
          );
        }

        activePreset = this.customPresets.find((preset) =>
          isPresetActive(preset)
        );
        activePresetIsCustom = !!activePreset;
      }

      const activeFiltersText = {};
      activeFilters.forEach((filter) => {
        activeFiltersText[filter.key] = filter.selectedText(
          getQueryValue(filter)
        );
      });

      this.queryState = Object.freeze({
        activeFilters,
        activeFiltersText,
        activePreset,
        activePresetIsCustom
      });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}

@Injectable()
export class AbstractQueryHelperService {
  constructor(private translate: TranslateService) {}

  getAccountsFilter(extra) {
    const translate = this.translate;
    return Object.assign(
      {
        type: 'accounts',
        get defaultValue() {
          return this.allAccounts.map((account) => account.id);
        },
        isDefault(value) {
          return value.length === this.defaultValue.length;
        },
        translateLabel: 'ACCOUNTS',
        icon: 'fa-folder',
        selectedText(value) {
          return translate.instant('_TOTAL__ACCOUNTS', {
            total: value.length
          });
        }
      },
      extra
    );
  }

  getFromDateTimeFilter(extra) {
    const translate = this.translate;
    return Object.assign(
      {},
      baseDateTimeFilter,
      {
        translateLabel: 'FROM_DATE',
        icon: 'fa-chevron-right',
        selectedText(value) {
          return translate.instant('SENT_AFTER__DATE_', {
            date: moment(value).format('Do MMM YYYY, hh:mm a')
          });
        }
      },
      extra
    );
  }

  getToDateTimeFilter(extra) {
    const translate = this.translate;
    return Object.assign(
      {},
      baseDateTimeFilter,
      {
        translateLabel: 'TO_DATE',
        icon: 'fa-chevron-left',
        selectedText(value) {
          return translate.instant('SENT_BEFORE__DATE_', {
            date: moment(value).format('Do MMM YYYY, hh:mm a')
          });
        }
      },
      extra
    );
  }

  getTextFilter(extra) {
    const translate = this.translate;
    return Object.assign(
      {
        type: 'text',
        isDefault: isNotSet,
        translateLabel: 'KEYWORDS',
        icon: 'fa-font',
        selectedText(keywords) {
          return translate.instant('KEYWORDS__KEYWORDS_', { keywords });
        }
      },
      extra
    );
  }
}
