import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { Account } from '@ui-resources-angular';
import { isNumeric, mapToIterable } from '../../../../../../common/utils';

import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';

@Component({
  selector: 'ssi-breakdown',
  templateUrl: './breakdown.component.html',
  styles: [],
  styleUrls: ['./breakdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreakdownComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() stats: {
    totalsByAccount: {
      account: Account;
      count: number;
    }[];
    allTotals: { count: number };
    totalsByAccountType: any;
    totalsByUser: any;
    validationsTagNames: string[];
    validationsTagsTotals: { name: string; count: number }[];
  };
  dataTitle: string;
  dataProp: string;
  dataToDisplay: any[] = [];

  showTypes = [
    { id: 'accounts', label: 'Account totals' },
    { id: 'networks', label: 'Network totals' },
    { id: 'user', label: 'User totals' }
  ];
  selectedShowType = this.showTypes[0];

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  constructor() {}

  ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return this.stats.totalsByAccount.some(
        (a) => a.account.account_type_id === n.key
      );
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);
    this.selectedNetwork = this.selectableNetworks[0];

    this.setDataToDisplay(this.selectedShowType);
  }

  setDataToDisplay(showType): void {
    switch (showType) {
      case this.showTypes[0]:
        this.dataToDisplay = this.stats.totalsByAccount;
        this.dataProp = 'account.name';
        this.dataTitle = 'ACCOUNT';
        break;
      case this.showTypes[1]:
        this.dataToDisplay = mapToIterable(this.stats.totalsByAccountType);
        this.dataProp = 'account.account_type_name';
        this.dataTitle = 'NETWORK';
        break;
      case this.showTypes[2]:
        this.dataToDisplay = this.stats.totalsByUser;
        this.dataProp = 'user.name';
        this.dataTitle = 'USER';
        break;
      default:
        break;
    }

    if (this.selectedNetwork.key !== 'all') {
      this.dataToDisplay = this.dataToDisplay.filter(
        (x) => x.account.account_type_id === this.selectedNetwork.key
      );
    }
  }

  onNetworkSelect(sn: NetworkSelectItem): void {
    this.selectedNetwork = sn;
    this.setDataToDisplay(this.selectedShowType);
  }

  onShowTypeChange(showType: any): void {
    this.selectedShowType = showType;
    this.setDataToDisplay(this.selectedShowType);
  }

  splitColumnFieldValue(field: string): string {
    return field.split('.')[1];
  }

  isNumber(value: any): boolean {
    return isNumeric(value);
  }
}
