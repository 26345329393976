import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Account,
  ActivityStatus,
  ActivityThread,
  Conversation,
  ConversationStatus
} from '@ui-resources-angular';
import { mapToIterable } from '../../utils';
import { StorageService } from '../../services/storage';
import { SurveyService } from '../../services/api/surveys';
import {
  DropdownSelect2Component,
  Option
} from '../dropdown-select-2/dropdown-select-2.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { CompanyService } from '../../services/company/company.service';
import { NotificationService } from '@orlo/common/services/notification/notification.service';

@Component({
  selector: 'ssi-conversation-resolve',
  templateUrl: './conversation-resolve.component.html',
  styles: [],
  styleUrls: ['./conversation-resolve.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConversationResolveComponent implements OnInit, OnChanges {
  @Input() conversation: Conversation;
  @Input() activityThread: ActivityThread;
  @Input() replyAccount: Account;
  @Input() isPrivateThread = false;
  @Input() sessionInvalid = false;
  @Input() canReply = false;
  @Input() iconOnly = false;
  @Input() iconOnlyLabel = false;
  @Input() menuPlacementY = 'top';
  @Input() menuPlacementX = 'left';

  @Output() onResolve = new EventEmitter<boolean>();

  @ViewChild(DropdownSelect2Component)
  resolveOptionsMenu: DropdownSelect2Component;

  resolveOptions: { [key: string]: Option } = {
    withSurvey: {
      key: 'withSurvey',
      label: 'Resolve with survey'
    },
    withoutSurvey: {
      key: 'withoutSurvey',
      label: 'Resolve only'
    }
  };
  resolveOptionsIterable: Option[] = mapToIterable(this.resolveOptions);
  selectedResolveOption: Option;

  ConversationStatus = ConversationStatus;
  showSurveyOptions = false;
  resolveWithTags = false;
  sendAutomatically = false;
  tooltip = '';

  constructor(
    protected modal: NgbModal,
    protected surveyService: SurveyService,
    protected translateService: TranslateService,
    protected storageService: StorageService,
    protected company: CompanyService,
    protected notificationService: NotificationService
  ) {}

  async ngOnInit() {
    this.tooltip = this.getTooltip();

    this.company
      .hasFeatureAccess('CONVERSATIONS_RESOLVE_WITH_TAGS')
      .then((resolveWithTags) => (this.resolveWithTags = !!resolveWithTags));
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['sessionInvalid']) {
      await this.surveyService
        .hasSendAutomatically()
        .then((sendAutomatically): void => {
          this.sendAutomatically = sendAutomatically ? true : false;
        });

      if (
        !this.sendAutomatically &&
        this.isPrivateThread &&
        !this.sessionInvalid &&
        this.replyAccount
      ) {
        // no surveys for public threads, or if the session has timed out
        await this.surveyService
          .hasSurveysEnabled(this.replyAccount.id)
          .then((enabled) => {
            this.showSurveyOptions = enabled ? true : false;
          });
      }
    }

    this.tooltip = this.getTooltip();
  }

  onResolveOptionChange(resolveOption: Option): void {
    this.resolve(resolveOption);
  }

  resolveClick(): void {
    if (this.conversation.resolved) {
      this.showErrorSnackbar('Conversation has already been resolved');
      return;
    }

    // if (
    //   this.conversation.status === ConversationStatus.Unread ||
    //   this.conversation.status === ConversationStatus.Unactioned
    // ) {
    //   this.showErrorSnackbar(
    //     'There are messages in this conversation that need to be actioned, before it can be resolved.'
    //   );
    //   return;
    // }

    if (this.resolveWithTags && !this.activityThread.thread.current.tags) {
      this.showErrorSnackbar(
        'Cannot resolve conversation until a tag is applied'
      );
      return;
    }

    this.showSurveyOptions ? this.resolveOptionsMenu.toggle() : this.resolve();
  }

  showErrorSnackbar(message: string): void {
    this.notificationService.open(
      message,
      {
        class: 'ssi ssi-error',
        color: '#be3609'
      },
      4000
    );
  }

  async resolve(resolveOption?: Option): Promise<void> {
    // default 'withSurvey' flag to 'true' except user explicitly selects 'without survey' option on UI - backend will
    // not try to send a survey if it's e.g. public convo or surveys aren't enabled for account
    // (it's safer that way since surveyConfig is being cached locally)

    let withSurvey = true;

    if (!this.sendAutomatically) {
      withSurvey = resolveOption
        ? resolveOption === this.resolveOptions.withSurvey
        : false;

      // TODO: should appear after we get success response from backend?
      const preventConfirmation = this.storageService.get(
        'orlo-prevent-resolve-confirmation'
      );
      if (!preventConfirmation) {
        const confirmationModal = this.modal.open(ConfirmationModalComponent, {
          windowClass: 'orlo-modal'
        });
        confirmationModal.componentInstance.negativeConfirmation = true;
        confirmationModal.componentInstance.icon = 'ssi ssi-resolve-convo';
        confirmationModal.componentInstance.title = !withSurvey
          ? 'Way to go, all resolved!'
          : 'Conversation has been resolved & survey has been scheduled!';
        confirmationModal.componentInstance.info = !withSurvey
          ? 'This conversation has been resolved and will be tucked safely away in your resolved folder if you need it.'
          : 'Please remember that this account can only receive one survey during a 24-hour period';
        confirmationModal.componentInstance.cancelButton = `Don't show again`;
        confirmationModal.componentInstance.confirmButton = `Okay great!`;
        confirmationModal.result.then((allowConfirmation) => {
          if (!allowConfirmation) {
            this.storageService.set('orlo-prevent-resolve-confirmation', true);
          }
        });
      }
    }

    console.log('Emitting onResolve event with', withSurvey);
    this.onResolve.emit(withSurvey);

    this.bulkActionOnResolve();
  }

  bulkActionOnResolve() {
    const activities = [
      this.activityThread.thread.current,
      ...this.activityThread.thread.children.activities,
      ...this.activityThread.thread.siblings.newer.activities,
      ...this.activityThread.thread.siblings.older.activities,
      ...(this.activityThread.thread.children.hiddenActivities || []),
      ...(this.activityThread.thread.siblings.newer.hiddenActivities || []),
      ...(this.activityThread.thread.siblings.older.hiddenActivities || [])
    ];

    activities
      .filter(
        (activity) =>
          activity.inbox.requires_action &&
          activity.statusText !== ActivityStatus.Actioned
      )
      .forEach((activity) => {
        activity.changeStatus(ActivityStatus.Actioned);
      });
  }

  getTooltip(): string {
    if (
      this.resolveWithTags &&
      this.activityThread &&
      !this.activityThread.thread.current.tags
    ) {
      return 'Cannot resolve conversation until a tag is applied';
    }

    if (this.conversation.resolved && this.conversation.resolved_by) {
      return `${this.translateService.instant('RESOLVED_BY')} ${
        this.conversation.resolvedByUser.fullName
      } in ${
        this.conversation.time_to_resolve > 3600
          ? Math.floor(this.conversation.time_to_resolve / 60 / 60) + ' hours'
          : Math.floor(this.conversation.time_to_resolve / 60) + ' mins'
      }`;
    }

    return this.translateService.instant('RESOLVE_CONVERSATION');
  }
}
