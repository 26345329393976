import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { decodeStateParams } from '@orlo/common/utils';
import { DashboardService } from '../../../common/services/api/dashboard';
import { ProfileModel } from '@ui-resources-angular';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewProfileModalComponent} from '@orlo/common/components/view-profile-modal/view-profile-modal.component';
import {ProfileHelperService} from '@orlo/common/services/profile-helper/profile-helper.service';

export function isOnValidDashboardResolveFn(state: StateService) {
  const decodedStateParams = decodeStateParams(state.params);
  const alreadyOnValidDashboard = decodedStateParams && decodedStateParams.id;
  console.log('decodedStateParams:', decodedStateParams);
  return alreadyOnValidDashboard;
}

export const isOnValidDashboard = {
  token: 'isOnValidDashboard',
  resolveFn: isOnValidDashboardResolveFn,
  deps: [StateService]
};

@Component({
  selector: 'ssi-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [],
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {
  static resolve = [isOnValidDashboard];

  @Input() isOnValidDashboard: boolean;

  protected destroyed$ = new Subject<void>();

  decodedStateParams: { [key: string]: any };

  constructor(
    protected state: StateService,
    protected dashboardService: DashboardService,
    protected profileModel: ProfileModel,
    protected profileHelper: ProfileHelperService
  ) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    this.dashboardService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((dashboards) => {
        const alreadyOnValidDashboard =
          this.decodedStateParams &&
          this.decodedStateParams.id &&
          dashboards.some((d) => d.id === this.decodedStateParams.id);
        console.log('alreadyOnValidDashboard:', alreadyOnValidDashboard);
        if (alreadyOnValidDashboard) {
          return;
        } else {
          if (dashboards.length) {
            this.state.go('auth.dashboard.view', {
              id: dashboards[dashboards.length - 1].id
            });
          } else {
            this.state.go('auth.dashboard.create');
          }
        }
      });

    this.handleOpenNoteFromEmailRedirect();
  }

  async handleOpenNoteFromEmailRedirect() {
    if (
      this.decodedStateParams.accountId &&
      this.decodedStateParams.profileId &&
      this.decodedStateParams.noteId
    ) {
      await this.profileHelper.viewProfile({
        accountId: this.decodedStateParams.accountId,
        profileId: this.decodedStateParams.profileId,
        noteId: this.decodedStateParams.noteId
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
