import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { deepValue } from '@jsier/deep-value';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PageChangeParams,
  PaginatorComponent
} from '../../../../../common/components/paginator';
import {
  DetectedTheme,
  DetectedThemesResponse
} from '../../../../../common/services/api';

@Component({
  selector: 'ssi-detected-themes',
  templateUrl: './detected-themes.component.html',
  styles: [],
  styleUrls: ['./detected-themes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetectedThemesComponent implements OnInit, OnChanges {
  @Input() detectedThemesResponse: DetectedThemesResponse;
  @Input() itemsPerPage: number;
  @Input() itemsCount: number;
  @Input() loading = false;

  @Output() pageChange = new EventEmitter<PageChangeParams>();
  @Output() reloadResults = new EventEmitter<void>();
  @Output() openDrilldownModal = new EventEmitter<DetectedTheme>();

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  refreshDisabled = false;
  deepValue = deepValue;

  constructor(protected modal: NgbModal) {}

  ngOnChanges() {}

  ngOnInit() {}

  onPageChange(params: PageChangeParams): void {
    this.pageChange.emit(params);
  }

  onRefresh(): void {
    this.reloadResults.emit();

    this.refreshDisabled = true;
    setTimeout(() => {
      this.refreshDisabled = false;
    }, 3000);
  }
}
