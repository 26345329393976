import { Component, OnInit } from '@angular/core';

import { AccountModel } from '@orlo/library/ui-resources/modules/exports';
import { accountsResolveFn } from '@orlo/modules/common-resolves';
import { allAccountsResolveFn } from './workflows/edit-workflows/edit-workflows.component';
import { WorkflowManagerService } from '@orlo/common/services/workflow-manager/workflow-manager.service';

@Component({
  selector: 'ssi-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  static resolve = [
    {
      token: 'accounts', // need this resolver in order to preload accounts before startting rendering auth states
      resolveFn: accountsResolveFn,
      deps: [AccountModel]
    }
  ];

  constructor(protected workflowManager: WorkflowManagerService) {}

  ngOnInit() {
    this.workflowManager.loadWorkflow(this.workflowManager.getCurrentId() || 0);
  }
}
