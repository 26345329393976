import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Agent } from '@orlo/library/interfaces/live-chat/agent';
import { Agent as AgentModel } from '@orlo/library/models/live-chat/agent';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

@Component({
  selector: 'ssi-live-chat-tally-tile',
  templateUrl: './live-chat-tally-tile.component.html',
  styles: [],
  styleUrls: ['./live-chat-tally-tile.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatTallyTileComponent {
  @Input() ageOfNewestAssignedConversation: string;
  @Input() ageOfNewestUnassignedConversation: string;
  @Input() quantityOfOngoingConversations: number;
  @Input() quantityOfOwnedConversations: number;
  @Input() quantityOfUnassignedConversations: number;
  @Input() hasConversationPushModeEnabled: boolean = false;
  @Output() isLiveChatActiveChange = new EventEmitter();

  constructor(private liveChatService: LiveChatService) {}

  public get agentAvatar() {
    return this.agent ? this.agent.avatar : '';
  }

  public get agent() {
    return this.liveChatService.agentModel;
  }

  public get isAgentOnline() {
    return this.agent ? this.agent.isOnline : '';
  }

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public updateFilter(value) {
    this.liveChatService.activeFilter = value;
    this.isLiveChatActiveChange.emit(true);
  }
}
