import { Input, Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Outbox, OutboxMessageType } from '@ui-resources-angular';
import { AccountTypeId, AccountTypeIdString } from '../../../enums';

@Component({
  selector: 'ssi-outbox-post-stats',
  templateUrl: './outbox-post-stats.component.html'
})
export class OutboxPostStatsComponent implements OnInit {
  @Input() post: Outbox;
  @Input() clicks: number;

  @Output() onShowNotes = new EventEmitter();

  OutboxMessageType = OutboxMessageType;
  showReach = false;
  reachTooltip = '';
  accountTypeId = AccountTypeIdString;

  constructor() {}

  ngOnInit() {
    this.showReach =
      (
        (
          this.post.account.account_type_id === AccountTypeId.LinkedIn.toString() ||
          this.post.account.account_type_id === AccountTypeId.LinkedInPersonal.toString()
        ) && this.post.type === OutboxMessageType.Video
      ) || this.post.account.socialNetwork.statistics.reach.outbox;

    this.reachTooltip = this.getReachTooltip();
  }

  getReachTooltip(): string {
    if (this.post.account.socialNetwork.statistics.reach.outbox) {
      return 'TOTAL_REACH';
    } else {
      return this.post.account.account_type_id === String(AccountTypeId.Twitter)
        ? 'X_DOESNT_PROVIDE_THIS_FIGURE_ON_THE_NATIVE_PLATFORM_AND_THEREFORE_WERE_UNABLE_TO_PROVIDE_THIS_INSIGHT'
        : (
          this.post.account.account_type_id === String(AccountTypeId.LinkedIn) ||
          this.post.account.account_type_id === String(AccountTypeId.LinkedInPersonal)
        )
        ? 'THIS_SOCIAL_NETWORK_PROVIDES_REACH_FROM_VIDEO_POSTS_ONLY'
        : 'THE_SOCIAL_NETWORK_DOES_NOT_PROVIDE_US_WITH_THE_TOTAL_REACH_FOR_INDIVIDUAL_POSTS';
    }
  }
}
