import { ViewEncapsulation } from '@angular/core';

import { Component, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-widget-influencers-list',
  templateUrl: './widget-influencers-list.component.html',
  styleUrls: ['./widget-influencers-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetInfluencersListComponent implements OnInit {
  @Output() loaded = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {
    this.loaded.emit();
  }
}
