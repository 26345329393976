import {
  Component,
  Input,
  OnInit,
  HostListener,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { isNumeric } from '../../utils';
import {
  BigNumberDenotation,
  BigNumberPipe
} from '@orlo/common/pipes/big-number/big-number.pipe';

const matchPrintMedia = matchMedia('print');

@Component({
  selector: 'ssi-big-number',
  templateUrl: './big-number.component.html',
  providers: [BigNumberPipe]
})
export class BigNumberComponent implements OnInit, OnDestroy, OnChanges {
  @Input() number: number;
  @Input() denotation: BigNumberDenotation = {
    million: 'M',
    thousand: 'K'
  };
  @Input() minFractionDigitsHover = 0;
  @Input() maxFractionDigitsHover = 2;

  viewValue: string | number;

  onPrint = () => {
    this.showFullNumber();
    setTimeout(() => this.showTruncatedNumber());
  };

  constructor(
    private bigNumber: BigNumberPipe,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit() {
    matchPrintMedia.addListener(this.onPrint);
  }

  ngOnChanges(changes) {
    if (changes.number) {
      this.showTruncatedNumber();
    }
  }

  ngOnDestroy() {
    matchPrintMedia.removeListener(this.onPrint);
  }

  @HostListener('mouseover')
  showFullNumber() {
    this.viewValue = isNumeric(this.number)
      ? this.decimalPipe.transform(
          this.number,
          `1.${this.minFractionDigitsHover}-${this.maxFractionDigitsHover}`
        ) // https://angular.io/api/common/DecimalPipe
      : 0;
  }

  @HostListener('mouseout')
  showTruncatedNumber() {
    this.viewValue = this.bigNumber.transform(this.number, this.denotation);
  }
}
