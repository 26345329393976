import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LiveChatPushModeAgentEncouragementComponent } from './live-chat-push-mode-agent-encouragement.component';
import { LiveChatOfflineAgentNoticeModule } from '../live-chat-offline-agent-notice/live-chat-offline-agent-notice.module';

@NgModule({
  declarations: [LiveChatPushModeAgentEncouragementComponent],

  exports: [LiveChatPushModeAgentEncouragementComponent],

  imports: [CommonModule, LiveChatOfflineAgentNoticeModule]
})
export class LiveChatPushModeAgentEncouragementModule {}
