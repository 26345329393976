import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  DoCheck,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';

import {
  PickerFileMetadata,
  PickerInstance,
  PickerResponse
} from 'filestack-js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { deepValue } from '@jsier/deep-value';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { OutboxFileType, OutboxPublisher } from '@ui-resources-angular';
import { AccountTypeId } from '../../../enums';
import { LANGUAGES } from '../../../constants';
import { AltTextModalComponent } from '../../publisher/alt-text-modal/alt-text-modal.component';
import { PublisherDisableOptions } from '../../publisher/publisher-active';
import { UploadSubtitlesModalComponent } from '../../publisher/upload-subtitles-modal/upload-subtitles-modal.component';
import {
  bytesToMb,
  FileUploaderService,
  FsFile
} from '../../../directives/file-uploader/file-uploader.service';
import { ComposerLayout, ComposerService } from '../composer.service';
import {
  FilestackFile,
  FilestackFileWithUploadProgress
} from '@orlo/common/services/filestack/filestack.service';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'ssi-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttachmentsComponent implements OnInit, OnDestroy {
  @Input() composer: OutboxPublisher;
  @Input() disable: PublisherDisableOptions;
  @Input() mediaUploadOptionsMenuBodyTemplate: TemplateRef<any>;
  @Input() composerLayout: ComposerLayout;

  OutboxFileType = OutboxFileType;
  ComposerLayout = ComposerLayout;
  subtitleFilename = '';
  languageName = 'Select your language';
  videoResizingInProgress = false;
  bytesToMb = bytesToMb;
  fsPikerInstance: PickerInstance;

  destroyed$ = new Subject<void>();

  constructor(
    protected modal: NgbModal,
    protected fileUploaderService: FileUploaderService,
    protected composerService: ComposerService
  ) {}

  ngOnInit() {
    this.fileUploaderService.fsPickerOpen$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((instance) => {
        this.fsPikerInstance = instance;
      });

    this.fileUploaderService.videoResizingInProgress$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((inProgress) => {
        this.videoResizingInProgress = inProgress;
      });

    this.fileUploaderService.fileUploadProgress$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((file: FilestackFileWithUploadProgress) => {
        const existingFile = this.composer.uploadingFiles.find(
          (f) => f.uploadId === file.uploadId
        );
        if (existingFile) {
          existingFile.uploadProgress = file.uploadProgress; // update
        } else {
          this.composer.uploadingFiles.push(file);
        }
        // console.log('composer.uploadingFiles:', this.composer.uploadingFiles);
      });

    this.fileUploaderService.filesUploadDone$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result: PickerResponse) => {
        if (result.filesUploaded.length) {
          result.filesUploaded.forEach((file) => {
            const progressFile = this.composer.uploadingFiles.find(
              (f) => f.uploadId === file.uploadId
            );
            if (progressFile) {
              progressFile.uploaded = true;
              progressFile.validated = false;
            }
          });
        }
        if (result.filesFailed.length) {
          result.filesFailed.forEach((file) => {
            this.removeProgressFile(file);
          });
        }
      });

    this.fileUploaderService.filesUploadedAndValidated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((files) => {
        files.forEach((file) => {
          this.removeProgressFile(file);
        });
      });

    this.fileUploaderService.filesValidationFailResult$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.removeProgressFile(result.file);
      });
  }

  removeProgressFile(file: FsFile | FilestackFile | PickerFileMetadata): void {
    const progressFile = this.composer.uploadingFiles.find(
      (f) => f.uploadId === file.uploadId
    );
    if (progressFile) {
      this.composer.uploadingFiles.splice(
        this.composer.uploadingFiles.indexOf(progressFile)
      );
    }
  }

  async cancelUpload(): Promise<void> {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    confirmationModal.componentInstance.title = 'Cancel upload?';
    confirmationModal.componentInstance.info = `Are you sure you want to cancel uploading ${
      this.composer.uploadingFiles.length > 1
        ? this.composer.uploadingFiles.length + ' files'
        : 'the file'
    }?`;
    confirmationModal.componentInstance.cancelButton = `No`;
    confirmationModal.componentInstance.confirmButton = `Yes, please!`;

    const confirmed = await confirmationModal.result;
    if (confirmed) {
      this.fsPikerInstance.cancel();
    }
  }

  noneUploaded(): boolean {
    return this.composer.uploadingFiles.every((f) => !f.uploaded);
  }

  iconType(file): string {
    // add switch statement for other file types
    switch (file.type) {
      case OutboxFileType.Image:
        return 'ssi ssi-image';
      case OutboxFileType.Video:
        return 'ssi ssi-video-correct';
      case OutboxFileType.Gif:
        return 'ssi ssi-gif-badge';
      default:
        return 'ssi ssi-attach-small';
    }
  }

  remove(file): void {
    this.composer.removeFile(file);
  }

  canAddAltText(file): boolean {
    if (
      file.type !== OutboxFileType.Image &&
      file.type !== OutboxFileType.Gif
    ) {
      return false;
    }

    return !!this.composer.accounts.find((account) =>
      [
        String(AccountTypeId.Twitter),
        String(AccountTypeId.Facebook),
        String(AccountTypeId.LinkedIn),
        String(AccountTypeId.LinkedInPersonal)
      ].includes(account.account_type_id)
    );
  }

  canAddCaptions(file): boolean {
    if (file.type !== OutboxFileType.Video) {
      return false;
    }

    return deepValue(
      this.composer,
      'splitPostAccount.socialNetwork.publish.features.videoCaptions'
    );
  }

  async addOrEditAltText(imageFile): Promise<void> {
    const modal = await this.modal.open(AltTextModalComponent, {
      windowClass: 'modal wide'
    });
    modal.componentInstance.imageFile = imageFile;
    modal.componentInstance.altText = imageFile.alt_text
      ? imageFile.alt_text
      : '';

    modal.result.then((text) => {
      if (text) {
        imageFile.alt_text = text;
      }
    });
  }

  async addOrEditCaptions(videoFile): Promise<void> {
    const modal = await this.modal.open(UploadSubtitlesModalComponent, {
      windowClass: 'modal wide'
    });
    console.log('videoFile:', videoFile);

    modal.componentInstance.videoFile = videoFile;
    modal.componentInstance.englishOnly = this.composer.hasLinkedinSelected();
    modal.componentInstance.selectedLocale.displayName = this.languageName;
    modal.componentInstance.subtitleFilename = this.subtitleFilename;
    if (
      videoFile.subtitles &&
      videoFile.subtitles.length &&
      videoFile.subtitles[0].locale
    ) {
      modal.componentInstance.selectedLocale = {
        locale: videoFile.subtitles[0].locale,
        displayName: LANGUAGES[videoFile.subtitles[0].locale]
      };
      modal.componentInstance.subtitleFile = {
        captions_url: videoFile.subtitles[0].captions_url,
        locale: videoFile.subtitles[0].locale
      };
      modal.componentInstance.subtitleFilename = 'Edit captions';
    }

    modal.result.then((subtitle) => {
      if (subtitle) {
        console.log('subtitle:', subtitle);
        const { subtitleFile, fileName, languageName } = subtitle;
        if (subtitleFile.locale && fileName) {
          videoFile.subtitles = subtitleFile;
          this.subtitleFilename = fileName;
        }
        this.languageName = languageName;
      }
    });
  }

  async editImage(imageFile: FsFile): Promise<void> {
    const editedImage = await this.fileUploaderService.editImage(
      imageFile,
      this.composer.isSplit
    );
    if (!editedImage) {
      // editor closed
      return;
    }

    if (this.composer.isSplit) {
      const indexToDelete = imageFile.accountIdsToLinkTo.findIndex(
        (id) => id === this.composer.splitPostAccount.id
      );
      imageFile.accountIdsToLinkTo.splice(indexToDelete, 1);
      this.composer.addFile(
        {
          url: editedImage.url,
          type: OutboxFileType.Image,
          mediaCategory: editedImage.mediaCategory,
          filename: imageFile.filename,
          edited: true
        },
        undefined,
        false
      );
    } else {
      imageFile.url = editedImage.url;
      imageFile.handle = editedImage.handle;
      imageFile.size = editedImage.size;
      imageFile.mimetype = editedImage.mimetype;
      imageFile.filename = editedImage.filename;
      imageFile.status = editedImage.status;
      imageFile.filestackFile = { ...imageFile.filestackFile, ...editedImage };
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.composer.reorderFile(
      this.composer.files[event.previousIndex],
      event.currentIndex
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
