<div class="insights-page insights-reports" ssiLoader [ssiLoaderVisible]="false">
  <div class="insights-container">

    <div class="insights-row">
      <div class="insights-reports-banner">
        <div class="insights-reports-banner-content">
          <h2>Listen, Understand &amp; Act</h2>
          <p>Use your Orlo templates below or build your own custom report to understand and act. Be strategic with your content, share data driven campaigns that will resonate with your audience, be notified of customer issues before they start to snowball, or identify key themes across your sector or brand.</p>
        </div>
        <img
          class="insights-reports-banner-hero"
          src="assets/images/insights/interaction.png"
          alt="two people interacting with devices"
        >
      </div>
    </div>

    <div class="insights-row">
      <div class="insights-reports-grid">
        <div
          class="insights-reports-grid-item"
          (click)="viewTemplateReport('brand')"
        >
          <div class="insights-report-card">
            <img
              src="assets/images/insights/green-area-chart.png"
              alt="green area chart"
            >
            <div class="insights-report-card-meta">
              <h3 class="insights-report-card-title">Brand Report</h3>
              <p>Recognise areas that need improvement and identify areas in which your brand is strong</p>
            </div>
          </div>
        </div>
        <div
          class="insights-reports-grid-item"
          (click)="viewTemplateReport('trend')"
        >
          <div class="insights-report-card">
            <img
              src="assets/images/insights/pink-bubble-chart.png"
              alt="pink bubble chart"
            >
            <div class="insights-report-card-meta">
              <h3 class="insights-report-card-title">Trend Report</h3>
              <p>Use trend insights to manage and foresee issues and engage with current for future customers</p>
            </div>
          </div>
        </div>
        <div
          class="insights-reports-grid-item"
          (click)="viewTemplateReport('product')"
        >
          <div class="insights-report-card">
            <img
              src="assets/images/insights/yellow-line-chart.png"
              alt="yellow line chart"
            >
            <div class="insights-report-card-meta">
              <h3 class="insights-report-card-title">Product Experience Report</h3>
              <p>Capture and analyse behaviour to maximise your product experience strategies</p>
            </div>
          </div>
        </div>
        <div
          class="insights-reports-grid-item"
          (click)="viewTemplateReport('demographic')"
        >
          <div class="insights-report-card">
            <img
              src="assets/images/insights/blue-bar-chart.png"
              alt="blue bar chart"
            >
            <div class="insights-report-card-meta">
              <h3 class="insights-report-card-title">Demographic Report</h3>
              <p>Get valuable insight into customer demographics, or benchmark competitor performance</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!reports.length && !loading">
      <div class="insights-reports-empty">
        <h3>No custom reports</h3>
        <p>You have not created any custom reports yet, get building!</p>
        <button type="button" (click)="createReport()">
          <i class="ssi ssi-addsmall"></i>
          <span>Create Report</span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="reports.length">
      <hr class="insights-page-break">
  
      <div class="insights-row">
        <div class="insights-reports-controls">
          <div class="insights-reports-controls-filter">
            <i class="ssi ssi-correct-search"></i>
            <input
              placeholder="Search custom reports..."
              type="search"
              [(ngModel)]="filterTerm"
              (ngModelChange)="onFilterTermChange()"
            >
          </div>

          <div class="insights-reports-controls-type">
            <ul>
              <li
                [ngClass]="{'insights-reports-controls-type-active': selectedAccessOption === accessOptions.public}"
                (click)="selectedAccessOption = accessOptions.public; onAccessOptionChange();"
              >Public Reports</li>
              <li
                [ngClass]="{'insights-reports-controls-type-active': selectedAccessOption === accessOptions.private}"
                (click)="selectedAccessOption = accessOptions.private; onAccessOptionChange();"
              >My Reports</li>
            </ul>
          </div>

          <ssi-dropdown-select-2 [options]="sortOptionsIterable"
                                [xPlacement]="'right'"
                                [(ngModel)]="selectedSortOption"
                                (ngModelChange)="onSortOptionChange()">
            <ng-template ssiTemplateSelector="headButton" let-expanded="expanded" let-toggle="toggle">
              <div class="insights-reports-controls-sort" (click)="toggle()">
                <span>Sort by</span> <span>{{selectedSortOption.label}}</span> &nbsp; <i class="ssi ssi-sort"></i>
              </div>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
  
        <div *ngIf="reports.length" class="insights-reports-grid">
          <div class="insights-reports-grid-item" *ngIf="filteredReports.length || (!filteredReports.length && !filterTerm)">
            <div class="insights-reports-tile">
              <h3>Create Report</h3>
              <p>Build your own custom report</p>
              <button type="button" (click)="createReport()" class="insights-reports-tile-add">
                <i class="ssi ssi-addsmall"></i>
              </button>
            </div>
          </div>

          <div class="insights-reports-grid-item"
          *ngFor="let report of reportsToRender; trackBy: trackById; let i = index"
          >
            <div class="insights-reports-tile insights-reports-tile-actionable"
                 (click)="viewReport(report)"
            >
              <h3>{{report.name}}</h3>
              <button 
                *ngIf="authUser.id === report.created_by"
                type="button" 
                (click)="$event.stopPropagation(); editReport(report)">
                <i class="ssi ssi-edit"></i>
                Edit
              </button>
            </div>
          </div>
        </div>

        <ssi-no-results
          *ngIf="!filteredReports.length && filterTerm"
          description="Check your spelling or try searching through your Public Reports?"
        ></ssi-no-results>

        <ssi-paginator [itemsCount]="filteredReports.length"
                       [pageLinksSize]="5"
                       [itemsPerPage]="7"
                       (onPageChange)="onPageChange($event)"></ssi-paginator>
      </div>
    </ng-container>
  </div>
</div>