import { sourceTypeName } from '../../../../modules/auth/monitoring/monitoring-streams/monitoring-streams.service';
import { Query } from '../../../../modules/auth/monitoring/common/constants/query-builder-options';

enum Operator {
  Or = '$or',
  And = '$and'
}

export class MonitoringStream {
  id: string;
  name: string;
  description: string;
  latest_result: Date;
  sources: sourceTypeName[];
  query: {
    [operator in Operator]: Query[];
  };
  include_retweets: boolean;
  geo: {
    enabled: boolean;
    radius: number;
  };
  lang: string;
  created_by: number;
  created_at: string;
  listening_group_ids?: string[];
  ig_hashtags?: Array<string>;
  updated_at?: string;
  updated_by?: number;

  constructor(props: any) {
    this.id = props.id;
    this.name = props.name;
    this.description = props.description;
    this.latest_result = new Date(props.latest_result);
    this.sources = props.sources;
    this.query = props.query;
    this.include_retweets = props.include_retweets;
    this.created_by = props.created_by;
    this.created_at = props.created_at;
    this.listening_group_ids = props.listening_group_ids;
    this.ig_hashtags = props.ig_hashtags;
    this.updated_at = props.updated_at;
    this.updated_by = props.updated_by;
  }
}
