import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgForm
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const TAGS_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagsInputComponent), //tslint:disable-line
  multi: true
};

@Component({
  selector: 'ssi-tags-input',
  templateUrl: './tags-input.component.html',
  providers: [TAGS_INPUT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./tags-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagsInputComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string;
  @Input() minLength: number;
  @Input() allowedTagsPattern: RegExp;
  @Input() updateModelValue = false;
  @Input() typeaheadOptions: string[];
  @Input() belowInputContent: TemplateRef<any>;
  @Input() maxTags = Infinity;

  @Output() tagAdded: EventEmitter<{ tag: string }> = new EventEmitter();
  @Output() tagRemoved: EventEmitter<{ tag: string }> = new EventEmitter();

  newTagValue: string;
  modelValue: string[];

  constructor() {}

  ngOnInit(): void {}

  typeaheadSearch = (searchText$: Observable<string>) => {
    return searchText$.pipe(
      map((searchText = '') => {
        if (searchText.length > 0 && this.typeaheadOptions) {
          return this.typeaheadOptions
            .filter((option) =>
              option.toLowerCase().includes(searchText.toLowerCase())
            )
            .slice(0, 5);
        } else {
          return [];
        }
      })
    );
  };

  private onChangeCallback: (_: any) => void = () => {};

  writeValue(newValue: string[]): void {
    this.modelValue = newValue || [];
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {}

  addTag(form: NgForm) {
    if (!form.valid) {
      return;
    }

    if (Array.from(this.modelValue || []).length >= this.maxTags) {
      return;
    }

    if (this.updateModelValue) {
      const tags = Array.from(this.modelValue || []);
      tags.push(this.newTagValue);
      this.setViewValue(tags);
    }
    this.tagAdded.emit({ tag: this.newTagValue });
    this.newTagValue = '';
    form.reset();
  }

  removeTag(tag) {
    if (this.updateModelValue) {
      const tags = Array.from(this.modelValue || []).filter(
        (iTag) => iTag !== tag
      );
      this.setViewValue(tags);
    }
    this.tagRemoved.emit({ tag });
  }

  private setViewValue(newValue: string[]) {
    this.writeValue(newValue);
    this.onChangeCallback(newValue);
  }

  onPaste(event: ClipboardEvent) {
    const pastedText =
      event && event.clipboardData && event.clipboardData.getData('text');

    const replacedDelimitersWithCommas = pastedText
      .replace(/\r?\n/g, ',')
      .replace(/;/g, ',');

    let splitTagsArray = replacedDelimitersWithCommas.split(',');
    splitTagsArray = splitTagsArray.map((tag) => tag.trim());
    this.setViewValue(splitTagsArray);
  }
}
