import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AccountModel, UserModel, Account, User } from '@ui-resources-angular';
import { groupBy } from 'lodash-es';
import {
  AccountAuthService,
  AuthProvider,
  AuthSessionOptionType,
  AccountAuthSession,
  AuthSessionOptionsResult
} from '../../../../../common/services/account-auth/account-auth.service';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import {
  trackByNestedProperty,
  trackByProperty
} from '../../../../../common/util';
import { authProviders, authUser } from '../../../../common-resolves';
import { AccountTypeId } from '../../../../../common/enums';

interface ProviderAccounts {
  provider: AuthProvider;
  accounts: Account[];
}

@Component({
  selector: 'ssi-reauth-accounts',
  templateUrl: './reauth-accounts.component.html',
  styleUrls: ['./reauth-accounts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReauthAccountsComponent implements OnInit, OnDestroy {
  static resolve = [authUser, authProviders];

  @Input() authUser: User;

  @Input() authProviders: AuthProvider[];

  canReAuthAccounts: boolean;

  totalReauthAccounts = 0;

  providerAccounts: ProviderAccounts[] = [];

  loadingTracker = this.asyncTracker.create();

  trackById = trackByProperty('id');

  trackByProviderName = trackByNestedProperty('provider.name');

  provider: AuthProvider;

  uuid: string;

  popupWindow: any;

  authSession: AccountAuthSession;

  authSessionOptions: AuthSessionOptionsResult;
  hasFacebookGroupAccount: boolean;
  AccountTypeId = AccountTypeId;

  constructor(
    private userModel: UserModel,
    private accountModel: AccountModel,
    private accountAuth: AccountAuthService,
    private popup: PopupService,
    private translate: TranslateService,
    private state: StateService,
    private asyncTracker: AsyncTrackerFactory
  ) {}

  ngOnInit() {
    try {
      this.canReAuthAccounts = this.authUser.hasCompanyPermission(
        'administer_accounts'
      );

      const accountsNeedingReauth = this.accountModel
        .getAll()
        .filter((account) => account.is_reauth_required);

      if (!(!!this.authProviders && Array.isArray(this.authProviders))) {
        throw new Error(
          `Value for 'reauth accounts providers' not in expected format.`
        );
      }

      this.providerAccounts = Object.values(
        groupBy(accountsNeedingReauth, 'account_type_name')
      ).map((accounts: Account[]) => {
        return {
          provider: this.authProviders.find(
            (provider) => provider.name === accounts[0].account_type_name
          ),
          accounts
        };
      });

      this.totalReauthAccounts = accountsNeedingReauth.length;
      this.authPopupCallback = this.authPopupCallback.bind(this);

      window.addEventListener('message', this.authPopupCallback, false);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.authPopupCallback, false);
  }

  authPopupCallback(event) {
    if (event.origin === location.origin || 'https://www.orlo.app') {
      const { data } = event;
      if (data.uuid) {
        this.uuid = data.uuid;
      } else if (data.queryString) {
        this.createAuthSession(data);
      }
    } else {
      return;
    }
  }

  async openAuthPopup(provider: AuthProvider) {
    this.provider = provider;
    this.popupWindow = window.open(
      `#/settings/auth-popup?id=${provider.id}`,
      'auth',
      'width=400,height=600'
    );
    await this.destroySession();
  }

  async reauthProvider({ provider }: ProviderAccounts) {
    this.openAuthPopup(provider);
  }

  async createAuthSession(data) {
    try {
      this.popupWindow.close();
      this.authSession = await this.accountAuth.createAuthenticatedSession(
        data,
        this.provider,
        this.uuid
      );
      this.authSessionOptions = await this.authSession.getSessionOptions({
        type: AuthSessionOptionType.Reauth
      });
      if (this.authSessionOptions.options.length === 0) {
        return this.popup.alert({
          isError: true,
          message: this.translate.instant(
            'NO_ACCOUNT_PAGES_COULD_BE_FOUND_PLEASE_MAKE_SURE_YOU_ARE_AN_ADMINISTRATOR_OF_THE_PAGE'
          )
        });
      }
      await Promise.all(
        this.authSessionOptions.options.map((option) =>
          this.authSession.reauthAccount(option)
        )
      );
      await this.destroySession();
      this.state.reload();
    } catch (e) {
      console.error(e);
    }
  }

  async destroySession() {
    if (this.authSession) {
      await this.authSession.clearSession();
      this.authSession = null;
      this.authSessionOptions = null;
    }
  }

  return() {
    this.state.go('auth.settings.index');
  }
}
