import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';

import { PopoverModule } from '../../../../common/directives/popover/popover.module';

import { LiveChatActivityTreeModule } from '../live-chat-activity-tree/live-chat-activity-tree.module';
import { LiveChatAssignChatModule } from '../live-chat-assign-chat/live-chat-assign-chat.module';
import { LiveChatConversationDashboardComponent } from './live-chat-conversation-dashboard.component';
import { LiveChatConversationIndicatorsModule } from '../live-chat-conversation-indicators/live-chat-conversation-indicators.module';
import { LiveChatConversationResponseModule } from '../live-chat-conversation-response/live-chat-conversation-response.module';
import { LiveChatConversationSidebarModule } from '../live-chat-conversation-sidebar/live-chat-conversation-sidebar.module';
import { LiveChatMessageTagsModule } from '../live-chat-message-tags/live-chat-message-tags.module';
import { LiveChatNetworkStateModule } from '../live-chat-network-state/live-chat-network-state.module';
import { LiveChatNotesModule } from '../live-chat-notes/live-chat-notes.module';
import { LiveChatAssignAgentModule } from '../live-chat-assign-agent/live-chat-assign-agent.module';

@NgModule({
  declarations: [LiveChatConversationDashboardComponent],

  exports: [LiveChatConversationDashboardComponent],

  imports: [
    CommonModule,
    LiveChatAssignChatModule,
    LiveChatActivityTreeModule,
    LiveChatConversationResponseModule,
    LiveChatConversationSidebarModule,
    LiveChatConversationIndicatorsModule,
    LiveChatMessageTagsModule,
    LiveChatNetworkStateModule,
    LiveChatNotesModule,
    LiveChatAssignAgentModule,
    NgbPopoverModule.forRoot(),
    PopoverModule,
    TranslateModule,
    ClickOutsideModule
  ]
})
export class LiveChatConversationDashboardModule {}
