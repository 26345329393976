import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { WorkflowModel } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export function workflowsFn(workflowModel: WorkflowModel) {
  return workflowModel.loadAll();
}

@Component({
  selector: 'ssi-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowsComponent implements OnInit {
  static resolve = [
    {
      token: 'workflows',
      resolveFn: workflowsFn,
      deps: [WorkflowModel]
    }
  ];

  @Input() workflows;
  newWorkflowForm: FormGroup;
  showCreateGroup = false;

  constructor(
    private state: StateService,
    private workflowModel: WorkflowModel,
    private workflowManager: WorkflowManagerService
  ) {}

  ngOnInit() {
    this.workflows = this.workflowModel.getAll();

    this.newWorkflowForm = new FormGroup({
      newGroupName: new FormControl('', [Validators.required])
    });
  }

  public loadAllAccounts() {
    this.workflowManager.loadWorkflow(0, { redirectToDashboard: true });
  }

  public createNew() {
    this.workflowModel
      .create(this.newWorkflowForm.value.newGroupName)
      .then((workflow) =>
        this.state.go('auth.editWorkflow', { workflowId: workflow.id })
      );
    this.showCreateGroup = false;
  }

  public loadState(workflow, stateName) {
    this.workflowManager.loadWorkflow(workflow.id).then(() =>
      this.state.go(stateName, {
        accounts: workflow.accounts.map((account) => account.id),
        workflow: workflow.id
      })
    );
  }
}
