import { ViewEncapsulation } from '@angular/core';


import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { Account, OutboxPublisher } from '@ui-resources-angular';
import { AccountTypeIdLabelMap } from '../../../enums';
import { DropdownSelect2Component } from '../../dropdown-select-2/dropdown-select-2.component';

@Component({
  selector: 'ssi-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSelectorComponent implements OnInit, OnChanges {
  @Input() disabled = false;
  @Input() accounts: Account[] = [];
  @Input() selectedAccounts: Account[] = [];
  @Output() selectedAccountsChange = new EventEmitter<Account[]>();

  @ViewChild(DropdownSelect2Component)
  dropdownComponent: DropdownSelect2Component;
  @ViewChild('dropdownElm') dropdownElm;
  accountSelectorDropdown
  @ViewChild('chipsContainer') chipsContainer: ElementRef;

  maxVisibleChipsCount = 999;
  chipsContainerWidth = 600; // to be determined below based on the dropdown width
  viewMoreElementWidth = 110;
  marginOffset = 5; // margin between chips

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['selectedAccounts'] &&
      Array.isArray(this.selectedAccounts) &&
      this.selectedAccounts.length &&
      this.dropdownComponent &&
      !this.dropdownComponent.expanded
    ) {
      this.changeDetectorRef.detectChanges();
      this.setMaxVisibleChipsCount();
    }
  }

  ngOnInit() {}

  selectedAccountsChanged(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
    this.selectedAccountsChange.emit(selectedAccounts);
  }

  onDropdownWidthChange(): void {
    this.chipsContainerWidth = this.dropdownElm.elementRef.nativeElement.offsetWidth - this.viewMoreElementWidth;
    if (
      Array.isArray(this.selectedAccounts) &&
      this.selectedAccounts.length &&
      !this.dropdownComponent.expanded
    ) {
      this.setMaxVisibleChipsCount();
    }
  }

  setMaxVisibleChipsCount(): number {
    if (!this.chipsContainer) {
      // no selected accounts
      return;
    }

    this.maxVisibleChipsCount = 999;
    this.changeDetectorRef.detectChanges();

    const chips = this.chipsContainer.nativeElement.children;

    let i = 0;
    let takenWidth = 0;
    while (!!chips[i] && takenWidth <= this.chipsContainerWidth) {
      const chipWidth = parseFloat(getComputedStyle(chips[i]).width);
      takenWidth += chipWidth + this.marginOffset;
      i++;
    }

    if (takenWidth > this.chipsContainerWidth) {
      this.maxVisibleChipsCount = i - 1;
    } else {
      this.maxVisibleChipsCount = i;
    }

    this.changeDetectorRef.detectChanges();
  }

  onDropdownToggled(visible: boolean): void {
    if (!visible) {
      this.setMaxVisibleChipsCount();
    }
  }

  getGroupLabel = (accountTypeId: string): string => {
    return AccountTypeIdLabelMap[accountTypeId];
  };

  deselectAll(): void {
    this.selectedAccounts = [];
    this.selectedAccountsChanged(this.selectedAccounts);
  }

  deselectAccount(account: Account): void {
    this.selectedAccounts = this.selectedAccounts.filter(
      (selectedAccount) => selectedAccount !== account
    );
    this.selectedAccountsChanged(this.selectedAccounts);
  }
}
