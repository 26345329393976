import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { User, UserModel, Note } from '@ui-resources-angular';

import { Message } from '@orlo/library/models/live-chat/message';
import { FormControl } from '@angular/forms';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

@Component({
  selector: 'ssi-live-chat-inline-notes',
  templateUrl: './live-chat-inline-notes.component.html',
  styles: [],
  styleUrls: ['./live-chat-inline-notes.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatInlineNotesComponent implements OnInit {
  @Input() isAddNoteButtonVisible = true;
  @Input() isExpanded = false;
  @Input() isNoteFormFocused = false;
  @Input() message: Message;

  @Output()
  isNoteFormFocusedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  openNotesScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  public user: User;

  private _areNotesVisible = false;
  private _isAddNoteFormVisible = false;
  private _isInitialised = false;

  public get activeUserName(): string {
    return this.user.fullName;
  }

  public areNotesVisible(): boolean {
    return this._areNotesVisible;
  }

  public get oldestNote(): Note {
    if (!this.message || !Array.isArray(this.message.notes)) {
      return;
    }
    return this.message.notes[0];
  }

  public get isAddNoteFormVisible(): boolean {
    return this._isAddNoteFormVisible;
  }

  public get isInitialised(): boolean {
    return this._isInitialised;
  }

  public get shouldShowNoteForm(): boolean {
    if (!this.isInitialised) {
      return;
    }

    if (!this.message.notes.length) {
      return true;
    }

    if (this.isAddNoteButtonVisible && !this.isAddNoteFormVisible) {
      return;
    }

    return true;
  }

  constructor(
    private liveChatService: LiveChatService,
    private userModel: UserModel
  ) {}

  // @todo: why note.author not part of definition?

  ngOnInit(): void {
    this.userModel.getAuthUser().then((user) => {
      this.user = user;

      if (!this.isInitialised) {
        this._isInitialised = true;
      }
    });
  }

  public onIsNoteFormFocusedChange(value: boolean): void {
    this.isNoteFormFocusedChange.emit(value);
  }

  public onOpenNotesScreen(): void {
    this.openNotesScreen.emit(true);
  }

  public onToggleForm(): void {
    this._isAddNoteFormVisible = !this._isAddNoteFormVisible;
  }
}
