import _ from 'lodash';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TransitionService, StateService, Transition } from '@uirouter/angular';
import {
  campaigns,
  workflowAccounts,
  authUser,
  companyConfig
} from '../../../common-resolves';
import {
  Account,
  AccountModel,
  User,
  OutboxPublisher,
  BlockingWord,
  serialiseOutboxPublisherText
} from '@ui-resources-angular';
import { API } from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatetimeModalComponent } from '../../../../common/components/datetime-modal/datetime-modal.component';
import { CampaignModalComponent } from '../../../../common/components/campaign-modal/campaign-modal.component';
import { DeleteModalComponent } from '../../../../common/components/delete-modal/delete-modal.component';
import { ConfirmationModalComponent } from '../../../../common/components/confirmation-modal/confirmation-modal.component';
import { ApiService, Campaign } from '../../../../common/services/api';
import { CampaignsService } from '../../../../common/services/api';

@Component({
  selector: 'ssi-bulk-edit',
  templateUrl: './bulk-edit.component.html',
  styleUrls: ['./bulk-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BulkEditComponent implements OnInit, OnDestroy {
  static resolve = [campaigns, workflowAccounts, authUser, companyConfig];

  @Input() campaigns: Campaign[];
  @Input() workflowAccounts: Account[];
  @Input() authUser: User;
  @Input() companyConfig;
  @ViewChild('campaignSelectWrapper') campaignHeader;

  private $routeChange;
  posts = [];
  selectedPosts = [];
  postErrors = [];
  postIDs = [];
  toggleActions = false;
  scheduleDate;
  now = Date.now();
  filterByValidation = null;
  promiseQueue = [];
  uploading = false;
  progressCount = 0;
  successCount = 0;
  failCount = 0;
  selectedMentions = [];
  loading = false;

  constructor(
    private api: API,
    private newApiService: ApiService,
    private transition: Transition,
    private transitionService: TransitionService,
    private campaignService: CampaignsService,
    public accountModel: AccountModel,
    public modal: NgbModal,
    private state: StateService,
    private blockingWord: BlockingWord
  ) {}

  async ngOnInit() {
    const { posts } = this.transition.params();
    this.loading = true;

    const blockingWords = await this.blockingWord.getBlockingWords();
    const blockingWordsRaw = blockingWords.words.map(
      (blockingWord) => blockingWord.word
    );

    this.posts = await this.api
      .get('outbox_v2/bulkGet', { params: { ids: posts } })
      .then(({ data }) => data.message);

    this.posts = this.posts.map((post) => {
      return new OutboxPublisher(
        this.workflowAccounts,
        this.authUser,
        this.companyConfig.use_utm_link_tracking,
        this.companyConfig.use_link_shortening,
        blockingWordsRaw,
        { edit: post }
      );
    });

    this.loading = false;

    this.$routeChange = this.transitionService.onStart(
      {},
      async (transition) => {
        if (transition.to().name !== 'auth.marketing.outboxSearch') {
          const confirmationModal = this.modal.open(
            ConfirmationModalComponent,
            {
              windowClass: 'orlo-modal'
            }
          );
          confirmationModal.componentInstance.negativeConfirmation = true;
          confirmationModal.componentInstance.icon = 'ssi ssi-cancel-edit';
          confirmationModal.componentInstance.title =
            'Finished with the editor?';
          confirmationModal.componentInstance.info = `You’ve made edits that you haven’t saved yet. If you exit the Editor, you’ll lose your changes.`;
          confirmationModal.componentInstance.cancelButton = 'Continue editing';
          confirmationModal.componentInstance.confirmButton = `Don't Save`;
          return await confirmationModal.result;
        } else {
          return true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.$routeChange();
  }

  public async editSchedule(post = null) {
    const modal = this.modal.open(DatetimeModalComponent, {
      windowClass: 'orlo-modal'
    });
    const newSchedule = await modal.result;
    if (newSchedule) {
      const confirmationModal = this.modal.open(ConfirmationModalComponent, {
        windowClass: 'orlo-modal'
      });
      confirmationModal.componentInstance.icon = 'ssi ssi-experience';
      confirmationModal.componentInstance.title = 'Save your edits?';
      confirmationModal.componentInstance.info = `You've changed the schedules of ${
        this.selectedPosts.length > 1 ? this.selectedPosts.length : 1
      } post${this.selectedPosts.length === 1 ? '' : 's'} to: ${new Date(
        newSchedule
      ).toUTCString()}`;
      confirmationModal.componentInstance.cancelButton = 'Cancel edits';
      confirmationModal.componentInstance.confirmButton = 'Save schedules';
      const confirm = await confirmationModal.result;

      if (confirm && newSchedule && !post) {
        this.selectedPosts
          .filter((p) => {
            return (
              this.checkSchedule(p.edit.send_at) !== 'Post already published'
            );
          })
          .map(
            (selectedPost) =>
              (selectedPost.edit.send_at = new Date(newSchedule))
          );
        this.selectedPosts = _.union(this.selectedPosts, this.selectedPosts);
      } else if (confirm && newSchedule && post) {
        const index = this.selectedPosts.findIndex(
          (postIndex) => postIndex.edit.id === post.edit.id
        );
        this.selectedPosts[index].edit.send_at = new Date(newSchedule);
      }
    }
    this.toggleActions = false;
  }

  public async editCampaigns(post = null) {
    const modal = this.modal.open(CampaignModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.campaigns = this.campaignService.sortByParent(
      this.campaigns
    ).live;
    const newCampaign = await modal.result;

    if (newCampaign) {
      const confirmationModal = this.modal.open(ConfirmationModalComponent, {
        windowClass: 'orlo-modal'
      });
      confirmationModal.componentInstance.icon = 'ssi ssi-campaign';
      confirmationModal.componentInstance.title = 'Save campaign tags?';
      confirmationModal.componentInstance.info = `You’ve changed the campaign tags for ${
        this.selectedPosts.length > 1 ? this.selectedPosts.length : 1
      } post${this.selectedPosts.length === 1 ? '' : 's'} to: ${
        newCampaign.name
      }`;
      confirmationModal.componentInstance.cancelButton = 'Cancel edits';
      confirmationModal.componentInstance.confirmButton = 'Save campaigns';
      const confirm = await confirmationModal.result;

      if (confirm && newCampaign && !post) {
        this.selectedPosts.map(
          (selectedPost) =>
            (selectedPost.edit.campaign_id = parseInt(newCampaign.id, 10))
        );
        this.selectedPosts = _.union(this.selectedPosts, this.selectedPosts);
      } else if (confirm && newCampaign && post) {
        const index = this.posts.findIndex(
          (postIndex) => postIndex.edit.id === post.edit.id
        );
        await this.api
          .post(
            'campaign/updateOutbox',
            {
              new_campaign_id: newCampaign.id,
              outbox_id: post.edit.id
            },
            {
              params: {
                _method: 'POST'
              }
            }
          )
          .then(({ data }) => {
            if (!data.success) {
              throw new Error('Failed to update campaign');
            }
            this.posts[index].edit.campaign_id = parseInt(newCampaign.id, 10);
          });
      }
    }
    this.toggleActions = false;
  }

  public requireValidation() {
    this.selectedPosts.map((post) => {
      post.edit.requires_validation = true;
      post.requiresValidation = true;
    });
    this.selectedPosts = _.union(this.selectedPosts, this.selectedPosts);
    this.toggleActions = false;
  }

  public async removeFromEditor() {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    confirmationModal.componentInstance.icon = 'ssi ssi-remove-microcopy';
    confirmationModal.componentInstance.title = 'Remove posts from Editor?';
    confirmationModal.componentInstance.info =
      'You’ve selected these posts to be removed from the Editor.';
    confirmationModal.componentInstance.cancelButton = 'Cancel';
    confirmationModal.componentInstance.confirmButton = 'Remove posts';
    const confirm = await confirmationModal.result;

    if (confirm) {
      this.posts = this.posts.filter((post) =>
        this.selectedPosts.find(
          (selectedPost) => selectedPost.edit.id === post.edit.id
        )
          ? null
          : post
      );
      this.selectedPosts = [];
    }

    this.toggleActions = false;
  }

  public async openBulkDeleteModal() {
    const modal = this.modal.open(DeleteModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.title = 'Delete selected posts?';
    modal.componentInstance.info = `Deleting the ${
      this.selectedPosts.length
    } post${
      this.selectedPosts.length === 1 ? '' : 's'
    } you have selected can’t be undone. Once deleted, the posts will be permanently removed from the platform along with any meta data.`;
    const confirmed = await modal.result;

    if (confirmed) {
      console.log('CONFIRMED!');
      this.bulkDelete();
    }
  }

  public async bulkAction(action) {
    this.uploading = true;
    this.selectedPosts.map((post) => {
      const diffDays =
        (Date.parse(post.edit.send_at) - this.now) / (1000 * 3600 * 24);
      const diffMins = Math.floor(diffDays / (1000 * 60));
      const isPublished = diffDays < 1 && diffMins < 0;
      if (isPublished) {
        const promise = this.api
          .post('campaign/updateOutbox', {
            outbox_id: post.edit.id,
            new_campaign_id: post.edit.campaign_id
          })
          .then(({ status, data }) => {
            this.progressCount++;
            status === 200 ? this.successCount++ : this.failCount++;
            return {
              id: post.edit.id,
              status,
              data
            };
          })
          .catch((error) => {
            this.progressCount++;
            this.failCount++;
            return {
              id: post.edit.id,
              status: error.response.status,
              error: 'Failed to update Campaign'
            };
          });
        this.promiseQueue.push(promise);
      } else {
        post.edit.text = serialiseOutboxPublisherText(
          post.text,
          post.links,
          this.getAccountById(post.edit.account_id),
          post.edit.campaign_id
            ? this.getCampaignById(post.edit.campaign_id)
            : null,
          []
        );
        delete post.edit.legacy_text;
        delete post.edit.seq;
        delete post.edit.targeting;
        const promise = this.api
          .post(
            'outbox_v2/indexOutboxv2',
            { message: post.edit },
            {
              params: {
                _method: action
              }
            }
          )
          .then(({ status, data }) => {
            this.progressCount++;
            status === 200 ? this.successCount++ : this.failCount++;
            return {
              id: post.edit.id,
              status,
              data
            };
          })
          .catch((error) => {
            this.progressCount++;
            this.failCount++;
            const httpErrorMessage =
              _.get(error, 'data.message') ||
              _.get(error, 'response.data.message');
            return {
              id: post.edit.id,
              status: error.response.status,
              error: httpErrorMessage
            };
          });
        this.promiseQueue.push(promise);
      }
    });

    this.postErrors = await Promise.all(this.promiseQueue).then((postResults) =>
      postResults.filter((post) => post.status !== 200)
    );
  }

  public checkError(id) {
    const postError = this.postErrors.find((post) => post.id === id);
    return postError ? postError.error : null;
  }

  async bulkDelete() {
    console.log('bulkDelete for selectedPosts:', this.selectedPosts);

    this.uploading = true;
    this.selectedPosts.map((post) => {
      const promise = this.newApiService
        .delete(`${this.newApiService.url}/outbox/index?id=${post.edit.id}`)
        .toPromise()
        .then(({ success }) => {
          console.log('successCount...:', success);
          this.progressCount++;
          success === true ? this.successCount++ : this.failCount++;
          return {
            id: post.edit.id,
            status: 200
          };
        })
        .catch((error) => {
          console.log('failCount...', error);
          this.progressCount++;
          this.failCount++;
          const httpErrorMessage =
            _.get(error, 'data.message') ||
            _.get(error, 'response.data.message');
          return {
            status: error.status,
            error: httpErrorMessage
          };
        });

      this.promiseQueue.push(promise);
    });

    this.postErrors = await Promise.all(this.promiseQueue).then((postResults) =>
      postResults.filter((post) => post.status !== 200)
    );
  }

  public checkSchedule(schedule) {
    const diff = Date.parse(schedule) - this.now;
    const days = diff / (1000 * 3600 * 24);
    if (days < 1) {
      const minutes = Math.floor(diff / (1000 * 60));
      if (minutes < 0) {
        return 'Post already published';
      }
      if (minutes < 60) {
        return `Scheduled in ${minutes}mins`;
      }
    }
    return null;
  }

  public selectPost(post, all) {
    if (all) {
      this.selectedPosts =
        this.selectedPosts.length === this.posts.length ? [] : [...this.posts];
    } else {
      const index = this.selectedPosts.findIndex(
        (selectedPost) => selectedPost.edit.id === post.edit.id
      );
      index > -1
        ? this.selectedPosts.splice(index, 1)
        : this.selectedPosts.push(post);
    }
  }

  public returnToOutbox() {
    this.state.go('auth.marketing.outboxSearch');
  }

  public returnToEditing() {
    this.failCount = 0;
    this.progressCount = 0;
    this.successCount = 0;
    this.uploading = false;
  }

  public cancelUpdate() {
    this.promiseQueue = [];
    this.uploading = false;
  }

  public updateSelectedMentions(selectedMention) {
    if (
      this.selectedMentions.some((mention) => mention.id === selectedMention.id)
    ) {
      return;
    } else {
      this.selectedMentions.push(selectedMention);
    }
  }

  public isSelected(id) {
    return this.selectedPosts.find((post) => post.edit.id === id);
  }

  public calculatePercentageComplete() {
    return (this.progressCount / this.selectedPosts.length) * 100;
  }

  private getAccountById(id) {
    return this.accountModel.get(id);
  }

  public fetchAccountName(id) {
    const { name } = this.getAccountById(id);
    return name;
  }

  public fetchAccountHandle(id) {
    const { username } = this.getAccountById(id);
    return username;
  }

  public fetchAccountImage(id) {
    const { picture } = this.getAccountById(id);
    return picture;
  }

  public fetchAccountIcon(id) {
    const {
      socialNetwork: {
        icon: { web }
      }
    } = this.getAccountById(id);
    return web;
  }

  private getCampaignById(id) {
    return this.campaigns.find((campaign) => campaign.id === id.toString());
  }

  public fetchCampaignName(id) {
    if (id) {
      const { name } = this.getCampaignById(id);
    }
    return id ? name : null;
  }
}
