import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';
import { TooltipModule } from '@orlo/common/directives/tooltip/tooltip.module';
import { ConfirmActionModule } from '@orlo/common/directives/tooltip/confirm-action/confirm-action.module';

import { EditComponent } from './edit.component';
import { CommonModule as DashboardCommonModule } from '../common/common.module';
import { MenuModule } from '@orlo/common/components/menu/menu.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardCommonModule,
    TooltipModule,
    ConfirmActionModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.dashboard.edit',
          url: '/edit/:id',
          // data: {
          //   shouldOpenAddWidget: false
          // },
          component: EditComponent
          // resolve: EditComponent.resolve
        }
      ]
    }),
    MenuModule
  ],
  declarations: [EditComponent]
})
export class EditModule {}
