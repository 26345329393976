import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Account } from '@ui-resources-angular';
import {
  NetworkConst,
  NetworksConst,
  networks,
  networksIterable
} from '../../../../../../common/constants';
import { mapToIterable } from '../../../../../../common/utils';
import { DateRanges, TotalsStats } from '../../marketing.service';

@Component({
  selector: 'ssi-follower-count',
  templateUrl: './follower-count.component.html',
  styles: [],
  styleUrls: ['./follower-count.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FollowerCountComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() dateRanges: DateRanges;
  @Input() stats: TotalsStats;

  networksConst: NetworksConst = networks;
  networksConstIterable: NetworkConst[] = networksIterable;

  totalsByAccountTypeIterable: any[] = [];

  constructor(protected translate: TranslateService) {}

  ngOnInit() {
    this.totalsByAccountTypeIterable = mapToIterable(
      this.stats.totalsByAccountType
    );

    this.totalsByAccountTypeIterable.sort((a, b) => {
      return (
        this.networksConst[a.account.account_type_name].order -
        this.networksConst[b.account.account_type_name].order
      );
    });
  }
}
