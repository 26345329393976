import { ViewEncapsulation } from '@angular/core';


import { Component, Input, TemplateRef } from '@angular/core';
import { Placement } from '@popperjs/core';

@Component({
  selector: 'ssi-tooltip-wrap',
  templateUrl: './tooltip-wrap.component.html',
  styleUrls: ['./tooltip-wrap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipWrapComponent {
  @Input() ssiTooltip: string;
  @Input() tooltipPlacement: Placement = 'top';
  @Input() tooltipAppendToBody: boolean;
  @Input() tooltipTitle: string;
  @Input() tooltipIconClass: string;
  @Input() tooltipDisabled = false;
  @Input() tooltipPositionFixed = false;
  @Input() tooltipHost: HTMLElement;
  @Input() tooltipTrigger = 'hover';
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipTemplateContext: any;
  @Input() tooltipWindowClass: string;
  @Input() tooltipOffset: number[] = [];
}
