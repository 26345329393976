import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingAdvertEditComponent } from './advertising-advert-edit.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { flatpickrFactory } from '@orlo/common/components/publisher/publisher.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AdvertisingTargetingBuilderModule } from '../advertising-targeting-builder/advertising-targeting-builder.module';
import { AddSecondsModule } from '@orlo/common/pipes/add-seconds/add-seconds.module';
import { FormatBudgetModule } from '@orlo/common/pipes/format-budget/format-budget.module';
import { OutboxPostModule } from '@orlo/common/components/outbox-post/outbox-post.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.advertEdit',
          url: '/advert-edit/:advertId',
          component: AdvertisingAdvertEditComponent,
          resolve: AdvertisingAdvertEditComponent.resolve
        }
      ]
    }),
    FlatpickrModule.forRoot(),
    AdvertisingTargetingBuilderModule,
    AddSecondsModule,
    FormatBudgetModule,
    OutboxPostModule
  ],
  declarations: [AdvertisingAdvertEditComponent],
  entryComponents: [AdvertisingAdvertEditComponent]
})
export class AdvertisingAdvertEditModule {}
