import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  SimpleChanges,
  OnChanges,
  AfterViewChecked
} from '@angular/core';
import { mapToIterable } from '../../../../../../common/utils';

import {
  ConfigType,
  ConfigOption,
  AggregateLabels
} from '../custom-widget.component';

export interface Field {
  key: string;
  label: string;
  id: string;
  type: string;
  [ConfigType.Measure]: boolean;
  [ConfigType.Dimension]: boolean;
  aggregateFunctions: string[];
}

export interface Fields {
  ID: Field;
  'Response Time': Field;
  'Created At': Field;
  'Is Private': Field;
  Tags: Field;
  Sentiment: Field;
  Language: Field;
  Channel: Field;
  Age: Field;
  Gender: Field;
  Location: Field;
  Emotion: Field;
  // 'Content Vector': Field;
  Classification: Field;
}

@Component({
  selector: 'ssi-custom-widget-field',
  templateUrl: './custom-widget-field.component.html',
  styles: [],
  styleUrls: ['./custom-widget-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomWidgetFieldComponent implements OnInit {
  @Input() fieldOption: ConfigOption;
  @Output() onFieldOptionChange = new EventEmitter();
  AggregateLabels = AggregateLabels;

  isDropdownOpen = false;
  isDropdownMetaOpen = false;
  selectedField: string;
  searchTerm = '';
  measure = '';
  chosenMeasure = '';

  fields: Fields = {
    ID: {
      key: 'ID',
      label: 'Results',
      id: 'id',
      type: 'STRING',
      [ConfigType.Measure]: true,
      [ConfigType.Dimension]: false,
      aggregateFunctions: ['COUNT']
    },
    'Response Time': {
      key: 'Response Time',
      label: 'Response Time',
      id: 'response_time',
      type: 'NUMBER',
      [ConfigType.Measure]: true,
      [ConfigType.Dimension]: false,
      aggregateFunctions: ['SUM', 'AVG', 'MIN', 'MAX']
    },
    'Created At': {
      key: 'Created At',
      label: 'Date',
      id: 'created_at',
      type: 'DATETIME',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    'Is Private': {
      key: 'Is Private',
      label: 'Visibility',
      id: 'is_private',
      type: 'BOOL',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    Tags: {
      key: 'Tags',
      label: 'Tags',
      id: 'tags',
      type: 'STRING',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    Sentiment: {
      key: 'Sentiment',
      label: 'Sentiment',
      id: 'sentiment',
      type: 'INT',
      [ConfigType.Measure]: true,
      [ConfigType.Dimension]: true,
      aggregateFunctions: ['SUM', 'AVG', 'MIN', 'MAX']
    },
    Language: {
      key: 'Language',
      label: 'Language',
      id: 'language',
      type: 'STRING',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    Channel: {
      key: 'Channel',
      label: 'Channel',
      id: 'channel',
      type: 'STRING',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    Age: {
      key: 'Age',
      label: 'Age',
      id: 'age',
      type: 'INT',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    Gender: {
      key: 'Gender',
      label: 'Gender',
      id: 'gender',
      type: 'STRING',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    Location: {
      key: 'Location',
      label: 'Location',
      id: 'location',
      type: 'STRING',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    Emotion: {
      key: 'Emotion',
      label: 'Emotion',
      id: 'emotion',
      type: 'STRING',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    },
    // 'Content Vector': {
    //   key: 'Content Vector',
    //   label: 'Content Vector',
    //   id: 'content_vector',
    //   type: 'VECTOR',
    //   [ConfigType.Measure]: false,
    //   [ConfigType.Dimension]: true,
    //   aggregateFunctions: []
    // },
    Classification: {
      key: 'Classification',
      label: 'Classification',
      id: 'classification',
      type: 'STRING',
      [ConfigType.Measure]: false,
      [ConfigType.Dimension]: true,
      aggregateFunctions: []
    }
  };
  fieldsIterable = mapToIterable(this.fields);

  constructor() {}

  ngOnInit() {
    if (this.fieldOption.default) {
      this.selectedField = this.fieldOption.default;
      this.resetMeasure(true);
    }
    if (this.fieldOption && this.fieldOption.selectedValue) {
      this.selectedField = this.fieldOption.selectedValue;
    }
  }

  onFieldSelected() {
    this.isDropdownOpen = false;
    this.resetMeasure(true);
    this._emitFieldData();
  }

  onFieldClicked(clickedField: Field) {
    if (clickedField.key === this.selectedField) {
      this.selectedField = '';
      this.onFieldOptionChange.emit(null);
      this.resetMeasure(true);
    }
  }

  saveMeasure(measure: string) {
    this.chosenMeasure = measure;
    this.isDropdownMetaOpen = false;
    this._emitFieldData();
  }

  resetMeasure(updateSelected: boolean): void {
    if (this.fieldOption.disableMeasure) {
      return;
    }
    if (
      this.fields[this.selectedField] &&
      this.fields[this.selectedField].aggregateFunctions.length
    ) {
      this.measure = this.fields[this.selectedField].aggregateFunctions[0];
    } else {
      this.measure = '';
    }
    if (updateSelected) {
      this.chosenMeasure = this.measure;
    }
  }

  private _emitFieldData(): void {
    if (this.chosenMeasure) {
      this.onFieldOptionChange.emit({
        field: this.selectedField,
        function: this.chosenMeasure
      });
      return;
    }
    this.onFieldOptionChange.emit({ field: this.selectedField });
  }
}
