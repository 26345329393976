import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-tags-input-2',
  templateUrl: './tags-input-2.component.html',
  styles: [],
  styleUrls: ['./tags-input-2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagsInput2Component implements OnInit, OnChanges {
  @Input() tags: string[] = [];
  @Input() placeholder: string;
  @Input() disabled: boolean = false;
  @Output() onTagsChange = new EventEmitter<string[]>();
  @Input() showSubmit?: boolean = false;
  @Input() submitText?: string = 'Search';
  @Input() disableOnBlur?: boolean = false;
  @Output() onSubmit = new EventEmitter<string[]>();

  inputFocused: boolean = false;
  tagsInput = '';

  constructor(public modal: NgbModal) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tags'] && changes['tags'].currentValue) {
      this.tags.forEach((t) => {
        this.addTag(t);
      });
    }
  }

  ngOnInit(): void {}

  deleteTag(tag: string) {
    this.tags = this.tags.filter((iTag) => iTag !== tag);
    this.onTagsChange.emit(this.tags);
  }

  clearTags(): void {
    this.tags = [];
    this.tagsInput = '';
    this.onTagsChange.emit(this.tags);
  }

  addTag(tag: string) {
    if (!this.tags.includes(tag) && tag.trim()) {
      this.tags.push(tag);
      this.onTagsChange.emit(this.tags);
    }
    this.tagsInput = '';
  }

  onBlur() {
    this.inputFocused = false;
    if (this.disableOnBlur) {
      return;
    }
    if (this.tagsInput.length) {
      this.addTag(this.tagsInput);
      this.onTagsChange.emit(this.tags);
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    if (
      event.keyCode === 13 ||
      event.key === 'Enter' ||
      event.keyCode === 39 ||
      event.key === 'ArrowRight'
    ) {
      this.addTag(this.tagsInput);
    }
  }

  submitSearch($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.addTag(this.tagsInput);
    this.onSubmit.emit(this.tags);
  }
}
