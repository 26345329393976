import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { ExternalComponent } from './external.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'external',
          url: '/external/?params',
          component: ExternalComponent
        }
      ]
    })
  ],
  declarations: [ExternalComponent]
})
export class ExternalModule {}
