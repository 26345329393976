import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportConversationComponent } from './export-conversation.component';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, UIRouterModule, FormsModule, ReactiveFormsModule],
  declarations: [ExportConversationComponent],
  exports: [ExportConversationComponent]
})
export class ExportConversationModule {}
