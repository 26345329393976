import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';
import { API } from '@ui-resources-angular';
import { RedirectService } from '@orlo/common/services/redirect/redirect.service';

export function policyResultResolveFn(api) {
  return api.get('company/policy');
}

@Component({
  selector: 'ssi-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PolicyComponent implements OnInit {
  static resolve = [
    {
      token: 'policyResult',
      resolveFn: policyResultResolveFn,
      deps: [API]
    }
  ];

  @Input() policyResult;
  policy;

  constructor(private api: API, private redirect: RedirectService) {}

  ngOnInit() {
    this.policy = this.policyResult.data;
  }

  agree() {
    this.api.post('company/policy');
    this.redirect.goToHomePage();
  }
}
