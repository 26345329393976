import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';
import { Account } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { trackByProperty } from '../../../../../common/util';
import { serialiseTargetingOptions } from '../advertising-targeting/advertising-targeting.service';
import {
  AdvertisingPresetModelService,
  TargetingOption
} from '../advertising-preset-model/advertising-preset-model.service';
import {
  advertisingAccounts,
  advertisingTargetingPresets
} from '../../../../common-resolves';

@Component({
  selector: 'ssi-advertising-create-preset',
  templateUrl: './advertising-create-preset.component.html',
  styleUrls: ['./advertising-create-preset.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvertisingCreatePresetComponent implements OnInit {
  static resolve = [advertisingAccounts, advertisingTargetingPresets];

  @Input() advertisingAccounts: Account[];

  trackById = trackByProperty('id');

  newPreset: {
    name: string;
    account: Account;
    targetingOptions: TargetingOption[];
  } = {
    name: '',
    account: undefined,
    targetingOptions: []
  };

  constructor(
    private presetModel: AdvertisingPresetModelService,
    private state: StateService
  ) {}

  ngOnInit() {}

  isTargetingInvalid() {
    switch (this.newPreset.account.account_type_id) {
      case '8':
        const compulsoryOptions = this.newPreset.targetingOptions.filter(
          (target) => target.option.displayName === 'Locations'
        );
        return compulsoryOptions.length === 0 ? true : false;
        break;
      case '9':
        const facebookCompulsoryOptions = ['Country', 'Region', 'City'];
        const compulsoryChosen = this.newPreset.targetingOptions.some(
          (target) =>
            facebookCompulsoryOptions.includes(target.option.displayName)
        );
        return compulsoryChosen ? false : true;
        break;
      case '10':
        return this.newPreset.targetingOptions.length === 0 ? true : false;
        break;
      default:
        return true;
    }
  }

  async savePreset() {
    await this.presetModel.create({
      name: this.newPreset.name,
      account_id: this.newPreset.account.id,
      targeting_options: serialiseTargetingOptions(
        this.newPreset.targetingOptions
      )
    });
    this.state.go('auth.marketing.advertising.presetList');
  }
}
