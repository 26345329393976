import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Campaign, Account, AccountModel } from '@ui-resources-angular';
import { OutboxQueryFactoryService } from '@orlo/common/services/outbox-query-factory/outbox-query-factory.service';
import { Colleague } from '@orlo/common/services/api';
import { onlyUnique } from '@orlo/common/util';
import {
  INBOX_REPLY_ALL_TYPES,
  INBOX_REPLY_PRIVATE_TYPES,
  INBOX_REPLY_PUBLIC_TYPES
} from '@orlo/common/constants';

export interface VisibilityOption {
  key: 'all' | 'public' | 'private';
  label: string;
}

export interface VisibilityOptions {
  all: VisibilityOption;
  public: VisibilityOption;
  private: VisibilityOption;
}

export const visibilityOptions: VisibilityOptions = {
  all: {
    key: 'all',
    label: 'All messages'
  },
  public: {
    key: 'public',
    label: 'Public messages only'
  },
  private: {
    key: 'private',
    label: 'Private messages only'
  }
};


export const visibilities: Array<VisibilityOption> = [
  {
    key: 'all',
    label: 'All messages'
  },
  {
    key: 'private',
    label: 'Private messages only'
  },
  {
    key: 'public',
    label: 'Public messages only'
  }
];

export interface ValidationFilters {
  account: Array<string>;
  networks: Array<string>;
  users: any[];
  visibility: 'all' | 'private' | 'public';
}

@Component({
  selector: 'ssi-validate-replies-filters',
  templateUrl: './validate-replies-filters.component.html',
  styles: [],
  styleUrls: ['./validate-replies-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidateRepliesFiltersComponent implements OnInit {
  @Input() workflowAccounts: Account[];
  @Input() campaigns: Campaign[];
  @Input() colleagues: Colleague[];
  @Input() authUser: any;
  @Input() validations: any;
  @Input() isLoading: boolean;
  @Input() postPerPage: number;
  @Output() validationsChange = new EventEmitter();
  @Output() isLoadingChange = new EventEmitter();
  @Output() updatePagination = new EventEmitter();

  visibilities = visibilities;

  activeColleagues: Colleague[];
  allAccounts: Account[];
  allNetworks: Array<{ id: string; label: string; icon: string }>;
  showFilters: 'parent' | 'account' | 'users' | 'networks' | 'visibility';
  filters: ValidationFilters;
  activeFilters: ValidationFilters;

  constructor(
    private outboxQueryFactory: OutboxQueryFactoryService,
    private accountModel: AccountModel
  ) {}

  ngOnInit() {
    const networksWithDuplicates = this.workflowAccounts.map((acc) => {
      return {
        id: acc.account_type_id,
        label: acc.accountTypeLabel,
        icon: acc.socialNetwork.accountTypeLabel
      };
    });

    this.allNetworks = onlyUnique(networksWithDuplicates);

    this.showFilters = 'parent';

    this.filters = {
      account: this.workflowAccounts.map((account) => account.id),
      networks: [],
      users: [],
      visibility: 'all'
    };
    this.activeFilters = {
      account: this.workflowAccounts.map((account) => account.id),
      networks: [],
      users: [],
      visibility: 'all'
    };
    this.getFilterList();
  }

  getPostQuery() {
    const outboxQuery = this.outboxQueryFactory.create({
      allAccounts: this.workflowAccounts,
      authUser: this.authUser
    });

    outboxQuery.reset();
    outboxQuery.params.validated = '0';
    outboxQuery.isValidationSearch = true;
    outboxQuery.params.types = INBOX_REPLY_ALL_TYPES.map((type) => type.value);
    if (this.filters.account.length) {
      outboxQuery.params.accounts = this.filters.account;
    }
    if (this.filters.networks.length) {
      outboxQuery.params.accounts = this.filters.account.filter((accId) =>
        this.filters.networks.includes(
          this.accountModel.get(accId).account_type_id
        )
      );
    }
    if (this.filters.users && this.filters.users.length) {
      outboxQuery.params.created_by = 'user:' + this.filters.users;
    }
    if (this.filters.visibility && this.filters.visibility.length) {
      if (this.filters.visibility === visibilityOptions.private.key) {
        outboxQuery.params.types = INBOX_REPLY_PRIVATE_TYPES.map(
          (type) => type.value
        );
      } else if (this.filters.visibility === visibilityOptions.public.key) {
        outboxQuery.params.types = INBOX_REPLY_PUBLIC_TYPES.map(
          (type) => type.value
        );
      } else {
        outboxQuery.params.types = INBOX_REPLY_ALL_TYPES.map(
          (type) => type.value
        );
      }
    }
    outboxQuery.params.deleted = 'exclude';
    outboxQuery.filters = outboxQuery.filters.filter(
      (filter) => !['validated', 'created_by', 'deleted'].includes(filter.key)
    );
    return outboxQuery;
  }

  public async updateValidations(paginationNumber) {
    const offset = paginationNumber * this.postPerPage - this.postPerPage;
    if (this.filters.account.length === 0) {
      return;
    }
    const outboxQuery = this.getPostQuery();
    await outboxQuery.search(offset, this.postPerPage);

    this.validations = [];
    this.validations.push(...outboxQuery.outboxPosts);
    this.validationsChange.emit(this.validations);

    if (paginationNumber === 1) {
      this.updatePagination.emit(outboxQuery.interactionTotals.total);
    }

    this.isLoading = false;
    this.isLoadingChange.emit(this.isLoading);

    this.showFilters = 'parent';
    this.activeFilters = this.filters;
  }

  public getFilterValues(e, filterType: string) {
    if (e.target.checked) {
      this.filters[filterType].push(e.target.value);
    } else {
      this.filters[filterType] = this.filters[filterType].filter((value) =>
        value === e.target.value ? false : true
      );
    }
  }

  public changeFilters(filterSet) {
    this.showFilters = filterSet;
    this.getFilterList();
  }

  public getFilterList() {
    this.activeColleagues = this.colleagues.filter(
      (colleague) => colleague.is_active
    );
    this.allAccounts = this.workflowAccounts;
  }

  toggleOptionRadioValue(event, filterSet) {
    if (event.target.id === this.filters[filterSet]) {
      this.filters[filterSet] = [];
    }
  }

  filterByText(event, filterList) {
    this.getFilterList();
    const inputValue = event.target.value;
    const regex = new RegExp(inputValue, 'gi');
    this[filterList] = this[filterList].filter((item) => {
      switch (filterList) {
        case 'allAccounts':
          return item.displayName.match(regex) && item;
          break;
        case 'activeColleagues':
          return item.fullName.match(regex) && item;
          break;
        default:
          return;
      }
    });
  }

  toggleFilterBool(filterSet) {
    this.filters[filterSet] = !this.filters[filterSet];
    this.activeFilters = this.filters;
  }

  toggleSelectAll(action: 'select' | 'deselect', filterSet) {
    if (action === 'select') {
      if (filterSet === 'account') {
        this.filters[filterSet] = this.workflowAccounts.map(
          (account) => account.id
        );
      } else if (filterSet === 'networks') {
        this.filters[filterSet] = this.allNetworks.map((n) => n.id);
      }
    }
    if (action === 'deselect') {
      this.filters[filterSet] = [];
    }
    this.activeFilters = this.filters;
  }
}
