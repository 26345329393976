import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  EventEmitter,
  Output,
  Inject,
  OnChanges,
  HostListener,
  ViewChild,
  OnInit,
  OnDestroy,
  ElementRef,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import {
  Activity,
  ActivityModel,
  ActivityStatus,
  ConversationVisibility,
  Conversation,
  ConversationStatus
} from '@ui-resources-angular';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SENTIMENT_CONFIG } from '../../../../common/constants';
import { InboxMode } from '../inbox.component';
import {
  KeyValueObject,
  trackByIndex,
  trackByProperty
} from '../../../../common/util';
import { Observable } from 'rxjs/Observable';
import { mergeMap } from 'rxjs/operators/mergeMap';
import { VirtualScrollComponent } from '../../../../common/components/virtual-scroll/virtual-scroll.component';
import {
  InboxActivityQuery,
  InboxConversationQuery
} from '../../../../common/services/inbox-query-factory/inbox-query-factory.service';
import {
  InboxQueryResultListItem,
  InboxQueryResultListItemType,
  InboxQueryType
} from '../../../../common/services/inbox-query-factory/queries/common';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague,
  AuditEventsService,
  AuditEventVerb
} from '../../../../common/services/api';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { filter } from 'rxjs/operators/filter';
import { timer } from 'rxjs/observable/timer';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../../common/services/notification/notification.service';

export const DIVIDER_HEIGHT = 25;
export const BASE_LIST_ITEM_HEIGHT = 105 + 8; // (8px margin bottom)
export const MEDIA_CONTAINER_HEIGHT = 115;

enum ArrowKeyDirection {
  Up = 'up',
  Down = 'down'
}

@Component({
  selector: 'ssi-inbox-query-result-list',
  templateUrl: './inbox-query-result-list.component.html',
  styleUrls: ['./inbox-query-result-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxQueryResultListComponent
  implements OnChanges, OnInit, OnDestroy {
  @Input() activeConversationThread;
  @Input() canMultiSelectResults: boolean;
  @Input() inboxMode: InboxMode;
  @Input() inboxQuery: InboxActivityQuery | InboxConversationQuery;
  @Input() isConversationThreadActive: boolean;
  @Input() multiSelectedResults: InboxQueryResultListItem[];
  @Input() changeActivity: EventEmitter<void>;
  @Input() onExitConversation: () => void;
  @Input() selectedSortOption;
  @Input() conversationThreadChange: EventEmitter<void>;
  @Input() isSocialPushMode: boolean;
  @Input() activeList: boolean;
  @Input() newIncomingReply: InboxQueryResultListItem;

  @Output() activeConversationThreadChange = new EventEmitter();
  @Output() multiSelectResult = new EventEmitter<InboxQueryResultListItem>();
  @Output() multiDeselectResult = new EventEmitter<InboxQueryResultListItem>();
  @Output() conversationEntered = new EventEmitter();

  @ViewChild(VirtualScrollComponent) virtualScroll: VirtualScrollComponent;

  multiSelectedResultIds: KeyValueObject<boolean> = {};

  lastMultiSelectedResult: InboxQueryResultListItem;

  sentimentConfigArray = Object.entries(
    SENTIMENT_CONFIG
  ).map(([key, config]) => ({ key, config }));

  sentimentConfigObject = SENTIMENT_CONFIG;

  viewPortItems: InboxQueryResultListItem[] = [];

  trackByIndex = trackByIndex;

  trackByUid = trackByProperty('uid');

  InboxQueryResultListItemType = InboxQueryResultListItemType;

  ConversationVisibility = ConversationVisibility;

  InboxQueryType = InboxQueryType;

  changeSentimentTrigger: ElementRef;

  assignToTrigger: ElementRef;
  isConversationThreadNewlyOpen = false;
  conversationsBeingActioned = {};

  quickActionsMenu: {
    sentiment: {
      active?: {
        item: InboxQueryResultListItem;
      };
    };
    assign: {
      active?: {
        item: InboxQueryResultListItem;
        assignedToUserOrTeam: Colleague | Team | null;
      };
    };
  } = {
    sentiment: {},
    assign: {}
  };

  onDestroy = new Subject();

  private _inboxResultsSubscription: Subscription;

  constructor(
    private activityModel: ActivityModel,
    @Inject(DOCUMENT) private document,
    private translate: TranslateService,
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService,
    private auditEventsService: AuditEventsService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  getAssignTo = (searchText$: Observable<string>) => {
    return searchText$.pipe(
      mergeMap((searchText = '') => {
        const promise = Promise.all([
          this.colleaguesService.getAllActive(),
          this.teamsService.getAllActive()
        ]).then(([colleagues, teams]: [Colleague[], Team[]]) => {
          const text = searchText.toLowerCase();
          const matchingColleagues = colleagues.filter((colleague) => {
            return (
              colleague.fullName.toLowerCase().includes(text) ||
              colleague.email_address.toLowerCase().includes(text)
            );
          });
          const matchingTeams = teams.filter((team) =>
            team.name.toLowerCase().includes(text)
          );
          return [...matchingColleagues, ...matchingTeams];
        });
        return fromPromise(promise);
      })
    );
  };

  formatAssignTo = (value) => {
    if (value instanceof Colleague) {
      return value.fullName;
    } else if (value instanceof Team) {
      return value.name;
    }
  };

  get isConversationMode() {
    return this.inboxMode === 'conversation';
  }

  isItemAConversationMessage(item) {
    if (!this.isConversationMode) {
      return false;
    }

    if (!this.isConversationThreadActive) {
      return false;
    }

    if (item.type !== InboxQueryResultListItemType.Activity) {
      return false;
    }

    if (!!this.inboxQuery && this.inboxQuery.type !== InboxQueryType.Activity) {
      return false;
    }

    return true;
  }

  isItemAConversationThread(item) {
    if (!this.isConversationMode) {
      return false;
    }

    if (this.isConversationThreadActive) {
      return false;
    }

    if (item.type !== InboxQueryResultListItemType.Conversation) {
      return false;
    }

    if (
      !!this.inboxQuery &&
      this.inboxQuery.type !== InboxQueryType.Conversation
    ) {
      return false;
    }

    return true;
  }

  isItemAMidActionConversation(item) {
    if (!item || !item.type || item.type !== 'conversation') {
      return;
    }

    return this.conversationsBeingActioned.hasOwnProperty(
      `${item.result.conversation.account_id}${item.result.conversation.thread_id}`
    );
  }

  getTotalMessageCountForConversation(item) {
    return (
      item.result.conversation.count_unread +
      item.result.conversation.count_unactioned +
      item.result.conversation.count_actioned
    );
  }

  isAssignToolVisibleForItem(item) {
    return (
      (!this.isConversationMode && !item.result.conversation) ||
      (this.isConversationMode && item.result.activity)
    );
  }

  ngOnInit(): void {
    this.newIncomingReply = undefined;
  }

  ngOnDestroy(): void {
    if (!!this._inboxResultsSubscription) {
      this._inboxResultsSubscription.unsubscribe();
    }

    this.onDestroy.next();
  }

  ngOnChanges(changes): void {
    if (changes.isConversationThreadActive) {
      // scroll to active item
      if (
        !changes.isConversationThreadActive.currentValue &&
        !!changes.isConversationThreadActive.previousValue
      ) {
        requestAnimationFrame(() => {
          this.virtualScroll.scrollToItem(this.activeConversationThread);
          this.activeConversationThread = undefined;
        });
      } else if (
        !!changes.isConversationThreadActive.currentValue &&
        !changes.isConversationThreadActive.previousValue
      ) {
        this.isConversationThreadNewlyOpen = true;
      }
    }

    if (changes.multiSelectedResults) {
      this.multiSelectedResultIds = {};
      this.multiSelectedResults.forEach((result) => {
        this.multiSelectedResultIds[result.uid] = true;
      });
    }

    if (
      changes.inboxQuery &&
      changes.inboxQuery.currentValue &&
      this.inboxQuery.activeResult
    ) {
      // console.log('this.inboxQuery changes: ', this.inboxQuery);
      this._inboxResultsSubscription = this.inboxQuery.activeResult.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .pipe(mergeMap(() => timer(1))) // cant use delay because of https://github.com/angular/angular/issues/10127
        .pipe(
          filter(() => {
            return !!this.inboxQuery.activeResult.value && !!this.virtualScroll; // in case the element was destroyed before the timeout fires
          })
        )
        .subscribe(() => {
          let scrollToItem = this.inboxQuery.activeResult.value;
          const index = this.inboxQuery.result.list.indexOf(
            this.inboxQuery.activeResult.value
          );
          const previousItem = this.inboxQuery.result.list[index - 1];
          if (
            previousItem &&
            previousItem.type === InboxQueryResultListItemType.Divider
          ) {
            scrollToItem = previousItem;
          }

          this.virtualScroll.scrollToItem(scrollToItem);
        });
    }

    if (this.newIncomingReply) {
      const newItem = this.newIncomingReply;
      const type = this.inboxQuery.type;

      if (type === 'activity') {
        if (
          newItem.result.activity.conversation.thread_id ===
          this.inboxQuery.activeResult.value.result.activity.conversation
            .thread_id
        ) {
          console.log('should add item', newItem);
          this.cdr.detectChanges();

          const sortOrder = this.inboxQuery.params.order;

          switch (sortOrder) {
            case 'desc':
              this.inboxQuery.result.list.unshift(newItem);
              break;
            case 'asc':
              this.inboxQuery.result.list.push(newItem);
              break;
          }

          this.viewPortItemsUpdated(this.inboxQuery.result.list);

          let scrollToItem = newItem;
          const index = this.inboxQuery.result.list.indexOf(newItem);

          switch (sortOrder) {
            case 'desc':
              this.virtualScroll.refresh();
              this.virtualScroll.scrollToItem(index);
              break;
            case 'asc':
              const previousItem = this.inboxQuery.result.list[index - 1];
              if (
                previousItem &&
                previousItem.type === InboxQueryResultListItemType.Divider
              ) {
                scrollToItem = previousItem;
              }

              this.virtualScroll.refresh();
              this.virtualScroll.scrollToItem(scrollToItem);
              break;
          }
        }
      }
      this.newIncomingReply = undefined; // reset
    }
  }

  hideAssignConversationTool() {
    if (!this.isConversationMode) {
      return;
    }

    this.quickActionsMenu.assign.active = null;
  }

  itemClicked(item: InboxQueryResultListItem) {
    this.conversationEntered.emit();
    const validItemTypes = [
      InboxQueryResultListItemType.Activity,
      InboxQueryResultListItemType.Conversation
    ];

    const isItemClickable = validItemTypes.includes(item.type);
    if (!isItemClickable) {
      return;
    }

    if (this.changeActivity !== undefined) {
      this.changeActivity.emit();
    }

    if (!!this.inboxQuery) {
      if (this.inboxQuery.activeResult.value === item) {
        this.inboxQuery.activeResult.value = null;
      } else {
        this.inboxQuery.activeResult.value = item;
      }
    }

    if (this.inboxMode === 'conversation') {
      if (item.type === InboxQueryResultListItemType.Conversation) {
        this.activeConversationThreadChange.emit(item);
      } else {
        this.conversationThreadChange.emit();
      }
    }
  }

  getHeight(item: InboxQueryResultListItem): number {
    if (item.type === InboxQueryResultListItemType.Divider) {
      return DIVIDER_HEIGHT;
    } else {
      return (
        BASE_LIST_ITEM_HEIGHT +
        (!!item.result.media.image.length || !!item.result.media.video.length
          ? MEDIA_CONTAINER_HEIGHT
          : 0)
      );
    }
  }

  openTopMostItem() {
    const activities = this.viewPortItems.filter(
      (item) => item.type === 'activity'
    );

    if (activities.length > 0) {
      this.itemClicked(activities[0]);
    }
  }

  toggleActivityStatus(activity: Activity) {
    if (
      activity.statusText === ActivityStatus.Unread ||
      activity.statusText === ActivityStatus.Unactioned
    ) {
      activity.changeStatus(ActivityStatus.Actioned);
    } else if (activity.statusText === ActivityStatus.Actioned) {
      activity.changeStatus(ActivityStatus.Unread);
    }
  }

  toggleConversationStatus(conversation: Conversation) {
    if (conversation.status === ConversationStatus.Resolved) {
      return;
    }

    if (
      conversation.status === ConversationStatus.Actioned ||
      conversation.status === ConversationStatus.OnHold
    ) {
      const opts = { with_survey: false };
      conversation.resolve(opts).then(() => {
        this.showConversationResolvedOrActionedNotification(conversation);
      });
    } else {
      // ConversationStatus.Unread | ConversationStatus.Unactioned
      const parameters = {
        ac: { [conversation.account_id]: 1 },
        root_thread_id: conversation.thread_id
      };

      const id = `${conversation.account_id}${conversation.thread_id}`;

      this.conversationsBeingActioned[id] = true;

      this.activityModel.markAllActioned(parameters).then(() => {
        delete this.conversationsBeingActioned[id];
        this.showConversationResolvedOrActionedNotification(conversation);
      });
    }
  }

  showConversationResolvedOrActionedNotification(
    conversation: Conversation
  ): void {
    this.notificationService.open(
      `Your conversation has been ${
        conversation.status === ConversationStatus.Actioned
          ? 'actioned'
          : 'resolved'
      }!`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      1000
    );
  }

  toggleMultiSelectedResult(
    result: InboxQueryResultListItem,
    $event: MouseEvent
  ) {
    // inbox widget doesnt support multi selecting or deselecting
    if (this.canMultiSelectResults) {
      if (!this.multiSelectedResults.includes(result)) {
        const selectAllPrevious =
          $event.shiftKey &&
          this.lastMultiSelectedResult &&
          this.multiSelectedResults.includes(this.lastMultiSelectedResult);

        this.multiSelectResult.emit(result);

        if (selectAllPrevious) {
          const resultIndex = this.inboxQuery.result.list.indexOf(result);
          const lastSelectedResultIndex = this.inboxQuery.result.list.indexOf(
            this.lastMultiSelectedResult
          );
          const sliceFrom = Math.min(resultIndex, lastSelectedResultIndex);
          const sliceTo = Math.max(resultIndex, lastSelectedResultIndex);
          this.inboxQuery.result.list
            .slice(sliceFrom, sliceTo)
            .filter(
              (iResult) =>
                iResult !== result && iResult !== this.lastMultiSelectedResult
            )
            .forEach((resultToMultiSelect) => {
              this.multiSelectResult.emit(resultToMultiSelect);
            });
        }

        this.lastMultiSelectedResult = result;
      } else {
        this.multiDeselectResult.emit(result);
        this.lastMultiSelectedResult = null;
      }

      // stop highlighting the text
      if ($event.shiftKey) {
        this.document.getSelection().removeAllRanges();
      }
    }
  }

  startAssignTo(item: InboxQueryResultListItem) {
    let assignedToUserOrTeam;
    if (item.type === InboxQueryResultListItemType.Activity) {
      assignedToUserOrTeam =
        item.result.activity.assignedToUser ||
        item.result.activity.assignedToTeam;
    } else {
      assignedToUserOrTeam =
        item.result.conversation.assignedToUser ||
        item.result.conversation.assignedToTeam;
    }
    this.quickActionsMenu.assign = {
      active: {
        item,
        assignedToUserOrTeam
      }
    };
  }

  saveAssignTo(
    item: InboxQueryResultListItem,
    assignTo: Colleague | Team | undefined
  ) {
    if (item.type === InboxQueryResultListItemType.Activity) {
      item.result.activity.assignTo(assignTo);
    }

    if (item.type === InboxQueryResultListItemType.Conversation) {
      item.result.conversation.assignTo(assignTo);
    }

    this.quickActionsMenu.assign.active = null;
  }

  showAssignConversationTool(item: InboxQueryResultListItem) {
    if (this.inboxMode !== 'conversation') {
      return;
    }

    this.startAssignTo(item);
  }

  toggleSentimentMenu(item: InboxQueryResultListItem) {
    if (
      !this.quickActionsMenu.sentiment.active ||
      this.quickActionsMenu.sentiment.active.item !== item
    ) {
      this.quickActionsMenu.sentiment = {
        active: {
          item
        }
      };
    } else {
      this.quickActionsMenu.sentiment.active = null;
    }
  }

  saveSentiment(item: InboxQueryResultListItem, value: number) {
    item.result.activity.changeSentiment(value);
    this.quickActionsMenu.sentiment.active = null;
  }

  viewPortItemsUpdated($event) {
    this.viewPortItems = $event;

    if (this.isConversationMode && !this.isConversationThreadActive) {
      this.openTopMostItem();
    }
  }

  private handleArrowKeyNavigation(event, direction: ArrowKeyDirection) {
    const focusedElementTagName = event.target.tagName.toLowerCase();
    const ignoreElements = ['textarea', 'button', 'a', 'input'];

    if (
      this.inboxQuery.activeResult.value &&
      !ignoreElements.includes(focusedElementTagName)
    ) {
      event.preventDefault();

      let index = this.inboxQuery.result.list.indexOf(
        this.inboxQuery.activeResult.value
      );

      if (direction === ArrowKeyDirection.Up) {
        index--;
        if (
          this.inboxQuery.result.list[index] &&
          this.inboxQuery.result.list[index].type ===
            InboxQueryResultListItemType.Divider
        ) {
          index--;
        }
      } else if (direction === ArrowKeyDirection.Down) {
        index++;
        if (
          this.inboxQuery.result.list[index] &&
          this.inboxQuery.result.list[index].type ===
            InboxQueryResultListItemType.Divider
        ) {
          index++;
        }
      }

      if (this.inboxQuery.result.list[index]) {
        this.inboxQuery.activeResult.value = this.inboxQuery.result.list[index];
      }
    }
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  onArrowUp(event: MouseEvent) {
    this.handleArrowKeyNavigation(event, ArrowKeyDirection.Up);
  }

  @HostListener('document:keydown.ArrowDown', ['$event'])
  onArrowDown(event: MouseEvent) {
    this.handleArrowKeyNavigation(event, ArrowKeyDirection.Down);
  }

  onMediaRevealed(activity: Activity) {
    activity.sensitiveContentRevealed = true;

    this.auditEventsService.createActivityEvent(
      activity.id,
      AuditEventVerb.SensitiveMediaViewed
    );
  }

  setItemExpanded(item: InboxQueryResultListItem, expanded: boolean) {
    if (expanded) {
      item.expandTimeoutId = setTimeout(() => {
        item.expanded = true;
      }, 600);
    } else {
      clearTimeout(item.expandTimeoutId);
      item.expanded = false;
    }
  }
}
