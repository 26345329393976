import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import Highcharts, { Options } from 'highcharts';
import { HighchartsHelperService } from '../../../../../../common/services/highcharts-helper/highcharts-helper.service';
import { FilterPeriod } from '../../chatbot-analytics.service';

@Component({
  selector: 'ssi-top-topics',
  templateUrl: './top-topics.component.html',
  styles: [],
  styleUrls: ['./top-topics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopTopicsComponent implements OnInit {
  @Input() topTopics: {
    name: string;
    count: { [date: string]: number };
    color: string;
  }[];
  @Input() filterPeriod: FilterPeriod;
  proTipOpen = false;

  topTopicsChartConfig: Options;

  constructor(private highchartsHelper: HighchartsHelperService) {}

  ngOnInit(): void {
    this.topTopicsChartConfig = this.formatDataForTopTopicsChart();
  }

  private formatDataForTopTopicsChart(): any {
    return {
      chart: {
        type: 'spline',
        spacing: [60, 40, 20, 40],
        zoomType: 'x'
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          },
          lineWidth: 2,
          pointStart: Date.parse(this.filterPeriod.start),
          pointInterval: 24 * 3600 * 1000
        }
      },
      title: {
        text: null
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolSize: 15,
            symbolStroke: '#838EAB',
            x: 25,
            y: -35
          }
        }
      },
      legend: {
        useHTML: true,
        alignColumns: false,
        itemStyle: {
          color: '#43537F',
          fontSize: '10px'
        },
        itemMarginTop: 5,
        itemMarginBottom: 5,
        margin: 40
      },
      xAxis: {
        type: 'datetime',
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: 'Topic count',
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          },
          margin: 30
        },
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            textTransform: 'lowercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        alternateGridColor: '#F8F9FD'
      },
      series: this.topTopics.map((topicObj) => {
        return {
          name: topicObj.name,
          color: topicObj.color,
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            topicObj.count,
            this.filterPeriod
          )
        };
      }),
      credits: {
        enabled: false
      },
      time: {
        useUTC: false
      }
    };
  }
}
