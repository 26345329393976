import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { User, UserModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-inbox-useful-things',
  templateUrl: './inbox-useful-things.component.html',
  styleUrls: ['./inbox-useful-things.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxUsefulThingsComponent implements OnInit {
  @Input() isPopupTemplate: boolean;
  @Input() tutorialVideos: any[];
  @Output() onClose = new EventEmitter();
  @Output() onShowVideoTutorial = new EventEmitter();
  @Output() onExportInbox = new EventEmitter();
  @Output() onMarkAllActioned = new EventEmitter();
  @Output() onMarkAllUnactioned = new EventEmitter();

  authUser: User;
  showUsefulThings = false;

  constructor(private userModel: UserModel) {}

  async ngOnInit() {
    this.userModel.getAuthUser().then((user) => (this.authUser = user));
  }
}
