import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ssi-outbox-post-new-stat',
  templateUrl: './outbox-post-new-stat.component.html',
  styleUrls: ['./outbox-post-new-stat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutboxPostNewStatComponent implements OnInit {
  @Input() statLabel: string;
  @Input() statFigure: string;
  @Input() dividerColor: string;

  constructor() {}

  ngOnInit() {}
}
