import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineNotesComponent } from './inline-notes.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotesModule } from '../notes/notes.module';
import { NotesTextareaModule } from '../notes-textarea/notes-textarea.module';
import { InlineNoteModule } from './inline-note/inline-note.module';
import { InlineAddNoteModule } from './inline-add-note/inline-add-note.module';
import { OrloComponentsModule } from 'orlo-components';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InlineAddNoteModule,
    InlineNoteModule,
    NotesModule,
    NotesTextareaModule,
    OrloComponentsModule
  ],
  declarations: [InlineNotesComponent],
  exports: [InlineNotesComponent]
})
export class InlineNotesModule {}
