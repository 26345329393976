import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-reports',
  templateUrl: './reports.component.html',
  styles: [],
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
