import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { Nl2brModule } from '../../../pipes/nl2br/nl2br.module';

import { LiveChatNoteFormComponent } from './live-chat-note-form.component';

@NgModule({
  declarations: [LiveChatNoteFormComponent],

  exports: [LiveChatNoteFormComponent],

  imports: [
    CommonModule,
    FormsModule,
    Nl2brModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class LiveChatNoteFormModule {}
