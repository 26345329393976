import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AccountModel, Account } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import { ReportBuildFormValue } from '../../common/report-build-from/report-build-from.component';
import { decodeStateParams } from '@orlo/common/utils';
import { AccountTypeId } from '../../../../../common/enums';
import { commonDateRanges } from '../../../../../common/constants/common-date-ranges';
import { compareToOptions } from '../../../../../common/constants/compare-to-options';

@Component({
  selector: 'ssi-build',
  templateUrl: './build.component.html',
  styles: [],
  styleUrls: ['./build.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BuildComponent implements OnInit {
  formValue?: ReportBuildFormValue;
  AccountTypeId = AccountTypeId;
  decodedStateParams: { [key: string]: any };

  constructor(
    protected state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    if (
      this.decodedStateParams.accountIds &&
      this.decodedStateParams.startDate &&
      this.decodedStateParams.endDate
    ) {
      // edit mode
      const accountIds = Array.isArray(this.decodedStateParams.accountIds)
        ? this.decodedStateParams.accountIds
        : [this.decodedStateParams.accountIds];

      this.formValue = {
        accounts: accountIds.map((id) => this.accountModel.get(id)),
        startDate: new Date(this.decodedStateParams.startDate),
        endDate: new Date(this.decodedStateParams.endDate),
        previousStartDate:
          this.decodedStateParams.previousStartDate &&
          new Date(this.decodedStateParams.previousStartDate),
        previousEndDate:
          this.decodedStateParams.previousEndDate &&
          new Date(this.decodedStateParams.previousEndDate),
        dateRange: commonDateRanges[this.decodedStateParams.dateRange],
        compareToOption: compareToOptions[this.decodedStateParams.compareTo],
        visibilityOption: undefined,
        tagsToInclude: undefined,
        tagsToExclude: undefined
      };
    }
  }

  onFormSubmit(output: ReportBuildFormValue) {
    console.log('form output: ', output);

    this.state.go('auth.analytics.survey.report', {
      accountIds: output.accounts.map((a) => a.id),
      startDate: output.startDate.toISOString(),
      endDate: output.endDate.toISOString(),
      dateRange: output.dateRange && output.dateRange.id
    });
  }
}
