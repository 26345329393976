import { ViewEncapsulation } from '@angular/core';

import { Component } from '@angular/core';

@Component({
  selector: 'ssi-advertising-not-purchased',
  templateUrl: './advertising-not-purchased.component.html',
  styles: [],
  styleUrls: ['./advertising-not-purchased.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvertisingNotPurchasedComponent {
  constructor() {}
}
