import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AdvertisingBoostPostComponent } from './advertising-boost-post.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { flatpickrFactory } from '@orlo/common/components/publisher/publisher.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AdvertisingTargetingBuilderModule } from '../advertising-targeting-builder/advertising-targeting-builder.module';
import { AddSecondsModule } from '@orlo/common/pipes/add-seconds/add-seconds.module';
import { FormatBudgetModule } from '@orlo/common/pipes/format-budget/format-budget.module';
import { OutboxPostModule } from '@orlo/common/components/outbox-post/outbox-post.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.advertising.boostPost',
          url: '/boost-post/:outboxId',
          component: AdvertisingBoostPostComponent,
          resolve: AdvertisingBoostPostComponent.resolve
        },
        {
          name: 'auth.marketing.advertising.boostPosts',
          url: '/boost-posts?outboxIds',
          component: AdvertisingBoostPostComponent,
          resolve: AdvertisingBoostPostComponent.resolve,
          params: {
            outboxIds: { array: true }
          }
        }
      ]
    }),
    FlatpickrModule.forRoot(),
    AdvertisingTargetingBuilderModule,
    AddSecondsModule,
    FormatBudgetModule,
    OutboxPostModule
  ],
  declarations: [AdvertisingBoostPostComponent],
  entryComponents: [AdvertisingBoostPostComponent]
})
export class AdvertisingBoostPostModule {}
