import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'ssi-cl-accordion',
  templateUrl: './cl-accordion.component.html',
  styles: [],
  styleUrls: ['./cl-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClAccordionComponent implements OnInit {
  accordionOpen = 0;

  constructor() {}

  ngOnInit() {}
}
