import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionService, StateService } from '@uirouter/angular';

@Component({
  selector: 'ssi-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit, OnDestroy {
  activeUrl: string;
  private $routeChange;

  constructor(
    public transition: TransitionService,
    public state: StateService,
    public location: Location
  ) {}

  ngOnInit() {
    this.activeUrl = this.state.current.name;

    this.$routeChange = this.transition.onSuccess({}, (transition) => {
      this.activeUrl = transition.to().name;
    });
  }

  ngOnDestroy() {
    this.$routeChange();
  }

  return() {
    window.history.back();
  }
}
