import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-wave-dots',
  templateUrl: './wave-dots.component.html',
  styleUrls: ['./wave-dots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WaveDotsComponent implements OnInit {
  @Input() color = '#FFF';

  ngOnInit() {}
}
