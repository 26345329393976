import { Component, Input, OnChanges, DoCheck, ViewEncapsulation } from '@angular/core';

import { Agent } from '@orlo/library/models/live-chat/agent';
import { Visitor } from '@orlo/library/models/live-chat/visitor';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { defaults } from '@orlo/library/constants/live-chat';
import { Person } from '@orlo/library/models/live-chat/person';

@Component({
  selector: 'ssi-live-chat-avatar',
  templateUrl: './live-chat-avatar.component.html',
  styles: [],
  styleUrls: ['./live-chat-avatar.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatAvatarComponent implements DoCheck, OnChanges {
  @Input() public statistic: number;
  @Input() public user: Person;

  private _fullPerson: Person;
  private _previousFullPerson: Person;

  constructor(private liveChatService: LiveChatService) {}

  public get class(): string {
    if (!this.fullPerson || !this.fullPerson.hasNetworkState) {
      return;
    }

    return this.fullPerson.networkStateValue;
  }

  public get isAgent(): boolean {
    return this.fullPerson instanceof Agent;
  }

  public get isVisitor(): boolean {
    return this.fullPerson instanceof Visitor;
  }

  public get isOfflineAgent(): boolean {
    return this.isAgent && this.fullPerson.isOffline;
  }

  public get isVerifiedVisitor(): boolean {
    if (!this.isVisitor) {
      return false;
    }

    const visitor = this.fullPerson as Visitor;

    return !!visitor.isLoggedIn;
  }

  public get name() {
    return this.fullPerson.displayName;
  }

  public get url() {
    if (!this.fullPerson) {
      return;
    }

    if (
      !!this.fullPerson.avatar &&
      this.fullPerson.avatar !== defaults.avatar
    ) {
      return this.fullPerson.avatar;
    }

    if (this.fullPerson instanceof Agent) {
      const agent = this.liveChatService.agents.get(this.fullPerson.id);

      if (!!agent) {
        return agent.avatar;
      }
    }

    return defaults.avatar;
  }

  public get fullPerson() {
    return this._fullPerson;
  }

  public ngDoCheck() {}

  public ngOnChanges(changes) {
    if (!!changes.user && !!changes.user.currentValue) {
      this._previousFullPerson = this._fullPerson = this.liveChatService.getFullModelForPerson(
        changes.user.currentValue
      );
    }
  }
}
