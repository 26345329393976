import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { EngagementComponent } from './engagement.component';
import { EngagementAnalyticsService } from './engagement.service';
import { EngagementBuildComponent } from './build/engagement-build.component';
import { EngagementReportComponent } from './report/engagement-report.component';
import { AccountSummaryCardModule } from '../common/account-summary-card/account-summary-card.module';
import { StatBarTileModule } from '../common/stat-bar-tile/stat-bar-tile.module';
import { AccountPillModule } from '../common/account-pill/account-pill.module';
import { TotalStatCardModule } from '../common/total-stat-card/total-stat-card.module';
import { ReportHeaderModule } from '../common/report-header/report-header.module';
import { ReportSidebarModule } from '../common/report-sidebar/report-sidebar.module';

import { AccountSummaryComponent } from './report/account-summary/account-summary.component';
import { InboxActivityComponent } from './report/inbox-activity/inbox-activity.component';
import { EngagementTimesComponent } from './report/engagement-times/engagement-times.component';
import { NetworkEngagementComponent } from './report/network-engagement/network-engagement.component';
import { ResponseTimesComponent } from './report/response-times/response-times.component';
import { TeamPerformanceComponent } from './report/team-performance/team-performance.component';
import { UserPerformanceComponent } from './report/user-performance/user-performance.component';
import { SentimentChangeComponent } from './report/sentiment-change/sentiment-change.component';
import { AverageResponseTimeComponent } from './report/average-response-time/average-response-time.component';
import { TopTagsComponent } from './report/top-tags/top-tags.component';
import { TagsTableComponent } from './report/tags-table/tags-table.component';
import { ResponseTimeComponent } from './report/response-time-sla/response-time-sla.component';

import { VarModule } from '../../../../common/directives/var/var.module';
import { HighchartModule } from '../../../../common/components/highchart/highchart.module';
import { TreetableModule } from '../../../../common/components/treetable';
import { UniqueModuleModule } from '../../../../common/pipes/unique/unique.module';
import { SortByModule } from '../../../../common/pipes/sort-by/sort-by.module';
import { BigNumberModule } from '../../../../common/pipes/big-number/big-number.module';
import { BigNumberModule as BigNumberComponentModule } from '../../../../common/components/big-number/big-number.module';
import { AbsModule } from '../../../../common/pipes/abs/abs.module';
import { SecondsToHumanTimeModule } from '../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.module';
import { InboxSentimentComponent } from './report/inbox-sentiment/inbox-sentiment.component';
import { StatChangeModule } from '../common/stat-change/stat-change.module';
import { FactOverviewModule } from '../common/fact-overview/fact-overview.module';
import { SentimentCardModule } from '../common/sentiment-card/sentiment-card.module';
import { ReportBuildFromModule } from '../common/report-build-from/report-build-from.module';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateSelectorModule } from '../../../../common/directives/template-selector/template-selector.module';
import { DropdownSelect2Module } from '../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { FormsModule } from '@angular/forms';
import { TopEngagersComponent } from './report/top-engagers/top-engagers.component';
import { MedalScoreModule } from '../common/medal-score/medal-score.module';
import { FallbackImageModule } from '../../../../common/directives/fallback-image/fallback-image.module';
import { SnackbarService } from '../../../../common/services/snackbar/snackbar.service';
import { ProgressBarModule } from '../../../../common/components/progress-bar/progress-bar.module';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { TopUrlsModule } from '../common/top-urls/top-urls.module';
import { RoundDecimalsModule } from '../../../../common/pipes/round-decimals/round-decimals.module';
import { SecondsToHumanTimePipe } from '@orlo/common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReportHeaderModule,
    ReportSidebarModule,
    AccountSummaryCardModule,
    StatBarTileModule,
    AccountPillModule,
    TreetableModule,
    UniqueModuleModule,
    SortByModule,
    TotalStatCardModule,
    BigNumberModule,
    BigNumberComponentModule,
    AbsModule,
    SecondsToHumanTimeModule,
    HighchartModule,
    VarModule,
    StatChangeModule,
    FactOverviewModule,
    SentimentCardModule,
    ReportBuildFromModule,
    TranslateModule,
    TemplateSelectorModule,
    DropdownSelect2Module,
    FormsModule,
    MedalScoreModule,
    ProgressBarModule,
    FallbackImageModule,
    TooltipModule,
    TopUrlsModule,
    RoundDecimalsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.analytics.engagement',
          url: '/engagement',
          abstract: true,
          component: EngagementComponent
        },
        {
          name: 'auth.analytics.engagement.build',
          url:
            '?accountIds&startDate&endDate&previousStartDate&previousEndDate&dateRange&compareTo&visibility&tagIdsToInclude&tagIdsToExclude&excludeImportDelay&includeSilencedMessages',
          component: EngagementBuildComponent
        },
        {
          name: 'auth.analytics.engagement.report',
          url:
            '/report?accountIds&startDate&endDate&previousStartDate&previousEndDate&dateRange&compareTo&visibility&tagIdsToInclude&tagIdsToExclude&excludeImportDelay&includeSilencedMessages&disablePopup&frequency',
          component: EngagementReportComponent,
          resolve: EngagementReportComponent.resolve
        }
      ]
    })
  ],
  declarations: [
    EngagementComponent,
    EngagementBuildComponent,
    EngagementReportComponent,
    AccountSummaryComponent,
    InboxActivityComponent,
    EngagementTimesComponent,
    NetworkEngagementComponent,
    ResponseTimesComponent,
    TeamPerformanceComponent,
    UserPerformanceComponent,
    SentimentChangeComponent,
    AverageResponseTimeComponent,
    InboxSentimentComponent,
    TopEngagersComponent,
    TopTagsComponent,
    TagsTableComponent,
    ResponseTimeComponent
  ],
  providers: [EngagementAnalyticsService, SnackbarService, SecondsToHumanTimePipe],
  entryComponents: [EngagementComponent]
})
export class EngagementModule {}
