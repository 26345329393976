import { ViewEncapsulation } from '@angular/core';

import { Account } from '@ui-resources-angular';
import { Component, Input, OnInit } from '@angular/core';
import {
  AnalyticsService,
  DateRanges,
  VideoMetricStatsResponse
} from '../../analytics.service';
import {
  NetworkSelectItem,
  networkSelectItems
} from '../../common/social-network-select/social-network-select.component';

export interface FactOverview {
  label: string;
  responseKey: string;
  current: number;
  previous: number;
  icon: string;
  iconColor: string;
  circleColor: string;
  tooltip?: string;
}

@Component({
  selector: 'ssi-video-metrics',
  templateUrl: './video-metrics.component.html',
  styles: [],
  styleUrls: ['./video-metrics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoMetricsComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() campaignIds: Array<string> = [];
  @Input() dateRanges: DateRanges;
  @Input() tagsToInclude: string[];
  @Input() tagsToExclude: string[];

  currentStats: VideoMetricStatsResponse;
  previousStats: VideoMetricStatsResponse;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  factList: Array<FactOverview> = [
    {
      label: 'Video Views',
      responseKey: 'video_views',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-video-views',
      iconColor: 'rgb(221, 11, 9)',
      circleColor: 'rgba(221, 11, 9, 0.1)'
    },
    {
      label: 'Average View Time (secs)',
      responseKey: 'average_view_time',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-av-response-time-stat',
      iconColor: 'rgb(138, 164, 234)',
      circleColor: 'rgba(138, 164, 234, 0.1)'
    },
    {
      label: 'Impressions',
      responseKey: 'impressions',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-impressions',
      iconColor: 'rgb(240, 180, 39)',
      circleColor: 'rgba(240, 180, 39, 0.1)'
    },
    {
      label: 'Reach',
      responseKey: 'reach',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-reach',
      iconColor: 'rgb(18, 171, 163)',
      circleColor: 'rgba(18, 171, 163, 0.1)'
    },
    {
      label: 'Link Clicks',
      responseKey: 'link_clicks',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-select-microcopy',
      iconColor: 'rgb(247, 139, 104)',
      circleColor: 'rgba(247, 139, 104, 0.1)'
    },
    {
      label: 'Likes',
      responseKey: 'likes',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-like-correct',
      iconColor: 'rgb(255, 134, 184)',
      circleColor: 'rgba(255, 134, 184, 0.1)'
    },
    {
      label: 'Comments',
      responseKey: 'comments',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-commentscorrect',
      iconColor: 'rgb(178, 198, 18)',
      circleColor: 'rgba(178, 198, 18, 0.1)'
    },
    {
      label: 'Shares',
      responseKey: 'shares',
      current: 0,
      previous: 0,
      icon: 'ssi ssi-shares-standard',
      iconColor: 'rgb(212, 30, 104)',
      circleColor: 'rgba(212, 30, 104, 0.1)'
    }
  ];

  constructor(private analyticsService: AnalyticsService) {}

  async ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return this.accounts.some((a) => a.account_type_id === n.key);
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);

    await this.getVideoMetrics(this.accounts);
  }

  onNetworkSelect(network) {
    let filteredAccountsByNetwork = [];
    if (network.key === 'all') {
      filteredAccountsByNetwork = this.accounts;
    } else {
      filteredAccountsByNetwork = this.accounts.filter(
        (account) =>
          account.account_type_id === network.account_type_id.toString()
      );
    }

    this.getVideoMetrics(filteredAccountsByNetwork);
  }

  private async getVideoMetrics(accounts) {
    if (
      this.dateRanges.previous &&
      this.dateRanges.previous.start &&
      this.dateRanges.previous.end
    ) {
      this.previousStats = await this.analyticsService.getVideoMetrics(
        accounts.length ? accounts.map((account) => account.id) : null,
        this.campaignIds.length ? this.campaignIds : null,
        this.dateRanges.previous,
        this.tagsToInclude,
        this.tagsToExclude
      );
    }

    this.currentStats = await this.analyticsService.getVideoMetrics(
      accounts.length ? accounts.map((account) => account.id) : null,
      this.campaignIds.length ? this.campaignIds : null,
      this.dateRanges.current,
      this.tagsToInclude,
      this.tagsToExclude
    );

    this.factList.forEach((fact) => {
      fact.current = Math.round(this.currentStats[fact.responseKey]);
      fact.previous = this.previousStats
        ? Math.round(this.previousStats[fact.responseKey])
        : 0;

      if (fact.responseKey === 'average_view_time') {
        fact.current = Math.floor(fact.current / 1000);
        fact.previous = Math.floor(fact.previous / 1000);
      }
    });
  }
}
