import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { groupBy } from '../../../../common/utils';
import { Option } from '../../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { OutboxPublisher, YoutubeVisibility } from '@ui-resources-angular';

export const youtubePostOptions = {
  [YoutubeVisibility.Private]: {
    key: YoutubeVisibility.Private,
    label: 'Private',
    icon: 'ssi-lock-private-yt',
    description: 'Private: Only you and people you choose can watch your video'
  },
  [YoutubeVisibility.Unlisted]: {
    key: YoutubeVisibility.Unlisted,
    label: 'Unlisted',
    icon: 'ssi-unlisted',
    description: 'Unlisted: Anyone with the video link can watch your video'
  },
  [YoutubeVisibility.Public]: {
    key: YoutubeVisibility.Public,
    label: 'Public',
    icon: 'ssi-everyone',
    description:
      'Public: Everyone can watch your video, with no need to grant access or a link'
  }
};

@Component({
  selector: 'ssi-youtube-post-options-select',
  templateUrl: './youtube-post-options-select.component.html',
  styles: [],
  styleUrls: ['./youtube-post-options-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class YoutubePostOptionsSelectComponent implements OnInit, OnChanges {
  @Input() composer: OutboxPublisher;
  @Input() visibility: YoutubeVisibility;

  options: { [key: string]: Option } = youtubePostOptions;
  optionsIterable: Option[] = Object.values(this.options);
  selectedOption: Option = this.options.private;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['visibility'] && changes['visibility'].currentValue) {
      this.selectedOption = this.options[this.visibility];
    }
  }

  ngOnInit() {}

  selectedOptionChanged(selectedOption: Option): void {
    this.selectedOption = selectedOption;
    this.composer.youtubeVisibility = selectedOption.key;
  }
}
