let doc = window.document;
let cached = {};
let LoadError = require('error-cause');

export function loadScript(script) {
  return new Promise(function (resolve, reject) {
    script.onload = function () {
      this.onload = null;
      this.onerror = null;
      return resolve(true);
    };

    script.onerror = function (err) {
      this.onload = null;
      this.onerror = null;
      reject(new LoadError('Failed to load ' + script.src, { cause: err }));
    };
  });
}

/* istanbul ignore next */
function loadScriptIE(script) {
  return new Promise(function (resolve) {
    script.onreadystatechange = function () {
      if (this.readyState !== 'loaded' && this.readyState !== 'complete') {
        return;
      }

      this.onreadystatechange = null;
      return resolve(true);
    };
  });
}

function loadStyle(style) {
  return new Promise(function (resolve, reject) {
    style.onload = function () {
      this.onload = null;
      this.onerror = null;
      return resolve(true);
    };

    style.onerror = function (err) {
      this.onload = null;
      this.onerror = null;
      reject(new LoadError('Failed to load ' + style.src, { cause: err }));
    };
  });
}

/* istanbul ignore next */
function loadStyleIE(style, id) {
  return new Promise(function (resolve, reject) {
    style.onload = function () {
      let i = doc.styleSheets.length;
      let cur;

      try {
        while (i--) {
          cur = doc.styleSheets[i];
          if (cur.id === id && cur.cssText) {
            return resolve(true);
          }
        }
      } catch (e) {}

      return reject(new LoadError('Failed to load ' + style.src));
    };
  });
}

function resolver(src) {
  return new Promise(function (resolve, reject) {
    let head =
      doc.head ||
      /* istanbul ignore next */ doc.getElementsByTagName('head')[0];
    let element;
    let loader;
    let promise;

    if (src.type === 'style') {
      element = doc.createElement('link');
      element.rel = 'stylesheet';
      element.id = 'load-css-' + random();
      element.href = src.url;

      loader =
        typeof element.addEventListener === 'undefined'
          ? /* istanbul ignore next */ loadStyleIE
          : loadStyle;
      resolve(loader(element));
      head.appendChild(element);
    } else if (src.type === 'json') {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', src.url, true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 /* XMLHttpRequest.DONE */) {
          xhr.onreadystatechange = null;
          if (xhr.status >= 200 && xhr.status < 400) {
            try {
              resolve(JSON.parse(xhr.responseText));
            } catch (error) {
              reject(error);
            }
          } else {
            reject(new LoadError('Failed to load ' + src.url));
          }
        }
      };
      xhr.withCredentials = Boolean(src.withCredentials);
      xhr.send();
    } else {
      element = doc.createElement('script');
      element.charset = 'utf8';
      element.src = src.url;

      loader =
        'onload' in element
          ? loadScript
          : /* istanbul ignore next */ loadScriptIE;
      promise = loader(element);

      if (src.exposed) {
        promise = promise.then(function () {
          if (typeof checkGlobal(src.exposed) === 'undefined') {
            throw new LoadError('Failed to load ' + src.url);
          }
        });
      }

      resolve(promise);
      head.appendChild(element);
    }
  });
}

let CSS_REGEXP = /\.css$/;
let JS_REGEXP = /\.js$/;

function normalizeSource(src) {
  if (isPlainObject(src)) {
    return src;
  }

  let type;
  if (CSS_REGEXP.test(src)) {
    type = 'style';
  } else if (JS_REGEXP.test(src)) {
    type = 'script';
  } else {
    type = 'json';
  }

  return {
    url: src,
    type: type
  };
}

function cacheKey(src) {
  let separate = '!';
  return separate + src.type + separate + src.url;
}

function isPlainObject(value) {
  return {}.toString.call(value) === '[object Object]';
}

function random() {
  /* jshint bitwise:false */
  return ~~(Math.random() * (1e5 + 1));
}

let isArray =
  Array.isArray ||
  /* istanbul ignore next */ function (val) {
    return {}.toString.call(val) === '[object Array]';
  };

export function promisescript(srcs) {
  let promises = [];
  let shouldReturnArray = true;
  let promise;
  let i;
  let length;
  let src;
  let key;

  if (!isArray(srcs)) {
    srcs = [srcs];
    shouldReturnArray = false;
  }

  length = srcs.length;
  for (i = 0; i < length; i++) {
    src = normalizeSource(srcs[i]);
    key = cacheKey(src);

    // If the result cached, resolve with the original promise;
    if (cached[key]) {
      promises.push(cached[key]);
      continue;
    }

    promise = resolver(src);

    cached[key] = promise;
    promises.push(promise);
  }

  if (!shouldReturnArray) {
    return promises[0];
  }

  return promises;
}

function clear() {
  cached = {};
}

export function checkGlobal(value) {
  if (!value) {
    return value;
  }
  let g = global;
  let values = value.split('.');

  for (let i = 0, len = values.length; i < len; i++) {
    if (typeof g === 'undefined') {
      break;
    }

    g = g[values[i]];
  }

  return g;
}
