<div class="targeting-options-panel">
  <div class="network-information">
    <div class="network-avatar">
      <ssi-social-network-icon
        [accountTypeId]="AccountTypeId.LinkedIn"
        [size]="20"
      ></ssi-social-network-icon>
    </div>
    <div class="network-body">
      <h4
        class="accordion-content-title"
        translate="LINKEDIN_TARGETING_OPTIONS"
      ></h4>
      <p
        class="accordion-content-body"
        translate="IN_ORDER_TO_TARGET_A_GROUP_OF_FOLLOWERS_ON_LINKEDIN_THE_GROUP_MUST_CONTAIN_AT_LEAST_300_PEOPLE_WITHIN_THE_TARGET_SELECTION_YOU_MAKE"
      ></p>
    </div>
  </div>

  <div
  class="targeting-form"
  [class.preview-toggled]="!previewToggled"
>
  <div
    *ngFor="let input of targetingService.options.LinkedIn.multiValueInputs"
    class="orlo-typeahead"
  >
  <ng-container *ngIf="input.key === 'geos' && input.value.length > 0">
    <label>
      <span [translate]="input.labelTranslateKey"></span>
    </label>

    <ssi-outbox-typeahead
      (filterChange)="onLinkedInGeosChange($event)"
      (valueChange)="onChange($event)"
      [(value)]="input.value"
      [disabled]="input.disable || post.edit?.social_id"
      [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
      [suggestions]="[]"
      [class.has-value]="input.value.length > 0"
    ></ssi-outbox-typeahead>
  </ng-container>

    <label *ngIf="input.key != 'geos'">
      <span [translate]="input.labelTranslateKey"></span>
    </label>

    <ssi-outbox-typeahead
      *ngIf="input.key === 'geoLocations'"
      (filterChange)="onLinkedInGeosChange($event)"
      (valueChange)="onChange($event)"
      [(value)]="input.value"
      [disabled]="input.disable || post.edit?.social_id"
      [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
      [suggestions]="input.options"
      [class.has-value]="input.value.length > 0"
    ></ssi-outbox-typeahead>

    <ssi-outbox-typeahead
      *ngIf="input.key != 'geoLocations' && input.key != 'geos'"
      (valueChange)="onChange($event)"
      [(value)]="input.value"
      [disabled]="input.disable || post.edit?.social_id"
      [placeholderText]="(input.placeholderTranslateKey | translate) + '...'"
      [suggestions]="input.options"
      [class.has-value]="input.value.length > 0"
    ></ssi-outbox-typeahead>
  </div>
</div>
</div>