import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { AsyncTrackerFactory } from 'angular-async-tracker';

import { Campaign, Outbox } from '@ui-resources-angular';
import { PopupService } from '../../services/popup/popup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutboxCampaignModalComponent } from '../outbox-campaign-modal/outbox-campaign-modal.component';

@Component({
  selector: 'ssi-outbox-post-menu',
  templateUrl: './outbox-post-menu.component.html',
  styles: [],
  styleUrls: ['./outbox-post-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutboxPostMenuComponent implements OnInit {
  @Input() post: Outbox;

  @Output() onDelete = new EventEmitter();

  campaigns: Campaign[];
  loadingTracker;
  advert;

  constructor(
    protected state: StateService,
    protected injector: Injector,
    protected popup: PopupService,
    protected translate: TranslateService,
    protected asyncTracker: AsyncTrackerFactory,
    private modal: NgbModal
  ) {}

  async ngOnInit() {
    this.loadingTracker = this.asyncTracker.create();
  }

  async deletePost() {
    const confirmed = await this.popup.confirm({
      title: this.translate.instant('DELETE_POST'),
      template: this.translate.instant(
        'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_POST'
      )
    });
    if (confirmed) {
      const promise = this.post.remove().then(() => this.onDelete.emit());
      this.loadingTracker.add(promise);
    }
  }

  boostPost() {
    this.state.go('auth.marketing.advertising.boostPost', {
      outboxId: this.post.id
    });
    this.modal.dismissAll();
  }

  async showCampaignModal() {
    const modal = this.modal.open(OutboxCampaignModalComponent, {
      windowClass: 'orlo-modal modal-high-zindex',
      backdropClass: 'backdrop-high-zindex'
    });
    modal.componentInstance.post = this.post;
    const result = await modal.result;
    this.post.campaign_id = result;
  }
}
