import { Injectable, Injector } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { Activity, ActivityModel } from '@ui-resources-angular';
import { ApiService } from '../api.service';
import { Store } from '../store';
import { Dashboard, DashboardWidget } from './dashboard.model';
import {
  widgetTypes,
  widgetTypesIterable
} from '@orlo/modules/auth/dashboard/common/constants';
// import { CompanyService } from '../../company/company.service';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  endpoint = `${this.api.url}/user/dashboard`;
  templatesEndpoint = `${this.api.url}/company/dashboardTemplate`; // identical APIs just different endpoint - templates are visible to all and changes to them can be made by admins only

  store = new Store<Dashboard>(Dashboard);
  templatesStore = new Store<Dashboard>(Dashboard);

  constructor(
    protected api: ApiService,
    protected injector: Injector,
    protected activityModel: ActivityModel // protected company: CompanyService,
  ) {
    this.getAll();
    this.getAllTemplates();
  }

  getAll(opts = { refreshStore: false }): Promise<Dashboard[]> {
    if (this.store.valueSet && !opts.refreshStore) {
      this.assignTypeConstToWidgets(this.store.value); // in case it gets deleted meanwhile
      return Promise.resolve(this.store.value);
    }

    return this.api
      .get(this.endpoint)
      .pipe(
        map((response: { dashboards: any[] }) => {
          this.assignTypeConstToWidgets(response.dashboards);
          this.store.value = response.dashboards;
          return this.store.value;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  getAllTemplates(opts = { refreshStore: false }): Promise<Dashboard[]> {
    if (this.templatesStore.valueSet && !opts.refreshStore) {
      this.assignTypeConstToWidgets(this.templatesStore.value); // in case it gets deleted meanwhile
      return Promise.resolve(this.templatesStore.value);
    }

    return this.api
      .get(this.templatesEndpoint)
      .pipe(
        map((response: { templates: any[] }) => {
          this.assignTypeConstToWidgets(response.templates);
          this.templatesStore.value = response.templates;
          return this.templatesStore.value;
        }),
        catchError((e) => this.api.mapError(e, this.templatesEndpoint))
      )
      .toPromise();
  }

  create(dashboard: Partial<Dashboard>): Promise<Dashboard> {
    return this.api
      .post(this.endpoint, dashboard)
      .pipe(
        map((response: any) => {
          this.store.add(response);
          return this.store.find(response.id);
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  createTemplate(dashboard: Partial<Dashboard>): Promise<Dashboard> {
    return this.api
      .post(this.templatesEndpoint, dashboard)
      .pipe(
        map((response: any) => {
          this.templatesStore.add(response);
          return this.templatesStore.find(response.id);
        }),
        catchError((e) => this.api.mapError(e, this.templatesEndpoint))
      )
      .toPromise();
  }

  update(dashboard: Dashboard): Promise<Dashboard> {
    const opts = {
      params: {
        id: dashboard.id
      }
    };

    return this.api
      .put(this.endpoint, dashboard, opts)
      .pipe(
        map((response: any) => {
          this.store.update(response);
          return this.store.find(response.id);
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  updateTemplate(dashboard: Dashboard): Promise<Dashboard> {
    const opts = {
      params: {
        id: dashboard.id
      }
    };

    return this.api
      .put(this.templatesEndpoint, dashboard, opts)
      .pipe(
        map((response: any) => {
          this.templatesStore.update(response);
          return this.templatesStore.find(response.id);
        }),
        catchError((e) => this.api.mapError(e, this.templatesEndpoint))
      )
      .toPromise();
  }

  delete(dashboard: Dashboard): Promise<{ success: boolean }> {
    const opts = {
      params: {
        id: dashboard.id
      }
    };

    return this.api
      .delete(this.endpoint, opts)
      .pipe(
        map((response: any) => {
          this.store.remove(dashboard.id);
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.endpoint))
      )
      .toPromise();
  }

  deleteTemplate(dashboard: Dashboard): Promise<{ success: boolean }> {
    const opts = {
      params: {
        id: dashboard.id
      }
    };

    return this.api
      .delete(this.templatesEndpoint, opts)
      .pipe(
        map((response: any) => {
          this.templatesStore.remove(dashboard.id);
          return response;
        }),
        catchError((e) => this.api.mapError(e, this.templatesEndpoint))
      )
      .toPromise();
  }

  assignTypeConstToWidgets(dashboards: Dashboard[]): void {
    dashboards.forEach((dashboard) => {
      dashboard.widgets.forEach((widget) => {
        widget.typeConst = widgetTypesIterable.find(
          (type) => type.key === widget.type
        );
      });
    });
  }

  getWalletWidgets(): DashboardWidget[] {
    return widgetTypesIterable.map((widgetType) => {
      return {
        type: widgetType.key,
        typeConst: widgetType,
        title: widgetType.label,
        featureFlag: widgetType.featureFlag,
        display_properties: {
          x: 0,
          y: 0,
          w: widgetType.defaultSize.w,
          h: widgetType.defaultSize.h
        }
      };
    });
  }

  // async filterFeatureFlagWidgets(widgets: DashboardWidget[]): Promise<DashboardWidget[]> {
  //   const widgetsFeatureFlagged = widgets.filter(widget => {
  //     return widgetTypesIterable.find(_widget => _widget.key === widget.type).featureFlag;
  //   });
  //   console.log('has feature flag: ', widgetsFeatureFlagged);
  //   const widgetsFlagged = [];
  //   await widgetsFeatureFlagged.forEach(async widget => {
  //     const widgetType = widgetTypesIterable.find(_widget => _widget.key === widget.type);
  //     if (await this.company.hasFeatureAccess(widgetType.featureFlag)) {
  //       console.log('fuck');
  //       widgetsFlagged.push(widget);
  //     }
  //   });
  //   console.log('widgetsFlagged: ', widgetsFlagged);
  //   return widgets.filter(widget => ! widgetsFlagged.find(_widget => widget.type === _widget.type));
  // }
}
