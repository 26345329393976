import { Inject, Injectable } from '@angular/core';
import { SOCKET_ENDPOINT } from '../../../../environments/environment';
import { io, Socket } from 'socket.io-client';
import { AuthService } from '../auth/auth.service';
import { SocketEventManagerService } from './socket-event-manager.service';

export interface ClientToServerEvents {
  noArg: () => void;
  basicEmit: (a: number, b: string, c: number[]) => void;
}

export interface ServerToClientEvents {
  withAck: (d: string, cb: (e: number) => void) => void;
}

@Injectable({ providedIn: 'root' })
export class SocketsService {
  private socket: Socket;

  constructor(
    private authService: AuthService,
    private socketEventManager: SocketEventManagerService
  ) {
    this.instantiate();
  }

  instantiate() {
    const browserlessMode = localStorage.getItem('browserless-io') === 'true';
    if (!this.authService.isAuthenticated(true) || browserlessMode) {
      return;
    }

    this.socket = io(SOCKET_ENDPOINT);
    this.socket.on('connect', () => {
      this.socket.emit('authentication', {
        token: this.authService.getToken()
      });

      this.socketEventManager.initialise(this.socket);
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  emit(ev: string, message: string) {
    this.socket.emit(ev, message);
    console.log(ev, message);
  }

  getSocketInstance() {
    return this.socket;
  }
}
