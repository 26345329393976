import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCampaignModalComponent } from './create-campaign-modal.component';
import { DropdownSelectModule } from '../../../dropdown-select/dropdown-select.module';
import { DropdownSelect2Module } from '../../../dropdown-select-2/dropdown-select-2.module';
import { OrloComponentsModule } from 'orlo-components';
import { DateTimePickerModule } from '../../../date-time-picker/date-time-picker.module';
import { TimePickerModule } from '../../../time-picker/time-picker.module';
import { TooltipModule } from '../../../../directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownSelectModule,
    DropdownSelect2Module,
    OrloComponentsModule,
    DateTimePickerModule,
    TimePickerModule,
    TooltipModule,
  ],
  declarations: [CreateCampaignModalComponent],
  exports: [CreateCampaignModalComponent],
  providers: [CreateCampaignModalComponent],
  entryComponents: [CreateCampaignModalComponent]
})
export class CreateCampaignModalModule {}
