import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatModalComponent } from './live-chat-modal.component';

@NgModule({
  declarations: [LiveChatModalComponent],
  entryComponents: [LiveChatModalComponent],

  exports: [LiveChatModalComponent],

  imports: [CommonModule, TranslateModule]
})
export class LiveChatModalModule {}
