import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';

import { Account, AccountModel } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../common/services/workflow-manager/workflow-manager.service';

@Component({
  selector: 'ssi-cl-dropdown-select',
  templateUrl: './cl-dropdown-select.component.html',
  styles: [],
  styleUrls: ['./cl-dropdown-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClDropdownSelectComponent implements OnInit {
  accounts: Account[] = [];

  constructor(
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  async ngOnInit() {
    this.accounts = await this.accountModel.findAllAccounts(
      this.workflowManager.getCurrentId()
    );
    this.accounts = orderBy(this.accounts, ['account_type_name', 'name']);
  }
}
