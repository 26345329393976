<div class="gif-search-header">
  <div class="search-input-container">
    <button
      *ngIf="filterTerm"
      class="cancel-search"
      (click)="filterTerm = ''"
    >
      <i class="ssi ssi-line-arrow-small-up"></i> {{'BACK_TO_CATEGORIES' | translate}}
    </button>
    <input
      type="search"
      [class.active]="filterTerm"
      placeholder="Search for gifs..."
      [(ngModel)]="filterTerm"
      (ngModelChange)="searchInputChanged()"
    />
  </div>
  <i
    class="close-icon ssi ssi-delete-close"
    (click)="closeModal()"
  ></i>
</div>
<div class="gifs-container">
  <ng-container *ngIf="gifsCategories && !filterTerm">
    <div
      class="gif-box category"
      *ngFor="let category of gifsCategories"
    >
      <label>{{category.name}}</label>
      <img
        [src]="category.gif.images.fixed_height_small.url"
        alt="{{category.name}}"
        (click)="selectCategory(category)"
      >
    </div>
  </ng-container>
  <div #gifElRef></div>
  <!-- <ng-template #elseTemplate>
    </ng-template> -->
</div>
<img
  src="assets/images/composer/powered-by-giphy.png"
  alt="Powered by Giphy"
>