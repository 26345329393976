import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Activity, Outbox } from '@ui-resources-angular';
import {
  AuditEventsService,
  AuditEventsResponse,
  AuditEvent,
  AuditEventVerb
} from '../../services/api';
import { FileDownloadService, SpreadsheetService } from '../../services';
import { FileMimeTypes } from '../../directives';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ssi-audit',
  templateUrl: './audit.component.html',
  animations: [
    trigger('slideIn', [
      transition('void => *', [
        style({ opacity: 0, height: '0' }),
        animate('120ms ease-out', style({ opacity: 1, height: '*' }))
      ])
    ])
  ],
  styleUrls: ['./audit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditComponent implements OnInit {
  @Input() activity?: Activity;
  @Input() outbox?: Outbox;

  readonly showHeadActions = true;
  filterActionsOpen = false;
  loading = false;
  auditEventsResponse: AuditEventsResponse;
  auditEventsToRender: AuditEvent[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private auditEventsService: AuditEventsService,
    private spreadsheetService: SpreadsheetService,
    private fileDownloadService: FileDownloadService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    await this.loadAuditEvents();
  }

  async loadAuditEvents(token?: string): Promise<void> {
    this.loading = true;
    try {
      this.auditEventsResponse = await this.auditEventsService.getAll(
        {
          activity_id: this.activity && this.activity.id,
          outbox_id: this.outbox && this.outbox.id
        },
        token
      );

      this.auditEventsToRender.push(...this.auditEventsResponse.events);

      console.log(this.auditEventsResponse);
    } catch (e) {
      console.error('Error: ', e);
    }
    this.loading = false;
  }

  async loadMore(): Promise<void> {
    if (
      this.loading ||
      !this.auditEventsResponse ||
      !this.auditEventsResponse.pagination.next
    ) {
      return;
    }

    await this.loadAuditEvents(this.auditEventsResponse.pagination.next);
  }

  async downloadXlsx() {
    const spreadsheetData = this.createSpreadsheetFromReport();
    const xlsx = await this.spreadsheetService.jsonToXlsx(spreadsheetData);

    const downloadConfig = {
      base64decode: true,
      filename: `Orlo - ${
        this.activity ? 'Inbox Audit' : 'Outbox Audit'
      } report.xlsx`,
      mimetype: FileMimeTypes.Xlsx
    };

    this.fileDownloadService.download(xlsx, downloadConfig);
  }

  createSpreadsheetFromReport() {
    return [
      () => {
        return {
          name: 'Summary',
          rows: [
            ['Date', 'Who', 'Action', 'Time', 'Description'],
            [],
            ...this.auditEventsResponse.events.map((auditEvent) => {
              const formattedEvent = this.getAuditEventForSpreadsheet(
                auditEvent
              );
              return [
                this.datePipe.transform(auditEvent.timestamp, 'MMM d, y'),
                auditEvent.actor.name,
                formattedEvent.action,
                this.datePipe.transform(auditEvent.timestamp, 'hh:mm a'),
                formattedEvent.description
              ];
            })
          ]
        };
      }
    ].map((fn) => fn());
  }

  private getAuditEventForSpreadsheet(
    auditEvent: AuditEvent
  ): { action: string; description: string } {
    switch (auditEvent.verb) {
      // inbox events
      case AuditEventVerb.StatusChange:
        return {
          action: 'Status changed',
          description: `marked this message ${auditEvent.params.status}`
        }; // 'unread', 'actioned', 'unactioned}'
      case AuditEventVerb.SentimentChanged:
        return {
          action: 'Sentiment changed',
          description: `amended the sentiment of this message to ${auditEvent.params.sentimentConst.label}`
        };
      case AuditEventVerb.AddedTags:
        return {
          action: 'Added Tags',
          description: `Added Message tags: ${auditEvent.params.tags}`
        };
      case AuditEventVerb.RemovedTags:
        return {
          action: 'Removed Tags',
          description: `Removed Message tags: ${auditEvent.params.tags}`
        };
      case AuditEventVerb.AddedNote:
        return { action: 'Added note', description: 'Added Message note' };
      case AuditEventVerb.DeletedNote:
        return { action: 'Deleted note', description: 'Removed Message note' };
      case AuditEventVerb.SocialLiked:
        return { action: 'Liked', description: 'liked this message' };
      case AuditEventVerb.SocialUnliked:
        return { action: 'Unliked', description: 'unliked this message' };
      case AuditEventVerb.SocialHide:
        return { action: 'Hide', description: 'hid this message' };
      case AuditEventVerb.SocialUnHide:
        return { action: 'Unhide', description: 'unhid this message' };
      case AuditEventVerb.Emailed:
        return {
          action: 'Emailed',
          description: 'Forwarded an email of this message'
        };
      case AuditEventVerb.Resolved:
        return { action: 'Resolved', description: 'Resolved this message' };
      case AuditEventVerb.Unresolved:
        return { action: 'Unresolved', description: 'Unresolved this message' };
      case AuditEventVerb.Redacted:
        return { action: 'Redacted', description: 'Redacted this message' };

      // common for both inbox and outbox
      case AuditEventVerb.Deleted:
        return {
          action: `${auditEvent.object.activity_id} ? 'Message deleted' : 'Post deleted'`,
          description: `${auditEvent.object.activity_id} ? 'message' : 'post'`
        };
        break;

      // outbox events
      case AuditEventVerb.Created:
        let description: string = 'Created this post';

        if (auditEvent.params.published_date) {
          description += ` and it was published at ${this.datePipe.transform(
            auditEvent.params.published_date,
            'hh:mm a'
          )}`;
        }
        if (auditEvent.params.scheduled_date) {
          description += ` and scheduled it for ${this.datePipe.transform(
            auditEvent.params.scheduled_date,
            'hh:mm a'
          )}`;
        }
        return {
          action: 'Post Created',
          description
        };

        break;
      case AuditEventVerb.Updated:
        if (auditEvent.params.scheduled_date) {
          return {
            action: 'Post re-scheduled',
            description: 'Re-scheduled this post'
          };
        } else {
          return { action: 'Post Edited', description: 'Edited this post' };
        }
        break;
      case AuditEventVerb.Accepted:
        return { action: 'Post Approved', description: 'Approved this post' };
        break;
      case AuditEventVerb.Rejected:
        return {
          action: 'Post Disapproved',
          description: 'Did not approve this post'
        };
        break;
      case AuditEventVerb.Published:
        return { action: 'Post Published', description: 'Published this post' };
        break;
      case AuditEventVerb.PublishFailed:
        return {
          action: 'Publish Failed',
          description: 'Failed to publish this post'
        };
        break;
      default:
        return { action: auditEvent.verb, description: auditEvent.verb };
        break;
    }
  }

  toggleFilterActions(): void {
    this.filterActionsOpen = !this.filterActionsOpen;
  }

  onSlideInAnimationDone(): void {}
}
