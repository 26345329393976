import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AccountModel, Account, ActivityTags } from '@ui-resources-angular';
import { decodeStateParams } from '@orlo/common/utils';

import {
  ReportBuildFormValue,
  visibilityOptions
} from '../../common/report-build-from/report-build-from.component';

import { commonDateRanges } from '../../../../../common/constants/common-date-ranges';
import { compareToOptions } from '../../../../../common/constants/compare-to-options';
import { AccountTypeId } from '../../../../../common/enums';

@Component({
  selector: 'ssi-engagement-build',
  templateUrl: './engagement-build.component.html',
  styles: [],
  styleUrls: ['./engagement-build.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EngagementBuildComponent implements OnInit {
  formValue?: ReportBuildFormValue;
  AccountTypeId = AccountTypeId;
  decodedStateParams: { [key: string]: any };

  constructor(
    protected state: StateService,
    protected accountModel: AccountModel,
    protected activityTags: ActivityTags
  ) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    if (
      this.decodedStateParams.accountIds &&
      this.decodedStateParams.startDate &&
      this.decodedStateParams.endDate
    ) {
      const accountIds = Array.isArray(this.decodedStateParams.accountIds)
        ? this.decodedStateParams.accountIds
        : [this.decodedStateParams.accountIds];

      const tagIdsToInclude = this.decodedStateParams.tagIdsToInclude
        ? Array.isArray(this.decodedStateParams.tagIdsToInclude)
          ? this.decodedStateParams.tagIdsToInclude
          : [this.decodedStateParams.tagIdsToInclude]
        : [];

      const tagIdsToExclude = this.decodedStateParams.tagIdsToExclude
        ? Array.isArray(this.decodedStateParams.tagIdsToExclude)
          ? this.decodedStateParams.tagIdsToExclude
          : [this.decodedStateParams.tagIdsToExclude]
        : [];

      this.formValue = {
        accounts: accountIds
          .map((id) => this.accountModel.get(id))
          .filter(
            (account) =>
              Number(account.account_type_id) !== AccountTypeId.TikTok
          ),
        startDate: new Date(this.decodedStateParams.startDate),
        endDate: new Date(this.decodedStateParams.endDate),
        previousStartDate:
          this.decodedStateParams.previousStartDate &&
          new Date(this.decodedStateParams.previousStartDate),
        previousEndDate:
          this.decodedStateParams.previousEndDate &&
          new Date(this.decodedStateParams.previousEndDate),
        dateRange: commonDateRanges[this.decodedStateParams.dateRange],
        compareToOption: compareToOptions[this.decodedStateParams.compareTo],
        visibilityOption: visibilityOptions[this.decodedStateParams.visibility],
        tagsToInclude: tagIdsToInclude,
        tagsToExclude: tagIdsToExclude
      };
    }
  }

  onFormSubmit(output: ReportBuildFormValue): void {
    console.log('form output:', output);

    this.state.go('auth.analytics.engagement.report', {
      accountIds: output.accounts.map((a) => a.id),
      startDate: output.startDate.toISOString(),
      endDate: output.endDate.toISOString(),
      previousStartDate:
        output.previousStartDate && output.previousStartDate.toISOString(),
      previousEndDate:
        output.previousEndDate && output.previousEndDate.toISOString(),
      dateRange: output.dateRange && output.dateRange.id,
      compareTo: output.compareToOption.id,
      visibility: output.visibilityOption.key,
      tagIdsToInclude: output.tagsToInclude,
      tagIdsToExclude: output.tagsToExclude,
      excludeImportDelay: output.excludeImportDelay,
      includeSilencedMessages: output.includeSilencedMessages
    });
  }
}
