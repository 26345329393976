import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit, OnChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ssi-password-input',
  templateUrl: './password-input.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent), //tslint:disable-line
      multi: true
    }
  ],
  styleUrls: ['./password-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordInputComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() class: string;
  endsWithWhitespace: boolean;
  startsWithWhitespace: boolean;
  showPassword = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes) {}

  contentChange(event: string) {
    this.propagateChange(event);
  }

  private propagateChange = (_: any) => {};

  private onChangeCallback: (_: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value) {
    this.onChangeCallback(value);
  }

  checkInput(value = '') {
    this.endsWithWhitespace = !!value.match(/^.+\s+$/);
    this.startsWithWhitespace = !!value.match(/^\s+.*$/);
  }

  togglePasswordDisplay() {
    this.showPassword = !this.showPassword;
  }
}
