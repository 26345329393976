import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PluginService } from '../../services/plugin/plugin.service';
import { Activity, CrmPerson } from '@ui-resources-angular';

@Component({
  selector: 'ssi-export-messages',
  templateUrl: './export-messages.component.html',
  styleUrls: ['./export-messages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportMessagesComponent implements OnInit {
  @Input() person: CrmPerson;
  @Input() close;
  @Input() activity: Activity;
  @Input() activityThread: any;

  threadActivities: any[];
  exportedMessages = [];
  initialisedPlugins;
  pluginMessageActions = [];
  selectedActivities = [];

  constructor(
    private plugin: PluginService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    const activities = [
      this.activityThread.current,
      ...this.activityThread.children.activities,
      ...this.activityThread.siblings.newer.activities,
      ...this.activityThread.siblings.older.activities,
      ...(this.activityThread.children.hiddenActivities || []),
      ...(this.activityThread.siblings.newer.hiddenActivities || []),
      ...(this.activityThread.siblings.older.hiddenActivities || [])
    ];

    function sortByCreated(a, b) {
      const dateA = new Date(a.interaction.created_at).getTime();
      const dateB = new Date(b.interaction.created_at).getTime();
      return dateA > dateB ? 1 : -1;
    }

    this.threadActivities = activities.sort(sortByCreated);

    this.initialisedPlugins = this.plugin.getInitialisedPlugins();

    Object.keys(this.initialisedPlugins).forEach((pluginId) => {
      if (
        !(
          !!this.initialisedPlugins[pluginId].actions &&
          Array.isArray(this.initialisedPlugins[pluginId].actions)
        )
      ) {
        return;
      }

      const messageType = this.initialisedPlugins[pluginId].actions.find(
        (action) => action.type === 'message'
      );

      messageType.pluginId = pluginId;
      this.pluginMessageActions.push(messageType);
    });
  }

  selectActivity(activity) {
    const index = this.selectedActivities.findIndex(
      (selectedActivity) => selectedActivity.id === activity.id
    );
    index > -1
      ? this.selectedActivities.splice(index, 1)
      : this.selectedActivities.push(activity);
  }

  isActivitySelected(activity) {
    return this.selectedActivities.find(
      (selectedActivity) => selectedActivity.id === activity.id
    );
  }

  isOutboundMessage(activity): boolean {
    return String(activity.author.id) !== String(this.activity.author.id);
  }

  parseMessage(content: string) {
    return content ? content.replace(/<[^>]*>?/gm, '') : '';
  }

  exportMessagesToPlugin(action) {
    if (
      !(
        !!this.person &&
        !!this.person.plugins &&
        Array.isArray(this.person.plugins)
      )
    ) {
      return false;
    }

    const personLinkedInPlugin = this.person.plugins.find(
      (linkedPlugin) => linkedPlugin.plugin_id === action.pluginId
    );

    this.plugin.trigger(
      {
        callbackId: action.callbackId,
        params: {
          pluginPersonId: personLinkedInPlugin
            ? personLinkedInPlugin.plugin_person_id
            : null,
          exportedMessages: this.parseMessagesForTwitter(
            this.selectedActivities
          )
        }
      },
      this.initialisedPlugins[action.pluginId].context.nativeElement
        .contentWindow
    );
    this.activeModal.close();
  }

  parseMessagesForTwitter(selectedActivities) {
    return selectedActivities.map((activity) => {
      const { interaction } = activity;
      if (interaction.is_private && interaction.social_type === 'twitter') {
        activity.interaction.content = `https://twitter.com/messages/compose?recipient_id=${activity.author.id}`;
      } else if (
        !interaction.is_private &&
        interaction.social_type === 'twitter'
      ) {
        activity.interaction.content = interaction.link;
      }
      return activity;
    });
  }
}
