
import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { emotions } from '../../../../../../common/constants';
import { MonitoringStreamsService } from '../../../../../../common/services/api';
import {
  PageChangeParams,
  PaginatorComponent
} from '../../../../../../common/components/paginator';
import {
  InsightsService,
  InsightsPost,
  sanitizeFiltersForAPI,
  dedupeAndMergeFiltersForAPI,
  InsightsSearchResponse
} from '../../../insights.service';
import { Filter } from '../../../reports/view/view-report.component';

@Component({
  selector: 'ssi-insights-posts',
  templateUrl: './insights-posts.component.html',
  styles: [],
  styleUrls: ['./insights-posts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsightsPostsComponent implements OnInit, OnChanges {
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  @Input() clusterId: number;
  @Input() jobTicket: string;
  @Input() filtersBarVisible = false;
  @Input() activeFilters: Filter[] = [];
  @Input() streamIds: string[];
  @Input() beforeLoadTemplate: TemplateRef<any>;
  @Input() initialLoadingTemplate: TemplateRef<any>;
  @Input() sanitizeFilters: boolean = true;
  @Input() selectedPosts: InsightsPost[] = [];
  @Input() bulkSentimentUpdate: number;

  @Output() postSelectToggled = new EventEmitter<InsightsPost>();

  loading = false;
  initalLoading = false;
  containerWidth = ''; // e.g. '1200px'
  responsesPerPage: { [key: number]: InsightsSearchResponse } = {};
  postsToRender: InsightsPost[] = [];
  leftPosts: InsightsPost[] = [];
  rightPosts: InsightsPost[] = [];

  readonly pagesToLoadInAdvance = 2;
  readonly itemsPerPage = 10;

  constructor(
    protected insightsService: InsightsService,
    protected monitoringStreamsService: MonitoringStreamsService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeFilters && changes.activeFilters.currentValue) {
      this.responsesPerPage = {}; // reset cached posts
      this.paginator.init();
    }
  }

  async getPosts(
    pageChangeParams: PageChangeParams
  ): Promise<InsightsSearchResponse> {
    try {
      let globalFilters;
      if (this.sanitizeFilters) {
        globalFilters = sanitizeFiltersForAPI(Array.from(this.activeFilters));
        globalFilters = dedupeAndMergeFiltersForAPI(globalFilters);
      } else {
        globalFilters = this.activeFilters;
      }

      let response;
      if (this.jobTicket && typeof this.clusterId === 'number') {
        // if clustered
        response = await this.insightsService.getClusteredPosts(
          this.jobTicket,
          this.clusterId,
          pageChangeParams.skip,
          pageChangeParams.take
        );
      } else {
        response = await this.insightsService.getPosts(
          globalFilters,
          this.streamIds,
          pageChangeParams.skip,
          pageChangeParams.take
        );
      }
      this.validateEmotions(response.results);
      return response;
    } catch (e) {
      console.error('Error loading/mapping insights search response: ', e);
      return undefined;
    }
  }

  async loadAndSetPosts(pageChangeParams: PageChangeParams): Promise<void> {
    // 'Infinite paging' since backend doesn't provide total number of posts
    // Initially, and each time user navigates to the last page load +2 pages (in advence)
    // Cache responses (and reset cache if user changes any of the filters, etc.)

    if (!this.responsesPerPage[pageChangeParams.currentPage]) {
      if (pageChangeParams.currentPage === 1) {
        this.clearPostsToRender();
        this.initalLoading = true;
      }
      this.loading = true;
      const response = await this.getPosts(pageChangeParams);
      this.loading = false;
      this.initalLoading = false;
      if (response && response.results.length) {
        this.responsesPerPage[pageChangeParams.currentPage] = response;
      }
    }

    this.setPostsToRender(pageChangeParams);

    if (
      this.responsesPerPage[pageChangeParams.currentPage] &&
      this.responsesPerPage[pageChangeParams.currentPage].next &&
      this.responsesPerPage[pageChangeParams.currentPage].next.offset &&
      !this.responsesPerPage[pageChangeParams.currentPage + 1]
    ) {
      let i = 1;
      let done = false;
      while (i <= this.pagesToLoadInAdvance && !done) {
        const nextResponse = await this.getPosts(
          PaginatorComponent.getPageParams(
            pageChangeParams.currentPage + i,
            this.itemsPerPage
          )
        );

        if (nextResponse && nextResponse.results.length) {
          this.responsesPerPage[
            pageChangeParams.currentPage + i
          ] = nextResponse;

          if (!nextResponse.next || !nextResponse.next.offset) {
            done = true;
          }
        } else {
          done = true;
        }

        i++;
      }
    }

    console.log('this.responsesPerPage: ', this.responsesPerPage);
  }

  async onPageChange(pageChangeParams: PageChangeParams): Promise<void> {
    await this.loadAndSetPosts(pageChangeParams);
  }

  getLoadedPostsCount(): number {
    let count = 0;
    Object.values(this.responsesPerPage).forEach((response) => {
      count += response.results.length;
    });

    return count;
  }

  onContainerWidthChange(width: string): void {
    this.containerWidth = width;
    this.splitPostsToRender();
  }

  setPostsToRender(pageChangeParams: PageChangeParams): void {
    const currentPageResponse = this.responsesPerPage[
      pageChangeParams.currentPage
    ];
    this.postsToRender = currentPageResponse ? currentPageResponse.results : [];
    // console.log('this.postsToRender', this.postsToRender);
    this.splitPostsToRender();
  }

  clearPostsToRender(): void {
    this.postsToRender = [];
    this.splitPostsToRender();
  }

  splitPostsToRender(): void {
    if (parseInt(this.containerWidth, 10) < 1200) {
      this.leftPosts = this.postsToRender.slice();
      this.rightPosts = [];
    } else {
      this.leftPosts = this.postsToRender.filter((p, i) => i % 2 === 0);
      this.rightPosts = this.postsToRender.filter((p, i) => i % 2 !== 0);
    }
  }

  isPostSelected(post: InsightsPost): boolean {
    return this.selectedPosts.indexOf(post) > -1;
  }

  validateEmotions(posts: InsightsPost[]): void {
    const emotionNotRecognizedPosts = posts.filter((p) => {
      return !!(
        p.data.insights &&
        p.data.insights.content.emotion.find((e) => !emotions[e])
      );
    });
    if (emotionNotRecognizedPosts.length) {
      emotionNotRecognizedPosts.forEach((p) => {
        console.error(
          'Emotions not recognized: ',
          p.data.insights && p.data.insights.content.emotion.join(', ')
        );
      });
    }
  }
}
