import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ActivityTags, Activity } from '@ui-resources-angular';

@Component({
  selector: 'ssi-message-actions-menu',
  templateUrl: './message-actions-menu.component.html',
  styleUrls: ['./message-actions-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageActionsMenuComponent implements OnInit {
  @Input() activity: Activity;
  @Input() isInbox = false;
  @Input() selfPenned: boolean;
  @Input() activeItem: '' | 'priority' | 'sentiment' | 'tag' | 'notes';
  @Input() sentiment: number;
  @Input() allTags: string[];
  @Input() activeTags: string[];

  @Output() itemExpanded = new EventEmitter<any>();
  @Output() changeSentiment = new EventEmitter<number>();
  @Output() changeActiveTags = new EventEmitter<string[]>();
  @Output() addNote = new EventEmitter<any>();
  @Output() close = new EventEmitter();

  @ViewChild('actionsMenu') actionsMenu;

  createNewTagsOption = false;

  constructor(private activityTags: ActivityTags) {}

  async ngOnInit() {
    this.createNewTagsOption = await this.activityTags.getCanCreateNewTags();
  }
}
