import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import sanitizeHtml from 'sanitize-html';

export const mentionRegex = /\B\@([\w\'-u00A1-\uFFFF])*\'?/g;
export const sanitizeWithAllowedTags = (content) => {
  return sanitizeHtml(content, {
    allowedTags: ['a'],
    allowedAttributes: {
      a: ['user-id', 'group-id']
    }
  });
};

@Component({
  selector: 'ssi-notes-textarea',
  template: `
    <div class="notes-textarea-container">
      <textarea
        #textarea
        ngModel
        class="textarea-input"
        [class.borderless]="borderless"
        [mention]="tagList"
        [mentionConfig]="{
          labelKey: mentionLabelKey,
          mentionSelect: onMentionSelect
        }"
        (ngModelChange)="contentChange($event)"
        [attr.placeholder]="placeholder"
        [attr.rows]="rows"
      >
      </textarea>
      <div
        class="notes-tag"
        [innerHTML]="contentWithTags | sanitizedHtml"
      ></div>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotesTextareaComponent), //tslint:disable-line
      multi: true
    }
  ],
  styleUrls: ['./notes-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotesTextareaComponent implements ControlValueAccessor {
  @Input() tagList: any[];
  @Input() mentionLabelKey = 'mentionName';
  @Input() placeholder = '';
  @Input() rows = 7;
  @Input() borderless = false;

  @ViewChild('textarea') textarea: ElementRef;

  contentWithTags = '';

  constructor(protected renderer: Renderer2) {}

  onMentionSelect = (mention: any) => {
    return `@${mention[this.mentionLabelKey]} `;
  };

  contentChange(value: string) {
    const matchedMentions = value.match(mentionRegex);
    let content = (' ' + value).slice(1);

    if (matchedMentions) {
      matchedMentions.map((tag) => {
        const mentionMatch = this.tagList.find(
          (tagItem) => tagItem[this.mentionLabelKey] === tag.substring(1)
        );
        if (mentionMatch) {
          content = content.replace(
            tag,
            `<a ${mentionMatch.mentionAttribute}="${
              (mentionMatch as any).id
            }">@${mentionMatch[this.mentionLabelKey]}</a>`
          );
        }
      });
    }

    content = sanitizeWithAllowedTags(content);
    this.contentWithTags = content;

    this.propagateChange(content);
  }

  private onChangeCallback: (_: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: any) {
    this.renderer.setProperty(this.textarea.nativeElement, 'value', value);
    this.contentWithTags = value;
    this.onChangeCallback(value);
  }

  private propagateChange = (_: any) => {};
}
