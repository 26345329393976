import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-inline-add-note',
  templateUrl: './inline-add-note.component.html',
  styles: [],
  styleUrls: ['./inline-add-note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InlineAddNoteComponent {
  @Input() isPublic: boolean;
  @Input() showGuide = false;
  @Input() inverse = false;
  @Output() addNote = new EventEmitter();
  @Output() cancelNote = new EventEmitter();

  constructor() {}
}
