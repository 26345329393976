import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LiveChatMessageNoteModule } from '../live-chat-message-note/live-chat-message-note.module';
import { LiveChatInlineNotesComponent } from './live-chat-inline-notes.component';
import { LiveChatNoteFormModule } from '../live-chat-note-form/live-chat-note-form.module';

@NgModule({
  declarations: [LiveChatInlineNotesComponent],

  exports: [LiveChatInlineNotesComponent],

  imports: [CommonModule, LiveChatMessageNoteModule, LiveChatNoteFormModule]
})
export class LiveChatInlineNotesModule {}
