import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import Highcharts, { Options } from 'highcharts';
import { HighchartsHelperService } from '../../../../../../common/services/highcharts-helper/highcharts-helper.service';
import { FilterPeriod } from '../../chatbot-analytics.service';

@Component({
  selector: 'ssi-chatbot-feedback',
  templateUrl: './chatbot-feedback.component.html',
  styles: [],
  styleUrls: ['./chatbot-feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatbotFeedbackComponent implements OnInit {
  @Input() feedbackTimes: {
    resolved: { [key: string]: number };
    unresolved: { [key: string]: number };
    noFeedback: { [key: string]: number };
  };
  @Input() filterPeriod: FilterPeriod;

  feedbackRateChartConfig: Options;
  feedbackCountChartConfig: Options;

  constructor(private highchartsHelper: HighchartsHelperService) {}

  ngOnInit(): void {
    this.feedbackRateChartConfig = this.formatDataForFeedbackRateChart();
    this.feedbackCountChartConfig = this.formatDataForFeedbackCountChart();
  }

  private formatDataForFeedbackRateChart(): any {
    return {
      chart: {
        type: 'column',
        spacing: [60, 40, 20, 40],
        zoomType: 'x'
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          pointStart: Date.parse(this.filterPeriod.start),
          pointInterval: 24 * 3600 * 1000
        }
      },
      title: {
        text: null
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolSize: 15,
            symbolStroke: '#838EAB',
            x: 25,
            y: -35
          }
        }
      },
      legend: {
        useHTML: true,
        itemStyle: {
          color: '#43537F',
          textTransform: 'uppercase',
          fontSize: '10px',
          letterSpacing: '1px'
        },
        margin: 40
      },
      xAxis: {
        type: 'datetime',
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: 'Feedback percent',
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          },
          margin: 30
        },
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            textTransform: 'lowercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        alternateGridColor: '#F8F9FD'
      },
      series: [
        {
          name: 'Resolved',
          color: '#425DEC',
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.feedbackTimes.resolved,
            this.filterPeriod
          )
        },
        {
          name: 'Unresolved',
          color: '#D41D68',
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.feedbackTimes.unresolved,
            this.filterPeriod
          )
        },
        {
          name: 'No feedback',
          color: '#F0B427',
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.feedbackTimes.noFeedback,
            this.filterPeriod
          )
        }
      ],
      credits: {
        enabled: false
      },
      time: {
        useUTC: false
      }
    };
  }

  private formatDataForFeedbackCountChart(): any {
    return {
      chart: {
        type: 'spline',
        spacing: [60, 40, 20, 40],
        zoomType: 'x'
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          },
          lineWidth: 2,
          pointStart: Date.parse(this.filterPeriod.start),
          pointInterval: 24 * 3600 * 1000
        }
      },
      title: {
        text: null
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolSize: 15,
            symbolStroke: '#838EAB',
            x: 25,
            y: -35
          }
        }
      },
      legend: {
        useHTML: true,
        itemStyle: {
          color: '#43537F',
          textTransform: 'uppercase',
          fontSize: '10px',
          letterSpacing: '1px'
        },
        margin: 40
      },
      xAxis: {
        type: 'datetime',
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: 'Feedback count',
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          },
          margin: 30
        },
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            textTransform: 'lowercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: '900'
          }
        },
        alternateGridColor: '#F8F9FD'
      },
      series: [
        {
          name: 'Resolved',
          color: '#425DEC',
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.feedbackTimes.resolved,
            this.filterPeriod
          )
        },
        {
          name: 'Unresolved',
          color: '#D41D68',
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.feedbackTimes.unresolved,
            this.filterPeriod
          )
        },
        {
          name: 'No feedback',
          color: '#F0B427',
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.feedbackTimes.noFeedback,
            this.filterPeriod
          )
        }
      ],
      credits: {
        enabled: false
      },
      time: {
        useUTC: false
      }
    };
  }
}
