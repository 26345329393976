import { Component, OnInit, Inject } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';
import { RedirectService } from '@orlo/common/services/redirect/redirect.service';
import {
  CrmPersonModel,
  AccountModel,
  Account,
  UserModel
} from '@ui-resources-angular';
// import { types } from 'node-sass';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '@orlo/common/components/publisher/publisher-active';

@Component({
  selector: 'ssi-external',
  template: ''
})
export class ExternalComponent implements OnInit {
  constructor(
    private state: StateService,
    private transition: Transition,
    private redirect: RedirectService,
    private crmPersonModel: CrmPersonModel,
    private accountModel: AccountModel,
    private userModel: UserModel,
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive
  ) {}

  async ngOnInit() {
    let params;
    console.log('params', params);
    try {
      params = JSON.parse(decodeURIComponent(this.transition.params().params));
    } catch (e) {
      params = {};
      console.error('Error handling the external link params:', e);
    }

    switch (params.type) {
      case 'activity':
      case 'activity_note':
        this.state.go('auth.inbox', {
          activity: params.activity_id,
          note_id: params.note_id
        });
        break;

      case 'conversation':
        this.state.go('auth.inbox', {
          conversation: params.conversation_id
        });
        break;

      case 'profile':
      case 'profile_note':
        console.log('going to new page', params);
        this.state.go('auth.dashboard', {
          profileId: params.profile_id,
          accountId: params.account_id,
          noteId: params.note_id
        });
        break;

      case 'outbox':
      case 'outbox_note':
        this.state.go('auth.marketing.contentCalendar', {
          outboxId: params.outbox_id,
          query: JSON.stringify({ accounts: [params.account_id] }),
          note_id: params.note_id
        });
        break;

      case 'person_note':
        const crmPerson = await this.crmPersonModel.findFromUUID({ id: params.id });
        const profile = crmPerson.profiles[0];
        this.state.go('auth.dashboard', {
          profileId: profile.social_id,
          accountId: profile.account_id,
          noteId: params.note_id
        });
        break;

      case 'draft':
      case 'draft_note':
        this.state.go('auth.marketing.draftsLibrary', {
          draftId: params.draft_id,
          noteId: params.note_id
        });
        break;

      case 'publish_canva':
        this.state.go('auth.marketing.contentCalendar', {}).then(async () => {
          const images = [];
          for (const i in params.media) {
            images.push({ url: params.media[i].url });
          }

          const authUser = await this.userModel.getAuthUser();
          let accounts = await this.accountModel.findAllAccounts();
          accounts = accounts.filter(
            (account) =>
              account.socialNetwork.mediaRestrictions.post.image.max >=
                images.length &&
              !account.socialNetwork.inboundInitiated &&
              authUser.hasAccountPermission(account.id, 'post')
          );

          this.publisherActive.next({
            isActive: true,
            create: {
              accounts,
              files: images
            }
          });
        });
        break;

      default:
        this.redirect.goToHomePage();
    }
  }
}
