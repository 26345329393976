import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import { Options } from 'highcharts';
import Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';

import {
  Sentiment,
  Sentiments,
  sentiments,
  sentimentsIterable
} from '../../../../../../common/constants/sentiments';

export interface MessageSentiment {
  negative: number;
  neutral: number;
  positive: number;
  semi_negative: number;
  semi_positive: number;
}

@Component({
  selector: 'ssi-inbox-sentiment',
  templateUrl: './inbox-sentiment.component.html',
  styles: [],
  styleUrls: ['./inbox-sentiment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxSentimentComponent implements OnInit {
  @Input() socialAccounts;
  @Input() stats;

  pieChartConfig: Options;

  sentiments: Sentiments = sentiments;
  sentimentsIterable: Sentiment[] = sentimentsIterable;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.pieChartConfig = this.formatDataForSentimentPieChart(
      this.stats.activity.period.current.inbound.sentiment
    );
  }

  private formatDataForSentimentPieChart(sentiment: MessageSentiment): any {
    const total = Object.values(sentiment).reduce((a, b) => a + b, 0);

    const formattedData = Object.entries(sentiment)
      .map((keyValue) => {
        return {
          name: `<div class="highchart-legend-inline">${(
            (keyValue[1] / total) *
            100
          ).toFixed(1)}% </div>  |  <span>${this.translate.instant(
            this.sentiments[keyValue[0]].label
          )}</span>`,
          y: keyValue[1],
          color: this.sentimentsIterable.find((s) => s.key === keyValue[0])
            .color,
          _pos: this.sentiments[keyValue[0]].numericKey
        };
      })
      .sort((a, b) => b._pos - a._pos);
    formattedData.forEach((s) => delete s._pos);

    return {
      chart: {
        type: 'pie',
        backgroundColor: null,
        spacing: [70, 70, 70, 70]
      },
      credits: {
        enabled: false
      },
      legend: {
        maxHeight: 300,
        width: 180,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginBottom: 30,
        x: -20,
        useHTML: true,
        itemStyle: {
          fontSize: '14px',
          fontWeight: '900',
          color: '#101525',
          fontFamily: 'Lato, Arial, sans-serif'
        }
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      accessibility: {
        description: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [
        {
          name: 'Messages',
          colorByPoint: true,
          minPointSize: 10,
          innerSize: '60%',
          zMin: 0,
          data: formattedData
        }
      ],
      loading: false
    };
  }
}
