import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  OnChanges,
  Input,
  Renderer2,
  OnDestroy,
  Output,
  EventEmitter,
  Inject
} from '@angular/core';
import { GET_TEXTAREA_CARET_COORDINATES } from '../../../../../../../common/services/services.module';

@Component({
  selector: 'ssi-textarea-caret-tracker',
  templateUrl: './textarea-caret-tracker.component.html',
  styleUrls: ['./textarea-caret-tracker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextareaCaretTrackerComponent implements OnChanges, OnDestroy {
  @Input() textarea: HTMLTextAreaElement;

  @Input() isActive: boolean;

  @Output() iconClicked: EventEmitter<void> = new EventEmitter();

  isFocused = false;

  eventListeners = [];

  barTop: number;

  constructor(
    private renderer: Renderer2,
    @Inject(GET_TEXTAREA_CARET_COORDINATES) private getCaretCoordinates
  ) {}

  ngOnChanges(changes) {
    if (changes.textarea && this.textarea) {
      this.unregisterEventListeners();
      this.eventListeners = [
        this.renderer.listen(this.textarea, 'focus', () =>
          this.onTextareaFocus()
        ),
        this.renderer.listen(this.textarea, 'blur', () =>
          this.onTextareaBlur()
        ),
        this.renderer.listen(this.textarea, 'keyup', () => this.positionBar()),
        this.renderer.listen(this.textarea, 'click', () => this.positionBar())
      ];
      if (document.activeElement === this.textarea) {
        setTimeout(() => {
          this.onTextareaFocus();
        });
      }
    }
  }

  ngOnDestroy() {
    this.unregisterEventListeners();
  }

  onTextareaFocus() {
    this.isFocused = true;
    this.positionBar();
  }

  onTextareaBlur() {
    // hack to make it possible to click on the tracker icon
    setTimeout(() => {
      this.isFocused = false;
    }, 100);
  }

  private positionBar() {
    const caret = this.getCaretCoordinates(
      this.textarea,
      this.textarea.selectionStart
    );
    const caretHeight = +getComputedStyle(this.textarea)['line-height'].replace(
      /px$/,
      ''
    );
    this.barTop = caret.top + caretHeight - this.textarea.scrollTop;
  }

  private unregisterEventListeners() {
    this.eventListeners.forEach((unregister) => unregister());
    this.eventListeners = [];
  }
}
