import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  OnDestroy
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { MonitoringStreamsService, Stream } from './monitoring-streams.service';
import { authUser } from '../../../common-resolves';
import { AccountModel, User } from '@ui-resources-angular';
import { Debounce } from '../../../../common/decorators';
import { mapToIterable } from '../../../../common/utils';
import { PaginatorComponent } from '../../../../common/components/paginator';
import { StreamBuilderService } from '../stream-builder/stream-builder.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CreateEditListeningModalComponent,
  ListeningModalVariant
} from '../common/components/create-edit-listening-modal/create-edit-listening-modal.component';
import { DatePipe } from '@angular/common';
import {
  ApiService,
  ColleaguesService,
  MonitoringStream
} from '../../../../common/services/api';
import { AccountTypeId } from '../../../../common/enums';
import { TooltipDirective } from '../../../../common/directives/tooltip/tooltip.directive';
import {
  ListeningGroup,
  ListeningPage,
  ListeningStreamService
} from '../../../../common/services/api/listening-stream';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CompanyService } from '../../../../common/services/company/company.service';
import { CreateEditHashtagModalComponent } from '../common/components/create-edit-hashtag-modal/create-edit-hashtag-modal.component';

export function monitoringStreamFn(monitoringStreamService) {
  return monitoringStreamService.getAll();
}

export interface InstagramHashtag {
  account_id: number;
  company_id: number;
  created_at: Date;
  created_by: number;
  id: number;
  instagram_id: string;
  is_disabled: boolean;
  last_imported_at: Date;
  name: string;
}

export enum MonitoringType {
  Inbox = 'inbox',
  Reporting = 'my streams',
  FacebookListening = 'facebook listening',
  InstagramHashtags = 'instagram hashtags'
}

@Component({
  selector: 'ssi-monitoring-streams',
  templateUrl: './monitoring-streams.component.html',
  styleUrls: ['./monitoring-streams.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MonitoringStreamsComponent implements OnInit, OnDestroy {
  static resolve = [
    {
      token: 'streams',
      resolveFn: monitoringStreamFn,
      deps: [MonitoringStreamsService]
    },
    authUser
  ];

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  @Input() streams: MonitoringStream[];
  @Input() authUser: User;

  streamsToRender: MonitoringStream[];
  filteredStreams: MonitoringStream[];
  isLoadingStreams = false;

  MonitoringType = MonitoringType;
  selectedMonitoringType = MonitoringType.Reporting;
  filterTerm = '';
  sortOptions = {
    name: {
      key: 'name',
      label: 'Alphabetically'
    },
    newest: {
      key: 'newest',
      label: 'By Newest'
    },
    oldest: {
      key: 'oldest',
      label: 'By Oldest'
    }
  };
  sortOptionsIterable = mapToIterable(this.sortOptions);
  selectedStreamsSortOption = this.sortOptions.name;
  selectedListeningSortOption = this.sortOptions.name;
  selectedHashtagSortOption = this.sortOptions.name;

  filterListeningTerm = '';
  filteredListeningPages: any[];
  listeningGroup: { name: string; id: string }; // currently only supporting one group as per ticket req for now
  listeningPages: ListeningPage[];

  filterHashtagTerm = '';
  filteredHashtags: any[];
  hashtags: any;

  hasFacebookAccounts = false;
  hasInstagramAccounts = false;

  @ViewChildren('removePageTooltip')
  removePageTooltips: QueryList<TooltipDirective>;

  socialListeningEnabled = false;
  hashtagsEnabled = false;

  protected destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private state: StateService,
    private streamBuilderService: StreamBuilderService,
    private listeningStreamService: ListeningStreamService,
    private modal: NgbModal,
    private accountModel: AccountModel,
    private company: CompanyService,
    private colleaguesService: ColleaguesService,
    private api: ApiService
  ) {}

  async ngOnInit() {
    this.performSocialListeningChecks();
    this.initHashtags();

    this.isLoadingStreams = true;
    this.sortStreamList();
    this.filterStreams();
    this.isLoadingStreams = false;
  }

  async initHashtags() {
    this.hashtagsEnabled = await this.company.hasFeatureAccess(
      'INSTAGRAM_HASHTAG_MONITORING'
    );

    if (!this.hashtagsEnabled) {
      return;
    }

    await this.getInstagramHashtags();

    this.hashtags.forEach(async (hashtag) => {
      hashtag.createdBy = await this.colleaguesService.findOneById(
        String(hashtag.created_by)
      );
    });
    this.sortHashtagsList();
    const allAccounts = await this.accountModel.findAccounts();
    this.hasInstagramAccounts = allAccounts.some(
      (a) =>
        a.account_type_id === String(AccountTypeId.Instagram) &&
        !a.is_reauth_required
    );
  }

  async performSocialListeningChecks() {
    this.socialListeningEnabled = await this.company.hasFeatureAccess(
      'SOCIAL_LISTENING'
    );
    if (this.socialListeningEnabled) {
      if (
        this.state &&
        this.state.params &&
        this.state.params.tab &&
        this.state.params.tab === 'listening'
      ) {
        this.selectedMonitoringType = MonitoringType.FacebookListening;
      }
      await this.listGroups();
      await this.listPagesInGroup();
      this.filteredListeningPages = this.listeningPages;
      const allAccounts = await this.accountModel.findAccounts();
      this.hasFacebookAccounts = allAccounts.some(
        (a) =>
          a.account_type_id === String(AccountTypeId.Facebook) &&
          !a.is_reauth_required
      );
    }
  }

  filterStreams(): void {
    this.filteredStreams = this.streams.filter((s) => {
      return s.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1;
    });
  }

  filterListeningPages(): void {
    this.filteredListeningPages = this.listeningPages.filter((p) => {
      return (
        p.name.toLowerCase().indexOf(this.filterListeningTerm.toLowerCase()) >
        -1
      );
    });
  }

  filterHashtags(): void {
    this.filteredHashtags = this.hashtags.filter((p) => {
      return (
        p.name.toLowerCase().indexOf(this.filterHashtagTerm.toLowerCase()) > -1
      );
    });
  }

  private _sortByName = (a, b) => {
    const dateA = a.name.toLowerCase();
    const dateB = b.name.toLowerCase();
    return dateA > dateB ? 1 : -1;
  };

  private _sortByNewestCreated = (a, b) => {
    const dateA = a.created_at ? Date.parse(a.created_at) : 0;
    const dateB = b.created_at ? Date.parse(b.created_at) : 0;
    return dateA > dateB ? -1 : 1;
  };

  private _sortByOldestCreated = (a, b) => {
    const dateA = a.created_at ? Date.parse(a.created_at) : 0;
    const dateB = b.created_at ? Date.parse(b.created_at) : 0;
    return dateA > dateB ? 1 : -1;
  };

  sortStreamList() {
    switch (this.selectedStreamsSortOption.key) {
      case this.sortOptions.name.key:
        this.streams.sort(this._sortByName);
        break;
      case this.sortOptions.newest.key:
        this.streams.sort(this._sortByNewestCreated);
        break;
      case this.sortOptions.oldest.key:
        this.streams.sort(this._sortByOldestCreated);
        break;
      default:
        break;
    }
  }

  sortHashtagsList() {
    this.filteredHashtags = this.hashtags;
    switch (this.selectedHashtagSortOption.key) {
      case this.sortOptions.name.key:
        this.filteredHashtags.sort(this._sortByName);
        break;
      case this.sortOptions.newest.key:
        this.filteredHashtags.sort(this._sortByNewestCreated);
        break;
      case this.sortOptions.oldest.key:
        this.filteredHashtags.sort(this._sortByOldestCreated);
        break;
      default:
        break;
    }
  }

  sortListeningPagesList() {
    switch (this.selectedListeningSortOption.key) {
      case this.sortOptions.name.key:
        this.listeningPages.sort(this._sortByName);
        break;
      case this.sortOptions.newest.key:
        this.listeningPages.sort(this._sortByNewestCreated);
        break;
      case this.sortOptions.oldest.key:
        this.listeningPages.sort(this._sortByOldestCreated);
        break;
      default:
        break;
    }
  }

  openMonitoringWizard(stream?) {
    if (stream) {
      this.streamBuilderService.updateStreamQuery(stream);
    } else {
      this.streamBuilderService.refreshStreamQuery();
    }
    this.state.go('auth.monitoring.monitoringStreamBuilder.desc');
  }

  @Debounce(600)
  onFilterTermChange(): void {
    this.filterStreams();
    this.paginator.init();
  }

  @Debounce(600)
  onListeningFilterTermChange(): void {
    this.filterListeningPages();
    this.paginator.init();
  }

  onMonitoringOptionChange(selectedMonitoringType): void {
    this.selectedMonitoringType = selectedMonitoringType;
    this.filterStreams();
    if (
      this.selectedMonitoringType === MonitoringType.Reporting &&
      this.paginator
    ) {
      this.paginator.init();
    }
  }

  onSortOptionChange(): void {
    if (this.selectedMonitoringType === MonitoringType.FacebookListening) {
      this.sortListeningPagesList();
    } else if (
      this.selectedMonitoringType === MonitoringType.InstagramHashtags
    ) {
      this.sortHashtagsList();
    } else {
      this.sortStreamList();
      this.filterStreams();
      this.paginator.init();
    }
  }

  onPageChange(opts) {
    this.streamsToRender = this.filteredStreams.slice(
      opts.startIndex,
      opts.endIndex
    );
  }

  async listGroups() {
    try {
      const res = await this.listeningStreamService.listGroups();
      this.listeningGroup = res[res.length - 1];
      console.log('listGroups res:', res);
    } catch (error) {
      console.error('Error getting the list of listening groups:', error);
    }
  }

  async listPagesInGroup(groupId?: string) {
    try {
      await this.listeningStreamService.listPagesInGroup(
        this.listeningGroup.id
      );

      this.listeningStreamService.store.value$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((pages) => {
          console.log('subscribed pages:', pages);
          this.listeningPages = pages;
          this.filteredListeningPages = pages;
        });
    } catch (error) {
      console.error(
        'Error getting the list of pages in listening groups:',
        error
      );
    }
  }

  openCreateGroupModal() {
    const modal = this.modal.open(CreateEditListeningModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.variant = ListeningModalVariant.createGroup;
    modal.componentInstance.onSave.subscribe((createdGroup: any) => {
      console.log('Created group:', createdGroup);
      this.listeningGroup.id = createdGroup.listening_group_id;
      // this.listPagesInGroup();
    });
  }

  openCreateHashtagModal() {
    const modal = this.modal.open(CreateEditHashtagModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.onSave.subscribe(async (hashtagName: string) => {
      await this.getInstagramHashtags();

      this.sortHashtagsList();
    });
  }

  openDeleteHashtagModal(rowData) {
    const modal = this.modal.open(CreateEditHashtagModalComponent, {
      windowClass: 'orlo-modal'
    });

    modal.componentInstance.instagramHashtagId = rowData.instagram_id;
    modal.componentInstance.hashtagName = rowData.name;
    modal.componentInstance.accountId = rowData.account_id;
    modal.componentInstance.onSave.subscribe(async (hashtagID: string) => {
      await this.getInstagramHashtags();

      this.filteredHashtags = this.hashtags.filter(
        (hashtag) => hashtag.instagram_id !== hashtagID
      );
      this.sortHashtagsList();
    });
  }

  async getInstagramHashtags() {
    const hashtagsResponse: any = await this.api
      .get(`${this.api.url}/monitoring/listInstagramHashtags`)
      .toPromise();
    this.hashtags = hashtagsResponse.hashtags.filter(
      (h: InstagramHashtag) => !h.is_disabled
    );

    this.hashtags.forEach(async (h) => {
      h.createdBy = await this.colleaguesService.findOneById(
        String(h.created_by)
      );
    });

    this.filteredHashtags = this.hashtags;
  }

  openAddPageModal() {
    const modal = this.modal.open(CreateEditListeningModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.variant = ListeningModalVariant.addPage;
    modal.componentInstance.group = this.listeningGroup;
    modal.componentInstance.onSave.subscribe((addedPage: any) => {
      console.log('Added page:', addedPage);
      this.listPagesInGroup();
      // this.listeningPages.push(addedPage);
    });
  }

  openEditGroupModal() {
    const modal = this.modal.open(CreateEditListeningModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.variant = ListeningModalVariant.editGroup;
    modal.componentInstance.group = this.listeningGroup;

    modal.componentInstance.onSave.subscribe((group: ListeningGroup) => {
      this.listeningGroup = group;
      console.log('Edited group:', group);
    });
  }

  openDeleteGroupModal() {
    const modal = this.modal.open(CreateEditListeningModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.variant = ListeningModalVariant.deleteGroup;
    modal.componentInstance.group = this.listeningGroup;
    modal.componentInstance.onSave.subscribe((page: any) => {
      console.log('Deleted group:', page);
    });
  }

  showRemovePageTooltip(rowIndex: number) {
    this.removePageTooltips
      .toArray()
      .find((t) => t.tooltipId === rowIndex)
      .show();
  }

  hideRemovePageTooltip(rowIndex: number) {
    this.removePageTooltips
      .toArray()
      .find((t) => t.tooltipId === rowIndex)
      .hide();
  }

  closeAllRemovePageTooltips() {
    this.removePageTooltips.toArray().forEach((t) => t.hide());
  }

  removePage(page: any) {
    console.log('remove page:', page);
    const req = {
      listening_group_id: this.listeningGroup.id,
      account_type_id: AccountTypeId.Facebook,
      social_id: page.social_id
    };
    this.listeningStreamService.removePageFromGroup(req);
    this.closeAllRemovePageTooltips();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.destroyed$.next();
  }
}
