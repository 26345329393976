import {
  Account,
  AccountModel,
  ActivityTags,
  CrmExternalIntegration,
  WorkflowModel
} from '@ui-resources-angular';
import inboxImg from './img/inbox-infographic.png';
import {
  TriggerConfig,
  ActionConfig,
  UIParam,
  UIParamFormType,
  staticParams,
  ParamKeyValueMap
} from '../ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import {
  ServiceMapping,
  overlayDimensions,
  mapSelectOptions,
  getParamByName,
  minShouldMatchParamFactory,
  IngredientConfig,
  replaceTemplateIngredientsWithHtml,
  IngredientType
} from './util';
import { AppletTriggerParam, AppletActionParam } from '../applets/applet';
import { Webhook } from '@orlo/common/services/webhook-model/webhook-model';
import { WorkflowManagerService } from '@orlo/common/services/workflow-manager/workflow-manager.service';
import {
  TeamsService,
  Team,
  Colleague,
  MonitoringStream
} from '@orlo/common/services/api';
import { AccountTypeId } from '@orlo/common/enums';
import { appInjector } from '@orlo/app-injector';
import { Injector } from '@angular/core';
import {
  IftttServiceModel,
  IftttService
} from '../ifttt-service-model/ifttt-service-model';

const triggers: TriggerConfig[] = [
  {
    api: {
      name: 'from stream'
    },
    isStreamTrigger: true,
    getLabel(params: AppletTriggerParam[]) {
      const streams: MonitoringStream[] = getParamByName(params, 'streams')
        .value;
      return (
        'Is on stream ' + streams.map((stream) => `${stream.name}`).join(' or ')
      );
    }
  },
  {
    api: {
      name: 'queries match content'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_CONTAINING_WORD_TITLE',
      description: 'INBOX_TRIGGER_CONTAINING_WORD_DESCRIPTION'
    },
    iconClass: 'ssi ssi-document-help',
    overlayDimensions: overlayDimensions.tagsInput,
    getLabel(params: AppletTriggerParam[]) {
      const mode: number = getParamByName(params, 'mode').value;
      const queries: string[] = getParamByName(params, 'queries').value;
      const operand: string =
        getParamByName(params, 'min_should_match').value === 'or'
          ? 'OR'
          : 'AND';
      return `${
        mode === 0 ? 'Contains the text' : 'Matches word(s)'
      } ${queries.map((query) => `"${query}"`).join(` ${operand} `)}`;
    },
    paramOverrides: {
      min_should_match: () => minShouldMatchParamFactory(),
      mode(): UIParam {
        return {
          form: {
            type: UIParamFormType.Hidden,
            defaultValue: 0,
            showAsCheckboxInput: true
          }
        };
      }
    }
  },
  {
    api: {
      name: 'sentiment'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_SENTIMENT_TITLE',
      description: 'INBOX_TRIGGER_SENTIMENT_DESCRIPTION'
    },
    iconClass: 'ssi ssi-semi-positive',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 220
    },
    getLabel(params: AppletTriggerParam[]) {
      const sentimentKeys = getParamByName(
        params,
        'sentiments'
      ) as AppletTriggerParam;
      const sentiment: string[] = mapSelectOptions(
        sentimentKeys,
        sentimentKeys.param.form.sentiment.options
      );
      return `Sentiment is ${sentiment.join(' or ')}`;
    }
  },
  {
    api: {
      name: 'language'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_LANGUAGE_TITLE',
      description: 'INBOX_TRIGGER_LANGUAGE_DESCRIPTION'
    },
    iconClass: 'ssi ssi-language',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletTriggerParam[]) {
      const languageKeys = getParamByName(
        params,
        'languages'
      ) as AppletTriggerParam;
      const langs: string[] = mapSelectOptions(
        languageKeys,
        languageKeys.param.form.select.options
      );
      return `Language is ${langs.join(' or ')}`;
    }
  },
  {
    api: {
      name: 'ai classifications'
    },
    translationIds: {
      title: 'AI Classifications',
      description: 'All messages given a classification by AI'
    },
    iconClass: 'fa fa-warning',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: overlayDimensions.select.height
    },
    getLabel(params: AppletTriggerParam[]) {
      const classificationsKeys = getParamByName(
        params,
        'classifications'
      ) as AppletTriggerParam;
      const classifications: string[] = mapSelectOptions(
        classificationsKeys,
        classificationsKeys.param.form.select.options
      );
      const operand: string =
        getParamByName(params, 'min_should_match').value === 'or'
          ? 'OR'
          : 'AND';
      return `Messages have the classification of ${classifications.join(
        ` ${operand} `
      )}`;
    },
    paramOverrides: {
      min_should_match: () => minShouldMatchParamFactory()
    }
  },
  {
    api: {
      name: 'word count'
    },
    translationIds: {
      title: 'INBOX_TRIGGER_WORD_COUNT_TITLE',
      description: 'INBOX_TRIGGER_WORD_COUNT_DESCRIPTION'
    },
    iconClass: 'ssi ssi-character-count',
    overlayDimensions: {
      width: overlayDimensions.select.width,
      height: 320
    },
    getLabel(params: AppletTriggerParam[]) {
      const limit = getParamByName(params, 'limit').value;
      const mode = getParamByName(params, 'compare_mode').value;
      const labels = {
        GT: 'greater than',
        LT: 'less than',
        GTE: 'greater than or equal to',
        LTE: 'less than or equal to'
      };
      return `Message word count is ${labels[mode]} ${limit}`;
    },
    paramOverrides: {
      compare_mode(): UIParam {
        return {
          form: {
            type: UIParamFormType.SelectSingle,
            select: {
              options: [
                {
                  value: 'GT',
                  label: 'Greater than',
                  trackBy: 0
                },
                {
                  value: 'LT',
                  label: 'Less than',
                  trackBy: 1
                },
                {
                  value: 'GTE',
                  label: 'Greater than or equal to',
                  trackBy: 2
                },
                {
                  value: 'LTE',
                  label: 'Less than or equal to',
                  trackBy: 3
                }
              ]
            }
          }
        };
      },
      limit(): UIParam {
        return {
          form: {
            type: UIParamFormType.TextInput,
            input: {
              placeholderTranslationKey: 'INBOX_TRIGGER_WORD_COUNT_PLACEHOLDER',
              type: 'number'
            }
          }
        };
      }
    }
  },
  {
    api: {
      name: 'vector similarity'
    },
    translationIds: {
      title: 'MONITORING_TRIGGER_VECTOR_TITLE',
      description: 'MONITORING_TRIGGER_VECTOR_DESCRIPTION'
    },
    iconClass: 'ssi ssi-orlo-insights',
    overlayDimensions: overlayDimensions.tagsInput,
    getLabel(params: AppletTriggerParam[]) {
      const queries = getParamByName(params, 'queries').value.map(
        (q) => `"${q}"`
      );

      return `Themes: ${queries.join(' or ')}`;
    },
    paramOverrides: {
      queries(): UIParam {
        return {
          form: {
            type: UIParamFormType.TagsInput,
            maxValues: 3,
            input: {
              placeholderTranslationKey: 'TYPE_WORDS_OR_PHRASES_AND_HIT_ENTER'
            }
          }
        };
      }
    }
  }
];

const actions: ActionConfig[] = [
  {
    api: {
      name: 'assign to user'
    },
    translationIds: {
      title: 'INBOX_ACTION_ASSIGN_TO_USER_TITLE',
      description: 'INBOX_ACTION_ASSIGN_TO_USER_DESCRIPTION'
    },
    iconClass: 'ssi ssi-oval-user-icon',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const user: Colleague = getParamByName(params, 'user_id').value;
      return `Assign to ${user && user.fullName}`;
    }
  },
  {
    api: {
      name: 'add tags'
    },
    translationIds: {
      title: 'INBOX_ACTION_ADD_TAGS_TITLE',
      description: 'INBOX_ACTION_ADD_TAGS_DESCRIPTION'
    },
    iconClass: 'ssi ssi-tag',
    overlayDimensions: overlayDimensions.tagsInput,
    getLabel(params: AppletActionParam[]) {
      const tags: string[] = getParamByName(params, 'tags').value;
      return `Add the tags "${tags.join('", "')}"`;
    },
    paramOverrides: {
      async tags(injector: Injector) {
        const tags = await injector.get(ActivityTags).getTags();
        const paramConfig = staticParams.tagsInput();
        paramConfig.form.input.promptOptions = tags;
        paramConfig.form.input.placeholderTranslationKey =
          'TYPE_YOUR_TAG_AND_PRESS_ENTER';
        return paramConfig;
      }
    }
  },
  {
    api: {
      name: 'send webhook message'
    },
    translationIds: {
      title: 'OUTBOX_ACTION_SEND_WEBHOOK_MESSAGE_TITLE',
      description: 'OUTBOX_ACTION_SEND_WEBHOOK_MESSAGE_DESCRIPTION'
    },
    iconClass: 'fa fa-plug',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const webhook: Webhook = getParamByName(params, 'webhook_id').value;
      return `Send a webhook notification to "${webhook && webhook.name}"`;
    }
  },
  {
    api: {
      name: 'push to inbox'
    },
    translationIds: {
      title: 'MONITORING_ACTION_PUSH_TO_INBOX_TITLE',
      description: 'MONITORING_ACTION_PUSH_TO_INBOX_TITLE' // TODO
    },
    iconClass: 'ssi ssi-send-icon',
    overlayDimensions: overlayDimensions.select,
    getLabel(params: AppletActionParam[]) {
      const accountId = getParamByName(params, 'account_id').value;
      const accountModel = appInjector().get(AccountModel);
      const account = accountModel.get(accountId);
      return `Push message to "${account && account.name}"`;
    },
    paramOverrides: {
      async account_id(injector: Injector) {
        const accounts = await injector
          .get(AccountModel)
          .findAccounts(
            (await injector.get(WorkflowManagerService)).getCurrentId()
          );
        const twitterAccs = accounts.filter(
          (a) => a.account_type_id === String(AccountTypeId.Twitter)
        );

        const paramConfig = staticParams.accountSelect();
        paramConfig.form.select.options = twitterAccs.map((a) => {
          return {
            value: a.id,
            label: a.name,
            trackBy: a.id
          };
        });

        return paramConfig;
      }
    }
  }
];

const ingredients: IngredientConfig[] = [
  {
    type: IngredientType.Template,
    api: {
      name: 'company.id'
    },
    translationIds: {
      label: 'Company_ID'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'search_stream.id'
    },
    translationIds: {
      label: 'STREAM_ID'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'monitoring_source'
    },
    translationIds: {
      label: 'MONITORING_SOURCE'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'external_id'
    },
    translationIds: {
      label: 'EXTERNAL_ID'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'id'
    },
    translationIds: {
      label: 'ID'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'interaction.content'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_INTERACTION_CONTENT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'interaction.created_at'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_CREATED_AT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'author.name'
    },
    translationIds: {
      label: 'AUTHOR_NAME'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'author.influence'
    },
    translationIds: {
      label: 'AUTHOR_INFLUENCE'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'sentiment'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_SENTIMENT_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'language'
    },
    translationIds: {
      label: 'LANGUAGE'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'visibility'
    },
    translationIds: {
      label: 'CONVERSATION_INGREDIENT_VISIBILITY_LABEL'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'ai_classifications'
    },
    translationIds: {
      label: 'AI CLASSIFICATIONS'
    }
  },
  {
    type: IngredientType.Template,
    api: {
      name: 'vector'
    },
    translationIds: {
      label: 'VECTOR'
    }
  }
];

const service: ServiceMapping = {
  id: 'monitoring.imported',
  brand: {
    img: inboxImg,
    iconClass: 'ssi ssi-ongoing-message'
  },
  triggers,
  actions,
  ingredients,
  translationIds: {
    title: 'INSIGHTS_STREAMS',
    triggers: 'INSIGHTS_STREAM_TRIGGERS',
    actions: 'INSIGHTS_STREAM_ACTIONS'
  }
};

export default service;
