import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NguiReactModule } from '@ngui/react';
import { PhotoEditorSDKDesktopUIComponent } from './pesdk-desktopui.component';

@NgModule({
  imports: [CommonModule, NguiReactModule],
  declarations: [PhotoEditorSDKDesktopUIComponent],
  exports: [PhotoEditorSDKDesktopUIComponent]
})
export class PhotoEditorSDKDesktopUIModule {}