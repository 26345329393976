import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatConversationStatisticsModule } from '../live-chat-conversation-statistics/live-chat-conversation-statistics.module';
import { LiveChatOfflineAgentNoticeModule } from '../live-chat-offline-agent-notice/live-chat-offline-agent-notice.module';

import { LiveChatNextChatComponent } from './live-chat-next-chat.component';

@NgModule({
  declarations: [LiveChatNextChatComponent],

  exports: [LiveChatNextChatComponent],

  imports: [
    CommonModule,
    LiveChatConversationStatisticsModule,
    LiveChatOfflineAgentNoticeModule,
    TranslateModule
  ]
})
export class LiveChatNextChatModule {}
