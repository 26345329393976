import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  Renderer2,
  Input
} from '@angular/core';
import { TeamsService, Team } from '../../services/api/teams';
import _ from 'lodash';
import { Colleague, ColleaguesService } from '../../services/api';
import {
  mentionRegex,
  sanitizeWithAllowedTags
} from '../notes-textarea/notes-textarea.component';

@Component({
  selector: 'ssi-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNoteComponent implements OnInit {
  @Input() disableTags = false;

  @Output() addNote = new EventEmitter<any>();
  @Output() cancelNote = new EventEmitter<any>();

  @ViewChild('noteInput') noteInput;
  @ViewChild('noteOverlay') noteOverlay;

  note = '';
  colleagues: Colleague[];
  teams: Team[];
  tagList: any[];
  colleaguesTagList: any[];
  teamTagList: any[];
  noteWithTags: any = 'note';

  constructor(
    private renderer: Renderer2,
    private colleaguesService: ColleaguesService,
    private teamsService: TeamsService
  ) {}

  async ngOnInit() {
    this.renderer.selectRootElement('#noteInput').focus();
    this.colleagues = await this.colleaguesService.getAllActive();
    this.teams = await this.teamsService.getAllActive();

    this.tagList = [...this.teams, ...this.colleagues];
  }

  onNoteInputScroll(event) {
    this.renderer.setProperty(
      this.noteOverlay.nativeElement,
      'scrollTop',
      event.target.scrollTop
    );
  }

  public contentChange(value: string) {
    const matchedMentions = value.match(mentionRegex);
    let content = (' ' + value).slice(1);

    if (matchedMentions) {
      matchedMentions.map((tag) => {
        const mentionMatch = this.tagList.find(
          (tagItem) => tagItem['mentionName'] === tag.substring(1)
        );
        if (mentionMatch) {
          content = content.replace(
            tag,
            `<a ${mentionMatch.mentionAttribute}="${
              (mentionMatch as any).id
            }">@${mentionMatch['mentionName']}</a>`
          );
        }
      });
    }

    content = sanitizeWithAllowedTags(content);
    this.noteWithTags = content;
  }
}
