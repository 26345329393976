import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-survey',
  templateUrl: './survey.component.html',
  styles: [],
  styleUrls: ['./survey.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
