import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import {
  Sentiment,
  Sentiments,
  sentiments,
  sentimentsIterable
} from '../../../../../common/constants/sentiments';

@Component({
  selector: 'ssi-sentiment-card',
  templateUrl: './sentiment-card.component.html',
  styles: [],
  styleUrls: ['./sentiment-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SentimentCardComponent implements OnInit {
  @Input() sentiment: Sentiment;
  @Input() label = '';
  @Input() currentValue: number;
  @Input() previousValue: number;
  @Input() showStatChange = true;

  sentiments: Sentiments = sentiments;
  sentimentsIterable: Sentiment[] = sentimentsIterable;

  constructor() {}

  ngOnInit() {}
}
