import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

@Component({
  selector: 'ssi-save-draft-modal',
  templateUrl: './save-draft-modal.component.html',
  styleUrls: ['./save-draft-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaveDraftModalComponent {
  @Input() title: string;
  @Input() meta: string;
  @Input() userIsAdmin: boolean;
  @Input() showSecondaryAction: boolean;
  @Input() draftTitle: string;
  @Input() showPublicDraftOption = true;
  @Input() isDraftPublic: boolean;
  @Input() primaryActionButtonText: string;
  @Output() onDraftTitleChange = new EventEmitter<string>();
  @Output() onPublicDraftToggle = new EventEmitter<boolean>();
  draftTitleMaxLength = 60;

  constructor(public activeModal: NgbActiveModal) {}
}
