import { mapToIterable } from '../utils';

export interface CompareToOption {
  id: string;
  label: string;
  getCompareToDates?: (start: Date, end: Date) => { start: Date; end: Date };
}

export interface CompareToOptions {
  previousPeriod: CompareToOption;
  previousYear: CompareToOption;
  custom: CompareToOption;
  noComparison: CompareToOption;
}

export const compareToOptions: CompareToOptions = {
  previousPeriod: {
    id: 'previousPeriod',
    label: 'Previous period',
    getCompareToDates: (start: Date, end: Date) => {
      return {
        end: new Date(start.getTime() - 1), // subtract 1 ms so it switches to the end of the day before
        start: new Date(start.getTime() - (end.getTime() - start.getTime()))
      };
    }
  },
  previousYear: {
    id: 'previousYear',
    label: 'Previous year',
    getCompareToDates: (start: Date, end: Date) => {
      return {
        end: new Date(new Date(end).setFullYear(end.getFullYear() - 1)),
        start: new Date(new Date(start).setFullYear(start.getFullYear() - 1))
        // end: moment(end).subtract(1, 'year').toDate(),
        // start: moment(start).subtract(1, 'year').toDate()
      };
    }
  },
  custom: {
    id: 'custom',
    label: 'Custom',
    getCompareToDates: (start: Date, end: Date) => {
      return {
        end: new Date(start.getTime() - 1), // subtract 1 ms so it switches to the end of the day before
        start: undefined
      };
    }
  },
  noComparison: {
    id: 'noComparison',
    label: 'No comparison',
    getCompareToDates: (start: Date, end: Date) => {
      return {
        end: undefined,
        start: undefined
      };
    }
  }
};

export const compareToOptionsIterable: CompareToOption[] = mapToIterable(
  compareToOptions
);
