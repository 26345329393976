import { ViewEncapsulation } from '@angular/core';

import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { FileMimeTypes } from '../../../../../common/directives/file-download/file-download.directive';
import {
  FileDownloadService,
  SpreadsheetService
} from '../../../../../common/services';
import { decodeStateParams } from '@orlo/common/utils';
import { Debounce } from '../../../../../common/decorators';
import { isElementWithinViewport } from '../../../../../common/utils';
import { TeamService } from '../team.service';

export interface DateRange {
  start: Date;
  end: Date;
}

@Component({
  selector: 'ssi-team-report',
  templateUrl: './team-report.component.html',
  styles: [],
  styleUrls: ['./team-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamReportComponent implements OnInit {
  reportActions = this.getReportActions();
  navItems = this.getNavItems();
  activeNavItemTag = 'users-summary';
  dateRanges: DateRange;

  loading = false;
  report;

  decodedStateParams: { [key: string]: any };

  constructor(
    public state: StateService,
    protected elementRef: ElementRef,
    protected teamService: TeamService,
    private spreadsheetService: SpreadsheetService,
    private fileDownload: FileDownloadService
  ) {}

  async ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    this.setDateRanges();
    await this.loadReport();
  }

  setDateRanges(): void {
    this.dateRanges = {
      start: new Date(this.decodedStateParams.startDate),
      end: new Date(this.decodedStateParams.endDate)
    };
  }

  async reportDateChanged(dateRanges): Promise<void> {
    this.dateRanges.start = new Date(dateRanges.from);
    this.dateRanges.end = new Date(dateRanges.to);
    await this.loadReport();
  }

  async loadReport(): Promise<void> {
    this.loading = true;
    try {
      this.report = await this.teamService.loadReport(this.dateRanges);
      console.log('report: ', this.report);

      this.populateNavItemElements();
      this.loading = false;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);
      window.history.back();
      return;
    }
  }

  isTimeField(field: string): boolean {
    return field === 'user.last_login';
  }

  formatDate(date: string): string {
    const newDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    return newDate.toLocaleDateString('en-US', options).replace(',', '');
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });

      this.setActiveNavItem();
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Users Summary',
        tag: 'users-summary',
        element: undefined
      },
      {
        label: 'Teams Summary',
        tag: 'teams-summary',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      {
        icon: 'ssi-print',
        label: 'Download XLSX',
        func: async () => {
          if (this.loading) {
            console.log('still loading');
            return;
          }
          const config = {
            base64decode: true,
            filename: `Orlo - Team report.xlsx`,
            mimetype: FileMimeTypes.Xlsx
          };
          const xlsx = await this.spreadsheetService.jsonToXlsx(
            (this.report as any).fileExportData
          );
          this.fileDownload.download(xlsx, config);
        }
      },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => {
          this.state.go('auth.analytics.team.build', this.decodedStateParams);
        }
      }
    ];
  }
}
