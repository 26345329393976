import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { sources } from '../../../monitoring-streams/monitoring-streams.service';
import { mapToIterable } from '../../../../../../common/utils';
import { isEqual } from 'lodash-es';

export interface Chip {
  id?: string;
  name: string;
  label: string;
  icon?: string;
  color?: string;
  hideOption?: boolean;
}

const isStringsArray = (arr) => arr.every((i) => typeof i === 'string');

@Component({
  selector: 'ssi-multi-select-chips',
  templateUrl: './multi-select-chips.component.html',
  styles: [],
  styleUrls: ['./multi-select-chips.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectChipsComponent implements OnInit {
  @Input() chipsPlaceholder: string =
    'Select sources you want to gather messages from';
  @Input() filterPlaceholder: string = 'Search to filter results...';
  @Input() selectedChips: Array<Chip> = [];
  @Input() chipsOptions: Array<Chip>;
  @Output() onSelectedChipsChange: EventEmitter<Chip[]> = new EventEmitter<
    Array<Chip>
  >();
  filterTerm = '';

  chipsDropdownOptions = [];
  allSelected = false;
  showOptionsDropdown = false;

  constructor() {}

  ngOnInit() {
    if (this.chipsOptions && Array.isArray(this.chipsOptions)) {
      this.chipsDropdownOptions = this.chipsOptions.filter(
        (source) => !source.hideOption
      );
    } else {
      console.warn('Chips options not in expected format: Array<Chip>');
    }

    this._checkIfAllSelected();
  }

  onFilterTermChange() {
    this.chipsDropdownOptions = this.chipsOptions
      .filter((source) => !source.hideOption)
      .filter(
        (source) =>
          source.label.toLowerCase().indexOf(this.filterTerm.toLowerCase()) > -1
      );
  }

  toggleSelectedChip(chip: any) {
    if (
      this.selectedChips &&
      this.selectedChips.length &&
      this.selectedChips.includes(chip)
    ) {
      this.selectedChips.splice(this.selectedChips.indexOf(chip), 1);
    } else {
      if (this.selectedChips && this.selectedChips.length) {
        this.selectedChips.push(chip);
      } else {
        this.selectedChips = [];
        this.selectedChips.push(chip);
      }
    }

    this._checkIfAllSelected();
    this.onSelectedChipsChange.emit(this.selectedChips);
  }

  toggleSelectAllChips() {
    this._checkIfAllSelected();
    if (this.allSelected) {
      this.selectedChips = [];
      this.allSelected = false;
    } else {
      this.selectedChips = this.chipsOptions
        .filter((source) => !source.hideOption)
        .map((source) => source);
      this.allSelected = true;
    }
    this.onSelectedChipsChange.emit(this.selectedChips);
  }

  private _checkIfAllSelected(): boolean {
    if (!this.selectedChips) {
      return false;
    }
    this.allSelected =
      this.selectedChips.length === this.chipsDropdownOptions.length;
  }
}
