
<div class="landing" [ngClass]="layoutClass">
  <div class="landing-wrap">

    <div class="landing-background-login">
      <img src="/assets/landing/login-background.png">
    </div>

    <div class="landing-background-forgot">
      <img src="/assets/landing/forgot-background.png">
    </div>

    <div class="landing-container">
      <div class="landing-container-col landing-container-col-md landing-container-col-illustration">
        <div class="landing-illustration">
          <img src="/assets/landing/login-illustration.png">
        </div>
      </div>

      <div class="landing-container-col landing-container-col-form">

        <div class="logo">
          <img src="/assets/landing/logo.png">
        </div>

        <div class="logo-sm">
          <img src="/assets/landing/logo-sm.png">
        </div>

        <div class="landing-container-inner">
          <div class="text-center" *ngIf="loadingTracker.active">
            <i class="fa fa-3x fa-spinner fa-spin text-light"></i>
          </div>

          <div *ngIf="!loadingTracker.active">
            <div class="alert alert-success" *ngIf="messages.success">
              {{ messages.success }}
            </div>
            <div class="alert text-danger" *ngIf="messages.error">
              {{ messages.error }}
            </div>
          </div>
        </div>

        <ng-container *ngIf="!loadingTracker.active">
          <ssi-login
            *ngIf="displayForm === 'login'"
            class="login-block"
            [loginLoadingTracker]="loadingTracker"
            (onMessageChange)="messages = $event"
            (onChangeForm)="changeForm($event)"
          ></ssi-login>

          <ssi-forgot-password
            *ngIf="displayForm === 'forgotPassword'"
            (onChangeForm)="changeForm($event)"
          ></ssi-forgot-password>
        </ng-container>
      </div>
    </div>
  </div>
</div>
