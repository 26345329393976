import BigNumber from 'bignumber.js';

const charMap: { [key: string]: string } = {
  "0": "A", "1": "B", "2": "C", "3": "D", "4": "E", "5": "F", "6": "G", "7": "H", "8": "I", "9": "J",
  "a": "K", "b": "L", "c": "M", "d": "N", "e": "O", "f": "P", "g": "Q", "h": "R", "i": "S", "j": "T",
  "k": "U", "l": "V", "m": "W", "n": "X", "o": "Y", "p": "Z", "q": "a", "r": "b", "s": "c", "t": "d",
  "u": "e", "v": "f", "w": "g", "x": "h", "y": "i", "z": "j", "A": "k", "B": "l", "C": "m", "D": "n",
  "E": "o", "F": "p", "G": "q", "H": "r", "I": "s", "J": "t", "K": "u", "L": "v", "M": "w", "N": "x",
  "O": "y", "P": "z", "Q": "0", "R": "1", "S": "2", "T": "3", "U": "4", "V": "5", "W": "6", "X": "7",
  "Y": "8", "Z": "9", "$": "-", "_": "_"
};

export function instagramIdToUrlSegment(id: string): string {
  id = (new BigNumber(id)).toString(64);
  let urlSegment = '';
  for (let j = 0; j < id.length; j++) {
    const char = id[j];
    urlSegment += charMap[char];
  }
  return urlSegment;
};

export function urlSegmentToInstagramId(urlSegment: string): string {
  const reversedCharMap: { [key: string]: string } = {};
  let id = '';

  for (const i in charMap) {
    const o = charMap[i];
    reversedCharMap[o] = i;
  }

  for (let j = 0; j < urlSegment.length; j++) {
    const char = urlSegment[j];
    id += reversedCharMap[char];
  }
  
  id = (new BigNumber(id, 64)).toString(10);
  return id;
};