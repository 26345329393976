<div class="average-response-time-section">
  <div class="section-chart-wrap">
    <div class="top-row">
      <div class="best-hours-container">
        <div class="left">
          <span>{{'WATCH_OUT_FOR' | translate}}:</span>
        </div>
        <div class="middle">
          <div class="hours">
            <span *ngFor="let hour of stats.topHours | slice:0:4; let i = index;">
              {{hour.label + (i === 3 ? ' ' : ', ')}}
            </span>
          </div>
          <div>
            <span>{{'ON_DAYS_SHOWN_BELOW' | translate}}</span>
          </div>
        </div>
        <div class="right">
          <i class="ssi ssi-experience"></i>
        </div>
      </div>
    </div>

    <ssi-highchart [config]="chartConfig"></ssi-highchart>

    <div class="chart-legend">
      <div class="legend-item not-good">
        <span>Response Time:</span>
      </div>
      <div class="legend-item not-good">
        <div class="circle"></div>
        <span>Very Low</span>
      </div>
      <div class="legend-item not-great">
        <div class="circle"></div>
        <span>Low</span>
      </div>
      <div class="legend-item okay">
        <div class="circle"></div>
        <span>Watch out</span>
      </div>
      <div class="legend-item good">
        <div class="circle"></div>
        <span>High</span>
      </div>
      <div class="legend-item great">
        <div class="circle"></div>
        <span>Very High</span>
      </div>
    </div>
  </div>
  
  <div class="art-tile art-tile-large art-tile-banner">
    <div>
      <h2>Reduce your response times</h2>
      <p>{{'QUICK_AS_A_FLASH_OR_SLOW_AS_A_SNAIL' | translate}} {{'AVERAGE_RESPONSE_TIME_BRIEF' | translate}}</p>
    </div>
    <img src="assets/images/person-laptop-conversation.png" alt="person wearing headphones">
  </div>
  
  <div class="section-grid section-grid-halves">
    <div class="section-grid-item">
      <div class="art-tile art-tile-copy">
        <h3>{{'COPY_AND_PASTE_THE_GOOD_STUFF' | translate}}:</h3>
        <p [innerHTML]="copyStats.good.text"></p>
        <span (click)="copyText(true)" class="art-tile-copy-icon">
          <i class="ssi ssi-copy-paste"></i>
        </span>
      </div>
    </div>
    <div class="section-grid-item">
      <div class="art-tile art-tile-copy">
        <h3>{{'COPY_AND_PASTE_THE_NOT_SO_GOOD_STUFF' | translate}}:</h3>
        <p [innerHTML]="copyStats.bad.text"></p>
        <span (click)="copyText(false)" class="art-tile-copy-icon">
          <i class="ssi ssi-copy-paste"></i>
        </span>
      </div>
    </div>
  </div>
</div>