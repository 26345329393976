import { ViewEncapsulation } from '@angular/core';

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { IftttService } from '../../../ifttt-utils/ifttt-service-model/ifttt-service-model';
import { Applet } from '../../../ifttt-utils/applets/applet';

@Component({
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NameComponent implements AfterViewInit {
  @Input() service: IftttService;

  @Input() applet: Applet;

  @ViewChild('nameInput') nameInput: ElementRef;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    });
  }
}
