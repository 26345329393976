import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'ssi-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent implements OnInit {
  @Input() content: string;
  @Input() icon: string;
  @Input() iconColour: string;

  constructor() {}

  async ngOnInit() {}
}
