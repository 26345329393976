import { Outbox, Account, OutboxMessage } from '@ui-resources-angular';
import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import { Stage } from './publisher.component';
import { Draft } from '@orlo/modules/auth/marketing/drafts-library/drafts-library.service';

export const PUBLISHER_ACTIVE = new InjectionToken('publisher active');

export interface PublisherDisableOptions {
  previewPost: boolean;
  changeAccount: boolean;
  changeCampaign: boolean;
  changeTargeting: boolean;
  requestingValidation: boolean;
  scheduling: boolean;
  editMedia: boolean;
  editText: boolean;
  setExpiry: boolean;
}

export interface PublisherActiveParams {
  isActive: boolean;
  stage?: Stage;
  create?: {
    accounts: Account[];
    activeCampaignID?: number;
    text?: string;
    quoteRetweet?: string;
    replyToSocialId?: string;
    copy?: Outbox;
    schedules?: Date[];
    files?: Array<{ url: string; mimetype?: string }>;
    shareId?: string;
  };
  edit?: {
    post: Outbox;
  };
  draft?: Draft;
  // {
  //   account_id: string;
  //   text: string;
  //   attachments?: any[];
  //   campaign_id: string;
  //   message_type: string;
  // }
  published?: boolean;
  disable?: PublisherDisableOptions;
}

export type PublisherActive = Subject<PublisherActiveParams>;

export function publisherActiveFactory() {
  return new Subject<PublisherActiveParams>();
}
