<div class="automated-reports-container">
  <div class="page-head">
    <div class="text">
      <h2>Your Automated Reports</h2>
      <p>
        Stay up to date with all of your data whenever you need it. Schedule and automate your Orlo reports to land directly in your email inbox, as PDFs. 
        Whether you need to share them with others in your team, or with wider stakeholders across your organisation, simply add in the email address(es) you want to receive the reports and let us know when you’d like them to be sent.
      </p>
    </div>
    <img src="assets/images/workstation.png"
         alt="A person sitting at a desk">
  </div>

  <div class="page-body">
    <div class="tabs">
      <div class="analytics"
           [class.active]="activeTab === AutomatedReportType.Analytics"
           (click)="changeTab(AutomatedReportType.Analytics)"><span>Analytics reports</span></div>
      <div class="insights"
           [class.active]="activeTab === AutomatedReportType.Insights"
           (click)="changeTab(AutomatedReportType.Insights)"><span>Insights reports</span></div>
    </div>
    <div class="table-container">
      <ssi-treetable [data]="reportsToShow"
                     [bodyStyle]="{'max-height': ''}"
                     [rowStyle]="{'height': '68px'}"
                     [virtualScroll]="false"
                     [resizeMode]="'fit'"
                     [reorderable]="false"
                     [saveSettings]="true"
                     [settingsStorageKey]="'treetable-automated-reports'"
                     [globalFilterInputRef]=""
                     id="treetable-automated-reports">
        <ssi-column [header]="'NAME'"
                    field="name"
                    width="20%"
                    [templateRefs]="{bodyCell: bodyCellTemplate, headCell: filterHeadCellTemplate}"></ssi-column>
        <ssi-column [header]="'FREQUENCY'"
                    field="frequency"
                    [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>
        <ssi-column [header]="'CREATED BY'"
                    field="createdBy.name"
                    [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>
        <ssi-column [header]="'EMAIL RECIPIENTS'"
                    field="recipients"
                    width="40%"
                    [templateRefs]="{bodyCell: bodyCellTemplate}"></ssi-column>
        <ssi-column header=""
                    [sortable]="false"
                    [field]="' '"
                    [templateRefs]="{bodyCell: actionsCellTemplate}"></ssi-column>

        <ssi-pagination [itemsPerPage]="50"
                        [pageLinksSize]="5"></ssi-pagination>

        <ng-template ssiTemplateSelector="noResults">
          <ssi-no-results *ngIf="!searchTerm"
                          title=""
                          description="No scheduled reports yet"></ssi-no-results>

          <ssi-no-results *ngIf="!!searchTerm"
                          title="No results!"
                          description="How about you try searching through your {{activeTab === AutomatedReportType.Analytics ? 'Insights' : 'Marketing'}} reports?"></ssi-no-results>
        </ng-template>
      </ssi-treetable>
    </div>

    <ng-template #filterHeadCellTemplate
                 ssiTemplateSelector="headCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData">
      <div class="table-cell"
           (mousedown)="$event.stopPropagation()">
        <div class="filter-container">
          <input type="search"
                 class="form-control form-control-minimal filter"
                 [placeholder]="'Search report name...'"
                 [(ngModel)]="searchTerm"
                 (input)="onFilterInput($event)">
          <i class="ssi ssi-search-small"
             [class.active]="searchTerm && reportsToShow.length"></i>
        </div>
      </div>
    </ng-template>

    <ng-template #bodyCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-column="column"
                 let-columnIndex="columnIndex"
                 let-columns="columns"
                 let-columnsMap="columnsMap"
                 let-fieldValue="fieldValue">
      <div class="table-cell body-cell text-cell"
           [class.gray-bg]="rowIndex % 2 === 0">
        <span class="status-dot"
              [class.paused]="rowData.is_paused"
              *ngIf="column.field === 'name'"></span>

        <span class="text"
              title="{{fieldValue}}"
              [class.paused]="rowData.is_paused"
              [class.bold]="column.field === 'name' || column.field === 'frequency'"
              [class.capitalize]="column.field === 'frequency'">{{column.field === 'frequency' ? fieldValue.toLowerCase() : fieldValue}}</span>
      </div>
    </ng-template>


    <ng-template #actionsCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData">
      <div class="table-cell body-cell actions-cell"
           [class.gray-bg]="rowIndex % 2 === 0">
        <button type="button"
                class="g-btn g-btn-secondary bg-white view-btn"
                (click)="viewReport(rowData)"><i class="ssi ssi-view-report"></i><span>View</span></button>
        <button type="button"
                class="g-btn g-btn-secondary bg-white edit-btn"
                (click)="editReport(rowData)"><i class="ssi ssi-correct-edit-small"></i></button>
        <button type="button"
                class="g-btn g-btn-secondary delete-btn delete-btn"
                (click)="deleteReportConfirmMenu.hide(); deleteReportConfirmMenu.show($event, {report: rowData});"><i class="ssi ssi-small-delete"></i> </button>
      </div>
    </ng-template>

    <ssi-context-menu #deleteReportConfirmMenu>
      <ng-template let-ctx>
        <ssi-confirm-action [title]="'Are you sure you want to delete this report?'"
                            dismissText="Cancel"
                            confirmText="Delete"
                            dismissTextColour="#838eab"
                            confirmTextColour="#f88c68"
                            (onDismiss)="deleteReportConfirmMenu.hide();"
                            (onConfirm)="deleteReportConfirmMenu.hide(); deleteReport(ctx.report)"></ssi-confirm-action>
      </ng-template>
    </ssi-context-menu>
  </div>
</div>