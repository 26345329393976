import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  TeamsService,
  Team,
  Colleague
} from '../../../../../../common/services/api';
import { NotesModalComponent } from './notes-modal/notes-modal.component';
import { NotesModelService, Note } from './notes-model/notes-model.service';

@Component({
  selector: 'ssi-validate-posts-notes',
  templateUrl: './validate-posts-notes.component.html',
  styleUrls: ['./validate-posts-notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidatePostsNotesComponent implements OnInit {
  notes: Note[];
  notesModal: any;

  @Input() validation: any;
  @Input() colleagues: Colleague[];
  @Input() teams: Team[];
  @Input() user: Colleague;

  constructor(
    public modal: NgbModal,
    public notesModelService: NotesModelService
  ) {}

  async ngOnInit() {
    this.notes = await this.notesModelService.fetchNotes(
      this.validation.id,
      this.validation.outbox ? 'advert' : 'outbox'
    );
  }

  public openNotes() {
    this.notesModal = this.modal.open(NotesModalComponent, { size: 'lg' });
    this.notesModal.componentInstance.validation = this.validation;
    this.notesModal.componentInstance.notes = this.notes;
    this.notesModal.componentInstance.user = this.user;
    this.notesModal.componentInstance.teams = this.teams;
    this.notesModal.componentInstance.colleagues = this.colleagues;
    this.notesModal.componentInstance.updateNotes = this.updateNotes;
  }

  public updateNotes(updatedNote) {
    const index = this.notes.indexOf(updatedNote);

    if (index > -1) {
      this.notes.splice(index, 1);
    } else {
      this.notes.unshift(updatedNote);
    }
  }
}
