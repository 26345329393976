import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  Input,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import {
  DraftType,
  DraftListItem,
  DraftsLibraryService
} from '../../../modules/auth/marketing/drafts-library/drafts-library.service';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '@orlo/common/components/publisher/publisher-active';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { UserModel } from '@ui-resources-angular';
import { SaveDraftModalComponent } from '../save-draft-modal/save-draft-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DraftComponent {
  @Input() draft: DraftListItem;
  @Input() showDelete = false;
  @Input() missingAccountAccess: boolean;

  @Output()
  onDeleteDraft = new EventEmitter<DraftListItem>();
  @Output()
  onShowDraftNotes = new EventEmitter<DraftListItem>();

  @ViewChild('deleteDraftTooltip') deleteDraftTooltip: TooltipDirective;

  awaitingDeleteConfirmation = false;

  constructor(
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive,
    private draftsLibraryService: DraftsLibraryService,
    private modal: NgbModal,
    private userModel: UserModel
  ) {}

  async triggerPublishDraft(draftId) {
    if (this.missingAccountAccess) {
      return;
    }
    await this.draftsLibraryService.getDraft(draftId).then((draft) => {
      this.publisherActive.next({
        isActive: true,
        draft
      });
    });
  }

  async triggerEditDraft() {
    if (this.missingAccountAccess) {
      return;
    }
    const user = await this.userModel.getAuthUser();
    let newTitle = '';
    const confirmationModal = this.modal.open(SaveDraftModalComponent, {
      windowClass: 'orlo-modal'
    });
    Object.assign(confirmationModal.componentInstance, {
      title: 'Draft Title',
      meta: 'Think of a useful reference for your draft title:',
      userIsAdmin: user.hasCompanyPermission('manage_public_drafts'),
      showPublicDraftOption: false,
      isDraftPublic: this.draft.draft_type === DraftType.Public,
      showSecondaryAction: false,
      primaryActionButtonText: 'Save',
      draftTitle: this.draft.title
    });
    confirmationModal.componentInstance.onDraftTitleChange.subscribe(
      (newDraftTitle: string) => (newTitle = newDraftTitle)
    );
    const result = await confirmationModal.result;
    if (!result) {
      return;
    }

    newTitle = newTitle || 'Untitled Draft';
    const draft = await this.draftsLibraryService.getDraft(this.draft.id);

    try {
      await this.draftsLibraryService.createOrUpdateDraft(
        newTitle,
        draft.draft_type,
        draft.outbox_messages,
        draft.extra,
        draft.id
      );
      this.draft.title = newTitle;
    } catch (e) {
      console.error(e);
      this.draft.title = draft.title;
    }
  }

  showDeleteDraftTooltip() {
    this.awaitingDeleteConfirmation = true;
    this.deleteDraftTooltip.show();
  }

  hideDeleteDraftTooltip() {
    this.awaitingDeleteConfirmation = false;
    this.deleteDraftTooltip.hide();
  }

  async deleteDraft(draft: DraftListItem) {
    this.awaitingDeleteConfirmation = false;
    this.deleteDraftTooltip.hide();
    this.onDeleteDraft.emit(draft);
  }

  showNotes(): void {
    this.onShowDraftNotes.emit(this.draft);
  }
}
