import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { OutboxSearchComponent } from './outbox-search.component';

import { OutboxPostListModule } from '../../../../common/components/outbox-post-list/outbox-post-list.module';
import { OutboxPostListNoResultsModule } from '../../../../common/components/outbox-post-list/outbox-post-list-no-results/outbox-post-list-no-results.module';
import { FiltersModule } from '../../../../common/components/filters';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
// import { BigNumberModule } from '../../../../common/pipes/big-number/big-number.module';
import { BigNumberModule } from '../../../../common/components/big-number/big-number.module';
import { TruncateModule } from '../../../../common/pipes/truncate/truncate.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.outboxSearch',
          url: '/outbox-search?query&outbox_id&validate&active_campaign_id',
          component: OutboxSearchComponent,
          resolve: OutboxSearchComponent.resolve,
          reloadOnSearch: false,
          params: {
            query: {
              dynamic: true,
              // value: null,
              // squash: false,  // Prevent parameter encoding?
              // type: 'string'
            },
            outbox_id: {
              dynamic: true
            },
            validate: {
              dynamic: true
            },
            active_campaign_id: {
              dynamic: true
            }
          }
        }
      ]
    }),
    OutboxPostListModule,
    OutboxPostListNoResultsModule,
    FiltersModule,
    TooltipModule,
    BigNumberModule,
    TruncateModule
  ],
  declarations: [OutboxSearchComponent],
  entryComponents: [OutboxSearchComponent]
})
export class OutboxSearchModule {}
