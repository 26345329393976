import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

import { Outbox, OutboxPublisherMention } from '@ui-resources-angular';

@Component({
  selector: 'ssi-outbox-post',
  templateUrl: './outbox-post.component.html',
  styles: [],
  styleUrls: ['./outbox-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutboxPostComponent implements OnInit {
  @Input() post: Outbox;

  mentions: OutboxPublisherMention[] = [];
  createdAtDate: string;
  createdAtTime: string;

  constructor() {}

  ngOnInit() {
    this.mentions = this.post.getMentions();

    const postedAt = this.post.sent_at || this.post.created_at;
    this.createdAtDate = moment(postedAt).format('DD MMM YYYY');
    this.createdAtTime = moment(postedAt).format('HH:mm');
  }
}
