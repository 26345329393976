import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-cl-menu',
  templateUrl: './cl-menu.component.html',
  styles: [],
  styleUrls: ['./cl-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
