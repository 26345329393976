import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ssi-no-results',
  templateUrl: './no-results.component.html',
  styles: [],
  styleUrls: ['./no-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoResultsComponent implements OnInit {
  @Input() icon: string | 'spinner' = 'ssi-no-results';
  @Input() title = 'No results!';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() description = '';

  @ContentChild(TemplateRef) contentTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
