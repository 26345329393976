import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { API, Account, AccountModel } from '@orlo/library/ui-resources/modules/exports';
import { StateService } from '@uirouter/angular';
import { workflowAccounts } from '../../../../../../common-resolves';

import { PopupService } from '../../../../../../../common/services/popup/popup.service';
import { customerSupportWidgetNamespace } from '@orlo/library/constants/live-chat';
import {
  competitorsFn,
  ProfileGroup,
  competitorsConfigFn,
  CompetitorsConfigResponse,
  ProfileGroupType
} from '../../../competitor-analysis.component';

@Component({
  selector: 'ssi-competitor-list',
  templateUrl: './competitor-list.component.html',
  styles: [],
  styleUrls: ['./competitor-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompetitorListComponent implements OnInit {
  static resolve = [
    {
      token: 'profileGroups',
      resolveFn: competitorsFn,
      deps: [API]
    },
    {
      token: 'competitorsConfig',
      resolveFn: competitorsConfigFn,
      deps: [API]
    }
  ];

  @Input() profileGroups: ProfileGroup[];
  @Input() workflowAccounts: Account[];
  @Input() competitorsConfig: CompetitorsConfigResponse;
  remainingSlots: { competitor: number; brand: number };
  templateView: string = 'competitors';
  filterQuery: string;

  constructor(
    private api: API,
    private state: StateService,
    private popup: PopupService,
    private location: Location,
    public accountModel: AccountModel
  ) {}

  async ngOnInit() {
    // manually setting the first-found avatar to prevent multiple checks in template
    this.profileGroups = this.profileGroups.map(
      (profileGroup: ProfileGroup) => {
        const profileWithAvatar = profileGroup.profiles.find(
          (acc: any) => acc.avatar_url
        );
        profileGroup.displayName = profileGroup.name;
        const firstPickedAvatar =
          (profileWithAvatar && profileWithAvatar.avatar_url) || null;
        return {
          ...profileGroup,
          firstPickedAvatar
        };
      }
    );
    this.remainingSlots = {
      competitor:
        this.competitorsConfig.max_competitors -
        this.profileGroups.filter(
          (group) => group.type === ProfileGroupType.Competitor
        ).length,
      brand:
        this.competitorsConfig.max_brands -
        this.profileGroups.filter(
          (group) => group.type === ProfileGroupType.Brand
        ).length
    };
  }

  editProfileGroup(selectedProfileGroup: ProfileGroup) {
    if (selectedProfileGroup.type === ProfileGroupType.Competitor) {
      this.state.go('^.competitorEdit', {
        id: selectedProfileGroup && selectedProfileGroup.id
      });
    } else {
      this.state.go('^.brandEdit', {
        id: selectedProfileGroup && selectedProfileGroup.id
      });
    }
  }

  createReport() {
    this.state.go('^.reportCreate');
  }

  async deleteProfileGroup(selectedProfileGroup: ProfileGroup) {
    const shouldDelete = await this.popup.confirm({
      title: 'Delete for all eternity?',
      message: `All collected data for this ${
        selectedProfileGroup.type === 'COMPETITOR' ? 'Competitor' : 'Brand'
      } will be deleted permanently.`,
      okText: 'Delete please',
      windowClass: 'modal-rounded-corners',
      hideClose: true,
      iconClass: 'ssi ssi-bin',
      backdrop: true
    });
    if (shouldDelete) {
      this.api
        .delete(
          `competitiveAnalysis/profileGroup?id=${selectedProfileGroup.id}`
        )
        .then((res) => {
          if (res.status === 200) {
            this.profileGroups = this.profileGroups.filter(
              (profileGroup: ProfileGroup) =>
                selectedProfileGroup.id !== profileGroup.id
            );
            this.remainingSlots[selectedProfileGroup.type.toLowerCase()]++;
          }
        });
    }
  }

  toggleLiveChat() {
    window.postMessage(
      { id: `${customerSupportWidgetNamespace}-widget-toggle` },
      '*'
    );
  }

  backToReports() {
    this.state.go('auth.analytics.competitorAnalysis.index');
  }
}
