import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { Campaign, Account } from '@ui-resources-angular';
import { OutboxQueryFactoryService } from '../../../../../common/services/outbox-query-factory/outbox-query-factory.service';
import { AdvertModelService } from '../../advertising/advert-model/advert-model.service';
import { Colleague } from '../../../../../common/services/api';

export interface ValidationFilters {
  account: any[];
  users: any[];
  campaign: any[];
  overdueScheduledPost: boolean;
}

@Component({
  selector: 'ssi-validate-posts-filters',
  templateUrl: './validate-posts-filters.component.html',
  styles: [],
  styleUrls: ['./validate-posts-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidateFiltersComponent implements OnInit {
  @Input() workflowAccounts: Account[];
  @Input() campaigns: Campaign[];
  @Input() colleagues: Colleague[];
  @Input() authUser: any;
  @Input() validations: any;
  @Input() isLoading: boolean;
  @Input() postPerPage: number;
  @Output() validationsChange = new EventEmitter();
  @Output() isLoadingChange = new EventEmitter();
  @Output() updatePagination = new EventEmitter();

  activeColleagues: Colleague[];
  allAccounts: any;
  allCampaigns: any;
  showFilters:
    | 'parent'
    | 'account'
    | 'users'
    | 'campaign'
    | 'overdueScheduledPost';
  filters: ValidationFilters;
  activeFilters: ValidationFilters;

  constructor(
    private transition: Transition,
    private advertModel: AdvertModelService,
    private outboxQueryFactory: OutboxQueryFactoryService
  ) {}

  ngOnInit() {
    this.showFilters = 'parent';
    this.filters = {
      account: this.workflowAccounts.map((account) => account.id),
      users: [],
      campaign: [],
      overdueScheduledPost: false
    };
    this.activeFilters = {
      account: this.workflowAccounts.map((account) => account.id),
      users: [],
      campaign: [],
      overdueScheduledPost: false
    };
    this.getFilterList();
  }

  getAdvertParams() {
    const params = { ...this.transition.params() };

    if (!params.id && !params.outbox_id && !params.account_id) {
      params.account_ids = this.workflowAccounts.map((account) => account.id);
    }
    params['requires_validation'] = 'exclusive';
    if (this.filters.account) {
      params['account_ids'] = this.filters.account;
    }
    if (this.filters.users.length) {
      params['user_ids'] = [this.filters.users];
    }
    if (this.filters.campaign && this.filters.campaign.length) {
      params['campaign_ids'] = [this.filters.campaign];
    }
    if (this.filters.overdueScheduledPost) {
      params['overdue'] = 'exclusive';
    }
    this.advertModel.ejectAll();
    return params;
  }

  getPostQuery() {
    const outboxQuery = this.outboxQueryFactory.create({
      allAccounts: this.workflowAccounts,
      campaigns: this.campaigns,
      authUser: this.authUser
    });

    outboxQuery.reset();
    outboxQuery.params.validated = '0';
    outboxQuery.isValidationSearch = true;
    if (this.filters.account.length) {
      outboxQuery.params.accounts = this.filters.account;
    }
    if (this.filters.users && this.filters.users.length) {
      outboxQuery.params.created_by = 'user:' + this.filters.users;
    }
    if (this.filters.campaign && this.filters.campaign.length) {
      outboxQuery.params.campaign_id = this.filters.campaign;
    }
    if (this.filters.overdueScheduledPost) {
      outboxQuery.params.overdue = 'exclusive';
    }
    outboxQuery.params.deleted = 'exclude';
    outboxQuery.filters = outboxQuery.filters.filter(
      (filter) => !['validated', 'created_by', 'deleted'].includes(filter.key)
    );
    return outboxQuery;
  }

  public async updateValidations(paginationNumber) {
    const offset = paginationNumber * this.postPerPage - this.postPerPage;
    if (this.filters.account.length === 0) {
      return;
    }
    const advertParams = this.getAdvertParams();
    const outboxQuery = this.getPostQuery();

    const adverts = await this.advertModel.findAll(advertParams, {
      bypassCache: true
    });
    await outboxQuery.search(offset, this.postPerPage);

    this.validations = [];
    this.validations.push(...outboxQuery.outboxPosts);
    this.validations.push(...adverts);
    this.validationsChange.emit(this.validations);

    if (paginationNumber === 1) {
      this.updatePagination.emit(outboxQuery.interactionTotals.total);
    }

    this.isLoading = false;
    this.isLoadingChange.emit(this.isLoading);

    this.showFilters = 'parent';
    this.activeFilters = this.filters;
  }

  public getFilterValues(e, filterType) {
    if (e.target.checked) {
      this.filters[filterType].push(e.target.value);
    } else {
      this.filters[filterType] = this.filters[filterType].filter(
        (value) => (value === e.target.value ? false : true)
      );
    }
  }

  public changeFilters(filterSet) {
    this.showFilters = filterSet;
    this.getFilterList();
  }

  public getFilterList() {
    this.activeColleagues = this.colleagues.filter(
      (colleague) => colleague.is_active
    );
    this.allCampaigns = this.campaigns.sort(
      (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
    );
    this.allAccounts = this.workflowAccounts;
  }

  toggleOptionRadioValue(event, filterSet) {
    if (event.target.id === this.filters[filterSet]) {
      this.filters[filterSet] = [];
    }
  }

  filterByText(event, filterList) {
    this.getFilterList();
    const inputValue = event.target.value;
    const regex = new RegExp(inputValue, 'gi');
    this[filterList] = this[filterList].filter((item) => {
      switch (filterList) {
        case 'allAccounts':
          return item.displayName.match(regex) && item;
          break;
        case 'activeColleagues':
          return item.fullName.match(regex) && item;
          break;
        case 'allCampaigns':
          return item.name.match(regex) && item;
          break;
        default:
          return;
      }
    });
  }

  toggleFilterBool(filterSet) {
    this.filters[filterSet] = !this.filters[filterSet];
    this.activeFilters = this.filters;
  }

  toggleSelectAll(action: 'select' | 'deselect', filterSet) {
    if (action === 'select') {
      if (filterSet === 'account') {
        this.filters[filterSet] = this.workflowAccounts.map(
          (account) => account.id
        );
      }
    }
    if (action === 'deselect') {
      this.filters[filterSet] = [];
    }
    this.activeFilters = this.filters;
  }
}
