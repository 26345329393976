/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

const DEFAULT_API_ENDPOINT = 'https://app.socialsignin.co.uk';

// web sockets: update in config.json also
// const DEFAULT_SOCKET_ENDPOINT = 'localhost:7010'; //when testing locally
// const DEFAULT_SOCKET_ENDPOINT = '20.90.114.58'; // Azure test instance
// const DEFAULT_SOCKET_ENDPOINT = '40.120.38.235'; // Test instance
// const DEFAULT_SOCKET_ENDPOINT = 'test-websocket.socialsignin.net'; // Test instance
const DEFAULT_SOCKET_ENDPOINT = 'https://ws.socialsignin.net'; // default one - production

// export enum EnvironmentType {
//   Test = 'test',
//   Development = 'development',
//   Production = 'production'
// }

const appConfig = {
  apiEndpoint: DEFAULT_API_ENDPOINT,
  socketEndpoint: DEFAULT_SOCKET_ENDPOINT
};

// if (process.env.SSI_ENV === EnvironmentType.Development) {
//   try {
//     const devConfig = require('../../config.json'); // tslint:disable-line
//     Object.assign(appConfig, devConfig);
//   } catch (e) {}
// }

//if (typeof process.env.API_ENDPOINT !== 'undefined') {
// appConfig.apiEndpoint = process.env.API_ENDPOINT;
//}

//if (typeof process.env.SOCKET_ENDPOINT !== 'undefined') {
// appConfig.socketEndpoint = process.env.SOCKET_ENDPOINT;
//}

export const API_ENDPOINT: string = appConfig.apiEndpoint;
export const SOCKET_ENDPOINT: string = appConfig.socketEndpoint;

// export const APP_VERSION: string = process.env.APP_VERSION;

// export const ENVIRONMENT: EnvironmentType = process.env
//   .SSI_ENV as EnvironmentType;

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiEndpoint: API_ENDPOINT,
  socketEndpoint: SOCKET_ENDPOINT,
  appVersion: require('../../package.json').version,
  credentials: {
    firebase: {
      apiKey: 'AIzaSyDRL-3LGbi-MkwIv7hMAvQbWlWmRj5Zzt4',
      authDomain: 'production-orlo-live-chat-uk.firebaseapp.com',
      databaseURL: 'https://production-orlo-live-chat-uk.firebaseio.com/',
      messagingSenderId: '467823975873',
      projectId: 'production-orlo-live-chat-uk',
      storageBucket: 'production-orlo-live-chat-uk.appspot.com'
    },
    messaging: {
      vapid: `BDYbAMmKI0oPEywWMSZw1yKC7YfKYIloyOY20hiSMvbBZ262LICNroD2wODBzHqyhUV2OI5_tdykzwVnm4jThCI`
    }
  },
  routes: {
    token:
      'https://europe-west1-production-orlo-live-chat-uk.cloudfunctions.net/widget-token'
  }
};
