import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LiveChatNetworkStateModule } from '../live-chat-network-state/live-chat-network-state.module';
import { LiveChatConversationSidebarComponent } from './live-chat-conversation-sidebar.component';
import { MapModule } from '../../../../common/components/map/map.module';

@NgModule({
  declarations: [LiveChatConversationSidebarComponent],

  exports: [LiveChatConversationSidebarComponent],

  imports: [
    CommonModule,
    LiveChatNetworkStateModule,
    TranslateModule,
    MapModule
  ]
})
export class LiveChatConversationSidebarModule {}
