import { appInjector } from '@orlo/app-injector';
import { Colleague, ColleaguesService } from '../colleagues';

export enum ReportAutomationFrequency {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum AutomatedReportType {
  Insights = 'insights',
  Analytics = 'analytics'
}

export class AutomatedReport {
  id?: string; // not present when creating new report
  name: string;
  url: string;
  frequency: ReportAutomationFrequency;
  recipients: string[];
  is_paused: boolean;
  created_by?: number; // not required when creating new report

  constructor(props: any = {}) {
    this.created_by = props.created_by;
    this.id = props.id;
    this.name = props.name;
    this.url = props.url;
    this.frequency = props.frequency;
    this.recipients = props.recipients;
    this.url = props.url;
    this.is_paused = props.is_paused;
    this.created_by = props.created_by;
  }

  isInsightsReport(): boolean {
    return this.url.includes('/insights');
  }

  isAnalyticsReport(): boolean {
    return this.url.includes('/analytics');
  }

  isMarketingReport(): boolean {
    return this.url.includes('/marketing');
  }

  get createdBy(): Colleague {
    if (!this.created_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.created_by);
  }
}
