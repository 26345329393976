<div class="outbox-campaign-modal">
  <div class="outbox-campaign-modal-header">
    <div class="outbox-campaign-modal-header-left">
      <h2>{{post.campaign_id ? 'Change Campaign' : 'Add Campaign'}}</h2>
    </div>
    <div class="outbox-campaign-modal-header-right">
      <button (click)="modalInstance.close(post.campaign_id)"
              class="outbox-campaign-modal-close">
        <i class="ssi ssi-addsmall"></i>
      </button>
    </div>
  </div>

  <div class="outbox-campaign-modal-body">
    <div class="outbox-campaign-modal-body-row">
      <label class="outbox-campaign-modal-label"
             for="campaignSelect">
        Choose a campaign
      </label>
      <div class="outbox-campaign-modal-body-row-input">
        <ssi-menu [headAlignment]="'top left'"
                  [bodyAlignment]="'top left'">
          <ng-template ssiTemplateSelector="menuHead">
            <button type="button"
                    class="campaigns-list-toggle-button">
              <span>{{selectedCampaign ? selectedCampaign.name : 'Select a Campaign'}}</span>
              <i class="ssi ssi-arrow-down-new"></i>
            </button>
          </ng-template>

          <ng-template ssiTemplateSelector="menuBody">
            <div class="list-select-container">
              <ssi-list-select2 class="campaign-list-select"
                                [multiple]="false"
                                [filterable]="true"
                                [filterPlaceholder]="'Search campaigns...'"
                                [filterFn]="campaignsFilterFn"
                                [toggleAllLabel]="'Select all'"
                                [toggleAllEnabled]="false"
                                [options]="filteredParents"
                                [(ngModel)]="selectedCampaign"
                                (ngModelChange)="onModelValueChange($event);">

                <ng-template ssiTemplateSelector="optionIcon"
                             let-parentCampaign="option"
                             let-selected="selected">
                  <i *ngIf="parentCampaign._children"
                     class="ssi ssi-arrow-down-new chevron expand-campaign-icon"
                     [class.down]="parentCampaign._children.expanded"
                     (click)="$event.stopPropagation(); expandCollapseCampaign(parentCampaign)"></i>
                </ng-template>

                <ng-template ssiTemplateSelector="expandedContent"
                             let-parentCampaign="option"
                             let-selected="selected">
                  <ssi-list-select2 *ngIf="parentCampaign._children && parentCampaign._children.expanded"
                                    class="campaign-children-list-select"
                                    [multiple]="false"
                                    [filterable]="true"
                                    [filterPlaceholder]="'Search child campaigns...'"
                                    [toggleAllLabel]="'Select all'"
                                    [toggleAllEnabled]="true"
                                    [options]="parentCampaign._children.filteredOptions"
                                    [(ngModel)]="selectedCampaign"
                                    (ngModelChange)="onChildModelValueChange($event, parentCampaign);">

                    <ng-template ssiTemplateSelector="optionIcon">
                      <!-- Just so it keeps space filled -->
                    </ng-template>
                  </ssi-list-select2>
                </ng-template>

              </ssi-list-select2>
            </div>
          </ng-template>
        </ssi-menu>
      </div>
    </div>
  </div>

  <div class="outbox-campaign-modal-footer">
    <div class="outbox-campaign-modal-footer-left">
      <button type="button"
              *ngIf="post.campaign_id"
              class="g-btn g-btn-lg g-btn-secondary bg-white remove-btn"
              (click)="selectedCampaign = undefined; changeCampaign()">Remove Campaign</button>
    </div>
    <div class="outbox-campaign-modal-footer-right">
      <button type="button"
              class="g-btn g-btn-lg apply-btn"
              (click)="changeCampaign()">{{post.campaign_id ? 'Change Campaign': 'Add Campaign'}}</button>
    </div>
  </div>
</div>