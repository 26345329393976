<ssi-report-header
    report="Benchmarking"
    [name]="reportName"
    [reportStartDate]="reportStartDate"
    [reportEndDate]="reportEndDate"
    [supportsKeyword]="true"
    (dateSelected)="reportDateSelected($event)"
    (onKeywordSubmit)="reportKeywordInput($event)"
>
</ssi-report-header>
<ssi-report-sidebar
    icon="ssi-competitor-analysis"
    color="#435DEC"
    [navList]="navItems"
    [actionList]="reportActions"
    [active]="activeNavItemTag"
    (navClicked)="scrollTo($event)"
>
</ssi-report-sidebar>
<div class="report-container">
    <div class="report-body-loading" *ngIf="loading">
        <div class="loading-container">
        <!-- <i class="ssi ssi-confetti"></i> -->
        <img src="assets/images/line-chart.gif">
        <h3>We're building your report!</h3>
        <p>This may take a few moments
        </p>
        </div>
    </div>
    <div class="report-body" *ngIf="!competitorStats[0]?.profiles[0].name">
        <div class="no-results">
            <i class="ssi ssi-semi-negative-new"></i>
            <h4>Something went wrong</h4>
            <p>Sorry this report has no results to display,<br/> you may have to change the date or report back later</p>
        </div>
    </div>
    <div class="report-body" *ngIf="!loading && competitorStats[0]?.profiles[0].name">
        <section class="section-container" id="overview">
            <div class="section-content">
                <div class="guidance">
                    <h4>Who's top of the leaderboard?</h4>
                    <p>Below you'll see how your chosen brands or competitors stack up when it comes to key metrics such as average engagement, top posts and audience growth. Who's going for gold and who's bringing up the rear?</p>
                </div>
                <div class="profile-comparisons" *ngIf="competitorStats.length > 1">
                    <div *ngFor="let competitor of competitorStats; let i = index;" class="profile" [class.focus]="competitor.id === focusId">
                        <div class="rank" id="rank-{{i}}">
                            <div>
                                {{i + 1}}
                            </div>
                            <i class="ssi ssi-social-score"></i>
                        </div>
                        <div class="profile-score">
                            <span class="number">{{competitor.socialScore}}</span>
                            <span class="name">{{competitor.name}}</span>
                        </div>
                        <div class="profile-color" [style.background]="competitor.colour"></div>
                    </div>
                </div>
            </div>
        </section>
        <hr />
        <section class="section-container" id="post-activity">
            <div class="section-heading">
                <h3>Post Activity</h3>
                <ul class="network-filter">
                    <li (click)="changeOverviewNetwork('total')" [class.active]="overviewNetwork === 'total'">All Networks</li>
                    <li (click)="changeOverviewNetwork('3')" [class.active]="overviewNetwork === '3'">Facebook</li>
                    <li (click)="changeOverviewNetwork('2')" [class.active]="overviewNetwork === '2'">X</li>
                    <li (click)="changeOverviewNetwork('12')" [class.active]="overviewNetwork === '12'">Instagram</li>
                </ul>
            </div>
            <div class="no-results" *ngIf="!checkNetwork(overviewNetwork)">
                <i class="ssi ssi-semi-negative-new"></i>
                <h4>Something went wrong</h4>
                <p>Sorry this network has no results to display,<br/> you may have to change the date or report back later</p>
            </div>
            <div class="section-content section-content-row" *ngIf="checkNetwork(overviewNetwork) && competitorStats.length === 1">
                <ng-container *ngFor="let competitor of competitorStats">
                    <div class="stat-list">
                        <div class="stat">
                            <div>
                                <p class="stat-title">Average Engagement </p>
                                <i
                                    class="ssi ssi-help"
                                    [ssiTooltip]="'This is the average number of interactions per post. Examples of interactions are shares, comments, likes and reactions'"
                                    tooltipPlacement="top"
                                    [tooltipWindowClass]="'dark rounded'"
                                ></i>
                            </div>
                            <div>
                                <span><ssi-big-number [number]="statsModel[competitor.id]['overview'][overviewNetwork]['avgInteractions']"></ssi-big-number></span>
                                <i class="ssi ssi-drive-microcopy stat-icon"></i>
                            </div>
                        </div>
                        <div class="stat">
                            <div>
                                <p class="stat-title">Average Daily Posts</p>
                                <i
                                    class="ssi ssi-help"
                                    [ssiTooltip]="'This is the average number of posts per day'"
                                    tooltipPlacement="top"
                                    [tooltipWindowClass]="'dark rounded'"
                                ></i>
                            </div>
                            <div>
                                <span><ssi-big-number [number]="statsModel[competitor.id]['overview'][overviewNetwork]['postFreq']"></ssi-big-number></span>
                                <ssi-icon icon="send" height="36" width="36" color="#838eab"></ssi-icon>
                            </div>
                        </div>
                        <div class="stat">
                            <div>
                                <p class="stat-title">Engagement Rate</p>
                                <i
                                    class="ssi ssi-help"
                                    ssiTooltip="This is the average number of interactions per post divided by number of the competitor’s followers multiplied by 100. We use the number of followers on the last day of the selected date range. Engagements may include Reactions, likes, clicks, comments and shares, depending on the network"
                                    tooltipPlacement="top"
                                    [tooltipWindowClass]="'dark rounded'"
                                ></i>
                            </div>
                            <div>
                                <span><ssi-big-number [number]="statsModel[competitor.id]['overview'][overviewNetwork]['interactionRate']" [maxFractionDigitsHover]="6"></ssi-big-number>%</span>
                                <i class="ssi ssi-engagement-rate stat-icon"></i>
                            </div>
                        </div>
                    </div>
                    <ssi-stat-graph
                        type="large"
                        [totalNumber]="statsModel[competitor.id]['overview'][overviewNetwork]['countMessages']"
                        [activeNetwork]="overviewNetwork"
                        [pieChartConfig]="statsModel[competitor.id]['mediaTypeGraphs'][overviewNetwork]"
                    ></ssi-stat-graph>
                </ng-container>
            </div>

            <div class="section-content" *ngIf="checkNetwork(overviewNetwork) && competitorStats.length > 1">
                <div class="stat-list stat-list-row">
                    <div class="stat-group" (click)="overviewKey = 'avgInteractions'">
                        <div class="stat-group-title">
                            <p>Average Engagement</p>
                            <i class="ssi ssi-help" [ssiTooltip]="'This is the average number of interactions per post. Examples of interactions are shares, comments, likes and reactions'"></i>
                        </div>
                        <div class="stat-group-stats" [ngClass]="{'active': overviewKey === 'avgInteractions'}">
                            <div class="stat-group-stats-selected"><i class="ssi ssi-tick"></i></div>
                            <i class="ssi ssi-average-engagement stat-icon" [ngClass]="{'active': overviewKey === 'avgInteractions'}"></i>
                            <ng-container *ngFor="let competitor of competitorStats">
                                <div *ngIf="statsModel[competitor.id]['overview'][overviewNetwork]" class="stat-competitor">
                                    <span>{{competitor.name}}</span>
                                    <div [style.background]="competitor.colour" class="stat-competitor-color"></div>
                                    <ssi-big-number [number]="statsModel[competitor.id]['overview'][overviewNetwork]['avgInteractions']"></ssi-big-number>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="stat-group" (click)="overviewKey = 'postFreq'">
                        <div class="stat-group-title">
                            <p>Average Daily Posts</p>
                            <i class="ssi ssi-help" [ssiTooltip]="'This is the average number of posts per day'"></i>
                        </div>
                        <div class="stat-group-stats" [ngClass]="{'active': overviewKey === 'postFreq'}">
                            <div class="stat-group-stats-selected"><i class="ssi ssi-tick"></i></div>
                            <ssi-icon icon="send" height="36" width="36" color="#838eab"></ssi-icon>
                            <ng-container *ngFor="let competitor of competitorStats">
                                <div *ngIf="statsModel[competitor.id]['overview'][overviewNetwork]" class="stat-competitor">
                                    <span>{{competitor.name}}</span>
                                    <div [style.background]="competitor.colour" class="stat-competitor-color"></div>
                                    <ssi-big-number [number]="statsModel[competitor.id]['overview'][overviewNetwork]['postFreq']"></ssi-big-number>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="stat-group" (click)="overviewKey = 'interactionRate'">
                        <div class="stat-group-title">
                            <p>Engagement Rate</p>
                            <i
                              class="ssi ssi-help"
                              ssiTooltip="This is the average number of interactions per post divided by number of the competitor’s followers multiplied by 100. We use the number of followers on the last day of the selected date range. Engagements may include Reactions, likes, clicks, comments and shares, depending on the network">
                            </i>
                        </div>
                        <div class="stat-group-stats" [ngClass]="{'active': overviewKey === 'interactionRate'}">
                            <div class="stat-group-stats-selected"><i class="ssi ssi-tick"></i></div>
                            <i class="ssi ssi-engagement-rate stat-icon"></i>
                            <ng-container *ngFor="let competitor of competitorStats">
                                <div *ngIf="statsModel[competitor.id]['overview'][overviewNetwork]" class="stat-competitor">
                                    <span>{{competitor.name}}</span>
                                    <div [style.background]="competitor.colour" class="stat-competitor-color"></div>
                                    <ssi-big-number [number]="statsModel[competitor.id]['overview'][overviewNetwork]['interactionRate']" [maxFractionDigitsHover]="6"></ssi-big-number>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div class="stat-comparisons">
                        <ul class="stat-comparisons-networks">
                            <li *ngIf="['total', '2'].includes(overviewNetwork)">
                                <span class="ssi ssi-x-logo"></span>
                            </li>
                            <li *ngIf="['total', '3'].includes(overviewNetwork)">
                                <span class="fa fa-facebook-f"></span>
                            </li>
                            <li *ngIf="['total', '12'].includes(overviewNetwork)">
                                <span class="fa fa-instagram"></span>
                            </li>
                        </ul>
                        <div class="stat-comparisons-title">{{postActivityTitle(overviewKey)}}</div>
                        <ng-container *ngFor="let competitor of competitorStats">
                            <div class="count-bar" *ngIf="statsModel[competitor.id]['overview'][overviewNetwork]">
                                <div class="progress-bar-wrapper">
                                    <div><span>{{competitor.name}}</span></div>
                                    <ssi-progress-bar
                                        [max]="'100'"
                                        [progress]="statsModel[competitor.id]['overview'][overviewNetwork][overviewKey]"
                                        [color]="competitor.colour"
                                        [thickness]="4">
                                    </ssi-progress-bar>
                                </div>
                                <div class="count">
                                    <ssi-big-number [number]="statsModel[competitor.id]['overview'][overviewNetwork][overviewKey]"></ssi-big-number>
                                </div>
                            </div>
                        </ng-container>
                        <div class="stat-comparisons-average">
                            <i class="ssi ssi-inbound-messages-stat"></i>
                            <ssi-big-number [number]="(totalAverages[overviewKey])"></ssi-big-number>
                            <span>Competitor Average</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <hr />
        <section class="section-container" id="media-types" *ngIf="competitorStats.length > 1">
            <div class="section-heading">
                <h3>Media Types</h3>
                <ul class="network-filter">
                    <li (click)="mediaTypeNetwork = 'total'" [class.active]="mediaTypeNetwork === 'total'">All Networks</li>
                    <li (click)="mediaTypeNetwork = '3'" [class.active]="mediaTypeNetwork === '3'">Facebook</li>
                    <li (click)="mediaTypeNetwork = '2'" [class.active]="mediaTypeNetwork === '2'">X</li>
                    <li (click)="mediaTypeNetwork = '12'" [class.active]="mediaTypeNetwork === '12'">Instagram</li>
                </ul>
            </div>
            <div class="no-results" *ngIf="!checkNetwork(mediaTypeNetwork)">
                <i class="ssi ssi-semi-negative-new"></i>
                <h4>Something went wrong</h4>
                <p>Sorry this network has no results to display,<br/> you may have to change the date or report back later</p>
            </div>
            <div class="section-content">
                <div class="media-types"  *ngIf="checkNetwork(mediaTypeNetwork)">
                    <div class="media-types-box competitors-{{competitorStats.length}}" *ngFor="let competitor of competitorStats">
                        <ng-container *ngIf="statsModel[competitor.id]['overview'][mediaTypeNetwork]">
                            <ssi-medal-score
                                *ngIf="isTopPlace('countMessages',competitor.id,audienceCountNetwork, true)"
                                [place]="1"
                                color="#F0B427"
                                variant="big"
                            ></ssi-medal-score>
                            <ssi-stat-graph
                                [type]="competitorStats.length > 2 ? 'large-thin' : 'large'"
                                [competitorName]="competitor.name"
                                [competitorColour]="competitor.colour"
                                [totalNumber]="statsModel[competitor.id]['overview'][mediaTypeNetwork]['countMessages']"
                                [activeNetwork]="mediaTypeNetwork"
                                [pieChartConfig]="statsModel[competitor.id]['mediaTypeGraphs'][mediaTypeNetwork]"
                            ></ssi-stat-graph>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>
        <hr />
        <section class="section-container" id ="top-posts">
            <ng-container *ngFor="let competitor of competitorStats; let i = index">
                <div class="section-heading">
                    <h3 *ngIf="statsModel[competitor.id].topPosts[topPostNetwork]">Top Posts {{competitorStats.length === 1 ? '' : competitor.name}}</h3>
                    <ul class="network-filter" *ngIf="i === 0">
                        <li (click)="topPostNetwork = '3'" [class.active]="topPostNetwork === '3'">Facebook</li>
                        <li (click)="topPostNetwork = '2'" [class.active]="topPostNetwork === '2'">X</li>
                        <li (click)="topPostNetwork = '12'" [class.active]="topPostNetwork === '12'">Instagram</li>
                    </ul>
                </div>
                <div class="no-results" *ngIf="!checkNetwork(topPostNetwork)">
                    <i class="ssi ssi-semi-negative-new"></i>
                    <h4>Something went wrong</h4>
                    <p>Sorry this network has no results to display,<br/> you may have to change the date or report back later</p>
                </div>
                <div class="section-content" *ngIf="checkNetwork(topPostNetwork)">
                    <div>
                        <p class="no-results" *ngIf="statsModel[competitor.id].topPosts[topPostNetwork]?.length === 0">Our report is still gathering data, check back soon</p>
                        <div class="top-posts">
                            <div class="top-post" *ngFor="let topPost of statsModel[competitor.id].topPosts[topPostNetwork]; let i = index">
                                <ssi-top-post-stats [topPost]="topPost" [top]="i === 0" [index]="i"></ssi-top-post-stats>
                                <ssi-top-post-view [topPost]="topPost"></ssi-top-post-view>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </section>
        <hr/>
        <section class="section-container" id="post-breakdown" *ngIf="competitorStats.length === 1">
            <div class="section-heading">
                <h3>Post Breakdown</h3>
                <ul class="network-filter">
                    <li (click)="postBreakdownNetwork = 'total'" [class.active]="postBreakdownNetwork === 'total'">All Networks</li>
                    <li (click)="postBreakdownNetwork = '3'" [class.active]="postBreakdownNetwork === '3'">Facebook</li>
                    <li (click)="postBreakdownNetwork = '2'" [class.active]="postBreakdownNetwork === '2'">X</li>
                    <li (click)="postBreakdownNetwork = '12'" [class.active]="postBreakdownNetwork === '12'">Instagram</li>
                </ul>
            </div>
            <div class="no-results" *ngIf="!checkNetwork(postBreakdownNetwork)">
                <i class="ssi ssi-semi-negative-new"></i>
                <h4>Something went wrong</h4>
                <p>Sorry this network has no results to display,<br/> you may have to change the date or report back later</p>
            </div>
            <div class="section-content" *ngIf="checkNetwork(postBreakdownNetwork)">
                <div class="post-breakdown">
                    <ng-container *ngFor="let competitor of competitorStats">
                        <div class="post-breakdown-box top-tags">
                            <div class="top-tags-box" [ngStyle]="{'flex-basis': 100 / competitorStats.length + '%', 'max-width': 100 / competitorStats.length + '%'}">
                                <div class="top-tags-box-header">
                                    <span>Top Tags</span>
                                    <div class="stat-comparisons-networks">
                                        <i *ngIf="['total','2'].includes(postBreakdownNetwork)" class="ssi ssi-x-logo"></i>
                                        <i *ngIf="['total','3'].includes(postBreakdownNetwork)" class="fa fa-facebook-f"></i>
                                        <i *ngIf="['total','12'].includes(postBreakdownNetwork)" class="fa fa-instagram"></i>
                                    </div>
                                </div>
                                <ssi-top-tags-list-box
                                                [competitor]="competitor"
                                                [tags]="statsModel[competitor.id].topTags[postBreakdownNetwork]"
                                ></ssi-top-tags-list-box>
                            </div>
                        </div>
                        <div class="post-breakdown-box top-words">
                            <div class="top-words-box" *ngFor="let competitor of competitorStats" [ngStyle]="{'flex-basis': 100 / competitorStats.length + '%', 'max-width': 100 / competitorStats.length + '%'}">
                                <div class="top-words-box-header">
                                    <span>Top Words</span>
                                    <div class="stat-comparisons-networks">
                                        <i *ngIf="['total','2'].includes(postBreakdownNetwork)" class="ssi ssi-x-logo"></i>
                                        <i *ngIf="['total','3'].includes(postBreakdownNetwork)" class="fa fa-facebook-f"></i>
                                        <i *ngIf="['total','12'].includes(postBreakdownNetwork)" class="fa fa-instagram"></i>
                                    </div>
                                </div>
                                <div class="tag-bar top g-stat-box" [style.border]="'1px solid ' + competitor.colour">
                                    <ssi-medal-score [place]="1" [color]="competitor.colour"></ssi-medal-score>
                                    <div class="progress-bar-wrapper">
                                        <div><span>{{statsModel[competitor.id]['topWords'][postBreakdownNetwork][0]?.name}}</span></div>
                                        <ssi-progress-bar
                                            [max]="'100'"
                                            [progress]="statsModel[competitor.id]['topWords'][postBreakdownNetwork][0]?.weight"
                                            [color]="competitor.colour">
                                        </ssi-progress-bar>
                                    </div>
                                    <div class="tag-count">
                                        <span>{{statsModel[competitor.id]['topWords'][postBreakdownNetwork][0]?.weight}}</span>
                                    </div>
                                </div>
                                <ssi-highchart [config]="statsModel[competitor.id]['topWordsGraphs'][postBreakdownNetwork]"></ssi-highchart>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>
        <section class="section-container" id="top-tags" *ngIf="competitorStats.length > 1">
            <div class="section-heading">
                <h3>Top Hashtags</h3>
                <ul class="network-filter">
                    <li (click)="topTagNetwork = 'total'" [class.active]="topTagNetwork === 'total'">All Networks</li>
                    <li (click)="topTagNetwork = '3'" [class.active]="topTagNetwork === '3'">Facebook</li>
                    <li (click)="topTagNetwork = '2'" [class.active]="topTagNetwork === '2'">X</li>
                    <li (click)="topTagNetwork = '12'" [class.active]="topTagNetwork === '12'">Instagram</li>
                </ul>
            </div>
            <div class="no-results" *ngIf="!checkNetwork(topTagNetwork)">
                <i class="ssi ssi-semi-negative-new"></i>
                <h4>Something went wrong</h4>
                <p>Sorry this network has no results to display,<br/> you may have to change the date or report back later</p>
            </div>
            <div class="section-content" *ngIf="checkNetwork(topTagNetwork)">
                <div class="top-tags">
                    <ng-container *ngFor="let competitor of competitorStats" >
                    <div class="top-tags-box" *ngIf="statsModel[competitor.id].topTags[topTagNetwork]" [ngStyle]="{'flex-basis': 100 / competitorStats.length + '%', 'max-width': 100 / competitorStats.length + '%'}">
                        <div class="top-tags-box-header">
                            <div class="competitor-legend" *ngIf="competitorStats.length > 1"><div [style.background]="competitor.colour"></div>{{competitor.name}}</div>
                            <div class="stat-comparisons-networks">
                                <i *ngIf="['total','2'].includes(topWordNetwork)" class="ssi ssi-x-logo"></i>
                                <i *ngIf="['total','3'].includes(topWordNetwork)" class="fa fa-facebook-f"></i>
                                <i *ngIf="['total','12'].includes(topWordNetwork)" class="fa fa-instagram"></i>
                            </div>
                        </div>
                        <ssi-top-tags-list-box
                                                [competitor]="competitor"
                                                [tags]="statsModel[competitor.id].topTags[topTagNetwork]"
                                                [slim]="competitorStats.length === 2 ? 'true' : 'false'"></ssi-top-tags-list-box>
                    </div>
                    </ng-container>
                </div>
            </div>
        </section>
        <hr *ngIf="competitorStats.length > 1"/>
        <section class="section-container" id="top-words" *ngIf="competitorStats.length > 1">
            <div class="section-heading">
                <h3>Top Words</h3>
                <ul class="network-filter">
                    <li (click)="topWordNetwork = 'total'" [class.active]="topWordNetwork === 'total'">All Networks</li>
                    <li (click)="topWordNetwork = '3'" [class.active]="topWordNetwork === '3'">Facebook</li>
                    <li (click)="topWordNetwork = '2'" [class.active]="topWordNetwork === '2'">X</li>
                    <li (click)="topWordNetwork = '12'" [class.active]="topWordNetwork === '12'">Instagram</li>
                </ul>
            </div>
            <div class="no-results" *ngIf="!checkNetwork(topWordNetwork)">
                <i class="ssi ssi-semi-negative-new"></i>
                <h4>Something went wrong</h4>
                <p>Sorry this network has no results to display,<br/> you may have to change the date or report back later</p>
            </div>
            <div class="section-content" *ngIf="checkNetwork(topWordNetwork)">
                <div class="top-words">
                    <ng-container *ngFor="let competitor of competitorStats">
                        <div class="top-words-box" *ngIf="statsModel[competitor.id]['topWords'][topWordNetwork]" [ngStyle]="{'flex-basis': 100 / competitorStats.length + '%', 'max-width': 100 / competitorStats.length + '%'}">
                            <div class="top-words-box-header">
                                <div class="competitor-legend" *ngIf="competitorStats.length > 1"><div [style.background]="competitor.colour"></div>{{competitor.name}}</div>
                                <div class="stat-comparisons-networks">
                                    <i *ngIf="['total','2'].includes(topWordNetwork)" class="ssi ssi-x-logo"></i>
                                    <i *ngIf="['total','3'].includes(topWordNetwork)" class="fa fa-facebook-f"></i>
                                    <i *ngIf="['total','12'].includes(topWordNetwork)" class="fa fa-instagram"></i>
                                </div>
                            </div>
                            <div class="no-results" *ngIf="!statsModel[competitor.id]['topWords'][topWordNetwork][0]">
                                <h4>No data to display</h4>
                                <p>This is either because we are still gathering data or there was no activity during the report period.</p>
                            </div>
                            <ng-container *ngIf="statsModel[competitor.id]['topWords'][topWordNetwork][0]">
                                <ssi-highchart [config]="statsModel[competitor.id]['topWordsGraphs'][topWordNetwork]"></ssi-highchart>
                                <div class="tag-bar top g-stat-box" [style.border]="'1px solid ' + competitor.colour">
                                    <ssi-medal-score [place]="1" [color]="competitor.colour"></ssi-medal-score>
                                    <div class="progress-bar-wrapper">
                                        <div><span>{{statsModel[competitor.id]['topWords'][topWordNetwork][0].name}}</span></div>
                                        <ssi-progress-bar
                                            [max]="'100'"
                                            [progress]="statsModel[competitor.id]['topWords'][topWordNetwork][0].weight"
                                            [color]="competitor.colour">
                                        </ssi-progress-bar>
                                    </div>
                                    <div class="tag-count">
                                        <span>{{statsModel[competitor.id]['topWords'][topWordNetwork][0].weight}}</span>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </ng-container>
                </div>
            </div>
        </section>
        <hr/>
        <section class="section-container" id="audience">
            <div class="section-heading">
                <h3>Follower Count</h3>
                <ul class="network-filter">
                    <li (click)="audienceCountNetwork = '3'" [class.active]="audienceCountNetwork === '3'">Facebook</li>
                    <li (click)="audienceCountNetwork = '2'" [class.active]="audienceCountNetwork === '2'">X</li>
                    <li (click)="audienceCountNetwork = '12'" [class.active]="audienceCountNetwork === '12'">Instagram</li>
                </ul>
            </div>
            <div class="no-results" *ngIf="!checkNetwork(audienceCountNetwork)">
                <i class="ssi ssi-semi-negative-new"></i>
                <h4>No data to display</h4>
                <p>This is either because we are still gathering data or there was no activity during the report period.</p>
            </div>
            <div class="section-content" *ngIf="checkNetwork(audienceCountNetwork)">
                <div class="follower-counts">
                    <ng-container *ngFor="let competitor of competitorStats">
                    <div class="count-chart" *ngIf="statsModel[competitor.id].audienceCount[audienceCountNetwork]" [ngStyle]="{'flex-basis': 100 / competitorStats.length + '%', 'max-width': 100 / competitorStats.length + '%'}">
                        <div class="current-count-single" *ngIf="competitorStats.length === 1">
                            <div class="stat-comparisons-networks">
                                <i *ngIf="['2'].includes(audienceCountNetwork)" class="ssi ssi-x-logo"></i>
                                <i *ngIf="['3'].includes(audienceCountNetwork)" class="fa fa-facebook-f"></i>
                                <i *ngIf="['12'].includes(audienceCountNetwork)" class="fa fa-instagram"></i>
                            </div>
                            <span><ssi-big-number [number]="statsModel[competitor.id]['totalFollowerCount'][audienceCountNetwork]"></ssi-big-number> Total Instagram followers</span>
                            <ssi-stat-change
                                [boxed]="true"
                                [current]="statsModel[competitor.id].audienceCount[audienceCountNetwork][reportEndDate]"
                                [previous]="statsModel[competitor.id].audienceCount[audienceCountNetwork][reportStartDate]"
                            >
                            </ssi-stat-change>
                        </div>
                        <div class="competitor-legend" *ngIf="competitorStats.length > 1"><div [style.background]="competitor.colour"></div>{{competitor.name}}</div>
                        <ssi-medal-score
                            *ngIf="competitorStats.length > 1 && isTopPlace('audienceGrowthCount',competitor.id,audienceCountNetwork)"
                            [place]="1"
                            color="#F0B427"
                            variant="big"
                        ></ssi-medal-score>
                        <ssi-highchart *ngIf="checkNetwork(audienceCountNetwork)" [config]="statsModel[competitor.id]['audienceCountGraphs'][audienceCountNetwork]"></ssi-highchart>
                        <div class="current-count" [style.border]="'1px solid ' + competitor.colour" *ngIf="competitorStats.length > 1">
                            <div class="stat-comparisons-networks">
                                <span *ngIf="['2'].includes(audienceCountNetwork)" class="ssi ssi-x-logo"></span>
                                <span *ngIf="['3'].includes(audienceCountNetwork)" class="fa fa-facebook-f"></span>
                                <span *ngIf="['12'].includes(audienceCountNetwork)" class="fa fa-instagram"></span>
                            </div>
                            <div class="number">
                                <ssi-big-number [number]="statsModel[competitor.id]['totalFollowerCount'][audienceCountNetwork]"></ssi-big-number>
                            </div>
                            <ssi-stat-change
                                [boxed]="true"
                                [current]="statsModel[competitor.id].audienceCount[audienceCountNetwork][reportEndDate]"
                                [previous]="statsModel[competitor.id].audienceCount[audienceCountNetwork][reportStartDate]"
                            >
                            </ssi-stat-change>
                        </div>
                    </div>
                    </ng-container>
                </div>
            </div>
        </section>
        <hr/>
        <section
            class="section-container growth-section"
            [ngClass]="{'single-brand': competitorStats.length === 1}"
        >
            <div class="section-heading">
                <h3>Audience Growth</h3>
                <ul
                    class="network-filter"
                    id="audience-growth"
                >
                    <!-- <li (click)="audienceGrowthNetwork = null" [class.active]="audienceGrowthNetwork === null">All Networks</li> -->
                    <li
                        (click)="audienceGrowthNetwork = '3'"
                        [class.active]="audienceGrowthNetwork === '3'"
                    >Facebook</li>
                    <li
                        (click)="audienceGrowthNetwork = '2'"
                        [class.active]="audienceGrowthNetwork === '2'"
                    >X</li>
                    <li
                        (click)="audienceGrowthNetwork = '12'"
                        [class.active]="audienceGrowthNetwork === '12'"
                    >Instagram</li>
                </ul>
            </div>
            <div class="no-results" *ngIf="!checkNetwork(audienceGrowthNetwork)">
                <i class="ssi ssi-semi-negative-new"></i>
                <h4>Something went wrong</h4>
                <p>Sorry this network has no results to display,<br/> you may have to change the date or report back later</p>
            </div>
            <div class="section-content" *ngIf="checkNetwork(audienceGrowthNetwork)">
                <div class="follower-growth" [class.single]="competitorStats.length === 1">
                    <ssi-highchart
                        [config]="statsModel.total['audienceGrowthGraphs'][audienceGrowthNetwork]"
                    ></ssi-highchart>
                    <div
                        class="growth-per-profile"
                        *ngIf="competitorStats.length > 1"
                    >
                        <div class="title-container">
                            <h4 class="stat-title">Audience Growth Rate</h4>
                            <i
                                class="ssi ssi-help"
                                [ssiTooltip]="'This is the audience growth rate as a percentage since the start of the report'"
                            ></i>
                        </div>
                        <div class="audience-stats">
                            <ng-container *ngFor="let competitor of competitorStats; let i = index">
                                <div
                                    *ngIf="statsModel[competitor.id]['audienceGrowthRate'][audienceGrowthNetwork] !== undefined"
                                    class="g-stat-box stat-box"
                                    [ngStyle]="{'flex-basis': 100 / competitorStats.length + '%'}"
                                    [style.border-color]="competitor.colour"
                                >
                                    <div
                                        class="growth-stat-box"
                                    >
                                        <div class="growth-label-container">
                                            <ssi-medal-score
                                                *ngIf="competitorStats.length > 1 && isTopPlace('audienceGrowthRate',competitor.id,audienceCountNetwork)"
                                                [place]="1"
                                                color="#F0B427"
                                                variant="big"
                                            ></ssi-medal-score>
                                            <div class="stat-label">
                                                <i
                                                    class="legend-dot"
                                                    [style.background]="competitor.colour"
                                                ></i>
                                                <span>{{competitor.name}}</span>
                                            </div>
                                        </div>
                                        <div class="growth-stats">
                                            <ssi-stat-change
                                                [boxed]="true"
                                                [current]="statsModel[competitor.id].audienceCount[audienceGrowthNetwork][reportEndDate]"
                                                [previous]="statsModel[competitor.id].audienceCount[audienceGrowthNetwork][reportStartDate]"
                                            >
                                            </ssi-stat-change>
                                            <span>{{statsModel[competitor.id]['audienceGrowthRate'][audienceGrowthNetwork].toFixed(2)}}%</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div
                        class="single-profile-growth"
                        *ngIf="competitorStats.length === 1"
                    >
                        <div class="box-stat">
                            <div class="title-container">
                                <h4 class="stat-title">Audience Growth Rate</h4>
                                <i
                                    class="ssi ssi-help"
                                    [ssiTooltip]="'This is the audience growth rate as a percentage since the start of the report'"
                                ></i>
                            </div>
                            <i class="ssi ssi-audience-growth-rate background-graphic"></i>
                            <span>{{statsModel[competitorStats[0].id]['audienceGrowthRate'][audienceGrowthNetwork].toFixed(2)}}%</span>
                            <ssi-stat-change
                                [boxed]="true"
                                [current]="statsModel[competitorStats[0].id]?.audienceCount[audienceGrowthNetwork][reportEndDate]"
                                [previous]="statsModel[competitorStats[0].id]?.audienceCount[audienceGrowthNetwork][reportStartDate]"
                            >
                            </ssi-stat-change>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
