import { ViewEncapsulation } from '@angular/core';


import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ssi-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClockComponent implements OnInit, OnDestroy {
  interval: any;
  timeDegrees: any;

  constructor() {}

  ngOnInit(): void {
    const updateTime = () => {
      const DEGREES_IN_CIRCLE = 360;
      const HOURS_IN_HALF_DAY = 12;
      const MINUTES_IN_HOUR = 60;

      this.timeDegrees = {
        hours: +moment().format('h') / HOURS_IN_HALF_DAY * DEGREES_IN_CIRCLE,
        minutes: +moment().format('m') / MINUTES_IN_HOUR * DEGREES_IN_CIRCLE
      };
    };

    updateTime();

    this.interval = setInterval(updateTime, 60 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
