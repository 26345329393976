import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-advertising-table-filters',
  templateUrl: './advertising-table-filters.component.html',
  styles: [],
  styleUrls: ['./advertising-table-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvertisingTableFiltersComponent {
  @Input() sortEnabled = true;
  @Input() statusEnabled = false;
  @Input() activeSocialFilter: number;
  @Input()
  activeStatusFilter: 'all' | 'live' | 'scheduled' | 'paused' | 'completed';
  @Input() activeSort: string;
  @Input() sortLabel: string;
  @Output() filterAccount: EventEmitter<number> = new EventEmitter();
  @Output() sortAccount: EventEmitter<string> = new EventEmitter();
  @Output() filterStatus: EventEmitter<string> = new EventEmitter();

  showSortBox = false;

  constructor() {}

  sortAccountAction(sortOption: string) {
    this.sortAccount.emit(sortOption);
    this.showSortBox = false;
  }
}
