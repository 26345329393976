import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';
import { Applet } from '../../../ifttt-utils/applets/applet';
import { AppletCollection } from '../../../ifttt-utils/applets/applet-collection';
import { IftttService } from '../../../ifttt-utils/ifttt-service-model/ifttt-service-model';

@Component({
  selector: 'ssi-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryComponent {
  @Input() service: IftttService;
  @Input() applet: Applet;
  @Input() appletCollection: AppletCollection;

  constructor(private state: StateService, private transition: Transition) {}

  async saveApplet() {
    if (this.transition.params().appletIndex) {
      this.appletCollection.applets[
        this.transition.params().appletIndex
      ] = this.applet;
    } else {
      this.appletCollection.applets.push(this.applet);
    }
    await this.appletCollection.service.saveAppletCollection(
      this.appletCollection
    );
    this.state.go('auth.iftttAppletCollectionList', {
      serviceName: this.service.name
    });
  }

  trackByIndex(index: number) {
    return index;
  }
}
