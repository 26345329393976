import { ViewEncapsulation } from '@angular/core';


import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-account-summary',
  templateUrl: './account-summary.component.html',
  styles: [],
  styleUrls: ['./account-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSummaryComponent {
  @Input() socialAccounts;
  @Input() stats;

  constructor() {}
}
