import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges
} from '@angular/core';

import { PieChartData } from '../../services/widget-chart-pie/widget-chart-pie.service';
import { Filter } from '../../../reports/view/view-report.component';
import { InsightsService, Pie } from '../../../insights.service';

import { Options } from 'highcharts';
import Highcharts from 'highcharts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';
import {
  findSentimentConst,
  sentimentsIterable
} from '../../../../../../common/constants';
import {
  FieldName,
  filtersFieldDefinitions,
  formatFilterTypeData
} from '../../constants/filters-field-definitions';
import { BigNumberPipe } from '../../../../../../common/pipes/big-number/big-number.pipe';
import { HighchartComponent } from '../../../../../../common/components/highchart/highchart.component';
import moment from 'moment';

@Component({
  selector: 'ssi-widget-chart-pie',
  templateUrl: './widget-chart-pie.component.html',
  styles: [],
  styleUrls: ['./widget-chart-pie.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetChartPieComponent implements OnInit, OnChanges {
  @Input() widget: Pie;
  // @Input() data: PieChartData;
  @Input() seriesName: string;
  @Input() isDonut: boolean = false;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();
  @Output() onApplyFilters = new EventEmitter();

  @ViewChild(HighchartComponent) highchart: HighchartComponent;

  pieConfig: Options = {};
  updateFlag: boolean = false;
  data: PieChartData;

  constructor(
    private insightsService: InsightsService,
    private bigNumberPipe: BigNumberPipe,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }) => {
        // console.log('data: ', data);

        this.data = this.insightsService.relabelDataModelForUi(
          this.widget,
          data
        );

        if (
          this.widget.break_down_by &&
          this.widget.break_down_by.field === FieldName.CreatedAt
        ) {
          this.data = Object.entries(this.data)
            .map(([key, value]) => {
              const momDate = moment(key);
              const dateFormat = momDate.hour()
                ? 'DD/MM/YYYYTHH'
                : 'DD/MM/YYYY';
              return { [momDate.format(dateFormat).toString()]: value };
            })
            .reduce((result, current) => Object.assign(result, current, {}));
        }

        this.pieConfig = this._getChartConfig(
          this.data,
          this.widget,
          this.isDonut
        );
        this.highchart.rerender();
        this.loaded.emit();
      });
  }

  private _formatChartData(data: {
    [key: string]: number;
  }): { name: string; y: number; sentimentKey: string }[] {
    return Object.entries(data).map((keyValue) => {
      const formattedValue = this.bigNumberPipe.transform(keyValue[1]);
      return {
        name: `${formattedValue} | ${keyValue[0]}`,
        y: keyValue[1],
        pieSliceName: keyValue[0],
        sentimentKey: keyValue[0]
      };
    });
  }

  private _getChartConfig(
    data: { [key: string]: number },
    widget: Pie,
    isDonut = false
  ): Options {
    let chartData;
    let colours;
    if (
      widget.break_down_by &&
      widget.break_down_by.field === FieldName.Sentiment
    ) {
      chartData = this._formatChartData(data).sort((a, b) => {
        const aConst = findSentimentConst('key3', a.sentimentKey);
        const bConst = findSentimentConst('key3', b.sentimentKey);
        return bConst.numericKey - aConst.numericKey;
      });
      colours = chartData.map(
        (d) => findSentimentConst('key3', d.sentimentKey).color2
      );
    } else {
      chartData = this._formatChartData(data).sort((a, b) =>
        a.y > b.y ? -1 : a.y < b.y ? 1 : 0
      );

      colours = [
        '#3CC5D8',
        '#D41D68',
        '#F0B427',
        '#89A4EA',
        '#00bde9',
        '#98efff',
        '#d4e0fa',
        '#ffe6e0',
        '#ff865e'
      ];
    }

    // console.log('chartData: ', chartData);

    const totalValue = this.bigNumberPipe.transform(
      Object.values(data).reduce((partialSum, a) => partialSum + a, 0)
    );

    Highcharts.setOptions({
      colors: colours
    });
    return {
      chart: {
        type: 'pie',
        backgroundColor: null,
        spacing: [0, 0, 0, 0]
      },
      credits: {
        enabled: false
      },
      legend: {
        maxHeight: 300,
        width: 180,
        title: {
          text: `
            <p class="insights-chart-pie-total">
              ${totalValue}
            </p>
            <p class="insights-chart-pie-subtotal">Total</p>
          `,
          style: {
            fontFamily: 'Lato, Arial, sans-serif'
          }
        },
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginTop: 2,
        itemMarginBottom: 13,
        x: 0,
        useHTML: true,
        itemStyle: {
          fontSize: '14px',
          fontWeight: '900',
          color: '#101525',
          fontFamily: 'Lato, Arial, sans-serif'
        }
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      accessibility: {
        description: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          events: {
            click: (event) => {
              console.log('datapoint click:', event);
              const modal = this.modal.open(DrilldownModalComponent, {
                windowClass: 'xxl-modal'
              });
              const filterType =
                filtersFieldDefinitions[this.widget.break_down_by.field]
                  .preferedFilterType;
              // let pieNameClicked = event.point.name
              //   .replace(/[0-9|]/g, '')
              //   .trim();
              // console.log('pieNameClicked:', pieNameClicked);
              modal.componentInstance.streamIds = this.streamIds;
              modal.componentInstance.displayApplyFilters = true;
              modal.componentInstance.globalFilters = this.globalFilters;
              modal.componentInstance.widgetFilters = this.widget.filters;
              modal.componentInstance.selectedFilters = [
                {
                  field: this.widget.break_down_by.field,
                  [filtersFieldDefinitions[this.widget.break_down_by.field]
                    .preferedFilterType]: formatFilterTypeData(
                    event.point['pieSliceName'],
                    filterType
                  )
                }
              ];
              modal.componentInstance.onApplyFilters.subscribe((filters) =>
                this.onApplyFilters.emit(filters)
              );
            }
          }
        }
      },
      series: [
        {
          type: 'pie',
          name: this.seriesName,
          innerSize: isDonut ? '40%' : 0,
          data: chartData
        }
      ]
    };
  }
}
