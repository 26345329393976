import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
  NgZone,
  OnDestroy
} from '@angular/core';
import {
  Trustometer,
  trustometersIterable,
  trustometers,
  getTrustometerObj
} from '../../../../../../common/constants';
import {
  InisightsClusterDocument,
  InsightsService,
  SentimentGauge
} from '../../../insights.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ClusteringStatusSource,
  DrilldownModalComponent
} from '../drilldown-modal/drilldown-modal.component';
import { Filter } from '../../../reports/view/view-report.component';
import { HighchartComponent } from '../../../../../../common/components/highchart/highchart.component';
import { Subject, Subscription, interval, merge, throwError } from 'rxjs';
import { SocketEventManagerService } from '../../../../../../common/services/sockets/socket-event-manager.service';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface ClusteringJob {
  clusteringObservables: any;
  stopConditions$: any;
  pollingDocumentStatus$: Subscription;
  clusteringDone$: Subject<any>;
  clusteringFailed$: Subject<any>;
  documentReady$: Subject<any>;
  clusteringJobFailed: boolean;
  loadingClusters: boolean;
  themes: Array<{
    clusterId: number;
    jobTicket: string;
    themeName: string;
  }>;
}

@Component({
  selector: 'ssi-widget-trustometer-gauge',
  templateUrl: './widget-trustometer-gauge.component.html',
  styles: [],
  styleUrls: ['./widget-trustometer-gauge.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetTrustometerGaugeComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() widget: SentimentGauge;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();

  @ViewChild(HighchartComponent) highchart: HighchartComponent;

  trustometer: Trustometer;
  trustometersIterable: Trustometer[] = trustometersIterable;
  trustometers = trustometers;
  score: number = 0;
  scoreFixed: string = '';
  scoreForGauge: number = 0;
  gaugeShapeLengthInPoints: number = 330;

  // clustering - web sockets - API polling
  trusting: ClusteringJob = {
    clusteringObservables: undefined,
    stopConditions$: undefined,
    pollingDocumentStatus$: undefined,
    clusteringDone$: new Subject(),
    clusteringFailed$: new Subject(),
    documentReady$: new Subject(),
    clusteringJobFailed: false,
    loadingClusters: false,
    themes: []
  };

  distrusting: ClusteringJob = {
    clusteringObservables: undefined,
    stopConditions$: undefined,
    pollingDocumentStatus$: undefined,
    clusteringDone$: new Subject(),
    clusteringFailed$: new Subject(),
    documentReady$: new Subject(),
    clusteringJobFailed: false,
    loadingClusters: false,
    themes: []
  };

  onDestroy = new Subject();

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal,
    private ngZone: NgZone,
    private socketEventManagerService: SocketEventManagerService
  ) {}

  ngOnInit() {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data, metadata }) => {
        this.score = data;

        this.calculateTrustometer();
        if (this.score) {
          this.getClustersData('trusting', metadata.trusting_ticket);
          this.getClustersData('distrusting', metadata.untrusting_ticket);
        } else {
          this.loaded.emit();
        }
      });
  }

  /** Use the score and transpile it to Gauge to fill the certain amount of `stroke-dasharray` in the template (SVG)
   * Since half-circle path is `gaugeShapeLengthInPoints` long, we are calculating the matching value, so i.e.:
   *  If the score is `90`, on a scale 100 (very trusting) to -100 (very distrusting) in order to fill the right amount of trust/distrust color
   * for non 0 - 100 scale, we have to calculate the `stroke-dasharray` value for the SVG shape/path, so doing below:
   */
  calculateTrustometer(): void {
    const trustometerMatched = getTrustometerObj(this.score);
    console.log('trustometerMatched:', trustometerMatched);
    this.trustometer = trustometerMatched;
    const translatedPercentage =
      100 - ((this.score - -100) / (100 - -100)) * 100;
    console.log('translatedPercentage:', translatedPercentage);

    // in case BE returns `data: null` show 0 and gauge in the middle
    this.scoreForGauge = this.score
      ? (translatedPercentage / 100) * this.gaugeShapeLengthInPoints
      : this.gaugeShapeLengthInPoints / 2;

    this.scoreFixed = this.score ? `${this.score.toFixed(2)}%` : '0%';
  }

  private async getClustersData(
    jobName: 'trusting' | 'distrusting',
    ticket: string
  ) {
    if (ticket === null) {
      this[jobName].themes = [];
      return;
    }

    if (!ticket) {
      return;
    }

    this[jobName].loadingClusters = true;
    this[jobName].themes = [];

    this[jobName].stopConditions$ = merge(
      this[jobName].documentReady$,
      this[jobName].clusteringFailed$,
      this[jobName].clusteringDone$,
      this.onDestroy
    ).pipe(tap((s) => console.log('Stop checking for clusters', jobName)));

    this[jobName].clusteringObservables = merge(
      this.socketEventManagerService.clusteringDone.pipe(
        takeUntil(this[jobName].stopConditions$),
        map((data) => ({ source: ClusteringStatusSource.clusteringDone, data }))
      ),
      this.socketEventManagerService.clusteringFailed.pipe(
        takeUntil(this[jobName].stopConditions$),
        map((data) => ({
          source: ClusteringStatusSource.clusteringFailed,
          data
        }))
      ),
      interval(5000).pipe(
        takeUntil(this[jobName].stopConditions$),
        startWith(0),
        switchMap(() => {
          console.log('Polling the ticket:', ticket);
          return this.insightsService.getInsightsClusterDocumentStatus(ticket);
        }),
        map((data) => ({ source: ClusteringStatusSource.apiPolling, data }))
      )
    );

    this[jobName].pollingDocumentStatus$ = this[
      jobName
    ].clusteringObservables.subscribe(
      async (res) => {
        console.log('Document ready response:', res);
        if (
          (res.source === ClusteringStatusSource.apiPolling &&
            !res.data.completed) ||
          res.data.ticket !== ticket
        ) {
          return;
        }

        if (res.source === ClusteringStatusSource.clusteringFailed) {
          this[jobName].clusteringFailed$.next();
          this[jobName].loadingClusters = false;
          return throwError('WebSockets sent clusteringFailed event');
        }

        this[jobName].documentReady$.next();
        const clusterDocument: InisightsClusterDocument[] = await this.insightsService.getInsightsClusterDocument(
          res.data.ticket
        );

        console.log('Document ready clusterDocument:', clusterDocument);
        this[jobName].themes = [];
        clusterDocument.slice(0, 5).map((doc, index) => {
          // console.log('doc:', doc);
          this[jobName].themes.push({
            clusterId: doc.id,
            jobTicket: res.data.ticket,
            themeName: doc.openai_theme
          });
        });
        this.ngZone.run(() => {
          this[jobName].loadingClusters = false;
        });
        this.hideLoaderIfJobsFinished();
      },
      (err) => {
        console.error('Error polling document status:', err);
        this.loaded.emit();

        this.ngZone.run(() => {
          this[jobName].clusteringJobFailed = true;
        });
      }
    );
  }

  hideLoaderIfJobsFinished() {
    if (!this.trusting.loadingClusters && !this.distrusting.loadingClusters) {
      this.loaded.emit();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  openDrilldownModal(theme: any) {
    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });
    modal.componentInstance.clusterId = theme.clusterId;
    modal.componentInstance.jobTicket = theme.jobTicket;
    modal.componentInstance.enableThemeClusters = false;
    modal.componentInstance.globalFilters = this.globalFilters;
    modal.componentInstance.streamIds = this.streamIds;
    modal.componentInstance.widgetFilters = this.widget.filters;
  }
}
