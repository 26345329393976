<form #form="ngForm"
      (submit)="submit(form)">
  <div class="form-row">
    <div class="form-field half">
      <label translate="START_DATE"></label>
      <div class="input-wrapper"
           #startDateContainer>
        <input mwlFlatpickr
               class="date-picker-input"
               type="text"
               name="startDate"
               [ngModel]="startDate"
               (ngModelChange)="onStartDatePicked($event)"
               [altInput]="true"
               [convertModelValue]="true"
               [enableTime]="true"
               [defaultHour]="00"
               [defaultMinute]="00"
               [defaultSeconds]="00"
               dateFormat="Y-m-d H:i"
               altFormat="M J Y, H:i"
               [maxDate]="endDate"
               [time24hr]="true"
               [appendTo]="startDateContainer.nativeElement"
               placeholder="Select..."
               [required]="true"
               (flatpickrOpen)="onFlatpickrOpen()">
      </div>
    </div>
    <div class="form-field half">
      <label translate="END_DATE"></label>
      <div class="input-wrapper"
           #endDateContainer>
        <input mwlFlatpickr
               class="date-picker-input"
               type="text"
               name="endDate"
               [ngModel]="endDate"
               (ngModelChange)="onEndDatePicked($event)"
               [altInput]="true"
               [convertModelValue]="true"
               [enableTime]="true"
               [defaultHour]="23"
               [defaultMinute]="59"
               [defaultSeconds]="59"
               dateFormat="Y-m-d H:i"
               altFormat="M J Y, H:i"
               [minDate]="startDate"
               [maxDate]="today"
               [time24hr]="true"
               [appendTo]="endDateContainer.nativeElement"
               placeholder="Select..."
               [required]="true"
               (flatpickrOpen)="onFlatpickrOpen()">
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-field">
      <div class="input-wrapper">
        <div *ngFor="let range of dateRangesIterable"
             class="inline-select-item"
             [class.selected]="range.id === selectedDateRange?.id"
             (click)="selectPresetDateRange(range)">
          <i *ngIf="range.id === selectedDateRange?.id"
             class="ssi ssi-date-preset"></i>
          <span>{{range.label}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row extra-margin-top"
       *ngIf="showCompareToSection">
    <div class="form-field">
      <label translate="COMPARE_TO"></label>
      <div class="input-wrapper">
        <div *ngFor="let option of compareToOptionsIterable"
             class="inline-select-item"
             [class.selected]="option.id === selectedCompareToOption.id"
             (click)="selectCompareToOption(option)">
          <i *ngIf="option.id === selectedCompareToOption.id"
             class="ssi ssi-tick-small"></i>
          <span>{{option.label}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row"
       *ngIf="showCompareToSection && selectedCompareToOption === compareToOptions.custom">
    <div class="form-field half">
      <label>Previous start date</label>
      <div class="input-wrapper"
           #previousStartDateContainer>
        <input mwlFlatpickr
               class="date-picker-input"
               type="text"
               name="previousStartDate"
               [ngModel]="previousStartDate"
               (ngModelChange)="onPreviousStartDatePicked($event)"
               [altInput]="true"
               [convertModelValue]="true"
               [enableTime]="true"
               [defaultHour]="00"
               [defaultMinute]="00"
               [defaultSeconds]="00"
               dateFormat="Y-m-d H:i"
               altFormat="M J Y, H:i"
               [maxDate]="previousEndDate || startDate"
               [time24hr]="true"
               [appendTo]="previousStartDateContainer.nativeElement"
               placeholder="Select..."
               [required]="selectedCompareToOption === compareToOptions.custom">

      </div>
    </div>
    <div class="form-field half">
      <label>Previous end date</label>
      <div class="input-wrapper"
           #previousEndDateContainer>
        <input mwlFlatpickr
               class="date-picker-input"
               type="text"
               name="previousEndDate"
               [ngModel]="previousEndDate"
               (ngModelChange)="onPreviousEndDatePicked($event)"
               [altInput]="true"
               [convertModelValue]="true"
               [enableTime]="true"
               [defaultHour]="23"
               [defaultMinute]="59"
               [defaultSeconds]="59"
               dateFormat="Y-m-d H:i"
               altFormat="M J Y, H:i"
               [minDate]="previousStartDate"
               [maxDate]="startDate"
               [time24hr]="true"
               [appendTo]="previousEndDateContainer.nativeElement"
               placeholder="Select..."
               [required]="selectedCompareToOption === compareToOptions.custom">
      </div>
    </div>
  </div>

  <div class="form-row extra-margin-top">
    <div class="form-field">
      <label>Accounts included</label>
      <div class="input-wrapper">
        <ssi-dropdown-select-2 name="accounts"
                               class="accounts-selector"
                               headButtonStyleVariant="analytics"
                               size="lg"
                               [required]="true"
                               [multiple]="true"
                               [filterable]="true"
                               [filterPlaceholder]="'Search for an account...'"
                               [toggleAllLabel]="'Select all accounts'"
                               [options]="accounts"
                               [(ngModel)]="selectedAccounts"
                               (ngModelChange)="onSelectedAccountsChange($event)">
          <ng-template ssiTemplateSelector="headButtonLabel"
                       let-expanded="expanded">
            <div class="select-accounts-placeholder"
                 *ngIf="!selectedAccounts.length">
              <span>Select accounts...</span>
            </div>
            <div class="select-accounts-label"
                 *ngIf="selectedAccounts.length">
              <span>{{selectedAccounts.length}} accounts</span>
              <ssi-social-network-icon *ngFor="let accountTypeId of selectedAccountTypeIds"
                                       [accountTypeId]="accountTypeId"
                                       variant="fa"></ssi-social-network-icon>
            </div>
          </ng-template>

          <ng-template ssiTemplateSelector="optionIcon"
                       let-account="option">
            <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                     variant="fa"></ssi-social-network-icon>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-field">
      <div class="input-wrapper select-all-accounts">
        <ssi-checkbox-2 name="selectAllAccountsChecked"
                        label="Select all accounts"
                        [(ngModel)]="selectAllAccountsChecked"
                        (ngModelChange)="onSelectAllAccountsChange($event)"></ssi-checkbox-2>
      </div>
    </div>
  </div>

  <div class="form-row extra-margin-top"
       *ngIf="showVisibilitySection">
    <div class="form-field">
      <label>Visibility</label>
      <div class="input-wrapper">
        <div *ngFor="let option of visibilityOptionsIterable"
             class="inline-select-item"
             [class.selected]="option.key === selectedVisibilityOption.key"
             (click)="selectVisibilityOption(option)">
          <i *ngIf="option.key === selectedVisibilityOption.key"
             class="ssi ssi-tick-small"></i>
          <span>{{option.label}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row extra-margin-top"
       *ngIf="showImportDelayOptions">
    <div class="form-field">
      <label>Av. Response Time</label>
      <div class="input-wrapper">
        <div *ngFor="let option of importDelayOptionsIterable"
             class="inline-select-item"
             [class.selected]="option.key === selectedImportDelayOption.key"
             (click)="selectImportDelayOption(option)">
          <i *ngIf="option.key === selectedImportDelayOption.key"
             class="ssi ssi-tick-small"></i>
          <span>{{option.label}}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showSilencedMessagesOptions" class="form-row extra-margin-top">
    <div class="form-field">
      <label>Silenced Messages</label>
      <div class="input-wrapper">
        <div
          *ngFor="let option of silencedMessagesOptionsIterable"
          class="inline-select-item"
          [class.selected]="option.key === selectedSilencedMessagesOption.key"
          (click)="selectSilencedMessagesOption(option)"
        >
          <i
            *ngIf="option.key === selectedSilencedMessagesOption.key"
            class="ssi ssi-tick-small"
          ></i>
          <span>{{option.label}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row extra-margin-top"
       *ngIf="showIncludeDeletedInTopPostsOptions">
    <div class="form-field">
      <label>Include deleted posts</label>
      <div class="input-wrapper">
        <div *ngFor="let option of includeDeletedInTopPostsOptionsIterable"
             class="inline-select-item"
             [class.selected]="option.key === selectedIncludeDeletedInTopPostsOption.key"
             (click)="selectedIncludeDeletedInTopPostsOption = option">
          <i *ngIf="option.key === selectedIncludeDeletedInTopPostsOption.key"
             class="ssi ssi-tick-small"></i>
          <span>{{option.label}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row extra-margin-top"
       *ngIf="showTagsSection && tags?.length">
    <div class="form-field" [ngSwitch]="report">
      <label *ngSwitchCase="Report.Engagement">Include Inbox Tags</label>
      <label *ngSwitchCase="Report.Marketing">Include Post Tags</label>
      <label *ngSwitchDefault>Include Tags</label>
      <div class="input-wrapper">
        <ssi-dropdown-select-2 name="tagsToInclude"
                               class="tag-selector"
                               headButtonStyleVariant="analytics"
                               size="lg"
                               [multiple]="true"
                               [filterable]="true"
                               [toggleAllEnabled]="false"
                               [filterPlaceholder]="'Search tags to include...'"
                               [headButtonIcon]="'ssi ssi-addsmall'"
                               [toggleAllLabel]="'Select all tags'"
                               [options]="tags"
                               [(ngModel)]="selectedTagsToInclude"
                               (ngModelChange)="onSelectedTagsToIncludeChange($event)">
          <ng-template ssiTemplateSelector="headButtonLabel"
                       let-expanded="expanded">
            <div class="select-tags-placeholder"
                 *ngIf="!selectedTagsToInclude.length">
              <span>Build your report with these tags only</span>
            </div>
            <div class="select-tag-labels"
                 *ngIf="selectedTagsToInclude.length">
              <ng-container *ngFor="let tag of selectedTagsToInclude">
                <span (click)="$event.stopPropagation(); removeIncludedTag(tag)">
                  {{tag.label}} <i class="ssi ssi-times"></i>
                </span>
              </ng-container>
            </div>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>
  </div>

  <div class="form-row"
       *ngIf="showTagsSection && tags?.length">
    <div class="form-field" [ngSwitch]="report">
      <label *ngSwitchCase="Report.Engagement">Exclude Inbox Tags</label>
      <label *ngSwitchCase="Report.Marketing">Exclude Post Tags</label>
      <label *ngSwitchDefault>Exclude Tags</label>
      <div class="input-wrapper">
        <ssi-dropdown-select-2 name="tagsToExclude"
                               class="tag-selector"
                               headButtonStyleVariant="analytics"
                               size="lg"
                               [multiple]="true"
                               [filterable]="true"
                               [toggleAllEnabled]="false"
                               [filterPlaceholder]="'Search tags to exclude...'"
                               [headButtonIcon]="'ssi ssi-addsmall'"
                               [toggleAllLabel]="'Select all tags'"
                               [options]="tags"
                               [(ngModel)]="selectedTagsToExclude"
                               (ngModelChange)="onSelectedTagsToExcludeChange($event)">
          <ng-template ssiTemplateSelector="headButtonLabel"
                       let-expanded="expanded">
            <div class="select-tags-placeholder"
                 *ngIf="!selectedTagsToExclude.length">
              <span>Exclude these tags from your report...</span>
            </div>
            <div class="select-tag-labels"
                 *ngIf="selectedTagsToExclude.length">
              <ng-container *ngFor="let tag of selectedTagsToExclude">
                <span (click)="$event.stopPropagation(); removeExcludedTag(tag)">
                  {{tag.label}} <i class="ssi ssi-times"></i>
                </span>
              </ng-container>
            </div>
          </ng-template>
        </ssi-dropdown-select-2>
      </div>
    </div>
  </div>

  <button class="g-btn border-radius-100 submit-button"
          type="submit"
          [disabled]="form.invalid">Build analytics</button>
</form>