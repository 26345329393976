import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-datetime-modal',
  templateUrl: './datetime-modal.component.html',
  styleUrls: ['./datetime-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatetimeModalComponent {
  @Input() title: string;
  @Input() info: string;
  schedule;

  constructor(public activeModal: NgbActiveModal) {}
}
