import { ViewEncapsulation } from '@angular/core';

import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import {
  AccountModel,
  UserModel,
  Campaign,
  API,
  Account
} from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import { KeyValueObject } from '../../../common/util';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { NotificationManagerService } from '../../../common/services/notification-manager/notification-manager.service';
import { campaigns, workflowAccounts } from '../../common-resolves';
import { CampaignModelService } from '../../auth/marketing/index/campaign-model/campaign-model.service';
import { TransitionService, StateService } from '@uirouter/angular';
import { CompanyService } from '../../../common/services/company/company.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ssi-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarketingComponent implements OnInit, OnDestroy {
  static resolve = [campaigns, workflowAccounts];
  onDestroy = new Subject();

  @Input() campaigns: Campaign[];
  @Input() workflowAccounts: Account[];
  now = new Date();
  permissions: KeyValueObject<boolean> = {};
  filterCampaignActive = false;
  filteredCampaigns;
  filterTerm: string;
  childCampaign: Campaign;
  currentCampaign: Campaign;
  parentCampaign: Campaign;
  currentCampaignStats: any;
  campaignHasAdvertising = false;
  $activeCampaignSub;
  childCampaignParams: string;
  childCampaignHasChildren: boolean;
  currentPage: string;
  theme: string;
  addCampaignActive = false;
  newCampaign = {
    name: '',
    parent_id: null,
    started_at: null,
    closed_at: null
  };
  floatingMenu = false;
  advertisingEnabled = false;
  showHeader = true;
  noMargin = false;

  private _workFlowChangedSubscription: Subscription;

  constructor(
    private api: API,
    private userModel: UserModel,
    private accountModel: AccountModel,
    private workflowManager: WorkflowManagerService,
    private campaignModelService: CampaignModelService,
    public notificationManager: NotificationManagerService,
    private $transitions: TransitionService,
    private $state: StateService,
    private company: CompanyService
  ) {}

  async ngOnInit() {
    try {
      await this.company
        .hasFeatureAccess('ADVERTS')
        .then((result) => (this.advertisingEnabled = !!result));
      this.currentPage = this.$state.current.name.split('.')[2];
      this._applyMarketingClasses(this.currentPage);
      this.setTheme();
      this._workFlowChangedSubscription = this.workflowManager.events.workflowChanged
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => this.updatePermissions());
      this.updatePermissions();

      this.resetCampaignFilterMenu();
      
      this.$activeCampaignSub = this.campaignModelService.$activeCampaign.subscribe(
        (activeCampaign: Campaign) => {
          try {
            if (!(!!this.campaigns && Array.isArray(this.campaigns))) {
              throw new Error(
                `Value for 'marketing campaigns' not in expected format.`
              );
            }

            this.parentCampaign =
              activeCampaign && activeCampaign.parent_id
                ? this.campaigns.find(
                    (campaign) => campaign.id === activeCampaign.parent_id
                  )
                : undefined;

            this.childCampaign = activeCampaign ? activeCampaign : undefined;
            this.currentCampaign = activeCampaign;
            this.updateCurrentCampaignTheme(activeCampaign);

            this.childCampaignParams = activeCampaign
              ? JSON.stringify({ campaign_id: (activeCampaign as any).id })
              : undefined;
            activeCampaign
              ? this.assignCampaignFilterMenu(activeCampaign.id)
              : this.resetCampaignFilterMenu();

            return true;
          } catch (error) {
            console.error(error);

            return false;
          }
        }
      );
      this.$transitions.onSuccess({}, (transition) => {
        let campaignId = '';
        if (
          transition.params() &&
          (transition.params().query || transition.params().active_campaign_id)
        ) {
          campaignId = JSON.parse(
            transition.params().query || transition.params().active_campaign_id
          ).campaign_id;
        }

        if (!campaignId) {
          this.campaignModelService.currentActiveCampaign = null;
        }

        this.currentPage = transition.to().name.split('.')[2];
        this._applyMarketingClasses(this.currentPage);
        this.setTheme();
      });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();

    if (!!this.$activeCampaignSub) {
      this.$activeCampaignSub.unsubscribe();
    }

    if (!!this._workFlowChangedSubscription) {
      this._workFlowChangedSubscription.unsubscribe();
    }
  }

  isOnContentCalendarState(): boolean {
    return this.$state.current.name === 'auth.marketing.contentCalendar';
  }

  private _applyMarketingClasses(currentPage): void {
    const pagesToHideHeader = ['draftsLibrary', 'contentCalendar'];
    const pagesToRemoveMargin = ['contentCalendar'];
    this.showHeader = !pagesToHideHeader.includes(currentPage);
    this.noMargin = pagesToRemoveMargin.includes(currentPage);
  }

  public setTheme() {
    switch (this.currentPage) {
      case 'advertising':
        this.theme = 'dark';
        break;
      default:
        this.theme = 'light';
    }
  }

  public toggleAddCampaignDropdown() {
    if (this.childCampaign.parent_id) {
      return;
    }

    this.newCampaign.parent_id = this.childCampaign
      ? this.childCampaign.id
      : null;
    this.addCampaignActive = !this.addCampaignActive;
  }

  public toggleFilterByCampaignDropdown() {
    this.filterCampaignActive = !this.filterCampaignActive;
  }

  public filterByTerm() {
    let campaignList = [];
    if (this.childCampaign) {
      campaignList = this.campaignModelService
        .filterInChildCampaigns(this.campaigns, false, this.childCampaign.id)
        .filter(
          (campaign) =>
            campaign.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >
            -1
        );
    } else {
      campaignList = this.campaignModelService
        .filterOutChildCampaigns(this.campaigns, false)
        .filter(
          (campaign) =>
            campaign.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >
            -1
        );
    }
    this.filteredCampaigns = Object.assign([], campaignList);
  }

  public async selectCampaign(campaign) {
    this.filterTerm = '';
    this.childCampaign = campaign;
    this.currentCampaign = campaign;
    this.campaignModelService.currentActiveCampaign = campaign
      ? campaign.id
      : undefined;
    if (this.filterCampaignActive) {
      this.toggleFilterByCampaignDropdown();
    }
    this.childCampaign
      ? this.assignCampaignFilterMenu(this.childCampaign.id)
      : this.resetCampaignFilterMenu();
    await this.updateCurrentCampaignTheme();
  }

  assignCampaignFilterMenu(campaignId) {
    this.childCampaignHasChildren =
      this.campaigns.filter(
        (campaign) => this.childCampaign.id === campaign.parent_id
      ).length > 0;
    this.filteredCampaigns = Object.assign(
      [],
      this.campaignModelService.filterInChildCampaigns(
        this.campaigns,
        false,
        campaignId
      )
    ).filter((campaign) => campaign.name.toLowerCase());
  }

  resetCampaignFilterMenu() {
    this.filteredCampaigns = Object.assign(
      [],
      this.campaignModelService.filterOutChildCampaigns(this.campaigns, false)
    );
  }

  async updateCurrentCampaignTheme(activeCampaign?) {
    try {
      if (!this.currentCampaign || !activeCampaign) {
        this.campaignHasAdvertising = false;
        return;
      }

      if (this.currentCampaign.is_closed || activeCampaign.is_closed) {
        this.campaignHasAdvertising = false;
        return;
      }

      await this.fetchCampaignStats(activeCampaign);

      this.campaignHasAdvertising =
        !!this.currentCampaignStats &&
        this.currentCampaignStats.advert_count_live > 0;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async fetchCampaignStats(activeCampaign) {
    const statsRequest = {
      account_ids: this.workflowAccounts.map((acc) => acc.id),
      since: activeCampaign.created_at,
      until: activeCampaign.closed_at
    };

    await this.api
      .post('stats/marketing', statsRequest)
      .then((res) => this.getCurrentCampaignStats(res.data.by_campaign));
  }

  getCurrentCampaignStats(data) {
    try {
      const cleanStats = [...data.filter((item) => item.campaign)];
      this.currentCampaignStats = cleanStats.find(
        (stats) =>
          !!stats &&
          !!stats.campaign &&
          !!this.currentCampaign &&
          stats.campaign.id === this.currentCampaign.id
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private async updatePermissions() {
    const [user, accounts] = await Promise.all([
      this.userModel.getAuthUser(),
      this.accountModel.findAccounts(this.workflowManager.getCurrentId())
    ]);

    function hasAccountPermission(permissions) {
      const accountIds = accounts.map((account) => [account.id]);
      return user.hasAccountPermission(accountIds, permissions);
    }

    this.permissions = {
      contentCalendar: hasAccountPermission([['post'], ['post_un-validated']]),
      validatePosts: hasAccountPermission([
        ['post'],
        ['post_un-validated'],
        ['validate_posts']
      ]),
      contentGenerator: hasAccountPermission([['post'], ['post_un-validated']]),
      bulkUploadPosts: hasAccountPermission([['post'], ['post_un-validated']]),
      publisher: hasAccountPermission([['post'], ['post_un-validated']]),
      contentLibrary: user.hasCompanyPermission('manage_company_files')
    };
  }

  isOutboxSearchState(): boolean {
    return this.$state.current.name === 'auth.marketing.outboxSearch';
  }
}
