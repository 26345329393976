import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Colleague } from '../../../../../../common/services/api';
import { Outbox, OutboxPublisherMention } from '@ui-resources-angular';

@Component({
  selector: 'ssi-validate-posts-content',
  templateUrl: './validate-posts-content.component.html',
  styleUrls: ['./validate-posts-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidatePostsContentComponent implements OnInit {
  @Input() validation: Outbox | any;
  @Input() user: Colleague;
  @Input() selected: Array<Outbox | any>;
  @Input() selectMode = false;

  @Output() addToSelected = new EventEmitter<void>();

  pipe = '&vert;';
  mentions: OutboxPublisherMention[] = [];

  constructor() {}

  ngOnInit(): void {
    this.mentions = this.validation.getMentions
      ? this.validation.getMentions()
      : this.validation.outbox && this.validation.outbox.getMentions
        ? this.validation.outbox.getMentions()
        : [];
  }

  isSelected(): boolean {
    return this.selected.map((item) => item.id).includes(this.validation.id);
  }
}
