import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { Suggestion } from '@orlo/library/interfaces/suggestion';

@Component({
  selector: 'ssi-outbox-typeahead',
  templateUrl: './outbox-typeahead.component.html',
  styleUrls: ['./outbox-typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutboxTypeaheadComponent implements OnChanges, OnDestroy, OnInit {
  @Input() disabled: boolean;
  @Input() placeholderText = '';
  @Input() shouldIgnoreHotkeys = false;
  @Input() suggestions: Suggestion[] = [];
  @Input() value: Suggestion[] = [];

  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  valueChange: EventEmitter<Suggestion[]> = new EventEmitter<Suggestion[]>();

  @ViewChild('suggestionsFilter') suggestionsFilter: ElementRef;

  _indexOfSelection = -1;
  isFocused = false;
  private _isInitialised = false;
  private _filter = '';
  private _filteredSuggestions: Suggestion[] = [];

  public get areSuggestionsVisible(): boolean {
    return (
      !!this.isFocused &&
      !!this.hasFilter &&
      !!this.filteredSuggestions &&
      !!this.filteredSuggestions.length
    );
  }

  public get choices() {
    return this.value.filter((value) => !!value && !!value.code);
  }

  public get filter(): string {
    return this._filter;
  }

  public set filter(value: string) {
    this._filter = value;

    const filterElement = this.suggestionsFilter
      .nativeElement as HTMLInputElement;
    filterElement.value = value;

    this._indexOfSelection = -1;

    this.emitNotificationOfFilterChange();
  }

  public get filterPattern(): RegExp {
    return new RegExp('^[a-zA-Z0-9ä-ÿ]+$');
  }

  public get filteredSuggestions() {
    return this._filteredSuggestions;
  }

  public get hasChoices(): boolean {
    return !!this.value.length;
  }

  public get hasFilter(): boolean {
    return !!this._filter && !!String(this._filter).length;
  }

  public get indexOfSelection() {
    return this._indexOfSelection;
  }

  public emitNotificationOfFilterChange() {
    this.filterChange.emit(this._filter);
  }

  public isSelectedSuggestionIndex(index: number) {
    return this._indexOfSelection === index;
  }

  async ngOnChanges(changes) {
    const isChoicesChanged: boolean =
      !!changes.choices &&
      (!changes.choices.previousValue ||
        changes.choices.previousValue !== changes.choices.currentValue);
    const isSuggestionsChanged: boolean =
      !!changes.suggestions &&
      (!changes.suggestions.previousValue ||
        changes.suggestions.previousValue !== changes.suggestions.currentValue);
    const isValueChanged: boolean =
      !!changes.value &&
      (!changes.value.previousValue ||
        changes.value.previousValue !== changes.value.currentValue);

    if (isSuggestionsChanged) {
      this.updateSuggestions();
    }

    if (isChoicesChanged || isValueChanged) {
      this.refresh();
    }
  }

  async ngOnDestroy() {}

  async ngOnInit() {
    this.onBlur();
  }

  onBlur() {
    this.isFocused = false;
    this._filteredSuggestions = [];
  }

  async onClose() {
    // event.stopImmediatePropagation();
    // event.preventDefault();

    await this.updateValue();
  }

  onKeydown(event) {
    this.isFocused = true;
    if (
      event.key === 'ArrowDown' &&
      this._indexOfSelection < this.filteredSuggestions.length - 1
    ) {
      this._indexOfSelection = this._indexOfSelection + 1;
    }

    if (event.key === 'ArrowUp' && this._indexOfSelection > 0) {
      this._indexOfSelection = this._indexOfSelection - 1;
    }

    if (event.key === 'Enter') {
      this.toggle(event, this.filteredSuggestions[this._indexOfSelection]);
    }
  }

  onFocus() {
    this.isFocused = true;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!!this.shouldIgnoreHotkeys || this.disabled) {
      return;
    }

    const input = this.suggestionsFilter.nativeElement;
    if (document.activeElement !== input) {
      return;
    }

    switch (event.key) {
      case 'Alt':
      case 'CapsLock':
      case 'Control':
      case 'Ctrl':
      case 'Dead':
      case 'Delete':
      case 'End':
      case 'F1':
      case 'F2':
      case 'F3':
      case 'F4':
      case 'F5':
      case 'F6':
      case 'F7':
      case 'F8':
      case 'F9':
      case 'F10':
      case 'F11':
      case 'F12':
      case 'F13':
      case 'F14':
      case 'F15':
      case 'F16':
      case 'Home':
      case 'Meta':
      case 'PageDown':
      case 'PageUp':
      case 'Shift':
        return false;

      case 'Escape':
        this.value = [];
        this.updateValue();
        return true;
      case 'ArrowLeft':
      case 'ArrowRight':
        return true;

      case 'ArrowDown':
      case 'ArrowUp':
      case 'Enter':
        return;

      case 'Backspace':
      case 'Delete':
        requestAnimationFrame(() => {
          const filterElement = this.suggestionsFilter
            .nativeElement as HTMLInputElement;
          this.filter = filterElement.value;

          this.updateSuggestions();
        });

        return true;

      default:
        // @todo: better trapping of non-alphnumeric characters
        if (!!this.filterPattern.test(event.key.toLocaleLowerCase())) {
          this.updateFilter(event.key);
        }

        return true;
    }
  }

  refresh() {
    this.isFocused = false;
    this.filter = '';
  }

  select(index: number) {
    this._indexOfSelection = index;
  }

  toggle(event: Event, selection: Suggestion): boolean {
    try {
      event.stopImmediatePropagation();

      if (this.disabled || !selection) {
        return;
      }

      const code = typeof selection !== 'string' ? selection.code : selection;

      if (
        !!this.value &&
        Array.isArray(this.value) &&
        this.value.find(
          (value) =>
            String(value.code).toLocaleLowerCase() ===
            String(code).toLocaleLowerCase()
        )
      ) {
        this.value = this.value.filter(
          (value) =>
            String(value.code).toLocaleLowerCase() !==
            String(code).toLocaleLowerCase()
        );
      } else {
        this.value.push(selection);
      }

      this.updateValue();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  updateFilter(character?: string) {
    if (!character) {
      return;
    }

    const previousFilter = '' + this._filter;

    this._filter += character;

    if (this._filter.trim() === previousFilter.trim()) {
      return;
    }

    this._indexOfSelection = -1;
    this.emitNotificationOfFilterChange();
    this.updateSuggestions();
  }

  updateSuggestions() {
    try {
      this._filteredSuggestions = !this._filter.length
        ? this.suggestions
        : this.suggestions.filter((suggestion) => {
            const label = String(suggestion.label).toLocaleLowerCase();

            if (!(!!this.value && Array.isArray(this.value))) {
              return false;
            }

            return (
              !this.value.find(
                (value) =>
                  String(value.code).toLocaleLowerCase() ===
                  String(suggestion.code).toLocaleLowerCase()
              ) && label.indexOf(this._filter.toLocaleLowerCase()) !== -1
            );
          });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      // if (isDebug) {
      //   console.log(`out: outboxTypeahead~>updateSuggestions`);
      // }
    }
  }

  updateValue() {
    this.valueChange.emit(this.choices);
  }
}
