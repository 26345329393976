import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-response-times',
  templateUrl: './response-times.component.html',
  styles: [],
  styleUrls: ['./response-times.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResponseTimesComponent implements OnInit {
  @Input() chartData;
  @Input() socialAccounts;
  @Input() activityStats;
  chartNetworks;

  constructor() {}

  ngOnInit() {
    this.chartNetworks = this.getChartNetworks();
  }

  getChartNetworks() {
    const networksInChart = this.chartData.series[0].data.map(
      (data) => data.name
    );

    return this.socialAccounts.filter((account) => {
      return networksInChart.includes(account.socialNetwork.account_type_name);
    });
  }
}
