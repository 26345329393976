import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ColleaguesService } from '../../../../../../common/services/api';
import { humanizeSeconds } from '../../../../../../common/utils';
import { SecondsToHumanTimePipe } from '../../../../../../common/pipes/seconds-to-human-time/seconds-to-human-time.pipe';

export interface Sla {
  from: number;
  to: number;
  value: number;
}
export interface SlaData {
  by_user: {
    [key: number]: Sla[];
  };
  top_level: Sla[];
}
@Component({
  selector: 'ssi-response-time-sla',
  templateUrl: './response-time-sla.component.html',
  styles: [],
  styleUrls: ['./response-time-sla.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResponseTimeComponent implements OnInit {
  @Input() slaData: SlaData;
  @Input() slaDataPrevious: SlaData;
  dataToDisplay: any[] = [];
  slas: { header: string }[] = [];
  total: number;

  constructor(
    private colleaguesService: ColleaguesService,
    private secondsToHumanTimePipe: SecondsToHumanTimePipe
  ) {}

  async ngOnInit() {
    if (this.slaData && this.slaData.by_user) {
      this.slas = (Object.values(this.slaData.by_user)[0] as any[]).map(
        (sla: Sla) => {
          const to = this.secondsToHumanTimePipe.transform(sla.to, true, true, {
            secondLabel: 's',
            minuteLabel: 'm',
            dayLabel: 'd',
            hourLabel: 'h'
          });
          const from = this.secondsToHumanTimePipe.transform(
            sla.from,
            true,
            true,
            {
              secondLabel: 's',
              minuteLabel: 'm',
              dayLabel: 'd',
              hourLabel: 'h'
            }
          );
          return {
            header: sla.from
              ? sla.to
                ? from + ' - ' + to
                : from + '+'
              : '0s -' + to
          };
        }
      );
    }
    this.dataToDisplay = await this._formatResponseData(this.slaData);
    this.total = this.slaData.top_level.reduce(
      (sum, sla) => sum + sla.value,
      0
    );
  }

  private async _formatResponseData(slaData: SlaData) {
    if (!slaData) {
      return [];
    }
    const allColleagues = await this.colleaguesService.getAll();
    const colleaguesWithData = allColleagues.filter((colleague) =>
      Object.keys(slaData.by_user).includes(colleague.id)
    );
    return colleaguesWithData.map((colleague) => ({
      user: colleague,
      // couldn't get treeable to loop columns and keep context for field
      responseSla0: slaData.by_user[colleague.id].map((sla) => sla.value)[0],
      responseSla1: slaData.by_user[colleague.id].map((sla) => sla.value)[1],
      responseSla2: slaData.by_user[colleague.id].map((sla) => sla.value)[2],
      responseSla3: slaData.by_user[colleague.id].map((sla) => sla.value)[3],
      responseSla4: slaData.by_user[colleague.id].map((sla) => sla.value)[4],
      responseSla5: slaData.by_user[colleague.id].map((sla) => sla.value)[5],
      responseSla6: slaData.by_user[colleague.id].map((sla) => sla.value)[6]
    }));
  }

  secondsToHumanTime(seconds: number): string {
    return humanizeSeconds(seconds);
  }
}
