import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewProfileModalComponent } from './view-profile-modal.component';
import { ProfileInboxStatsModule } from '../profile-inbox-stats/profile-inbox-stats.module';
import { SocialPostModule } from '../social-post/social-post.module';
import { TranslateModule } from '@ngx-translate/core';
import { TriggerPublishModule } from '../../directives/trigger-publish/trigger-publish.module';
import { UniqueModuleModule } from '../../pipes/unique/unique.module';
import { TooltipModule } from '../../../common/directives/tooltip/tooltip.module';
import { GaugeChartModule } from '../gauge-chart/gauge-chart.module';
import { GaugeModule } from 'angular-gauge';
import { PieChartModule } from '../pie-chart/pie-chart.module';
import { CrmPersonNotesModule } from '../crm-person-notes/crm-person-notes.module';
import { CrmPersonTagsModule } from '../crm-person-tags/crm-person-tags.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivityModule } from '../activity/activity.module';
import { ProfileHelperService } from '@orlo/common/services/profile-helper/profile-helper.service';

@NgModule({
  imports: [
    CommonModule,
    ProfileInboxStatsModule,
    SocialPostModule,
    TranslateModule,
    TriggerPublishModule,
    TooltipModule,
    GaugeChartModule,
    GaugeModule.forRoot(),
    PieChartModule,
    CrmPersonNotesModule,
    UniqueModuleModule,
    CrmPersonTagsModule,
    ActivityModule,
    NgbDropdownModule.forRoot(),
    NgbTabsetModule.forRoot()
  ],
  exports: [ViewProfileModalComponent],
  declarations: [ViewProfileModalComponent],
  entryComponents: [ViewProfileModalComponent],
  providers: [ProfileHelperService]
})
export class ViewProfileModalModule {}
