import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Inject } from '@angular/core';
import { Account, OutboxModel } from '@ui-resources-angular';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '../../../../../common/components/publisher/publisher-active';

@Component({
  selector: 'ssi-content-generator-article',
  templateUrl: './content-generator-article.component.html',
  styleUrls: ['./content-generator-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentGeneratorArticleComponent {
  @Input() article;
  @Input() postToAccount: Account;
  @Input() campaignId: string;

  constructor(
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive,
    private outboxModel: OutboxModel
  ) {}

  public createPost(article): void {
    const outboxPosts = this.outboxModel.filter({
      account_id: this.postToAccount.id
    });

    this.publisherActive.next({
      isActive: true,
      create: {
        accounts: [this.postToAccount],
        text: `${article.title} ${article.url}`,
        schedules: [this.outboxModel.getSuggestedPostTime(0, outboxPosts, 3)],
        files: article.img ? [{ url: article.img }] : [],
        activeCampaignID: parseInt(this.campaignId, 10)
      }
    });
  }
}
