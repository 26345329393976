import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  TemplateRef
} from '@angular/core';
import { subMonths } from 'date-fns';
import { Stage } from '../publisher.component';
import { MarketingAnalyticsService } from '../../../services/marketing-analytics/marketing-analytics.service';
import { OutboxPublisher, Campaign } from '@ui-resources-angular';
import { PopupService } from '../../../services/popup/popup.service';
import { DatePipe } from '@angular/common';
import { AsyncTracker } from 'angular-async-tracker';
import { PublisherDisableOptions } from '../publisher-active';

@Component({
  selector: 'ssi-publisher-schedule',
  templateUrl: './publisher-schedule.component.html',
  host: {
    '[class.publisher-secondary-view]': 'true'
  },
  providers: [DatePipe],
  styleUrls: ['./publisher-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublisherScheduleComponent implements OnInit {
  @Input() post: OutboxPublisher;

  @Input() postInvalidTooltipTemplate: TemplateRef<any>;

  @Input() publishingLoadingTracker: AsyncTracker;

  @Input() disable: PublisherDisableOptions;

  @Input() campaigns: Campaign[] = [];

  @Output() changeStage = new EventEmitter();

  @Output() publish = new EventEmitter();

  @Output() showPostPreview = new EventEmitter();

  stages = Stage;

  selectedDate: Date;

  topPostTimes;

  constructor(
    private marketingAnalytics: MarketingAnalyticsService,
    private popup: PopupService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    this.topPostTimes = await this.marketingAnalytics.getTopPostTimes({
      accountIds: this.post.accounts.map((account) => account.id),
      start: subMonths(new Date(), 1),
      end: new Date(),
      includeEmptyValues: true
    });
  }

  addSchedule(date: Date, post: OutboxPublisher) {
    const { isValid, error } = this.post.addSchedule(date, post);
    if (!isValid) {
      if (error.dateInPast) {
        this.popup.alert({
          isError: true,
          message: 'Sorry but that date is in the past'
        });
      } else if (this.post.hasTwitterSelected() && error.dateTooCloseTo) {
        this.popup.alert({
          title: 'Hold up...',
          windowClass: 'modal-style-1',
          iconClass: 'ssi ssi-spam-microcopy',
          okText: 'Okay thanks',
          message: `
            Sorry but the date that you selected is too close to ${this.datePipe.transform(
              error.dateTooCloseTo,
              'medium'
            )}. 
            It must be at least 7 days apart from other scheduled times to prevent social network spamming rules.
          `
        });
      } else if (error.maxSchedulesReached) {
        this.popup.alert({
          isError: true,
          message: `Sorry but you can only add a maximum of ${this.post.scheduleCount.maximum} schedules`
        });
      }
    } else {
      this.selectedDate = null;
    }
  }
}
