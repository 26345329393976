import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSelectComponent } from './list-select.component';
import { ListSelectOptionComponent } from './list-select-option/list-select-option.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ListSelectComponent, ListSelectOptionComponent],
  exports: [ListSelectComponent, ListSelectOptionComponent]
})
export class ListSelectModule {}
