import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'web-app-angular';

  constructor(protected state: StateService) {}

  // login() {
  //   this.state.go('entry.landing.login');
  // }
}
