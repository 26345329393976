import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';
import { serverSettings, companyConfig } from '../../../../common-resolves';
import { StateService } from '@uirouter/angular';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';

@Component({
  selector: 'ssi-internationalization',
  templateUrl: './internationalization.component.html',
  styles: [],
  styleUrls: ['./internationalization.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InternationalizationComponent implements OnInit {
  static resolve = [serverSettings, companyConfig];
  @Input() serverSettings;
  @Input() companyConfig;

  loadingTracker = this.asyncTrackerFactory.create();

  internationalizationForm: FormGroup;

  constructor(
    private state: StateService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private asyncTrackerFactory: AsyncTrackerFactory
  ) {}

  async ngOnInit() {
    this.internationalizationForm = this.fb.group({
      currency_code: [this.companyConfig.currency_code]
    });
  }

  async saveInternationalization() {
    const promise = this.companyService.saveConfig(
      this.internationalizationForm.value
    );
    this.loadingTracker.add(promise);
    this.return();
  }

  return() {
    this.state.go('^');
  }
}
