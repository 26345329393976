import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ssi-highchart-controls',
  templateUrl: './highchart-controls.component.html',
  styles: [],
  styleUrls: ['./highchart-controls.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HighchartControlsComponent {
  @Input() chartSearchDates: any;
  @Input() chartDefaultStartDate: string;
  @Input() now: string;
  @Input() reportMode: boolean;
  @Output() chartChangeDate = new EventEmitter();
  @Output() chartChangeDateCustom = new EventEmitter();

  showSearch = false;
  display = 'lastMonth';

  constructor() {}

  toggleSearchBox() {
    this.showSearch = !this.showSearch;
    this.display = this.showSearch ? 'search' : '';
  }
}
