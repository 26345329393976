export class PromiseQueue {
  private queue: (() => Promise<any>)[] = [];
  private pendingCount = 0;
  private readonly maxPending: number;

  constructor(maxPending: number = 1) {
    if (maxPending < 1) {
      throw new Error('maxPending must be greater than 0');
    }
    this.maxPending = maxPending;
  }

  async add<T>(task: () => Promise<T>): Promise<T> {
    // If we can process immediately, do so
    if (this.pendingCount < this.maxPending && this.queue.length === 0) {
      return this.runTask(task);
    }

    // Otherwise, add to queue and wait
    return new Promise((resolve, reject) => {
      this.queue.push(async () => {
        try {
          const result = await task();
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });

      // Try to process queue if possible
      this.processQueue();
    });
  }

  private async runTask<T>(task: () => Promise<T>): Promise<T> {
    this.pendingCount++;
    try {
      return await task();
    } finally {
      this.pendingCount--;
      this.processQueue();
    }
  }

  private async processQueue(): Promise<void> {
    while (this.queue.length > 0 && this.pendingCount < this.maxPending) {
      const task = this.queue.shift();
      if (task) {
        // Don't await here - we want to process tasks in parallel up to maxPending
        this.runTask(task);
      }
    }
  }

  public get pending(): number {
    return this.pendingCount;
  }

  public get queued(): number {
    return this.queue.length;
  }
}
