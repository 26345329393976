import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { PublishSettingsComponent } from './publish-settings.component';
import { OrloComponentsModule } from 'orlo-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsModule } from '@orlo/common/components/alerts/alerts.module';
import { ToggleSwitchModule } from '@orlo/common/components/toggle-switch/toggle-switch.module';
import { TooltipModule } from '@orlo/common/directives/tooltip/tooltip.module';
import { OffClickModule } from '@orlo/common/directives/off-click/off-click.module';
import { CheckboxModule } from '@orlo/common/components/checkbox/checkbox.module';
import { ConfirmActionModule } from '@orlo/common/directives/tooltip/confirm-action/confirm-action.module';

@NgModule({
  imports: [
    CommonModule,
    OrloComponentsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.settings.companysettings.publishSettings',
          url: '/publish-settings',
          component: PublishSettingsComponent,
          resolve: PublishSettingsComponent.resolve
        }
      ]
    }),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AlertsModule,
    ToggleSwitchModule,
    TooltipModule,
    OffClickModule,
    CheckboxModule,
    ConfirmActionModule
  ],
  declarations: [PublishSettingsComponent],
  entryComponents: [PublishSettingsComponent]
})
export class PublishSettingsModule {}
