import { ViewEncapsulation } from '@angular/core';


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AccountModel, ActivityStatus } from '@ui-resources-angular';
import { widgetTypes } from '../../../constants';
import {
  KeyValueObject,
  mapToIterable
} from '../../../../../../../common/utils';
import {
  InboxSortField,
  InboxSortOrder
} from '../../../../../../../common/components/filters';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';

export interface InboxMessagesConfig {
  liveChatTally: boolean;
}

export interface InboxMessagesConfigQuery {
  sort: InboxSortField;
  order: InboxSortOrder;
  status?: ActivityStatus[];
  assigned?: string;
}

export interface Option {
  key: string;
  label: string;
  icon?: string;
  query?: KeyValueObject;
}

@Component({
  selector: 'ssi-widget-inbox-message-config',
  templateUrl: './widget-inbox-message-config.component.html',
  styles: [],
  styleUrls: ['./widget-inbox-message-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetInboxMessageConfigComponent implements OnInit {
  @Input() widget: DashboardWidget;
  @Output() selectedPresetChange = new EventEmitter<Option>();

  widgetTypes = widgetTypes;
  widgetTitleMaxLength = 60;

  sortOptions: KeyValueObject<Option> = this.getSortOptions();
  sortOptionsIterable: Option[] = mapToIterable(this.sortOptions);
  selectedSortOption: Option = this.sortOptions.newest;

  presets: KeyValueObject<Option> = this.getPresets();
  presetsIterable: Option[] = mapToIterable(this.presets);
  selectedPreset: Option = this.presets.all;

  constructor(
    protected translate: TranslateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  async ngOnInit() {
    if (!this.widget.config) {
      // new widget - set default config
      this.widget.config = {
        liveChatEnabled: false
      };
    }

    this.parseApiSearchParams();
  }

  parseApiSearchParams(): void {
    if (!this.widget.apiSearchParams) {
      // new widget - set default search params
      this.widget.apiSearchParams = {
        // sort: InboxSortField.Date,
        // order: InboxSortOrder.Desc
      };
    } else {
      // edit mode - re-populate fields
      this.selectedSortOption = this.sortOptionsIterable.find((option) => {
        return (
          option.query.sort === this.widget.apiSearchParams.sort &&
          option.query.order === this.widget.apiSearchParams.order
        );
      });

      if (
        this.widget.apiSearchParams.assigned ===
        this.presets.assignedToMe.query.assigned
      ) {
        this.selectedPreset = this.presets.assignedToMe;
      }
      if (
        JSON.stringify(this.widget.apiSearchParams.status) ===
        JSON.stringify(this.presets.unactioned.query.status)
      ) {
        this.selectedPreset = this.presets.unactioned;
      }
    }
  }

  /* accessed outside of this component - from the parent */
  public getConfig(): InboxMessagesConfig {
    // this.widget.config.liveChatEnabled is already bound to the checkbox, just return the config as it is
    return this.widget.config as any;
  }

  /* accessed outside of this component - from the parent */
  public getQuery(): InboxMessagesConfigQuery {
    return {
      ...this.selectedSortOption.query,
      ...this.selectedPreset.query
    } as InboxMessagesConfigQuery;
  }

  getPresets(): KeyValueObject<Option> {
    return {
      all: {
        key: 'all',
        label: this.translate.instant('ALL_MESSAGES'),
        icon: 'ssi ssi-all-messages-new',
        query: {
          assigned: null,
          status: [
            ActivityStatus.Unactioned,
            ActivityStatus.Unread,
            ActivityStatus.Actioned
          ]
        }
      },
      assignedToMe: {
        key: 'assignedToMe',
        label: this.translate.instant('ASSIGNED_TO_ME'),
        icon: 'ssi ssi-assigned',
        query: {
          assigned: 'me',
          status: [
            ActivityStatus.Unactioned,
            ActivityStatus.Unread,
            ActivityStatus.Actioned
          ]
        }
      },
      unactioned: {
        key: 'unactioned',
        label: this.translate.instant('UNACTIONED'),
        icon: 'ssi ssi-unactioned',
        query: {
          assigned: null,
          status: [ActivityStatus.Unactioned, ActivityStatus.Unread]
        }
      }
    };
  }

  getSortOptions(): KeyValueObject<Option> {
    return {
      smart: {
        key: 'smart',
        label: this.translate.instant('SMART_SORT'),
        query: {
          sort: InboxSortField.Smart,
          order: InboxSortOrder.Desc
        }
      },
      newest: {
        key: 'newest',
        label: this.translate.instant('SORT_BY_NEWEST'),
        query: {
          sort: InboxSortField.Date,
          order: InboxSortOrder.Desc
        }
      },
      oldest: {
        key: 'oldest',
        label: this.translate.instant('SORT_BY_OLDEST'),
        query: {
          sort: InboxSortField.Date,
          order: InboxSortOrder.Asc
        }
      },
      priorityNewest: {
        key: 'priorityNewest',
        label: this.translate.instant('SORT_BY_HIGHEST_PRIORITY_NEWEST'),
        query: {
          sort: InboxSortField.Priority,
          order: InboxSortOrder.Desc
        }
      },
      priorityOldest: {
        key: 'priorityOldest',
        label: this.translate.instant('SORT_BY_LOWEST_PRIORITY_NEWEST'),
        query: {
          sort: InboxSortField.Priority,
          order: InboxSortOrder.Asc
        }
      }
    };
  }

  onSelectedSortOptionChange(option: Option): void {
    this.selectedPresetChange.emit(option);
  }

  onSelectedPresetChange(preset: Option): void {
    this.selectedPresetChange.emit(preset);
  }
}
