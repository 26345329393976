import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-publisher-expiry-modal',
  templateUrl: './publisher-expiry-modal.component.html',
  styleUrls: ['./publisher-expiry-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublisherExpiryModalComponent implements OnInit {
  @Input() actionButtonText: string;
  @Input() startDate: Date = new Date();
  @Input() expiryDate: Date;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.expiryDate) {
      this.startDate = this.expiryDate;
    }
  }
}
