import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Agent } from '@orlo/library/models/live-chat/agent';
import { Conversation as ConversationModel } from '@orlo/library/models/live-chat/conversation';
import { get_relative_age_of_date } from '@orlo/library/helpers/datetimes';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

import {
  active as stringActive,
  assigned as stringAssigned,
  oldest as stringOldest,
  total as stringTotal,
  unassigned as stringUnassigned
} from '@orlo/library/constants/strings';

@Component({
  selector: 'ssi-live-chat-team-statistics',
  templateUrl: './live-chat-team-statistics.component.html',
  styles: [],
  styleUrls: ['./live-chat-team-statistics.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatTeamStatisticsComponent {
  @Input() quantityOfAssignedConversations: number;
  @Input() quantityOfTotalConversations: number;
  @Input() quantityOfUnassignedConversations: number;
  @Input() stalestConversation: ConversationModel;
  @Input() teams: any;
  @Input() public agents: Agent[] = [];

  public chatStatistics = [
    {
      filter: stringTotal,
      icon: `ssi ssi-headphones`,
      label: `TOTAL_ONGOING_CHATS`,
      values: stringTotal
    },

    {
      filter: stringUnassigned,
      icon: `ssi ssi-total-engagement`,
      label: `UNASSIGNED_CHATS`,
      values: stringUnassigned
    },

    {
      filter: stringAssigned,
      icon: `ssi ssi-average-response-time`,
      label: `ASSIGNED_CHATS`,
      values: stringAssigned
    },

    {
      icon: `ssi ssi-groups`,
      label: `ACTIVE_AGENTS`,
      values: stringActive
    },

    {
      icon: `ssi ssi-experience`,
      label: `LONGEST_WAITING`,
      values: stringOldest
    }
  ];

  constructor(private liveChatService: LiveChatService) {}

  public get chatStatisticsValues() {
    return {
      active: this.liveChatService.quantityOfActiveAgents,
      assigned: this.liveChatService.quantityOfAssignedConversations,
      oldest: !this.stalestConversation
        ? '-'
        : get_relative_age_of_date(this.stalestConversation.visitorWaitingTime),
      total: this.liveChatService.quantityOfTotalConversations,
      unassigned: this.liveChatService.quantityOfUnassignedConversations
    };
  }

  public toggleStatisticFilter(statistic) {
    if (statistic.filter) {
      this.liveChatService.activeFilter = statistic.filter;
    }
  }
}
