import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Inject, OnInit } from '@angular/core';
import { Account, OutboxModel } from '@ui-resources-angular';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '../../../../../common/components/publisher/publisher-active';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkifyPipe } from '../../../../../common/pipes/linkify/linkify.pipe';

@Component({
  selector: 'ssi-rss-feed-article',
  templateUrl: './rss-feed-article.component.html',
  styleUrls: ['./rss-feed-article.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RssFeedArticleComponent implements OnInit {
  @Input() article;
  @Input() postToAccount: Account;
  @Input() viewClass: string;
  @Input() campaignId: string;

  expandedView = false;
  formattedContent: string;
  descriptionCropped = false;

  constructor(
    @Inject(PUBLISHER_ACTIVE) private publisherActive: PublisherActive,
    private outboxModel: OutboxModel,
    public activeModal: NgbActiveModal,
    protected modal: NgbModal,
    protected linkifyPipe: LinkifyPipe
  ) {}

  ngOnInit() {
    this.formattedContent = this.formatContent();
  }

  public createPost(article): void {
    const outboxPosts = this.outboxModel.filter({
      account_id: this.postToAccount.id
    });

    this.publisherActive.next({
      isActive: true,
      create: {
        accounts: [this.postToAccount],
        text: `${article.title} ${article.url}`,
        schedules: [this.outboxModel.getSuggestedPostTime(0, outboxPosts, 3)],
        files: article.img ? [{ url: article.img }] : [],
        activeCampaignID: parseInt(this.campaignId, 10)
      }
    });
  }

  formatContent(): string {
    const maxLength = 200;
    const description = this.article.description || '';

    function canCrop(text: string, index: number): boolean {
      const char = text[index];
      return char === '\n' || char === ' ';
    }

    if (this.expandedView || description.length <= maxLength) {
      return this.linkifyPipe.transform(description);
    }

    // crop content on white space only (avoid breaking links, mentions, hashtags, etc.)
    // find the first space before maxLength and crop content there
    for (let i = maxLength - 1; i >= 0; i--) {
      if (canCrop(description, i) && !canCrop(description, i - 1)) {
        const croppedDescription = `${description.substring(0, i)}...`;
        this.descriptionCropped = true;
        return this.linkifyPipe.transform(croppedDescription);
      }
    }

    return '';
  }

  readMore(): void {
    this.expandedView = true;
    this.formattedContent = this.formatContent();
  }

  readLess(): void {
    this.expandedView = false;
    this.formattedContent = this.formatContent();
  }
}
