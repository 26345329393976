import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  ViewEncapsulation
} from '@angular/core';

import { Message } from '@orlo/library/models/live-chat/message';
import { Person } from '@orlo/library/models/live-chat/person';
import { Robot } from '@orlo/library/models/live-chat/robot';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { LANGUAGES } from '../../../constants';

// ----------------

const isDebug = false;

// ----------------

@Component({
  selector: 'ssi-live-chat-message',
  templateUrl: './live-chat-message.component.html',
  styles: [],
styleUrls: ['./live-chat-message.scss'],
encapsulation: ViewEncapsulation.None
})
export class LiveChatMessageComponent implements OnChanges {
  @Input() public isSelected: boolean = false;
  @Input() public message: Message;
  @Input() public translationMode: boolean = false;

  @Output()
  public openNotes: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public openTags: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public translationDetectedLanguage: EventEmitter<string> = new EventEmitter<string>();

  public translated: boolean = false;
  public translation;
  private languages = LANGUAGES;

  constructor(
    private liveChatService: LiveChatService,
    private translationService: TranslationService
  ) {}

  public get applicationName(): string {
    return this.liveChatService.activeApplication.name;
  }

  public get author(): Person | Robot {
    if (!this.message) {
      return;
    }

    if (!!this.message.hasRobot) {
      return this.message.author;
    }

    // pass the 'full' visitor object along, preserving metadata.
    // @todo: maybe set on message object when locally created rather than here?

    const author: Person = this.message.author as Person;

    if (this.message.hasVisitor) {
      return this.liveChatService.activeConversation.visitor;
    }

    if (this.message.hasAgent) {
      return this.liveChatService.getFullModelForPerson(author);
    }
  }

  public get networkStateForAuthor(): string {
    if (!this.message || !!this.message.fromRobot) {
      return;
    }

    const author = this.author as Person;

    if (!author) {
      return;
    }

    return author.networkStateValue;
  }

  public ngOnChanges(changes) {
    try {
      if (isDebug) {
        console.log(`messages changes:`);
        console.dir(changes);
      }
      if (changes.translationMode) {
        if (changes.translationMode.currentValue && !this.translation) {
          this.translateMessage();
        } else {
          this.translation = null;
        }
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public onClickNotes(): void {
    this.openNotes.emit(this.message.id);
  }

  public onClickTags(): void {
    this.openTags.emit(true);
  }

  public translateMessage() {
    this.translationService
      .translateText({ text: this.message.text })
      .then((translations) => {
        this.translation = translations[0];
        if (this.translation.detectedSourceLanguage !== 'en') {
          this.translationDetectedLanguage.emit(
            this.translation.detectedSourceLanguage
          );
        }
      });
  }

  public fetchLanguageTranslation() {
    return this.languages[this.translation.detectedSourceLanguage]
      ? this.languages[this.translation.detectedSourceLanguage]
      : `'${this.translation.detectedSourceLanguage}'`;
  }
}
