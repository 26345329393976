import { ViewEncapsulation } from '@angular/core';


import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { uniqBy } from 'lodash-es';
import { format } from 'date-fns';
import { deepValue } from '@jsier/deep-value';

import {
  Account,
  AccountModel,
  AccountType,
  AutoComment,
  MediaRestrictions,
  Outbox,
  OutboxModel,
  OutboxPublisher,
  OutboxPublisherFile,
  OutboxPublisherMention,
  SocialNetwork,
  socialNetworkSettings
} from '@ui-resources-angular';
import { ApiService } from '../../services/api';
import { SocialPostCharactersRemainingPipe } from '../../pipes/social-post-characters-remaining/social-post-characters-remaining.pipe';
import { ReplyBoxComponent } from '../reply-box/reply-box.component';
import { UserPreferencesService } from '../../services/user-preferences/user-preferences.service';
import { PopupService } from '../../services/popup/popup.service';
import { CompanyService } from '../../services/company/company.service';
import { TranslationService } from '../../services/translation/translation.service';
import { WorkflowManagerService } from '../../services/workflow-manager/workflow-manager.service';
import { PredictedResponseService } from '../../services/predicted-response/predicted-response.service';
import { FilestackService } from '../../services/filestack/filestack.service';
import { AccountTypeName } from '../../enums';

export interface ActivityReplyUser {
  name: string;
  username: string;
  id: string;
  excluded: boolean;
}

export interface ShareFirstCommentError {
  key: string;
  account: Account;
  message: string;
}

@Component({
  selector: 'ssi-share-first-comment',
  templateUrl: './share-first-comment.component.html',
  styles: [],
  styleUrls: ['./share-first-comment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareFirstCommentComponent implements OnInit {
  @Input() outboxPost?: Outbox;
  @Input() composer?: OutboxPublisher;

  @ViewChild(ReplyBoxComponent) replyBox: ReplyBoxComponent;

  prepopulateReply?: AutoComment;
  mediaRestrictions = new MediaRestrictions();
  isSplit = false;
  splitPostAccount: Account;
  combinedComment: AutoComment = {
    text: '',
    image: '', // url
    file: null
  };
  splitComment: { [key: string]: AutoComment } = {
    // acoountId: {text: '', image: '', file: null}
  };

  post: {
    text: string;
    account: Account;
    outbox_files: OutboxPublisherFile[];
    mentions: OutboxPublisherMention[];
  } = {
    text: '',
    account: undefined,
    outbox_files: [],
    mentions: []
  };

  reply: {
    tracker: AsyncTracker;
    text?: string;
    account?: Account;
    visible?: boolean;
    add_dm_reply_link?: boolean;
    file?: any;
    link?: any;
    totalIncludedUsers: number;
    users: ActivityReplyUser[];
  } = {
    tracker: this.asyncTracker.create(),
    users: [],
    totalIncludedUsers: 0,
    visible: true,
    text: ''
  };

  translation: {
    translatedText: string;
    detectedSourceLanguage: string;
    activity: {
      account: Account;
      interaction: {
        content: string;
        entities: any;
      };
    };
  };

  errors: ShareFirstCommentError[] = [];

  signature: string;

  private _contentText: string = '';
  private _isPredictive: boolean = false;
  private _prediction: string = '';
  private _previousText: string = '';

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected api: ApiService,
    protected popup: PopupService,
    protected company: CompanyService,
    protected translate: TranslateService,
    protected outboxModel: OutboxModel,
    protected accountModel: AccountModel,
    protected asyncTracker: AsyncTrackerFactory,
    protected filestackService: FilestackService,
    protected userPreferences: UserPreferencesService,
    protected translationService: TranslationService,
    protected workflowManager: WorkflowManagerService,
    protected predictedResponseService: PredictedResponseService,
    protected socialPostCharactersRemainingPipe: SocialPostCharactersRemainingPipe
  ) {}

  ngOnInit() {
    // to set up the post
    if (this.outboxPost) {
      this.post = this.outboxPost as any;
      this.post.mentions = this.outboxPost.getMentions();
    } else if (this.composer) {
      this.post.account = this.composer.accounts[0];
      this.post.text = this.composer.getTextForAccount(this.post.account);
      this.post.outbox_files = this.composer.getFilesForAccount(
        this.post.account
      );
      this.post.mentions = this.composer.getMentionsForAccount(
        this.post.account
      );
    } else {
      throw new Error(
        'Either outbox post or publisher instance must be provided!'
      );
    }

    // to set up the reply
    if (this.outboxPost) {
      if (
        Array.isArray(this.outboxPost.auto_comment) &&
        this.outboxPost.auto_comment[0]
      ) {
        this.combinedComment = this.outboxPost.auto_comment[0];
        this.setFileMetaIfMissing(this.combinedComment);
      }
      this.isSplit = false;
      this.setSplitPostAccount(undefined);
    } //
    else if (this.composer) {
      const autoCommentAccountIds = Object.keys(
        this.composer.autoCommentByAccountId
      );
      if (autoCommentAccountIds.length) {
        if (autoCommentAccountIds.length === 1) {
          // single account (edit/copy post)
          this.combinedComment = this.composer.autoCommentByAccountId[
            autoCommentAccountIds[0]
          ];
          this.setFileMetaIfMissing(this.combinedComment);
          this.isSplit = false;
          this.setSplitPostAccount(undefined);
        } else {
          // handle mutiple accounts (draft post)
          autoCommentAccountIds.forEach((id) => {
            this.splitComment[id] = this.composer.autoCommentByAccountId[id];
            this.setFileMetaIfMissing(this.splitComment[id]);
          });
          this.isSplit = true;
          this.setSplitPostAccount(this.composer.accounts[0]);
        }
      } else {
        // new post
        this.isSplit = false;
        this.setSplitPostAccount(undefined);
      }
    } //
    else {
      throw new Error(
        'Either outbox post or publisher instance must be provided!'
      );
    }

    if (!this.reply.text) {
      this.userPreferences.getPreferences().then((userPreferences) => {
        this.signature = userPreferences.inbox_signature
          ? `${userPreferences.inbox_signature}`
          : '';
        this.reply.text = this.signature
          ? this.reply.text + ' ' + this.signature
          : this.reply.text;
      });
    }
  }

  setIsSplit(isSplit: boolean): void {
    this.isSplit = isSplit;

    if (this.isSplit) {
      this.composer.accounts.forEach((account) => {
        this.splitComment[account.id] = { ...this.combinedComment };
      });
      this.setSplitPostAccount(this.composer.accounts[0]);
    } //
    else {
      this.combinedComment = { ...this.splitComment[this.splitPostAccount.id] };
      this.splitComment = {};
      this.setSplitPostAccount(undefined);
    }
  }

  setSplitPostAccount(account: Account): void {
    this.splitPostAccount = account;

    if (this.isSplit) {
      this.reply.account = this.splitPostAccount;

      this.reply.text = this.splitComment[this.splitPostAccount.id].text;
      this.reply.file = this.splitComment[this.splitPostAccount.id].file;
    } else {
      this.reply.account = this.composer
        ? this.composer.accounts[0]
        : this.outboxPost.account;

      this.reply.text = this.combinedComment.text;
      this.reply.file = this.combinedComment.file;
    }
  }

  async setFileMetaIfMissing(autoComment: AutoComment): Promise<void> {
    // edit first comment mode (bakcend only saves the image url).. get the file metadata from filestack..
    if (autoComment.file || !autoComment.image) {
      // file meta already exists or no image set
      return;
    }

    autoComment.file = {
      url: autoComment.image,
      // type: '',
      filename: '',
      mimetype: ''
    };

    try {
      const file = await this.filestackService.getFileMetadata(
        autoComment.image
      );
      autoComment.file = file as OutboxPublisherFile;
    } catch (error) {
      console.error('Error getting file metadata: ', error);
    }
  }

  getMediaRestrictions(): any {
    if (this.isSplit) {
      return this.mediaRestrictions.forAccount(this.splitPostAccount, 'reply');
    }

    const accounts = this.outboxPost
      ? [this.outboxPost.account]
      : this.composer.accounts;

    const socialNetworks: Array<{
      config: SocialNetwork;
      accounts: Account[];
    }> = Object.entries(socialNetworkSettings)
      .map(([key, config]) => ({
        config,
        accounts: accounts.filter(
          (account) => account.account_type_name === key
        )
      }))
      .filter((network) => network.accounts.length > 0);

    return this.mediaRestrictions.combine(socialNetworks, 'reply');
  }

  getnetworkReplyOptions(): any {
    if (this.isSplit) {
      return this.splitPostAccount.socialNetwork.activity;
    }

    const accounts = this.outboxPost
      ? [this.outboxPost.account]
      : this.composer.accounts;

    const socialNetworks: Array<{
      config: SocialNetwork;
      accounts: Account[];
    }> = Object.entries(socialNetworkSettings)
      .map(([key, config]) => ({
        config,
        accounts: accounts.filter(
          (account) => account.account_type_name === key
        )
      }))
      .filter((network) => network.accounts.length > 0);

    const networkReplyOptions = {
      responseMedia: {
        textOptional: socialNetworks.every((network) =>
          deepValue(network, 'config.activity.responseMedia.textOptional')
        ),
        image: {
          private: socialNetworks.every((network) =>
            deepValue(network, 'config.activity.responseMedia.image.private')
          ),
          public: socialNetworks.every((network) =>
            deepValue(network, 'config.activity.responseMedia.image.public')
          )
        }
      }
    };

    return networkReplyOptions;
  }

  getMaxCharsCount = (account?: Account): number => {
    if (account) {
      return socialNetworkSettings[account.account_type_name].maxPostCharacters[
        'reply'
      ];
    }

    if (this.isSplit) {
      return socialNetworkSettings[this.splitPostAccount.account_type_name]
        .maxPostCharacters['reply'];
    }

    // get the minimum max characters allowed for all selected accounts (combined)
    const accounts = this.outboxPost
      ? [this.outboxPost.account]
      : this.composer.accounts;

    let maxCharsAllowed = Infinity;
    accounts.forEach((a) => {
      const charsAllowed =
        socialNetworkSettings[a.account_type_name].maxPostCharacters['reply'];
      if (charsAllowed && charsAllowed < maxCharsAllowed) {
        maxCharsAllowed = charsAllowed;
      }
    });

    return maxCharsAllowed;
  };

  canPublish(): boolean {
    if (this.outboxPost && this.prepopulateReply) {
      if (
        this.reply.text === this.prepopulateReply.text &&
        (this.reply.file && this.reply.file.url) == this.prepopulateReply.image //tslint:disable-line
      ) {
        // edit mode - user didn't make any changes
        return false;
      }
    }

    return this.replyBox && this.replyBox.canReply();
  }

  onUpdateReplyText(text: string) {
    // this.replyTextChanged();
    this.reply.text = text;

    if (this.isSplit) {
      this.splitComment[this.splitPostAccount.id].text = text;
    } else {
      this.combinedComment.text = text;
    }
  }

  onFileUploadSuccess(file: any) {
    this.reply.file = file;

    if (this.isSplit) {
      this.splitComment[this.splitPostAccount.id].file = file;
      this.splitComment[this.splitPostAccount.id].image = file.url;
    } else {
      this.combinedComment.file = file;
      this.combinedComment.image = file.url;
    }
  }

  onRemoveAttachment() {
    this.reply.file = null;

    if (this.isSplit) {
      this.splitComment[this.splitPostAccount.id].file = null;
      this.splitComment[this.splitPostAccount.id].image = '';
    } else {
      this.combinedComment.file = null;
      this.combinedComment.image = '';
    }
  }

  translateReplyText() {
    this.translationService
      .translateText({
        text: this.reply.text,
        target: this.translation.detectedSourceLanguage
      })
      .then((translations) => {
        this.reply.text = translations[0].translatedText;
        this.onUpdateReplyText(this.reply.text);
      });
  }

  addPrivateReplyLink() {
    if (this.reply.account.socialNetwork.addPrivateReplyLinkAsAttachment) {
      this.reply.add_dm_reply_link = !this.reply.add_dm_reply_link;
    } else {
      const replyLink: string = this.reply.account.socialNetwork.getPrivateReplyLink(
        this.reply.account
      );
      const replyText = this.reply.text || '';
      if (!replyText.includes(replyLink)) {
        this.reply.text = [replyText, replyLink]
          .filter((part) => !!part)
          .join(' ');

        this.onUpdateReplyText(this.reply.text);
      }
    }
  }

  /* public - accessed outside of this component (from the parent) */
  public async addOrEditFirstComment(): Promise<void> {
    this.errors = this.validateFirstComments();
    if (this.errors.length) {
      return;
    }

    if (this.outboxPost) {
      // edit/delete scheduled comment
      const comment: AutoComment = {
        text: this.reply.text ? this.reply.text : null,
        image: this.reply.file ? this.reply.file.url : null
      };
      await this.editOrDeleteFirstComment(comment);
      return;
    }

    // create/edit scheduled post
    this.composer.autoCommentByAccountId = {};

    if (this.isSplit) {
      this.composer.accounts
        .filter(
          (a) =>
            !!(this.splitComment[a.id].text || this.splitComment[a.id].image)
        )
        .forEach((a) => {
          this.composer.autoCommentByAccountId[a.id] = {
            text: this.splitComment[a.id].text,
            image: this.splitComment[a.id].image,
            file: this.splitComment[a.id].file
          };
        });
    } else {
      if (this.combinedComment.text || this.combinedComment.image) {
        this.composer.accounts.forEach((a) => {
          this.composer.autoCommentByAccountId[a.id] = {
            text: this.combinedComment.text,
            image: this.combinedComment.image,
            file: this.combinedComment.file
          };
        });
      }
    }
  }

  validateFirstComments(): ShareFirstCommentError[] {
    this.errors = [];

    const accounts = this.outboxPost
      ? [this.reply.account]
      : this.composer.accounts;

    accounts.forEach((account) => {
      const replyText = this.isSplit
        ? this.splitComment[account.id].text
        : this.combinedComment.text;

      if (replyText) {
        // only text is being validated
        const accountErrors = this.validateFirstComment(account, replyText);
        this.errors = this.errors.concat(accountErrors);
      }
    });

    return this.errors;
  }

  validateFirstComment(
    account: Account,
    replyText: string
  ): ShareFirstCommentError[] {
    const errors = [];

    const charactersRemaining = this.socialPostCharactersRemainingPipe.transform(
      replyText,
      this.getMaxCharsCount(account),
      account.account_type_name === AccountType.Twitter
    );
    if (charactersRemaining < 0) {
      errors.push({
        key: 'length',
        account,
        message: `${account.name}: First comment is ${Math.abs(
          charactersRemaining
        )} characters too long.`
      });
    }

    if (
      account.socialNetwork.activity &&
      account.socialNetwork.activity.response &&
      account.socialNetwork.activity.response.validate
    ) {
      const validationResult = account.socialNetwork.activity.response.validate(
        {
          text: replyText
        }
      );
      if (!validationResult.isValid && validationResult.errors) {
        if (validationResult.errors.uppercase) {
          errors.push({
            key: 'uppercase',
            account,
            message: `${account.name}: First comment cannot be all uppercase letters.`
          });
        }

        if (validationResult.errors.hashtags) {
          errors.push({
            key: 'hashtags',
            account,
            message: `${account.name}: First comment cannot contain more than ${validationResult.errors.hashtags.amount.max} hashtags.`
            // message: this.translate.instant(
            //   'YOUR_REPLY_CANNOT_CONTAIN_MORE_THAN__MAXALLOWEDHASHTAGS__HASHTAGS',
            //   {
            //     maxAllowedHashtags: validationResult.errors.hashtags.amount.max
            //   }
            // )
          });
        }

        if (validationResult.errors.links) {
          errors.push({
            key: 'links',
            account,
            message: `${account.name}: First comment cannot contain more than ${validationResult.errors.links.amount.max} links.`
          });
        }
      }
    }

    return errors;
  }

  async editOrDeleteFirstComment(comment: AutoComment | null): Promise<void> {
    if (this.editPostModeAndHasAutoComment()) {
      if (!comment) {
        this.composer.edit.auto_comment = [];
        // TODO: need also to remove auto_comment from the outboxPost (locally).. (it's there even after [reload] - stays cached)
        return;
      }
    }

    if (!this.outboxPost) {
      return;
    }

    const outboxMessage = await this.outboxPost.toOutboxPublisherMessageFormat();

    // delete if no comment passed
    outboxMessage.auto_comment = comment ? [comment] : [];

    const body = { message: outboxMessage };
    const config: any = {
      params: {
        _method: 'PUT'
      }
    };

    const response = await this.api
      .post(`${this.api.url}/outbox_v2/indexOutboxv2`, body, config)
      .toPromise();
    console.log('response: ', response);

    this.outboxPost.auto_comment = comment ? [comment] : [];
  }

  editPostModeAndHasAutoComment(): boolean {
    return !!(
      this.composer &&
      this.composer.edit &&
      Array.isArray(this.composer.edit.auto_comment) &&
      this.composer.edit.auto_comment.length
    );
  }

  // ----
  // predictions stuff
  // ----

  public get messageTextValue(): string {
    return this.reply.text;
  }

  public set messageTextValue(value: string) {
    this.setResponse(value);
  }

  public get prediction(): string {
    return this._prediction;
  }

  public set prediction(value: string) {
    this._prediction = value;
  }

  public get isPredictive() {
    return this._isPredictive;
  }

  public async acceptPrediction(event: KeyboardEvent): Promise<boolean> {
    try {
      const prediction = String(this._prediction);
      const shouldContinue =
        !prediction.trim().length ||
        prediction.trim() === String(this.messageTextValue).trim();

      if (shouldContinue) {
        return false;
      }

      event.preventDefault();

      const shouldAddSpace =
        PredictedResponseService.CharactersRequiringSpaceSuffix.indexOf(
          prediction.substr(-1)
        ) === -1;
      const responseValue = prediction + (shouldAddSpace ? ' ' : '');

      const quantityOfWordsInPrediction: number = prediction
        .slice(this.messageTextValue.length)
        .trim()
        .split(' ').length;

      this.setResponse(responseValue);

      const storageKey = `ls` + format(new Date(), 'DD-MM-YYYY');
      const currentStoredValue: number =
        Number(window.localStorage.getItem(storageKey)) || 0;

      window.localStorage.setItem(
        storageKey,
        String(currentStoredValue + quantityOfWordsInPrediction)
      );

      await this.refreshPredictions(false);

      return true;
    } catch (error) {
      console.error('Error accepting prediction:', error);

      return false;
    }
  }

  public async onKeyDown(event: KeyboardEvent): Promise<boolean> {
    try {
      switch (event.key.toLowerCase()) {
        case 'arrowright':
          {
            const textarea = event.target as HTMLTextAreaElement;
            const cursor = textarea.selectionStart;
            const messageLength = this.reply.text.length;

            if (cursor >= messageLength) {
              return this.acceptPrediction(event);
            }
          }
          break;

        case 'tab':
          {
            return this.acceptPrediction(event);
          }
          break;
      }

      return true;
    } catch (error) {
      console.error('Error handling onKeyDown event:', error);

      return false;
    }
  }

  public async onKeyUp(event: KeyboardEvent): Promise<boolean> {
    try {
      this._previousText = this._contentText;
      this._contentText = this.reply.text;

      this.refreshPredictions(true);

      return true;
    } catch (error) {
      console.error('Error handling onKeyUp event:', error);

      return false;
    }
  }

  public async refreshPredictions(shouldFetchFreshPredictions: boolean) {
    try {
      // clear the prediction to reduce any visual lag
      // if e.g. lots of text is pasted in and the lookup is on a slow connection.

      this._prediction = '';

      if (!this.isPredictive) {
        return;
      }

      this._prediction = await this.predictedResponseService.getPredictionFor(
        this.reply.text,
        shouldFetchFreshPredictions
      );
      // .trim()
      // .replace(
      //   '{{name}}',
      //   this.activity.author.name
      //     ? this.activity.author.name.split(' ')[0]
      //     : ''
      // );

      return true;
    } catch (error) {
      console.error('Error refreshing the predictions', error);

      return false;
    }
  }

  public setResponse(text: string = '') {
    try {
      this.reply.text = text;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  // end of predictions stuff...
}
