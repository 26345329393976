import { ViewEncapsulation } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'ssi-report-sidebar',
  templateUrl: './report-sidebar.component.html',
  styles: [],
  styleUrls: ['./report-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportSidebarComponent implements OnInit {
  @Input() icon: string;
  @Input() color: string;
  @Input() actionList: any[];
  @Input() navList: { label: string; tag: string }[];
  @Input() active: string;

  @Output() navClicked = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
