import { ViewEncapsulation } from '@angular/core';

import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { Subject } from 'rxjs/Subject';
import { Snippet } from '@orlo/library/interfaces/snippet';
import { fadeIn, fadeOut } from '../../../animations';

const ALPHANUMERIC_REGEX = /^[0-9a-zA-Z]+$/;

@Component({
  selector: 'ssi-activity-snippets-dropdown',
  templateUrl: './activity-snippets-dropdown.component.html',
  animations: [
    trigger('fadeInOut', [
      transition('void => *', useAnimation(fadeIn)),
      transition('* => void', useAnimation(fadeOut))
    ])
  ],
  host: {
    // tslint:disable-line
    '[@fadeInOut]': 'true'
  },
  styleUrls: ['./activity-snippets-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivitySnippetsDropdownComponent
  implements OnInit, AfterViewInit {
  @Input() canAdministerCompany = false;
  @Input() snippets: Snippet[];
  @Input() template: TemplateRef<any>;
  @ViewChildren('snippetEl') snippetEl: HTMLLIElement[];

  snippetElements: any[];

  public filteredSnippets: Snippet[] = [];
  public indexOfSelection: number;
  public selection: Subject<any> = new Subject();

  filterTerm = '';
  private _lastKeyPressed: string;

  constructor() {}

  ngOnInit() {
    this._filterSnippets('');
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    event.preventDefault();
    switch (event.key) {
      case 'ArrowDown':
        if (this.indexOfSelection < this.filteredSnippets.length - 1) {
          this.indexOfSelection++;
          this.focusElement(this.indexOfSelection);
        }

        break;

      case 'ArrowUp':
        if (this.indexOfSelection > 0) {
          this.indexOfSelection--;
          this.focusElement(this.indexOfSelection);
        }

        break;

      case 'Backspace':
        if (
          typeof this._lastKeyPressed === 'undefined' ||
          (this._lastKeyPressed === 'Backspace' && !this.filterTerm)
        ) {
          return this.cancel();
        }

        this.filterTerm = this.filterTerm.slice(0, this.filterTerm.length - 1);

        this._filterSnippets(this.filterTerm);

        break;

      case 'Enter':
        this.select(this.filteredSnippets[this.indexOfSelection]);

        break;

      case 'ArrowLeft':
      case 'Escape':
        return this.cancel();

      case 'Alt':
      case 'ArrowRight':
      case 'CapsLock':
      case 'Control':
      case 'Ctrl':
      case 'Dead':
      case 'Delete':
      case 'End':
      case 'F1':
      case 'F2':
      case 'F3':
      case 'F4':
      case 'F5':
      case 'F6':
      case 'F7':
      case 'F8':
      case 'F9':
      case 'F10':
      case 'F11':
      case 'F12':
      case 'F13':
      case 'F14':
      case 'F15':
      case 'F16':
      case 'Home':
      case 'Meta':
      case 'PageDown':
      case 'PageUp':
      case 'Shift':
      case 'Tab':
        return;

      default:
        if (ALPHANUMERIC_REGEX.test(event.key.toLocaleLowerCase())) {
          this.filterTerm += event.key;
          this._filterSnippets(this.filterTerm);
        } else {
          return this.cancel();
        }

        break;
    }

    this._lastKeyPressed = event.key;
  }

  ngAfterViewInit(): void {
    this._getAllListElements();
  }

  private _getAllListElements() {
    this.snippetElements = this.snippetEl.map((el) => {
      return el;
    });
  }

  private _filterSnippets(term: string): void {
    this.filteredSnippets = !term
      ? this.snippets
      : this.snippets.filter(
          (snippet) =>
            snippet.title
              .toLowerCase()
              .indexOf(this.filterTerm.toLowerCase()) !== -1
        );
    this.indexOfSelection = this.filteredSnippets.length ? 0 : undefined;
  }

  public focusElement(index: number) {
    this.snippetElements[index].nativeElement.scrollIntoView();
  }

  public cancel() {
    this.selection.next();
  }

  public isSelected(index: number) {
    return index === this.indexOfSelection;
  }

  public select(snippet: Snippet): void {
    this.selection.next(snippet);
  }
}
