import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-label-tag',
  templateUrl: './label-tag.component.html',
  styleUrls: ['./label-tag.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LabelTagComponent implements OnInit {
  @Input() tagValue: any;
  @Input() bgColor?: string;
  @Input() textColor?: string;

  @Output() onRemoveTag = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    // console.log('labelTag tagValue:', this.tagValue);
  }

  removeTag(tag: object): void {
    this.onRemoveTag.emit(tag);
  }

  getTagName(): string {
    if (!this.tagValue) {
      return 'unknown';
    }
    return (
      this.tagValue.label ||
      this.tagValue.filename ||
      this.tagValue.id ||
      (this.tagValue.file && this.tagValue.file.filename) ||
      'unknown'
    );
  }
}
