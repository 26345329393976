import { Record } from 'js-data';
import { api } from '../../core/services/api';
import { services } from '../../common';
import { Model } from '../../model';
import {
  ColleaguesService,
  Colleague
} from '@orlo/common/services/api';
import { appInjector } from '@orlo/app-injector';

export class Note extends Record {
  id: string;
  content: string;
  created_at: string;
  created_by: string;
  subject: 'crm_person' | 'activity' | 'outbox' | 'draft';
  subject_id: string;

  get author(): Colleague {
    if (!this.created_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.created_by);
  }

  // setters must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set author(c: Colleague) {}

  destroy() {
    return api
      .del('note/index', {
        params: {
          subject: this.subject,
          subject_id: this.subject_id,
          id: this.id
        },
        showLoading: true
      })
      .then((result) => {
        services.models.get<NoteModel>('note').eject(this.id);
        return result;
      });
  }
}

export class NoteModel extends Model<Note> {
  constructor() {
    super('note', {
      endpoint: 'note/index',
      relations: {
        belongsTo: {
          // colleague: {
          //   localKey: 'created_by',
          //   localField: 'author'
          // }
        }
      },
      deserialize(resourceConfig, attrs) {
        if (attrs.data.data) {
          return attrs.data.data;
        }
        return attrs.data;
      },
      recordClass: Note
    });
  }
}

export function noteModelFactory(dataStore?) {
  return services.models.get('note') || new NoteModel();
}
