import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';
import {
  UserModel,
  AccountModel,
  Auth,
  Account,
  WorkflowModel
} from '@ui-resources-angular';
import { Subject } from 'rxjs';
import { NotificationManagerService } from '../../../common/services/notification-manager/notification-manager.service';
import { AuthService } from '../../../common/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

const WORKFLOW_STORAGE_KEY_ID = 'currentWorkflowId';

@Injectable()
export class WorkflowManagerService {
  events = {
    workflowChanged: new Subject<number | undefined>()
  };

  constructor(
    private auth: AuthService,
    private $state: StateService,
    private translate: TranslateService,
    private workflowModel: WorkflowModel,
    private errorHandler: ErrorHandlerService,
    private userModel: UserModel,
    private accountModel: AccountModel,
    private notificationManager: NotificationManagerService,
    private localStorageService: LocalStorageService
  ) {
    'ngInject';
  }

  loadWorkflow(
    workflowId?: number,
    {
      forceRefresh,
      redirectToDashboard
    }: { forceRefresh?: boolean; redirectToDashboard?: boolean } = {}
  ) {
    if (!this.auth.isAuthenticated(true)) {
      this.userModel.logout();
      return Promise.reject();
    }

    return this.workflowModel
      .loadAll(forceRefresh)
      .then(() => {
        return Promise.all([
          this.userModel.getAuthUser(forceRefresh),
          this.accountModel.findAccounts(workflowId) as any
        ]);
      })
      .then(([authUser, accounts]) => {
        this.setCurrentId(workflowId);

        if (accounts.length > 0) {
          // We have loaded a group or permitted number of accounts

          this.notificationManager.updateCurrentWorkflowTotals();
          if (redirectToDashboard) {
            this.$state.go('auth.dashboard', {}, { reload: true });
          }
          const workflowAccountIds = accounts.map((acc: Account) =>
            ['id', 'name', 'account_type_name'].reduce((obj, curr) => {
              obj[curr] = acc[curr];
              return obj;
            }, {})
          );
          localStorage.setItem(
            'workflowAccountIds',
            JSON.stringify(workflowAccountIds)
          );
          return accounts;
        } else if (this.getCurrentId() > 0) {
          // is workflow and has no accounts

          this.setCurrentId();
          this.errorHandler.error({
            message: this.translate.instant('NO_WORKFLOW_ACCOUNTS_ALERT')
          });
          this.loadWorkflow(0);
          return Promise.reject();
        } else if (authUser.hasCompanyPermission('administer_accounts')) {
          // no accounts added

          this.$state.go('auth.manageAccounts');
          return Promise.resolve();
        } else {
          // accounts added but no permissions

          this.errorHandler.error({
            message: this.translate.instant(
              'YOUR_USER_HAS_NO_SOCIAL_ACCOUNTS_ASSIGNED_TO_IT_PLEASE_SPEAK_TO_' +
                'THE_ADMINSTRATOR_OF_YOUR_COMPANY_TO_ENABLE_SOME_PERMISSIONS'
            )
          });

          this.userModel.logout();
          // .finally(() => this.authService.clearJWT());

          return Promise.reject();
        }
      });
  }

  getCurrentId(): number {
    return this.localStorageService.get<number>(WORKFLOW_STORAGE_KEY_ID);
  }

  private setCurrentId(id?: number): void {
    if (!id) {
      this.localStorageService.remove(WORKFLOW_STORAGE_KEY_ID);
    } else {
      this.localStorageService.set(WORKFLOW_STORAGE_KEY_ID, id);
    }
    this.events.workflowChanged.next(id);
  }
}
