import {
  Component,
  Input,
  Inject,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectorRef,
  NgZone,
  OnInit
} from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { AsyncTracker } from 'angular-async-tracker';

import { decodeStateParams } from '@orlo/common/utils';
import { UserModel, Auth } from '@ui-resources-angular';
import { AuthService } from '@orlo/common/services/auth/auth.service';
import { SocketsService } from '@orlo/common/services/sockets/sockets.service';
import { RedirectService } from '@orlo/common/services/redirect/redirect.service';

@Component({
  selector: 'ssi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  @Input() loginLoadingTracker: AsyncTracker;
  @Output() onMessageChange = new EventEmitter();
  @Output() onChangeForm = new EventEmitter();
  twoFactorAuthenticationRequired = false;

  email_address: string;
  password: string;
  two_factor_authentication_code: string;

  loading = false;
  loginSuccess = false;

  decodedStateParams: { [key: string]: any };

  constructor(
    private authService: AuthService,
    private socket: SocketsService,
    private userModel: UserModel,
    private state: StateService,
    private redirect: RedirectService,
    private location: Location,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.decodedStateParams = decodeStateParams(this.state.params);
  }

  async login(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.onMessageChange.emit({ error: null, success: null });
    this.loading = true;
    await this.userModel
      .login(
        this.email_address,
        this.password,
        true,
        this.two_factor_authentication_code
      )
      .then((user) => {
        this.loginSuccess = true;
        this.authCallback(user);
      })
      .catch((error) => {
        if (error.twoFactorAuthRequired) {
          this.twoFactorAuthenticationRequired = true;
        } else {
          console.error(`Unable to login:`, error);
        }
      });
    this.loading = false;
  }

  loginWithGoogle() {
    const promise = this.authService
      .authenticate('google', {
        long_expire: true
      })
      .then((response) => this.authCallback(response))
      .then(() => setTimeout(() => {}, 1000)); // tslint:disable-line

    this.loginLoadingTracker.add(promise);
  }

  loginWithAzure() {
    const promise = this.authService
      .authenticate('azure', {
        long_expire: true
      })
      .then((response) => this.authCallback(response))
      .then(() => setTimeout(() => {}, 1000)); // tslint:disable-line

    this.loginLoadingTracker.add(promise);
  }

  toggleChat(): boolean {
    try {
      window.postMessage(
        { id: '_orlo_369d2bba-098d-4683-b82c-c31d003a243b-widget-toggle' },
        '*'
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private authCallback(user) {
    console.log('LOGIN authCallback, should instantiate socket: ', user);
    this.socket.instantiate();

    if (user.two_factor_authentication_required) {
      this.twoFactorAuthenticationRequired = true;
    } else if (user.is_password_change_required) {
      return this.state.go('auth.settings.mysettings.mypassword');
    } else if (!user.is_policy_agreed_to) {
      return this.state.go('^.^.policy');
    }

    this.authService.setJWT();

    this.redirect.handleSuccessfulLogin(this.decodedStateParams.returnTo);
  }
}
