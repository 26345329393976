import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PublisherLandingComponent } from './publisher-landing.component';
import { DropdownSelectModule } from '../../dropdown-select/dropdown-select.module';
import { DropdownSelect2Module } from '../../dropdown-select-2/dropdown-select-2.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ListSelectModule } from '../../list-select/list-select.module';
import { TooltipModule } from '../../../directives/tooltip/tooltip.module';
import { MediaNavModule } from '../../media-nav/media-nav.module';
import { EnlargeImageModule } from '../../../directives/enlarge-image/enlarge-image.module';
import { ContextMenuModule } from '../../../components/context-menu/context-menu.module';
import { TextInputAutocompleteModule } from '../../../components/text-input-autocomplete';
import { TextInputHighlightModule } from '../../../components/text-input-highlight';
import { EmojiFormControlContainerModule } from '../../../directives/emoji-form-control-container/emoji-form-control-container.module';
import { TruncateModule } from '../../../pipes/truncate/truncate.module';
import { FileUploaderModule } from '../../../directives/file-uploader/file-uploader.module';
import { PublisherActionsAndMediaComponent } from './publisher-actions-and-media/publisher-actions-and-media.component';
import { PublisherTargetingComponent } from './publisher-targeting/publisher-targeting.component';
import { OutboxTypeaheadModule } from '../../outbox-typeahead/outbox-typeahead.module';
import { PublisherActive, PUBLISHER_ACTIVE } from '../publisher-active';
import { Subject } from 'rxjs';
import { PublishModule } from '@orlo/library/ui-resources/angular/modules/publish.module';
import { OffClickModule } from '../../../directives/off-click/off-click.module';
import { EmojiAutocompleteModule } from '../../emoji-autocomplete/emoji-autocomplete.module';
import { IconModule } from '../../icon/icon.module';
import { GifSearchModule } from '../../../directives/gif-search/gif-search.module';
import { ToggleSwitch2Module } from '../../../components/toggle-switch-2/toggle-switch-2.module';
import { AiWritingToolModalComponent } from './ai-writing-tool-modal/ai-writing-tool-modal.component';
import { OrloComponentsModule } from 'orlo-components';
import { Checkbox2Module } from '../../checkbox-2/checkbox-2.module';
import { SpinnerModule } from '../../spinner/spinner.module';
import { MenuModule } from '../../menu/menu.module';
import { OverlayModule } from '@angular/cdk/overlay';

export function publisherActiveFactory(): PublisherActive {
  return new Subject();
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    DropdownSelectModule,
    DropdownSelect2Module,
    ListSelectModule,
    TooltipModule,
    MediaNavModule,
    EmojiFormControlContainerModule,
    TruncateModule,
    TranslateModule,
    FileUploaderModule,
    OutboxTypeaheadModule,
    PublishModule,
    EnlargeImageModule,
    ContextMenuModule,
    TextInputAutocompleteModule,
    TextInputHighlightModule,
    OffClickModule,
    EmojiAutocompleteModule,
    IconModule,
    GifSearchModule,
    ToggleSwitch2Module,
    OrloComponentsModule,
    Checkbox2Module,
    SpinnerModule,
    OverlayModule,
    MenuModule,
    SpinnerModule
  ],
  declarations: [
    PublisherLandingComponent,
    PublisherActionsAndMediaComponent,
    PublisherTargetingComponent,
    AiWritingToolModalComponent
  ],
  providers: [],
  exports: [PublisherLandingComponent]
})
export class PublisherLandingModule {}
