import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { PushNotification } from '@orlo/library/interfaces/push-notifications/push-notification';
import { Permission } from '@orlo/library/types/push-notifications/permission';
import { Subscriber } from 'rxjs/Subscriber';
import strings from '@orlo/library/constants/strings';
import { capitalize } from '@orlo/library/helpers/strings';
import { Subscription } from 'rxjs/Subscription';
import { appInjector } from '../../../app-injector';
import { AccountModel, ProfileModel } from '@ui-resources-angular';
import { PopupService } from '../popup/popup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowManagerService } from '../workflow-manager/workflow-manager.service';
import { ViewProfileModalComponent } from '../../components/view-profile-modal/view-profile-modal.component';

@Injectable({ providedIn: 'root' })
export class ProfileHelperService {
  constructor(
    protected accountModel: AccountModel,
    protected profileModel: ProfileModel,
    protected workflowManager: WorkflowManagerService,
    protected popup: PopupService,
    protected ngbModal: NgbModal
  ) {}

  async viewProfile({
    accountId,
    profileId,
    noteId,
    showInteractions = false,
    loadFeed = false
  }: {
    accountId: string;
    profileId: string;
    noteId?: string;
    showInteractions?: boolean;
    loadFeed?: boolean;
  }): Promise<any> {
    if (!accountId || !profileId) {
      return this.popup.alert({
        isError: true,
        message: 'Sorry, but this profile does not exist'
      });
    }
    const profile = await this.profileModel.findByIdAndAccountId(
      profileId,
      accountId
    );
    const workflowAccounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId()
    );
    console.log('profile:', profile);
    console.log('workflowAccounts:', workflowAccounts);

    const modal = this.ngbModal.open(ViewProfileModalComponent, {
      backdrop: false,
      windowClass: 'modal-scroll-wrapper',
      keyboard: true
    });
    modal.componentInstance.profile = profile;
    modal.componentInstance.workflowAccounts = workflowAccounts;
    modal.componentInstance.noteId = noteId;
    modal.componentInstance.showInteractions = showInteractions;
    modal.componentInstance.loadFeed = loadFeed;
    modal.componentInstance.accountId = accountId;
  }
}
