import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  TemplateRef
} from '@angular/core';
import { Activity, User, UserModel } from '@ui-resources-angular';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

import { Account } from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  InboxKeyboardShortcutsService,
  KeyboardShortcut
} from '../../../services/inbox-keyboard-shortcuts/inbox-keyboard-shortcuts.service';
import { RedactModalComponent } from '../../redact-modal/redact-modal.component';
import { ValidatePostsDissaproveComponent } from '../../../../modules/auth/marketing/validate-posts/validate-posts-box/validate-posts-dissaprove/validate-posts-dissaprove.component';
import { OutboxTagsService } from '../../../services/api/outbox-tags';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { PopupService } from '../../../services/popup/popup.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { AuditEventVerb, AuditEventsService } from '../../../services/api';
import { CompanyService } from '../../../services/company/company.service';

@Component({
  selector: 'ssi-message-actions-inline',
  templateUrl: './message-actions-inline.component.html',
  styleUrls: ['./message-actions-inline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageActionsInlineComponent implements OnInit {
  @Input() activity: Activity;
  @Input() selfPenned: boolean;
  @Input() isInbox: boolean;
  @Input() account: Account;
  @Input() sentiment: number | boolean;
  @Input() sentimentOptions?: number;
  @Input() assignedSuccess?: any;
  @Input() tagsTooltip?: number;
  @Input() activeTags?: string[];
  @Input() showActions?: boolean;
  @Input() showViewNatively: boolean;
  @Input() showDelete: boolean;
  @Input() showRedact: boolean;
  @Input() shouldEnableSentimentShortcut?: boolean;
  @Input() likeConfig: any;
  @Input() hideCommentConfig: any;
  @Input() showConversationAssignTo: boolean;
  @Input() isConvertedToActivity = false;
  @Input() notes?: any;

  @Output() conversationAssignToClicked = new EventEmitter<void>();
  @Output() showActionsChange = new EventEmitter<any>();
  @Output() viewNativelyClicked = new EventEmitter<void>();
  @Output() toggleLikeClicked = new EventEmitter<void>();
  @Output() toggleShowHideCommentClicked = new EventEmitter<boolean>();
  @Output() deleteClicked = new EventEmitter<void>();
  @Output() redactConfirmed = new EventEmitter<void>();
  @Output() hideActivity = new EventEmitter<void>();

  authUser: User;
  approvedActioned = false;
  userCanApprove = false;
  private _keyboardShortcutSubsription: Subscription;
  spamDetectionEnabled?: boolean;

  @ViewChild('disapprovedSnackbarTemplate')
  public disapprovedSnackbarTemplate: TemplateRef<any>;
  @ViewChild('approvedSnackbarTemplate')
  public approvedSnackbarTemplate: TemplateRef<any>;
  @ViewChild('sentimentActionTrigger') sentimentActionTrigger: CdkOverlayOrigin;

  constructor(
    public modal: NgbModal,
    private inboxKeyboardShortcutsService: InboxKeyboardShortcutsService,
    private userModel: UserModel,
    private outboxTagsService: OutboxTagsService,
    private snackbar: SnackbarService,
    private popup: PopupService,
    private notificationService: NotificationService,
    private auditEventsService: AuditEventsService,
    private company: CompanyService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spamDetectionEnabled = await this.company.hasFeatureAccess(
      'SPAM_DETECTION'
    );
    if (this.shouldEnableSentimentShortcut) {
      this._keyboardShortcutSubsription = this.inboxKeyboardShortcutsService.shortcutPressed$.subscribe(
        (key) => {
          if (key === KeyboardShortcut.AmendSentiment) {
            setTimeout(() => {
              this.showActionsChange.emit({
                showActions: !this.showActions,
                origin: this.sentimentActionTrigger,
                activeAction: 'sentiment'
              });
            });
          }
        }
      );
    }

    if (this.isConvertedToActivity) {
      this.authUser = await this.userModel.getAuthUser();
      const hasValidatePostCompanyPermission = this.authUser.hasCompanyPermission(
        'validate_posts'
      );
      const hasValidatePostAccountPermission = this.authUser.hasAccountPermission(
        [this.activity.account_id],
        [['validate_account_posts']]
      );
      this.userCanApprove =
        hasValidatePostCompanyPermission || hasValidatePostAccountPermission;
    }
  }

  public async redactMessage() {
    const modal = this.modal.open(RedactModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.title = 'Redact message?';
    modal.componentInstance.info =
      'Redacting this message cannot be undone.  The text of the message will be removed and any attached media will be deleted. A timestamp will be placed within the message box to show who has redacted the content and when.';
    const confirmed = await modal.result;

    if (confirmed) {
      this.redactConfirmed.emit();
    }
  }

  public approvePost() {
    this.popup
      .confirm({
        title: 'Approve Reply',
        template: 'Are you sure you want to approve this reply?'
      })
      .then((shouldApprove) => {
        if (shouldApprove) {
          this.activity.outbox.approve();
          this.activity.outbox.is_validated = true;
          this.approvedActioned = true;
          this.snackbar.open(this.approvedSnackbarTemplate, {
            duration: 3,
            position: 'top',
            snackbarClass: 'snackbar-style-1'
          });
        }
      });
  }

  public async disapproveReply() {
    const validationTags = await this.outboxTagsService.getValidationTags();
    const modal = this.modal.open(ValidatePostsDissaproveComponent);
    modal.componentInstance.disapprovalTags = validationTags.map((tag) => {
      return {
        label: tag.name,
        value: tag.name
      };
    });
    const result = await modal.result;

    if (result !== 'cancel') {
      this.activity.outbox.disapprove(
        (result as any).reason,
        (result as any).disapprovalTags.map((t) => t.value)
      );
      this.snackbar.open(this.disapprovedSnackbarTemplate, {
        duration: 3,
        position: 'top',
        snackbarClass: 'snackbar-style-1'
      });
      this.hideActivity.emit();
    }
  }

  hideMedia(activity: Activity) {
    activity.sensitiveContentRevealed = false;

    // this.auditEventsService.createActivityEvent(
    //   activity.id,
    //   AuditEventVerb.SensitiveMediaHidden
    // );
  }

  markAsSpam(): void {
    this.activity.markAsSpam().then(() => {
      if (this.activity.markedAsSpam) {
        this.showSuccessSnackbar('Message successfully marked as spam');
      }
    });
  }

  showSuccessSnackbar(message: string): void {
    this.notificationService.open(
      message,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      4000
    );
  }
}
