import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'ssi-rounded-box-check',
  templateUrl: './rounded-box-check.component.html',
  styleUrls: ['./rounded-box-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoundedBoxCheckComponent implements OnInit {
  @Input() text: string;
  @Input() selected: boolean = false;
  @Input() borderedInital: boolean = false;
  @Input() suggestiveCheck: boolean = false;
  @Input() leadingIcon?: string = 'ssi ssi-tick-small';

  constructor() {}

  ngOnInit() {}
}
