<div class="stream-card">
  <div class="stream-card-header">
    <div class="stream-card-row">
      <p>Last Result: {{stream.latest_result ? (stream.latest_result | date:'d MMM y') : 'Unknown'}}</p>
    </div>
    <div class="stream-card-row">
      <ng-container *ngIf="showEdit">
        <button
          class="stream-card-header-action"
          (click)="onEditButtonClick.emit()"
          >
          <!-- uiSref="auth.monitoringEdit"
          [uiParams]="{streamId: 'a802418b4951e9fccfb8729633e4653b'}" -->
          <i class="ssi ssi-correctedit"></i>
        </button>
      </ng-container>
    </div>
  </div>

  <div class="stream-card-body">
    <div class="stream-card-row stream-card-body-content">
      <h3>{{stream.name}}</h3>
      <p>{{stream.description}}</p>
    </div>
    <div class="stream-card-row">
      <ul class="stream-sources">
        <ng-container *ngFor="let source of stream.sources; let i = index">
          <li
            *ngIf="i < maximumSources"
            class="stream-source"
            [ssiTooltip]="sources[source].label"
            tooltipWindowClass="tooltip-chunky"
            [ngStyle]="{
              'z-index': ((stream.sources.length > maximumSources ? maximumSources : stream.sources.length) / 2) < i ? (stream.sources.length > maximumSources ? maximumSources : stream.sources.length) % i - 1 : i,
              'background-color': sources[source].color,
              'background-image': sources[source].background ? sources[source].background : ''
            }"
            >
            <i [class]="sources[source].icon"></i>
          </li>
        </ng-container>
        <div
          *ngIf="stream.sources.length > maximumSources"
          class="stream-source-more"
        >
          <p
            [ssiTooltip]="getRemainingSourcesList(stream.sources, maximumSources)"
            tooltipWindowClass="tooltip-chunky"
          >and {{stream.sources.length - maximumSources}} more</p>
        </div>
      </ul>
    </div>
  </div>

  <div class="stream-card-footer">
    <!-- <ng-container *ngIf="showViewReport">
      <orlo-button
        class="stream-card-footer-action"
        textColor="#fff"
        bgColor="#14BAE3"
        state="primary"
        leadingIcon="ssi ssi-view-report"
        text="View Report"
        uiSref="auth.monitoringResults"
        [uiParams]="{streamId: 'a802418b4951e9fccfb8729633e4653b'}"
      ></orlo-button>
    </ng-container> -->
  </div>
</div>