import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-engagement-inbox-activity',
  templateUrl: './inbox-activity.component.html',
  styles: [],
  styleUrls: ['./inbox-activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxActivityComponent implements OnInit {
  @Input() messagesChart;
  @Input() activityStats;

  constructor() {}

  ngOnInit(): void {}
}
