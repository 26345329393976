import { ViewEncapsulation } from '@angular/core';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { orderBy } from 'lodash-es';

import { CampaignsService, Campaign } from '../../../../../common/services/api';

@Component({
  selector: 'ssi-cl-dropdown-select-e',
  templateUrl: './cl-dropdown-select-e.component.html',
  styles: [],
  styleUrls: ['./cl-dropdown-select-e.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClDropdownSelectEComponent implements OnInit {
  campaigns: Array<Campaign | any> = [];
  filteredCampaigns: Array<Campaign | any> = [];
  selectedCampaign: Campaign;

  constructor(
    protected datePipe: DatePipe,
    protected changeDetectorRef: ChangeDetectorRef,
    protected campaignsService: CampaignsService
  ) {}

  async ngOnInit() {
    this.campaigns = await this.campaignsService.getAll();
    this.campaigns = orderBy(this.campaigns, [(c) => c.name.toLowerCase()]);

    this.campaigns = this.campaigns
      .filter((c) => !c.parent_id)
      .map((p) => {
        const children = this.campaigns.filter((c) => c.parent_id === p.id);
        return {
          ...p,
          _children: children.length
            ? {
                expanded: false,
                options: children,
                filteredOptions: [...children]
              }
            : undefined
        };
      });

    this.filteredCampaigns = [...this.campaigns];
  }

  campaignsFilterFn = (filterTerm: string) => {
    const skipFiltering = !filterTerm || filterTerm.length < 2;
    if (skipFiltering) {
      this.filteredCampaigns = [...this.campaigns];
      this.campaigns.forEach((parent: Campaign | any) => {
        if (parent._children) {
          parent._children.filteredOptions = [...parent._children.options];
          if (
            filterTerm.length === 1 &&
            !parent._children.options.some((c) => c === this.selectedCampaign)
          ) {
            parent._children.expanded = false;
          }
        }
      });
      return this.filteredCampaigns;
    }

    this.filteredCampaigns = this.campaigns.filter((parent: Campaign | any) => {
      const parentMatch = (parent.name || '')
        .toLowerCase()
        .includes(filterTerm.toLowerCase());

      let childrenMatch = false;
      if (parent._children) {
        parent._children.filteredOptions = parent._children.options.filter(
          (child) => {
            return (child.name || '')
              .toLowerCase()
              .includes(filterTerm.toLowerCase());
          }
        );

        childrenMatch = !!parent._children.filteredOptions.length;
        parent._children.expanded = !!childrenMatch;
      }

      return parentMatch || childrenMatch;
    });

    return this.filteredCampaigns;
  };

  selectedOptionsChanged(selectedCampaign: Campaign): void {
    // console.log('this.selectedCampaign: ', this.selectedCampaign);
    this.selectedCampaign = selectedCampaign;
  }

  deselectCampaign(): void {
    this.selectedCampaign = undefined;
  }

  expandCollapseCampaign(parent: Campaign | any): void {
    parent._children.expanded = !parent._children.expanded;
  }

  onChildCampaignToggle(
    campaign: Campaign | any,
    parent: Campaign | any
  ): void {
    // console.log('on child campaign toggle...');
  }

  getTooltipContent = (campaign: Campaign): string => {
    if (!campaign.started_at && !campaign.closed_at) {
      return '';
    }
    return (
      'This campaign runs ' +
      (campaign.started_at
        ? 'from ' + this.datePipe.transform(campaign.started_at, 'MMM d, y')
        : '') +
      (campaign.closed_at
        ? ' until ' + this.datePipe.transform(campaign.closed_at, 'MMM d, y')
        : '')
    );
  };
}
