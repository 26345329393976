import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';
import { StreamBuilderService } from './stream-builder.service';
import { authUser } from '../../../common-resolves';
import { User } from '@ui-resources-angular';

@Component({
  selector: 'ssi-stream-builder',
  templateUrl: './stream-builder.component.html',
  styles: [],
  styleUrls: ['./stream-builder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StreamBuilderComponent implements OnInit {
  static resolve = [authUser];

  streamQuery = {
    geo: {
      enabled: false,
      radius: 25
    },
    id: '',
    latest_result: '',
    name: '',
    description: '',
    query: [
      {
        comparison: '',
        type: '',
        value: ''
      }
    ],
    sources: []
  };

  @Input() authUser: User;
  constructor(private streamBuilderService: StreamBuilderService) {}

  ngOnInit() {
    this.streamBuilderService
      .fetchStreamQueryObservable()
      .subscribe((streamQueryUpdate) => {
        Object.assign(this.streamQuery, streamQueryUpdate);
      });
  }
}
