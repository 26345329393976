import { ViewEncapsulation } from '@angular/core';

import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { AccountModel, Account, Outbox } from '@ui-resources-angular';
import { FileMimeTypes } from '../../../../../common/directives/file-download/file-download.directive';
import {
  FileDownloadService,
  SpreadsheetService
} from '../../../../../common/services';
import { decodeStateParams } from '@orlo/common/utils';
import { Debounce } from '../../../../../common/decorators';
import { isElementWithinViewport } from '../../../../../common/utils';
import { AccountService } from '../account.service';
import {
  commonDateRanges,
  compareToOptions
} from '../../../../../common/constants';
import { AnalyticsService, LinkClicksData } from '../../analytics.service';

export interface DateRange {
  start: Date;
  end: Date;
}
export interface DateRanges {
  current: DateRange;
  previous: DateRange;
}

@Component({
  selector: 'ssi-account-report',
  templateUrl: './account-report.component.html',
  styles: [],
  styleUrls: ['./account-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountReportComponent implements OnInit {
  reportActions = this.getReportActions();
  navItems = this.getNavItems();
  activeNavItemTag = 'account-summary';
  dateRanges: DateRanges;

  accountIds: string[] = [];
  accounts: Account[] = [];

  loading = false;
  report;
  decodedStateParams: { [key: string]: any };

  constructor(
    public state: StateService,
    protected elementRef: ElementRef,
    protected accountService: AccountService,
    private spreadsheetService: SpreadsheetService,
    private fileDownload: FileDownloadService,
    protected analyticsService: AnalyticsService,
    protected accountModel: AccountModel
  ) {}

  async ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);
    this.setAccounts();
    this.setDateRanges();
    await this.loadReport();
  }

  setAccounts(): void {
    this.accountIds = Array.isArray(this.decodedStateParams.accountIds)
      ? this.decodedStateParams.accountIds
      : [this.decodedStateParams.accountIds];

    this.accounts = this.accountModel
      .filter({ where: { id: { in: this.accountIds } } })
      .sort((a1, a2) => Number(a1.id) - Number(a2.id));
  }

  setDateRanges(): void {
    this.dateRanges = {
      current: {
        start: new Date(this.decodedStateParams.startDate),
        end: new Date(this.decodedStateParams.endDate)
      },
      previous: {
        start:
          this.decodedStateParams.previousStartDate &&
          new Date(this.decodedStateParams.previousStartDate),
        end:
          this.decodedStateParams.previousEndDate &&
          new Date(this.decodedStateParams.previousEndDate)
      }
    };
  }

  async reportDateChanged(dateRanges): Promise<void> {
    this.dateRanges.current.start = new Date(dateRanges.from);
    this.dateRanges.current.end = new Date(dateRanges.to);

    this.updateCompareToDates();
    await this.loadReport();
  }

  updateCompareToDates(): void {
    const selectedCompareToOption =
      compareToOptions[this.decodedStateParams.compareTo];

    if (!selectedCompareToOption.getCompareToDates) {
      return;
    }

    const { start, end } = selectedCompareToOption.getCompareToDates(
      this.dateRanges.current.start,
      this.dateRanges.current.end
    );
    this.dateRanges.previous.start = start;
    this.dateRanges.previous.end = end;
    this.analyticsService.updateUrlParams({
      startDate: this.dateRanges.current.start,
      endDate: this.dateRanges.current.end
    });
  }
  s;

  async loadReport(): Promise<void> {
    this.loading = true;
    try {
      this.report = await this.accountService.loadReport(
        this.accounts,
        this.dateRanges
      );
      this.populateNavItemElements();
      this.loading = false;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);
      window.history.back();
      return;
    }
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });

      this.setActiveNavItem();
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Account Comparison',
        tag: 'account-table',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      {
        icon: 'ssi-print',
        label: 'Download XLSX',
        func: async () => {
          if (this.loading) {
            console.log('still loading');
            return;
          }
          const config = {
            base64decode: true,
            filename: `Orlo - Account report.xlsx`,
            mimetype: FileMimeTypes.Xlsx
          };
          const xlsx = await this.spreadsheetService.jsonToXlsx(
            this.accountService.createSpreadsheetExport(this.report)
          );
          this.fileDownload.download(xlsx, config);
        }
      },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => {
          this.state.go(
            'auth.analytics.account.build',
            this.decodedStateParams
          );
        }
      }
    ];
  }

  splitColumnFieldValue(field: string): string {
    return field.split('.')[1];
  }
}
