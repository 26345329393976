import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LiveChatNetworkStateModule } from '../live-chat-network-state/live-chat-network-state.module';
import { LiveChatConversationTreeComponent } from './live-chat-conversation-tree.component';

@NgModule({
  declarations: [LiveChatConversationTreeComponent],

  exports: [LiveChatConversationTreeComponent],

  imports: [CommonModule, LiveChatNetworkStateModule, TranslateModule]
})
export class LiveChatConversationTreeModule {}
