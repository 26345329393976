import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';


import { StreamBuilderService, StreamQuery } from '../stream-builder.service';
import { LANGUAGES } from '../../../../../common/constants';
import {
  ListeningGroup,
  ListeningStreamService
} from '../../../../../common/services/api/listening-stream';
import {
  sourceTypeName,
  sources
} from '../../monitoring-streams/monitoring-streams.service';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { Chip } from '../../common/components/multi-select-chips/multi-select-chips.component';
import { mapToIterable } from '../../../../../common/utils';
import { ApiService } from '../../../../../common/services/api';

@Component({
  selector: 'ssi-finalise-step',
  templateUrl: './finalise-step.component.html',
  styles: [],
  styleUrls: ['./finalise-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinaliseStepComponent implements OnInit {
  streamQuery: StreamQuery;
  includeRetweets: boolean = true;
  languages: {
    code: string;
    label: string;
  }[];

  socialListeningEnabled = false;
  hashtagMonitoringEnabled = true;
  sources = sources;
  sourcesIterable = mapToIterable(sources);
  selectedStreamSources: Array<Chip> = [];
  listeningGroups: Array<Chip>;
  instagramHashtags: Array<Chip>;
  selectedInstagramHashtags: Array<Chip>;
  selectedListeningGroups: Array<Chip>;
  selectedLanguage: { code: string; label: string };
  twitterSource: boolean;

  constructor(
    private streamBuilderService: StreamBuilderService,
    private listeningStreamService: ListeningStreamService,
    private company: CompanyService,
    private api: ApiService
  ) {}

  async ngOnInit() {
    this.streamQuery = this.streamBuilderService.fetchStreamQuery();
    this.includeRetweets = this.streamQuery['include-retweets'];
    this.twitterSource = this.streamQuery.sources.includes(
      sourceTypeName.Twitter
    );
    this.loadLanguages();
    this.loadSelectedStreams();

    this.performSocialListeningChecks();
  }

  async performSocialListeningChecks() {
    this.socialListeningEnabled = await this.company.hasFeatureAccess(
      'SOCIAL_LISTENING'
    );
    if (this.socialListeningEnabled) {
      this.loadListeningGroups();
    }

    this.hashtagMonitoringEnabled = await this.company.hasFeatureAccess(
      'INSTAGRAM_HASHTAG_MONITORING'
    );
    if (this.hashtagMonitoringEnabled) {
      this.loadInstagramHashtags();
    }
  }

  loadSelectedStreams() {
    if (this.streamQuery.sources && this.streamQuery.sources.length) {
      this.selectedStreamSources = this.streamQuery.sources.map((source) => {
        return sources[source];
      });
    }
  }

  async loadLanguages() {
    this.languages = Object.entries(LANGUAGES).map((codeLabelPair) => {
      return {
        code: codeLabelPair[0],
        label: codeLabelPair[1]
      };
    });
    this.languages.unshift({ code: null, label: 'Any language' });
  }

  async loadListeningGroups() {
    const listeningGroupsResponse = await this.listeningStreamService.listGroups();
    this.listeningGroups = listeningGroupsResponse.map((g) => {
      return {
        id: g.id,
        label: g.name,
        icon: 'fa fa-facebook',
        color: '#0866ff',
        name: g.id,
        hideOption: false
      };
    });

    if (
      this.streamQuery.listening_group_ids &&
      this.streamQuery.listening_group_ids.length
    ) {
      this.selectedListeningGroups = this.streamQuery.listening_group_ids.map(
        (groupId) => {
          return this.listeningGroups.find((g) => g.id === groupId);
        }
      );
    }
  }
  async loadInstagramHashtags() {
    const instagramHashtagsResponse: any = await this.api
      .get(`${this.api.url}/monitoring/listInstagramHashtags`)
      .toPromise();
    this.instagramHashtags = instagramHashtagsResponse.hashtags
      .filter((h) => !h.is_disabled)
      .map((tag) => {
        return {
          id: tag.instagram_id,
          name: tag.name,
          label: `#${tag.name}`,
          icon: 'fa fa-instagram',
          color: 'linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%)',
          hideOption: false
        };
      });

    if (this.streamQuery.ig_hashtags && this.streamQuery.ig_hashtags.length) {
      this.selectedInstagramHashtags = this.streamQuery.ig_hashtags.map(
        (hashtag) => {
          return {
            id: hashtag,
            name: hashtag,
            label: `#${hashtag}`,
            icon: 'fa fa-instagram',
            color:
              'linear-gradient(180deg, #635fcc, #d43082 37%, #fec86b 100%)',
            hideOption: false
          };
        }
      );
    }
  }

  onSelectedLanguageChange($event: { code: string; label: string }) {
    this.streamQuery.lang = $event.code;
  }

  onSelectedSourcesChange(
    selectedSources: Array<{ id: string; name: sourceTypeName; label: string }>
  ) {
    this.streamQuery.sources = selectedSources.map((s) => s.name);
    this.selectedStreamSources = selectedSources;
    this.twitterSource = this.streamQuery.sources.includes(
      sourceTypeName.Twitter
    );
  }

  onSelectedListeningGroupChange(groups: Chip[]) {
    this.streamQuery.listening_group_ids = groups.map((g) => g.name);
  }

  onSelectedInstagramHashtagChange(hashtags: Chip[]) {
    console.log('hashtags:', hashtags);
    this.streamQuery.ig_hashtags = hashtags.map((h) => h.name);
  }

  onTwitterSourceToggle(toggled: boolean) {
    if (toggled) {
      this.streamQuery.sources.push(sourceTypeName.Twitter);
      this.selectedStreamSources.unshift(sources[sourceTypeName.Twitter]);
    } else {
      this.selectedStreamSources = this.selectedStreamSources.filter(
        (s) => s.name !== sourceTypeName.Twitter
      );
      this.streamQuery.sources.splice(
        this.streamQuery.sources.findIndex((s) => s === sourceTypeName.Twitter),
        1
      );
    }
  }

  deleteStream(streamQuery) {
    this.streamBuilderService.deleteSearchStream(this.streamQuery.id, true);
  }

  saveStep() {
    // remove Facebook just before API call since it's not accepted in `sources` array
    // `listening_group_ids` is used for that purpose
    const fbIndex = this.streamQuery.sources.findIndex(
      (s) => s === sourceTypeName.Facebook
    );
    if (fbIndex > -1) {
      this.streamQuery.sources.splice(fbIndex, 1);
    }
    this.streamQuery['include-retweets'] = this.includeRetweets;
    this.streamBuilderService.saveStreamQuery(this.streamQuery);
  }
}
