import { ViewEncapsulation } from '@angular/core';


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';

@Component({
  selector: 'ssi-insights-navbar',
  templateUrl: './insights-navbar.component.html',
  styles: [],
  styleUrls: ['./insights-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsightsNavbarComponent implements OnInit {
  @Input() reportName = '';
  @Input() reportID: string;
  @Output() widgetAdd = new EventEmitter<void>();
  @Output() widgetCustomAdd = new EventEmitter();
  @Output() reportNameChange = new EventEmitter<string>();
  @Output() reportDelete = new EventEmitter<void>();

  constructor(private state: StateService) {}

  ngOnInit() {
    this.reportName =
      (this.state.params && decodeURIComponent(this.state.params.reportName)) || this.reportName;
    this.onReportNameChange(); // emit it on init since it's undefined except the user change it
  }

  onReportNameChange() {
    this.reportNameChange.emit(this.reportName);
  }

  refresh() {
    this.state.reload();
  }

  goToCreateReport(): void {
    this.state.go('auth.insights.reports.create', {
      reportIdToCopy: this.reportID
    });
  }
}
