import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import moment from 'moment';
import {
  DateRange,
  commonDateRanges,
  DateRanges,
  commonDateRangesIterable
} from '../../../../../common/constants/common-date-ranges';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import { Report } from '../../common/report-build-from/report-build-from.component';
import {
  CompareToOption,
  CompareToOptions,
  compareToOptions,
  compareToOptionsIterable
} from '../../../../../common/constants/compare-to-options';
import { decodeStateParams } from '@orlo/common/utils';
import { AccountModel, Account, ActivityTags } from '@ui-resources-angular';

export interface ReportBuildFormValue {
  accounts: Account[];
  dateRange: DateRange;
  compareToOption: CompareToOption;
  startDate: Date;
  endDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;
}

@Component({
  selector: 'ssi-account-build',
  templateUrl: './account-build.component.html',
  styles: [],
  styleUrls: ['./account-build.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountBuildComponent implements OnInit {
  formValue?: ReportBuildFormValue;
  Report = Report;
  decodedStateParams: { [key: string]: any };

  constructor(
    protected state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService
  ) {}

  async ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    if (
      this.decodedStateParams.accountIds &&
      this.decodedStateParams.startDate &&
      this.decodedStateParams.endDate
    ) {
      // edit mode
      const accountIds = Array.isArray(this.decodedStateParams.accountIds)
        ? this.decodedStateParams.accountIds
        : [this.decodedStateParams.accountIds];

      this.formValue = {
        accounts: accountIds.map((id) => this.accountModel.get(id)),
        startDate: new Date(this.decodedStateParams.startDate),
        endDate: new Date(this.decodedStateParams.endDate),
        previousStartDate:
          this.decodedStateParams.previousStartDate &&
          new Date(this.decodedStateParams.previousStartDate),
        previousEndDate:
          this.decodedStateParams.previousEndDate &&
          new Date(this.decodedStateParams.previousEndDate),
        dateRange: commonDateRanges[this.decodedStateParams.dateRange],
        compareToOption: compareToOptions[this.decodedStateParams.compareTo]
      };
    }
  }

  onFormSubmit(form: ReportBuildFormValue) {
    const params = {
      accountIds: form.accounts.map((a) => a.id),
      startDate: form.startDate.toISOString(),
      endDate: form.endDate.toISOString(),
      previousStartDate:
        form.previousStartDate && form.previousStartDate.toISOString(),
      previousEndDate:
        form.previousEndDate && form.previousEndDate.toISOString(),
      dateRange: form.dateRange && form.dateRange.id,
      compareTo: form.compareToOption.id
    };

    this.state.go('auth.analytics.account.report', params);
  }

  onFlatpickrOpen() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 200);
  }
}
