import { ViewEncapsulation } from '@angular/core';

import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { Account, AccountModel } from '@ui-resources-angular';
import { decodeStateParams } from '@orlo/common/utils';
import { AdStatsService, Report, DateRanges } from '../ad-stats.service';
import { Debounce } from '../../../../../common/decorators';
import { isElementWithinViewport } from '../../../../../common/utils';
import { CURRENCY_SYMBOLS } from '../../../../../common/constants/currency-symbols';

@Component({
  selector: 'ssi-ad-stats-report',
  templateUrl: './ad-stats-report.component.html',
  styles: [],
  styleUrls: ['./ad-stats-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdStatsReportComponent implements OnInit {
  reportActions = this.getReportActions();
  navItems = this.getNavItems();
  activeNavItemTag = 'engagement-summary';

  accountIds: string[] = [];
  accounts: Account[] = [];
  dateRanges: DateRanges;
  currencySymbols = CURRENCY_SYMBOLS;

  loading = false;
  report: Report;
  decodedStateParams: { [key: string]: any };

  constructor(
    public state: StateService,
    protected elementRef: ElementRef,
    protected accountModel: AccountModel,
    protected adStatsService: AdStatsService
  ) {}

  async ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);
    this.setAccounts();
    this.setDateRanges();
    await this.loadReport();
  }

  setAccounts(): void {
    this.accountIds = Array.isArray(this.decodedStateParams.accountIds)
      ? this.decodedStateParams.accountIds
      : [this.decodedStateParams.accountIds];

    this.accounts = this.accountModel
      .filter({
        where: { id: { in: this.accountIds } }
      })
      .sort((a1, a2) => Number(a1.id) - Number(a2.id));
  }

  setDateRanges(): void {
    this.dateRanges = {
      current: {
        start: new Date(this.decodedStateParams.startDate),
        end: new Date(this.decodedStateParams.endDate)
      },
      previous: {
        start:
          this.decodedStateParams.previousStartDate &&
          new Date(this.decodedStateParams.previousStartDate),
        end:
          this.decodedStateParams.previousEndDate &&
          new Date(this.decodedStateParams.previousEndDate)
      }
    };
  }

  async reportDateChanged(dateRanges): Promise<void> {
    this.dateRanges.current.start = new Date(dateRanges.from);
    this.dateRanges.current.end = new Date(dateRanges.to);

    // this.updateCompareToDates();
    await this.loadReport();
  }

  async loadReport(): Promise<void> {
    this.loading = true;
    try {
      this.report = await this.adStatsService.loadReport(
        this.accounts,
        this.dateRanges
      );
      console.log('report: ', this.report);

      this.populateNavItemElements();
      this.loading = false;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);
      window.history.back();
      return;
    }
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });

      this.setActiveNavItem();
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Engagement Summary',
        tag: 'engagement-summary',
        element: undefined
      },
      {
        label: 'Account Breakdown',
        tag: 'ad-account-breakdown',
        element: undefined
      },
      {
        label: 'Spend Summary',
        tag: 'spend-summary',
        element: undefined
      },
      {
        label: 'Spend Breakdown',
        tag: 'spend-breakdown',
        element: undefined
      },
      {
        label: 'Spend vs Engagement',
        tag: 'spend-engagement',
        element: undefined
      },
      {
        label: 'Spend vs Conversion',
        tag: 'spend-conversion',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      // {
      //   icon: 'ssi-print',
      //   label: 'Download XLSX',
      //   func: this.downloadXlsx.bind(this)
      // },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => {
          this.state.go('auth.analytics.adStats.build', this.decodedStateParams);
        }
      }
    ];
  }

  async downloadXlsx(): Promise<void> {
    try {
      console.log('Download XLSX code here');
    } catch (error) {
      console.error('Error generating Ad Stats report XLSX');
    }
  }
}
