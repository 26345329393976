import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-sentiment-list',
  templateUrl: './sentiment-list.component.html',
  styleUrls: ['./sentiment-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SentimentListComponent {
  @Input() isInsights: boolean = false;
  @Input() sentiment: number;
  @Output() changeSentiment = new EventEmitter<number>();

  constructor() {}
}
