import { ViewEncapsulation } from '@angular/core';


import { Input, Component, EventEmitter, Output, OnInit } from '@angular/core';

import { AsyncTrackerFactory } from 'angular-async-tracker';
import { Outbox } from '@ui-resources/angular/module';
import { OutboxQuery } from '../../services/outbox-query-factory/outbox-query-factory.service';

import * as moment from 'moment';

@Component({
  selector: 'ssi-outbox-post-list',
  templateUrl: './outbox-post-list.component.html',
  styleUrls: ['./outbox-post-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutboxPostListComponent implements OnInit {
  @Input() outboxQuery: OutboxQuery;
  @Input() campaigns?: any;
  @Input() showValidation?: boolean;
  @Input() isSelectable?: boolean;
  @Input() selectedPosts?: Outbox[];
  @Input() maxHeight?: number;
  @Input() disableAutoHeight?: boolean;
  @Input() shouldRefreshPublisher = false;
  @Input() dayFilter = false;
  @Input() limit = 25;
  @Input() queryOverrides;

  @Output() closeModal = new EventEmitter();

  @Output()
  onSelectToggle: EventEmitter<{
    isSelected: boolean;
    post: Outbox;
  }> = new EventEmitter();

  loadingTracker = this.asyncTracker.create();
  overrideScrollDisable = false;

  constructor(private asyncTracker: AsyncTrackerFactory) {}

  ngOnInit(): void {}

  isPostSelected(post: Outbox) {
    return (
      this.selectedPosts &&
      this.selectedPosts.some((iPost) => iPost.id === post.id)
    );
  }

  async onScrolled(): Promise<void> {
    const currentPostCount = this.outboxQuery.outboxPosts.length;
    const queryOverrides = {
      start_date: moment().subtract(30, 'days').format()
    };

    await this.outboxQuery.search(
      this.outboxQuery.outboxPosts && this.outboxQuery.outboxPosts.length,
      this.limit,
      false,
      Object.assign(this.dayFilter && queryOverrides, this.queryOverrides)
    );

    const newPostCount = this.outboxQuery.outboxPosts.length;
    this.overrideScrollDisable = currentPostCount === newPostCount;
  }
}
