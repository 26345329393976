import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-crm-profile-sentiment-bar',
  templateUrl: './crm-profile-sentiment-bar.component.html',
  styleUrls: ['./crm-profile-sentiment-bar.component.scss']
})
export class CrmProfileSentimentBarComponent {
  @Input() profile;
  @Input() thin = false;
  @Input() spacingBefore = true;

  constructor() {}
}
