import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

import { Report } from '../../ad-stats.service';

@Component({
  selector: 'ssi-engagement-summary',
  templateUrl: './engagement-summary.component.html',
  styles: [],
  styleUrls: ['./engagement-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EngagementSummaryComponent implements OnInit {
  @Input() report: Report;

  constructor() {}

  ngOnInit() {}
}
