import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  OnDestroy,
  OnInit,
  HostListener,
  ViewEncapsulation
} from '@angular/core';
import { addSeconds } from 'date-fns';

import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Message } from '@orlo/library/models/live-chat/message';
import { Agent as AgentModel } from '@orlo/library/models/live-chat/agent';

import { ConversationUserEvent } from '@orlo/library/models/live-chat/conversation-user-event';

import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { LiveChatAssignAgentComponent } from '../live-chat-assign-agent/live-chat-assign-agent.component';
import { CompanyService } from '../../../services/company/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api';
import { SocketEventManagerService } from '../../../services/sockets/socket-event-manager.service';
import { Conversation } from '@orlo/library/models/live-chat/conversation';

// ----------------

const isDebug: boolean = false;

// ----------------

@Component({
  selector: 'ssi-live-chat-conversation-dashboard',
  templateUrl: './live-chat-conversation-dashboard.component.html',
  styles: [],
  styleUrls: ['./live-chat-conversation-dashboard.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatConversationDashboardComponent
  implements OnChanges, OnDestroy, OnInit {
  @Input() public activeConversation: Conversation;
  @Output() public messageSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('sidebar', { read: ElementRef })
  public sidebar: ElementRef;

  public isNoteFormFocused: boolean = false;
  public isResponseFocused: boolean = false;
  public isVisitorBlocked: boolean = false;
  public initialised: boolean = false;
  public assignOtherAgentsFeature: boolean = false;
  public translationMode: boolean = false;
  public translationLanguage: string;
  public visitorIpLocation;

  private _isAssignChatScreenActive: boolean = false;
  private _isBlurred: boolean = false;
  private _isNotesScreenVisible: boolean = false;
  private _isSidebarVisible: boolean = true;
  private _isTagsVisible: boolean = false;
  private _responseAddition: string;
  private _selectedMessage: Message;
  private _selectedMessageId: string;

  $ipAddressLookup: Subscription;

  constructor(
    private liveChatService: LiveChatService,
    private modal: NgbModal,
    private companyService: CompanyService,
    private api: ApiService,
    private socketEventManagerService: SocketEventManagerService
  ) {}

  public get agent(): AgentModel {
    return this.liveChatService.agentModel;
  }

  public get areSuggestionsAvailable(): boolean {
    return this.liveChatService.areSuggestionsAvailable;
  }

  public get areTagsUpdating(): boolean {
    return !!this.liveChatService.areActiveConversationTagsUpdating;
  }

  public get canChangeAssignment(): boolean {
    if (
      !(
        !this.agent.isOffline ||
        !!this.conversation ||
        !this.conversation.isArchived ||
        !this.conversation.isResolved ||
        !this.isInterfaceDisabled
      )
    ) {
      return false;
    }

    return true;
  }

  public get canReply(): boolean {
    return (
      this.agent.hasWritePermissionForApplicationID(
        this.conversation.applicationId
      ) &&
      !this.agent.isOffline &&
      ((!this.conversation.hasAgent &&
        !this.liveChatService.isPushModeEnabled) ||
        this.isAgentAssignedToConversation)
    );
  }

  public get conversation() {
    return this.liveChatService.activeConversation;
  }

  public get conversationEvents(): ConversationUserEvent {
    return this.liveChatService.activeConversationEvents;
  }

  public get isAgentAssignedToConversation(): boolean {
    return (
      this.conversation.hasAgentModel &&
      this.conversation.agent.id === this.agent.id
    );
  }

  public get isAssignChatScreenActive(): boolean {
    return this._isAssignChatScreenActive;
  }

  public set isAssignChatScreenActive(value: boolean) {
    this._isAssignChatScreenActive = value;
  }

  public get isBlurred(): boolean {
    return this._isBlurred;
  }

  public get isConversationGracePeriodExpired(): boolean {
    try {
      if (!this.conversation.dateOfResolution) {
        return false;
      }

      const endOfGracePeriod = addSeconds(
        this.conversation.dateOfResolution,
        this.liveChatService.applications.find(
          (conversation) => conversation.id === this.conversation.applicationId
        ).resolutionGracePeriod
      );

      if (Date.now() < endOfGracePeriod.getTime()) {
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public get isConversationResolved(): boolean {
    if (!this.conversation) {
      return true;
    }

    return this.conversation.isResolved;
  }

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public get isLoaded(): boolean {
    return this.liveChatService.isActiveConversationLoaded;
  }

  public get isLoading(): boolean {
    return this.liveChatService.isActiveConversationLoading;
  }

  public get isNotesScreenVisible(): boolean {
    return this._isNotesScreenVisible;
  }

  public get isResolving(): boolean {
    return this.liveChatService.isActiveConversationResolving;
  }

  public get isSidebarVisible(): boolean {
    return this._isSidebarVisible;
  }

  public set isSidebarVisible(value: boolean) {
    this._isSidebarVisible = value;
  }

  public get isTagsVisible() {
    return this._isTagsVisible;
  }

  public get messages(): Message[] {
    return this.liveChatService.activeMessages;
  }

  public get responseAddition(): string {
    return this._responseAddition;
  }

  public get selectedMessage(): Message {
    return this._selectedMessage;
  }

  public get shouldTagsIgnoreHotkeys(): boolean {
    return this.isNoteFormFocused || this.isNotesScreenVisible;
  }

  public emailMessage(): void {
    // @todo
  }

  public isDropDownOpen(name: string): boolean {
    // @todo
    return false;
  }

  public async ngOnChanges(changes) {
    try {
      if (isDebug) {
        console.log(`conversation dashboard changes:`);
        console.dir(changes);
      }

      if (
        !!changes.activeConversation &&
        !!changes.activeConversation.currentValue
      ) {
        // treat as a reset
        this._isTagsVisible = false;

        if (this.conversation.visitor.data.clientIp) {
          await this.api
            .post(`${this.api.url}/ipLookup/ipLookup`, {
              ip_address: this.conversation.visitor.data.clientIp
            })
            .subscribe((res) => {});
        }

        if (!this.initialised && this.conversation.visitor.data.clientIp) {
          // edge case where the first chat they click might not have an Ip stored
          (await this.liveChatService.blockList()).subscribe(
            (blockListSnapshot) => {
              this.isVisitorBlocked = blockListSnapshot.payload
                .data()
                .ipAddresses.includes(this.conversation.visitor.data.clientIp);
            }
          );

          this.$ipAddressLookup = this.socketEventManagerService.ipAddressLookup
            .pipe(debounceTime(2000))
            .subscribe((response) => {
              this.visitorIpLocation = response;
            });

          this.initialised = true;
        }
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public ngOnDestroy() {
    this.onCloseConversation();
    window.removeEventListener('resize', (event) => this.onResize(event));
    this.$ipAddressLookup.unsubscribe();
  }

  public async ngOnInit() {
    window.addEventListener('resize', (event) => this.onResize(event));

    this.assignOtherAgentsFeature = await this.companyService
      .hasFeatureAccess('LIVECHAT_ASSIGN_OTHER_AGENTS')
      .then((enabled) => enabled);
  }

  public onBlur(): void {
    this._isBlurred = true;
  }

  public onCloseConversation(): void {
    this.liveChatService.closeActiveConversation();
  }

  public onFocus(): void {
    this._isBlurred = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (
      !!this.isBlurred ||
      !!this.isTagsVisible ||
      !!this.isResponseFocused ||
      !!this.isNoteFormFocused
    ) {
      return;
    }

    switch (event.key.toLowerCase()) {
      case 't':
        this.onOpenTags();

        return;
    }
  }

  public onMessageUpdated(event: Event): void {
    this._isTagsVisible = false;
  }

  public onCloseNotesScreen(): void {
    this._isNotesScreenVisible = false;
  }

  public onOpenNotesScreen(event: Event): void {
    this._isNotesScreenVisible = true;
  }

  public onIsNoteFormFocusedChange(change: boolean): void {
    this.isNoteFormFocused = change;
  }

  public onIsResponseFocusedChange(change: boolean): void {
    this.isResponseFocused = change;
  }

  public onOpenTags(value?: boolean): void {
    if (typeof value !== 'undefined') {
      if (this._isTagsVisible !== value) {
        this._isTagsVisible = !!value;
      }

      return;
    }

    this._isTagsVisible = !this._isTagsVisible;
  }

  public resolveConversation(): void {
    this.liveChatService.toggleConversationResolution(this.conversation, true);
  }

  public async selectMessage(message: Message): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatConversationDashboard~>selectMessage`);
      }

      if (!!this.isInterfaceDisabled) {
        return;
      }

      this._selectedMessageId = message.id;
      this._selectedMessage = this.messages.find(
        (messageItem) => messageItem.id === this._selectedMessageId
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public showAssignChatScreen(): void {
    this._isSidebarVisible = false;
    this.isAssignChatScreenActive = true;
  }

  public async toggleChatAssignment(): Promise<boolean> {
    try {
      if (!this.canChangeAssignment) {
        return true;
      }

      await this.liveChatService.toggleAssignmentToConversationForAgent(
        this.conversation
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async openAssignAgentModal() {
    const modal = this.modal.open(LiveChatAssignAgentComponent, {
      windowClass: 'rounded-corners-40',
      centered: true
    });

    modal.componentInstance.conversation = this.conversation;
    modal.componentInstance.agent = this.agent;

    const result = await modal.result;

    if (result) {
      await this.liveChatService.unassignAgentFromConversation(
        this.conversation
      );
      await this.liveChatService.assignNewAgentToConversation(
        this.conversation,
        result
      );
    } else {
      await this.liveChatService.unassignAgentFromConversation(
        this.conversation
      );
    }
  }

  public toggleSidebar(): void {
    this._isSidebarVisible = !this._isSidebarVisible;
  }

  public unresolveConversation(): void {
    this.liveChatService.toggleConversationResolution(this.conversation, false);
  }

  public translateMessages(): void {
    this.translationMode = !this.translationMode;
  }

  private onResize(event): void {
    if (!this.isAssignChatScreenActive) {
      this._isSidebarVisible = window.innerWidth >= 1350;
    }
  }

  public onTranslationDetectedLangugage(language) {
    this.translationLanguage = language;
  }

  public blockVisitorByIp(ip) {
    this.liveChatService.blockVisitorByIp(ip);
  }

  public unblockVisitorByIp(ip) {
    this.liveChatService.unblockVisitorByIp(ip);
  }
}
