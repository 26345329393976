import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  OnChanges,
  ElementRef,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ssi-trigger-or-action-card',
  templateUrl: './trigger-or-action-card.component.html',
  styleUrls: ['./trigger-or-action-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TriggerOrActionCardComponent implements OnChanges {
  @Input() overlayVisible = false;
  @Input() overlayWidth: number;
  @Input() overlayHeight: number;
  @Input() hasValue: boolean;
  @Input() triggerOrAction;
  @Input() cardTitleTemplate: TemplateRef<any>;
  @Input() cardIconTemplate: TemplateRef<any>;
  @Input() cardDescriptionTemplate: TemplateRef<any>;
  @Input() cardValueLabelTemplate: TemplateRef<any>;
  @Input() cardOverlayTemplate: TemplateRef<any>;

  @Output() cardClicked: EventEmitter<void> = new EventEmitter();
  @Output() cardOffClick: EventEmitter<void> = new EventEmitter();
  @Output() clearValue: EventEmitter<void> = new EventEmitter();

  overlayPosition: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.overlayVisible && this.overlayVisible === true) {
      this.positionOverlay();
    }
  }

  delayCardClicked() {
    setTimeout(() => this.cardClicked.emit()); // hack to make card clicked and overlay off click work nicely
  }

  overlayOffClick() {
    if (this.overlayVisible) {
      this.cardOffClick.emit();
    }
  }

  private positionOverlay(): void {
    const elementPosition: ClientRect = this.element.nativeElement.getBoundingClientRect();
    const CARD_BORDER_WIDTH = 2;
    this.overlayPosition = {
      top: -((this.overlayHeight - elementPosition.height) / 2),
      left: -((this.overlayWidth - elementPosition.width) / 2)
    };
    if (elementPosition.left + this.overlayPosition.left < 0) {
      this.overlayPosition.left = -CARD_BORDER_WIDTH;
    }
    if (elementPosition.top + this.overlayPosition.top < 0) {
      this.overlayPosition.top = -CARD_BORDER_WIDTH;
    }
    if (elementPosition.right - this.overlayPosition.left > window.innerWidth) {
      delete this.overlayPosition.left;
      this.overlayPosition.right = -CARD_BORDER_WIDTH;
    }
    if (
      elementPosition.bottom - this.overlayPosition.top >
      window.innerHeight
    ) {
      delete this.overlayPosition.top;
      this.overlayPosition.bottom = -CARD_BORDER_WIDTH;
    }
  }
}
