import { ViewEncapsulation } from '@angular/core';


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Outbox } from '@ui-resources/modules/exports';
import { AccountTypeId } from '../../../../../../common/enums';
import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';
import {
  SortType,
  sortTypes,
  SortTypes
} from '../../../common/top-post-list/top-post-list.component';
import { AnalyticsService } from '../../../analytics.service';

@Component({
  selector: 'ssi-top-posts-section',
  templateUrl: './top-posts-section.component.html',
  styles: [],
  styleUrls: ['./top-posts-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopPostsSectionComponent implements OnInit {
  @Input() report: any;
  @Input() campaignIds: string[];
  @Input() includeDeletedInTopPosts: string;
  @Input() tagsToInclude: string[] = null;
  @Input() tagsToExclude: string[] = null;

  @Output() postsLoaded = new EventEmitter<Outbox[]>();
  @Output() onSortTypeChanged = new EventEmitter<SortType>();

  postsToDisplay: Outbox[] = [];
  loading = false;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];
  sortTypes: SortTypes = sortTypes;
  selectedSortType: SortType = this.sortTypes.clicks;

  constructor(protected analyticsService: AnalyticsService) {}

  async ngOnInit(): Promise<void> {
    this.selectedNetwork = this.selectableNetworks[0];

    const posts = await this.loadPosts(
      this.selectedNetwork,
      this.selectedSortType
    );
    if (posts) {
      this.postsToDisplay = posts;
      this.postsLoaded.emit(posts);
    }
  }

  async loadPosts(
    nsi: NetworkSelectItem,
    sortType: SortType
  ): Promise<Outbox[]> {
    this.loading = true;
    try {
      if (
        sortType.key === sortTypes.reach.key &&
        nsi.account_type_id === AccountTypeId.Twitter
      ) {
        // Twitter does not provide reach statistic, see CT-1656
        this.loading = false;
        return [];
      }

      const accountTypeIds =
        nsi.key === 'all' ? undefined : [nsi.account_type_id];

      const posts = await this.analyticsService.loadTopPosts(
        this.report.filter.period,
        sortType.key,
        undefined,
        accountTypeIds,
        this.campaignIds,
        { include_deleted: this.includeDeletedInTopPosts === 'true' },
        this.tagsToInclude.length ? this.tagsToInclude : [],
        this.tagsToExclude.length ? this.tagsToExclude : []
      );

      this.loading = false;
      return posts;
    } catch (e) {
      console.error('Error loading/mapping top posts: ', e);
      this.loading = false;
      return undefined;
    }
  }

  async onNetworkSelect(nsi: NetworkSelectItem): Promise<void> {
    const posts = await this.loadPosts(nsi, this.selectedSortType);
    if (posts) {
      this.selectedNetwork = nsi;
      this.postsToDisplay = posts;
    }
  }

  async onSortTypeChange(sortType: SortType): Promise<void> {
    this.onSortTypeChanged.emit(sortType);
    const posts = await this.loadPosts(this.selectedNetwork, sortType);
    if (posts) {
      this.selectedSortType = sortType;
      this.postsToDisplay = posts;
    }
  }
}
