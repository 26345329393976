import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ssi-disapprovals',
  templateUrl: './disapprovals.component.html',
  styleUrls: ['./disapprovals.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DisapprovalsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
