import { Injectable, Injector } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'angular-2-local-storage';

import {
  AccountModel,
  Activity,
  ActivityModel,
  ActivityTags,
  Conversation,
  ConversationModel,
  Outbox,
  OutboxModel
} from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import { TwitterThreadService } from '../../../modules/auth/outbox/twitter-threads/service/twitter-threads.service';
import { NotificationManagerService } from '../notification-manager/notification-manager.service';
import { CompanyService } from '../company/company.service';
import { Socket } from 'socket.io-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialPushModeModalComponent } from '@orlo/common/components/social-push-mode-modal/social-push-mode-modal.component';

const TEST_EVENT = 'test';
const ACTIVITY_STATUS_CHANGE_EVENT = 'activity.status.change';
const ACTIVITY_UPDATED_SENTIMENT = 'activity.updated.sentiment';
const OUTBOX_CREATED_EVENT = 'outbox.created';
const OUTBOX_DELETED_EVENT = 'outbox.deleted';
const OUTBOX_UPDATED_EVENT = 'outbox.updated';
const OUTBOX_UPDATED_APPROVED_EVENT = 'outbox.updated.approved';
const OUTBOX_UPDATED_RESCHEDULED_EVENT = 'outbox.updated.rescheduled';
const OUTBOX_UPDATED_PUBLISHED_EVENT = 'outbox.updated.published';
const OUTBOX_UPDATED_FAILED_TO_PUBLISH_EVENT =
  'outbox.updated.failed_to_publish';
const OUTBOX_UPDATED_VALIDATED_FILES = 'outbox.updated.validated_files';
const SNIPPET_CREATED_EVENT = 'snippet.created';
const SNIPPET_REMOVED_EVENT = 'snippet.removed';
const COMPANY_TAG_CREATED_EVENT = 'company_tag.created';
const COMPANY_TAG_REMOVED_EVENT = 'company_tag.removed';
const COMPANY_TAG_PURGE_EVENT = 'company_tag.purge';
const UNREAD_INBOX_MESSAGES_COUNT_DELTA_EVENT =
  'unread_inbox_messages_count_delta';
const CONVERSATION_UPDATED_EVENT = 'conversation.updated';
const CONVERSATION_CREATED_EVENT = 'conversation.created';
const CONVERSATION_PUSH_MODE = 'push_mode.company_status';
const INSIGHTS_CLUSTERING_DONE = 'insights.clustering.done';
const INSIGHTS_CLUSTERING_FAILED = 'insights.clustering.failed';
const LIVECHAT_IP_LOOKUP = 'livechat_ip_lookup';

const KV_SOCIAL_PUSH_MODE_CHECKED = 'kv_social_push_mode.checked_company';

export interface SocialPushModeCheckedResponse {
  count_assigned_by_user_id: { [key: number]: number };
  count_conversations_on_hold: number;
  count_conversations_to_assign: number;
  user_ids: Array<number>;
  user_ids_busy: Array<number>;
}

@Injectable({ providedIn: 'root' })
export class SocketEventManagerService {
  newConversation = new Subject<Partial<Conversation>>();
  clusteringDone = new Subject<any>();
  clusteringFailed = new Subject<any>();
  socialPushModeDisabled = new Subject<boolean | undefined>();
  socialPushModeChecked = new Subject<any>();
  ipAddressLookup = new Subject<any>();
  pushModeModal;

  constructor(
    private activityModel: ActivityModel,
    private accountModel: AccountModel,
    private outboxModel: OutboxModel,
    private twitterThread: TwitterThreadService,
    private notificationManager: NotificationManagerService,
    private activityTags: ActivityTags,
    private company: CompanyService,
    private injector: Injector,
    private workflowManager: WorkflowManagerService,
    private localStorageService: LocalStorageService,
    private modal: NgbModal
  ) {}

  initialise(socket: Socket) {
    const disconnect$ = fromEvent(socket, 'disconnect').pipe(share());
    const conversationModel = this.injector.get(ConversationModel);

    fromEvent(socket, TEST_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event) => {
        console.log(event);
      });

    fromEvent(socket, ACTIVITY_STATUS_CHANGE_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((updatedActivity: Activity) => {
        const activity: Activity = this.activityModel.get(updatedActivity.id);
        if (activity) {
          Object.assign(activity, updatedActivity);
        }
      });

    fromEvent(socket, ACTIVITY_UPDATED_SENTIMENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((update: { id: string; properties }) => {
        const activity: Activity = this.activityModel.get(update.id);
        if (activity) {
          (activity.sentiment || ({} as any)).value =
            update.properties.sentiment;
        }
      });

    fromEvent(socket, OUTBOX_CREATED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.updateOutboxPost(event.outbox_id, event.new_state);
        this.updateTwitterThread(event.new_state);
        if (!+event.new_state.is_validated) {
          this.changeOutboxUnvalidatedTotal(event.account_id, +1);
        }
      });

    fromEvent(socket, OUTBOX_DELETED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.updateOutboxPost(event.outbox_id, event.new_state);
        this.updateTwitterThread(event.new_state);
        if (!+event.new_state.is_validated) {
          this.changeOutboxUnvalidatedTotal(event.account_id, -1);
        }
      });

    fromEvent(socket, OUTBOX_UPDATED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.updateOutboxPost(event.outbox_id, event.new_state);
        if (+event.new_state.is_validated !== +event.old_state.is_validated) {
          if (+event.new_state.is_validated) {
            this.changeOutboxUnvalidatedTotal(event.account_id, -1);
          } else {
            this.changeOutboxUnvalidatedTotal(event.account_id, +1);
          }
        }
      });

    fromEvent(socket, OUTBOX_UPDATED_APPROVED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.updateOutboxPost(event.outbox_id, {
          validator_id: event.validator_id,
          is_validated: true
        });
        this.changeOutboxUnvalidatedTotal(event.account_id, -1);
      });

    fromEvent(socket, OUTBOX_UPDATED_RESCHEDULED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.updateOutboxPost(event.outbox_id, { send_at: event.send_at });
      });

    fromEvent(socket, OUTBOX_UPDATED_PUBLISHED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.updateTwitterThread(event);
        this.updateOutboxPost(event.outbox_id, {
          sent_at: event.sent_at,
          social_id: event.social_id
        });
      });

    fromEvent(socket, OUTBOX_UPDATED_FAILED_TO_PUBLISH_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.changeOutboxUnvalidatedTotal(event.account_id, +1);
      });

    fromEvent(socket, OUTBOX_UPDATED_VALIDATED_FILES)
      .pipe(takeUntil(disconnect$))
      .subscribe((event: any) => {
        this.updateOutboxPost(event.outbox_id, { is_draft: false });
      });

    fromEvent(socket, SNIPPET_CREATED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe(() => {
        this.company.clearInboxSnippetsCache();
      });

    fromEvent(socket, SNIPPET_REMOVED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe(() => {
        this.company.clearInboxSnippetsCache();
      });

    fromEvent(socket, COMPANY_TAG_CREATED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe(() => {
        this.activityTags.clearCache();
      });

    fromEvent(socket, COMPANY_TAG_REMOVED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe(() => {
        this.activityTags.clearCache();
      });

    fromEvent(socket, COMPANY_TAG_PURGE_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe(({ tag }) => {
        this.activityTags.clearCache();
        this.activityTags.purgeTagFromActivities(tag);
      });

    fromEvent(socket, UNREAD_INBOX_MESSAGES_COUNT_DELTA_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe((data: { account_id: number; delta: number }) => {
        this.isAccountIdInWorkflow(data.account_id).then((isInWorkflow) => {
          if (isInWorkflow) {
            this.notificationManager.totalInboxMessages =
              this.notificationManager.totalInboxMessages + data.delta;
          }
        });
      });

    fromEvent(socket, CONVERSATION_UPDATED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe(async (partialConversation: Partial<Conversation>) => {
        this.newConversation.next(partialConversation);

        const conversation = conversationModel.get(partialConversation.id);
        if (conversation) {
          Object.assign(conversation, partialConversation);
        }
      });

    fromEvent(socket, CONVERSATION_CREATED_EVENT)
      .pipe(takeUntil(disconnect$))
      .subscribe(async (partialConversation: Partial<Conversation>) => {
        this.newConversation.next(partialConversation);

        const conversation = conversationModel.get(partialConversation.id);
        if (conversation) {
          Object.assign(conversation, partialConversation);
        }
      });

    fromEvent(socket, CONVERSATION_PUSH_MODE)
      .pipe(takeUntil(disconnect$))
      .subscribe((status: any) => {
        if (status) {
          if (!status.enabled) {
            this.socialPushModeDisabled.next(true);
          }
          const modal = this.modal.open(SocialPushModeModalComponent, {
            windowClass: 'orlo-modal',
            backdropClass: 'orlo-modal-backdrop',
            size: 'sm',
            backdrop: 'static'
          });

          modal.componentInstance.isEnabled = status.enabled;
        }
      });

    fromEvent(socket, INSIGHTS_CLUSTERING_DONE)
      .pipe(takeUntil(disconnect$))
      .subscribe((response: any) => {
        this.clusteringDone.next(response);
      });

    fromEvent(socket, INSIGHTS_CLUSTERING_FAILED)
      .pipe(takeUntil(disconnect$))
      .subscribe((response: any) => {
        this.clusteringFailed.next(response);
      });

    fromEvent(socket, KV_SOCIAL_PUSH_MODE_CHECKED)
      .pipe(takeUntil(disconnect$))
      .subscribe((response: SocialPushModeCheckedResponse) => {
        this.socialPushModeChecked.next(response);
      });

    fromEvent(socket, LIVECHAT_IP_LOOKUP)
      .pipe(takeUntil(disconnect$))
      .subscribe((response: any) => {
        this.ipAddressLookup.next(response);
      });
  }

  updateOutboxPost(outboxId, updatedObject) {
    const post: Outbox = this.outboxModel.get(outboxId);
    if (post) {
      Object.assign(post, updatedObject);
    }
  }

  updateTwitterThread(updatedObject) {
    this.twitterThread.newOutbox.next(updatedObject);
    if (updatedObject.social_id) {
      this.twitterThread.displayAdd.next(true);
    }
  }

  isAccountIdInWorkflow(accountId: string | number): Promise<boolean> {
    try {
      return this.accountModel
        .findAccounts(this.workflowManager.getCurrentId())
        .then((accounts) => {
          if (!(!!accounts && Array.isArray(accounts))) {
            throw new Error(
              `value for 'socket event manager accounts' is in unexpected format.`
            );
          }

          return !!accounts.find((account) => +account.id === +accountId);
        });
    } catch (error) {
      console.error(error);

      return;
    }
  }

  changeOutboxUnvalidatedTotal(accountId, amount) {
    this.isAccountIdInWorkflow(accountId).then((isInWorkflow) => {
      if (isInWorkflow) {
        if (!this.notificationManager.totalUnvalidatedPosts) {
          this.notificationManager.totalUnvalidatedPosts = 0;
        }
        this.notificationManager.totalUnvalidatedPosts += amount;
      }
    });
  }
}
