<div>
  <ssi-report-header
    report="Chatbot Report"
    name="{{chatbot?.name}}"
    [reportStartDate]="report.filter.period.start"
    [reportEndDate]="report.filter.period.end"
    (dateSelected)="reportDateSelected($event)"
  >
  </ssi-report-header>
  <ssi-report-sidebar
    icon="ssi-chatbot"
    color="#F0B427"
    [navList]="navItems"
    [actionList]="reportActions"
    [active]="activeNavItemTag"
    (navClicked)="scrollTo($event)"
  >
  </ssi-report-sidebar>

  <div
    class="report-body-loading"
    *ngIf="loading"
  >
    <div class="loading-container">
      <!-- <i class="ssi ssi-confetti"></i> -->
      <img src="assets/images/line-chart.gif">
      <h3 translate="WERE_BUILDING_YOUR_REPORT"></h3>
      <p translate="THIS_MAY_TAKE_A_MINUTE_SO_SIT_TIGHT_ITS_NOT_EASY_GATHERING_ALL_THAT_INSIGHT"></p>
    </div>
  </div>
  <div
    class="report-body"
    *ngIf="!loading"
  >
    <section
      class="section-container"
      id="summary"
    >
      <div class="section-heading">
        <h3>Chatbot Summary</h3>
      </div>
      <div class="section-content">
        <div class="facts-container">
          <ssi-fact-overview
            [label]="'CHATBOT_SESSIONS' | translate"
            [width]="'auto'"
            [number]="report?.reportData.metrics.session_count"
            iconClass="ssi-chatbot"
            iconColor="#89A4EA"
            circleColor="#EDF1FC"
          >
          </ssi-fact-overview>
          <ssi-fact-overview
            [label]="'HANDOVER_RATE' | translate"
            [width]="'auto'"
            [number]="report?.reportData.handoverPercent"
            [showString]="true"
            iconClass="ssi-handover-rate"
            iconColor="#F88C68"
            circleColor="#FFF1ED"
          >
          </ssi-fact-overview>
          <ssi-fact-overview
            [label]="'DEFLECTION_RATE' | translate"
            [width]="'auto'"
            [number]="report?.reportData.deflectionPercent"
            [showString]="true"
            iconClass="ssi-deflection-rate"
            iconColor="#12ACA4"
            circleColor="#EAF9F8"
          >
          </ssi-fact-overview>
          <ssi-fact-overview
            [label]="'RESOLVED_FEEDBACK' | translate"
            [width]="'auto'"
            [number]="report?.reportData.feedbackResolvedPercent"
            [showString]="true"
            iconClass="ssi-resolved-feedback"
            iconColor="#425DEC"
            circleColor="#F3F6FD"
          >
          </ssi-fact-overview>
          <ssi-fact-overview
            [label]="'UNRESOLVED_FEEDBACK' | translate"
            [width]="'auto'"
            [number]="report?.reportData.feedbackUnresolvedPercent"
            [showString]="true"
            iconClass="ssi-unresolved-feedback"
            iconColor="#D41D68"
            circleColor="#FFECF4"
          >
          </ssi-fact-overview>
          <ssi-fact-overview
            [label]="'NO_FEEDBACK' | translate"
            [width]="'auto'"
            [number]="report?.reportData.feedbackNonePercent"
            [showString]="true"
            iconClass="ssi-no-results"
            iconColor="#F0B427"
            circleColor="#FBF3E2"
          >
          </ssi-fact-overview>
        </div>
      </div>
    </section>

    <hr>
    <section
      class="section-container"
      id="sessions"
    >
      <ssi-total-sessions
        [sessionsData]="report.reportData.session_start_times"
        [filterPeriod]="report.filter.period"
      >
      </ssi-total-sessions>
    </section>
    <hr>
    <section
      class="section-container"
      id="handover"
    >
      <ssi-handover-times
        [handoverTimes]="{handover: report.reportData.handover_times, nonHandover: report.reportData.non_handover_times}"
        [filterPeriod]="report.filter.period"
      >
      </ssi-handover-times>
    </section>

    <hr>
    <section
      class="section-container"
      id="feedback"
    >
      <ssi-chatbot-feedback
        [feedbackTimes]="{resolved: report.reportData.feedback.resolved, unresolved: report.reportData.feedback.unresolved, noFeedback: report.reportData.feedback.no_feedback_given}"
        [filterPeriod]="report.filter.period"
      >
      </ssi-chatbot-feedback>
    </section>

    <hr>
    <section
      class="section-container"
      id="top-urls"
    >
      <div class="section-heading">
        <h3 translate="TOP_URLS_PROMPTING_CHATBOTS"></h3>
      </div>
      <div class="section-content">
        <ssi-top-urls
          [topURLs]="report.reportData.topUrls"
          countLabel="Count"
        ></ssi-top-urls>
      </div>
    </section>

    <hr>
    <section
      class="section-container"
      id="topic-usage"
    >
      <ssi-topic-usage [topicUsage]="report.reportData.topicUsage"></ssi-topic-usage>
    </section>

    <hr>
    <section
      class="section-container"
      id="top-topics"
    >
      <ssi-top-topics
        [topTopics]="report.reportData.topTopics"
        [filterPeriod]="report.filter.period"
      ></ssi-top-topics>
    </section>

    <hr>
    <section
      class="section-container"
      id="topic-feedback"
    >
      <ssi-topic-feedback [topicFeedback]="report.reportData.topicFeedback"></ssi-topic-feedback>
    </section>
  </div>
</div>