
import {
  Component,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { SnackbarOptions } from './snackbar.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
  content: any;
  options: SnackbarOptions;
  @Output() close = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  public dismiss() {
    this.close.emit();
  }
}
