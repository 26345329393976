import { Component, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

import { Note, User, UserModel } from '@ui-resources-angular';

import { Colleague } from '../../../services/api';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import strings from '@orlo/library/constants/strings';
import { Message } from '@orlo/library/models/live-chat/message';

@Component({
  selector: 'ssi-live-chat-message-note',
  templateUrl: './live-chat-message-note.component.html',
  styles: [],
  styleUrls: ['./live-chat-message-note.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatMessageNoteComponent {
  @Input() public canAddMore = false;
  @Input() public canViewMore = false;
  @Input() public message: Message;
  @Input() public note: Note;
  @Input() public user: User;

  @Output() clickAddNote: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickMoreNotes: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _isDeleting = false;

  public get author(): string {
    if (!this.user) {
      return;
    }

    return this.user.fullName;
  }

  public get content(): string {
    if (!this.note) {
      return;
    }

    return this.note.content;
  }

  public get date(): string {
    if (!this.note) {
      return;
    }

    return this.note.created_at;
  }

  public get didActiveAgentAuthorNote(): boolean {
    if (!this.note) {
      return;
    }

    // @todo add author to definition of Note.
    const author = this.note[strings.author] as Colleague;

    if (!author || !this.user) {
      return;
    }

    return this.user.id === author.id;
  }

  public get isDeleting(): boolean {
    return this._isDeleting;
  }

  constructor(
    private liveChatService: LiveChatService,
    private userModel: UserModel
  ) {}

  onClickAddNote() {
    this.clickAddNote.emit(true);
  }

  public async onClickDelete(): Promise<boolean> {
    this._isDeleting = true;

    try {
      await this.liveChatService.deleteNoteOnMessage(this.note, this.message);
    } catch (error) {
      console.error(error);
      return false;
    }

    return true;
  }

  onClickMoreNotes() {
    this.clickMoreNotes.emit(true);
  }
}
