<div
  class="monitoring-page monitoring-streams"
  [ngClass]="{
    'monitoring-page-reporting': selectedMonitoringType === MonitoringType.Reporting
  }"
>
  <div class="monitoring-header">
    <div class="monitoring-header-left">
      <h1 class="monitoring-header-title">Listening</h1>
    </div>
    <div class="monitoring-header-right">
      <!-- <orlo-button
        *ngIf="authUser?.hasCompanyPermission('manage_monitoring_streams')"
        class="monitoring-header-right-button"
        textColor="#fff"
        bgColor="#12ACA4"
        state="primary"
        leadingIcon="ssi ssi-addsmall"
        text="New Stream"
        uiSref="auth.monitoringAdd"
      ></orlo-button> -->
      <orlo-button
        *ngIf="selectedMonitoringType === MonitoringType.FacebookListening"
        class="monitoring-header-right-button"
        [textColor]="!hasFacebookAccounts ? '#838EAB' : '#FFF'"
        [bgColor]="!hasFacebookAccounts ? '#F4F4FA' : '#4EAAA4'"
        state="primary"
        leadingIcon="ssi ssi-addsmall"
        text="New Facebook page"
        (btnClick)="openAddPageModal()"
        [disabled]="!hasFacebookAccounts"
      ></orlo-button>
      <orlo-button
        *ngIf="selectedMonitoringType === MonitoringType.InstagramHashtags"
        class="monitoring-header-right-button"
        [textColor]="!hasInstagramAccounts ? '#838EAB' : '#FFF'"
        [bgColor]="!hasInstagramAccounts ? '#F4F4FA' : '#0D827C'"
        state="primary"
        leadingIcon="ssi ssi-addsmall"
        text="New Hashtag"
        (btnClick)="openCreateHashtagModal()"
        [disabled]="!hasInstagramAccounts"
      ></orlo-button>
    </div>
  </div>

  <div class="monitoring-body">
    <div class="monitoring-container">
      <div *ngIf="selectedMonitoringType !== MonitoringType.InstagramHashtags" class="monitoring-row">
        <div class="monitoring-message">
          <div class="monitoring-message-left">
            <h2>Listening Streams</h2>
            <p>Listening begins with building your streams! Here you can create new streams and manage existing ones to
              unearth intelligence around your reputation, the experience you deliver, competitors and more.</p>
          </div>
          <div class="monitoring-message-right">
            <img
              src="assets/images/monitoring/man-mobile-charts.png"
              alt="cartoon man browsing charts on phone"
            >
          </div>
        </div>
      </div>

      <div class="monitoring-row">
        <div class="monitoring-actions">
          <div class="monitoring-actions-left">
            <ng-container *ngIf="streams?.length && selectedMonitoringType === MonitoringType.Reporting">
              <div class="monitoring-actions-search">
                <i class="ssi ssi-correct-search"></i>
                <input
                  placeholder="Search streams..."
                  type="search"
                  [(ngModel)]="filterTerm"
                  (ngModelChange)="onFilterTermChange()"
                >
                <!-- (ngModelChange)="onFilterTermChange()" -->
              </div>
            </ng-container>
            <ng-container
              *ngIf="selectedMonitoringType === MonitoringType.FacebookListening && listeningPages && listeningPages.length"
            >
              <div class="monitoring-actions-search">
                <i class="ssi ssi-correct-search"></i>
                <input
                  placeholder="Search Facebook pages..."
                  type="search"
                  [(ngModel)]="filterListeningTerm"
                  (ngModelChange)="filterListeningPages()"
                >
                <!-- (ngModelChange)="onFilterTermChange()" -->
              </div>
            </ng-container>
            <ng-container
              *ngIf="selectedMonitoringType === MonitoringType.InstagramHashtags && hashtags && hashtags.length"
            >
              <div class="monitoring-actions-search">
                <i class="ssi ssi-correct-search"></i>
                <input
                  placeholder="Search Instagram Hashtags..."
                  type="search"
                  [(ngModel)]="filterHashtagTerm"
                  (ngModelChange)="filterHashtags()"
                >
              </div>
            </ng-container>
          </div>
          <div class="monitoring-actions-center">
            <span
              class="monitoring-actions-filter"
              [ngClass]="{'monitoring-actions-filter-active': selectedMonitoringType === MonitoringType.Reporting}"
              (click)="onMonitoringOptionChange(MonitoringType.Reporting);"
            >{{MonitoringType.Reporting}}</span>
            <span
              *ngIf="socialListeningEnabled"
              class="monitoring-actions-filter"
              [ngClass]="{'monitoring-actions-filter-active': selectedMonitoringType === MonitoringType.FacebookListening}"
              (click)="onMonitoringOptionChange(MonitoringType.FacebookListening);"
            >{{MonitoringType.FacebookListening}}</span>
            <span
              *ngIf="hashtagsEnabled"
              class="monitoring-actions-filter"
              [ngClass]="{'monitoring-actions-filter-active': selectedMonitoringType === MonitoringType.InstagramHashtags}"
              (click)="onMonitoringOptionChange(MonitoringType.InstagramHashtags);"
            >{{MonitoringType.InstagramHashtags}}</span>
            <!-- <span
              class="monitoring-actions-filter"
              [ngClass]="{'monitoring-actions-filter-active': selectedMonitoringType === MonitoringType.Inbox}"
              (click)="onMonitoringOptionChange(MonitoringType.Inbox);"
            >{{MonitoringType.Inbox}}</span> -->
          </div>
          <div class="monitoring-actions-right">
            <ng-container *ngIf="streams?.length && selectedMonitoringType === MonitoringType.Reporting">
              <ssi-dropdown-select-2
                [options]="sortOptionsIterable"
                [xPlacement]="'right'"
                [(ngModel)]="selectedStreamsSortOption"
                (ngModelChange)="onSortOptionChange()"
              >
                <ng-template
                  ssiTemplateSelector="headButton"
                  let-expanded="expanded"
                  let-toggle="toggle"
                >
                  <div
                    class="monitoring-actions-sort"
                    (click)="toggle()"
                  >
                    <span>Sort</span> <span>{{selectedStreamsSortOption.label}}</span> &nbsp; <i class="ssi ssi-sort"></i>
                  </div>
                </ng-template>
              </ssi-dropdown-select-2>
            </ng-container>
            <ng-container *ngIf="listeningPages?.length && selectedMonitoringType === MonitoringType.FacebookListening">
              <ssi-dropdown-select-2
                [options]="sortOptionsIterable"
                [xPlacement]="'right'"
                [(ngModel)]="selectedListeningSortOption"
                (ngModelChange)="onSortOptionChange()"
              >
                <ng-template
                  ssiTemplateSelector="headButton"
                  let-expanded="expanded"
                  let-toggle="toggle"
                >
                  <div
                    class="monitoring-actions-sort"
                    (click)="toggle()"
                  >
                    <span>Sort</span> <span>{{selectedListeningSortOption.label}}</span> &nbsp; <i class="ssi ssi-sort"></i>
                  </div>
                </ng-template>
              </ssi-dropdown-select-2>
            </ng-container>

            <ng-container *ngIf="hashtags?.length && selectedMonitoringType === MonitoringType.InstagramHashtags">
              <ssi-dropdown-select-2
                [options]="sortOptionsIterable"
                [xPlacement]="'right'"
                [(ngModel)]="selectedHashtagSortOption"
                (ngModelChange)="onSortOptionChange()"
              >
                <ng-template
                  ssiTemplateSelector="headButton"
                  let-expanded="expanded"
                  let-toggle="toggle"
                >
                  <div
                    class="monitoring-actions-sort"
                    (click)="toggle()"
                  >
                    <span>Sort</span> <span>{{selectedHashtagSortOption.label}}</span> &nbsp; <i class="ssi ssi-sort"></i>
                  </div>
                </ng-template>
              </ssi-dropdown-select-2>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isLoadingStreams">
        <div class="loading"></div>
      </ng-container>

      <ng-container *ngIf="!isLoadingStreams">
        <ng-container *ngIf="selectedMonitoringType === MonitoringType.Reporting">
          <div class="monitoring-row">
            <div *ngIf="!streams?.length">
              <div
                class="monitoring-new-enhanced-body"
                *ngIf="authUser?.hasCompanyPermission('manage_monitoring_streams')"
              >
                <h2>No streams</h2>
                <p>Build a stream for reporting</p>
                <orlo-button
                  class="monitoring-new-enhanced-body-action"
                  textColor="#fff"
                  bgColor="#12ACA4"
                  state="primary"
                  leadingIcon="ssi ssi-addsmall"
                  text="Create stream"
                  (click)="openMonitoringWizard()"
                ></orlo-button>
              </div>
            </div>

            <div
              *ngIf="streams?.length"
              class="monitoring-streams-grid"
            >
              <div
                class="monitoring-streams-grid-item"
                *ngIf="(filteredStreams.length || (!filteredStreams.length && filterTerm)) && authUser?.hasCompanyPermission('manage_monitoring_streams')"
              >
                <div class="insights-streams-tile">
                  <h3>New Stream</h3>
                  <p>Build a new stream for reporting</p>
                  <button
                    type="button"
                    class="insights-streams-tile-add"
                    (click)="openMonitoringWizard()"
                  >
                    <i class="ssi ssi-addsmall"></i>
                  </button>
                </div>
              </div>

              <ng-container *ngFor="let stream of streamsToRender">
                <ssi-stream-card
                  class="monitoring-streams-grid-item monitoring-streams-grid-item-reporting"
                  [stream]="stream"
                  [showEdit]="authUser?.hasCompanyPermission('manage_monitoring_streams')"
                  [showViewReport]="false"
                  (onEditButtonClick)="openMonitoringWizard(stream)"
                ></ssi-stream-card>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedMonitoringType === MonitoringType.FacebookListening">
          <div
            class="listening-container"
            [class.blank-view]="!listeningPages || (listeningPages && listeningPages.length === 0)"
          >
            <div
              class="call-to-action"
              *ngIf="!listeningPages || (listeningPages && listeningPages.length === 0) && hasFacebookAccounts"
            >
              <i class="ssi ssi-orlo-insights"></i>
              <h2>Create your group</h2>
              <p>Start adding the Facebook Pages you want to listen to</p>
              <span (click)="openCreateGroupModal()">
                <i class="ssi ssi-addsmall"></i>
              </span>
            </div>
            <!-- <button (click)="openEditGroupModal()">{{listeningGroup.name}} edit <i
              class="ssi ssi-correct-edit-small"></i></button> -->
            <!-- table start -->
            <div
              class="table-container"
              *ngIf="listeningPages && listeningPages.length > 0"
            >
              <ssi-treetable
                [data]="filteredListeningPages"
                [bodyStyle]="{'max-height': ''}"
                [rowStyle]="{'height': '68px'}"
                [virtualScroll]="false"
                [resizeMode]="'fit'"
                [reorderable]="true"
                [saveSettings]="false"
                [settingsStorageKey]="'treetable-insights-listening-pages'"
                [globalFilterInputRef]=""
                id="treetable-insights-listening-pages"
              >
                <ssi-column
                  [header]="'PAGE_NAME' | translate"
                  [sortable]="false"
                  field="name"
                  [templateRefs]="{bodyCell: nameCellTemplate}"
                >
                </ssi-column>
                <ssi-column
                  [header]="'HANDLE' | translate"
                  [sortable]="false"
                  field="username"
                  [templateRefs]="{bodyCell: nameCellTemplate}"
                ></ssi-column>
                <ssi-column
                  [header]="'DATE_ADDED' | translate"
                  [sortable]="false"
                  field="created_at"
                  [templateRefs]="{bodyCell: nameCellTemplate}"
                ></ssi-column>
                <ssi-column
                  [header]="'EDITOR' | translate"
                  [sortable]="false"
                  [field]="'createdBy.fullName'"
                  [templateRefs]="{bodyCell: nameCellTemplate}"
                ></ssi-column>
                <ssi-column
                  header=""
                  [sortable]="false"
                  [field]="' '"
                  [templateRefs]="{bodyCell: actionsCellTemplate}"
                ></ssi-column>

                <ssi-pagination
                  [itemsPerPage]="10"
                  [pageLinksSize]="5"
                ></ssi-pagination>
              </ssi-treetable>
              <button (click)="openEditGroupModal()">{{listeningGroup.name}} <i
                  class="ssi ssi-correct-edit-small"></i></button>

              <ng-template
                #nameCellTemplate
                ssiTemplateSelector="bodyCell"
                let-rowIndex="rowIndex"
                let-rowData="rowData"
                let-column="column"
                let-fieldValue="fieldValue"
              >
                <div
                  class="table-cell name-cell"
                  [class.row-odd]="rowIndex % 2 === 0"
                  [class.light]="column.field === 'created_at' || column.field === 'createdBy.fullName'"
                >
                  <ng-container *ngIf="column.field === 'created_at'">
                    <span>{{fieldValue | date: 'dd MMM yyyy'}}</span>
                  </ng-container>
                  <ng-container *ngIf="column.field !== 'created_at'">
                    <span>{{fieldValue}}</span>
                  </ng-container>
                </div>
              </ng-template>


              <ng-template
                #actionsCellTemplate
                ssiTemplateSelector="bodyCell"
                let-rowIndex="rowIndex"
                let-rowData="rowData"
              >
                <div
                  class="table-cell actions-cell"
                  [class.row-odd]="rowIndex % 2 === 0"
                >
                  <button
                    (click)="showRemovePageTooltip(rowIndex)"
                    #removePageTooltip="ssiTooltip"
                    [ssiTooltip]
                    [ssiTooltipId]="rowIndex"
                    tooltipWindowClass="confirm-action-tooltip-window"
                    tooltipPlacement="right"
                    tooltipTrigger="manual"
                    [tooltipTemplate]="removePageTooltipTemplate"
                  ><i class="ssi ssi-small-delete"></i></button>
                </div>

                <ng-template #removePageTooltipTemplate>
                  <ssi-confirm-action
                    (ssiOffClick)="closeAllRemovePageTooltips()"
                    title="Are you sure you want to delete this Facebook page? This cannot be undone"
                    dismissText="Cancel"
                    confirmText="Delete page"
                    confirmTextColour="#F88C68"
                    dismissTextColour="#43537f"
                    (onDismiss)="hideRemovePageTooltip(rowIndex)"
                    (onConfirm)="removePage(rowData)"
                  ></ssi-confirm-action>
                </ng-template>
              </ng-template>
            </div>
            <!-- table end -->

            <div
              class="call-to-action"
              *ngIf="!hasFacebookAccounts || (listeningPages && listeningPages.length === 0) && !hasFacebookAccounts"
            >
              <i class="ssi ssi-company-settings"></i>
              <h2>First things first</h2>
              <p>You will need to have a Facebook social account linked first</p>
              <a uiSref="auth.manageAccounts">Manage Social Accounts</a>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedMonitoringType === MonitoringType.InstagramHashtags">
          <div
            class="hashtag-container"
            [class.blank-view]="!hashtags || (hashtags && hashtags.length === 0)"
          >
            <div
              class="call-to-action"
              *ngIf="!hashtags || (hashtags && hashtags.length === 0) && hasInstagramAccounts"
            >
              <i class="ssi ssi-hashtag-empty-state"></i>
              <h2>Create your hashtags</h2>
              <p>Start adding the Instagram hashtags you want to listen to</p>
              <span (click)="openCreateHashtagModal()">
                <i class="ssi ssi-addsmall"></i>
              </span>
            </div>

            <div
              class="call-to-action"
              *ngIf="!hasInstagramAccounts || (hashtags && hashtags.length === 0) && !hasInstagramAccounts"
            >
              <i class="ssi ssi-company-settings"></i>
              <h2>First things first</h2>
              <p>You will need to have a Instagram social account linked first</p>
              <a uiSref="auth.manageAccounts">Manage Social Accounts</a>
            </div>

            <div
                class="table-container"
                *ngIf="hashtags && hashtags.length > 0"
              >
                <ssi-treetable
                  [data]="filteredHashtags"
                  [bodyStyle]="{'max-height': ''}"
                  [rowStyle]="{'height': '68px'}"
                  [virtualScroll]="false"
                  [resizeMode]="'fit'"
                  [reorderable]="true"
                  [saveSettings]="false"
                  [settingsStorageKey]="'treetable-insights-hashtags'"
                  [globalFilterInputRef]=""
                  id="treetable-insights-hashtags"
                >
                  <ssi-column
                    [header]="'HASHTAG' | translate"
                    [sortable]="false"
                    field="name"
                    [templateRefs]="{bodyCell: nameCellTemplate}"
                  >
                  </ssi-column>
                  <ssi-column
                    [header]="'DATE_ADDED' | translate"
                    [sortable]="false"
                    field="created_at"
                    [templateRefs]="{bodyCell: nameCellTemplate}"
                  ></ssi-column>
                  <ssi-column
                    [header]="'EDITOR' | translate"
                    [sortable]="false"
                    [field]="'createdBy.fullName'"
                    [templateRefs]="{bodyCell: nameCellTemplate}"
                  ></ssi-column>
                  <ssi-column
                    header=""
                    [sortable]="false"
                    [field]="' '"
                    [templateRefs]="{bodyCell: actionsCellTemplate}"
                  ></ssi-column>

                  <ssi-pagination
                    [itemsPerPage]="10"
                    [pageLinksSize]="5"
                  ></ssi-pagination>
                </ssi-treetable>

                <ng-template
                  #nameCellTemplate
                  ssiTemplateSelector="bodyCell"
                  let-rowIndex="rowIndex"
                  let-rowData="rowData"
                  let-column="column"
                  let-fieldValue="fieldValue"
                >
                  <div
                    class="table-cell name-cell"
                    [class.row-odd]="rowIndex % 2 === 0"
                    [class.light]="column.field === 'created_at' || column.field === 'createdBy.fullName'"
                  >
                    <ng-container *ngIf="column.field === 'created_at'">
                      <span>{{fieldValue | date: 'dd MMM yyyy'}}</span>
                    </ng-container>
                    <ng-container *ngIf="column.field !== 'created_at'">
                      <span>{{fieldValue}}</span>
                    </ng-container>
                  </div>
                </ng-template>


                <ng-template
                  #actionsCellTemplate
                  ssiTemplateSelector="bodyCell"
                  let-rowIndex="rowIndex"
                  let-rowData="rowData"
                >
                  <div
                    class="table-cell actions-cell"
                    [class.row-odd]="rowIndex % 2 === 0"
                  >
                    <button
                      (click)="openDeleteHashtagModal(rowData)"
                    ><i class="ssi ssi-small-delete"></i></button>
                  </div>
                </ng-template>
              </div>
              <!-- table end -->

          </div>
        </ng-container>

        <ssi-paginator
          *ngIf="streams?.length && selectedMonitoringType === MonitoringType.Reporting"
          [itemsCount]="filteredStreams?.length"
          [pageLinksSize]="5"
          [itemsPerPage]="9"
          (onPageChange)="onPageChange($event)"
        ></ssi-paginator>
      </ng-container>

    </div>
  </div>
</div>