import {
  Component,
  Input,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  SecurityContext
} from '@angular/core';
import { Activity } from '@ui-resources-angular';
import { nl2br } from '../../util';
import twitterText from 'twitter-text';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ssi-activity-text',
  template: '<div #element [innerHTML]="contentHTML"></div>'
})
export class ActivityTextComponent implements OnChanges, AfterViewInit {
  @Input() activity: Activity;
  @Input() cropContent: boolean;
  @Input() isTranslatable: boolean;
  @Output() onReadMoreClick = new EventEmitter();
  @Output() onMentionClick = new EventEmitter<{ profileId: string }>();
  contentHTML: SafeHtml;
  showAllMentions = false;
  textTruncated = false;
  mentionsTruncted = false;
  @ViewChild('element') public element: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cropContent && this.activity && this.activity.interaction) {
      this.sanitizeActivityContent();
      this._setActivityText();
    }

    if (changes.activity && this.activity && this.activity.interaction) {
      this.sanitizeActivityContent();
      this.activity.interaction.original_content = this.activity.interaction.content;
      this._compileHtml();
      this._setActivityText();
    }
  }

  ngAfterViewInit() {
    if (this.element.nativeElement.querySelector('[data-readmore-link]')) {
      this._addReadMoreEvent();
    }

    if (this.element.nativeElement.querySelector('[data-mention-value]')) {
      this._addMentionClickEvents();
    }

    if (this.element.nativeElement.querySelector('[data-mention-expand]')) {
      const expandElement = this.element.nativeElement.querySelector(
        `[data-mention-expand]`
      );
      this.renderer.listen(expandElement, 'click', (event) => {
        event.stopPropagation();
        this.expandMentions();
      });
    }
  }

  sanitizeActivityContent() {
    this.activity.interaction.content = this.sanitizer.sanitize(
      this.isTranslatable ? SecurityContext.NONE : SecurityContext.HTML,
      this.activity.interaction.content
    );

    if (this.activity.interaction.content) {
      // $sanitize for some reason converts new line (\n) to HTML entity new line (&#10;) when sanitizing.
      // Bring it back because twitterText does not seem to recognize it and is unable to extract entities (urls, mentions, hashtags, etc.) correctly from text later on in the process below. See CT-2720.
      this.activity.interaction.content = this.activity.interaction.content.replace(
        /&#10;/g,
        '\n'
      );
    }
  }

  private _addMentionClickEvents() {
    const mentionElements = this.element.nativeElement.querySelectorAll(
      `[data-mention-value]`
    );
    Array.from(mentionElements).forEach((mention: HTMLElement) => {
      const profileId = mention.getAttribute('data-mention-value');
      this.renderer.listen(mention, 'click', () =>
        this.onMentionClick.emit({ profileId })
      );
    });
  }

  private _addReadMoreEvent() {
    const readmoreElement = this.element.nativeElement.querySelector(
      `[data-readmore-link]`
    );
    this.renderer.listen(readmoreElement, 'click', (event) => {
      event.stopPropagation();
      this.textTruncated = false;
      this.onReadMoreClick.emit();
    });
  }

  private _compileHtml() {
    if (this.activity.account.isTwitter()) {
      const mentions =
        this.activity.interaction &&
        this.activity.interaction.entities &&
        this.activity.interaction.entities.user_mentions;

      const usernames = Array.isArray(mentions)
        ? mentions.map((u) => `@${u.screen_name}`)
        : [];

      const transformed = usernames.join(' ');
      const shouldTruncateMentions =
        usernames.length > 2 &&
        this.activity.interaction.content &&
        this.activity.interaction.content.indexOf(transformed) > -1;

      if (this.showAllMentions) {
        this.mentionsTruncted = false;
        this.activity.interaction.content = this.activity.interaction.original_content;
      } else if (shouldTruncateMentions) {
        this.mentionsTruncted = true;
        this.activity.interaction.content = this.activity.interaction.content.replace(
          transformed,
          `${usernames
            .slice(0, 2)
            .join(' ')} <a href="javascript:;" data-mention-expand>and ${
            usernames.length - 2
          } more</a>`
        );
      }
    }

    const linkifiedText = this._getLinkifiedActivityText(
      this.activity.interaction.content,
      this.activity.interaction.entities,
      this.activity.account
    );
    const linkifiedTextWithLineBreaks = nl2br(linkifiedText);
    const content = `<span ssi-emoji-text>${linkifiedTextWithLineBreaks}</span>`;
    this.contentHTML = this.sanitizer.bypassSecurityTrustHtml(content);
    this.cdr.detectChanges();
    if (this.element.nativeElement.querySelector('[data-mention-value]')) {
      this._addMentionClickEvents();
    }
    if (this.element.nativeElement.querySelector('[data-readmore-link]')) {
      this._addReadMoreEvent();
    }
  }

  private _setActivityText() {
    const maxLength = 240;
    const cropContent =
      this.cropContent && this.activity.interaction.content.length > maxLength;
    let croppedActivityContent;

    if (cropContent) {
      const croppedContent = this.activity.interaction.content.substr(
        0,
        maxLength
      );
      const trimmedCroppedContent = croppedContent
        .substr(
          0,
          Math.min(croppedContent.length, croppedContent.lastIndexOf(' '))
        )
        .trim();
      croppedActivityContent = trimmedCroppedContent;
    }

    const linkifiedText = this._getLinkifiedActivityText(
      croppedActivityContent || this.activity.interaction.content,
      this.activity.interaction.entities,
      this.activity.account
    );
    const linkifiedTextWithLineBreaks = nl2br(linkifiedText);
    let content = `<span ssi-emoji-text>${linkifiedTextWithLineBreaks}</span>`;

    if (cropContent) {
      const readMore = `<span data-readmore-link class="activity-text-read-more">Read More</span>`;
      content = `${content}... ${readMore}`;
      this.textTruncated = true;
    }
    this.contentHTML = this.sanitizer.bypassSecurityTrustHtml(content);
  }

  private _getLinkifiedActivityText(
    activityContent,
    activityEntities,
    activityAccount
  ) {
    const replacements = [];

    if (Array.isArray(activityEntities && activityEntities.user_mentions)) {
      const prefix = activityAccount.isTwitter() ? '@' : '';
      activityEntities.user_mentions
        .filter((entity) => this._entityVisible(entity, activityContent))
        .filter((entity) => this._indicesMatch(entity, activityContent, prefix))
        .forEach((entity) => {
          replacements.push({
            indices: entity.indices,
            html: `<a href="javascript:;" data-mention-value="${entity.id}">${prefix}${entity.screen_name}</a>`
          });
        });
    }

    // use twitterText for hastags (works for other SNs as well) - there is no advantage using backend provided hashtags
    // since backend is also parsing text looking for hashtags using regex
    const hashtagEntities = twitterText.extractHashtagsWithIndices(
      activityContent
    );
    if (Array.isArray(hashtagEntities) && activityAccount.socialNetwork) {
      hashtagEntities
        .filter((entity) => this._entityVisible(entity, activityContent))
        .forEach((entity) => {
          replacements.push({
            indices: entity.indices,
            html: `<a href="${
              activityAccount.socialNetwork.urlBases.hashtag
            }${encodeURIComponent(entity.hashtag)}" target="_blank">#${
              entity.hashtag
            }</a>`
          });
        });
    }

    // use twitterText for urls (works for other SNs as well) - backend provided urls are
    // incomplete, e.g. test.com isn't recognized as url (without protocol part)
    const urlEntities = twitterText.extractUrlsWithIndices(activityContent);
    if (Array.isArray(urlEntities)) {
      urlEntities
        .filter((entity) => this._entityVisible(entity, activityContent))
        .forEach((entity) => {
          const displayUrl = entity.url;
          if (!entity.url.startsWith('http')) {
            entity.url = `http://${entity.url}`;
          }
          replacements.push({
            indices: entity.indices,
            html: `<a href="${entity.url}" target="_blank">${displayUrl}</a>`
          });
        });
    }

    // reverse order replacements so the replacement indices wont get messed up
    replacements.sort((a, b) => b.indices[0] - a.indices[0]);

    // console.log('replacements: ', replacements);
    let html = activityContent;
    if (html) {
      for (const replacement of replacements) {
        const before = html.substr(0, replacement.indices[0]);
        const after = html.substr(replacement.indices[1]);
        html = `${before}${replacement.html}${after}`;
      }
    }

    return html;
  }

  private _entityVisible(
    entity: {
      indices: number[];
    },
    activityContent: string
  ) {
    if (!this.textTruncated) {
      return true;
    }
    // entity is outside of the visible text?
    return entity.indices[1] < activityContent.length;
  }

  private _indicesMatch(
    entity: {
      indices: number[];
      screen_name?: string;
      text?: string;
      display_url?: string;
    },
    activityContent: string,
    prefix = ''
  ) {
    const entityValue = entity.screen_name || entity.display_url || entity.text;
    const match =
      prefix + entityValue ===
      activityContent.slice(entity.indices[0], entity.indices[1]);

    if (!match && !this.mentionsTruncted) {
      // for trackjs
      console.error(
        'Entity indices do not match: ',
        JSON.stringify(entity),
        activityContent
      );
    }

    return match;
  }

  public expandMentions() {
    this.showAllMentions = true;
    this._compileHtml();
  }
}
