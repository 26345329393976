import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitySnippetsModule } from '../../../directives/activity-snippets/activity-snippets.module';
import { LiveChatMessageTagsModule } from '../live-chat-message-tags/live-chat-message-tags.module';
import { LiveChatConversationResponseComponent } from './live-chat-conversation-response.component';
import { EmojiFormControlContainerModule } from '../../../directives/emoji-form-control-container/emoji-form-control-container.module';

@NgModule({
  declarations: [LiveChatConversationResponseComponent],

  exports: [LiveChatConversationResponseComponent],

  imports: [
    ActivitySnippetsModule,
    CommonModule,
    EmojiFormControlContainerModule,
    FormsModule,
    LiveChatMessageTagsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class LiveChatConversationResponseModule {}
