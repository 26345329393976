import { FilestackSources } from '@orlo/common/services/filestack/filestack.service';

export const acceptedFileTypesForImages = [
  `image/gif`,
  `image/jpeg`,
  `image/jpg`,
  `image/png`
];

export const permittedSources = [
  FilestackSources.LocalFileSystem,
  FilestackSources.CustomSource,
  FilestackSources.ImageSearch,
  FilestackSources.Dropbox,
  FilestackSources.Url,
  FilestackSources.Webcam,
  FilestackSources.Googledrive
];

export const maximumFileSizeForImagesInBytes = 5000000;
