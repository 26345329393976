import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { deepValue } from '@jsier/deep-value';
import { AccountTypeIdString } from '../../../../../../common/enums';
import { Account } from '@ui-resources-angular';
import { isNumeric, mapToIterable } from '../../../../../../common/utils';

import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';
import { DateRanges, TotalsStats } from '../../marketing.service';

@Component({
  selector: 'ssi-account-summary',
  templateUrl: './account-summary.component.html',
  styles: [],
  styleUrls: ['./account-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSummaryComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() dateRanges: DateRanges;
  @Input() stats: TotalsStats;

  deepValue = deepValue;
  viewExactTotalsChecked = false;
  dataToDisplay: any[] = [];

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  showTypes = [
    { id: 'accounts', label: 'Account totals' },
    { id: 'networks', label: 'Network totals' }
  ];
  selectedShowType = this.showTypes[0];

  AccountTypeIdString = AccountTypeIdString;

  constructor() {}

  ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return this.accounts.some((a) => a.account_type_id === n.key);
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);
    this.selectedNetwork = this.selectableNetworks[0];

    this.setDataToDisplay(this.selectedShowType);
  }

  setDataToDisplay(showType: any): void {
    this.dataToDisplay =
      showType === this.showTypes[0]
        ? this.stats.totals
        : mapToIterable(this.stats.totalsByAccountType);

    if (this.selectedNetwork.key !== 'all') {
      this.dataToDisplay = this.dataToDisplay.filter(
        (x) => x.account.account_type_id === this.selectedNetwork.key
      );
    }
  }

  onNetworkSelect(sn: NetworkSelectItem): void {
    this.selectedNetwork = sn;
    this.setDataToDisplay(this.selectedShowType);
  }

  onShowTypeChange(showType: any): void {
    this.selectedShowType = showType;
    this.setDataToDisplay(this.selectedShowType);
  }

  splitColumnFieldValue(field: string): string {
    return field.split('.')[1];
  }

  onViewExactTotalsToggled(checked: boolean): void {
    // console.log('viewExactTotalsChecked: ', checked);
  }

  /**
   * Format engagement rate by passing a value and returning non-rounded string.
   * Avoided toFixed(num) method to avoid rounding
   * @param value raw value returned from the API
   * @returns `number` or `string` value representing the engagement rate
   */
  formatEngagementRate(value: number): number | string {
    return typeof value === 'number' ? value.toString().slice(0, 5) : value;
  }

  isNumber(value: any): boolean {
    return isNumeric(value);
  }
}
