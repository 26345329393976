import { Component, ViewEncapsulation } from '@angular/core';

import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { Conversation as ConversationModel } from '@orlo/library/models/live-chat/conversation';
import { Team } from '@orlo/library/interfaces/live-chat/team';

@Component({
  selector: 'ssi-live-chat-dashboard',
  templateUrl: './live-chat-dashboard.component.html',
  styles: [],
  styleUrls: ['./live-chat-dashboard.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatDashboardComponent {
  constructor(private liveChatService: LiveChatService) {}

  public get activeConversation(): ConversationModel {
    return this.liveChatService.activeConversation;
  }

  public get hasActiveConversation(): boolean {
    return !!this.liveChatService.activeConversation;
  }

  public get quantityOfAssignedConversations(): number {
    return this.liveChatService.quantityOfAssignedConversations;
  }

  public get quantityOfTotalConversations(): number {
    return this.liveChatService.quantityOfTotalConversations;
  }

  public get quantityOfUnassignedConversations(): number {
    return this.liveChatService.quantityOfUnassignedConversations;
  }

  public get stalestConversation(): ConversationModel {
    return this.liveChatService.stalestConversation;
  }

  public get teams(): Team[] {
    return this.liveChatService.teams;
  }
}
