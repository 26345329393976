import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import {
  FilestackService,
  FilestackPickOptions
} from '../filestack/filestack.service';
import { permittedSources } from '@orlo/library/constants/filestack';
import { SnackbarService } from '../snackbar/snackbar.service';

export interface ChatBot {
  id?: string;
  name: string;
  metabase_id: string;
  metabase?: Metabase;
  messages?: ChatBotMessage;
}

export interface ChatBotMessageData {
  on_enter: { text: string }[];
  no_topic_match?: { text: string }[];
}

export interface ChatBotMessage {
  welcome: ChatBotMessageData;
  another_question: ChatBotMessageData;
  did_that_help: ChatBotMessageData;
  talk_to_agent: ChatBotMessageData;
  anything_else: ChatBotMessageData;
  connecting_to_agent: ChatBotMessageData;
  done: ChatBotMessageData;
}

export interface Metabase {
  id: string;
  company_id?: string;
  name: string;
}

export interface Topic {
  id?: string;
  MB_id?: string;
  is_deleted?: boolean;
  is_enabled: boolean;
  is_handover_request: boolean;
  is_no_handover: boolean;
  is_conversational: boolean;
  aggergate_vector?: string[];
  name: string;
  response: string;
  link: string;
  link_text?: string;
}

export interface Example {
  id: string;
  type: string;
  text: string;
  topic_id?: string;
}

export enum TopicResponseType {
  HANDOVER = 'handover',
  NO_HANDOVER = 'no-handover',
  CONVERSATIONAL = 'conversational',
  DEFAULT = 'default'
}

@Injectable()
export class ChatBotService {
  constructor(
    private api: API,
    private filestack: FilestackService,
    private snackbar: SnackbarService
  ) {}

  getChatBotList(): Promise<ChatBot[]> {
    return this.api.get('chatbot/botIndex').then((res) => {
      return res.data.chatbots;
    });
  }

  async createChatBot(newChatBot: ChatBot) {
    return await this.api
      .post('chatbot/botIndex', newChatBot)
      .then(({ data }) => data.id);
  }

  async editChatBot(chatbot: ChatBot, id: string) {
    delete chatbot.messages;
    await this.api.post(`chatbot/botIndex?bot_id=${id}`, {
      ...chatbot,
      _method: 'PATCH'
    });
  }

  getMetabaseList(): Promise<Metabase[]> {
    return this.api
      .get('chatbot/metabaseIndex')
      .then((res) => res.data.metabases);
  }

  createMetabaseList(newMetabaseName: string) {
    this.api.post('chatbot/metabaseIndex', { name: newMetabaseName });
  }

  async editMetabase(id: string, name: string) {
    await this.api.post(`chatbot/metabaseIndex?metabase_id=${id}`, {
      name,
      _method: 'PATCH'
    });
  }

  queueScrapeTopics(metabase_id: string, url: string) {
    this.api.post('chatbot/metabaseScrape', { metabase_id, url });
  }

  async uploadCSV(metabase_id: string) {
    const client = await this.filestack.getClient();
    const options: FilestackPickOptions = {
      accept: ['text/csv', '.csv'],
      fromSources: permittedSources,
      maxFiles: 1,
      onUploadDone: async (result) => {
        if (result) {
          await this.api.post('chatbot/metabaseCsvImporter', {
            metabase_id,
            url: result.filesUploaded[0].url
          });
          this.snackbar.open('Uploaded CSV', { duration: 5 });
        }
      }
    };
    client.picker(options).open();
  }

  getTopicList(metabase_id: string): Promise<Topic[]> {
    return this.api
      .get('chatbot/metabaseTopic', { params: { metabase_id } })
      .then((res) => res.data.topics);
  }

  createTopic(metabase_id: string, topic: Topic): Promise<string> {
    return this.api
      .post('chatbot/metabaseTopic', { metabase_id, ...topic })
      .then(({ data }) => data.id);
  }

  editTopic(metabase_id: string, topic_id: string, newValues: Partial<Topic>) {
    this.api.post('chatbot/metabaseTopic', {
      metabase_id,
      topic_id,
      ...newValues,
      _method: 'PATCH'
    });
  }

  deleteTopic(metabase_id: string, topic_id: string) {
    this.api.del('chatbot/metabaseTopic', {
      params: { metabase_id, topic_id }
    });
  }

  deleteMetabase(metabase_id: string) {
    this.api.del('/chatbot/metabaseIndex', {
      params: { metabase_id }
    });
  }

  deleteChatbot(bot_id: string) {
    this.api.del('/chatbot/botIndex', {
      params: { bot_id }
    });
  }

  getTopicExamples(metabase_id: string, topic_id: string): Promise<Example[]> {
    return this.api
      .get('chatbot/metabaseTopic', { params: { metabase_id, topic_id } })
      .then((res) => res.data.examples);
  }

  createExample(metabase_id: string, topic_id: string, text: string) {
    this.api.post('chatbot/metabaseTopicExample', {
      metabase_id,
      topic_id,
      text
    });
  }

  editExample(
    metabase_id: string,
    topic_id: string,
    example_id: string,
    text: string
  ) {
    this.api.post('chatbot/metabaseTopicExample', {
      metabase_id,
      topic_id,
      example_id,
      text,
      _method: 'PATCH'
    });
  }

  deleteExample(metabase_id: string, topic_id: string, example_id: string) {
    this.api.del('chatbot/metabaseTopicExample', {
      params: { metabase_id, topic_id, example_id }
    });
  }
}
