import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { decodeStateParams } from '@orlo/common/utils';

@Component({
  selector: 'ssi-embed-widget',
  templateUrl: './embed-widget.component.html',
  styles: [],
  styleUrls: ['./embed-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmbedWidgetComponent implements OnInit {
  activeView = 'website';
  widget;
  decodedStateParams: { [key: string]: any };

  constructor(public api: API, private state: StateService) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    if (!this.decodedStateParams.widget) {
      this.state.go('^');
    } else {
      this.widget = this.decodedStateParams.widget;
    }
  }

  return() {
    this.state.go('^');
  }
}
