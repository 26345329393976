<div class="settings-nav">
  <button class="orlo-button" uiSref="auth.settings.index">
    <i class="ssi ssi-chevron-left"></i> <span translate="BACK"></span>
  </button>
  <ul>
    <li><i class="ssi ssi-dashboard"  uiSref="auth.settings.index"></i></li>
    <li><i class="ssi ssi-mysettings" uiSref="auth.settings.mysettings"></i></li>
    <li><i class="ssi ssi-websitewidget" uiSref="auth.settings.accountsettings"></i></li>
    <li><i class="ssi ssi-companysettings" uiSref="auth.settings.companysettings"></i></li>
  </ul>
  <h1>Authentication settings</h1>
</div>
<div class="container container-app-page">
  <ssi-alerts></ssi-alerts>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-btn">
      <h3 class="panel-title"><i class="fa fa-user"></i> <span translate="USER_SECURITY"></span></h3>
      <div class="clearfix"></div>
    </div>
    <div class="panel-body">
      <div class="form-horizontal" role="form">
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="TWOFACTOR_AUTHENTICATION"></label>
          <div class="col-sm-9">
            <select [(ngModel)]="settings.company_preferences.two_factor" class="form-control">
              <option value="1" translate="ON"></option>
              <option value="0" translate="OFF"></option>
            </select>
          </div>
        </div>
        <p class="alert alert-info" translate="REQUIRE_USERS_TO_LOGIN_WITH_TWOFACTOR_AUTHENTICATION_REQUIRED_USERS_MOBILE_PHONE_NUMBER"></p>
        <hr/>
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="ALLOW_EMAIL_AND_PASSWORD_LOGIN"></label>
          <div class="col-sm-9">
            <select [(ngModel)]="settings.company_preferences.can_login_with_password" class="form-control">
              <option value="1" translate="ON"></option>
              <option value="0" translate="OFF"></option>
            </select>
          </div>
        </div>
        <p class="alert alert-info" translate="ALLOW_USERS_TO_LOGIN_WITH_AN_EMAIL_ADDRESS_AND_A_PASSWORD"></p>
        <hr>
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="ALLOW_LOGIN_WITH_A_GOOGLE_ACCOUNT"></label>
          <div class="col-sm-9">
            <select [(ngModel)]="settings.company_preferences.can_login_with_google" class="form-control">
              <option value="1" translate="ON"></option>
              <option value="0" translate="OFF"></option>
            </select>
          </div>
        </div>
        <p class="alert alert-info" translate="IF_YOUR_ORGANISATION_USES_GOOGLE_APPS_FOR_YOUR_COMPANY_EMAILS_THEN_ENABLE_THIS_OPTION_TO_ALLOW_ONE_CLICK_LOGIN_USING_YOUR_COMPANIES_EMAIL_ACCOUNT"></p>
        <hr>
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="ALLOW_LOGIN_WITH_A_MICROSOFT_ACCOUNT"></label>
          <div class="col-sm-9">
            <input
              id="tenant"
              type="text"
              class="form-control"
              name="tenant"
              [(ngModel)]="settings.company_preferences.azure_tenant_id"
            >
          </div>
        </div>
        <p class="alert alert-info" translate="IF_YOUR_ORGANISATION_USES_MICROSOFT_APPS_FOR_YOUR_COMPANY_EMAILS_THEN_ENABLE_THIS_OPTION_TO_ALLOW_ONE_CLICK_LOGIN_USING_YOUR_COMPANIES_EMAIL_ACCOUNT"></p>
      </div>
    </div>
    <div class="panel-footer text-right">
      <button type="button" class="btn btn-success" (click)="saveSettings()">
        <i class="fa fa-floppy-o"></i> <span translate="SAVE"></span>
      </button>
    </div>
  </div>

  <form class="panel panel-default" *ngIf="betaOkta" #samlForm="ngForm">
    <div class="panel-heading">
      <h3 class="panel-title">
        <i class="fa fa-lock"></i>
        <span translate="SAML_AUTHENTICATION"></span>
      </h3>
    </div>
    <div class="panel-body">
      <div class="alert alert-info">
        <!-- <div
          translate="TO_ADD_SOCIALSIGNIN_TO_YOUR_SSO_PROVIDER_YOU_WILL_REQUIRE_YOUR_COMPANY_UUID_THIS_IS_STRONG_UUID_STRONG"
          translate-values="{uuid: authUser.company_uuid}">
        </div> -->
        <div>
          To add Orlo to your SSO provider you will require your Company UUID, this is <strong>{{ authUser.company_uuid }}</strong>
        </div>
      </div>
      <div class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label" translate="SELECT_A_PROVIDER"></label>
          <div class="col-sm-9">
            <select
              class="form-control"
              name="provider"
              [(ngModel)]="selectedIdentityProvider.name"
              (ngModelChange)="identityProviderChanged(selectedIdentityProvider.name)"
              required>
              <option value="" translate="SELECT_A_PROVIDER"></option>
              <option
                *ngFor="let provider of allSamlIdentities"
                [value]="provider.name">
                {{provider.display_name}}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIdentityProvider.name">
          <hr>
          <div class="form-group">
            <label class="col-sm-3 control-label" translate="IDENTITY_PROVIDER_ISSUER"></label>
            <div class="col-sm-9">
              <input
                class="form-control"
                type="text"
                name="issuer"
                [(ngModel)]="selectedIdentityProvider.entity_id"
                [required]="selectedIdentityProvider.name">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label" translate="SAML_20_ENDPOINT_HTTP"></label>
            <div class="col-sm-9">
              <input
                class="form-control"
                type="text"
                name="endpoint"
                [(ngModel)]="selectedIdentityProvider.sso_url"
                [required]="selectedIdentityProvider.name">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label" translate="PUBLIC_CERTIFICATE"></label>
            <div class="col-sm-9">
              <textarea
                class="form-control"
                rows="4"
                name="cert"
                [(ngModel)]="selectedIdentityProvider.cert"
                [required]="selectedIdentityProvider.name">
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer text-right">
      <button
        type="button"
        class="btn btn-danger"
        (click)="deleteIdentityProvider(selectedIdentityProvider)"
        *ngIf="selectedIdentityProvider.id">
        <i class="fa fa-trash-o"></i> <span translate="DELETE"></span>
      </button>
      <button
        type="button"
        class="btn btn-success"
        [disabled]="!samlForm.valid"
        (click)="saveIdentityProvider()">
        <i class="fa fa-floppy-o"></i> <span translate="SAVE"></span>
      </button>
    </div>
  </form>
</div>
