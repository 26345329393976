<div #actionsMenu
     class="message-box-actions-menu"
     [ngClass]="{'message-box-actions-menu-selfpenned': selfPenned}">
  <ul class="message-box-actions-menu-items">
    <ng-container *ngIf="!activeItem || activeItem === 'priority'">
      <li
        *ngIf="isInbox && !selfPenned"
        [ngClass]="{'message-box-actions-menu-item-active': activeItem === 'priority'}"
        class="message-box-actions-menu-item message-box-actions-menu-priority"
      >
        <div
          class="message-box-actions-menu-item-button"
          (click)="itemExpanded.emit('priority')"
        >
          <i class="menu-item-icon ssi ssi-priority priority-icon"></i>
          <p class="menu-item-title">
            {{activity?.inbox.priority ? 'Change Priority' : 'Set Priority'}}
          </p>
        </div>
        <div
          *ngIf="activeItem === 'priority'"
          class="message-box-actions-menu-item-body"
        >
          <ssi-inbox-priority-select
            [selectedPriority]="activity?.inbox.priority"
            (prioritySelect)="activity.changePriority($event)"
          ></ssi-inbox-priority-select>
        </div>
      </li>
    </ng-container>
    
    <ng-container *ngIf="!activeItem || activeItem === 'sentiment'">
      <li *ngIf="isInbox && !selfPenned && !activity.account?.isYoutube()"
          [ngClass]="{'message-box-actions-menu-item-active': activeItem === 'sentiment'}"
          class="message-box-actions-menu-item message-box-actions-menu-sentiment">
        <div class="message-box-actions-menu-item-button"
            (click)="itemExpanded.emit('sentiment')">
          <i class="menu-item-icon ssi ssi-amend-sentiment-new"></i>
          <p class="menu-item-title">
            Amend Sentiment
          </p>
        </div>
        <div *ngIf="activeItem === 'sentiment'"
            class="message-box-actions-menu-item-body">
          <ssi-sentiment-list [sentiment]="sentiment"
                              (changeSentiment)="changeSentiment.emit($event)"></ssi-sentiment-list>
        </div>
      </li>
    </ng-container>
    <ng-container *ngIf="!activeItem || activeItem === 'tag'">
      <li *ngIf="isInbox && !selfPenned"
          [ngClass]="{'message-box-actions-menu-item-active': activeItem === 'tag'}"
          class="message-box-actions-menu-item message-box-actions-menu-tabs">
        <div *ngIf="activeItem !== 'tag'"
            class="message-box-actions-menu-item-button"
            (click)="itemExpanded.emit('tag')">
          <!-- <i class="menu-item-icon ssi ssi-tag-test"></i> -->
          <p class="menu-item-title">
            {{activeTags?.length ? 'Edit Message Tag' : 'Add Message Tag'}}
          </p>
        </div>
        <!-- <i *ngIf="activeItem === 'tag'"
          class="menu-item-icon ssi ssi-tag-test"></i> -->
        <div *ngIf="activeItem === 'tag'"
            class="message-box-actions-menu-item-body">
          <ssi-multi-select [items]="allTags"
                            [activeItems]="activeTags"
                            [showCreateNewOption]="createNewTagsOption"
                            (changeSelectedItems)="changeActiveTags.emit($event)"></ssi-multi-select>
        </div>
      </li>
    </ng-container>
    <ng-container *ngIf="!activeItem || activeItem === 'notes'">
      <li *ngIf="isInbox"
          [ngClass]="{'message-box-actions-menu-item-active': activeItem === 'notes'}"
          class="message-box-actions-menu-item message-box-actions-menu-notes">
        <div class="message-box-actions-menu-item-button"
             (click)="itemExpanded.emit('notes')">
          <i class="menu-item-icon ssi ssi-orlo-note"></i>
          <p class="menu-item-title">
            Add Note
          </p>
        </div>
        <div *ngIf="activeItem === 'notes'"
             class="message-box-actions-menu-item-body">
          <ssi-add-note (addNote)="addNote.emit($event)"
                        (cancelNote)="close.emit()"></ssi-add-note>
        </div>
      </li>
    </ng-container>
  </ul>
</div>