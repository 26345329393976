import { ViewEncapsulation } from '@angular/core';

import {
  Input,
  Component,
  Renderer2,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'ssi-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmActionComponent {
  @Input() buttonState: string = 'text';
  @Input() title: string;
  @Input() dismissText: string;
  @Input() confirmText: string;
  @Input() dismissTextColour?: string = '#b4bbce';
  @Input() confirmTextColour?: string = '#e29e8c';

  @Output() onDismiss = new EventEmitter();
  @Output() onConfirm = new EventEmitter();

  constructor() {}

  dismiss() {
    this.onDismiss.emit();
  }

  confirm() {
    this.onConfirm.emit();
  }
}
