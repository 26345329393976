import { ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';


@Component({
  selector: 'ssi-analytics',
  templateUrl: './analytics.component.html',
  styles: [],
  styleUrls: ['./analytics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticsComponent {
  constructor() {}
}
