import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Nl2brModule } from '../../../pipes/nl2br/nl2br.module';

import { LiveChatMessageModule } from '../live-chat-message/live-chat-message.module';
import { LiveChatActivityTreeComponent } from './live-chat-activity-tree.component';

import { LiveChatInlineNotesModule } from '../live-chat-inline-notes/live-chat-inline-notes.module';

@NgModule({
  declarations: [LiveChatActivityTreeComponent],

  exports: [LiveChatActivityTreeComponent],

  imports: [
    CommonModule,
    LiveChatInlineNotesModule,
    LiveChatMessageModule,
    Nl2brModule,
    TranslateModule
  ]
})
export class LiveChatActivityTreeModule {}
