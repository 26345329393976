import { ViewEncapsulation } from '@angular/core';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import moment from 'moment';

import { Debounce } from '../../../../../common/decorators';

@Component({
  selector: 'ssi-report-header',
  templateUrl: './report-header.component.html',
  styles: [],
  styleUrls: ['./report-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportHeaderComponent implements OnInit {
  now = new Date();
  reportSearchDates = {
    from: null,
    to: null
  };
  reportDefaultStartDate = this.formatToDate('lastYear');
  toggleControls = false;

  keyword: string;
  toggleSearch = false;

  @Input() report: string;
  @Input() name: string;
  @Input() dateRangesTemplate: TemplateRef<any>;
  @Input() reportStartDate;
  @Input() reportEndDate;
  @Input() canChangeDates = true;
  @Input() supportsKeyword = false;

  @Output() onKeywordSubmit = new EventEmitter<string>();
  @Output() onDateRangeClicked = new EventEmitter();
  @Output() dateSelected = new EventEmitter<{ from: string; to: string }>();

  constructor() {}

  ngOnInit() {}

  @Debounce(400, false)
  keywordInput(event) {
    this.onKeywordSubmit.emit(this.keyword);
  }

  reportChangeDate(timespan) {
    const from = this.formatToDate(timespan);
    this.reportStartDate = moment(from).format('YYYY-MM-DD');

    this.dateSelected.emit({ from, to: moment(this.now).format('YYYY-MM-DD') });
    this.toggleControls = false;
  }

  reportChangeDateCustom() {
    if (!moment(this.reportSearchDates.from).isValid()) {
      return;
    }

    if (!moment(this.reportSearchDates.to).isValid()) {
      return;
    }

    this.reportStartDate = moment(this.reportSearchDates.from).format(
      'YYYY-MM-DD'
    );
    this.reportEndDate = moment(this.reportSearchDates.to).format('YYYY-MM-DD');

    this.dateSelected.emit({
      from: this.reportStartDate,
      to: this.reportEndDate
    });
    this.toggleControls = false;
  }

  private formatToDate(timespan) {
    let timestamp;

    switch (timespan) {
      case 'lastQuarter':
        timestamp = moment(this.now).subtract(1, 'Q').format();
        break;
      case 'lastMonth':
        timestamp = moment(this.now).subtract(1, 'months').format();
        break;
      case 'lastWeek':
        timestamp = moment(this.now).subtract(7, 'days').format();
        break;
      case 'yesterday':
        timestamp = moment(this.now).subtract(1, 'days').format();
        break;
    }

    return timestamp;
  }
}
