import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  OnInit,
  HostListener,
  ViewChild
} from '@angular/core';
import { Transition } from '@uirouter/angular';
import { API, Campaign, Account } from '@ui-resources-angular';
import {
  colleagues,
  advertisingAccounts,
  campaigns,
  authUser,
  teams,
  workflowAccounts
} from '../../../common-resolves';
import { TeamsService, Team, Colleague } from '../../../../common/services/api';
import { OutboxQueryFactoryService } from '../../../../common/services/outbox-query-factory/outbox-query-factory.service';
import {
  Advert,
  AdvertModelService
} from '../advertising/advert-model/advert-model.service';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatePostsModalComponent } from './validate-posts-modal/validate-posts-modal.component';
import { OUTBOX_POST_TYPES } from '../../../../common/constants';

const POSTS_PER_PAGE = 15;

export async function advertsFn(
  advertModel: AdvertModelService,
  transition: Transition,
  accounts: Account[]
) {
  const params = { ...transition.params() };
  if (!params.id && !params.outbox_id && !params.account_id) {
    params.account_ids = accounts.map((account) => account.id);
  }
  params['requires_validation'] = 'exclusive';
  advertModel.ejectAll();
  const adverts = await advertModel.findAll(params, { bypassCache: true });
  if (!Array.isArray(adverts)) {
    return [adverts];
  }
  return adverts;
}

export async function postsFn(
  workflowAccountsParam,
  campaignsParam,
  authUserParam,
  outboxQueryFactory
) {
  const outboxQuery = outboxQueryFactory.create({
    allAccounts: workflowAccountsParam,
    campaignsParam,
    authUserParam
  });

  outboxQuery.filters.forEach((filter) => {
    if (filter.key === 'types') {
      Object.defineProperty(filter, 'defaultValue', {
        get() {
          return OUTBOX_POST_TYPES.map((type) => type.value);
        }
      });
      filter.isDefault = (val) => {
        return val.length === OUTBOX_POST_TYPES.length;
      };
    }
  });
  outboxQuery.reset(); // required because the default message types filter has changed
  outboxQuery.params.validated = '0';
  outboxQuery.params.deleted = 'exclude';
  outboxQuery.isValidationSearch = true;
  outboxQuery.params.with = [
    'boosted_post_counts',
    'note_counts',
    'interaction_totals',
    'interaction_totals_skip_clicks'
  ];
  outboxQuery.filters = outboxQuery.filters.filter(
    (filter) => !['validated', 'deleted'].includes(filter.key)
  );
  await outboxQuery.search(0, POSTS_PER_PAGE);
  return {
    posts: outboxQuery.outboxPosts,
    total: outboxQuery.interactionTotals.total
  };
}

@Component({
  selector: 'ssi-marketing-validate-posts',
  templateUrl: './validate-posts.component.html',
  styleUrls: ['./validate-posts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidatePostsComponent implements OnInit {
  static resolve = [
    colleagues,
    workflowAccounts,
    campaigns,
    teams,
    authUser,
    advertisingAccounts,
    {
      token: 'adverts',
      resolveFn: advertsFn,
      deps: [AdvertModelService, Transition, 'advertisingAccounts']
    },
    {
      token: 'outboxQuery',
      resolveFn: postsFn,
      deps: [
        'workflowAccounts',
        'campaigns',
        'authUser',
        OutboxQueryFactoryService
      ]
    }
  ];
  validations: any[]; // Outbox | Advert
  selected = [];
  selectMode = false;
  lastSelected = null;
  isLoading = false;

  @Input() adverts: Advert[];
  @Input() outboxQuery: any;
  @Input() campaigns: Campaign[];
  @Input() teams: Team[];
  @Input() workflowAccounts: Account[];
  @Input() colleagues: Colleague[];
  @Input() authUser: any;
  @ViewChild('filterComponent') filterComponent;
  posts = [];
  pages: Array<{number: number, active: boolean}> = [];
  postPerPage = POSTS_PER_PAGE;

  constructor(
    private state: StateService,
    private modal: NgbModal,
    private api: API
  ) {}

  async ngOnInit() {
    this.posts = this.outboxQuery.posts;
    this.updatePagination(this.outboxQuery.total);
    this.getValidations();
  }

  @HostListener('document:keydown.shift')
  handleKeydownEvent() {
    this.selectMode = true;
  }

  @HostListener('document:keyup')
  handleKeyupEvent() {
    this.selectMode = false;
  }

  updatePagination(totalPosts) {
    const pageCount = Math.ceil(totalPosts / POSTS_PER_PAGE);
    this.pages = new Array(pageCount).fill(null).map((page, index) => {
      return { number: index + 1, active: index === 0 };
    });
  }

  changePage(page) {
    if (page.active) {
      return;
    }
    this.isLoading = true;
    this.pages.find(($page) => $page.active).active = false;
    this.pages.find(($page) => $page.number === page.number).active = true;
    this.filterComponent.updateValidations(page.number);
  }

  getValidations() {
    try {
      if (!(!!this.posts && Array.isArray(this.posts))) {
        throw new Error(
          `Value for 'validate posts box posts' not in expected format.`
        );
      }

      this.validations = this.posts.concat(this.adverts);
      this.validations = this.validations.filter((validation) => {
        if (
          validation.outbox_id &&
          this.posts.find((post) => post.id === validation.outbox_id)
        ) {
          return null;
        } else {
          return validation;
        }
      });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public postValidated(post) {
    this.validations = this.validations.filter(
      (validation) => validation.id !== post.id
    );
  }

  public fetchAdvert(validation) {
    try {
      if (validation.outbox_id) {
        return;
      }

      if (!(!!this.adverts && Array.isArray(this.adverts))) {
        throw new Error(
          `Value for 'validate posts box adverts' not in expected format.`
        );
      }

      return this.adverts.find((advert) => advert.outbox_id === validation.id);
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  addToSelected(validation) {
    if (this.selected.includes(validation)) {
      this.selected = this.selected.filter((id) => id !== validation);
    } else {
      this.selected.push(validation);
    }
  }

  selectAll() {
    this.selected = this.validations.filter(
      (validation) => !validation.validated && validation
    );
  }

  selectValidation(event, validation) {
    if (!event.shiftKey) {
      return;
    }
    if (this.selected.includes(validation)) {
      this.selected = this.selected.filter(
        (selectedValidation) => selectedValidation.id !== validation.id
      );
      this.lastSelected = null;
    } else {
      const validationIndex = this.validations.indexOf(validation);

      if (this.lastSelected !== null) {
        const currentSelection =
          this.lastSelected > validationIndex
            ? this.validations.slice(validationIndex, this.lastSelected)
            : this.validations.slice(
                this.lastSelected + 1,
                validationIndex + 1
              );
        const duplicatedSelection = this.selected.filter((selectedValidation) =>
          currentSelection
            .map((currentValidation) => currentValidation.id)
            .includes(selectedValidation.id)
        );
        const uniqueValidationsSelected = currentSelection.filter(
          (val) => !duplicatedSelection.map((v) => v.id).includes(val.id)
        );
        this.selected.push(...uniqueValidationsSelected);
      } else {
        this.selected.push(validation);
      }
      this.lastSelected = validationIndex;
    }
  }

  refresh() {
    this.state.go('auth.marketing.validatePosts', {}, { reload: true });
  }

  async validateSelected(approved: boolean) {
    let result;

    if (approved) {
      const modal = this.modal.open(ValidatePostsModalComponent, {
        windowClass: 'validate-posts-modal'
      });
      modal.componentInstance.modalConfig = {
        icon: `ssi ssi-validations-lightbox`,
        title: `${this.selected.length} Validations selected`,
        meta: 'Are you happy with these posts?',
        cancelButton: 'Cancel Selection',
        okButton: 'Yes approve'
      };
      result = await modal.result;
    }

    if (result !== 'cancel') {
      this.selected.forEach((item) =>
        item.outbox
          ? this.validateAdvert(approved, item)
          : this.validatePost(approved, item)
      );
    }
    this.selected = [];
  }

  public async validatePost(approved, validation) {
    const validateRequest = {
      id: validation.id,
      account_id: validation.account_id,
      approved,
      seq: validation.seq,
      delete: false
    };

    if (approved) {
      validateRequest['validator_note'] = '';
      validateRequest['email_only'] = false;
    }

    await this.api.post('outbox_v2/validation', validateRequest).then((res) => {
      if (res.status === 200) {
        this.validations.forEach((item) => {
          if (item.id === validation.id) {
            item['validated'] = approved ? 'approved' : 'disapproved';
          }
        });
      }
    });
  }

  public async validateAdvert(approved, validation) {
    const validateRequest = {
      approved
    };

    const id = validation.id;

    if (approved) {
      validateRequest['validator_note'] = '';
      validateRequest['email_only'] = false;
    }

    await this.api
      .post(`advertising/advertisingValidateAdvert?id=${id}`, validateRequest, {
        params: {
          _method: 'PATCH'
        }
      })
      .then((res) => {
        if (res.status === 200) {
          this.validations.forEach((item) => {
            if (item.id === id) {
              item['validated'] = approved ? 'approved' : 'disapproved';
            }
          });
        }
      });
  }
}
