import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { AccountModel, API, Account } from '@ui-resources-angular';
import { PopupService } from '../../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncTrackerFactory } from 'angular-async-tracker';

@Component({
  selector: 'ssi-add-custom-provider-modal',
  templateUrl: './add-custom-bluesky-provider-modal.component.html',
  styleUrls: ['./add-custom-bluesky-provider-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCustomBlueskyProviderModalComponent {
  provider: any;
  name: string;
  displayName: string;
  token: string;
  accountCreated = false;

  loadingTracker = this.asyncTracker.create();

  confirmationText: string;

  constructor(
    private api: API,
    public activeModal: NgbActiveModal,
    private asyncTracker: AsyncTrackerFactory
  ) {}

  async createAccount() {
    try {
      const response = await this.api.post('account/blueskyCreate', {
        name: this.displayName,
        handle: this.name.startsWith('@') ? this.name.slice(1) : this.name,
        token: this.token
      });
      if (response.status === 200) {
        this.accountCreated = true;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
