// declare function escape(s: string): string;
// declare function unescape(s: string): string;

// export function encodeStateParam(param: string): any {
//   if (typeof param !== 'string') {
//     console.error('Param to encode must be a string, param: ', param);
//     return param;
//   }

//   return btoa(unescape(encodeURIComponent(param)));
// }

export function decodeStateParams(stateParams: { [key: string]: any }): {
  [key: string]: any;
} {
  const decodedParams = {};
  if (!stateParams) {
    return decodedParams;
  }

  Object.keys(stateParams).forEach((key) => {
    decodedParams[key] =
      typeof stateParams[key] === 'string'
        ? decodeURIComponent(stateParams[key]) // decodeURIComponent(escape(atob(stateParams[key])))
        : stateParams[key];
  });

  return decodedParams;
}
