import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Agent } from '@orlo/library/models/live-chat/agent';

@Component({
  selector: 'ssi-live-chat-offline-agent-notice',
  templateUrl: './live-chat-offline-agent-notice.component.html',
  styles: [],
  styleUrls: ['./live-chat-offline-agent-notice.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatOfflineAgentNoticeComponent {
  @Input() agent: Agent;

  public get isAgentOffline(): boolean {
    return this.agent ? this.agent.isOffline : true;
  }
}
