import { Injectable } from '@angular/core';
import { Model, Record, Account, API } from '@ui-resources-angular';
import {
  AdvertisingTargetingOption,
  serialiseTargetingOptions
} from '../advertising-targeting/advertising-targeting.service';
import { appInjector } from '@orlo/app-injector';
import {
  ColleaguesService,
  Colleague
} from '@orlo/common/services/api';

export interface TargetingOption {
  id: string;
  value: string;
  label: string;
  option?: AdvertisingTargetingOption;
}

/* tslint:disable variable-name */
export class AdvertisingPreset extends Record {
  created_at: string;
  created_by: string;
  id: string;
  name: string;
  targeting_options: TargetingOption[];
  account_id: string;
  account: Account;

  get createdBy(): Colleague {
    if (!this.created_by) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.created_by);
  }

  // setters must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set createdBy(c: Colleague) {}

  async destroy() {
    const api = this.injector.get(API);
    const presetModel = this.injector.get(AdvertisingPresetModelService);
    await api.del('advertising/advertisingPreset', {
      params: {
        id: this.id
      }
    });
    presetModel.eject(this);
  }

  async update(changes: { name: string; targetingOptions: TargetingOption[] }) {
    const api = this.injector.get(API);
    await api.post(
      'advertising/advertisingPreset',
      {
        id: this.id,
        name: changes.name,
        targeting_options: serialiseTargetingOptions(changes.targetingOptions)
      },
      {
        params: {
          _method: 'PATCH'
        }
      }
    );
    this.name = changes.name;
    this.targeting_options = changes.targetingOptions;
  }
}

@Injectable() // tslint:disable-line max-classes-per-file
export class AdvertisingPresetModelService extends Model<AdvertisingPreset> {
  constructor() {
    super('advertisingPreset', {
      endpoint: 'advertising/advertisingPreset',
      recordClass: AdvertisingPreset,
      deserialize(resourceConfig, attrs) {
        return attrs.data.presets;
      },
      relations: {
        belongsTo: {
          // colleague: {
          //   localKey: 'created_by',
          //   localField: 'createdBy'
          // },
          account: {
            localKey: 'account_id',
            localField: 'account'
          }
        }
      }
    });
  }
}
