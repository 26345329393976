import { ViewEncapsulation } from '@angular/core';

import { Component } from '@angular/core';
import { OutboxPublisher } from '@ui-resources-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { PopupService } from '../../../services/popup/popup.service';

@Component({
  selector: 'ssi-facebook-share-modal',
  templateUrl: './facebook-share-modal.component.html',
  styleUrls: ['./facebook-share-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FacebookShareModalComponent {
  post: OutboxPublisher;

  loadingTracker = this.asyncTrackerFactory.create();

  sendAtDate: Date;

  now = new Date();

  constructor(
    public activeModal: NgbActiveModal,
    private asyncTrackerFactory: AsyncTrackerFactory,
    private popup: PopupService
  ) {}

  async publish() {
    if (this.sendAtDate) {
      const { isValid } = this.post.addSchedule(this.sendAtDate);
      if (!isValid) {
        this.popup.alert({
          title: 'Could not schedule shared post',
          message: 'Sorry but the scheduled date you picked is in the past'
        });
        return;
      }
    }
    const promise = this.post.publish();
    this.loadingTracker.add(promise);
    await promise;
    this.activeModal.close();
  }
}
