import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-validate-posts-actions',
  templateUrl: './validate-posts-actions.component.html',
  styleUrls: ['./validate-posts-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidatePostsActionsComponent implements OnInit {
  @Input() validation: any;
  @Input() scheduledDate: string;
  @Input() campaignName: string;
  @Input() account: any;

  socialAccount: {
    icon: '';
    name: '';
  };

  inboxLink: {
    mode?: string;
    conversation?: string;
    activity?: string;
    reply?: string;
  } = {};

  constructor() {}

  ngOnInit() {
    this.socialAccount = {
      icon: this.account ? this.account.socialNetwork.icon.web : '',
      name: this.account ? this.account.displayName : ''
    };

    if (this.validation.conversation_link) {
      const linkParams = this.validation.conversation_link.split('/#/')[1];
      this.inboxLink.mode = linkParams.split('?')[0];
      if (linkParams.includes('conversation=')) {
        this.inboxLink.conversation = linkParams
          .split('conversation=')[1]
          .split('&')[0];
      }
      if (linkParams.includes('activity=')) {
        this.inboxLink.activity = linkParams
          .split('activity=')[1]
          .split('&')[0];
      }
    }
  }
}
