import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatTeamModule } from '../live-chat-team/live-chat-team.module';
import { LiveChatTeamStatisticsModule } from '../live-chat-team-statistics/live-chat-team-statistics.module';
import { LiveChatTeamDashboardComponent } from './live-chat-team-dashboard.component';

@NgModule({
  declarations: [LiveChatTeamDashboardComponent],

  exports: [LiveChatTeamDashboardComponent],

  imports: [
    CommonModule,
    LiveChatTeamModule,
    LiveChatTeamStatisticsModule,
    TranslateModule
  ]
})
export class LiveChatTeamDashboardModule {}
