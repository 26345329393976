import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import {
  Account,
  AccountModel,
  AccountType,
  UserModel
} from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import { ROW_HEIGHT } from '../../widgets-grid/widgets-grid.component';
import { CompanyService } from '../../../../../../../common/services/company/company.service';
import { ApiService } from '../../../../../../../common/services/api';
import { StateService } from '@uirouter/angular';

export interface StreamResultResponse {
  cache_hit: boolean;
  cache_key: string;
  cached_at: number;
  results: StreamResult[];
  twitter_query: any;
}

export interface StreamResult {
  link?: string;
  text: string;
  created_at: Date;
  social_id: string;
  author: {
    name: string;
    username: string;
    avatar: string;
  };
  media: Array<{
    id: string;
    type: string;
    url: string;
  }>;
}

export interface SearchStream extends StreamResult {
  // mediaByType: {
  //   image: Array<{
  //     id: string;
  //     type: string;
  //     url: string;
  //   }>;
  //   video: Array<{
  //     id: string;
  //     type: string;
  //     url: string;
  //   }>;
  //   document: Array<{
  //     id: string;
  //     type: string;
  //     url: string;
  //   }>;
  // };
  isLiked?: boolean;
  author: {
    name: string;
    username: string;
    avatar: string;
    link: string;
    /** Only available on profile-search widget in Dashboard - profile_v2/index */
    id?: string;
  };

  // additional props for profile search widget in Dashboard
  like_count?: number;
  share_count?: number;
  image_url?: string;
  id?: string;
}

@Component({
  selector: 'ssi-widget-live-x-stream',
  templateUrl: './widget-live-x-stream.component.html',
  styles: [],
  styleUrls: ['./widget-live-x-stream.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetLiveXStreamComponent implements OnInit, OnChanges {
  @Input() widget: DashboardWidget;
  @Input() widgetSize: { w: number; h: number };

  // '2x1' | '2x2' etc
  viewClass: string;
  accounts: Account[] = [];
  query: any;
  allResultsLoaded: boolean;
  searchStreams: SearchStream[] = [];
  viewPortItems: SearchStream[];

  disableLoadMore: boolean;
  containerHeight: number; // - widget header height

  displayMax = 8; // SCROLL_INCREMENT_AMOUNT;
  loadingTracker: any;
  currentAccount: Account;

  @Output() loaded = new EventEmitter<boolean>();
  private allAccounts: Account[];

  constructor(
    protected userModel: UserModel,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected companyService: CompanyService,
    protected state: StateService,
    protected api: ApiService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['widget'] && changes['widget'].currentValue) {
      this.containerHeight = this.widget.display_properties.h * ROW_HEIGHT - 48; // - widget header height
    }

    if (changes.widgetSize && changes.widgetSize.currentValue) {
      this.viewClass = `${this.widgetSize.w}x${this.widgetSize.h}`;
    }
  }

  async ngOnInit() {
    if (
      this.widget.apiSearchParams &&
      this.widget.apiSearchParams.hasOwnProperty('query')
    ) {
      this.query = {
        ...this.widget.apiSearchParams.query,
        language: 'en',
        include_retweet: false
      };
    }

    this.viewClass = `${this.widgetSize.w}x${this.widgetSize.h}`;

    this.allAccounts = await this.accountModel.findAccounts();
    this.currentAccount = this.allAccounts.find(
      (account) => account.id === this.query.account_id
    );

    this.accounts = this.allAccounts.filter(
      (account) => account.account_type_name === AccountType.Twitter
    );

    this.loadData();
    this.loaded.emit(true);
  }

  async loadData() {
    const endpoint = `${this.api.url}/monitoring/twitterLiteQuery`;

    try {
      const request = {
        query: this.query,
        account_id: this.widget.apiSearchParams.account_id,
        title: this.widget.title
      };

      const response: any = await this.api.post(endpoint, request).toPromise();
      this.searchStreams = response.results.map((stream: StreamResult) => {
        return {
          ...stream,
          media: this.getStreamMediaByType(stream.media),
          isLiked: false,
          author: {
            ...stream.author,
            link: `https://twitter.com/${stream.author.username}`
          }
        };
      });

      console.log('this.searchStreams:', this.searchStreams);
      return this.searchStreams;
    } catch (error) {
      console.error('Error getting Live X streams - twitterLiteQuery:', error);
    }
  }

  getItemHeight = (stream: SearchStream): number => {
    return 200;
  };

  viewPortItemsUpdated(event: SearchStream[]) {
    console.log('event:', event);
    this.viewPortItems = event;
  }

  loadMore() {
    this.displayMax += 8;
    if (this.displayMax > this.searchStreams.length) {
      const promise = this.loadData().then((moreStreams) => {
        this.disableLoadMore = moreStreams.length === 0;
        this.searchStreams = [...this.searchStreams, ...moreStreams];
      });
      this.loadingTracker.addPromise(promise);
      return promise;
    }
  }

  getStreamMediaByType(
    media: Array<{ id: string; type: string; url: string }>
  ) {
    const mediaByType = {
      image: media.filter((m) => m.type === 'image'),
      video: media.filter((m) => m.type === 'video'),
      document: media.filter((m) => m.type === 'document')
    };

    return mediaByType;
  }
}
