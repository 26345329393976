import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import {
  Dashboard,
  DashboardService,
  DashboardWidget
} from '../../../../../../../common/services/api/dashboard';
import {
  Account,
  AccountModel,
  Activity,
  ActivityModel
} from '@ui-resources-angular';
import { orderBy } from 'lodash-es';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import {
  ContentGeneratorService,
  RssArticle
} from '../../../../../marketing/content-generator/content-generator.service';
import stripTags from 'strip';
import { LocalStorageService } from 'angular-2-local-storage';
import { StateService } from '@uirouter/angular';
import { ApiService } from '../../../../../../../common/services/api';

export const SCROLL_INCREMENT_AMOUNT = 10;
export interface SocialList {
  id: string;
  name: string;
  is_owned_by_account: boolean;
  is_public: boolean;
}

@Component({
  selector: 'ssi-widget-social-wall',
  templateUrl: './widget-social-wall.component.html',
  styles: [],
  styleUrls: ['./widget-social-wall.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetSocialWallComponent implements OnInit, OnChanges {
  @Input() widget: DashboardWidget;
  @Input() currentDashboard: Dashboard;

  accounts: Account[] = [];
  selectedAccount: Account;
  viewClass: string;

  activities: Activity[] = [];
  viewPortActivities: Activity[] = [];

  loadingTracker: any;
  activityLoadError: boolean;
  displayMax: number;
  disableLoadMore: boolean;
  socialLists: Array<SocialList>;
  socialListsOptions: Array<Partial<SocialList>>;
  allPostsList = {
    id: 'all',
    name: 'All Posts'
  };
  selectedList: Partial<SocialList> = this.allPostsList;

  @Output() loaded = new EventEmitter<boolean>();

  constructor(
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected contentGeneratorService: ContentGeneratorService,
    protected activityModel: ActivityModel,
    protected localStorageService: LocalStorageService,
    protected state: StateService,
    protected dashboardService: DashboardService,
    protected api: ApiService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.viewClass = `${this.widget.display_properties.w}x${this.widget.display_properties.h}`;
  }

  async ngOnInit() {
    this.viewClass = `${this.widget.display_properties.w}x${this.widget.display_properties.h}`;

    this.accounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId()
    );
    this.accounts = orderBy(this.accounts, ['account_type_name', 'name']);

    this.loaded.emit(true);

    // currently selected account
    // const savedSettings = this.localStorageService.get<any>(this.localSettingsStorageKey);
    const savedSettings = this.widget.config;

    if (savedSettings && !Array.isArray(this.accounts)) {
      throw new Error(
        `value for 'socialWall accounts' is not in the expected format.`
      );
    }

    const savedAccount = savedSettings
      ? this.accounts.find(
          (account) => +account.id === +savedSettings.accountId
        )
      : null;
    if (savedAccount) {
      this.selectedAccount = savedAccount;
      this.accountSelected(savedSettings.listId ? savedSettings.listId : null);
    }
  }

  async loadActivities(fromId?: string) {
    this.activityLoadError = false;
    this.activities = [];
    const params = Object.assign(
      {},
      {
        account_id: this.selectedAccount.id,
        list_id:
          this.selectedList && this.selectedList.id !== this.allPostsList.id
            ? this.selectedList.id
            : null
      },
      {
        from_id: fromId
      }
    );
    try {
      this.activities = await this.activityModel.findAll(params, {
        bypassCache: true,
        autoError: false,
        params
      });
    } catch (error) {
      console.error('Error getting the Social wall activities:', error);
    }
  }

  async accountSelected(listId?: string) {
    this.widget.config = {
      accountId: this.selectedAccount ? this.selectedAccount.id : null,
      listId:
        this.widget.config && this.widget.config.listId
          ? this.widget.config.listId
          : null
    };
    this.dashboardService
      .update(this.currentDashboard)
      .then((updatedDashboard) => {
        console.log('currentDashboard: ', updatedDashboard);
      });
    if (this.selectedAccount.socialNetwork.supportsSocialLists) {
      const endpoint = `${this.api.url}/social/lists`;
      await this.api
        .get(endpoint, {
          params: { account_id: this.selectedAccount.id },
          autoError: false
        })
        .toPromise()
        .then((data: SocialList[]) => {
          this.socialListsOptions = data;
          this.socialListsOptions.unshift(this.allPostsList);
          if (listId) {
            const matchingList = this.socialListsOptions.find(
              (l) => l.id === listId
            );
            this.selectedList = matchingList ? matchingList : this.allPostsList;
          }
        });
    } else {
      this.socialListsOptions = null;
    }
    this.loadActivities();
  }

  selectedAccountChanged(selectedAccount: Account): void {
    this.selectedAccount = selectedAccount;
  }

  selectedListChanged(): void {
    this.widget.config = {
      accountId:
        this.widget.config && this.widget.config.accountId
          ? this.widget.config.accountId
          : null,
      listId: this.selectedList ? this.selectedList.id : null
    };

    this.dashboardService
      .update(this.currentDashboard)
      .then((updatedDashboard) => {
        console.log('currentDashboard: ', updatedDashboard);
      });
    this.loadActivities();
  }

  getHeight = () => {
    return 284;
  };

  viewPortActivitiesUpdated(items: Activity[]): void {
    // console.log('viewPortItemsUpdated: ', items);
    this.viewPortActivities = items;
  }

  onScrolled(): void {
    // console.log('scrolled...');
  }
}
