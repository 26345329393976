import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { PopoverModule } from '../../../../common/directives/popover/popover.module';
import { LiveChatConversationStatisticsComponent } from './live-chat-conversation-statistics.component';

@NgModule({
  declarations: [LiveChatConversationStatisticsComponent],

  exports: [LiveChatConversationStatisticsComponent],

  imports: [
    CommonModule,
    NgbPopoverModule.forRoot(),
    PopoverModule,
    TranslateModule
  ]
})
export class LiveChatConversationStatisticsModule {}
