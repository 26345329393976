import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnChanges } from '@angular/core';
import {
  TriggerParamConfig,
  UIParamFormType
} from '../../../../ifttt-utils/ifttt-trigger-action-params/ifttt-trigger-action-params.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ssi-triggers-range-selector',
  templateUrl: './triggers-range-selector.component.html',
  styleUrls: ['./triggers-range-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TriggersRangeSelectorComponent implements OnChanges {
  @Input() allParams: TriggerParamConfig[];

  @Input() param: TriggerParamConfig;

  @Input() form: FormGroup;

  rangeValue: [number, number];

  isMinParam: boolean;

  minValue: number;

  maxValue: number;

  maxParam: TriggerParamConfig;

  ngOnChanges() {
    if (this.param && this.allParams && this.form) {
      const rangeParams = this.allParams.filter(
        (param) => param.form.type === UIParamFormType.Range
      );
      if (rangeParams.length % 2 !== 0) {
        throw new Error(
          `There should be an even amount of range params, found ${
            rangeParams.length
          } range params`
        );
      }
      const paramIndex = this.allParams.indexOf(this.param);
      this.isMinParam = paramIndex % 2 === 0;
      if (this.isMinParam) {
        this.minValue = this.param.form.defaultValue;
        this.maxParam = this.allParams[paramIndex + 1];
        this.maxValue = this.maxParam.form.defaultValue;
        this.rangeValue = [
          this.form.controls[this.param.name].value,
          this.form.controls[this.maxParam.name].value
        ];
      }
    }
  }

  updateFormValues() {
    this.form.controls[this.param.name].setValue(this.rangeValue[0]);
    this.form.controls[this.maxParam.name].setValue(this.rangeValue[1]);
  }
}
