import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import * as deepDiff from 'deep-diff';

import { Message } from '@orlo/library/models/live-chat/message';
import { Agent } from '@orlo/library/models/live-chat/agent';
import { ConversationUserEvent } from '@orlo/library/models/live-chat/conversation-user-event';
import { smooth as stringSmooth } from '@orlo/library/constants/strings';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';

// ----------------

const isDebug = false;

// ----------------

@Component({
  selector: 'ssi-live-chat-activity-tree',
  templateUrl: './live-chat-activity-tree.component.html',
  styles: [],
  styleUrls: ['./live-chat-activity-tree.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatActivityTreeComponent implements AfterViewInit, OnChanges {
  @Input() conversationEvents: ConversationUserEvent;
  @Input() isNoteFormFocused: boolean;
  @Input() messages: Message[];
  @Input() selectedMessage: Message;
  @Input() translationMode: boolean = false;

  @Output()
  isNoteFormFocusedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  messageSelected: EventEmitter<Message> = new EventEmitter<Message>();
  @Output()
  openNotesScreen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openTags: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  translationDetectedLanguage: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('activities') activitiesList: ElementRef;
  @ViewChild('inlineNotesContainer') inlineNotesContainer: ElementRef;

  public areNotesExpanded: boolean = false;

  // @todo clearer naming here:
  protected _areNotesOpening: boolean = false;
  protected _areNotesVisible: boolean = false;
  protected _idOfLatestVisitorMessage: string;
  protected _typingAgents: Agent[];
  protected _viewingAgents: Agent[];

  constructor(protected liveChatService: LiveChatService) {}

  public get areAgentsTyping(): boolean {
    return !!this.typingAgents.length;
  }

  public get areAgentsViewing(): boolean {
    return !!this.viewingAgents.length;
  }

  public get areNotesOpening() {
    return this._areNotesOpening;
  }

  public get areNotesVisible() {
    if (this.areNotesExpanded) {
      return true;
    }

    return this._areNotesVisible;
  }

  public get idOfLatestVisitorMessage(): string {
    return this._idOfLatestVisitorMessage;
  }

  public get inlineNotesContainerElement(): HTMLElement {
    const inlineNotesContainerElement = this.inlineNotesContainer
      .nativeElement as HTMLElement;

    return inlineNotesContainerElement;
  }

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public get isVisitorTyping(): boolean {
    return this.liveChatService.activeConversationEvents.isVisitorTyping;
  }

  public get typingAgents(): Agent[] {
    return this._typingAgents;
  }

  public get viewingAgents(): Agent[] {
    return this._viewingAgents;
  }

  public get visitorAvatar(): string {
    return this.liveChatService.activeVisitor.avatar;
  }

  public isSelectedMessage(message: Message): boolean {
    return message === this.selectedMessage;
  }

  public ngAfterViewInit() {
    try {
      const reversedMessages = Object.assign([], this.messages).reverse();
      const reversedMessageIndex = reversedMessages.findIndex(
        (item: Message) => !item.fromAgent && !item.fromRobot
      );
      const message = reversedMessages[reversedMessageIndex];

      // console.log(`reversedMessageIndex: ${reversedMessageIndex}`);
      // console.log(`selectedMessage:`);
      // console.log(this.selectedMessage);

      // const messageIndex = !this.selectedMessage
      //   ? this.messages.length - reversedMessageIndex
      //   : this.messages
      //       .map((item: Message) => item.id)
      //       .indexOf(this.selectedMessage.id);

      requestAnimationFrame(() => this.selectMessage(message));

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public ngOnChanges(changes) {
    try {
      if (isDebug) {
        console.log(`activity tree changes:`);
        console.log(changes);
      }

      const hasChangeForConversationEvents: boolean =
        !!changes.conversationEvents &&
        !!changes.conversationEvents.currentValue;

      const hasChangeForMessages: boolean =
        !!changes.messages &&
        !!changes.messages.currentValue &&
        Array.isArray(changes.messages.currentValue);

      const hasChangeForSelectedMessage: boolean =
        !!changes.selectedMessage && !!changes.selectedMessage.currentValue;

      if (hasChangeForConversationEvents) {
        if (isDebug) {
          console.log(`has changes for conversation events`);
        }

        const currentVersion = changes.conversationEvents
          .currentValue as ConversationUserEvent;

        if (
          !changes.conversationEvents.previousValue ||
          currentVersion !== changes.conversationEvents.previousVersion
        ) {
          const typingAgents = [];
          const viewingAgents = [];

          Object.keys(currentVersion.items.agents).forEach((agentId) => {
            const model = this.liveChatService.agents.get(agentId);

            if (!!currentVersion.items.agents[agentId].lastTypedAt) {
              typingAgents.push(model);
            }

            if (!!currentVersion.items.agents[agentId].lastViewedAt) {
              viewingAgents.push(model);
            }
          });

          this._typingAgents = typingAgents;
          this._viewingAgents = viewingAgents;
        }
      }

      if (hasChangeForMessages) {
        if (isDebug) {
          console.log(`has changes for messages`);
        }

        this._idOfLatestVisitorMessage = Object.assign([], this.messages)
          .reverse()
          .find(
            (message) => !!message && !!message.id && message.fromVisitor
          ).id;

        requestAnimationFrame(async () => {
          if (!!this.selectedMessage) {
            if (isDebug) {
              console.log(`has selected message`);
            }

            const latestVersionOfSelectedMessage = this.messages.find(
              (nextMessage) => nextMessage.id === this.selectedMessage.id
            );
            const previousVersionOfSelectedMessage = !!changes.messages
              .previousValue
              ? changes.messages.previousValue.find(
                  (nextMessage) => nextMessage.id === this.selectedMessage.id
                )
              : null;

            let messageDifferences =
              !!previousVersionOfSelectedMessage &&
              previousVersionOfSelectedMessage.data &&
              !!latestVersionOfSelectedMessage &&
              latestVersionOfSelectedMessage.data &&
              deepDiff.diff(
                previousVersionOfSelectedMessage.data,
                latestVersionOfSelectedMessage.data
              );

            if (messageDifferences) {
              messageDifferences = messageDifferences.filter(
                (difference) =>
                  !!difference &&
                  !!difference.path &&
                  (difference.path !== 'actionedAt' ||
                    difference.path !== 'actionedBy')
              );

              if (messageDifferences.length) {
                this.selectMessage(latestVersionOfSelectedMessage);
              }
            } else {
              this._updateSelection(this.selectedMessage.id, false);
            }
          } else {
            if (isDebug) {
              console.log(`does not have changes for selected message`);
            }
          }

          await this.liveChatService.markActiveConversationAsViewed();
        });
        // }
      }

      if (hasChangeForSelectedMessage) {
        if (isDebug) {
          console.log(`has changes for selected message`);
        }

        requestAnimationFrame(() => {
          if (!!changes.selectedMessage) {
            this._selectMessage(changes.selectedMessage);
          }
        });
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public onIsNoteFormFocusedChange(value: boolean): void {
    this.isNoteFormFocusedChange.emit(value);
  }

  public onOpenNotes(messageId: string): void {
    if (this.areNotesExpanded) {
      return;
    }

    if (!(!messageId && !!this.messages)) {
      return;
    }

    const message = this.messages.find(
      (soughtMessage) => soughtMessage.id === messageId
    );

    if (!message) {
      return;
    }

    if (!this.isSelectedMessage(message)) {
      this._areNotesVisible = false;
      this._areNotesOpening = true;
      this.selectMessage(message);
    } else {
      this._areNotesVisible = !this._areNotesVisible;
      this.scrollTo(this.inlineNotesContainer.nativeElement);
    }
  }

  public onOpenNotesScreen() {
    this.openNotesScreen.emit(true);
  }

  public onOpenTags() {
    this.openTags.emit(true);
  }

  public onTranslationDetectedLangugage($event) {
    this.translationDetectedLanguage.emit($event);
  }

  public selectMessage(message: Message): void {
    if (!message) {
      return;
    }

    if (!!message.hasRobot || !!message.hasAgent) {
      return;
    }

    if (!!this.isInterfaceDisabled) {
      return;
    }

    this.messageSelected.emit(message);
  }

  private scrollTo(element: HTMLElement): void {
    requestAnimationFrame(() =>
      element.scrollIntoView({
        behavior: stringSmooth
      })
    );
  }

  private _selectMessage(changeFragment) {
    try {
      if (isDebug) {
        console.log(`in liveChatActivityTree~>_selectMessage`);
      }

      const currentValue: Message = changeFragment.currentValue;
      const previousValue: Message = changeFragment.previousValue;
      const hasCurrentValue = !!currentValue && !!currentValue.id;
      const hasPreviousValue = !!previousValue && !!previousValue.id;
      const hasDifferentId =
        !!hasCurrentValue &&
        (!hasPreviousValue ||
          (!!hasPreviousValue && previousValue.id !== currentValue.id));

      if (!!this.areNotesOpening) {
        this._areNotesVisible = true;
        this._areNotesOpening = false;
      } else {
        this._areNotesVisible = false;
      }

      if (!hasDifferentId) {
        if (!!this._areNotesVisible) {
          if (isDebug) {
            console.log(`same id, visible notes: scroll to element`);
          }
          this.scrollTo(this.inlineNotesContainerElement);
        } else {
          if (hasCurrentValue && hasPreviousValue) {
            let messageDifferences = deepDiff.diff(
              changeFragment.previousValue.data,
              changeFragment.currentValue.data
            );

            if (messageDifferences) {
              messageDifferences = messageDifferences.filter(
                (difference) =>
                  !!difference &&
                  !!difference.path &&
                  (difference.path !== 'actionedAt' ||
                    difference.path !== 'actionedBy')
              );

              if (!!messageDifferences.length) {
                this._updateSelection(currentValue.id, false);
              }
            }
          }
        }

        // return;
      }

      return this._updateSelection(currentValue.id, true);
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  private _updateSelection(
    messageId: string,
    shouldScroll: boolean = true
  ): boolean {
    try {
      const list = this.activitiesList.nativeElement as HTMLUListElement;

      if (!list) {
        return;
      }

      Array.from(list.children).forEach((node: HTMLElement) => {
        if (node.getAttribute(`message-id`) !== messageId) {
          return;
        }

        list.insertBefore(this.inlineNotesContainerElement, node.nextSibling);
      });

      if (!shouldScroll) {
        return true;
      }

      const index = this.messages
        .map((message) => message.id)
        .indexOf(messageId);

      const messageElement = list.children[index] as HTMLLIElement;

      if (!messageElement) {
        return;
      }

      const targetElement = !!this.areNotesOpening
        ? this.inlineNotesContainer.nativeElement
        : messageElement;

      if (isDebug) {
        console.log(`scroll to target`);
      }

      this.scrollTo(targetElement);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
}
