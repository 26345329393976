import { LiveChatModalComponent } from '../../components/live-chat/live-chat-modal/live-chat-modal.component';
import {
  ComponentFactoryResolver,
  ViewContainerRef,
  Injectable
} from '@angular/core';
import { ModalAttributes } from '@orlo/library/interfaces/modal-attributes';
import { StateService } from '@uirouter/angular';

@Injectable()
export class LiveChatModalFactoryService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private state: StateService
  ) {}

  public create(
    attributes: ModalAttributes,
    parentContainer: ViewContainerRef
  ): LiveChatModalComponent {
    if (!parentContainer) {
      console.error(`Invalid container for modal`);

      return;
    }
    // do not currently show LC-related popups while on dashboard (CT-4709)
    const onDashboard =
      this.state.current &&
      this.state.current.name &&
      this.state.current.name.startsWith('auth.dashboard');
    if (onDashboard) {
      return;
    }

    const modal = this.componentFactoryResolver.resolveComponentFactory(
      LiveChatModalComponent
    );

    const modalReference = parentContainer.createComponent(modal);
    const modalInstance = modalReference.instance;

    Object.assign(modalReference.instance, attributes);

    modalInstance.componentReference = modalReference;

    return modalInstance;
  }
}
