import { ViewEncapsulation } from '@angular/core';

import {
  Input,
  Component,
  Renderer2,
  forwardRef,
  OnInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ssi-checkbox-2',
  templateUrl: './checkbox-2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Checkbox2Component),
      multi: true
    }
  ],
  styleUrls: ['./checkbox-2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Checkbox2Component implements OnInit, ControlValueAccessor {
  @Input() variant: 'circle' | 'square' = 'circle';
  @Input() disabled = false;
  @Input() highlightLabel = true;
  @Input() label = '';
  @Input() tooltipMessage = '';
  @Input() reversed = false;

  @ViewChild('inputRef') inputRef: ElementRef;

  id = uuidv4();
  modelValue: boolean;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  private onChangeCallback: (_: any) => void = () => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  private onTouchCallback: () => void = () => {};
  registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  writeValue(value: boolean) {
    this.modelValue = value;
  }

  inputChange(event: any): void {
    this.modelValue = !this.modelValue;
    this.onChangeCallback(this.modelValue);
  }

  checkboxClick(event: any): void {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.inputRef.nativeElement.dispatchEvent(new Event('change'));
  }
}
