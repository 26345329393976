<div class="modal-header">
  <h3 class="modal-title no-wrap">
    <span *ngIf="provider">Link {{provider.label}} with Orlo</span>
  </h3>
</div>
<div class="modal-body">
  <div
    class="loading"
    *ngIf="loadingTracker.active"
  >
  </div>
  <p>
    In order for you to add your {{provider.label}} account to Orlo, you will need to generate an App password 
    <a
      href="https://bsky.app/settings/app-passwords"
      target="_blank">here.
    </a>
    We recommend using "Orlo" as the App Password name and ensure you tick "Allow access to your direct messages" so we can manage those through the Inbox. Copy and paste the generated app password into the box below with your account name and display name.
  </p>
  <form
    novalidate
    #submitForm="ngForm"
    [hidden]="loadingTracker.active"
  >

    <div *ngIf="provider">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>BLUESKY ACCOUNT NAME</label>
          <input
            class="form-control input-lg"
            type="text"
            name="name"
            placeholder="account.bsky.social"
            [(ngModel)]="name"
            required
          >
        </div>
        <div class="form-group col-md-6">
          <label>DISPLAY NAME</label>
          <input
            class="form-control input-lg"
            type="text"
            name="displayName"
            placeholder="Display name"
            [(ngModel)]="displayName"
            required
          >
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>APP PASSWORD</label>
          <input
            class="form-control input-lg"
            type="text"
            name="secret"
            placeholder="App password"
            [(ngModel)]="token"
            required
          >
        </div>
      </div>
      <hr>

      <div class="text-center">
        <button
          class="btn btn-primary btn-lg text-uppercase"
          [disabled]="!submitForm.valid"
          (click)="submitForm.valid && createAccount()"
          translate="ADD"
        >
        </button>
      </div>
    </div>
  </form>
  
  <p *ngIf="accountCreated" class="success-message">
    Congratulations! Your {{provider.name}} account is now linked with Orlo
  </p>
</div>

<div class="modal-footer text-right">
  <button
    class="btn btn-default"
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-times"></i> <span translate="{{ accountCreated ? 'SAVE_CHANGES' : 'CANCEL' }}"></span>
  </button>
</div>
