import { ViewEncapsulation } from '@angular/core';
import moment from 'moment';
import { HighchartsHelperService } from '../../../../../../common/services/highcharts-helper/highcharts-helper.service';
import { DateRanges } from '../../ad-stats.service';


import { Component, Input, OnInit } from '@angular/core';
import { Options, SeriesOptionsType } from 'highcharts';
import { currencyOptions } from '../../build/ad-stats-build.component';

@Component({
  selector: 'ssi-spend-conversion',
  templateUrl: './spend-conversion.component.html',
  styles: [],
  styleUrls: ['./spend-conversion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpendConversionComponent implements OnInit {
  @Input() adStatsData;
  @Input() dateRanges: DateRanges;
  chartData: Options = {};

  constructor(private highchartsHelper: HighchartsHelperService) {}

  ngOnInit(): void {
    this.chartData = this.formatChartConfig();
  }

  formatChartConfig(): any {
    const series: SeriesOptionsType[] = [
      {
        data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
          this.adStatsData.current.spend.values,
          {
            start: this.dateRanges.current.start.toISOString(),
            end: this.dateRanges.current.end.toISOString()
          }
        ),
        name: 'Spend',
        color: '#F88C68',
        fillOpacity: 0.35,
        yAxis: 1,
        type: 'areaspline',
        index: 0,
        marker: {
          enabled: false
        }
      },
      {
        data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
          this.adStatsData.current.conversions.values,
          {
            start: this.dateRanges.current.start.toISOString(),
            end: this.dateRanges.current.end.toISOString()
          }
        ),
        name: 'Conversion',
        color: '#D41D68',
        index: this.adStatsData.previous ? 2 : 1,
        type: 'spline',
        marker: {
          enabled: true,
          radius: 4.3,
          symbol: 'circle'
        }
      }
    ];

    if (this.adStatsData.previous) {
      series.push(
        {
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.adStatsData.previous.spend.values,
            {
              start: this.dateRanges.current.start.toISOString(),
              end: this.dateRanges.current.end.toISOString()
            }
          ),
          // name: this.translate.instant(secondaryLabel),
          name: 'Spend (Previous period)',
          dashStyle: 'ShortDash',
          type: 'spline',
          color: '#F88C68',
          marker: {
            enabled: false
          },
          index: 1
        },
        {
          data: this.highchartsHelper.apiTimeSeriesDataToHighchartsMap(
            this.adStatsData.previous.conversions.values,
            {
              start: this.dateRanges.current.start.toISOString(),
              end: this.dateRanges.current.end.toISOString()
            }
          ),
          // name: this.translate.instant(secondaryLabel),
          name: 'Conversion (Previous period)',
          dashStyle: 'ShortDash',
          type: 'spline',
          color: '#D41D68',
          marker: {
            enabled: false
          },
          index: 3
        }
      );
    }

    return {
      chart: {
        type: 'areaspline',
        height: 398, // 315 + legend height
        zoomType: 'x'
      },
      title: {
        text: null
      },
      legend: {
        enabled: true,
        padding: 5,
        itemStyle: {
          color: '#43537F',
          fontSize: '12px',
          fontWeight: '900',
          letterSpacing: '1px',
          marginRight: '20px'
        },
        align: 'left',
        margin: 60,
        x: 45
      },
      series,
      yAxis: [
        {
          title: {
            text: 'TOTAL',
            style: {
              color: '#838EAB',
              fontSize: '10px'
            },
            x: -30
          },
          tickAmount: 8,
          labels: {
            format: `{value}`,
            style: {
              color: '#838EAB',
              fontSize: '10px'
            }
          },
          gridLineWidth: 40,
          gridLineColor: '#F4F4FA'
        },
        {
          title: {
            text: 'SPEND',
            rotation: 270,
            style: {
              color: '#838EAB',
              fontSize: '10px'
            },
            x: 20
          },
          tickAmount: 8,
          labels: {
            format: `${
              currencyOptions[this.adStatsData.current.spend.currency].symbol
            }{value}`,
            style: {
              color: '#838EAB',
              fontSize: '10px'
            }
          },
          gridLineWidth: 40,
          gridLineColor: '#F4F4FA',
          opposite: true
        }
      ],
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: '#838EAB',
            fontSize: '10px'
          }
        },
        min: moment.utc(this.dateRanges.current.start).valueOf(),
        tickLength: 0,
        maxPadding: 0,
        endOnTick: false,
        startOnTick: false
      },
      exporting: {
        buttons: {
          contextButton: {
            height: 34,
            width: 16,
            symbolStroke: 'transparent',
            x: 48,
            y: -58
          }
        }
      },
      credits: {
        enabled: false
      },
      time: {
        useUTC: false
      }
    };
  }
}
