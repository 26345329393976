<div class="section-heading">
  <h3>
    <span
      translate="LINK_CLICKS"
    ></span>
    <i
      class="ssi ssi-help"
      ssiTooltip="These figures show all clicks on all posts for the account/s during the reporting period"
      tooltipWindowClass="tooltip-chunky-big"
    ></i>
    <div class="exact-totals-switch" *ngIf="isMarketingReport">
      <ssi-toggle-switch-2
        [ssiTooltip]="'Turning this on shows you the clicks on posts published within the report range only, rather than on all posts'"
        [label]="'LIMIT CLICKS TO POSTS PUBLISHED WITHIN RANGE'"
g        [(ngModel)]="viewClicksOnPosts"
        (ngModelChange)="onViewClicksOnPostsToggled($event)"></ssi-toggle-switch-2>
    </div>
  </h3>
  <ssi-social-network-select
    [selectableNetworks]="selectableNetworks"
    (networkSelect)="onNetworkSelect($event)"
  ></ssi-social-network-select>
</div>

<div
  class="section-content"
  ssiLoader
  [ssiLoaderVisible]="loading"
>

  <ssi-highchart [config]="chartOptions"></ssi-highchart>

  <div class="total-guidance-container">
    <div class="total-clicks">
      <ssi-fact-overview
        [label]="'TOTAL_CLICKS' | translate"
        [number]="clicksData?.totalClicks"
        iconClass="ssi-select-microcopy"
        iconColor="#F0B427"
        circleColor="#fbf3e2"
        [width]="'250px'"
        [height]="'100%'"
        [ssiTooltip]="'THIS_IS_THE_TOTAL_NUMBER_OF_TIMES_THE_LINKS_IN_YOUR_POSTS_HAVE_BEEN_CLICKED__SIMPLE' | translate"
        tooltipWindowClass="tooltip-chunky-big"
      >
      </ssi-fact-overview>
    </div>
    <div class="guidance-text">
      <div class="text">
        <h2 translate="MAKE_SURE_IT_ALL_CLICKS_INTO_PLACE"></h2>
        <p [innerHTML]="'LINK_CLICKS_TRACKING_GUIDANCE_DETAILED_DESCRIPTION' | translate"></p>
      </div>
      <img
        src="assets/images/link-clicks-guidance.png"
        alt="A person drawing with the marker on the chalkboard"
      >
    </div>
  </div>

  <div
    class="global-link-clicks"
    id="global-link-clicks"
  >
    <div class="map-container">
      <h4 translate="GLOBAL_LINK_CLICKS"> <i
          class="ssi ssi-help"
          [ssiTooltip]="'THIS_GRAPH_SHOWS_YOU_THE_LOCATION_OF_LINK_CLICKS_ACROSS_ALL_NETWORKS_BUT_THESE_CAN_ONLY_BE_TRACKED_IF_THE_CUSTOMER_HAS_LOCATION_SERVICES_ENABLED' | translate"
          tooltipWindowClass="tooltip-chunky-big"
        ></i></h4>

      <ssi-map
        [zoom]="2"
        [center]="[43.325178,-41.859375]"
        [mapHeight]="400"
        [markers]="mapMarkers"
        [zoomOnClick]="false"
      >
      </ssi-map>
    </div>
    <div
      class="locations-list-container"
      [class.full-width]="clicksData.locations.length > 8"
      *ngIf="clicksData?.locations.length > 0"
    >
      <h4 translate="LOCATIONS"></h4>
      <ul class="locations-list">
        <li *ngFor="let location of clicksData.locations">
          <span>{{location.country.name.common}}</span> <span>{{location.count}}</span>
          <ssi-progress-bar
            [max]="clicksData.locations[0].count"
            [progress]="location.count"
            [thickness]="4"
            bgColor="#fff"
            [color]="location.colour"
            [radius]="5"
          ></ssi-progress-bar>
        </li>
      </ul>
    </div>
    <div
      class="locations-list-container no-results"
      *ngIf="clicksData?.locations.length === 0"
    >
      <i class="ssi ssi-no-results"></i>
      <h4 translate="NOTHING_TO_SEE_HERE"></h4>
      <p translate="NO_LINK_CLICKS_VIEW_DESCRIPTION"></p>
    </div>
  </div>
</div>