import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditLinksModalComponent } from './edit-links-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleSwitch2Module } from '../toggle-switch-2/toggle-switch-2.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrloComponentsModule } from 'orlo-components';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { MediaNavModule } from '../media-nav/media-nav.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToggleSwitch2Module,
    FlatpickrModule,
    OrloComponentsModule,
    TooltipModule,
    MediaNavModule
  ],
  declarations: [EditLinksModalComponent],
  exports: [EditLinksModalComponent],
  providers: [EditLinksModalComponent],
  entryComponents: [EditLinksModalComponent]
})
export class EditLinksModalModule {}
