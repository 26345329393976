import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

import { Account, AccountModel } from '@ui-resources-angular';
import { groupBy } from '../../../../../common/utils';

@Component({
  selector: 'ssi-cl-dropdown-select-a',
  templateUrl: './cl-dropdown-select-a.component.html',
  styles: [],
  styleUrls: ['./cl-dropdown-select-a.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClDropdownSelectAComponent implements OnInit {
  @Input() accounts: Account[] = [];
  selectedAccounts: Account[] = [];
  selectedAccountTypeIds: string[] = [];

  constructor() {}

  ngOnInit() {}

  selectedAccountsChanged(selectedAccounts: Account[]): void {
    this.selectedAccounts = selectedAccounts;
  }
}
