import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-phrase-confirm-modal',
  templateUrl: './phrase-confirm-modal.component.html',
  styles: [],
  styleUrls: ['./phrase-confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhraseConfirmModalComponent implements OnInit {
  @Input() type: 'delete' | 'sensitiveContent' = 'delete';

  title = 'Please Confirm';
  description = 'Some description';
  passphrase = 'DELETE';
  passphraseDescription = `Enter security phrase "DELTE" to proceed:`;
  confirmButtonText = 'Delete';

  passphraseModel = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.type === 'sensitiveContent') {
      this.title = 'Sensitive Content Warning';
      this.description =
        'Orlo has detected that this attachment contains sensitive content and has hidden it from you automatically. You can view the attachment by entering the phrase below, displaying it for you only - other users will need to reveal it for themselves, if required. Once revealed, you can hide the attachment using the option on the message actions.';
      this.passphrase = 'REVEAL';
      this.passphraseDescription = `Enter security phrase "REVEAL" to view:`;
      this.confirmButtonText = 'Reveal image';
    }
  }
}
