import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-composer-cancel-modal',
  templateUrl: './composer-cancel-modal.component.html',
  styles: [],
  styleUrls: ['./composer-cancel-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComposerCancelModalComponent {
  @Input() title: string;
  @Input() isDraft: boolean;

  constructor(public activeModal: NgbActiveModal) {}
}
