import { ViewEncapsulation } from '@angular/core';

import { Component } from '@angular/core';

@Component({
  selector: 'ssi-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntryComponent {
  constructor() {}
}
