import { ViewEncapsulation } from '@angular/core';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'ssi-total-stat-card',
  templateUrl: './total-stat-card.component.html',
  styles: [],
  styleUrls: ['./total-stat-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TotalStatCardComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() value: string | number;
  @Input() iconSize: string;
  @Input() iconColor: string;
  @Input() growthValue?: string | number;
  @Input() growth?: boolean;
  @Input() iconBackgroundColor?: string;
  @Input() height?: string;
  @Input() valueIsString?: boolean = false;
  @Input() lowerIsPositive?: boolean;

  constructor() {}
}
