import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'ssi-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {
  @Input() size: number;
  @Input() visible = false;
  @Input() borderColor: string;
  @Input() borderTopColor: string;
  @Input() variant: 'blue' | 'white' = 'blue';

  constructor() {}

  ngOnInit() {}

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  isVisible() {
    return this.visible;
  }
}
