import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageAttachmentsComponent } from './message-attachments.component';
import { FallbackImageModule } from '@orlo/common/directives/fallback-image/fallback-image.module';
import { IconFileComponent } from '@orlo/common/components/icon-file/icon-file.component';
import { EnlargeImageModule } from '@orlo/common/directives/enlarge-image/enlarge-image.module';
import { BlurImageModule } from '@orlo/common/directives/blur-image/blur-image.module';
import { ImageUrls } from './image-urls.pipe';

@NgModule({
  imports: [
    CommonModule,
    FallbackImageModule,
    EnlargeImageModule,
    BlurImageModule
  ],
  declarations: [MessageAttachmentsComponent, ImageUrls, IconFileComponent],
  exports: [MessageAttachmentsComponent]
})
export class MessageAttachmentsModule {}
