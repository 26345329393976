import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-message-link-preview',
  templateUrl: './message-link-preview.component.html',
  styleUrls: ['./message-link-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageLinkPreviewComponent {
  @Input() selfPenned: boolean;
  @Input() title: string;
  @Input() description: string;
  @Input() url: string;
  @Input() thumb: string;
  validThumb = true;

  constructor() {}

  handleThumbError() {
    this.validThumb = false;
  }
}
