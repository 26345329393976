import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'ssi-conversation-resolution-confirmation',
  templateUrl: './conversation-resolution-confirmation.component.html',
  styleUrls: ['./conversation-resolution-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConversationResolutionConfirmationComponent implements OnInit {
  @Output() onDismiss = new EventEmitter();

  private _microcopyIndex: number;

  availableMicrocopy = [
    {
      icon: 'ssi ssi-microcopy-fireworks',
      label: 'Okay!',
      primaryText: 'ALL_RESOLVED',
      secondaryText: `GO_YOU_HEAD_HOME_TO_SEE_WHERE_YOURE_AT`
    }
    // {
    //   icon: 'ssi ssi-microcopy-koala',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Muy bien!',
    //   secondaryText: 'Cuddly koala salutes you'
    // },
    // {
    //   icon: 'ssi ssi-microcopy-boxing',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Float like a social butterfly',
    //   secondaryText: '(&amp; Sting like a bee)'
    // },
    // {
    //   icon: 'ssi ssi-microcopy-snake',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Ssssssensational',
    //   secondaryText: 'Powering through your posts!'
    // },
    // {
    //   icon: 'ssi ssi-microcopy-fox',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'You little fox',
    //   secondaryText: 'Good job! Go be social'
    // },
    // {
    //   icon: 'ssi ssi-microcopy-karate',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'All done!',
    //   secondaryText: 'Go forth &amp; do great things'
    // },
    // {
    //   icon: 'ssi ssi-microcopy-moose',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Good job',
    //   secondaryText: "Here's a congratulatory moose"
    // },
    // {
    //   icon: 'ssi ssi-microcopy-wave',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Ride the wave',
    //   secondaryText: '&amp; Stay productive'
    // },
    // {
    //   icon: 'ssi ssi-microcopy-bird',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Tweet tweet!',
    //   secondaryText: `(Translation: "Right on!")`
    // },
    // {
    //   icon: 'ssi ssi-microcopy-namaste',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Namaste',
    //   secondaryText: 'Great work today'
    // },
    // {
    //   icon: 'ssi ssi-microcopy-sun',
    //   label: 'Cool, show me the stats?',
    //   primaryText: "Here's some sun",
    //   secondaryText: "It's been cloudy lately"
    // },
    // {
    //   icon: 'ssi ssi-microcopy-firms',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Gimme firms',
    //   secondaryText: "(Couldn't have done it better myself)"
    // },
    // {
    //   icon: 'ssi ssi-microcopy-fists',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Tremendous',
    //   secondaryText: "You've got your brain in gear!"
    // },
    // {
    //   icon: 'ssi ssi-microcopy-rockstar',
    //   label: 'Cool, show me the stats?',
    //   primaryText: 'Daaang',
    //   secondaryText: "You've got this down, rockstar"
    // }
  ];

  constructor() {}

  async ngOnInit() {
    const max = this.availableMicrocopy.length - 1;
    const value = this._getRandomIntegerBetween(0, max);
    this._microcopyIndex = value;
  }
  public get messageIcon() {
    if (!this._microcopyIndex === undefined) {
      return;
    }

    return this.availableMicrocopy[this._microcopyIndex].icon;
  }

  public get primaryMessageText() {
    if (!this._microcopyIndex === undefined) {
      return;
    }

    return this.availableMicrocopy[this._microcopyIndex].primaryText;
  }

  public get secondaryMessageText() {
    if (!this._microcopyIndex === undefined) {
      return;
    }

    return this.availableMicrocopy[this._microcopyIndex].secondaryText;
  }

  private _getRandomIntegerBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
