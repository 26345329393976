import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-cl-context-menu',
  templateUrl: './cl-context-menu.component.html',
  styles: [],
  styleUrls: ['./cl-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClContextMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  delete(message: string): void {
    window.alert(message);
  }
}
