import { ViewEncapsulation } from '@angular/core';

import { Component, HostListener, OnInit } from '@angular/core';
import {
  OutboxPublisher,
  socialNetworkSettings,
  Account,
  SocialNetworkLinkPreview,
  SocialNetwork,
  OutboxPublisherLink,
  OutboxPublisherMention,
  OutboxPublisherFile
} from '@ui-resources-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { get } from 'lodash-es';

interface SocialNetworkWithAccounts {
  config: SocialNetwork;
  accounts: Account[];
}

export const previewSupportedAccountTypeIds = [2, 3, 15, 4, 6, 12];

@Component({
  selector: 'ssi-publisher-social-network-preview-modal',
  templateUrl: './publisher-social-network-preview-modal.component.html',
  styleUrls: ['./publisher-social-network-preview-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublisherSocialNetworkPreviewModalComponent implements OnInit {
  post: OutboxPublisher;
  activeAccount: Account;
  activeNetwork: SocialNetworkWithAccounts;
  activeNetworkIndex: number;
  socialNetworks = socialNetworkSettings;
  text: string;
  links: OutboxPublisherLink[];
  mentions: OutboxPublisherMention[];
  files: OutboxPublisherFile[];
  preview: SocialNetworkLinkPreview | void;
  loadingTracker = this.asyncTrackerFactory.create();
  errorMessage: string;

  accountName = {
    2: {
      accountName: 'X'
    },
    3: {
      accountName: 'Facebook'
    },
    15: {
      accountName: 'Facebook Group'
    },
    4: {
      accountName: 'LinkedIn'
    },
    6: {
      accountName: 'YouTube'
    },
    12: {
      accountName: 'Instagram'
    }
  };

  constructor(
    public activeModal: NgbActiveModal,
    private asyncTrackerFactory: AsyncTrackerFactory
  ) {}

  ngOnInit() {
    this.setActiveNetwork(this.post.socialNetworks[0]);
  }

  filterSupportedPreviews = (item: SocialNetworkWithAccounts) =>
    item &&
    previewSupportedAccountTypeIds.includes(Number(item.config.accountTypeId));

  async setActiveNetwork(
    network: SocialNetworkWithAccounts,
    account = network.accounts[0]
  ) {
    this.files = this.post.getFilesForAccount(account);
    this.activeNetworkIndex = this.post.socialNetworks.indexOf(network);
    this.activeNetwork = network;
    this.activeAccount = account;
    const { value, links, mentions } = this.post.getSocialNetworkTextPreview(
      this.activeAccount
    );
    this.text = value;
    this.links = links;
    this.mentions = mentions;
    const fetchingPreview = this.post
      .getSocialNetworkLinkPreview(this.activeAccount)
      .catch((err) => {
        if (err.robotsTxtBlocked) {
          this.errorMessage = `
          The URL you’re sharing is blocking our previewer. Please <a href="https://support.orlo.tech/robots-blocking-previewer" target="_blank">click here</a> for further information.`;
        } else {
          const errorFromApi = get(err, 'response.data.message');
          this.errorMessage = `${errorFromApi}<br>
          This is normally because the website is blocking access from crawlers.
        `;
        }
      });
    this.loadingTracker.add(fetchingPreview);
    this.preview = await fetchingPreview;
  }

  @HostListener('document:keyup.ArrowLeft')
  goToPreviousNetwork() {
    if (this.activeNetworkIndex !== 0) {
      this.setActiveNetwork(
        this.post.socialNetworks[this.activeNetworkIndex - 1]
      );
    }
  }

  @HostListener('document:keyup.ArrowRight')
  goToNextNetwork() {
    if (this.activeNetworkIndex !== this.post.socialNetworks.length - 1) {
      this.setActiveNetwork(
        this.post.socialNetworks[this.activeNetworkIndex + 1]
      );
    }
  }
}
