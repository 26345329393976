import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  workflowAccounts,
  companyPreferences,
  companyConfigResolveFn
} from '../../../../common-resolves';
import { StateService } from '@uirouter/angular';
import {
  CompanyService,
  CompanyConfig,
  CompanyPreferences
} from '@orlo/common/services/company/company.service';
import { SurveyService } from '@orlo/common/services/api/surveys';
import { Account } from '@ui-resources-angular';

interface AccountSurveySetting {
  id: string;
  enabled: boolean;
  score_question: string;
  comment_question: string;
  end_survey_message;
}

export interface SurveySetting {
  company: {
    enabled: boolean;
    type: string;
    max: number;
    min: number;
    send_automatically: boolean;
    send_delay: number;
  };
  accounts: AccountSurveySetting[];
}

@Component({
  selector: 'ssi-survey-settings',
  templateUrl: './survey-settings.component.html',
  styles: [],
  styleUrls: ['./survey-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveySettingsComponent implements OnInit {
  static resolve = [
    companyPreferences,
    {
      token: 'companyConfig',
      resolveFn: companyConfigResolveFn,
      deps: [CompanyService]
    },
    workflowAccounts
  ];

  @Input() companyPreferences: CompanyPreferences;
  @Input() companyConfig: CompanyConfig;
  @Input() workflowAccounts: Account[];

  surveySettingsForm: FormGroup;
  selectedAccount: Account;
  selectedAccountIndex: number = 0;
  accountSurveySettings: AccountSurveySetting[] = [];
  showAccountSurveyForm: boolean = false;
  filteredSurveyAccounts: Account[];
  surveyConfig;
  disableUserInput: boolean;

  constructor(
    private state: StateService,
    private surveyService: SurveyService
  ) {}

  async ngOnInit() {
    this.surveyConfig = await this.surveyService
      .getConfig()
      .then((config) => config);

    this.surveySettingsForm = new FormGroup({
      enabled: new FormControl(this.surveyConfig.company.enabled, []),
      type: new FormControl(this.surveyConfig.company.type, []),
      score_min: new FormControl(this.surveyConfig.company.score_min, []),
      score_max: new FormControl(this.surveyConfig.company.score_max, []),
      send_automatically: new FormControl(
        this.surveyConfig.company.send_automatically,
        []
      ),
      send_delay: new FormControl(this.surveyConfig.company.send_delay, [
        Validators.required,
        Validators.min(1),
        Validators.max(24)
      ])
    });

    this.filteredSurveyAccounts = this.workflowAccounts.filter(
      ({ account_type_id }) => {
        return ['2', '3'].includes(account_type_id);
      }
    );

    this.accountSurveySettings = this.filteredSurveyAccounts.map(({ id }) => {
      return {
        id,
        enabled: false,
        score_question: '',
        comment_question: '',
        end_survey_message: ''
      };
    });

    for (const accountConfig of this.surveyConfig.accounts) {
      const configuredAccount = this.accountSurveySettings.find(
        ({ id }) => parseInt(id, 10) === accountConfig.id
      );
      if (configuredAccount) {
        const index = this.accountSurveySettings.findIndex(
          ({ id }) => parseInt(id, 10) === accountConfig.id
        );

        // TODO ask backend if survey account ids can be saved as string same as regular account ids
        const savedAccount = Object.assign({}, ...accountConfig);
        savedAccount.id = savedAccount.id.toString();

        this.accountSurveySettings[index] = savedAccount;
      }
    }

    if (
      this.surveyConfig.company.use_emojis &&
      this.surveyConfig.company.type === 'csat'
    ) {
      this.surveySettingsForm.get('type').setValue('csat_emoji');
      this.disableUserInput = true;
    }

    if (this.surveyConfig.company.type === 'nps') {
      this.disableUserInput = true;
    }

    this.selectedAccount = this.filteredSurveyAccounts[0];
    this.onChanges();
  }

  onChanges(): void {
    this.surveySettingsForm.get('type').valueChanges.subscribe((type) => {
      switch (type) {
        case 'nps':
          this.disableUserInput = true;
          this.surveySettingsForm.get('score_max').clearValidators();
          this.patchNPSScore();
          break;
        case 'csat_emoji':
          this.disableUserInput = true;
          this.surveySettingsForm
            .get('score_max')
            .setValidators([Validators.required, Validators.max(5)]);
          this.patchCSATEmojiScore();
          break;
        default:
          this.surveySettingsForm.get('score_max').clearValidators();
          this.surveySettingsForm.updateValueAndValidity();
          this.disableUserInput = false;
          break;
      }
    });
  }

  patchNPSScore() {
    if (
      this.surveySettingsForm.value.score_min !== 0 ||
      this.surveySettingsForm.value.score_max !== 10
    ) {
      this.surveySettingsForm.patchValue({
        score_min: 0,
        score_max: 10
      });
    }
    this.surveySettingsForm.updateValueAndValidity();
  }

  patchCSATEmojiScore() {
    if (
      this.surveySettingsForm.value.score_min !== 0 ||
      this.surveySettingsForm.value.score_max !== 5
    ) {
      this.surveySettingsForm.patchValue({
        score_min: 1,
        score_max: 5
      });
    }
    this.surveySettingsForm.updateValueAndValidity();
  }

  onSelectedAccountsChange(selectedAccount: Account): void {
    console.log(this.accountSurveySettings);
    this.showAccountSurveyForm = true;
    this.selectedAccountIndex = this.accountSurveySettings.findIndex(
      ({ id }) => id === selectedAccount.id
    );
  }

  convertStringToNumber(accountTypeId: string) {
    return Number(accountTypeId);
  }

  saveSurveySettings() {
    const payload = {
      company: Object.assign({}, { ...this.surveySettingsForm.value }),
      accounts: this.accountSurveySettings
    };
    if (this.surveySettingsForm.value.type === 'csat_emoji') {
      payload.company.type = 'csat';
      payload.company.use_emojis = true;
    } else {
      payload.company.use_emojis = false;
    }
    this.surveyService.patchConfig(payload);
  }

  return() {
    this.state.go('^');
  }
}
