import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalListComponent } from './modal-list.component';
import { InfluencersListModule } from '@orlo/common/components/influencers-list/influencers-list.module';

@NgModule({
  declarations: [ModalListComponent],
  imports: [CommonModule, InfluencersListModule],
  exports: [ModalListComponent]
})
export class ModalListModule {}
