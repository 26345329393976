import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input } from '@angular/core';
import {
  ActivityTags,
  API,
  CrmExternalIntegration,
  CrmExternalIntegrationModel,
  CrmExternalIntegrationType,
  NewCrmExternalIntegration,
  User
} from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import {
  companyPreferencesResolveFn,
  authUser
} from './../../../../common-resolves';
import {
  CompanyService,
  CompanyPreferences
} from '../../../../../common/services/company/company.service';
import { SpreadsheetService } from '../../../../../common/services/spreadsheet/spreadsheet.service';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';

interface EngagementSla {
  threshold: number;
}

const getOrderedSlas = (slas: EngagementSla[]): EngagementSla[] =>
  slas.sort((a, b) => a.threshold - b.threshold);

export async function snippetsResolveFn(company: CompanyService) {
  return company.getInboxSnippets();
}

export async function tagsResolveFn(activityTags: ActivityTags) {
  return activityTags.getTags();
}

export async function crmExternalIntegrationTypesResolveFn(
  crmExternalIntegrationModel: CrmExternalIntegrationModel
) {
  return crmExternalIntegrationModel.getTypes();
}

export async function configuredCrmExternalIntegrationsResolveFn(
  crmExternalIntegrationModel: CrmExternalIntegrationModel
) {
  return crmExternalIntegrationModel.findAllActive();
}

@Component({
  selector: 'ssi-inbox-settings',
  templateUrl: './inbox-settings.component.html',
  styleUrls: ['./inbox-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxSettingsComponent implements OnInit {
  static resolve = [
    authUser,
    {
      token: 'settings',
      resolveFn: companyPreferencesResolveFn,
      deps: [CompanyService]
    },
    {
      token: 'snippets',
      resolveFn: snippetsResolveFn,
      deps: [CompanyService]
    },
    {
      token: 'tags',
      resolveFn: tagsResolveFn,
      deps: [ActivityTags]
    },
    {
      token: 'crmExternalIntegrationTypes',
      resolveFn: crmExternalIntegrationTypesResolveFn,
      deps: [CrmExternalIntegrationModel]
    },
    {
      token: 'configuredCrmExternalIntegrations',
      resolveFn: configuredCrmExternalIntegrationsResolveFn,
      deps: [CrmExternalIntegrationModel]
    }
  ];

  @Input() authUser: User;
  @Input() settings: CompanyPreferences;
  @Input() snippets;
  @Input() tags;
  @Input() crmExternalIntegrationTypes: CrmExternalIntegrationType[];
  @Input() configuredCrmExternalIntegrations: CrmExternalIntegration[];
  newIntegrationType: CrmExternalIntegrationType;
  tagsExport;
  newSnippet = { title: '', body: '' };
  newTag;
  newApprovalTag;
  newIntegration: {
    type: CrmExternalIntegrationType;
    form: NewCrmExternalIntegration;
  };
  newIntegrationLoadingTracker = this.asyncTrackerFactory.create();
  engagementReportSLAs: EngagementSla[];
  // responseTimeSLAs: any[];
  orderedSLAs;
  // orderedResponseSLAs;
  loadingTracker = this.asyncTrackerFactory.create();
  currentTagsPage = 1;

  approvalTags = ['test', 'test 2', 'test 3', 'test 4'];
  // replace with API call

  hasValidatePostPermission = false;

  constructor(
    private company: CompanyService,
    private state: StateService,
    private api: API,
    private activityTags: ActivityTags,
    private spreadsheet: SpreadsheetService,
    private crmExternalIntegrationModel: CrmExternalIntegrationModel,
    private translate: TranslateService,
    private popup: PopupService,
    private asyncTrackerFactory: AsyncTrackerFactory
  ) {}

  ngOnInit() {
    const tagsExport = [
      {
        name: 'Inbox tags export',
        rows: this.tags.map((tag) => [tag]),
        header: ['Tag']
      }
    ];

    this.spreadsheet.jsonToXlsx(tagsExport).then((file) => {
      this.tagsExport = file;
    });

    this.engagementReportSLAs = JSON.parse(
      this.settings.company_preferences.engagement_report_slas
    );
    this.orderedSLAs = getOrderedSlas(this.engagementReportSLAs);

    this.hasValidatePostPermission = this.authUser.hasCompanyPermission(
      'validate_posts'
    );

    // this.responseTimeSLAs = [];
    // this.orderedResponseSLAs = this._getOrderedResponseSlas(this.responseTimeSLAs);
  }

  async handlePopup(title, message, action, actionParams) {
    const confirmDelete = await this.popup.confirm({
      title: this.translate.instant(title),
      message: this.translate.instant(message)
    });
    if (confirmDelete) {
      switch (action) {
        case 'deleteSnippet':
          this.deleteSnippet(...actionParams);
          break;
        case 'deleteTag':
          this.deleteTag(...actionParams);
          break;
        case 'deleteApprovalTag':
          this.deleteApprovalTag(...actionParams);
          break;
        case 'disableCrmIntegration':
          this.disableCrmIntegration(...actionParams);
      }
    }
  }

  saveSettings() {
    return this.api.post('settings/companyPreference', this.settings);
  }

  createSnippet() {
    this.company
      .createInboxSnippet(this.newSnippet.title, this.newSnippet.body)
      .then((snippet) => {
        this.snippets.push(snippet);
        this.newSnippet = { title: '', body: '' };
      });
  }

  deleteSnippet(snippet?) {
    this.company.deleteInboxSnippet(snippet).then(() => {
      this.snippets = this.snippets.filter((iSnippet) => iSnippet !== snippet);
    });
  }

  createTag() {
    this.activityTags.createTag(this.newTag).then(() => {
      this.tags.push(this.newTag);
      this.tags.sort((tag1, tag2) => tag1.localeCompare(tag2));
      this.newTag = '';
    });
  }

  deleteTag(tag?: string, purge?: boolean) {
    this.activityTags.deleteTag(tag, purge).then(() => {
      this.tags = this.tags.filter((iTag) => iTag !== tag);
    });
  }

  createApprovalTag() {
    // this.activityTags.createTag(this.newTag).then(() => {
    //   this.tags.push(this.newTag);
    //   this.tags.sort((tag1, tag2) => tag1.localeCompare(tag2));
    //   this.newTag = '';
    // });

    this.approvalTags.push(this.newApprovalTag);
    this.approvalTags.sort((tag1, tag2) => tag1.localeCompare(tag2));
    this.newApprovalTag = '';
  }

  deleteApprovalTag(tag?: string, purge?: boolean) {
    // this.activityTags.deleteTag(tag, purge).then(() => {
    //   this.tags = this.tags.filter((iTag) => iTag !== tag);
    // });

    // use api call here to remove from db
    this.approvalTags = this.approvalTags.filter((iTag) => iTag !== tag);
  }

  beginCreateNewCrmIntegration(typeName) {
    const type: CrmExternalIntegrationType = this.crmExternalIntegrationTypes.find(
      (iType) => iType.name === typeName
    );
    this.newIntegration = {
      type,
      form: {
        type: typeName,
        name: '',
        configuration: {}
      }
    };
  }

  saveNewCrmIntegration() {
    const promise = this.crmExternalIntegrationModel
      .createNew(this.newIntegration.form)
      .then((newIntegration) => {
        this.configuredCrmExternalIntegrations.push(newIntegration);
        this.newIntegration = undefined;
        this.newIntegrationType = undefined;
      });
    this.newIntegrationLoadingTracker.add(promise);
  }

  disableCrmIntegration(integration?: CrmExternalIntegration) {
    integration.disable().then(() => {
      this.configuredCrmExternalIntegrations = this.configuredCrmExternalIntegrations.filter(
        (iIntegration) => iIntegration !== integration
      );
    });
  }

  deleteSla(sla: EngagementSla) {
    this.engagementReportSLAs = this.engagementReportSLAs.filter(
      (iSla) => iSla !== sla
    );
    this.orderedSLAs = getOrderedSlas(this.engagementReportSLAs);
  }

  addSla() {
    const orderedSlas = getOrderedSlas(this.engagementReportSLAs);
    this.engagementReportSLAs.push({
      threshold:
        orderedSlas.length > 0
          ? orderedSlas[orderedSlas.length - 1].threshold + 1
          : 60
    });
  }

  saveSlas() {
    this.settings.company_preferences.engagement_report_slas = JSON.stringify(
      getOrderedSlas(this.engagementReportSLAs)
    );
    this.saveSettings().then(() => this.state.go('auth.settings.index'));
  }

  // saveResponseSlas() {
  //   // api request then run _getOrderedResponseSlas
  //   this.saveSettings().then(() => this.state.go('auth.settings.index'));
  // }

  // private _getOrderedResponseSlas(slas: any[]): any[] {
  //   return slas.sort((a, b) => a.threshold - b.threshold);
  // }

  // addResponseSla() {
  //   const orderedResponseSlas = this._getOrderedResponseSlas(this.responseTimeSLAs);
  //   this.responseTimeSLAs.push({
  //     threshold:
  //       orderedResponseSlas.length > 0
  //         ? orderedResponseSlas[orderedResponseSlas.length - 1].threshold + 1
  //         : 60
  //   });
  // }

  // deleteResponseSla(sla) {
  //   this.responseTimeSLAs = this.responseTimeSLAs.filter(
  //     (iSla) => iSla !== sla
  //   );
  //   this.orderedResponseSLAs = this._getOrderedResponseSlas(this.responseTimeSLAs);
  // }
}
