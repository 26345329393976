import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import {
  Sentiment,
  findSentimentConst
} from '../../../../../../common/constants';
import {
  FieldName,
  filtersFieldDefinitions,
  formatFilterTypeData
} from '../../constants/filters-field-definitions';
import { Filter } from '../../../reports/view/view-report.component';
import {
  InisightsClusterDocument,
  InsightsService,
  KPI
} from '../../../insights.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ClusterDocument,
  ClusteringStatusSource,
  DrilldownModalComponent
} from '../drilldown-modal/drilldown-modal.component';
import { Subject, Subscription, interval, merge, throwError } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SocketEventManagerService } from '../../../../../../common/services/sockets/socket-event-manager.service';

export interface ClusteringJob {
  clusteringObservables: any;
  stopConditions$: any;
  pollingDocumentStatus$: Subscription;
  clusteringDone$: Subject<any>;
  clusteringFailed$: Subject<any>;
  documentReady$: Subject<any>;
  clusteringJobFailed: boolean;
  loadingClusters: boolean;
  themes: string[];
}

export interface KpiData {
  label: string;
  val: string[];
}

@Component({
  selector: 'ssi-widget-theme-cloud',
  templateUrl: './widget-theme-cloud.component.html',
  styles: [],
  styleUrls: ['./widget-theme-cloud.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetThemeCloudComponent implements OnChanges, OnInit, OnDestroy {
  @Input() label: string;
  @Input() widget: KPI;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();
  @Output() requiredRenderSize = new EventEmitter<{ w: number; h: number }>();
  @Output() loadingAgain = new EventEmitter<void>();

  jobsFinished: number = 0;

  themesData: Array<{
    openai_theme: string;
    sentiment: Sentiment;
    size: number;
    type: string;
  }> = [];
  averageThemeSize: number;

  // clustering - web sockets - API polling
  positive: ClusteringJob = {
    clusteringObservables: undefined,
    stopConditions$: undefined,
    pollingDocumentStatus$: undefined,
    clusteringDone$: new Subject(),
    clusteringFailed$: new Subject(),
    documentReady$: new Subject(),
    clusteringJobFailed: false,
    loadingClusters: false,
    themes: []
  };

  negative: ClusteringJob = {
    clusteringObservables: undefined,
    stopConditions$: undefined,
    pollingDocumentStatus$: undefined,
    clusteringDone$: new Subject(),
    clusteringFailed$: new Subject(),
    documentReady$: new Subject(),
    clusteringJobFailed: false,
    loadingClusters: false,
    themes: []
  };

  onDestroy = new Subject();

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal,
    private ngZone: NgZone,
    private socketEventManagerService: SocketEventManagerService
  ) {}

  ngOnInit(): void {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    this.loadingAgain.emit();
    this.themesData = [];
    this.jobsFinished = 0;
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data, metadata }) => {
        this.getClustersData('positive', metadata.positive_ticket);
        this.getClustersData('negative', metadata.negative_ticket);
      });
  }

  private addTheme(
    theme: InisightsClusterDocument,
    type: 'positive' | 'negative'
  ) {
    this.themesData.push({
      openai_theme: theme.openai_theme,
      sentiment: findSentimentConst('numericKey', type === 'positive' ? 2 : -2),
      size: theme.size,
      type
    });
    if (this.themesData && this.themesData.length >= 20) {
      this.themesData = this.themesData
        .sort((t1, t2) => t2.size - t1.size)
        .splice(0, 10);
      this.averageThemeSize =
        this.themesData.reduce((accumulator, object) => {
          return accumulator + object.size;
        }, 0) / this.themesData.length;

      this.loaded.emit();
    }
  }

  private async getClustersData(
    jobName: 'positive' | 'negative',
    ticket: string
  ) {
    if (!ticket) {
      return;
    }

    this[jobName].loadingClusters = true;
    this[jobName].themes = [];

    this[jobName].stopConditions$ = merge(
      this[jobName].documentReady$,
      this[jobName].clusteringFailed$,
      this[jobName].clusteringDone$,
      this.onDestroy
    ).pipe(
      tap((s) => console.log('Stop checking for Theme cloud clusters', jobName))
    );

    this[jobName].clusteringObservables = merge(
      this.socketEventManagerService.clusteringDone.pipe(
        takeUntil(this[jobName].stopConditions$),
        map((data) => ({ source: ClusteringStatusSource.clusteringDone, data }))
      ),
      this.socketEventManagerService.clusteringFailed.pipe(
        takeUntil(this[jobName].stopConditions$),
        map((data) => ({
          source: ClusteringStatusSource.clusteringFailed,
          data
        }))
      ),
      interval(5000).pipe(
        takeUntil(this[jobName].stopConditions$),
        startWith(0),
        switchMap(() => {
          console.log('Polling the ticket:', ticket);
          return this.insightsService.getInsightsClusterDocumentStatus(ticket);
        }),
        map((data) => ({ source: ClusteringStatusSource.apiPolling, data }))
      )
    );

    this[jobName].pollingDocumentStatus$ = this[
      jobName
    ].clusteringObservables.subscribe(
      async (res) => {
        console.log('Document ready response:', res);
        if (
          (res.source === ClusteringStatusSource.apiPolling &&
            !res.data.completed) ||
          res.data.ticket !== ticket
        ) {
          return;
        }

        if (res.source === ClusteringStatusSource.clusteringFailed) {
          this[jobName].clusteringFailed$.next();
          this[jobName].loadingClusters = false;
          this.jobsFinished++;
          return throwError('WebSockets sent clusteringFailed event');
        }

        this[jobName].documentReady$.next();
        const clusterDocument: InisightsClusterDocument[] = await this.insightsService.getInsightsClusterDocument(
          res.data.ticket
        );
        clusterDocument.splice(0, 5);

        console.log(`clusterDocument ready for ${jobName}:`, clusterDocument);

        if (Array.isArray(clusterDocument)) {
          const cloudGroup = clusterDocument.map((doc, index) => ({
            ...doc,
            openai_theme: doc.openai_theme,
            sentiment: findSentimentConst(
              'numericKey',
              jobName === 'positive' ? 2 : -2
            ),
            size: doc.size,
            type: jobName,
            jobTicket: res.data.ticket
          }));
          this.themesData.push(...cloudGroup);
          this.jobsFinished++;
          if (this.jobsFinished === 2) {
            this.themesData = this.themesData.sort(
              (t1, t2) => t2.size - t1.size
            );
            this.averageThemeSize =
              this.themesData.reduce((accumulator, object) => {
                return accumulator + object.size;
              }, 0) / this.themesData.length;

            this.loaded.emit();
          }
        }
        this.ngZone.run(() => {
          this[jobName].loadingClusters = false;
        });
      },
      (err) => {
        console.error('Error polling document status:', err);
        this.loaded.emit();

        this.ngZone.run(() => {
          this[jobName].clusteringJobFailed = true;
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  openDrilldownModal(theme: any) {
    const filterType =
      filtersFieldDefinitions[FieldName.Sentiment].preferedFilterType;

    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });
    modal.componentInstance.clusterId = theme.id;
    modal.componentInstance.jobTicket = theme.jobTicket;
    modal.componentInstance.enableThemeClusters = false;
    modal.componentInstance.globalFilters = this.globalFilters;
    modal.componentInstance.streamIds = this.streamIds;
    modal.componentInstance.widgetFilters = this.widget.filters;
    modal.componentInstance.selectedFilters = [
      {
        field: FieldName.Sentiment,
        [filterType]: formatFilterTypeData(
          theme.type === 'positive' ? 'Very Positive' : 'Very Negative',
          filterType
        )
      }
    ];
  }
}
