import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { Account, AccountModel } from '@ui-resources-angular';
import { orderBy } from 'lodash-es';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import {
  ContentGeneratorService,
  Feed,
  RssArticle
} from '../../../../../marketing/content-generator/content-generator.service';
import stripTags from 'strip';

@Component({
  selector: 'ssi-widget-rss-feeds',
  templateUrl: './widget-rss-feeds.component.html',
  styles: [],
  styleUrls: ['./widget-rss-feeds.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetRssFeedsComponent implements OnInit, OnChanges {
  @Input() widget: DashboardWidget;

  accounts: Account[] = [];
  selectedAccount: Account;
  articles: RssArticle[] = [];
  viewPortArticles: RssArticle[] = [];
  viewClass: string;

  @Output() loaded = new EventEmitter<boolean>();

  constructor(
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected contentGeneratorService: ContentGeneratorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.viewClass = `${this.widget.display_properties.w}x${this.widget.display_properties.h}`;
  }

  async ngOnInit() {
    this.viewClass = `${this.widget.display_properties.w}x${this.widget.display_properties.h}`;

    this.accounts = await this.accountModel.findAccounts(
      this.workflowManager.getCurrentId()
    );
    this.accounts = orderBy(this.accounts, ['account_type_name', 'name']);

    await this.loadArticles();
    this.loaded.emit(true);
  }

  async loadArticles(): Promise<void> {
    const allRssFeeds = await this.contentGeneratorService.getRssFeed();
    const activeFeeds = allRssFeeds.filter((feed) => {
      return this.widget.apiSearchParams.feedIds.includes(feed.id);
    });

    let allArticles = [];

    const feedPromises = activeFeeds.map((feed: Feed) => {
      return this.contentGeneratorService
        .getFeedArticles(feed.url)
        .then((feedItems) => {
          const articles = feedItems.map((item) => {
            return {
              title: stripTags(item.title),
              description: stripTags(item.content),
              url: item.url,
              created_at: item.published_date
                ? new Date(item.published_date)
                : new Date(),
              img: item.img,
              logo: item.logo,
              feed
            };
          });
          allArticles = [...allArticles, ...articles];
        });
    });

    await Promise.all(feedPromises);
    this.articles = orderBy(allArticles, ['created_at'], ['desc']);
    // console.log('this.articles : ', this.articles);
  }

  selectedAccountChanged(selectedAccount: Account): void {
    this.selectedAccount = selectedAccount;
  }

  getHeight = () => {
    return 284;
  };

  viewPortItemsUpdated(items: RssArticle[]): void {
    // console.log('viewPortItemsUpdated: ', items);
    this.viewPortArticles = items;
  }

  onScrolled(): void {
    // console.log('scrolled...');
  }
}
