import { ViewEncapsulation } from '@angular/core';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { OutboxPublisher, socialNetworkSettings } from '@ui-resources-angular';

import { AsyncTrackerFactory } from 'angular-async-tracker';

import { Suggestion } from '@orlo/library/interfaces/suggestion';
import { COUNTRIES } from '../../../../constants';
import { PublisherActive, PUBLISHER_ACTIVE } from '../../publisher-active';
import { Stage } from '../publisher-landing.component';
import * as linkedinTargeting from './linkedin-targeting';

import { AccountTypeName } from '../../../../enums';

// ----------------

const isDebug = false;

// ----------------

enum TargetingNetwork {
  Facebook = 'facebook',
  LinkedIn = 'linkedin',
  'Nextdoor Agency' = 'nextdooragency',
  'Nextdoor Agency US' = 'nextdooragencyus'
}

interface TargetingInput {
  disable?: boolean;
  key: string;
  labelTranslateKey: string;
  onChange?: () => void;
  options?: Array<{ code: string; label: string }>;
  placeholderTranslateKey: string;
  value: any[];
}

function getFacebookTargetingInput(
  ctrl: PublisherTargetingComponent,
  key: string
): TargetingInput {
  if (
    !(
      !!ctrl &&
      !!ctrl.targetingInputs &&
      !!ctrl.targetingInputs.Facebook &&
      Array.isArray(ctrl.targetingInputs.Facebook) &&
      !!ctrl.targetingInputs.Facebook.length
    )
  ) {
    return null;
  }
  return ctrl.targetingInputs.Facebook[0].find((input) => input.key === key);
}

const ALL_COUNTRIES = Object.keys(COUNTRIES).map((countryCode) => {
  return {
    code: countryCode,
    label: COUNTRIES[countryCode]
  };
});

@Component({
  selector: 'ssi-publisher-targeting',
  templateUrl: './publisher-targeting.component.html',
  styleUrls: ['./publisher-targeting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublisherTargetingComponent implements OnChanges, OnInit {
  @Input() post: OutboxPublisher;

  @Output() changeStage = new EventEmitter<{ stage: string }>();

  private _citiesCache = {};

  parentStages = Stage;

  TargetingNetwork = TargetingNetwork;

  activeNetwork: TargetingNetwork;

  socialNetworkSettings = socialNetworkSettings;

  targetingInputs: {
    [network: string]: TargetingInput[][];
  } = {
    Facebook: [
      [
        {
          key: 'countries',
          labelTranslateKey: 'COUNTRIES',
          onChange: () => this.onFacebookCountriesChange(),
          options: ALL_COUNTRIES,
          placeholderTranslateKey: 'EG_TYPE_FRANCE',
          value: [] as Suggestion[]
        },
        {
          key: 'cities',
          labelTranslateKey: 'CITIES',
          onChange: () => this.onFacebookCitiesChange(),
          options: [],
          placeholderTranslateKey: 'EG_TYPE_PARIS',
          value: []
        }
      ]
    ],
    LinkedIn: [
      [
        {
          key: 'geos',
          labelTranslateKey: 'LOCATIONS',
          placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_LOCATIONS',
          options: linkedinTargeting.geos,
          value: []
        }
      ],
      [
        {
          key: 'companySizes',
          labelTranslateKey: 'COMPANY_SIZE',
          placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_COMPANY_SIZES',
          options: linkedinTargeting.companySizes,
          value: []
        },
        {
          key: 'jobFunc',
          labelTranslateKey: 'JOB_FUNCTION',
          placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_JOB_FUNCTION',
          options: linkedinTargeting.jobFunc,
          value: []
        }
      ],
      [
        {
          key: 'industries',
          labelTranslateKey: 'INDUSTRY',
          placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_INDUSTRIES',
          options: linkedinTargeting.industries,
          value: []
        },
        {
          key: 'seniorities',
          labelTranslateKey: 'SENIORITY_LEVEL',
          placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_SENIORITIES',
          options: linkedinTargeting.seniorities,
          value: []
        }
      ]
    ]
  };

  hasFacebook: boolean;

  hasLinkedIn: boolean;

  hasNextdoor: boolean;

  hasNextdoorUS: boolean;

  singleValueInputs = {
    Facebook: {
      gender: null,
      relationship_status: null
    }
  };

  targetingLoadingTracker = this.asyncTrackerFactory.create();

  boundaries = [];
  selectedBoundaries = [];

  constructor(
    private asyncTrackerFactory: AsyncTrackerFactory,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive
  ) {}

  async getCitiesForCountryCode(text: string): Promise<Suggestion[]> {
    if (isDebug) {
      console.groupCollapsed(`publisherTargeting~>getCitiesForCountryCode`);
      console.log(`for code: ${text}`);
    }

    if (!text) {
      if (isDebug) {
        console.groupEnd();
      }

      return [];
    }

    const fb = socialNetworkSettings.Facebook;

    if (
      !(
        !!this.post &&
        !!this.post.accounts &&
        Array.isArray(this.post.accounts)
      )
    ) {
      return;
    }

    const account = this.post.accounts.find(
      (iAccount) => iAccount.socialNetwork === fb
    );
    const options = await fb.targeting.city(text, account);

    if (isDebug) {
      console.log(`city targeting options:`);
      console.dir(options);
    }

    const countryTargeting = getFacebookTargetingInput(this, 'countries');

    if (countryTargeting.value.length > 0) {
      const { code } = countryTargeting.value[0];
      const filteredOptions = options.filter(
        (option) =>
          option.country.code.toLocaleLowerCase() === code.toLocaleLowerCase()
      );

      if (isDebug) {
        console.log(`options filtered by country:`);
        console.dir(filteredOptions);
        console.groupEnd();
      }

      return filteredOptions;
    }

    if (isDebug) {
      console.log(`options:`);
      console.log(options);
      console.groupEnd();
    }

    return options;
  }

  async goBack() {
    if (isDebug) {
      console.groupCollapsed(`publisherTargeting~>goBack`);
    }

    if (isDebug) {
      console.log(`before post update`);
      console.log(`targetingInputs:`);
      console.dir(this.targetingInputs);
      console.log(`post targeting:`);
      console.dir(this.post.targeting);
    }

    Object.entries(this.targetingInputs).forEach(([networkKey, rows]) => {
      rows.forEach((row) => {
        row.forEach((input) => {
          if (input.key === 'cities' || input.key === 'interests') {
            this.post.targeting[networkKey][input.key] = input.value;
          } else {
            this.post.targeting[networkKey][input.key] = input.value.map(
              (value) => value.code
            );
          }
        });
      });
    });

    ['gender', 'relationship_status'].forEach((targetingKey) => {
      this.post.targeting.Facebook[targetingKey] = this.singleValueInputs
        .Facebook[targetingKey]
        ? this.singleValueInputs.Facebook[targetingKey].value
        : null;
    });

    const promise = this.post.validateTargeting();
    this.targetingLoadingTracker.add(promise);
    await promise;
    const output = { stage: this.parentStages.ActionsAndMedia };

    if (isDebug) {
      console.log(`emitted output:`);
      console.log(output);
    }

    this.changeStage.emit(output);

    if (isDebug) {
      console.log(`after post update`);
      console.log(`targetingInputs:`);
      console.dir(this.targetingInputs);
      console.log(`post targeting:`);
      console.dir(this.post.targeting);
    }

    if (isDebug) {
      console.groupEnd();
    }
  }

  async ngOnChanges(changes) {
    if (isDebug) {
      console.groupCollapsed(`publisherTargeting~>ngOnInit`);
      console.log(`Changes:`);
      console.dir(changes);
      console.groupEnd();
    }
  }

  async ngOnInit() {
    try {
      if (isDebug) {
        console.log(`in: publisherTargeting~>ngOnInit`);
      }

      this.hasFacebook = this.post.socialNetworks.some(
        (network) => network.config === socialNetworkSettings.Facebook
      );

      this.hasLinkedIn = this.post.socialNetworks.some(
        (network) => network.config === socialNetworkSettings.LinkedIn
      );

      this.hasNextdoor = this.post.socialNetworks.some(
        (network) =>
          network.config === socialNetworkSettings[AccountTypeName.Nextdoor]
      );

      this.hasNextdoorUS = this.post.socialNetworks.some(
        (network) =>
          network.config === socialNetworkSettings[AccountTypeName.NextdoorUS]
      );

      if (this.hasFacebook) {
        this.activeNetwork = TargetingNetwork.Facebook;
      } else if (this.hasLinkedIn) {
        this.activeNetwork = TargetingNetwork.LinkedIn;
      } else if (this.hasNextdoor) {
        this.activeNetwork = TargetingNetwork[AccountTypeName.Nextdoor];
      } else {
        this.activeNetwork = TargetingNetwork[AccountTypeName.NextdoorUS];
      }

      if (this.hasNextdoor) {
        this.boundaries = await socialNetworkSettings[
          AccountTypeName.Nextdoor
        ].targeting.boundaries(
          this.post.accounts.find((account) => account.account_type_id === '18')
            .id
        );
        this.selectedBoundaries = this.post.targeting[
          AccountTypeName.Nextdoor
        ].group_ids.map((id) =>
          this.boundaries.find((boundary) => boundary.group_id === id)
        );
      }

      if (this.hasNextdoorUS) {
        this.boundaries = await socialNetworkSettings[
          AccountTypeName.NextdoorUS
        ].targeting.boundaries(
          this.post.accounts.find((account) => account.account_type_id === '20')
            .id
        );
        this.selectedBoundaries = this.post.targeting[
          AccountTypeName.NextdoorUS
        ].group_ids.map((id) =>
          this.boundaries.find((boundary) => boundary.group_id === id)
        );
      }

      if (isDebug) {
        console.log(`targetingInputs:`);
        console.dir(this.targetingInputs);
        console.log(`post targeting:`);
        console.dir(this.post.targeting);
      }

      const countryTargeting = getFacebookTargetingInput(this, 'countries');
      await countryTargeting.onChange();

      Object.entries(this.targetingInputs).forEach(([networkKey, rows]) => {
        rows.forEach((row) => {
          row.forEach((input) => {
            input.value = (
              this.post.targeting[networkKey][input.key] || []
            ).map((value) => {
              if (input.key === 'cities' || input.key === 'interests') {
                return value;
              }

              if (
                !(!!input && !!input.options && Array.isArray(input.options))
              ) {
                return;
              }

              return input.options.find((option) =>
                typeof option.code === 'string'
                  ? option.code.toLocaleLowerCase() ===
                    value.toLocaleLowerCase()
                  : option.code === value
              );
            });
          });
        });
      });

      if (
        !(
          !!socialNetworkSettings &&
          !!socialNetworkSettings.Facebook &&
          !!socialNetworkSettings.Facebook.targeting
        )
      ) {
        throw new Error(
          `Value for 'Facebook gender targeting' not in expected format.`
        );
      }

      if (
        !(
          !!socialNetworkSettings &&
          !!socialNetworkSettings.Facebook &&
          !!socialNetworkSettings.Facebook.targeting &&
          !!socialNetworkSettings.Facebook.targeting.gender &&
          Array.isArray(socialNetworkSettings.Facebook.targeting.gender)
        )
      ) {
        throw new Error(
          `Value for 'Facebook gender targeting' not in expected format.`
        );
      }

      if (
        !(
          !!socialNetworkSettings &&
          !!socialNetworkSettings.Facebook &&
          !!socialNetworkSettings.Facebook.targeting &&
          !!socialNetworkSettings.Facebook.targeting.relationshipStatus &&
          Array.isArray(
            socialNetworkSettings.Facebook.targeting.relationshipStatus
          )
        )
      ) {
        throw new Error(
          `Value for 'Facebook gender relationship status' not in expected format.`
        );
      }

      this.singleValueInputs.Facebook.gender = socialNetworkSettings.Facebook.targeting.gender.find(
        (gender) => gender.value === +this.post.targeting.Facebook.gender
      );
      this.singleValueInputs.Facebook.relationship_status = socialNetworkSettings.Facebook.targeting.relationshipStatus.find(
        (status) =>
          status.value === +this.post.targeting.Facebook.relationship_status
      );

      const cityTargeting = getFacebookTargetingInput(this, 'cities');
      await cityTargeting.onChange();
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      if (isDebug) {
        console.log(`out: publisherTargeting~>ngOnInit`);
      }
    }
  }

  async onFacebookCitiesFilterChange(filter: string) {
    if (isDebug) {
      console.groupCollapsed(
        `publisherTargeting~>onFacebookCitiesFilterChange`
      );
    }

    if (isDebug) {
      console.log(`new filter value:`);
      console.log(filter);
    }

    const foundCities = await this.getCitiesForCountryCode(filter);
    const cityTargeting = getFacebookTargetingInput(this, 'cities');
    cityTargeting.options = foundCities;

    if (isDebug) {
      console.log(`found cities:`);
      console.dir(foundCities);
      console.groupEnd();
    }
  }

  async onFacebookCitiesChange() {
    try {
      if (isDebug) {
        console.log(`in: publisherTargeting~>onFacebookCitiesChange`);
      }

      requestAnimationFrame(() => {
        // the change won't actually be registered until after the change handler fires
        // so here we bounce it on to the next frame.

        const countryTargeting = getFacebookTargetingInput(this, 'countries');
        const cityTargeting = getFacebookTargetingInput(this, 'cities');

        if (isDebug) {
          console.log(`city targeting value:`);
          console.log(cityTargeting.value);
          console.log(cityTargeting.value.length);
        }

        if (!cityTargeting.value.length) {
          countryTargeting.disable = false;

          if (isDebug) {
            console.groupEnd();
          }

          return;
        }

        if (
          !(
            !!countryTargeting &&
            !!countryTargeting.options &&
            Array.isArray(countryTargeting.options)
          )
        ) {
          throw new Error(
            `Options for 'publisher country targeting' not in expected format.`
          );
        }

        countryTargeting.value = [
          countryTargeting.options.find(
            (option) =>
              option.code.toLocaleLowerCase() ===
              cityTargeting.value[0].country.code.toLocaleLowerCase()
          )
        ];
        countryTargeting.disable = true;

        if (isDebug) {
          console.log(`countryTargeting should be disabled now ...`);
          console.dir(this.targetingInputs);
        }
      });

      if (isDebug) {
        console.groupEnd();
      }
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      if (isDebug) {
        console.log(`publisherTargeting~>onFacebookCitiesChange`);
      }
    }
  }

  async onFacebookCountriesChange() {
    if (isDebug) {
      console.groupCollapsed(`publisherTargeting~>onFacebookCountriesChange`);
    }

    requestAnimationFrame(async () => {
      const countryTargeting = getFacebookTargetingInput(this, 'countries');
      const cityTargeting = getFacebookTargetingInput(this, 'cities');

      let options = [];

      if (isDebug) {
        console.log(`cityTargeting.value:`);
        console.dir(cityTargeting.value);
      }

      for (const suggestion of countryTargeting.value) {
        if (isDebug) {
          console.log(`code ${suggestion.code}`);
        }

        const isInCache: boolean =
          !!this._citiesCache[suggestion.code] &&
          this._citiesCache[suggestion.code].length;

        if (!isInCache) {
          this._citiesCache[
            suggestion.code
          ] = await this.getCitiesForCountryCode(suggestion.code);
        }

        options = options.concat(this._citiesCache[suggestion.code]);
      }

      if (isDebug) {
        console.log(`new city options:`);
        console.dir(options);
      }

      cityTargeting.options = options;
    });

    if (isDebug) {
      console.groupEnd();
    }
  }

  onSelectedBoundariesChange(boundaries: any[]) {
    this.selectedBoundaries = boundaries;
    if (this.hasNextdoor) {
      this.post.targeting[
        AccountTypeName.Nextdoor
      ].group_ids = this.selectedBoundaries.map(
        (boundary) => boundary.group_id
      );
    }
    if (this.hasNextdoorUS) {
      this.post.targeting[
        AccountTypeName.NextdoorUS
      ].group_ids = this.selectedBoundaries.map(
        (boundary) => boundary.group_id
      );
    }
  }

  removeBoundary(id) {
    this.selectedBoundaries = this.selectedBoundaries.filter(
      (boundary) => boundary.group_id !== id
    );
    if (this.hasNextdoor) {
      this.post.targeting[
        AccountTypeName.Nextdoor
      ].group_ids = this.selectedBoundaries.map(
        (boundary) => boundary.group_id
      );
    }
    if (this.hasNextdoorUS) {
      this.post.targeting[
        AccountTypeName.NextdoorUS
      ].group_ids = this.selectedBoundaries.map(
        (boundary) => boundary.group_id
      );
    }
  }
}
