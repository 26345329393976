import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import moment from 'moment';
import { mapToIterable } from '../../../../../../common/utils';
import {
  DateRange,
  commonDateRanges,
  DateRanges
} from '../../../../../../common/constants/common-date-ranges';
import {
  Campaign,
  CampaignsService
} from '../../../../../../common/services/api/campaigns';
import { endOfDay, startOfDay } from 'date-fns';
import { decodeStateParams } from '@orlo/common/utils';
import { OutboxTagsService } from '../../../../../../common/services/api';

export interface IncludeDeletedOption {
  key: boolean;
  label: string;
}

export const includeDeletedOptions: any = {
  include: {
    key: true,
    label: 'Include deleted posts'
  },
  exclude: {
    key: false,
    label: 'Exclude deleted posts'
  }
};

export const includeDeletedOptionsIterable: IncludeDeletedOption[] =
  mapToIterable(includeDeletedOptions);

@Component({
  selector: 'ssi-campaign-build',
  templateUrl: './campaign-build.component.html',
  styles: [],
  styleUrls: ['./campaign-build.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignBuildComponent implements OnInit {
  dateRanges: DateRanges;
  dateRangesIterable: DateRange[];
  selectedDateRange: DateRange = commonDateRanges.month;

  startDate: Date = this.selectedDateRange.start;
  endDate: Date = this.selectedDateRange.end;

  includeDeletedOptionsIterable: IncludeDeletedOption[] =
    includeDeletedOptionsIterable;
  selectedIncludeDeletedOption: IncludeDeletedOption =
    includeDeletedOptions.exclude;

  campaign: Partial<Campaign> = {};

  today: Date = moment().endOf('day').toDate();

  postTags = [];
  selectedTagsToInclude = [];
  selectedTagsToExclude = [];

  decodedStateParams: { [key: string]: any };

  constructor(
    private state: StateService,
    private campaignsService: CampaignsService,
    protected outboxTagsService: OutboxTagsService
  ) {}

  async ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    if (this.decodedStateParams) {
      this.campaign = await this.campaignsService.getById(
        this.decodedStateParams.campaignId
      );
    }
    const campaignReference = this.campaign;

    const allTags = await this.outboxTagsService.getPostTags();
    this.postTags = allTags.map((t) => ({ name: t.name }));

    this.dateRanges = {
      ...commonDateRanges,
      get allTime() {
        return {
          id: 'allTime',
          label: 'All time',
          start: startOfDay(new Date(campaignReference.started_at)),
          end: endOfDay(new Date())
        };
      }
    };

    this.dateRangesIterable = mapToIterable(this.dateRanges);

    if (this.decodedStateParams.startDate && this.decodedStateParams.endDate) {
      // edit mode
      if (this.decodedStateParams.dateRange) {
        this.selectPresetDateRange(
          this.dateRanges[this.decodedStateParams.dateRange]
        );
      } else {
        this.onStartDatePicked(new Date(this.decodedStateParams.startDate));
        this.onEndDatePicked(new Date(this.decodedStateParams.endDate));
      }
    }

    if (this.decodedStateParams.tagIdsToInclude) {
      const tagIdsToInclude = Array.isArray(
        this.decodedStateParams.tagIdsToInclude
      )
        ? this.decodedStateParams.tagIdsToInclude.map((t) => ({
            id: t,
            name: t
          }))
        : [
            {
              id: this.decodedStateParams.tagIdsToInclude,
              name: this.decodedStateParams.tagIdsToInclude
            }
          ];
      this.onSelectedTagsToIncludeChange(tagIdsToInclude);
    }

    if (this.decodedStateParams.tagIdsToExclude) {
      const tagIdsToExclude = Array.isArray(
        this.decodedStateParams.tagIdsToExclude
      )
        ? this.decodedStateParams.tagIdsToExclude.map((t) => ({
            id: t,
            name: t
          }))
        : [
            {
              id: this.decodedStateParams.tagIdsToExclude,
              name: this.decodedStateParams.tagIdsToExclude
            }
          ];
      this.onSelectedTagsToExcludeChange(tagIdsToExclude);
    }

    if (this.decodedStateParams.includeDeleted) {
      this.selectedIncludeDeletedOption =
        this.decodedStateParams.includeDeleted === 'true'
          ? includeDeletedOptions.include
          : includeDeletedOptions.exclude;
    }
  }

  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.state.go('auth.analytics.campaign.report', {
      campaignId: this.decodedStateParams.campaignId,
      startDate: this.startDate.toISOString(),
      endDate: this.endDate.toISOString(),
      dateRange: this.selectedDateRange && this.selectedDateRange.id,
      tagIdsToInclude: this.selectedTagsToInclude.map((t) => t.name),
      tagIdsToExclude: this.selectedTagsToExclude.map((t) => t.name),
      includeDeleted: this.selectedIncludeDeletedOption.key
    });
  }

  selectPresetDateRange(range: DateRange): void {
    this.selectedDateRange = this.dateRanges[range.id];

    this.startDate = this.selectedDateRange.start;
    this.endDate = this.selectedDateRange.end;
  }

  onStartDatePicked(startDate: Date): void {
    this.startDate = startDate;
    this.selectedDateRange = undefined;
  }

  onEndDatePicked(endDate: Date): void {
    this.endDate = endDate;
    this.selectedDateRange = undefined;
  }

  onSelectedTagsToIncludeChange(tags) {
    this.selectedTagsToInclude = tags;
  }

  onSelectedTagsToExcludeChange(tags) {
    this.selectedTagsToExclude = tags;
  }

  removeIncludedTag(tag) {
    this.selectedTagsToInclude = this.selectedTagsToInclude.filter(
      (iTag) => iTag.id !== tag.id
    );
  }

  removeExcludedTag(tag) {
    this.selectedTagsToExclude = this.selectedTagsToExclude.filter(
      (t) => t.id !== tag.id
    );
  }

  onFlatpickrOpen() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 200);
  }
}
