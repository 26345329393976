import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

import {
  ChatBotService,
  ChatBot
} from '../../../../../common/services/chat-bot/chat-bot.service';
import { workflowAccounts } from '../../../../../modules/common-resolves';
import { Account } from '@ui-resources/modules/exports';
import { API } from '@ui-resources-angular';

export async function chatBotsFn(chatBot: ChatBotService): Promise<ChatBot[]> {
  return await chatBot.getChatBotList();
}

@Component({
  selector: 'ssi-enable-social-chatbots',
  templateUrl: './enable-social-chatbots.component.html',
  styles: [],
  styleUrls: ['./enable-social-chatbots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnableSocialChatbotsComponent implements OnInit {
  static resolve = [
    {
      token: 'chatBots',
      resolveFn: chatBotsFn,
      deps: [ChatBotService]
    },
    workflowAccounts
  ];

  @Input() chatBots: ChatBot[];
  @Input() workflowAccounts: Account[];

  enabledAccounts: { bot_id: string; account_id: string }[];
  loading = false;

  constructor(public chatBot: ChatBotService, private api: API) {}

  async ngOnInit() {
    this.enabledAccounts = await this.getEnabledAccounts();
    this.workflowAccounts = this.workflowAccounts.filter((account) =>
      ['2', '3'].includes(account.account_type_id)
    );
  }

  checkIfBotAssigned(chatbotId, accountId) {
    const enabledAccount = this.enabledAccounts.find(
      (account) => account.account_id === accountId
    );
    return enabledAccount && enabledAccount.bot_id === chatbotId ? true : false;
  }

  getCurrentAssignedBot(accountId) {
    const { bot_id } = this.enabledAccounts.find(
      (account) => account.account_id === accountId
    );
    return bot_id ? bot_id : null;
  }

  async enableChatbotOnAccount(event, accountId) {
    this.loading = true;
    if (event.target.value !== 'disable') {
      await this.api
        .post('chatbot/socialChatBotAssign', {
          bot_id: event.target.value,
          account_id: accountId
        })
        .catch(() => {
          this.loading = false;
          event.target.value = this.getCurrentAssignedBot(accountId);
        });
    } else {
      await this.api
        .delete(`chatbot/socialChatBotAssign?account_id=${accountId}`)
        .catch(() => {
          this.loading = false;
          event.target.value = this.getCurrentAssignedBot(accountId);
        });
    }

    this.loading = false;
    this.enabledAccounts = await this.getEnabledAccounts();
  }

  private getEnabledAccounts() {
    return this.api.get('chatbot/socialChatBotAssign').then(({ data }) => {
      return data.chatbots;
    });
  }
}
