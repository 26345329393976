import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FilestackService } from '../../../../../common/services/filestack/filestack.service';
import { authUser, userSettings } from '../../../../common-resolves';
import { API, User } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ssi-my-details',
  templateUrl: './my-details.component.html',
  styleUrls: ['./my-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyDetailsComponent implements OnInit {
  static resolve = [userSettings, authUser];

  @Input() userSettings;
  @Input() authUser: User;

  myDetailsForm: FormGroup;
  model = {
    firstname: '',
    lastname: '',
    email: '',
    mobile: ''
  };
  avatar: string;
  uploadedFile;

  constructor(
    private filestack: FilestackService,
    private api: API,
    private state: StateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.myDetailsForm = new FormGroup({
      first_name: new FormControl(
        this.userSettings.first_name,
        Validators.required
      ),
      last_name: new FormControl(
        this.userSettings.last_name,
        Validators.required
      ),
      email_address: new FormControl(
        this.userSettings.email_address,
        Validators.required
      ),
      mobile: new FormControl(
        this.userSettings.mobile,
        Validators.pattern(/^\+?[0-9]\d{4,14}$/)
      )
    });
    this.avatar = this.userSettings.avatar_url;
    const phoneNumber = this.myDetailsForm.get('mobile');
    phoneNumber.valueChanges.subscribe((number: string) => {
      // Strip out the empty spaces
      if (number.includes(' ')) {
        this.myDetailsForm.controls.mobile.setValue(number.replace(' ', ''));
      }
      // Add country code for the UK to get full format if it's the local UK number
      if (number.startsWith('07')) {
        phoneNumber.setValue(number.replace('07', '+447'));
      }
    });
  }

  async pickAvatar() {
    try {
      const client = await this.filestack.getClient();
      const options = {
        maxFiles: 1,
        onFileUploadFinished: (file) => (this.uploadedFile = file),
        onClose: () => (this.avatar = this.uploadedFile.url)
      };

      client.picker(options).open();
    } catch (e) {
      console.log(e);
    }
  }

  deleteAvatar() {
    this.avatar = 'https://images.socialsignin.net/avatar/avatar-incoming.png';
  }

  sanitizeURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onSubmit() {
    const settings = Object.assign({}, this.myDetailsForm.value);
    settings.avatar_url = this.avatar;
    settings.mobile =
      !!settings.mobile &&
      typeof settings.mobile === 'string' &&
      settings.mobile.length > 0
        ? settings.mobile.replace(/^0+/, '+44')
        : '';

    this.api.post('settings/user', settings).then((result) => {
      if (result.status === 200) {
        this.state.go('auth.settings.index');
      }
    });
  }

  return() {
    this.state.go('^');
  }
}
