import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsyncTrackerFactory } from 'angular-async-tracker';
import * as moment from 'moment';
import { SpreadsheetService } from '../spreadsheet/spreadsheet.service';
import {
  AbstractQuery,
  AbstractQueryHelperService,
  QueryFilterOption
} from '../abstract-query-helper/abstract-query-helper.service';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import {
  AccountModel,
  API,
  Campaign,
  CampaignModel,
  getOutboxMessageClass,
  Outbox,
  OutboxMessageClass,
  OutboxModel,
  User,
  Account
} from '@ui-resources-angular';
import { TeamsService, Team, ColleaguesService, Colleague, Tag } from '../api';
import { WorkflowManagerService } from '../../../common/services/workflow-manager/workflow-manager.service';
import { OUTBOX_MESSAGE_TYPES, TIMEZONE_OFFSET } from '../../constants';
import { FilestackFile } from '../filestack/filestack.service';
import { PopupService } from '../popup/popup.service';

const isNotSet = (value) => !value;

export interface CalendarMonthViewTotals {
  [date: string]: { [socialNetwork: string]: number };
}

export class OutboxQuery extends AbstractQuery {
  loading = {
    initial: this.injector.get(AsyncTrackerFactory).create(),
    more: this.injector.get(AsyncTrackerFactory).create(),
    csv: this.injector.get(AsyncTrackerFactory).create()
  };

  isValidationSearch: boolean;
  outboxPosts: Outbox[] = [];
  interactionTotals;

  presets = [
    {
      key: 'unvalidated',
      icon: 'fa fa-check-circle-o',
      label: 'UNVALIDATED_POSTS',
      query: {
        validated: '0',
        types: OUTBOX_MESSAGE_TYPES.map((type) => type.value)
      }
    },
    {
      key: 'all',
      icon: 'ssi ssi-all-messages',
      label: 'ALL_MESSAGES',
      query: {}
    }
  ];

  constructor(
    private injector: Injector,
    private workflowManager: WorkflowManagerService,
    public allAccounts: Account[],
    private popup: PopupService,
    authUser: User,
    campaigns: Campaign[],
    postTags: Tag[],
    private onSearch: () => void
  ) {
    super();

    const abstractQueryHelper = injector.get(AbstractQueryHelperService);
    const colleaguesService = injector.get(ColleaguesService);
    const translate = injector.get(TranslateService);
    const campaignModel = injector.get(CampaignModel);
    const teamsService = injector.get(TeamsService);

    const campaignOptions: Array<
      QueryFilterOption<string | number>
    > = campaigns
      .sort((campaignA, campaignB) =>
        campaignA.name.localeCompare(campaignB.name)
      )
      .map((campaign) => ({ label: campaign.name, value: campaign.id }));
    campaignOptions.unshift({
      label: '-- No Campaign --',
      value: 'null'
    });

    const tagOptions: Array<
      QueryFilterOption<string | number>
    > = postTags.map((tag) => ({ label: tag.name, value: tag.name }));

    this.filters = [
      abstractQueryHelper.getAccountsFilter({
        key: 'accounts',
        allAccounts
      }),
      {
        key: 'created_by',
        type: 'user',
        selectTeams: true,
        isDefault: isNotSet,
        serialize(userOrTeam) {
          if (userOrTeam) {
            if (userOrTeam instanceof Colleague) {
              if (+userOrTeam.id === +authUser.id) {
                return 'me';
              } else {
                return `user:${userOrTeam.id}`;
              }
            } else if (userOrTeam instanceof Team) {
              return `group:${userOrTeam.id}`;
            }
          } else {
            return userOrTeam;
          }
        },
        deserialize(idString) {
          if (idString === 'me') {
            idString = `user:${authUser.id}`;
          }

          if (idString && typeof idString === 'string') {
            if (idString.startsWith('user:')) {
              const colleagueId = idString.replace('user:', '');
              return colleaguesService.store.find(colleagueId);
            } else if (idString.startsWith('group:')) {
              const teamId = idString.replace('group:', '');
              return teamsService.store.find(teamId);
            }
          } else {
            return idString;
          }
        },
        translateLabel: 'AUTHOR',
        icon: 'fa-user',
        selectedText(userOrTeam) {
          if (userOrTeam) {
            const name =
              userOrTeam instanceof Colleague
                ? userOrTeam.fullName
                : userOrTeam.name;
            return translate.instant('AUTHORED_BY__NAME_', {
              name
            });
          }
        }
      },
      abstractQueryHelper.getFromDateTimeFilter({
        key: 'start_date'
      }),
      abstractQueryHelper.getToDateTimeFilter({
        key: 'end_date'
      }),
      {
        key: 'campaign_id',
        type: 'selectSingle',
        isDefault: isNotSet,
        translateLabel: 'SOCIAL_CAMPAIGN',
        icon: 'fa-bookmark',
        selectedText(value) {
          if (value === 'null') {
            return 'No Campaign';
          }
          if (value && campaignModel.get(value)) {
            return translate.instant('PART_OF_THE__CAMPAIGNNAME__CAMPAIGN', {
              campaignName: campaignModel.get(value).name
            });
          }
        },
        options: campaignOptions
      },
      {
        key: 'types',
        type: 'selectMultiple',
        get defaultValue() {
          return OUTBOX_MESSAGE_TYPES.map(
            (messageType) => messageType.value
          ).filter(
            (type) => getOutboxMessageClass(type) === OutboxMessageClass.Post
          );
        },
        isDefault(value) {
          return (
            value.length === this.defaultValue.length &&
            value.every(
              (type) => getOutboxMessageClass(type) === OutboxMessageClass.Post
            )
          );
        },
        translateLabel: 'MESSAGE_TYPES',
        icon: 'fa-file-image-o',
        selectedText(value) {
          return translate.instant('_COUNT__MESSAGE_TYPES', {
            count: value.length
          });
        },
        options: OUTBOX_MESSAGE_TYPES
      },
      {
        key: 'deleted',
        type: 'selectSingle',
        defaultValue: 'exclude',
        isDefault(value) {
          return value === this.defaultValue;
        },
        translateLabel: 'DELETED_MESSAGES',
        icon: 'fa-trash-o',
        selectedText(value) {
          switch (value) {
            case 'include':
              return translate.instant('INCLUDE_DELETED_POSTS');

            case 'exclude':
              return translate.instant('EXCLUDE_DELETED_POSTS');

            case 'exclusive':
              return translate.instant('ONLY_DELETED_POSTS');

            default:
              return '';
          }
        },
        options: [
          {
            label: 'INCLUDE_DELETED',
            value: 'include'
          },
          {
            label: 'EXCLUDE_DELETED',
            value: 'exclude'
          },
          {
            label: 'ONLY_DELETED',
            value: 'exclusive'
          }
        ]
      },
      {
        key: 'validated',
        type: 'selectSingle',
        isDefault: isNotSet,
        translateLabel: 'APPROVALS',
        icon: 'fa-check-circle-o',
        selectedText(value) {
          switch (value) {
            case '0':
              return translate.instant('POSTS_WAITING_FOR_APPROVAL');

            case '1':
              return translate.instant('APPROVED_POSTS_ONLY');

            case '2':
              return translate.instant('DISAPPROVED_ONLY');

            default:
              return '';
          }
        },
        options: [
          {
            label: 'ALL_POSTS'
          },
          {
            label: 'WAITING_FOR_APPROVAL',
            value: '0'
          },
          {
            label: 'APPROVED_ONLY',
            value: '1'
          },
          {
            label: 'DISAPPROVED_ONLY',
            value: '2'
          }
        ]
      },
      abstractQueryHelper.getTextFilter({
        key: 'text'
      }),
      {
        key: 'scheduled',
        type: 'selectSingle',
        defaultValue: 'include',
        isDefault(value) {
          return value === this.defaultValue;
        },
        translateLabel: 'SCHEDULED_MESSAGES',
        icon: 'fa-clock-o',
        selectedText(value) {
          switch (value) {
            case 'include':
              return translate.instant('INCLUDE_SCHEDULED_POSTS');

            case 'exclude':
              return translate.instant('EXCLUDE_SCHEDULED_POSTS');

            case 'exclusive':
              return translate.instant('ONLY_SCHEDULED_POSTS');

            default:
              return '';
          }
        },
        options: [
          {
            label: 'INCLUDE_SCHEDULED_POSTS',
            value: 'include'
          },
          {
            label: 'EXCLUDE_SCHEDULED_POSTS',
            value: 'exclude'
          },
          {
            label: 'ONLY_SCHEDULED_POSTS',
            value: 'exclusive'
          }
        ]
      },
      {
        key: 'sort_dir',
        type: 'selectSingle',
        defaultValue: 'desc',
        isDefault(value) {
          return value === this.defaultValue;
        },
        translateLabel: 'SORT_DIRECTION',
        icon: 'fa-sort',
        selectedText(value) {
          switch (value) {
            case 'asc':
              return translate.instant('SORT_ASCENDING');

            case 'desc':
              return translate.instant('SORT_DESCENDING');

            default:
              return '';
          }
        },
        options: [
          {
            label: 'SORT_DESCENDING',
            value: 'desc'
          },
          {
            label: 'SORT_ASCENDING',
            value: 'asc'
          }
        ]
      },
      {
        key: 'status',
        type: 'selectSingle',
        defaultValue: null,
        isDefault(value) {
          return value === this.defaultValue;
        },
        translateLabel: 'FAILED_POSTS',
        icon: 'fa-warning',
        selectedText(value) {
          switch (value) {
            case 'publish_failed':
              return translate.instant('ONLY_FAILED_POSTS');

            case 'published':
              return translate.instant('EXCLUDE_FAILED_POSTS');

            default:
              return '';
          }
        },
        options: [
          {
            label: 'ONLY_FAILED_POSTS',
            value: 'publish_failed'
          },
          {
            label: 'EXCLUDE_FAILED_POSTS',
            value: 'published'
          }
        ]
      },
      {
        key: 'post_tags',
        type: 'selectMultiple',
        isDefault: isNotSet,
        translateLabel: 'POST_TAGS',
        icon: 'ssi ssi-tag-test',
        selectedText(value) {
          const total = value ? value.length : '0';
          return `${total} post tags`;
        },
        options: tagOptions
      }
    ];
  }

  getStringifiedQuery() {
    this.apiQuery.workflow = this.workflowManager.getCurrentId();
    return JSON.stringify(this.apiQuery);
  }

  getResults(
    offset = 0,
    limit = 25,
    withNoteCount = true,
    withStats = false,
    queryOverrides = {}
  ) {
    const params = Object.assign(
      {},
      this.apiQuery,
      this.params,
      queryOverrides,
      {
        offset,
        limit,
        with: ['boosted_post_counts'],
        v: 2,
        tz_offset: TIMEZONE_OFFSET
      }
    );

    if (withNoteCount) {
      params.with.push('note_counts');
    }
    if (withStats) {
      params.with.push('interaction_totals');
    }
    if (this.isValidationSearch) {
      params.with.push('interaction_totals', 'interaction_totals_skip_clicks');
    }

    return this.injector
      .get(API)
      .post('outbox/search', params)
      .then(({ data: result }) => {
        result.posts = this.injector.get(OutboxModel).inject(result.posts);
        return result;
      });
  }

  search(
    offset: number = 0,
    limit: number = 25,
    withStats = true,
    queryOverrides = {}
  ) {
    const isFirstPage = offset === 0;
    if (isFirstPage) {
      this.updateQueryState();
    }

    const promise = this.getResults(
      offset,
      limit,
      true,
      withStats && offset === 0,
      queryOverrides
    ).then((result) => {
      if (isFirstPage) {
        this.outboxPosts = result.posts;
        this.interactionTotals = result.interaction_totals;
        this.interactionTotals.avg = {};
        Object.entries(result.interaction_totals).forEach(
          ([interactionType, total]: [string, number]) => {
            this.interactionTotals.avg[interactionType] =
              total / result.interaction_totals.total;
          }
        );
        this.interactionTotals.avg.score = this.injector
          .get(OutboxModel)
          .getScore(
            this.interactionTotals.avg.comment_count,
            this.interactionTotals.avg.share_count,
            this.interactionTotals.avg.like_count,
            this.interactionTotals.avg.clicks
          );
      } else {
        this.outboxPosts = this.outboxPosts = [
          ...this.outboxPosts,
          ...result.posts
        ];
      }
      this.onSearch();
    });

    if (offset === 0) {
      this.loading.initial.add(promise);
    } else {
      this.loading.more.add(promise);
    }

    return promise;
  }

  getCalendarMonthTotals(calendarDate: Date): Promise<CalendarMonthViewTotals> {
    const queryClone = Object.assign({}, this.apiQuery, {
      start_date: moment(calendarDate).startOf('month').format(),
      end_date: moment(calendarDate).endOf('month').format(),
      tz_offset: TIMEZONE_OFFSET
    });

    return this.injector
      .get(API)
      .post<{ data: CalendarMonthViewTotals }>('outbox/calendar', queryClone)
      .then(({ data }) => data);
  }

  async exportToFile(queryOverrides = {}) {
    const params = Object.assign(
      {},
      this.apiQuery,
      this.params,
      queryOverrides,
      {
        with: ['boosted_post_counts', 'note_counts', 'interaction_totals'],
        v: 2,
        tz_offset: TIMEZONE_OFFSET
      }
    );

    const promise = this.injector.get(API).post('outbox/exportOutbox', params);
    this.loading.csv.add(promise);
    const result = await promise;

    const ok = await this.popup.alert({
      title: 'Success!',
      message: 'Outbox export will be emailed to you shortly.'
    });
  }
}

@Injectable()
export class OutboxQueryFactoryService {
  constructor(
    private injector: Injector,
    private workflowManager: WorkflowManagerService
  ) {}

  create({
    onSearch = () => {},
    allAccounts,
    savedQuery,
    campaigns = [],
    postTags = [],
    authUser
  }: {
    onSearch?: () => void;
    allAccounts: Account[];
    savedQuery?: any;
    campaigns?: Campaign[];
    postTags?: Tag[];
    authUser: User;
  }): OutboxQuery {
    try {
      const popup = this.injector.get(PopupService);
      const query = new OutboxQuery(
        this.injector,
        this.workflowManager,
        allAccounts,
        popup,
        authUser,
        campaigns,
        postTags,
        onSearch
      );
      query.reset();

      if (
        savedQuery &&
        (typeof savedQuery.workflow === 'undefined' || // tslint:disable-next-line
          savedQuery.workflow == this.workflowManager.getCurrentId())
      ) {
        Object.assign(query.apiQuery, savedQuery);
      }

      if (!(!!allAccounts && Array.isArray(allAccounts))) {
        throw new Error(
          `Value for 'outbox query factory all accounts' not in expected format.`
        );
      }

      query.apiQuery.accounts = query.apiQuery.accounts.filter((accountId) => {
        return allAccounts.find((account) => +account.id === +accountId);
      });

      query.updateQueryState();

      return query;
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}
