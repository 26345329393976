import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  DoCheck,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import strings from '@orlo/library/constants/strings';
import { LiveChatService } from '../../../services/live-chat/live-chat.service';
import { PredictedResponseService } from '../../../services/predicted-response/predicted-response.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { format } from 'date-fns';

// ----------------

const isDebug: boolean = false;

// ----------------

@Component({
  selector: 'ssi-live-chat-conversation-response',
  templateUrl: './live-chat-conversation-response.component.html',
  styles: [],
  styleUrls: ['./live-chat-conversation-response.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LiveChatConversationResponseComponent
  implements DoCheck, OnChanges, AfterViewInit {
  @Input() addition: string = '';
  @Input() isPredictive: boolean = false;
  @Input() isResponseFocused: boolean;
  @Input() translationMode: boolean = false;
  @Input() translationLanguage: string;

  @Output()
  isResponseFocusedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('predictionElement') predictionElement: ElementRef;

  private _contentText: string = '';
  private _prediction: string = '';
  private _previousText: string = '';
  private _textarea: HTMLTextAreaElement;
  private _wasConversationResolved: boolean;

  public messageText = new FormControl({
    disabled: this.isInterfaceDisabled,
    value: ''
  });

  constructor(
    private elementReference: ElementRef,
    private liveChatService: LiveChatService,
    private liveChatPredictedResponseService: PredictedResponseService,
    private localStorageService: LocalStorageService,
    private translationService: TranslationService
  ) {}

  public get elementForPrediction(): HTMLSpanElement {
    if (!this.predictionElement || !this.predictionElement.nativeElement) {
      return;
    }

    return this.predictionElement.nativeElement;
  }

  public get isConversationResolved(): boolean {
    return this.liveChatService.isActiveConversationResolved;
  }

  public get isInterfaceDisabled(): boolean {
    return this.liveChatService.isInterfaceDisabled;
  }

  public get isReplying(): boolean {
    return this.liveChatService.isActiveConversationReplying;
  }

  public get isResolving(): boolean {
    return this.liveChatService.isActiveConversationResolving;
  }

  public get messageTextValue(): string {
    return this.messageText.value;
  }

  public set messageTextValue(value: string) {
    this.setResponse(value);
  }

  public get prediction(): string {
    return this._prediction;
  }

  public async acceptPrediction(event: KeyboardEvent): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in: liveChatConversationResponse~>acceptPrediction`);
      }

      if (!this.elementForPrediction) {
        return true;
      }

      const prediction = String(this.elementForPrediction.innerText);
      const shouldContinue =
        !prediction.trim().length ||
        prediction.trim() === String(this.messageTextValue.trim());

      if (shouldContinue) {
        if (isDebug) {
          console.log(`returning - prediction empty or otherwise invalid.`);
        }

        return false;
      }

      event.preventDefault();

      const shouldAddSpace =
        PredictedResponseService.CharactersRequiringSpaceSuffix.indexOf(
          prediction.substr(-1)
        ) === -1;
      const responseValue = prediction + (shouldAddSpace ? ' ' : '');

      if (isDebug) {
        console.log(`accepted prediction: ${prediction}`);
      }

      const quantityOfWordsInPrediction: number = prediction
        .slice(this.messageTextValue.length)
        .trim()
        .split(' ').length;

      this.messageText.setValue(responseValue);

      const storageKey = format(new Date(), 'DD-MM-YYYY');
      const currentStoredValue: number =
        Number(this.localStorageService.get(storageKey)) || 0;

      this.localStorageService.set(
        storageKey,
        currentStoredValue + quantityOfWordsInPrediction
      );

      await this.refreshPredictions(false);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      if (isDebug) {
        console.log(`out: liveChatConversationResponse~>acceptPrediction`);
      }
    }
  }

  public addNote() {}

  ngDoCheck() {
    const isConversationResolved =
      this.liveChatService.isActiveConversationResolving ||
      this.liveChatService.isActiveConversationResolved;

    // removing as causing errors, possibly redundant
    // if (isConversationResolved && !this._wasConversationResolved) {
    // this.setResponse('');
    // }

    this._wasConversationResolved = isConversationResolved;
  }

  ngOnChanges(changes): boolean {
    try {
      if (isDebug) {
        console.log(`in: liveChatConversationResponse~>ngOnChanges`);
      }

      const hasAddition =
        !!changes && !!changes.addition && !!changes.addition.currentValue;

      if (hasAddition) {
        this.messageTextValue += this.addition;
        this.addition = undefined;
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  ngAfterViewInit() {
    try {
      this._textarea = this.elementReference.nativeElement.querySelectorAll(
        'textarea'
      )[0];

      this.setResponse(
        this.liveChatService.getResponseCacheForActiveConversation()
      );

      this.updateTypingIndicators();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public async onKeyDown(event: KeyboardEvent): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in: liveChatConversationResponse->onKeyDown`);
        console.dir(event);
      }

      switch (event.key.toLowerCase()) {
        case 'arrowright':
          {
            if (isDebug) {
              console.log(`pressed right arrow.`);
            }

            const cursor = this._textarea.selectionStart;
            const messageLength = this.messageTextValue.length;

            if (cursor >= messageLength) {
              return this.acceptPrediction(event);
            }
          }
          break;

        case 'tab':
          {
            if (isDebug) {
              console.log(`pressed tab.`);
            }

            return this.acceptPrediction(event);
          }
          break;

        case strings.enter: {
          if (!event.metaKey && !event.ctrlKey) {
            return;
          }

          this.reply();

          // prevent newline being added to output.
          return false;
        }
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      if (isDebug) {
        console.log(`out: liveChatConversationResponse->onKeyDown`);
      }
    }
  }

  public async onKeyUp(event: KeyboardEvent): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatConversationResponse->onKeyUp`);
      }

      this._previousText = this._contentText;
      this._contentText = this._textarea.value;

      this.refreshPredictions(true);
      this.updateTypingIndicators();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public onMessageBlur(event: Event): void {
    if (isDebug) {
      console.log(`in liveChatConversationResponse->onMessageBlur`);
    }

    this.isResponseFocusedChange.emit(false);
  }

  public onMessageFocus(event: Event): void {
    if (isDebug) {
      console.log(`in liveChatConversationResponse->onMessageFocus`);
    }

    this.isResponseFocusedChange.emit(true);
  }

  public async onValueChange(): Promise<boolean> {
    try {
      if (isDebug) {
        console.log(`in liveChatConversationResponse~>onValueChange`);
      }

      if (typeof this._textarea.value !== undefined) {
        this._previousText = this.messageText.value;
        this.messageText.setValue(this._textarea.value);
      }

      this.liveChatService.updateActiveAgentResponseCacheForActiveConversation(
        this._textarea.value
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public openFileUploader() {}

  public openKeyboardShortcutList() {}

  public async refreshPredictions(shouldFetchFreshPredictions: boolean) {
    try {
      if (isDebug) {
        console.groupCollapsed(
          `in: liveChatConversationResponse~>refreshPredictions`
        );
      }

      // clear the prediction to reduce any visual lag
      // if e.g. lots of text is pasted in and the lookup is on a slow connection.

      this._prediction = '';

      if (!this.isPredictive) {
        if (isDebug) {
          console.log(
            `returning - this response component is not set to be predictive.`
          );
          console.groupEnd();
        }

        return;
      }

      this._prediction = (
        await this.liveChatPredictedResponseService.getPredictionFor(
          this._textarea.value,
          shouldFetchFreshPredictions
        )
      )
        .trim()
        .replace(
          '{{name}}',
          this.liveChatService.activeConversation.visitor.forename
        );

      if (isDebug) {
        console.log(`received new prediction:`);
        console.dir(this._prediction);
        console.groupEnd();
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      if (isDebug) {
        console.groupEnd();
      }
    }
  }

  public async reply(): Promise<boolean> {
    await this.onValueChange();

    const messageParameters = {
      text: '' + this.messageText.value
    };

    if (this.translationMode) {
      await this.translationService
        .translateText({
          text: this.messageText.value,
          target: this.translationLanguage
        })
        .then((translations) => {
          messageParameters.text = '' + translations[0].translatedText;
        });
    }

    this.messageText.disable();

    try {
      this.setResponse('');

      return this.liveChatService
        .replyToActiveConversation(messageParameters)
        .then((isSuccess: boolean) => {
          this.messageText.enable();

          if (!isSuccess) {
            this.setResponse(this._textarea.value);
          } else {
            if (!!this.elementForPrediction) {
              this.elementForPrediction.innerText = '';
            }

            this._prediction = '';

            this._contentText = '';
            this._previousText = '';
            this.liveChatService.updateEventsOnActiveConversationForActiveAgent(
              {
                lastTypedAt: null
              }
            );
          }

          return isSuccess;
        });
    } catch (error) {
      console.error(error);

      this.messageText.enable();

      return false;
    }
  }

  public replyAndResolve(): Promise<any> {
    this.messageText.disable();

    return this.reply()
      .then(async (isSuccess: boolean) => {
        if (!isSuccess) {
          return;
        }

        return await this.liveChatService.toggleConversationResolution(
          this.liveChatService.activeConversation,
          true
        );
      })
      .finally(() => {
        this.messageText.enable();
      });
  }

  public async replyWithAttachment(event: Event): Promise<boolean> {
    const element = event.target as HTMLInputElement;
    const file = element.files[0];
    try {
      await this.liveChatService.uploadToActiveConversation(file);
      const input = this.fileInput.nativeElement as HTMLInputElement;
      input.value = '';
    } catch (error) {
      console.error(error);

      return false;
    }

    return true;
  }

  public setResponse(text: string = '') {
    try {
      if (isDebug) {
        console.log(`in: liveChatConversationResponse~>setResponse`);
      }

      this._textarea.value = text;
      this.messageText.setValue(this._textarea.value);
      this.liveChatService.updateActiveAgentResponseCacheForActiveConversation(
        this._textarea.value
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  protected async updateTypingIndicators(): Promise<boolean> {
    if (!!this.messageText.value.length && !this._previousText.length) {
      return this.liveChatService.updateEventsOnActiveConversationForActiveAgent(
        {
          lastTypedAt: firebase.firestore.FieldValue.serverTimestamp()
        }
      );
    } else if (!this.messageText.value.length && !!this._previousText.length) {
      return this.liveChatService.updateEventsOnActiveConversationForActiveAgent(
        {
          lastTypedAt: null
        }
      );
    }

    return true;
  }
}
