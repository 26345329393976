import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import {
  FilestackFile,
  fileTypeNotRecognized
} from '../filestack/filestack.service';
import * as XLSX from 'xlsx'

const CSV_MIMETYPE = 'text/csv';

const loadCsvParser = () => import('papaparse');

const loadXlsxParser = (): any => {
  // return import('exports-loader?XLSX!script-loader!xlsx/dist/xlsx.full.min');
  return XLSX; // TODO: test this... 
}

function dateNum(v) {
  const epoch = Date.parse(v);
  return (
    (epoch - new Date(Date.UTC(1899, 11, 30)).getTime()) / (24 * 60 * 60 * 1000)
  );
}

function createSheet(xlsx, data) {
  const ws = {};
  const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
  for (let R = 0; R !== data.length; ++R) {
    for (let C = 0; C !== data[R].length; ++C) {
      if (range.s.r > R) {
        range.s.r = R;
      }
      if (range.s.c > C) {
        range.s.c = C;
      }
      if (range.e.r < R) {
        range.e.r = R;
      }
      if (range.e.c < C) {
        range.e.c = C;
      }
      const cell: any = { v: data[R][C] };
      if (cell.v === null) {
        continue;
      }
      const cellRef = xlsx.utils.encode_cell({ c: C, r: R });

      if (typeof cell.v === 'number') {
        cell.t = 'n';
      } else if (typeof cell.v === 'boolean') {
        cell.t = 'b';
      } else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = xlsx.SSF._table[14];
        cell.v = dateNum(cell.v);
      } else {
        cell.t = 's';
      }

      ws[cellRef] = cell;
    }
  }

  if (range.s.c < 10000000) {
    ws['!ref'] = xlsx.utils.encode_range(range);
  }
  return ws;
}

function Workbook() {
  this.SheetNames = [];
  this.Sheets = {};
}
@Injectable()
export class SpreadsheetService {
  constructor(private api: API) {}

  async xlsxFileToJson(filestackFile: FilestackFile) {
    const [file, xlsx, papaParse] = await Promise.all([
      this.readFile(filestackFile.url),
      loadXlsxParser(),
      loadCsvParser()
    ]);

    let parsed;
    try {
      parsed = xlsx.read(file, { type: 'base64' });
    } catch (e) {
      return Promise.reject({ type: 'InvalidFile', message: e.message });
    }

    const sheets = {};
    Object.keys(parsed.Sheets).forEach((sheetName) => {
      const csv = xlsx.utils.sheet_to_csv(parsed.Sheets[sheetName]); // if using sheet_to_json blank cells get removed
      try {
        sheets[sheetName] = papaParse.parse(csv.trim()).data;
      } catch (e) {
        return Promise.reject({ type: 'InvalidFile', message: e.message });
      }
    });
    return { fileType: 'xlsx', sheets };
  }

  async csvFileToJson(filestackFile: FilestackFile) {
    try {
      const [file, papaParse] = await Promise.all([
        this.readFile(filestackFile.url, false),
        loadCsvParser()
      ]);
      const parsed = papaParse.parse(file.trim());
      if (parsed.errors.length > 0) {
        return Promise.reject({
          type: 'InvalidFile',
          message: parsed.errors[0].message
        });
      }
      return { fileType: 'csv', csv: parsed.data };
    } catch (e) {
      return Promise.reject({ type: 'InvalidFile', message: e.message });
    }
  }

  async fileToJson(filestackFile: FilestackFile) {
    if (
      filestackFile.mimetype === CSV_MIMETYPE ||
      fileTypeNotRecognized(filestackFile)
    ) {
      // Some browsers seemingly don't recognize file type set by Windows for CSV files so it ends up being empty string
      // try as csv first, then as xlsx
      try {
        return await this.csvFileToJson(filestackFile);
      } catch (e) {
        return await this.xlsxFileToJson(filestackFile);
      }
    } else {
      // try xlsx first then csv
      try {
        return await this.xlsxFileToJson(filestackFile);
      } catch (e) {
        return await this.csvFileToJson(filestackFile);
      }
    }
  }

  async jsonToCsv(json, opts) {
    const papaParse = await loadCsvParser();
    return papaParse.unparse(json, opts);
  }

  async jsonToXlsx(sheets) {
    const xlsx = await loadXlsxParser();

    const wb = new Workbook();
    sheets.forEach((sheet, index) => {
      const sheetName = sheet.name || 'Sheet ' + index;
      wb.SheetNames.push(sheetName);
      let rows = [];
      if (sheet.header) {
        rows.push(sheet.header);
      }
      rows = rows.concat(sheet.rows);
      wb.Sheets[sheetName] = createSheet(xlsx, rows);
    });

    return xlsx.write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'base64'
    });
  }

  private async readFile(url, base64 = true): Promise<any> {
    const {
      data: { contents }
    } = await this.api.get<{
      data: { contents: string };
    }>('server/readFile', { params: { url, base64 } });
    return contents;
  }
}
