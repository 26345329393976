import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from '../../../animations';

@Component({
  selector: 'ssi-tooltip-window',
  templateUrl: './tooltip-window.component.html',
  animations: [
    trigger('fadeInOut', [
      transition('void => *', useAnimation(fadeIn)),
      transition('* => void', useAnimation(fadeOut))
    ])
  ],
  styleUrls: ['./tooltip-window.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    // tslint:disable-line
    '[@fadeInOut]': 'true',
    '[class]': 'placement + " " + windowClass'
  }
})
export class TooltipWindowComponent {
  @Input() contents: string;

  @Input() placement: string;

  @Input() title: string;

  @Input() iconClass: string;

  @Input() trailingIconClass: string;

  @Input() windowClass: string;

  @Input() template: TemplateRef<any>;

  @Input() templateContext: any;
}
