import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';

interface DisplayedItem {
  label: string;
  isSelected: boolean;
}

@Component({
  selector: 'ssi-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectComponent implements OnInit {
  @Input() items: string[];
  @Input() activeItems?: string[];
  @Input() showCreateNewOption?: boolean;
  @Output() changeSelectedItems = new EventEmitter<string[]>();
  @Output() addNewItem = new EventEmitter<string>();
  @ViewChild('multiSelectInput') multiSelectInput: ElementRef;
  selectedItems: string[] = [];
  allItems: DisplayedItem[] = [];
  filteredItems: DisplayedItem[] = [];
  showAddNew = false;

  constructor() {}

  ngOnInit() {
    this.selectedItems = this.activeItems || [];
    this.allItems = this.items.map((item) => {
      return { label: item, isSelected: this.selectedItems.includes(item) };
    });
    this.filteredItems = this.allItems;
  }

  onInputKeyDown(event) {
    const value = event.target.value;
    this.showAddNew =
      !!value && !this.allItems.map((item) => item.label).includes(value);
    this.filterList(value);
  }

  filterList(value) {
    const regex = new RegExp(value, 'gi');
    this.filteredItems = this.allItems.filter((item) =>
      item.label.match(regex)
    );
  }

  createItem() {
    if (!this.showCreateNewOption) {
      return;
    }
    const newLabel = this.multiSelectInput.nativeElement.value;
    this.selectedItems.push(newLabel);
    this.addNewItem.emit(newLabel);
    this.allItems.push({ label: newLabel, isSelected: true });
    this.filteredItems = this.allItems;
    this.changeSelectedItems.emit(this.selectedItems);
  }

  addItem(item) {
    if (item.isSelected) {
      return;
    }
    this.selectedItems.push(item.label);
    this.changeSelectedItems.emit(this.selectedItems);
    this.toggleSelected(item.label, true);
    this.multiSelectInput.nativeElement.value = '';
    this.filteredItems = this.allItems;
  }

  removeItem(item) {
    const itemLabel = item.label || item;
    this.selectedItems = this.selectedItems.filter(
      ($item) => $item !== itemLabel
    );
    this.changeSelectedItems.emit(this.selectedItems);
    this.toggleSelected(itemLabel, false);
  }

  toggleSelected(itemLabel, isSelected) {
    const tag = this.allItems.find(($item) => $item.label === itemLabel);
    if (tag) {
      tag.isSelected = isSelected;
    }
    // this.filteredItems = this.allItems;
  }
}
