import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignModalComponent {
  @Input() title: string;
  @Input() info: string;
  @Input() campaigns;
  campaign = [];

  constructor(public activeModal: NgbActiveModal) {}
}
