import { ViewEncapsulation } from '@angular/core';

import {
  Input,
  Component,
  OnInit,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'ssi-profile-inbox-stats',
  templateUrl: './profile-inbox-stats.component.html',
  styleUrls: ['./profile-inbox-stats.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileInboxStatsComponent implements OnInit, OnChanges {
  @Input() profile: any;

  days: number;

  @Output() onViewEngagements = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {}
}
