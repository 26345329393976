import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';
import {
  OutboxPublisher,
  OutboxPublisherMention,
  SocialNetworkLinkPreview,
  Account,
  OutboxPublisherLink,
  OutboxPublisherFile
} from '@ui-resources-angular';

@Component({
  selector: 'ssi-publisher-twitter-post-preview',
  templateUrl: './publisher-twitter-post-preview.component.html',
  styleUrls: ['./publisher-twitter-post-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublisherTwitterPostPreviewComponent {
  @Input() post: OutboxPublisher;
  @Input() account: Account;
  @Input() text: string;
  @Input() preview: SocialNetworkLinkPreview;
  @Input() links: OutboxPublisherLink[];
  @Input() mentions: OutboxPublisherMention[];
  @Input() files: OutboxPublisherFile[];
  currentDate = new Date();
}
