import { ViewEncapsulation } from '@angular/core';


import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-account-pill',
  templateUrl: './account-pill.component.html',
  styles: [],
  styleUrls: ['./account-pill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountPillComponent {
  @Input() icon: string;
  @Input() label: string;

  constructor() {}
}
