import { ViewEncapsulation } from '@angular/core';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountTypeId, AccountTypeName} from '../../../../enums';
import {OutboxPublisher, socialNetworkSettings} from '@ui-resources-angular';
import {MultiValueInput, TargetingOptionsService} from '../targeting-options.service';
import {Suggestion} from "@orlo/library/interfaces/suggestion";

@Component({
  selector: 'ssi-linkedin-options',
  templateUrl: './linkedin-options.component.html',
  styleUrls: ['./linkedin-options.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkedInOptionsComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() previewToggled: boolean;

  @Output() select = new EventEmitter();

  constructor(public targetingService: TargetingOptionsService) {}

  getLinkedInTargetingInput(key: string): MultiValueInput {
    return this.targetingService.options.LinkedIn.multiValueInputs.find(
      (input) => input.key === key
    );
  }

  ngOnInit() {
    console.log('Should initialise LinkedIn targeting...');
    this.targetingService.initialiseTargeting(
      AccountTypeName.LinkedIn,
      this.post.targeting.LinkedIn
    );
    this.buildFromPost();
  }

  onChange(value) {
    this.select.emit(value);
  }

  async buildFromPost() {
    this.targetingService.options.LinkedIn.multiValueInputs.forEach((input) => {
      input.value = (this.post.targeting.LinkedIn[input.key] || []).map(
        (value) => {
          if (input.key === 'geos') {
            if (!input.value) {
              input.options = [];
              return;
            }
          }

          if (input.key === 'geoLocations' && value) {
            if (value.code) {
              return value;
            }
            return this.reverseLookupGeoLocationLabel(value)
              .then(function (result) {
                if (result !== null) {
                  input.value.push({
                    code: result[0].code,
                    label: result[0].label
                  });
                }
              });
          }

          if (!(!!input && !!input.options && Array.isArray(input.options))) {
            return;
          }

          return input.options.find((option) =>
            typeof option.code === 'string'
              ? option.code.toLocaleLowerCase() === value.toLocaleLowerCase()
              : option.code === value
          );
        }
      );
    });
  }

  async getGeoLocationsForQuery(text: string): Promise<Suggestion[]> {
    if (!text) {
      return [];
    }

    const li = socialNetworkSettings.LinkedIn;

    if (
      !(
        !!this.post &&
        !!this.post.accounts &&
        Array.isArray(this.post.accounts)
      )
    ) {
      return;
    }

    const account = this.post.accounts.find(
      (iAccount) => iAccount.socialNetwork === li
    );

    return await li.publish.targeting.geoLocations(account, text, null);
  }

  async reverseLookupGeoLocationLabel(urn: string): Promise<Suggestion[]> {
    if (!urn) {
      return [];
    }

    const li = socialNetworkSettings.LinkedIn;

    if (
      !(
        !!this.post &&
        !!this.post.accounts &&
        Array.isArray(this.post.accounts)
      )
    ) {
      return;
    }

    const account = this.post.accounts.find(
      (iAccount) => iAccount.socialNetwork === li
    );

    return await li.publish.targeting.geoLocations(account, null, urn);
  }

  async onLinkedInGeosChange(filter: string) {
    if (filter.length > 0) {
      const foundGeos = await this.getGeoLocationsForQuery(filter);
      const geoTargeting = this.getLinkedInTargetingInput('geoLocations');
      geoTargeting.options = foundGeos;
    }
  }

  async copyToPost() {
    this.targetingService.options.LinkedIn.multiValueInputs.forEach((input) => {
      if (input.key === 'geoLocations') {
        this.post.targeting.LinkedIn[input.key] = input.value;
      } else {
        this.post.targeting.LinkedIn[input.key] = input.value.filter(value => value).map(
          (value) => value.code
        );
      }
    });
  }

  public readonly AccountTypeId = AccountTypeId;
}
