import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { decodeStateParams } from '@orlo/common/utils';
import { AccountModel, Account } from '@ui-resources-angular';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import {
  ReportBuildFormValue,
  visibilityOptions
} from '../../common/report-build-from/report-build-from.component';

import { commonDateRanges } from '../../../../../common/constants/common-date-ranges';
import { compareToOptions } from '../../../../../common/constants/compare-to-options';
import { OutboxTagsService } from '../../../../../common/services/api/outbox-tags';

@Component({
  selector: 'ssi-build',
  templateUrl: './build.component.html',
  styles: [],
  styleUrls: ['./build.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BuildComponent implements OnInit {
  formValue?: Partial<ReportBuildFormValue>;
  validationTags: string[];
  decodedStateParams: { [key: string]: any };

  constructor(
    protected state: StateService,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected outboxTagsService: OutboxTagsService
  ) {}

  async ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    const allTags = await this.outboxTagsService.getValidationTags();
    this.validationTags = allTags.map((t) => t.name);
    if (
      this.decodedStateParams.accountIds &&
      this.decodedStateParams.startDate &&
      this.decodedStateParams.endDate
    ) {
      // edit mode
      const accountIds = Array.isArray(this.decodedStateParams.accountIds)
        ? this.decodedStateParams.accountIds
        : [this.decodedStateParams.accountIds];

      const tagsToInclude = Array.isArray(this.decodedStateParams.includeTags)
        ? this.decodedStateParams.includeTags
        : [this.decodedStateParams.includeTags];

      const tagsToExclude = Array.isArray(this.decodedStateParams.excludeTags)
        ? this.decodedStateParams.excludeTags
        : [this.decodedStateParams.excludeTags];

      this.formValue = {
        accounts: accountIds.map((id) => this.accountModel.get(id)),
        startDate: new Date(this.decodedStateParams.startDate),
        endDate: new Date(this.decodedStateParams.endDate),
        previousStartDate:
          this.decodedStateParams.previousStartDate &&
          new Date(this.decodedStateParams.previousStartDate),
        previousEndDate:
          this.decodedStateParams.previousEndDate &&
          new Date(this.decodedStateParams.previousEndDate),
        dateRange: commonDateRanges[this.decodedStateParams.dateRange],
        tagsToInclude,
        tagsToExclude
      };
    }
  }

  onFormSubmit(output: ReportBuildFormValue) {
    console.log('form output: ', output);

    this.state.go('auth.analytics.disapprovals.report', {
      accountIds: output.accounts.map((a) => a.id),
      startDate: output.startDate.toISOString(),
      endDate: output.endDate.toISOString(),
      previousStartDate:
        output.previousStartDate && output.previousStartDate.toISOString(),
      previousEndDate:
        output.previousEndDate && output.previousEndDate.toISOString(),
      dateRange: output.dateRange && output.dateRange.id,
      includeTags: output.tagsToInclude && output.tagsToInclude,
      excludeTags: output.tagsToExclude && output.tagsToExclude
    });
  }
}
