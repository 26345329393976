import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  OnChanges,
  Renderer2
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { ActivityTags, User, UserModel } from '@ui-resources-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  InsightsService,
  InsightsReport,
  InsightsPost,
  InsightsPostType
} from '../../../insights.service';

import {
  commonDateRanges,
  commonDateRangesIterable,
  DateRange,
  DateRanges,
  emotions,
  Emotions,
  emotionsIterableSortedAZ,
  findSentimentConst,
  sentimentsIterable
} from '@orlo/common/constants';
import {
  Filter,
  Match,
  Query
} from '../../../reports/view/view-report.component';
import { AutomateModalComponent } from '@orlo/common/components/automate-modal/automate-modal.component';
import { CompanyService } from '@orlo/common/services/company/company.service';
import { AssignMessageComponent } from '@orlo/common/components/assign-message/assign-message.component';
import { Colleague, Team } from '@orlo/common/services/api';
import { NotificationService } from '@orlo/common/services/notification/notification.service';
import { Option } from '@orlo/common/components/dropdown-select-2/dropdown-select-2.component';

export enum ReportView {
  Graph = 'graph',
  Comment = 'comment'
}

@Component({
  selector: 'ssi-report-action-bar',
  templateUrl: './report-action-bar.component.html',
  styles: [],
  styleUrls: ['./report-action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportActionBarComponent implements OnInit, OnChanges {
  @Input() report: InsightsReport;
  @Input() reportView: ReportView = ReportView.Graph;
  @Input() showSaveButton = false;
  @Input() showPinButton = false;
  @Input() userOwnsReport = false;
  @Input() activeFilters: Filter[];
  @Input() pdfExportDisabled: boolean;
  @Input() widgetsLoaded: boolean;
  @Input() templateMode: boolean;
  @Input() selectedPosts: InsightsPost[] = [];
  @Input() disableBulkEmotions: boolean = false;
  @Input() disableBulkDelete: boolean = false;

  @Output() onSaveClick = new EventEmitter();
  @Output() onRefreshClick = new EventEmitter();
  @Output() onFiltersClick = new EventEmitter();
  @Output() onDateChange = new EventEmitter<string>();
  @Output() onDateCustomClick = new EventEmitter();
  @Output() onAddWidgetClick = new EventEmitter();
  @Output() onShareClick = new EventEmitter();
  @Output() onEditReportClick = new EventEmitter();
  @Output() onCopyReportClick = new EventEmitter();
  @Output() onSettingsClick = new EventEmitter();
  @Output() onDeleteClick = new EventEmitter();
  @Output() onReportViewChange = new EventEmitter<ReportView>();
  @Output() onPinClick = new EventEmitter<boolean>();
  @Output() onKeywordFilter = new EventEmitter();
  @Output() onExportClick = new EventEmitter();
  @Output() onClearSelectedPosts = new EventEmitter();
  @Output() onBulkSentimentUpdated = new EventEmitter<number>();

  pinned: boolean = true;
  keywordTags: Array<Match | Query> = [];
  tagsCondition: string;
  dateOptions = commonDateRangesIterable.map((date) => date.label);
  dateRanges: DateRanges = commonDateRanges;
  dateRangesIterable: DateRange[] = commonDateRangesIterable;
  date: string = this.dateRanges.month.label;
  warningBanner = false;
  activeFilterTotal: number;
  browserlessMode = false;
  privateReports: InsightsReport[] = [];
  publicReports: InsightsReport[] = [];
  schedulesAutomatedReportsFeature = false;
  ReportView = ReportView;

  tags: Option[] = [];
  selectedTags: Option[] = [];
  selectedEmotions: Option[] = [];
  emotions: Emotions = emotions;
  emotionsSorted = emotionsIterableSortedAZ.map(
    ({ icon, ...keepAttrs }) => keepAttrs
  );
  showSentimentMenu = false;
  actionBarWidth: number;

  // dateOptions: string[] = [
  //   'Today',
  //   'This week',
  //   'Last 7d',
  //   'Last 14 days',
  //   'This month',
  //   'Last month',
  //   'Last 90 days',
  //   'This year'
  // ];

  constructor(
    protected renderer: Renderer2,
    protected state: StateService,
    protected insightsService: InsightsService,
    public modal: NgbModal,
    private company: CompanyService,
    private notificationService: NotificationService,
    protected activityTags: ActivityTags
  ) {}

  ngOnInit(): void {
    this.onPinClick.emit(this.pinned);
    this.onReportViewChange.emit(this.reportView);
    this.activeFilterTotal = this.activeFilters.filter(
      (filter) =>
        filter.all !== 'deleted' &&
        filter.all !== 'permission' &&
        filter.key !== 'keyword'
    ).length;

    this.populateTags();

    this.insightsService.getReports().then((reports) => {
      const sortByNewestUpdated = (a, b) => {
        const dateA = Date.parse(a.updated_at);
        const dateB = Date.parse(b.updated_at);
        return dateA > dateB ? -1 : 1;
      };
      const sortedReports = reports.sort(sortByNewestUpdated);
      this.privateReports = sortedReports
        .filter((report) => !report.is_shared)
        .slice(0, 6);
      this.publicReports = sortedReports
        .filter((report) => report.is_shared)
        .slice(0, 6);
    });

    const fetchBrowserlessCookie = document.cookie.match(
      new RegExp('(^| )' + 'browserless_io' + '=([^;]+)')
    );
    this.browserlessMode = fetchBrowserlessCookie
      ? fetchBrowserlessCookie[2] === 'true'
      : false;

    console.log('params: ', this.state.params);
    if (this.state.params.disablePopup) {
      this.renderer.addClass(window.document.body, 'hide-pendo-popup');
    }

    this.company
      .hasFeatureAccess('SCHEDULED_REPORTS')
      .then((result) => (this.schedulesAutomatedReportsFeature = !!result));

    console.log('this.tags', this.tags);
  }

  refreshReport() {
    this.state.reload();
  }

  ngOnChanges(changes: SimpleChanges) {
    const keywordFilter = this.activeFilters.find(
      (filter) => filter.key === 'keyword'
    );
    if (keywordFilter) {
      this.tags = [keywordFilter.match || keywordFilter.query];
      this.tagsCondition = keywordFilter.match ? 'match' : 'query';
    } else {
      this.tags = [];
    }

    this.date = this.activeFilters.find(
      (filter) => filter.field === 'Created At'
    )
      ? this.activeFilters.find((filter) => filter.field === 'Created At').label
      : this.dateRanges.month.label;

    this.warningBanner = this.activeFilters.find(
      (filter) => filter.all === 'deleted' || filter.all === 'permission'
    )
      ? true
      : false;

    this.activeFilterTotal = this.activeFilters.filter(
      (filter) =>
        filter.all !== 'deleted' &&
        filter.all !== 'permission' &&
        filter.key !== 'keyword'
    ).length;
  }

  dateSelected(date) {
    this.date = date;
    this.onDateChange.emit(
      this.dateRangesIterable.find((range) => range.label === date).id
    );
  }

  async openAutomateReportModal() {
    const automateModal = this.modal.open(AutomateModalComponent, {
      windowClass: 'orlo-modal'
    });
    automateModal.componentInstance.editMode = false;
    automateModal.componentInstance.reportUrl = this.state.href(
      this.state.current.name,
      this.state.params,
      { absolute: true }
    );
  }

  assignPosts(): void {
    const selectedPostCount = this.selectedPosts.length;
    const modal = this.modal.open(AssignMessageComponent, {
      windowClass: 'rounded-corners-40',
      centered: true
    });
    modal.componentInstance.selectedInsightsResults = this.selectedPosts;
    modal.componentInstance.isMonitoringActivity = true;

    modal.componentInstance.onAssigned.subscribe(
      (assignedUserOrTeam: Colleague | Team) => {
        const userOrTeamName =
          assignedUserOrTeam instanceof Colleague
            ? assignedUserOrTeam.fullName
            : assignedUserOrTeam.name;

        this.notificationService.open(
          `${selectedPostCount} post results have been assigned to ${userOrTeamName}`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          5000
        );

        this.onClearSelectedPosts.emit();
        this.refreshReport();
      }
    );
  }

  populateTags(): void {
    this.activityTags.getTags().then((tags: string[]) => {
      this.tags = tags.map((tag) => {
        return {
          id: tag,
          label: tag
        };
      });
    });
  }

  updateTags(selectedTags: Option[]): void {
    for (const item of this.selectedPosts) {
      item.data.setTags(
        this.selectedTags.map((t) => t.id),
        item.type === InsightsPostType.Monitoring
      );
    }
  }

  changeSentiment(sentiment: number) {
    this.onBulkSentimentUpdated.emit(sentiment);
  }

  updateEmotions(selectedEmotions: Option[]): void {
    for (const item of this.selectedPosts) {
      item.data.updateEmotions(
        this.selectedEmotions.map((e) => e.key),
        item.type === InsightsPostType.Monitoring
      );
    }
  }

  deletePosts() {
    if (this.disableBulkDelete) {
      return;
    }

    let resultsDeleted = 0;
    for (const item of this.selectedPosts) {
      if (item.type === InsightsPostType.Monitoring) {
        item.data.remove(item.type === InsightsPostType.Monitoring);
        resultsDeleted++;
      }
    }

    this.notificationService.open(
      `${resultsDeleted} post results have been deleted`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      5000
    );
    this.onClearSelectedPosts.emit();
    this.refreshReport();
  }

  onContainerWidthChange(width: string): void {
    this.actionBarWidth = parseInt(width, 10);
  }
}
