<div class="engagement-analytics-build-container">
  <div class="head">
    <h3>Inbox Report</h3>
    <h2>Build your report</h2>
  </div>
  <div class="body">
    <div class="body-inner">
      <ssi-report-build-from
        report="engagement"
        [showCompareToSection]="true"
        [showVisibilitySection]="true"
        [showTagsSection]="true"
        [showImportDelayOptions]="true"
        [showSilencedMessagesOptions]="true"
        [allChannels]="true"
        [accountTypeIdsToExclude]="[16,6]"
        [formValue]="formValue"
        (formSubmit)="onFormSubmit($event)"
      ></ssi-report-build-from>
    </div>
  </div>
</div>