import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatModalFactoryService } from '../../../services/live-chat-modal-factory/live-chat-modal-factory.service';
import { LiveChatConversationTreeModule } from '../live-chat-conversation-tree/live-chat-conversation-tree.module';
import { LiveChatModalComponent } from '../live-chat-modal/live-chat-modal.component';
import { LiveChatNavigationModule } from '../live-chat-navigation/live-chat-navigation.module';
import { LiveChatNextChatModule } from '../live-chat-next-chat/live-chat-next-chat.module';
import { LiveChatPushModeAgentEncouragementModule } from '../live-chat-push-mode-agent-encouragement/live-chat-push-mode-agent-encouragement.module';
import { LiveChatTallyTileModule } from '../live-chat-tally-tile/live-chat-tally-tile.module';
import { LiveChatTeamDashboardModule } from '../live-chat-team-dashboard/live-chat-team-dashboard.module';
import { LiveChatModalModule } from '../live-chat-modal/live-chat-modal.module';

import { LiveChatComponent } from './live-chat.component';
import { ActivityLinkPreviewComponent } from '../../activity-link-preview/activity-link-preview.component';
import { ActivityLinkPreviewModule } from '../../activity-link-preview/activity-link-preview.module';
@NgModule({
  declarations: [LiveChatComponent],

  exports: [LiveChatComponent],

  imports: [
    CommonModule,
    LiveChatConversationTreeModule,
    LiveChatNavigationModule,
    LiveChatNextChatModule,
    LiveChatPushModeAgentEncouragementModule,
    LiveChatTallyTileModule,
    LiveChatTeamDashboardModule,
    LiveChatModalModule,
    ActivityLinkPreviewModule,
    TranslateModule
  ],

  providers: [LiveChatModalFactoryService]
})
export class LiveChatModule {}
