import { ViewEncapsulation } from '@angular/core';

import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssi-message-split-panel',
  templateUrl: './message-split-panel.component.html',
  styleUrls: ['./message-split-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageSplitPanelComponent {
  @Output() cancel = new EventEmitter<any>();
  @Output() splitConversationConfirmed = new EventEmitter<any>();

  constructor() {}
}
