import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PasswordInputModule } from '../../../../common/components/password-input/password-input.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'entry.landing.login',
          url: '/login?returnTo',
          component: LoginComponent
        }
      ]
    }),
    FormsModule,
    PasswordInputModule,
    TranslateModule
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent]
})
export class LoginModule {}
