import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-cl-dropdown-select-g',
  templateUrl: './cl-dropdown-select-g.component.html',
  styles: [],
  styleUrls: ['./cl-dropdown-select-g.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClDropdownSelectGComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onSelectedTagsChange(selectedTags: any): void {
    console.log('onSelectedTagsChange: ', selectedTags);
  }
}
