import { ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild
} from '@angular/core';
import { Transition, StateService } from '@uirouter/angular';
import { workflowAccounts } from '../../../common-resolves';
import { Account } from '@ui-resources-angular';
import { trackByProperty } from '../../../../common/util';
import { API } from '@ui-resources-angular';
import { CampaignModelService } from './campaign-model/campaign-model.service';
import {
  Campaign,
  CampaignsService
} from '../../../../common/services/api/campaigns';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface Stats {
  outbox_clicks: number;
  outbox_comments: number;
  outbox_likes: number;
  outbox_messages: number;
  outbox_reach: number;
  outbox_impressions: number;
  outbox_shares: number;
  advert_clicks: number;
  advert_cost: number;
  advert_count: number;
  advert_count_live: number;
  advert_engagement: number;
  advert_impressions: number;
}

interface CampaignStats {
  [key: string]: Stats;
}

@Component({
  selector: 'ssi-marketing-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IndexComponent implements OnInit, OnDestroy {
  static resolve = [workflowAccounts];

  @Input() workflowAccounts: Account[];
  @ViewChild('campaignSelectWrapper') campaignHeader;

  now = new Date();
  currentCampaignsList: Campaign[];
  activeCampaignId: string;
  trackById = trackByProperty('id');
  settingsActive = false;
  campaignStats: CampaignStats = {};
  allCampaignStats = [];
  chartOptions = {
    loading: true
  };
  $activeCampaignSub;
  $newCampaignSub;
  chartDefaultStartDate = this.formatToDate('lastMonth');
  chartSearchDates = {
    from: null,
    to: null
  };
  childCampaign: any;
  campaignHasAdvertising = false;
  addCampaignActive = false;
  newCampaign = {
    name: '',
    parent_id: null,
    started_at: null,
    closed_at: null
  };
  fixCampaignHeader = false;
  campaigns: Campaign[];

  protected destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private api: API,
    private campaignModelService: CampaignModelService,
    private transition: Transition,
    private state: StateService,
    private campaignsService: CampaignsService
  ) {}

  async ngOnInit() {
    this.campaigns = await this.campaignsService.getAll();
    this.campaignsService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((allCampaigns) => {
        this.currentCampaignsList = this.campaignModelService.filterOutChildCampaigns(
          allCampaigns,
          false
        );
      });

    await this.fetchCampaignStats();
    this.fetchLinkClickStats(null, this.chartDefaultStartDate);

    this.$activeCampaignSub = this.campaignModelService.$activeCampaign.subscribe(
      (activeCampaign) => {
        if (activeCampaign) {
          this.activeCampaignId = (activeCampaign as any).id;
          this.currentCampaignsList = this.campaignModelService.filterInChildCampaigns(
            this.campaigns,
            false,
            this.activeCampaignId
          );
          this.fetchLinkClickStats(
            this.activeCampaignId,
            this.chartDefaultStartDate
          );
          this.updateCurrentCampaignTheme(activeCampaign);
        } else {
          this.activeCampaignId = null;
          this.currentCampaignsList = this.campaignModelService.filterOutChildCampaigns(
            this.campaigns,
            false
          );
          this.fetchLinkClickStats(null, this.chartDefaultStartDate);
          this.campaignHasAdvertising = false;
        }
        this.childCampaign = activeCampaign ? activeCampaign : undefined;
        this.updateLocationParams({
          query: this.activeCampaignId
            ? JSON.stringify({ campaign_id: this.activeCampaignId })
            : undefined
        });
      }
    );

    const { query } = this.transition.params();

    if (query) {
      const campaignParam = JSON.parse(query);
      this.selectCampaign(campaignParam.campaign_id);
    }
  }

  ngOnDestroy() {
    if (!!this.$activeCampaignSub) {
      this.$activeCampaignSub.unsubscribe();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.campaignHeader) {
      return;
    }

    const windowYPosition = window.scrollY;
    const campaignHeaderYPosition = this.campaignHeader.nativeElement.offsetTop;
    const pageNavOffset = 105;
    if (
      this.fixCampaignHeader !==
      windowYPosition > campaignHeaderYPosition - pageNavOffset
    ) {
      this.fixCampaignHeader =
        windowYPosition > campaignHeaderYPosition - pageNavOffset;
    }
  }

  updateCurrentCampaignTheme(activeCampaign) {
    const cleanStats = [
      ...this.allCampaignStats.filter((item) => item.campaign)
    ];
    const currentCampaignStats = cleanStats.find(
      (campaign) => campaign.campaign.id === activeCampaign.id
    );
    this.campaignHasAdvertising = currentCampaignStats.advert_count_live > 0;
  }

  public toggleAddCampaignDropdown() {
    this.newCampaign.parent_id = this.childCampaign
      ? this.childCampaign.id
      : null;
    this.addCampaignActive = !this.addCampaignActive;
  }

  formatToDate(timespan) {
    let timestamp;

    switch (timespan) {
      case 'lastQuarter':
        timestamp = moment(this.now).subtract(1, 'Q').format();
        break;
      case 'lastMonth':
        timestamp = moment(this.now).subtract(1, 'months').format();
        break;
      case 'lastWeek':
        timestamp = moment(this.now).subtract(7, 'days').format();
        break;
      case 'yesterday':
        timestamp = moment(this.now).subtract(1, 'days').format();
        break;
    }

    return timestamp;
  }

  chartChangeDate(timespan) {
    const from = this.formatToDate(timespan);
    const getCampaign = this.activeCampaignId || null;

    this.chartOptions = { loading: true };
    this.fetchLinkClickStats(getCampaign, from);
    this.fetchCampaignStats(from);
  }

  chartChangeDateCustom() {
    if (!moment(this.chartSearchDates.from).isValid()) {
      return;
    }

    if (!moment(this.chartSearchDates.to).isValid()) {
      return;
    }

    const lastDayCompensateHours = moment(this.chartSearchDates.to)
      .add(1, 'days')
      .format();
    const getCampaign = this.activeCampaignId || null;

    this.chartOptions = { loading: true };
    this.fetchLinkClickStats(
      getCampaign,
      this.chartSearchDates.from,
      lastDayCompensateHours
    );
    this.fetchCampaignStats(this.chartSearchDates.from, lastDayCompensateHours);
  }

  public filterByCompletedCampaigns(filterActive) {
    const filterActiveBool = filterActive === 'true';
    this.currentCampaignsList = this.activeCampaignId
      ? this.campaignModelService.filterInChildCampaigns(
          this.campaigns,
          filterActiveBool,
          this.activeCampaignId
        )
      : this.campaignModelService.filterOutChildCampaigns(
          this.campaigns,
          filterActiveBool
        );
  }

  public fetchChildren(parentCampaign) {
    return this.campaigns.filter(
      (campaign) => parentCampaign.id === campaign.parent_id
    );
  }

  public selectCampaign(id) {
    this.campaignModelService.currentActiveCampaign = id;
  }

  public async fetchCampaignStats(from?, to?) {
    const statsRequest = {
      account_ids: this.workflowAccounts
        .filter((acc) => acc.account_type_id !== '6')
        .map((acc) => acc.id),
      since: from || this.chartDefaultStartDate,
      until: to || moment(this.now).format()
    };

    await this.api.post('stats/marketing', statsRequest).then((res) => {
      this.campaignStats = this.formatStats(res.data.by_campaign);
      this.allCampaignStats = res.data.by_campaign;
    });
  }

  public fetchLinkClickStats(campaignId, from?, to?) {
    const linkClicksRequest = {
      account_ids: this.workflowAccounts
        .filter((acc) => acc.account_type_id !== '6')
        .map((acc) => acc.id),
      since: from || this.chartDefaultStartDate,
      until: to || moment(this.now).format(),
      group_by: ['datetime'],
      ...(campaignId && {
        campaign_ids: [campaignId]
      })
    };

    this.api
      .post('stats/eslinkclicks', linkClicksRequest)
      .then(
        (res) =>
          (this.chartOptions = this.formatDataForChart(res.data.by_datetime))
      );
  }

  private formatDataForChart(data) {
    return {
      chart: {
        type: 'spline'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: Object.keys(data).map((date) => {
          return moment(date).format('D MMM');
        }),
        labels: {
          style: {
            color: '#838eab',
            fontSize: '10px'
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null
        },
        labels: {
          style: {
            color: '#838eab',
            fontSize: '10px'
          }
        }
      },
      series: [
        {
          name: 'Clicks',
          color: '#14bae3',
          data: Object.values(data).map((item: any) => item.count)
        }
      ],
      loading: false
    };
  }

  private formatStats(totalStats): CampaignStats {
    const statKeys = [
      'outbox_clicks',
      'outbox_comments',
      'outbox_likes',
      'outbox_messages',
      'outbox_reach',
      'outbox_impressions',
      'outbox_shares',
      'advert_clicks',
      'advert_cost',
      'advert_count',
      'advert_impressions',
      'advert_count_live',
      'advert_engagement'
    ];
    const returnObj = {};

    function totalUpStats(statsArray) {
      const statistics = {};
      for (const stat of statKeys) {
        statistics[stat] = _.reduce(
          statsArray,
          (sum, n) => {
            return sum + parseInt(n[stat], 10);
          },
          0
        );
      }
      return statistics;
    }
    this.campaigns.map((campaign) => {
      const statsPerParentCampaign = totalStats.filter((stat) => {
        if (stat.campaign) {
          // Add stats for single campaigns with parents
          if (stat.campaign.parent_id) {
            returnObj[stat.campaign.id] = stat;
          }
          return (
            stat.campaign.id === campaign.id ||
            stat.campaign.parent_id === campaign.id
          );
        }
      });
      returnObj[campaign.id] = totalUpStats(statsPerParentCampaign);
    });

    returnObj['total'] = totalUpStats(totalStats);

    console.log(returnObj);

    return returnObj;
  }

  private updateLocationParams(params) {
    this.state.go('auth.marketing.index', params);
  }
}
