import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';

import { EditComponent } from './edit.component';

import { WidgetsGridModule } from '../../common/components/widgets-grid/widgets-grid.module';
import { InsightsNavbarModule } from '../../common/components/insights-navbar/insights-navbar.module';
import { DropdownSelect2Module } from '../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { NotificationComponent } from '@orlo/common/services/notification/notification.component';
import { NotificationModule } from '@orlo/common/services/notification/notification.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.insights.reports.edit',
          url: '/edit/:id?reportName',
          data: {
            shouldOpenAddWidget: false
          },
          component: EditComponent,
          resolve: EditComponent.resolve
        }
      ]
    }),
    WidgetsGridModule,
    InsightsNavbarModule,
    DropdownSelect2Module,
    NotificationModule
  ],
  exports: [],
  declarations: [EditComponent],
  entryComponents: [NotificationComponent]
})
export class EditModule {}
