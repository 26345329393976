import { ViewEncapsulation } from '@angular/core';
import {
  Input,
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
  Outbox,
  User,
  // OutboxPublisher,
  OutboxPublisherMention,
  Account,
  AutoComment
  // socialNetworkSettings,
  // BlockingWord
} from '@ui-resources-angular';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
import { AccountTypeId } from '../../enums';
import { OutboxCampaignModalComponent } from '../outbox-campaign-modal/outbox-campaign-modal.component';
import { ScheduleFirstCommentComponent } from '../schedule-first-comment/schedule-first-comment.component';
import { Tag, TagType, OutboxTagsService } from '../../services/api';
// import { PublisherSocialNetworkPreviewModalComponent } from '../publisher/publisher-social-network-preview-modal/publisher-social-network-preview-modal.component';
// import { CompanyService } from '../../services/company/company.service';

@Component({
  selector: 'ssi-outbox-post-new',
  templateUrl: './outbox-post-new.component.html',
  styleUrls: ['./outbox-post-new.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutboxPostNewComponent implements OnInit, OnDestroy {
  @Input() post: Outbox;
  @Input() showDelete = true;
  @Input() account: Account;
  @Input() authUser: User;
  @Output() closeModal = new EventEmitter();
  @Output() onDeletedPost = new EventEmitter();
  mentions: OutboxPublisherMention[] = [];
  AccountTypeId = AccountTypeId;
  media: string[] = [];
  mediaAltText: string[] = [];

  tags: Tag[] = [];
  selectedTags: Tag[] = [];
  destroyed$ = new Subject<void>();

  @ViewChild('deletePostTooltip') deletePostTooltip: TooltipDirective;

  constructor(
    private state: StateService,
    private modal: NgbModal,
    private outboxTagsService: OutboxTagsService // private blockingWord: BlockingWord, // private company: CompanyService
  ) {}

  async ngOnInit() {
    this.mentions = this.post.getMentions();
    this.media = this._formatMedia();
    this.mediaAltText = this._formatMediaAltText();
    this.showDelete = !this.post.deleted_at;

    this.outboxTagsService.postTagsStore.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags) => {
        this.tags = tags;

        this.selectedTags = this.post.post_tags
          .map((tag) => {
            return this.outboxTagsService.postTagsStore.find(tag.id);
          })
          .filter((tag) => !!tag);
      });
  }

  public showDeletePostTooltip(): void {
    this.deletePostTooltip.show();
  }

  public async openCampaignModal() {
    const modal = this.modal.open(OutboxCampaignModalComponent, {
      windowClass: 'orlo-modal modal-high-zindex',
      backdropClass: 'backdrop-high-zindex',
      backdrop: 'static'
    });
    modal.componentInstance.post = this.post;
    const result = await modal.result;
    this.post.campaign_id = result;
  }

  postTagsChange(selectedTags: Tag[]): void {
    this.post.updateTags(this.selectedTags);
  }

  createTag(name: string): void {
    this.outboxTagsService.createTag(name, TagType.Post).then((createdTag) => {
      this.selectedTags.push(this.tags.find((t) => t.id === createdTag.id));
      this.postTagsChange(this.selectedTags);
    });
  }

  public navigateToBoost(params: { [key: string]: string }): void {
    this.closeModal.emit();
    setTimeout(() => {
      // timeout needed since closing the modal updates the state (removes outboxId param from url)
      // so wait for that before going to the new state
      this.state.go('auth.marketing.advertising.boostPost', params);
      window.scrollTo({ behavior: 'smooth', top: 0 });
    });
  }

  // public async openPostPreviewModal() {
  //   const socNetWithAcc = {
  //     config: socialNetworkSettings[this.account.accountTypeName],
  //     accounts: [this.account]
  //   };
  //   const companyConfig = await this.company.getConfig();
  //   const blockingWords = await this.blockingWord.getBlockingWords();
  //   const blockingWordsRaw = blockingWords.words.map(
  //     (blockingWord) => blockingWord.word
  //   );
  //   const outboxPost = new OutboxPublisher(
  //     [this.account],
  //     this.authUser,
  //     companyConfig.use_utm_link_tracking,
  //     companyConfig.use_link_shortening,
  //     blockingWordsRaw,
  //     { replyToSocialId: undefined },
  //     true
  //   );
  //   const modal = this.modal.open(PublisherSocialNetworkPreviewModalComponent, { centered: true });
  //   modal.componentInstance.post = Object.assign({}, outboxPost, { socialNetworks: [socNetWithAcc] });
  //   modal.componentInstance.activeAccount = this.account;
  //   modal.componentInstance.activeNetwork = socNetWithAcc;
  // }

  private _formatMedia(): string[] {
    if (this.post.outbox_files.length === 1) {
      return Array.of(this.post.outbox_files[0].public_url);
    }
    if (this.post.outbox_files.length > 1) {
      return this.post.outbox_files.map((image) => image.public_url);
    }
  }

  private _formatMediaAltText(): string[] {
    if (this.post.outbox_files.length === 1) {
      return Array.of(this.post.outbox_files[0].alt_text);
    }
    if (this.post.outbox_files.length > 1) {
      return this.post.outbox_files.map((image) => image.alt_text);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  onAddToThread(): void {
    this.closeModal.emit();
    setTimeout(() => {
      // timeout needed since closing the modal updates the state (removes outboxId param from url)
      // so wait for that before going to the new state
      this.state.go('auth.twitterThreads', { outboxId: this.post.id });
    });
  }

  async editAutoComment(post: Outbox): Promise<void> {
    try {
      await this.showScheduleFirstCommentModal(post);
    } catch (e) {
      console.log('Cancelled / modal dismissed..');
      return;
    }
  }

  async showScheduleFirstCommentModal(
    post: Outbox
  ): Promise<AutoComment | undefined> {
    const modal = await this.modal.open(ScheduleFirstCommentComponent, {
      windowClass: 'orlo-modal orlo-modal-1200 position-top-20',
      backdropClass: 'orlo-modal-backdrop'
    });
    modal.componentInstance.outboxPost = post;

    return await modal.result;
  }
}
