<div class="account-selector-container"
     [class.no-selected-options]="!selectedAccounts.length">
     
  <!-- <label>Select account</label> -->
  <ssi-dropdown-select-2 
    #dropdownElm
    size="lg"
    name="accounts"
    [multiple]="true"
    [filterable]="true"
    [placeholdersKeyword]="'accounts'"
    [toggleAllLabel]="'all accounts'"
    [options]="accounts"
    [groupBy]="{key: 'account_type_id', toggleAllEnabled: true, getGroupLabel: getGroupLabel}"
    [disabled]="disabled"
    [(ngModel)]="selectedAccounts"
    (ngModelChange)="selectedAccountsChanged($event)"
    (toggled)="onDropdownToggled($event)"
    [ssiObserveWidthAndHeight]="true"
    (onWidthChange)="onDropdownWidthChange()"
  >

    <ng-template ssiTemplateSelector="headButtonLabel"
                 let-toggle="toggle"
                 let-expanded="expanded">
      <div class="select-placeholder"
           *ngIf="!selectedAccounts.length">
        <span translate="SELECT_ACCOUNTS"></span>
      </div>
      <div class="selected-options-container">
        <div #chipsContainer
             class="chips-container"
             [style.width.px]="chipsContainerWidth"
             *ngIf="selectedAccounts.length">
          <div class="chip"
               (click)="$event.stopPropagation();"
               *ngFor="let account of selectedAccounts | slice:0:maxVisibleChipsCount;">
            <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                                     [size]="20"></ssi-social-network-icon>
            <span>{{account.name || account.alias || account.username}}</span>
            <i class="ssi ssi-delete-close"
               [class.disabled]="disabled"
               (click)="$event.stopPropagation(); !disabled && deselectAccount(account);"></i>
          </div>
        </div>
        <button *ngIf="selectedAccounts.length > maxVisibleChipsCount"
                type="button"
                class="g-btn text-only-button"
                (click)="$event.stopPropagation(); toggle();">{{selectedAccounts.length - maxVisibleChipsCount}} more</button>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="bodyHeader"
                 let-toggle="toggle">
      <div class="menu-header">
        <span *ngIf="selectedAccounts.length"
              class="g-text">{{selectedAccounts.length}} accounts selected</span>
        <span *ngIf="!selectedAccounts.length"
              class="g-text no-accounts-selected-label">No accounts selected</span>
        <button *ngIf="selectedAccounts.length"
                type="button"
                class="g-btn text-only-button underlined"
                (click)="$event.stopPropagation(); deselectAll();"><span>Clear all</span></button>
        <i class="ssi ssi-arrow-down-new collapse-icon"
           (click)="toggle()"></i>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="optionIcon"
                 let-account="option">
      <ssi-social-network-icon [accountTypeId]="account.account_type_id"
                               [size]="20"></ssi-social-network-icon>
    </ng-template>
  </ssi-dropdown-select-2>
</div>