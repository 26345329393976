<div class="multi-select">
  <!-- <ul *ngIf="selectedItems.length" class="multi-select-selected-items">
    <li *ngFor="let item of selectedItems" class="multi-select-selected-item">
      <p>{{item}}</p>
      <span
        (click)="removeItem(item)">
        <i class="ssi ssi-times multi-select-selected-item-action"></i>
      </span>
    </li>
  </ul> -->

  <div class="multi-select-input-wrap">
    <input
      #multiSelectInput
      (keyup)="onInputKeyDown($event)"
      class="multi-select-input"
      placeholder="Type Inbox Tags...">
    <i class="ssi ssi-search-small"></i>
    <div
      *ngIf="showCreateNewOption && showAddNew"
      class="multi-select-input-action"
      (click)="createItem()">
      <i class="ssi ssi-addsmall"></i>
      <span>New</span>
    </div>
  </div>
  
  <ul class="multi-select-items custom-scrollbar">
    <li
      *ngFor="let item of filteredItems"
      [ngClass]="{'multi-select-item-highlight': item.isSelected}"
      class="multi-select-item"
      (click)="item.isSelected ? removeItem(item) : addItem(item)">
    
        <span>{{item.label}}</span>
        <!-- <span
          *ngIf="item.isSelected"
          class="multi-select-item-action">
          <i class="ssi ssi-tick"></i>
        </span> -->
        <div class="tick-container"
               [class.checked]="item.isSelected">
          <i class="ssi ssi-tick-small tick"></i>
        </div>
  
    </li>
  </ul>
</div>