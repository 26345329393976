import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Account,
  AccountModel,
  CampaignModel,
  UserModel
} from '@ui-resources-angular';
import {
  CampaignsService,
  TeamsService,
  ColleaguesService
} from '../../../../../../../common/services/api';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import {
  InboxActivityQuery,
  InboxQueryFactoryService
} from '../../../../../../../common/services/inbox-query-factory/inbox-query-factory.service';
import { CompanyService } from '../../../../../../../common/services/company/company.service';
import { InboxQueryResultListItem } from '../../../../../../../common/services/inbox-query-factory/queries/common';
import { WorkflowManagerService } from '../../../../../../../common/services/workflow-manager/workflow-manager.service';
import { ConversationPushModeService } from '../../../../../../../common/services/conversation-push-mode/conversation-push-mode.service';
import { LiveChatService } from '../../../../../../../common/services/live-chat/live-chat.service';
import { InboxActivityModalComponent } from '../../../../../../../common/components/inbox-activity/inbox-activity-modal/inbox-activity-modal.component';
import { ROW_HEIGHT } from '../../widgets-grid/widgets-grid.component';

@Component({
  selector: 'ssi-widget-inbox-message',
  templateUrl: './widget-inbox-message.component.html',
  styles: [],
  styleUrls: ['./widget-inbox-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetInboxMessageComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() widget: DashboardWidget;
  @Output() loaded = new EventEmitter<boolean>();

  inboxQuery: InboxActivityQuery;
  containerHeight: number;

  isLiveChatEnabled = false;
  isLiveChatActive = false;
  hasConversationPushModeEnabled = false;

  protected destroyed$ = new Subject<void>();

  constructor(
    protected modal: NgbModal,
    protected injector: Injector,
    protected userModel: UserModel,
    protected accountModel: AccountModel,
    protected campaignModel: CampaignModel,
    protected companyService: CompanyService,
    protected teamsService: TeamsService,
    protected colleaguesService: ColleaguesService,
    protected campaignsService: CampaignsService,
    protected workflowManager: WorkflowManagerService,
    protected inboxQueryFactory: InboxQueryFactoryService,
    protected liveChatService: LiveChatService,
    protected conversationPushModeService: ConversationPushModeService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['widget'] && changes['widget'].currentValue) {
      this.containerHeight = this.widget.display_properties.h * ROW_HEIGHT - 48; // - widget header height
    }
  }

  async ngOnInit() {
    this.liveChatService.login();
    this.liveChatService.isEnabled$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((enabled) => {
        this.isLiveChatEnabled = enabled;
        this.isLiveChatActive = false; // this.isLiveChatEnabled;
      });

    this.conversationPushModeService
      .getCompanySettings()
      .then((socialPushSettings) => {
        this.hasConversationPushModeEnabled =
          socialPushSettings && socialPushSettings.enabled;
      });

    const [
      allAccounts,
      workflowAccounts,
      authUser,
      teams,
      colleagues,
      campaigns,
      companyConfig
    ] = await Promise.all([
      this.accountModel.findAccounts(),
      this.accountModel.findAllAccounts(this.workflowManager.getCurrentId()),
      this.userModel.getAuthUser(),
      this.teamsService.getAll(),
      this.colleaguesService.getAll(),
      this.campaignModel.findAll(),
      this.companyService.getConfig()
    ]);

    this.inboxQuery = this.inboxQueryFactory.createActivityQuery({
      allAccounts,
      authUser,
      colleagues,
      teams,
      campaigns,
      savedQuery: JSON.parse(JSON.stringify(this.widget.apiSearchParams)), // keep the instance - for some reason it's being modified by the inbox query
      onSearch() {
        // console.log('onSearch...');
      }
    });

    this.inboxQuery.search().then(() => {
      this.loaded.emit(true);
    });

    this.inboxQuery.activeResult.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((value) => !!value))
      .subscribe((value: InboxQueryResultListItem) => {
        const modal = this.modal.open(InboxActivityModalComponent, {
          windowClass: 'xxl-modal'
        });
        modal.componentInstance.authUser = authUser;
        modal.componentInstance.activity = this.inboxQuery.activeResult.value.result.activity;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateLiveChatStatus(value: boolean) {
    // if (!value) {
    //   this.onExitLiveChat.emit();
    // }
    // this.isLiveChatActive = value;
  }
}
