<div class="team-report-container">
  <ssi-report-header
    report="Team Report"
    [name]=""
    [dateRangesTemplate]="dateRangesRef"
    (dateSelected)="reportDateChanged($event)"
  ></ssi-report-header>
  
  <ssi-report-sidebar
    icon="ssi-marketing-analytics"
    color="#D41D68"
    [navList]="navItems"
    [actionList]="reportActions"
    [active]="activeNavItemTag"
    (navClicked)="scrollTo($event)"
  ></ssi-report-sidebar>

  <div
    class="report-body-loading"
    *ngIf="loading"
  >
    <div class="loading-container">
      <img src="assets/images/line-chart.gif">
      <h3>We're building your report!</h3>
      <p>This may take a few moments
      </p>
    </div>
  </div>

  <div
    class="report-body"
    *ngIf="!loading"
  >

    <section
      id="users-summary"
      class="section-container section-container-users-summary"
    >
      <h2 class="section-title">Users Summary</h2>

      <ssi-treetable
        [data]="report.userStats"
        [bodyStyle]="{'max-height': '408px'}"
        [rowStyle]="{'height': '68px'}"
        [virtualScroll]="false"
        [resizeMode]="'fit'"
        [reorderable]="true"
        [saveSettings]="true"
        [settingsStorageKey]="'treetable-teams-report-user-performance'"
        [globalFilterInputRef]=""
        id="treetable-teams-report-user-performance"
      >
        <ssi-column
          header="User"
          field="user.fullName"
          [templateRefs]="{bodyCell: userBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          header="Last Active"
          field="user.last_login"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          field="total_outbox_posts"
          header="Total messages published"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
        <ssi-column
          field="total_actioned_activities"
          header="Total inbox messages actioned"
          [templateRefs]="{bodyCell: statBodyCellTemplate}"
        ></ssi-column>
      </ssi-treetable>
    
      <ng-template
        #userBodyCellTemplate
        ssiTemplateSelector="bodyCell"
        let-rowIndex="rowIndex"
        let-rowData="rowData"
        let-fieldValue="fieldValue"
      >
        <div class="body-cell body-cell-primary">
          <i class="ssi ssi-user"></i>
          <p>{{fieldValue}}</p>
        </div>
      </ng-template>
    </section>

    <div class="section-delimiter"></div>

    <section
      id="teams-summary"
      class="section-container section-container-teams-breakdown"
    >
      <h2 class="section-title">Teams Summary</h2>

      <div class="team-performance-section">
        <ssi-treetable
          [data]="report.teamStats"
          [bodyStyle]="{'max-height': '408px'}"
          [rowStyle]="{'height': '68px'}"
          [virtualScroll]="false"
          [resizeMode]="'fit'"
          [reorderable]="true"
          [saveSettings]="true"
          [settingsStorageKey]="'treetable-teams-report-team-performance'"
          [globalFilterInputRef]=""
          id="treetable-teams-report-team-performance"
        >
          <ssi-column
            header="Team"
            field="team.name"
            [templateRefs]="{bodyCell: teamBodyCellTemplate}"
          ></ssi-column>
          <ssi-column
            field="total_outbox_posts"
            header="Total messages published"
            [templateRefs]="{bodyCell: statBodyCellTemplate}"
          ></ssi-column>
          <ssi-column
            field="total_actioned_activities"
            header="Total inbox messages actioned"
            [templateRefs]="{bodyCell: statBodyCellTemplate}"
          ></ssi-column>
        </ssi-treetable>
      
        <ng-template
          #teamBodyCellTemplate
          ssiTemplateSelector="bodyCell"
          let-rowIndex="rowIndex"
          let-rowData="rowData"
          let-fieldValue="fieldValue"
        >
          <div class="body-cell body-cell-primary">
            <i class="ssi ssi-teams"></i>
            <p>{{fieldValue}}</p>
          </div>
        </ng-template>
      
        <ng-template
          #statBodyCellTemplate
          ssiTemplateSelector="bodyCell"
          let-rowIndex="rowIndex"
          let-rowData="rowData"
          let-column="column"
          let-columnIndex="columnIndex"
          let-columns="columns"
          let-columnsMap="columnsMap"
          let-fieldValue="fieldValue"
        >
          <div
            class="body-cell stat"
            [class.row-even]="rowIndex % 2 !== 0"
          >
            <p
              *ngIf="isTimeField(column.field)"
              [innerHTML]="formatDate(fieldValue)"
            ></p>
            <p
              *ngIf="!isTimeField(column.field)"
              [innerHTML]="fieldValue"
            ></p>
          </div>
        </ng-template>
      </div>
    </section>

  </div>

  <ng-template #dateRangesRef>
    <div class="selected-date-ranges">
      <span class="current">
        {{dateRanges.start | date: 'dd MMM yy'}} - {{dateRanges.end | date: 'dd MMM yy'}}
      </span>
    </div>
  </ng-template>
</div>