import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopupOptions, PopupType } from '../popup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-popup-message-modal',
  templateUrl: './popup-message-modal.component.html',
  styleUrls: ['./popup-message-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupMessageModalComponent implements OnInit {
  options: PopupOptions;

  PopupType = PopupType;

  inputText = '';

  userFirstName = '';

  selectedTags = [];

  constructor(
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private userModel: UserModel
  ) {}

  async ngOnInit() {
    this.options.message = this.options.message || this.options.template;
    this.options.okText = this.options.okText || this.translate.instant('OK');
    this.options.cancelText =
      this.options.cancelText || this.translate.instant('CANCEL');
    try {
      this.userFirstName = (await this.userModel.getAuthUser()).first_name;
    } catch (e) {
      this.userFirstName = ''; // user is not logged in
    }
  }

  onSelectedTagsChange(tags: any[]) {
    this.selectedTags = tags;
  }

  removeIncludedTag(tag) {
    this.selectedTags = this.selectedTags.filter((iTag) => iTag.id !== tag.id);
  }
}
