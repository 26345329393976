import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatNoteFormModule } from '../live-chat-note-form/live-chat-note-form.module';
import { LiveChatInlineNotesModule } from '../live-chat-inline-notes/live-chat-inline-notes.module';
import { LiveChatMessageModule } from '../live-chat-message/live-chat-message.module';
import { LiveChatMessageNoteModule } from '../live-chat-message-note/live-chat-message-note.module';

import { LiveChatNotesComponent } from './live-chat-notes.component';

@NgModule({
  declarations: [LiveChatNotesComponent],

  exports: [LiveChatNotesComponent],

  imports: [
    CommonModule,
    LiveChatInlineNotesModule,
    LiveChatMessageModule,
    LiveChatMessageNoteModule,
    LiveChatNoteFormModule,
    TranslateModule
  ]
})
export class LiveChatNotesModule {}
