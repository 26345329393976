import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { LandingComponent } from './landing.component';
import { LoginModule } from './login/login.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'entry.landing',
          url: '/landing',
          component: LandingComponent,
          abstract: true
        }
      ]
    }),
    LoginModule,
    ForgotPasswordModule
  ],
  declarations: [LandingComponent],
  exports: [LandingComponent]
})
export class LandingModule {}
