import { ViewEncapsulation } from '@angular/core';


import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

import getCaretCoordinates from 'textarea-caret';
import { precedingCharValid } from '../text-input-autocomplete';
import { JoyPixelsEmoji } from '../../directives/emoji-form-control-container/emoji-picker/emoji-picker.component';
import * as EmojiToolkit from 'emoji-toolkit';

// @ts-ignore
// import toPX from 'to-px';

export interface EmojiChoice {
  label: string;
  char: string;
}
@Component({
  selector: 'ssi-emoji-autocomplete',
  templateUrl: './emoji-autocomplete.component.html',
  styleUrls: ['./emoji-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmojiAutocompleteComponent
  implements OnChanges, OnInit, OnDestroy {
  @Input() textInputElement: HTMLTextAreaElement | HTMLInputElement;
  @Input() menuTemplate: TemplateRef<any>;
  @Input() triggerCharacter = ':';
  @Input() menuOffsetTop = 0;
  /**
   * The regular expression that will match the search text after the trigger character.
   * No match will hide the menu.
   */
  @Input() searchRegexp = /(?:\s+|^):(\w*(?: \w+)?)$/;

  @Output() menuShow = new EventEmitter();
  @Output() menuHide = new EventEmitter();
  @Output() emojiSelected = new EventEmitter<EmojiChoice>();

  emojiList: JoyPixelsEmoji[];
  choices: EmojiChoice[] = [];
  activeChoice: EmojiChoice;
  private _eventListeners: Array<() => void> = [];

  menuCtrl?: {
    template: TemplateRef<any>;
    context: any;
    position: {
      top: number;
      left: number;
    };
    triggerCharacterPosition: number;
  };

  constructor(private ngZone: NgZone, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {}

  async ngOnInit() {
    this.setKeyboardEventListeners();
    this.setTextareParentElementStyles();
    this.loadEmojiLibs();
  }

  ngOnDestroy() {
    this.hideMenu();
    this._eventListeners.forEach((unregister) => unregister());
  }

  setKeyboardEventListeners(): void {
    const onKeydown = this.renderer.listen(
      this.textInputElement,
      'keydown',
      (event) => this.onKeydown(event)
    );
    this._eventListeners.push(onKeydown);

    const onInput = this.renderer.listen(
      this.textInputElement,
      'input',
      (event) => this.onInput(event)
    );
    this._eventListeners.push(onInput);

    const onBlur = this.renderer.listen(
      this.textInputElement,
      'blur',
      (event) => this.onBlur(event)
    );
    this._eventListeners.push(onBlur);

    const onClick = this.renderer.listen(
      this.textInputElement,
      'click',
      (event) => this.onClick(event)
    );
    this._eventListeners.push(onClick);
  }

  onKeydown(event: KeyboardEvent): void {
    if (!this.menuCtrl) {
      const cursorPosition = this.textInputElement.selectionStart;
      const precedingChar = this.textInputElement.value.charAt(
        cursorPosition - 1
      );

      if (
        event.key === this.triggerCharacter &&
        precedingCharValid(precedingChar)
      ) {
        this.showMenu();
        return;
      }

      return;
    }

    if (this.menuCtrl && !this.menuTemplate) {
      this.handleMenuArrowKeys(event);
    }
  }

  handleMenuArrowKeys(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const activeChoiceIdx = this.choices.indexOf(this.activeChoice);
      this.activeChoice = this.choices[activeChoiceIdx + 1] || this.choices[0];
      return;
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      const activeChoiceIdx = this.choices.indexOf(this.activeChoice);
      this.activeChoice =
        this.choices[activeChoiceIdx - 1] ||
        this.choices[this.choices.length - 1];
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      this.selectChoice(this.activeChoice);
      return;
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      return;
    }

    if (event.key === 'ArrowRight') {
      event.preventDefault();
      return;
    }
  }

  onInput(event: any): void {
    if (!this.menuCtrl) {
      return;
    }

    const value = event.target.value;

    if (
      value[this.menuCtrl.triggerCharacterPosition] !== this.triggerCharacter
    ) {
      this.hideMenu();
      return;
    }

    const cursorPosition = this.textInputElement.selectionStart;
    if (cursorPosition < this.menuCtrl.triggerCharacterPosition) {
      this.hideMenu();
      return;
    }

    const searchText = value.slice(
      this.menuCtrl.triggerCharacterPosition,
      cursorPosition
    );
    if (!searchText.match(this.searchRegexp)) {
      this.hideMenu();
      return;
    }

    this.search(searchText);
  }

  onBlur(event: FocusEvent): void {
    if (!this.menuCtrl) {
      return;
    }

    // this.hideMenu();
  }

  onClick(event: MouseEvent): void {
    if (!this.menuCtrl) {
      return;
    }

    const cursorPosition = this.textInputElement.selectionStart;
    if (cursorPosition <= this.menuCtrl.triggerCharacterPosition) {
      this.hideMenu();
      return;
    }

    const searchText = this.textInputElement.value.slice(
      this.menuCtrl.triggerCharacterPosition,
      cursorPosition
    );
    if (!searchText.match(this.searchRegexp)) {
      this.hideMenu();
      return;
    }
  }

  hideMenu() {
    if (!this.menuCtrl) {
      return;
    }

    this.menuCtrl = undefined;
    this.menuHide.emit();
  }

  showMenu() {
    if (this.menuCtrl) {
      return;
    }

    const lineHeight = getLineHeight(this.textInputElement);
    const { top, left } = getCaretCoordinates(
      this.textInputElement,
      this.textInputElement.selectionStart
    );

    this.menuCtrl = {
      template: this.menuTemplate,
      context: {
        choices: this.choices,
        selectChoice: this.selectChoice
        // $implicit: {
        //   selectChoice: this.selectChoice
        // },
      },
      position: {
        top: top + lineHeight + this.menuOffsetTop,
        left
      },
      triggerCharacterPosition: this.textInputElement.selectionStart
    };

    this.menuShow.emit();
  }

  search(value: string) {
    this.choices = this.emojiList
      // exclude unicode9 emoji for now until browsers support them
      .filter(
        (emoji: JoyPixelsEmoji) =>
          !['modifier', 'regional'].includes(emoji.category)
      )
      .sort((a: JoyPixelsEmoji, b: JoyPixelsEmoji) => +a.order - +b.order)
      .filter(
        (emoji: JoyPixelsEmoji) =>
          emoji.shortname &&
          emoji.shortname.toLowerCase().includes(value.toLowerCase())
      )
      .slice(0, 5)
      .map((emoji: JoyPixelsEmoji) => {
        const char = EmojiToolkit.shortnameToUnicode(emoji.shortname);

        return {
          label: `${char} ${emoji.shortname}`,
          char
        };
      });

    this.activeChoice = this.choices[0];
  }

  selectChoice = (choice: EmojiChoice) => {
    const label = choice.char;
    const startIndex = this.menuCtrl!.triggerCharacterPosition;
    const start = this.textInputElement.value.slice(0, startIndex);
    const caretPosition = this.textInputElement.selectionStart;
    const end = this.textInputElement.value.slice(caretPosition);
    const insertValue = label + ' ';
    this.textInputElement.value = start + insertValue + end;
    // force ng model / form control to update
    this.textInputElement.dispatchEvent(new Event('input'));

    const setCursorAt = (start + insertValue).length;
    this.textInputElement.setSelectionRange(setCursorAt, setCursorAt);
    this.textInputElement.focus();

    this.emojiSelected.emit(choice);

    this.hideMenu();
  };

  async loadEmojiLibs(): Promise<void> {
    const [emojiList] = await Promise.all([import('emoji-toolkit/emoji.json')]);

    this.emojiList = Object.values(emojiList);
  }

  setTextareParentElementStyles(): void {
    if (
      getComputedStyle(this.textInputElement.parentElement).position !==
      'relative'
    ) {
      this.textInputElement.parentElement.style['position'] = 'relative';
    }
    if (
      getComputedStyle(this.textInputElement.parentElement).display === 'inline'
    ) {
      // If textarea is direct child of a component (no DIV container)
      this.textInputElement.parentElement.style['display'] = 'block';
    }
  }
}

function getLineHeight(elm: HTMLElement): number {
  const lineHeightStr = getComputedStyle(elm).lineHeight || '';
  const lineHeight = parseFloat(lineHeightStr);
  const normalLineHeight = 1.2;

  const fontSizeStr = getComputedStyle(elm).fontSize || '';
  // const fontSize = +toPX(fontSizeStr);
  const fontSize = parseFloat(fontSizeStr);

  if (lineHeightStr === lineHeight + '') {
    return fontSize * lineHeight;
  }

  if (lineHeightStr.toLowerCase() === 'normal') {
    return fontSize * normalLineHeight;
  }

  // return toPX(lineHeightStr);
  return parseFloat(lineHeightStr);
}
