import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ListSelectModule } from '../../list-select/list-select.module';
import { LiveChatAssignChatComponent } from './live-chat-assign-chat.component';
import { LiveChatTeamModule } from '../live-chat-team/live-chat-team.module';

@NgModule({
  declarations: [LiveChatAssignChatComponent],

  exports: [LiveChatAssignChatComponent],

  imports: [CommonModule, ListSelectModule, LiveChatTeamModule, TranslateModule]
})
export class LiveChatAssignChatModule {}
