import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ssi-bulk-progress-modal',
  templateUrl: './bulk-progress-modal.component.html',
  styleUrls: ['./bulk-progress-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BulkProgressModalComponent {
  @Input() progress: { total: number; completed: number };
  constructor(public activeModal: NgbActiveModal) {}

  close(value: boolean) {
    this.activeModal.close(value);
  }
}
