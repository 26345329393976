import { Injectable } from '@angular/core';
import { promisescript } from '@orlo/common/utils/promise-load-script';

@Injectable()
export class LazyLoadService {
  loadFileStackCss(): Promise<void> {
    return promisescript({
      url: 'https://static.filestackapi.com/picker/v3/0.10.3/main.css',
      type: 'style'
    });
  }
}
