import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { StreamBuilderService, StreamQuery } from '../stream-builder.service';

@Component({
  selector: 'ssi-description-step',
  templateUrl: './description-step.component.html',
  styles: [],
  styleUrls: ['./description-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DescriptionStepComponent implements OnInit {
  streamQuery: StreamQuery;

  constructor(private streamBuilderService: StreamBuilderService) {}

  ngOnInit() {
    this.streamQuery = this.streamBuilderService.fetchStreamQuery();
    console.log(this.streamQuery);
  }

  saveStep() {
    this.streamBuilderService.updateStreamQuery(this.streamQuery);
  }

  deleteStream(streamQuery) {
    this.streamBuilderService.deleteSearchStream(this.streamQuery.id, true);
  }
}
