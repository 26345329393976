import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Topic,
  TopicResponseType
} from '../../../../../../common/services/chat-bot/chat-bot.service';

@Component({
  selector: 'ssi-topic-modal',
  templateUrl: './topic-modal.component.html',
  styles: [],
  styleUrls: ['./topic-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopicModalComponent implements OnInit {
  @Input() title: string;
  @Input() actionButton: string;
  @Input() topic: Topic;
  responseType: TopicResponseType;
  topicForm: Topic = {
    name: '',
    response: '',
    link: '',
    link_text: '',
    is_enabled: true,
    is_handover_request: false,
    is_no_handover: false,
    is_conversational: false
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.topic.id) {
      Object.keys(this.topicForm).forEach(
        (field) => (this.topicForm[field] = this.topic[field])
      );

      if (this.topicForm.is_handover_request) {
        this.responseType = TopicResponseType.HANDOVER;
      } else if (this.topicForm.is_no_handover) {
        this.responseType = TopicResponseType.NO_HANDOVER;
      } else if (this.topicForm.is_conversational) {
        this.responseType = TopicResponseType.CONVERSATIONAL;
      } else {
        this.responseType = TopicResponseType.DEFAULT;
      }
    }
  }

  submitTopicForm(form) {
    const formToSubmit = {
      ...form,
      is_handover_request: this.responseType === TopicResponseType.HANDOVER,
      is_no_handover: this.responseType === TopicResponseType.NO_HANDOVER,
      is_conversational: this.responseType === TopicResponseType.CONVERSATIONAL
    };
    this.activeModal.close(formToSubmit);
  }
}
