import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PluginService } from '../../services/plugin/plugin.service';
import { CrmPerson } from '@ui-resources-angular';

@Component({
  selector: 'ssi-orlo-crm-person',
  templateUrl: './orlo-crm-person.component.html',
    styleUrls: ['./orlo-crm-person.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrloCrmPersonComponent implements OnInit {
  @Input() activities: [];
  @Input() person: CrmPerson;
  @Input() selectedPersonId: string;
  @Input() linkedPersonId: string;
  @Input() close;

  initialisedPlugins;
  pluginMessageActions = [];
  selectedPerson;
  linkedPerson;

  constructor(
    private plugin: PluginService,
    public modalInstance: NgbActiveModal
  ) {}

  async ngOnInit() {
    this.initialisedPlugins = this.plugin.getInitialisedPlugins();

    this.selectedPerson = await this.plugin
      .getCrmPerson(this.selectedPersonId)
      .then(({ data }) => data);
    this.linkedPerson = await this.plugin
      .getCrmPerson(this.linkedPersonId)
      .then(({ data }) => data);

    Object.keys(this.initialisedPlugins).forEach((pluginId) => {
      if (
        !(
          !!this.initialisedPlugins[pluginId].actions &&
          Array.isArray(this.initialisedPlugins[pluginId].actions)
        )
      ) {
        return;
      }

      const messageType = this.initialisedPlugins[pluginId].actions.find(
        (action) => action.type === 'message'
      );

      messageType.pluginId = pluginId;
      this.pluginMessageActions.push(messageType);
    });
  }

  assignIconByNetwork(id) {
    switch (id) {
      case 2:
        return 'ssi ssi-x-logo';
        break;
      case 3:
        return 'fa fa-facebook';
        break;
      case 4:
        return 'fa fa-link';
        break;
      case 6:
        return 'fa fa-youtube-play';
        break;
      case 11:
        return 'ssi ssi-headphones';
        break;
      case 12:
        return 'fa fa-instagram';
        break;
      default:
        break;
    }
  }

  returnOption(option) {
    this.modalInstance.close(option);
  }

  cancel() {
    this.modalInstance.close(null);
  }
}
