import { ViewEncapsulation } from '@angular/core';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-top-urls',
  templateUrl: './top-urls.component.html',
  styles: [],
  styleUrls: ['./top-urls.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopUrlsComponent {
  @Input() topURLs: { url: string; visits: number }[];
  @Input() countLabel?: string = 'Total visits';

  constructor() {}
}
