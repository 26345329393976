import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  OnInit,
  HostListener,
  ViewChild
} from '@angular/core';
import { API, Account, Outbox, User } from '@ui-resources-angular';
import {
  colleagues,
  authUser,
  workflowAccounts
} from '../../../common-resolves';
import { Team, Colleague } from '../../../../common/services/api';
import { OutboxQueryFactoryService } from '../../../../common/services/outbox-query-factory/outbox-query-factory.service';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  INBOX_REPLY_PRIVATE_TYPES,
  INBOX_REPLY_PUBLIC_TYPES,
  INBOX_REPLY_ALL_TYPES
} from '../../../../common/constants';
import { ValidatePostsModalComponent } from '../validate-posts/validate-posts-modal/validate-posts-modal.component';
import { ValidateRepliesFiltersComponent } from './validate-replies-filters/validate-replies-filters.component';
import { OutboxValidationStatus } from './validate-reply-box/validate-reply-box.component';

const POSTS_PER_PAGE = 15;

export async function repliesFn(
  workflowAccountsParam,
  authUserParam,
  outboxQueryFactory
) {
  const outboxQuery = outboxQueryFactory.create({
    allAccounts: workflowAccountsParam,
    authUserParam
  });

  outboxQuery.filters.forEach((filter) => {
    if (filter.key === 'types') {
      Object.defineProperty(filter, 'defaultValue', {
        get() {
          return INBOX_REPLY_ALL_TYPES.map((type) => type.value);
        }
      });
      filter.isDefault = (val) => {
        return val.length === INBOX_REPLY_ALL_TYPES.length;
      };
    }
  });
  outboxQuery.reset(); // required because the default message types filter has changed
  outboxQuery.params.validated = '0';
  outboxQuery.params.deleted = 'exclude';
  outboxQuery.isValidationSearch = true;
  outboxQuery.params.types = INBOX_REPLY_ALL_TYPES.map((t) => t.value);
  outboxQuery.filters = outboxQuery.filters.filter(
    (filter) => !['validated', 'deleted'].includes(filter.key)
  );
  await outboxQuery.search(0, POSTS_PER_PAGE);
  return {
    posts: outboxQuery.outboxPosts,
    total: outboxQuery.interactionTotals.total
  };
}

@Component({
  selector: 'ssi-marketing-validate-replies',
  templateUrl: './validate-replies.component.html',
  styleUrls: ['./validate-replies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidateRepliesComponent implements OnInit {
  static resolve = [
    colleagues,
    workflowAccounts,
    authUser,
    {
      token: 'outboxQuery',
      resolveFn: repliesFn,
      deps: ['workflowAccounts', 'authUser', OutboxQueryFactoryService]
    }
  ];
  validations: Outbox[];
  selected = [];
  selectMode = false;
  lastSelected = null;
  isLoading = false;

  @Input() outboxQuery: any;
  @Input() workflowAccounts: Account[];
  @Input() colleagues: Colleague[];
  @Input() authUser: User;
  @ViewChild('filterComponent')
  filterComponent: ValidateRepliesFiltersComponent;
  posts = [];
  pages = [];
  postPerPage = POSTS_PER_PAGE;

  privateReplyTypes = INBOX_REPLY_PRIVATE_TYPES;
  publicReplyTypes = INBOX_REPLY_PUBLIC_TYPES;

  constructor(
    private state: StateService,
    private modal: NgbModal,
    private api: API
  ) {}

  async ngOnInit() {
    this.posts = this.outboxQuery.posts;
    this.updatePagination(this.outboxQuery.total);
    this.getValidations();
  }

  @HostListener('document:keydown.shift')
  handleKeydownEvent() {
    this.selectMode = true;
  }

  @HostListener('document:keyup')
  handleKeyupEvent() {
    this.selectMode = false;
  }

  updatePagination(totalPosts) {
    const pageCount = Math.ceil(totalPosts / POSTS_PER_PAGE);
    this.pages = new Array(pageCount).fill(null).map((page, index) => {
      return { number: index + 1, active: index === 0 };
    });
  }

  changePage(page) {
    if (page.active) {
      return;
    }
    this.isLoading = true;
    this.pages.find(($page) => $page.active).active = false;
    this.pages.find(($page) => $page.number === page.number).active = true;
    this.filterComponent.updateValidations(page.number);
  }

  getValidations() {
    try {
      if (!(!!this.posts && Array.isArray(this.posts))) {
        throw new Error(
          `Value for 'validate posts box posts' not in expected format.`
        );
      }

      this.validations = this.posts;
      this.validations.forEach((v) => {
        v.visibility =
          INBOX_REPLY_PRIVATE_TYPES.filter((t) => t.value === v.type).length > 0
            ? 'private'
            : 'public';

        v.inboxThreadLink = `${v.account_id}_${v.in_reply_to_social_id}`;
      });
      console.log('Inbox validations:', this.validations);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  public postValidated(post) {
    this.validations = this.validations.filter(
      (validation) => validation.id !== post.id
    );
  }

  addToSelected(validation) {
    if (this.selected.includes(validation)) {
      this.selected = this.selected.filter((id) => id !== validation);
    } else {
      this.selected.push(validation);
    }
  }

  selectAll() {
    this.selected = this.validations.filter(
      (validation) => !validation.is_validated && validation
    );
  }

  selectValidation(event, validation) {
    if (!event.shiftKey) {
      return;
    }
    if (this.selected.includes(validation)) {
      this.selected = this.selected.filter(
        (selectedValidation) => selectedValidation.id !== validation.id
      );
      this.lastSelected = null;
    } else {
      const validationIndex = this.validations.indexOf(validation);

      if (this.lastSelected !== null) {
        const currentSelection =
          this.lastSelected > validationIndex
            ? this.validations.slice(validationIndex, this.lastSelected)
            : this.validations.slice(
                this.lastSelected + 1,
                validationIndex + 1
              );
        const duplicatedSelection = this.selected.filter((selectedValidation) =>
          currentSelection
            .map((currentValidation) => currentValidation.id)
            .includes(selectedValidation.id)
        );
        const uniqueValidationsSelected = currentSelection.filter(
          (val) => !duplicatedSelection.map((v) => v.id).includes(val.id)
        );
        this.selected.push(...uniqueValidationsSelected);
      } else {
        this.selected.push(validation);
      }
      this.lastSelected = validationIndex;
    }
  }

  refresh() {
    this.state.go('auth.marketing.validateReplies', {}, { reload: true });
  }

  async validateSelected(approved: boolean) {
    let result;

    if (approved) {
      const modal = this.modal.open(ValidatePostsModalComponent, {
        windowClass: 'validate-posts-modal'
      });
      modal.componentInstance.modalConfig = {
        icon: `ssi ssi-validations-lightbox`,
        title: `${this.selected.length} Validations selected`,
        meta: 'Are you happy with these posts?',
        cancelButton: 'Cancel Selection',
        okButton: 'Yes approve'
      };
      result = await modal.result;
    }

    if (result !== 'cancel') {
      this.selected.forEach((item) => this.validatePost(approved, item));
    }
    this.selected = [];
  }

  public async validatePost(approved, validation) {
    const validateRequest = {
      id: validation.id,
      account_id: validation.account_id,
      approved,
      seq: validation.seq,
      delete: false
    };

    if (approved) {
      validateRequest['validator_note'] = '';
      validateRequest['email_only'] = false;
    }

    await this.api.post('outbox_v2/validation', validateRequest).then((res) => {
      if (res.status === 200) {
        this.validations.forEach((item) => {
          if (item.id === validation.id) {
            item['validated'] = approved
              ? OutboxValidationStatus.Approved
              : OutboxValidationStatus.Disapproved;
          }
        });
      }
    });
  }
}
