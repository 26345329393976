import { ViewEncapsulation } from '@angular/core';


import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash-es';

import { Account, AccountModel } from '@ui-resources-angular';
import { groupBy } from '../../../../../common/utils';
import {
  MonitoringStream,
  MonitoringStreamsService,
  ThemeDetector,
  ThemeDetectorFormValue,
  ThemeDetectorsService
} from '../../../../../common/services/api';
import { WorkflowManagerService } from '../../../../../common/services/workflow-manager/workflow-manager.service';
import { NotificationService } from '../../../../../common/services/notification/notification.service';
import { ConfirmationModalComponent } from '../../../../../common/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'ssi-theme-detector-create-edit',
  templateUrl: './theme-detector-create-edit.component.html',
  styles: [],
  styleUrls: ['./theme-detector-create-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeDetectorCreateEditComponent implements OnInit, OnChanges {
  @Input() themeDetector: ThemeDetector; // if present then it's edit mode

  @Output() formSubmit = new EventEmitter<ThemeDetectorFormValue>();
  @Output() delete = new EventEmitter<ThemeDetector>();

  @ViewChild('form') form: NgForm;

  accounts: Account[] = [];
  selectedAccountTypeIds: string[] = [];

  streams: MonitoringStream[] = [];

  model: ThemeDetectorFormValue;
  submittedModel: ThemeDetectorFormValue;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected modal: NgbModal,
    protected accountModel: AccountModel,
    protected workflowManager: WorkflowManagerService,
    protected monitoringStreamsService: MonitoringStreamsService,
    protected themeDetectorsService: ThemeDetectorsService,
    protected notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['themeDetector']) {
      this.model = this.themeDetector
        ? this.themeDetector.toFormValue()
        : this.initModel();
      this.submittedModel = JSON.parse(JSON.stringify(this.model));
    }
  }

  async ngOnInit() {
    const [accounts, streams] = await Promise.all([
      // this.accountModel.findAccounts(this.workflowManager.getCurrentId()),
      this.accountModel.findAccounts(undefined, { bypassPermissions: true }),
      this.monitoringStreamsService.getAll()
    ]);

    this.streams = streams;

    this.accounts = orderBy(accounts, ['account_type_name', 'name']);

    // needed here as well (beside OnCganges) since it cannot re-populate (edit mode) correctly if accounts and streams aren't set first
    this.model = this.themeDetector
      ? this.themeDetector.toFormValue()
      : this.initModel();
    this.submittedModel = JSON.parse(JSON.stringify(this.model));
  }

  initModel(): ThemeDetectorFormValue {
    return {
      minClusterSize: 10, // default value for number type input is null (at least for Chrome)
      queryWindowHours: 1,
      lifetimeHours: 168,
      accounts: [],
      streams: [],
      notificationEmails: ''
    };
  }

  canSubmit(): boolean {
    return (
      this.form.valid &&
      JSON.stringify(this.model) !== JSON.stringify(this.submittedModel)
    );
  }

  submit(form: NgForm): void {
    setTimeout(async () => {
      // timeout - wait for model changes to be applied to the form
      // console.log('form: ', form);

      if (
        this.themeDetector &&
        !(await this.confirmEditOrDelete(this.themeDetector, true))
      ) {
        return;
      }

      const themeDetectorToCreateOrEdit = ThemeDetector.fromFormValue(
        form.value
      );
      if (this.themeDetector) {
        themeDetectorToCreateOrEdit.id = this.themeDetector.id;
        // this will fire subscription to the detectedThemes store
        await this.themeDetectorsService.update(themeDetectorToCreateOrEdit);
      } else {
        // this will fire subscription to the detectedThemes store
        await this.themeDetectorsService.create(themeDetectorToCreateOrEdit);
      }

      this.createOrUpdateSnackbarSuccess(this.themeDetector);
      this.submittedModel = JSON.parse(JSON.stringify(this.model));
    });
  }

  async deleteDetector(): Promise<void> {
    if (!this.themeDetector) {
      return;
    }

    if (!(await this.confirmEditOrDelete(this.themeDetector))) {
      return;
    }

    // this will fire subscription to the detectedThemes store
    await this.themeDetectorsService.delete(this.themeDetector.id);
  }

  cancel(): void {
    this.model = this.themeDetector
      ? this.themeDetector.toFormValue()
      : this.initModel();
    this.submittedModel = JSON.parse(JSON.stringify(this.model));
  }

  onMinClusterSizeInput(value: number | null): void {}

  onQueryWindowHoursInput(value: number | null): void {}

  onLifetimeHoursInput(value: number | null): void {}

  onNotificationEmailsInput(value: string): void {}

  onSelectedStreamsChange(selectedStreams: MonitoringStream[]): void {}

  onSelectedAccountsChange(selectedAccounts: Account[]): void {
    this.model.accounts = selectedAccounts;
    this.setSelectedAccountTypeIds();
  }

  setSelectedAccountTypeIds(): void {
    this.selectedAccountTypeIds = [];

    const accountGroups = groupBy(
      this.model.accounts,
      (account) => account.account_type_id
    );

    accountGroups.forEach((accountGroup) => {
      this.selectedAccountTypeIds.push(accountGroup[0].account_type_id);
    });
  }

  confirmEditOrDelete(
    themeDetector: ThemeDetector,
    edit = false
  ): Promise<boolean> {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    // confirmationModal.componentInstance.negativeConfirmation = true;
    // confirmationModal.componentInstance.icon = 'ssi ssi-resolve-convo';
    confirmationModal.componentInstance.title = `Are you sure?`;
    confirmationModal.componentInstance.info = edit
      ? 'Newly detected themes from this edit will be added to the list below'
      : 'This will delete any previously detected themes listed blow';
    confirmationModal.componentInstance.cancelButton = `Cancel`;
    confirmationModal.componentInstance.confirmButton = edit
      ? `Edit alert`
      : `Delete alert`;

    return confirmationModal.result;
  }

  createOrUpdateSnackbarSuccess(themeDetector: ThemeDetector): void {
    this.notificationService.open(
      themeDetector
        ? `Theme detector has been updated`
        : `Theme detector has been created`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      2000,
      true,
      true
    );
  }
}
