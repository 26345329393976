import { ViewEncapsulation } from '@angular/core';


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { inboxPriorities } from '../../../../common/constants';

@Component({
  selector: 'ssi-inbox-priority-select',
  templateUrl: './inbox-priority-select.component.html',
  styles: [],
  styleUrls: ['./inbox-priority-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxPrioritySelectComponent implements OnInit {
  @Output() prioritySelect = new EventEmitter<string>();
  @Input() selectedPriority: string;
  @Input() showSingleValue: boolean;

  priorities: string[] = inboxPriorities.map((p) => String(p));

  constructor() {}

  ngOnInit() {}

  select(priority: string): void {
    priority === this.selectedPriority
      ? this.prioritySelect.emit(null)
      : this.prioritySelect.emit(priority);
  }
}
