import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ChatBot,
  Metabase
} from '../../../../../../common/services/chat-bot/chat-bot.service';

@Component({
  selector: 'ssi-chat-bot-modal',
  templateUrl: './chat-bot-modal.component.html',
  styles: [],
  styleUrls: ['./chat-bot-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatBotModalComponent implements OnInit {
  @Input() metabases: Metabase[];
  @Input() chatBot: ChatBot;
  chatBotForm: ChatBot = {
    name: '',
    metabase_id: '',
    messages: {
      welcome: {
        on_enter: [{ text: 'Welcome, how can I help?' }],
        no_topic_match: [{ text: 'Sorry, I dont know how to help you.' }]
      },
      another_question: {
        on_enter: [{ text: 'How can I help?' }],
        no_topic_match: [{ text: 'Sorry, I dont know how to help you.' }]
      },
      did_that_help: {
        on_enter: [{ text: 'Does that help?' }]
      },
      talk_to_agent: {
        on_enter: [{ text: 'Do you want to talk to a human?' }]
      },
      anything_else: {
        on_enter: [{ text: 'Anything else?' }]
      },
      connecting_to_agent: {
        on_enter: [{ text: 'Ok, connecting you to an agent...' }]
      },
      done: {
        on_enter: [{ text: 'Ok, bye!' }]
      }
    }
  };
  messageFields: string[] = [];
  messageLabels = {
    welcome: 'Welcome',
    another_question: 'Another Question',
    did_that_help: 'Did that help',
    talk_to_agent: 'Talk to Agent',
    anything_else: 'Anything Else',
    connecting_to_agent: 'Connecting to Agent',
    done: 'Done'
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.messageFields = Object.keys(this.chatBotForm.messages);
    if (this.chatBot) {
      this.chatBotForm.name = this.chatBot.name;
      this.chatBotForm.metabase_id = this.chatBot.metabase_id;
    }
  }
}
