import { ViewEncapsulation } from '@angular/core';


import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';

import { mapToIterable } from '../../../../../../common/utils';
import {
  Colleague,
  ColleaguesService
} from '../../../../../../common/services/api/colleagues';
import {
  SurveyConfig,
  SurveyReport,
  SurveyService,
  SurveyType
} from '../../../../../../common/services/api/surveys';

export interface SurveyTypeOption {
  key: SurveyType;
  label: string;
}

export const surveyTypes: { [type in SurveyType]: SurveyTypeOption } = {
  [SurveyType.NPS]: {
    key: SurveyType.NPS,
    label: 'NPS'
  },
  [SurveyType.CSAT]: {
    key: SurveyType.CSAT,
    label: 'CSAT'
  }
};

export interface FiltersFormValue {
  startDate: Date;
  endDate: Date;
  surveyType: SurveyTypeOption;
  colleagues: Colleague[];
  minScore?: number;
  maxScore?: number;
  surveyee?: string;
  comment?: string;
}

@Component({
  selector: 'ssi-filters',
  templateUrl: './filters.component.html',
  styles: [],
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() report: SurveyReport;
  @Input() startDate: Date;
  @Input() endDate: Date;

  @Output() formSubmit = new EventEmitter<FiltersFormValue>();

  @ViewChild('form') form: NgForm;
  model: FiltersFormValue;
  submittedModel: FiltersFormValue;

  surveyConfig: SurveyConfig;
  colleagues: Colleague[] = [];
  surveyTypes = surveyTypes;
  surveyTypesIterable: SurveyTypeOption[] = mapToIterable(surveyTypes);
  today: Date = moment().endOf('day').toDate();
  avgScore: number;
  avgScoreSurveyTypeLabel: string;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected surveyService: SurveyService,
    protected colleaguesService: ColleaguesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.model &&
      changes['startDate'] &&
      changes['startDate'].currentValue
    ) {
      this.model.startDate = changes['startDate'].currentValue;
    }

    if (this.model && changes['endDate'] && changes['endDate'].currentValue) {
      this.model.endDate = changes['endDate'].currentValue;
    }

    if (changes['report'] && changes['report'].currentValue) {
      this.setAvgScore();
    }
  }

  async ngOnInit() {
    this.model = this.initModel(); // just so it has some values when rendering starts
    this.colleagues = await this.colleaguesService.getAllActive();
    this.surveyConfig = await this.surveyService.getConfig();
    this.model = this.initModel();
    this.submit(this.form);
  }

  initModel(): FiltersFormValue {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      surveyee: '', // default value for text type input (at least for Chrome)
      comment: '',
      minScore: null, // default value for number type input (at least for Chrome)
      maxScore: null,
      colleagues: [],
      surveyType: this.surveyConfig
        ? this.surveyTypes[this.surveyConfig.company.type]
        : undefined
    };
  }

  getColleagueOptionLabel(colleague: Colleague): string {
    return colleague.fullName;
  }

  setAvgScore(): void {
    if (!this.report || !this.report.aggregation) {
      return;
    }

    if (this.model.surveyType === this.surveyTypes.csat) {
      this.avgScore = Number(this.report.aggregation.avg_score.toFixed(1));
    } else {
      this.avgScore = Math.round(this.report.aggregation.avg_score);
    }

    this.avgScoreSurveyTypeLabel = this.model.surveyType.label;
  }

  onSurveyTypeChange(selectedSurveyType: SurveyTypeOption): void {}

  onColleaguesChange(selectedColleagues: Colleague[]): void {}

  onSurveyeeInput(value: string): void {}

  onCommentInput(value: string): void {}

  onMinScoreInput(value: number): void {}

  onMaxScoreInput(value: number): void {}

  onFlatpickrOpen(): void {}

  onStartDatePicked(startDate: Date): void {}

  onEndDatePicked(endDate: Date): void {}

  hasActiveFilters(): boolean {
    return JSON.stringify(this.model) !== JSON.stringify(this.initModel());
  }

  canSubmit(): boolean {
    return JSON.stringify(this.model) !== JSON.stringify(this.submittedModel);
  }

  clearFilters(): void {
    // this.form.reset();
    this.model = this.initModel();
    if (this.canSubmit()) {
      this.submit(this.form);
    }
  }

  submit(form: NgForm): void {
    setTimeout(() => {
      // timeout - wait for model changes to be applied to the form
      this.formSubmit.emit(this.form.value);
      this.submittedModel = JSON.parse(JSON.stringify(this.model));
    });
  }
}
