import { ViewEncapsulation } from '@angular/core';

import { Component, OnInit, Input, Inject, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API } from '@ui-resources-angular';
import { EnlargeImageModalComponent } from '../enlarge-image-modal/enlarge-image-modal.component';

@Component({
  selector: 'ssi-social-post',
  templateUrl: './social-post.component.html',
  styleUrls: ['./social-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SocialPostComponent implements OnInit {
  @Input() post;
  @Input() author;
  showRetweetDropdown = false;

  constructor(
    private api: API,
    private injector: Injector,
    private modal: NgbModal
  ) {}

  ngOnInit() {}

  async socialAction(action: string) {
    this.showRetweetDropdown = false;
    return await this.api
      .post('social/' + action, {
        account_id: this.author.account.id,
        social_id: this.post.id
      })
      .then(({ data }) => {
        if (data.success) {
          if (action === 'like') {
            this.post.have_liked = true;
            this.post.like_count = this.post.like_count + 1;
          } else if (action === 'share') {
            this.post.have_shared = true;
            this.post.share_count = this.post.share_count + 1;
          }
        }
        return data;
      });
  }

  enlargeImage() {
    const modal = this.modal.open(EnlargeImageModalComponent, {
      windowClass: 'orlo-modal',
      // windowClass: 'modal-rounded modal-backdrop-blue modal-border-none',
      size: 'lg',
      backdrop: 'static'
    });

    modal.componentInstance.imageSrc = this.post.image_url;
  }
}
