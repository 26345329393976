import { Record } from 'js-data';
import { services } from '../../common';
import { Model } from '../../model';
import * as differenceInDays from 'date-fns/difference_in_days';
import * as startOfDay from 'date-fns/start_of_day';

export class Campaign extends Record {
  id: number;
  created_at: string;
  started_at: string;
  closed_at: string;
  deleted_at: string;
  is_closed: boolean;
  is_deleted: boolean;
  is_started: boolean;
  name: string;
  parent_id: number;

  get age_days() {
    const start = this.started_at || this.created_at;
    const end = this.is_closed ? this.closed_at : new Date();
    const ageDays = differenceInDays(startOfDay(end), startOfDay(start));
    if (ageDays < 0) {
      return;
    }
    return ageDays;
  }

  set age_days(val) {}

  getChildren() {
    return services.models.get<CampaignModel>('campaign').filter({
      where: { parent_id: this.id }
    });
  }

  getParent() {
    return this.parent_id
      ? services.models.get<CampaignModel>('campaign').get(this.parent_id)
      : null;
  }
}

export class CampaignModel extends Model<Campaign> {
  constructor() {
    super('campaign', {
      endpoint: 'campaign/index_v2',
      recordClass: Campaign
    });
  }
}

export function campaignModelFactory(dataStore?) {
  return services.models.get('campaign') || new CampaignModel();
}
