import { ViewEncapsulation } from '@angular/core';


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Note, NoteModel, User, UserModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-note',
  templateUrl: './note.component.html',
  styles: [],
  styleUrls: ['./note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoteComponent implements OnInit {
  @Input() note: Note;
  @Output() deleteNote = new EventEmitter<void>();

  authUser: User;
  createdByYou = false;

  constructor(protected userModel: UserModel) {}

  ngOnInit() {
    this.userModel.getAuthUser().then((user) => {
      this.authUser = user;
      this.createdByYou = this.note.created_by === this.authUser.id;
    });
  }
}
