import { ViewEncapsulation } from '@angular/core';


import { Component, OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';

import { Account, AccountModel } from '@ui-resources-angular';

@Component({
  selector: 'ssi-cl-button',
  templateUrl: './cl-button.component.html',
  styles: [],
  styleUrls: ['./cl-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClButtonComponent implements OnInit {
  disabled = false;

  constructor() {}

  ngOnInit() {}
}
