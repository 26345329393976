<ssi-advertising-nav></ssi-advertising-nav>

<ssi-advertising-table-filters
  (filterAccount)="filterAccount($event)"
  [activeSocialFilter]="activeSocialFilter"
  [sortEnabled]="false">
</ssi-advertising-table-filters>

<div class="preset-wrap">
  <div *ngIf="advertisingTargetingPresets.length === 0" class="presets-none">
    <i class="ssi ssi-workflow-microcopy presets-none-icon"></i>
    <h2 class="presets-none-title">Why not speed up your workflow?</h2>
    <p class="presets-none-meta">Create an awesome range of target audiences your whole team can use or that you can re-use for all related campaign material</p>
    <a class="btn btn-primary btn-style-2" uiSref="^.createPreset">
      <i class="ssi ssi-plus"></i>
      Create target audience
    </a>
  </div>

  <ul class="presets list-unstyled" *ngIf="advertisingTargetingPresets.length > 0">
    <li class="preset preset-create">
      <p class="preset-title">Create target audience</p>
      <a class="btn btn-default btn-preset" uiSref="^.createPreset">
        <i class="ssi ssi-plus text-primary"></i>
      </a>
    </li>
    <li
      *ngFor="let preset of advertisingTargetingPresets; trackBy:trackById"
      [ssiTooltip]
      [tooltipTemplate]="presetTooltipTemplate"
      [tooltipAppendToBody]="true"
      [tooltipTemplateContext]="{preset:preset}"
      tooltipWindowClass="tooltip-preset"
      class="preset">
      <h5 class="preset-title">
        <i class="preset-title-icon" [ngClass]="preset.account.socialNetwork.icon.web"></i>
        <p>{{preset.name}}</p>
      </h5>
      <a class="btn btn-default btn-preset" uiSref="^.editPreset" [uiParams]="{presetId: preset.id}">
        <i class="ssi ssi-edit text-primary"></i>
      </a>
      <button class="btn btn-default btn-preset" (click)="deletePreset(preset)">
        <i class="ssi ssi-delete text-danger"></i>
      </button>
    </li>
  </ul>
  
  <span *ngIf="advertisingTargetingPresets.length" class="preset-count">
    {{advertisingTargetingPresets.length}}
    Total saved target audiences
  </span>
</div>

<ng-template #presetTooltipTemplate let-preset="preset">
  <i class="tooltip-preset-icon" [ngClass]="preset.account.socialNetwork.icon.web"></i>
  <p class="tooltip-preset-title">{{preset.name}}</p>
  <!-- <p class="tooltip-preset-meta">Estimated Audience Range</p> -->
  
  <div class="tooltip-preset-tags-wrap" *ngFor="let target of currentPresetTargets; trackBy: presetList(preset)">
    <ul class="tooltip-preset-tags">
      <li *ngFor="let item of target.value; let lastItem = last" class="tooltip-preset-tag">
        {{item}}<span *ngIf="!lastItem">, </span>
      </li>
    </ul>
  </div>
  <div data-popper-arrow class="tooltip-arrow"></div>
</ng-template>