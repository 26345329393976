export enum AccountTypeId {
  Company = 1,
  Twitter = 2,
  Facebook = 3,
  LinkedIn = 4,
  Google = 5, // dead
  YouTube = 6,
  InstagramOld = 7, // dead
  LinkedInAds = 8,
  FacebookAds = 9,
  TwitterAds = 10,
  LiveChat = 11,
  Instagram = 12,
  TwilioSMS = 13,
  TwilioWhatsApp = 14,
  FacebookGroup = 15,
  TikTok = 16,
  ReviewTrackers = 17,
  Nextdoor = 18,
  TikTokBusiness = 19,
  NextdoorUS = 20,
  Threads = 21,
  Bluesky = 22,
  LinkedInPersonal = 23
}

export enum AccountTypeIdString {
  Company = '1',
  Twitter = '2',
  Facebook = '3',
  LinkedIn = '4',
  Google = '5', // dead
  YouTube = '6',
  InstagramOld = '7', // dead
  LinkedInAds = '8',
  FacebookAds = '9',
  TwitterAds = '10',
  LiveChat = '11',
  Instagram = '12',
  TwilioSMS = '13',
  TwilioWhatsApp = '14',
  FacebookGroup = '15',
  TikTok = '16',
  ReviewTrackers = '17',
  Nextdoor = '18',
  TikTokBusiness = '19',
  NextdoorUS = '20',
  Threads = '21',
  Bluesky = '22',
  LinkedInPersonal = '23'
}

export enum AccountTypeName {
  Company = 'Company',
  Facebook = 'Facebook',
  FacebookAds = 'Facebook Ads',
  FacebookGroup = 'Facebook Group',
  GooglePlus = 'Google+', // dead
  Instagram = 'Instagram', // dead
  InstagramBusiness = 'Instagram Business',
  LinkedIn = 'LinkedIn',
  LinkedInAds = 'LinkedIn Ads',
  TwilioSMS = 'Twilio SMS',
  TwilioWhatsApp = 'Twilio WhatsApp',
  Twitter = 'Twitter',
  TwitterAds = 'Twitter Ads',
  YouTube = 'YouTube',
  LiveChat = 'Live Chat',
  TikTok = 'TikTok',
  ReviewTrackers = 'Review Trackers',
  Nextdoor = 'Nextdoor Agency',
  X = 'X',
  TiktokBusiness = 'TikTok Business',
  NextdoorUS = 'Nextdoor Agency US',
  Threads = 'Threads',
  Bluesky = 'Bluesky',
  LinkedInPersonal = 'LinkedIn Personal'
}

export const AccountTypeIdStringMap = {
  [AccountTypeId.Company]: AccountTypeIdString.Company,
  [AccountTypeId.Twitter]: AccountTypeIdString.Twitter,
  [AccountTypeId.Facebook]: AccountTypeIdString.Facebook,
  [AccountTypeId.LinkedIn]: AccountTypeIdString.LinkedIn,
  [AccountTypeId.Google]: AccountTypeIdString.Google, // dead
  [AccountTypeId.YouTube]: AccountTypeIdString.YouTube,
  [AccountTypeId.InstagramOld]: AccountTypeIdString.InstagramOld, // dead
  [AccountTypeId.LinkedInAds]: AccountTypeIdString.LinkedInAds,
  [AccountTypeId.FacebookAds]: AccountTypeIdString.FacebookAds,
  [AccountTypeId.TwitterAds]: AccountTypeIdString.TwitterAds,
  [AccountTypeId.LiveChat]: AccountTypeIdString.LiveChat,
  [AccountTypeId.Instagram]: AccountTypeIdString.Instagram,
  [AccountTypeId.TwilioSMS]: AccountTypeIdString.TwilioSMS,
  [AccountTypeId.TwilioWhatsApp]: AccountTypeIdString.TwilioWhatsApp,
  [AccountTypeId.FacebookGroup]: AccountTypeIdString.FacebookGroup,
  [AccountTypeId.TikTok]: AccountTypeIdString.TikTok,
  [AccountTypeId.ReviewTrackers]: AccountTypeIdString.ReviewTrackers,
  [AccountTypeId.Nextdoor]: AccountTypeIdString.Nextdoor,
  [AccountTypeId.TikTokBusiness]: AccountTypeIdString.TikTokBusiness,
  [AccountTypeId.NextdoorUS]: AccountTypeIdString.NextdoorUS,
  [AccountTypeId.Threads]: AccountTypeIdString.Threads,
  [AccountTypeId.Bluesky]: AccountTypeIdString.Bluesky,
  [AccountTypeId.LinkedInPersonal]: AccountTypeIdString.LinkedInPersonal
};

export const AccountTypeIdNameMap = {
  [AccountTypeId.Company]: AccountTypeName.Company,
  [AccountTypeId.Twitter]: AccountTypeName.Twitter,
  [AccountTypeId.Facebook]: AccountTypeName.Facebook,
  [AccountTypeId.LinkedIn]: AccountTypeName.LinkedIn,
  [AccountTypeId.Google]: AccountTypeName.GooglePlus, // dead
  [AccountTypeId.YouTube]: AccountTypeName.YouTube,
  [AccountTypeId.InstagramOld]: AccountTypeName.Instagram, // dead
  [AccountTypeId.LinkedInAds]: AccountTypeName.LinkedInAds,
  [AccountTypeId.FacebookAds]: AccountTypeName.FacebookAds,
  [AccountTypeId.TwitterAds]: AccountTypeName.TwitterAds,
  [AccountTypeId.LiveChat]: AccountTypeName.LiveChat,
  [AccountTypeId.Instagram]: AccountTypeName.InstagramBusiness,
  [AccountTypeId.TwilioSMS]: AccountTypeName.TwilioSMS,
  [AccountTypeId.TwilioWhatsApp]: AccountTypeName.TwilioWhatsApp,
  [AccountTypeId.FacebookGroup]: AccountTypeName.FacebookGroup,
  [AccountTypeId.TikTok]: AccountTypeName.TikTok,
  [AccountTypeId.ReviewTrackers]: AccountTypeName.ReviewTrackers,
  [AccountTypeId.Nextdoor]: AccountTypeName.Nextdoor,
  [AccountTypeId.TikTokBusiness]: AccountTypeName.TiktokBusiness,
  [AccountTypeId.NextdoorUS]: AccountTypeName.NextdoorUS,
  [AccountTypeId.Threads]: AccountTypeName.Threads,
  [AccountTypeId.Bluesky]: AccountTypeName.Bluesky,
  [AccountTypeId.LinkedInPersonal]: AccountTypeName.LinkedInPersonal
};

export const AccountTypeIdLabelMap = {
  [AccountTypeId.Company]: 'Company',
  [AccountTypeId.Twitter]: 'X',
  [AccountTypeId.Facebook]: 'Facebook',
  [AccountTypeId.LinkedIn]: 'LinkedIn',
  [AccountTypeId.Google]: 'Google', // dead
  [AccountTypeId.YouTube]: 'Youtube',
  [AccountTypeId.InstagramOld]: 'Instagram', // dead
  [AccountTypeId.LinkedInAds]: 'LinkedIn Ads',
  [AccountTypeId.FacebookAds]: 'Facebook Ads',
  [AccountTypeId.TwitterAds]: 'Twitter Ads',
  [AccountTypeId.LiveChat]: 'Live Chat',
  [AccountTypeId.Instagram]: 'Instagram',
  [AccountTypeId.TwilioSMS]: 'SMS',
  [AccountTypeId.TwilioWhatsApp]: 'WhatsApp',
  [AccountTypeId.FacebookGroup]: 'Facebook Group',
  [AccountTypeId.TikTok]: 'TikTok',
  [AccountTypeId.ReviewTrackers]: 'Review Trackers',
  [AccountTypeId.Nextdoor]: 'Nextdoor',
  [AccountTypeId.TikTokBusiness]: 'TikTok Business',
  [AccountTypeId.NextdoorUS]: 'Nextdoor US',
  [AccountTypeId.Threads]: 'Threads',
  [AccountTypeId.Bluesky]: 'Bluesky',
  [AccountTypeId.LinkedInPersonal]: 'Linkedin Personal'
};

export function getAccountTypeIdFromName(
  name: AccountTypeName | string
): string {
  return Object.keys(AccountTypeIdNameMap).find((accountTypeId) => {
    return AccountTypeIdNameMap[accountTypeId] === name;
  });
}
