import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-progress-bar',
  templateUrl: './progress-bar.component.html',
  styles: [],
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent implements OnInit {
  @Input() color = '#14bae3';
  @Input() bgColor = '#f4f4fa';
  @Input() radius = 10; // px
  @Input() thickness = 3; // px
  @Input() reversed = false;

  @Input() max = 100;
  @Input() progress = 0;

  constructor() {}

  ngOnInit(): void {}

  get progressPct(): number {
    const pct = (this.progress / this.max) * 100;
    if (pct > 100) {
      // console.error('Progress cannot be greater then max!');
      return 100;
    }

    return pct;
  }
}
