<div class="audit-container">
	<i class="close-icon ssi ssi-times"
	   (click)="activeModal.dismiss()"></i>

	<div class="head">
		<div class="head-row"
			 [class.g-box-shadow-bottom]="!filterActionsOpen">
			<h3>{{(activity ? "INBOX_AUDIT" : "OUTBOX_AUDIT") | translate}}</h3>
			<div class="head-action"
				 *ngIf="showHeadActions"
				 (click)="downloadXlsx()">
				<i class="ssi ssi-download"></i>
				<span>Download XLSX</span>
			</div>
			<!-- <div class="head-action"
				 *ngIf="showHeadActions"
				 (click)="toggleFilterActions()">
				<i class="ssi ssi-filters-new filter-icon"></i>
				<span>Filter actions</span>
			</div> -->
		</div>

		<div class="head-row actions-row g-box-shadow-bottom"
			 *ngIf="filterActionsOpen"
			 [@slideIn]
			 (@slideIn.done)="onSlideInAnimationDone()">
			actions....
		</div>
	</div>

	<div class="body"
		 ssiLoader
		 [ssiLoaderVisible]="loading">
		<div class="audit-events">
			<ssi-audit-event *ngFor="let event of auditEventsToRender; let last = last;"
							 [auditEvent]="event"
							 [last]="last"></ssi-audit-event>
		</div>

		<div class="load-more-btn-container">
			<button *ngIf="this.auditEventsResponse?.pagination.next"
					type="button"
					class="btn load-more-btn"
					[disabled]="loading || !this.auditEventsResponse?.pagination.next"
					(click)="loadMore()">{{'LOAD_MORE' | translate}}</button>

			<ssi-no-results *ngIf="!loading && !this.auditEventsResponse?.pagination.next && this.auditEventsResponse?.pagination.previous || !loading && this.auditEventsResponse?.events.length === 0" title="No more results!"></ssi-no-results>
		</div>
	</div>
</div>