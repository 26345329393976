import { ViewEncapsulation } from '@angular/core';

import {
  Input,
  Component,
  ViewChild,
  Renderer2,
  forwardRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  AfterViewInit
} from '@angular/core';
import {
  NgForm,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ssi-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() parentNgForm: NgForm;
  @Input() label: string;
  @Input() checked: boolean;
  @Input() workWithValue: any;
  @Input() checkboxText: string;
  @Input() leadingIcon?: string; // 'ssi ssi-total-approvals-requested'
  @ViewChild('checkboxRef') _checkboxRef;

  @Output() onChecked: EventEmitter<boolean> = new EventEmitter();

  id = uuidv4();

  isDisabled: boolean;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.checked) {
      this.isChecked = changes.checked.currentValue;
    }
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this._checkboxRef.nativeElement.checked = this.checked;
  }

  isChecked = false;
  onChange = (_) => {};
  onBlur = (_) => {};

  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChanged($event) {
    this.isChecked = $event && $event.target && $event.target.checked;
    this.onChecked.emit($event.target.checked);
    this.checked = $event.target.checked;
    this.onChange(this.checked);

    if (this.workWithValue && this.isChecked) {
      this.onChange(String(this.workWithValue));
    } else {
      this.onChange(this.isChecked);
    }
  }
}
