import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { API, AccountModel } from '@orlo/library/ui-resources/modules/exports';

import { StateService } from '@uirouter/core';
import { decodeStateParams } from '@orlo/common/utils';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { customerSupportWidgetNamespace } from '@orlo/library/constants/live-chat';
import { CompanyService } from '../../../../../../../common/services/company/company.service';
import {
  Report,
  competitorsFn,
  ProfileGroup,
  reportsFn
} from '../../../competitor-analysis.component';
import { TooltipDirective } from '../../../../../../../common/directives/tooltip/tooltip.directive';

@Component({
  selector: 'ssi-create-edit-report',
  templateUrl: './create-edit-report.component.html',
  styles: [],
  styleUrls: ['./create-edit-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateEditReportComponent implements OnInit, OnDestroy {
  static resolve = [
    {
      token: 'reports',
      resolveFn: reportsFn,
      deps: [API, CompanyService]
    },
    {
      token: 'profileGroups',
      resolveFn: competitorsFn,
      deps: [API]
    }
  ];

  @Input() reports: Report[];
  @Input() profileGroups: ProfileGroup[];

  @ViewChild('deleteReportTooltip') deleteReportTooltip: TooltipDirective;
  $routeChange;
  reportForm: FormGroup;
  report: Report = {
    name: '',
    description: '',
    profile_group_ids: [],
    focus_id: null
  };
  adminMode: { id: string; label: string };
  adminModes = {
    edit: {
      id: 'edit',
      label: 'Edit'
    },
    create: {
      id: 'create',
      label: 'Create a'
    }
  };
  previousState: string;

  decodedStateParams: { [key: string]: any };

  constructor(
    private api: API,
    private state: StateService,
    private location: Location,
    public accountModel: AccountModel
  ) {}

  async ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    if (
      this.state &&
      this.decodedStateParams &&
      this.decodedStateParams.reportId
    ) {
      this.adminMode = this.adminModes.edit;
      this.report = this.reports.find(
        (report: Report) => report.id === this.decodedStateParams.reportId
      );
    } else {
      this.adminMode = this.adminModes.create;
    }

    if (!this.report) {
      this.state.go('^.index');
      return;
    }

    this.reportForm = new FormGroup({
      name: new FormControl(this.report.name, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      description: new FormControl(this.report.description, [
        Validators.maxLength(80)
      ])
    });

    // manually setting the first-found avatar to prevent multiple checks in template
    this.profileGroups = this.profileGroups.map(
      (profileGroup: ProfileGroup) => {
        const firstPickedAvatar = profileGroup.profiles.find(
          (acc: any) => acc.avatar_url
        ).avatar_url;
        return {
          ...profileGroup,
          firstPickedAvatar
        };
      }
    );
  }

  isCompetitiorSelected(selectedCompetitorId) {
    return this.report.profile_group_ids.find(
      (id) => parseInt(selectedCompetitorId, 10) === id
    );
  }

  attachCompetitorToReport(selectedCompetitor, attach) {
    const selectedCompetitorInt = parseInt(selectedCompetitor.id, 10);
    if (attach && this.report.profile_group_ids.length >= 4) {
      return;
    }
    if (!attach) {
      const index = this.report.profile_group_ids.indexOf(
        selectedCompetitorInt
      );
      this.report.profile_group_ids.splice(index, 1);
    }
    if (
      attach &&
      !this.report.profile_group_ids.includes(selectedCompetitorInt)
    ) {
      this.report.profile_group_ids.push(selectedCompetitorInt);
    }
  }

  focusProfile(id) {
    this.report.focus_id = this.report.focus_id === id ? null : id;
  }

  backToReports() {
    this.location.back();
  }

  createReport() {
    if (this.reportForm.valid && this.report.profile_group_ids.length > 0) {
      this.report.name = this.reportForm.value.name;
      this.report.description = this.reportForm.value.description;
      this.report.focus_id = parseInt(this.report.focus_id as any, 10);

      const existingReport = this.reports.find(
        ({ id }) => this.report.id === id
      );

      if (existingReport) {
        this.api
          .put(
            `competitiveAnalysis/report?id=${existingReport.id}`,
            this.report
          )
          .then((res) => {
            if (res.status === 200) {
              this.state.go('auth.analytics.competitorAnalysis.index');
            }
          });
      } else {
        this.api.post('competitiveAnalysis/report', this.report).then((res) => {
          if (res.status === 200) {
            this.report.id = res.data.id;
            this.report.created_at = Date.now().toString();
            this.reports.push(this.report);
          }
          this.state.go('auth.analytics.competitorAnalysis.index');
        });
      }
    }
  }

  async deleteReport(selectedReport: Report) {
    this.deleteReportTooltip.hide();

    this.api
      .delete(`competitiveAnalysis/report?id=${selectedReport.id}`)
      .then((res) => {
        if (res.status === 200) {
          this.reports = this.reports.filter(
            (report: Report) => selectedReport.id !== report.id
          );
        }
      });
  }

  toggleLiveChat() {
    window.postMessage(
      { id: `${customerSupportWidgetNamespace}-widget-toggle` },
      '*'
    );
  }

  ngOnDestroy(): void {
    // this.renderer.removeClass(document.body, 'dark-bg');
  }
}
