import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

import { AuditEvent, AuditEventVerb } from '../../services/api';

@Component({
  selector: 'ssi-audit-event',
  templateUrl: './audit-event.component.html',
  styles: [],
  styleUrls: ['./audit-event.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditEventComponent implements OnInit {
  @Input() auditEvent: AuditEvent;
  @Input() last = false;
  expandedNoteText = false;

  AuditEventVerb = AuditEventVerb;

  constructor() {}

  ngOnInit() {}
}
