import { ViewEncapsulation } from '@angular/core';


import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { Options } from 'highcharts';
import Highcharts from 'highcharts';

import { Account } from '@ui-resources-angular';
import { mapToIterable } from '../../../../../../common/utils';
import { HighchartsHelperService } from '../../../../../../common/services/highcharts-helper/highcharts-helper.service';
import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';
import { MarketingService, DateRanges } from '../../marketing.service';

export interface HeatmapStat {
  key: 'clicks' | 'shares' | 'comments' | 'likes';
  label: string;
}

@Component({
  selector: 'ssi-top-times-to-post',
  templateUrl: './top-times-to-post.component.html',
  styles: [],
  styleUrls: ['./top-times-to-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopTimesToPostComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() dateRanges: DateRanges;
  @Input() stats: any; // topTimesToPostStats, TODO: need interface

  statsToDisplay: any;
  chartConfig: Options;
  loading = false;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];

  heatmapStats: { [key: string]: HeatmapStat } = {
    clicks: {
      key: 'clicks',
      label: 'Clicks'
    },
    shares: {
      key: 'shares',
      label: 'Shares'
    },
    comments: {
      key: 'comments',
      label: 'Comments'
    },
    likes: {
      key: 'likes',
      label: 'Likes'
    }
  };
  heatmapStatsIterable: HeatmapStat[] = mapToIterable(this.heatmapStats);
  selectedHeatmapStats: HeatmapStat[] = this.heatmapStatsIterable.slice();

  constructor(
    protected elementRef: ElementRef,
    protected translate: TranslateService,
    protected marketingService: MarketingService,
    protected highchartsHelper: HighchartsHelperService
  ) {}

  ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return this.accounts.some((a) => a.account_type_id === n.key);
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);
    this.selectedNetwork = this.selectableNetworks[0];

    this.selectedHeatmapStats = this.heatmapStatsIterable.slice();

    this.statsToDisplay = this.stats;
    this.chartConfig = this.getChartConfig();
  }

  async onNetworkSelect(nsi: NetworkSelectItem): Promise<void> {
    const networkStats = await this.loadTopPostTimes(
      nsi,
      this.selectedHeatmapStats
    );
    if (networkStats) {
      this.selectedNetwork = nsi;

      this.statsToDisplay = networkStats;
      this.chartConfig = this.getChartConfig();
    }
  }

  async onHeatmapStatsChange(heatmapStats: HeatmapStat[]): Promise<void> {
    const networkStats = await this.loadTopPostTimes(
      this.selectedNetwork,
      heatmapStats
    );
    if (networkStats) {
      this.selectedHeatmapStats = heatmapStats;

      this.statsToDisplay = networkStats;
      this.chartConfig = this.getChartConfig();
    }
  }

  async loadTopPostTimes(
    nsi: NetworkSelectItem,
    heatmapStats: HeatmapStat[]
  ): Promise<any> {
    this.loading = true;
    try {
      const accountIds =
        nsi.key === 'all'
          ? this.accounts.map((a) => a.id)
          : this.accounts
              .filter((a) => a.account_type_id === nsi.key)
              .map((a) => a.id);

      const networkStats = await this.marketingService.getTopPostTimes(
        accountIds,
        this.dateRanges,
        heatmapStats.map((s) => s.key)
      );

      this.loading = false;
      return networkStats;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);

      this.loading = false;
      return undefined;
    }
  }

  getChartConfig(): any {
    const includeEmptyValues = false;

    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(moment().hours(i).format('ha'));
    }

    const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    const seriesData = [];
    Object.keys(this.statsToDisplay.chartData).forEach((dayKey) => {
      const dayIndex = days.findIndex((dow) => dow.toLowerCase() === dayKey);
      if (dayIndex > -1) {
        Object.keys(this.statsToDisplay.chartData[dayKey]).forEach(
          (hourIndex) => {
            const value = Math.round(
              this.statsToDisplay.chartData[dayKey][hourIndex].value
            );
            //   console.log('val: ', value);
            const data = this.statsToDisplay.chartData[dayKey][hourIndex].data;
            //   console.log('data: ', data);
            if (value > 0 || includeEmptyValues) {
              const point: {
                x: number;
                y: number;
                data: any;
                value: string | number;
                color?: string;
              } = {
                x: +hourIndex,
                y: dayIndex,
                data,
                value
              };

              if (value === 0) {
                point.value = '';
                point.color = 'white';
              }

              seriesData.push(point);
            }
          }
        );
      }
    });

    return {
      global: {
        useUTC: false
      },
      chart: {
        type: 'heatmap',
        defaultSeriesType: 'areaspline',
        zoomType: 'x',
        version: 2,
        // plotBorderWidth: 5,
        // plotBorderColor: '#efefef',
        backgroundColor: null,
        spacingTop: 5,
        // spacingBottom: 0,
        spacingLeft: 10,
        spacingRight: 20
        // marginTop: 5,
        // spacing: [10, 10, 10, 10]
      },
      series: [
        {
          data: seriesData,
          name: '',
          color: '#D41D68',
          borderWidth: 2,
          borderColor: '#F4F4FA',
          dataLabels: {
            enabled: true,
            color: 'black',
            style: {
              textOutline: false,
              fontSize: 12,
              fontWeight: 900
            }
          }
        }
      ],
      xAxis: {
        title: '',
        categories: hours,
        min: 0,
        max: hours.length - 1,
        labels: {
          style: {
            color: '#838EAB'
          }
        },
        dateTimeLabelFormats: {
          month: '%e %b',
          year: '%b'
        }
      },
      yAxis: {
        title: '',
        categories: days,
        min: 0,
        max: days.length - 1,
        reversed: true,
        labels: {
          align: 'center',
          style: {
            color: '#838EAB'
          }
        },
        gridLineWidth: 2,
        gridLineColor: '#F4F4FA'
      },
      colorAxis: {
        min: 0,
        // max: 100,
        // minColor: '#FFFFFF',
        // maxColor: '#D41D68'
        stops: [
          [0, '#FFEEE9'],
          [0.25, '#FBC5DB'],
          [0.5, '#FF5DA0'],
          [0.75, '#F40064'],
          [1, '#D41D68']
        ]
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        /* tslint:disable */
        formatter: function () {
          return `
          At <b>${this.series.xAxis.categories[this.point.x]}</b>&nbsp;on <b>${
            this.series.yAxis.categories[this.point.y]
          }</b>
          posts have a success rate of <b>${this.point.value}%</b>
          in comparison to the rest of the week<br><br>
          <div style="text-align: center">
          ${
            this.point.data.totals.comments
              ? `<b>${this.point.data.totals.comments}</b> Comments <br>`
              : ''
          }
          ${
            this.point.data.totals.shares
              ? `<b>${this.point.data.totals.shares}</b> Shares <br>`
              : ''
          }
          ${
            this.point.data.totals.clicks
              ? `<b>${this.point.data.totals.clicks}</b> Clicks <br>`
              : ''
          }
          ${
            this.point.data.totals.likes
              ? `<b>${this.point.data.totals.likes}</b> Likes <br>`
              : ''
          }
          </div>
        `;
        }
        /* tslint:enable */
      }
    };
  }
}
