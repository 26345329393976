import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { OutboxTypeaheadComponent } from './outbox-typeahead.component';

@NgModule({
  declarations: [OutboxTypeaheadComponent],

  exports: [OutboxTypeaheadComponent],

  imports: [ClickOutsideModule, CommonModule, TranslateModule]
})
export class OutboxTypeaheadModule {}
