import { ViewEncapsulation } from '@angular/core';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { decodeStateParams } from '@orlo/common/utils';
import { Dashboard } from '../../../../../../common/services/api/dashboard';

@Component({
  selector: 'ssi-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styles: [],
  styleUrls: ['./dashboard-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardNavbarComponent implements OnInit {
  @Input() dashboardName = '';
  @Input() dashboardID: string;
  @Input() mode: 'view' | 'create' | 'edit' = 'view';
  @Input() dashboards = [];

  @Output() widgetAdd = new EventEmitter<void>();
  @Output() dashboardNameChange = new EventEmitter<string>();
  @Output() dashboardDelete = new EventEmitter<void>();
  @Output() dashboardChange = new EventEmitter<string>();

  selectedDashboard: Dashboard;
  decodedStateParams: { [key: string]: any };

  constructor(private state: StateService) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    this.dashboardName =
      (this.decodedStateParams && this.decodedStateParams.reportName) ||
      this.dashboardName;
      
    this.onDashboardNameChange(); // emit it on init since it's undefined except the user change it

    this.selectedDashboard = this.dashboards.find(
      (d) => d.id === this.decodedStateParams.id
    ); // needs setting up with local storage
  }

  onDashboardNameChange() {
    this.dashboardNameChange.emit(this.dashboardName);
  }

  onDashboardChange(dashboard) {
    this.dashboardChange.emit(dashboard.id);
  }

  refresh() {
    this.state.reload();
  }

  edit() {
    this.state.go('auth.dashboard.edit', {
      id: this.dashboardID
    });
  }

  create() {
    this.state.go('auth.dashboard.create');
  }
}
