import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  EventEmitter,
  Output
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UserModel } from '@ui-resources-angular';
import { Snippet } from '@orlo/library/interfaces/snippet';
import { CompanyService } from '../../services/company/company.service';
import { ActivitySnippetsDropdownComponent } from './activity-snippets-dropdown/activity-snippets-dropdown.component';
import { precedingCharValid } from '../../components/text-input-autocomplete';

const HOTKEYS = ['/'];

@Directive({
  selector: '[ssiActivitySnippets]'
})
export class ActivitySnippetsDirective implements OnDestroy {
  private _activeHotkey: string;
  private _canAdministerCompany = false;
  private _dropdownFactory: ComponentFactory<ActivitySnippetsDropdownComponent>;
  private _dropdown: ComponentRef<ActivitySnippetsDropdownComponent>;
  private cursorPosition: number;

  public snippets: Snippet[] = [];

  @Output()
  onSnippetSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _company: CompanyService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _element: ElementRef,
    private _injector: Injector,
    private _userModel: UserModel,
    private _viewContainer: ViewContainerRef,
    @Inject(DOCUMENT) private document
  ) {
    this.refreshSnippets();
    this._userModel.getAuthUser().then((authUser) => {
      this._canAdministerCompany = authUser.hasCompanyPermission(
        'administer_company'
      );
    });

    this._dropdownFactory = _componentFactoryResolver.resolveComponentFactory(
      ActivitySnippetsDropdownComponent
    );
  }

  public get canAdministerCompany() {
    return this._canAdministerCompany;
  }

  @HostListener('window:keydown', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    // only take effect if the directive has focus...
    if (
      this._dropdown ||
      this._element.nativeElement !== document.activeElement
    ) {
      return;
    }

    const textarea = event.target as HTMLTextAreaElement;
    this.cursorPosition = textarea.selectionStart;

    const precedingChar = textarea.value.charAt(this.cursorPosition - 1);

    if (
      !event.shiftKey &&
      HOTKEYS.indexOf(event.key) !== -1 &&
      precedingCharValid(precedingChar)
    ) {
      this._activeHotkey = event.key;
      this.showDropdown();
    }
  }

  private hideDropdown() {
    if (this._dropdown) {
      this._viewContainer.clear();
      this._dropdown = undefined;
    }
  }

  ngOnDestroy() {
    this.hideDropdown();
  }

  private refreshSnippets(): Promise<Snippet[]> {
    return this._company.getInboxSnippets().then((snippets: Snippet[]) => {
      const searchText = '';
      this.snippets = snippets.filter((snippet: Snippet) =>
        snippet.title.toLowerCase().includes(searchText.toLowerCase())
      );

      return this.snippets;
    });
  }

  private showDropdown() {
    this._dropdown = this._viewContainer.createComponent(
      this._dropdownFactory,
      0,
      this._injector,
      []
    );

    this._dropdown.instance.snippets = this.snippets;
    this._dropdown.instance.canAdministerCompany = this.canAdministerCompany;

    this._dropdown.instance.selection.subscribe((snippet: Snippet) => {
      if (typeof snippet === 'undefined') {
        return this.hideDropdown();
      }

      const value: string = this._element.nativeElement.value;

      const before = value.substr(0, this.cursorPosition);
      // this.cursorPosition + 1 (skip the trigger char, i.e. '/')
      const after = value.substr(this.cursorPosition + 1, value.length);

      const valueWithSnippet = before + snippet.body + ' ' + after;

      const nativeElement = this._element.nativeElement as HTMLTextAreaElement;
      nativeElement.value = valueWithSnippet;

      this.onSnippetSelected.emit(valueWithSnippet);
      this.hideDropdown();
    });

    const dropdownElement = this._dropdown.location.nativeElement.querySelectorAll(
      '.dropdown-container'
    )[0];

    requestAnimationFrame(() => {
      dropdownElement.style.top = `0px`;
    });
  }
}
