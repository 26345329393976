import { ViewEncapsulation } from '@angular/core';

import { Component } from '@angular/core';
import { TextInputAutocompleteMenuComponent } from '../../../text-input-autocomplete';
import { Subject } from 'rxjs';
import { ProfileSearchResult } from '@ui-resources-angular';

@Component({
  selector: 'ssi-publisher-mentions-autocomplete-menu',
  templateUrl: './publisher-mentions-autocomplete-menu.component.html',
  styleUrls: ['./publisher-mentions-autocomplete-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublisherMentionsAutocompleteMenuComponent extends TextInputAutocompleteMenuComponent {
  choiceLoading: boolean;
  position: {
    top: number;
    left: number;
  };
  selectChoice: Subject<any>;
  activeChoice: any;
  trackById = (index: number, choice: ProfileSearchResult) => choice.id;
}
