import { ViewEncapsulation } from '@angular/core';


import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssi-sentiment-change',
  templateUrl: './sentiment-change.component.html',
  styles: [],
  styleUrls: ['./sentiment-change.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SentimentChangeComponent {
  @Input() totalMessages: number;
  @Input() totalConversations: number;
  @Input() chartConfig;

  constructor() {}
}
