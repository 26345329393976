import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Activity, Account } from '@ui-resources-angular';
import { Team, Colleague } from '../../../services/api';

@Component({
  selector: 'ssi-message-footer',
  templateUrl: './message-footer.component.html',
  styleUrls: ['./message-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageFooterComponent implements OnInit {
  @Input() activity: Activity;
  @Input() isPrivate: boolean;
  @Input() isActive: boolean;
  @Input() isRoot: boolean;
  @Input() isInbox: boolean;
  @Input() isActionable?: boolean;
  @Input() canReply?: boolean;
  @Input() account: Account;
  @Input() stats: any;
  @Input() statsShown = false;
  @Input() deleted: any;
  @Input() redacted: any;
  @Input() retweetConfig: any;
  @Input() likeConfig: any;
  @Input() hideCommentConfig: any;
  @Input() enableActions: boolean;
  @Input() sentiment: number | boolean;
  @Input() sentimentOptions?: number;
  @Input() shouldEnableSentimentShortcut?: boolean;
  @Input() tagsTooltip?: number;
  @Input() activeTags?: string[];
  @Input() showActions?: boolean;
  @Input() showViewNatively: boolean;
  @Input() showDelete: boolean;
  @Input() showRedact: boolean;
  @Input() showConversationAssignTo: boolean;
  @Input() retweetSuccess?: boolean;
  @Input() selfPenned?: boolean;
  @Input() assignedSuccess?: Team | Colleague;
  @Input() isConvertedToActivity = false;
  @Input() notes?: any;

  @Output() showActionsChange = new EventEmitter<any>();
  @Output() setActive = new EventEmitter();
  @Output() retweet = new EventEmitter();
  @Output() quoteRetweet = new EventEmitter();
  @Output() showStats = new EventEmitter();
  @Output() viewNativelyClicked = new EventEmitter();
  @Output() toggleLikeClicked = new EventEmitter();
  @Output() toggleShowHideCommentClicked = new EventEmitter();
  @Output() conversationAssignToClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() redactConfirmed = new EventEmitter();
  @Output() hideActivity = new EventEmitter<void>();

  retweetMenuVisible = false;
  retweetExpanded = false;
  retweetRequested = false;

  constructor() {}

  ngOnInit(): void {}
}
