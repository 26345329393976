import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  OnInit,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';
import { Account, AccountModel } from '@ui-resources-angular';
import { orderBy } from 'lodash-es';
import { Applet } from '../../../ifttt-utils/applets/applet';
import { IftttService } from '../../../ifttt-utils/ifttt-service-model/ifttt-service-model';
import { WorkflowManagerService } from '../../../../../../common/services/workflow-manager/workflow-manager.service';

const lowerCaseIncludes = (str: string, match: string): boolean =>
  str.toLowerCase().includes(match.toLowerCase());

export async function workflowAccountsResolveFn(
  accountModel: AccountModel,
  workflowManager: WorkflowManagerService
) {
  try {
    const accounts = await accountModel.findAccounts(
      workflowManager.getCurrentId()
    );
    return orderBy(accounts, ['account_type_name', 'name']);
  } catch (e) {
    console.log(e);
  }
}

@Component({
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountsComponent implements AfterViewInit, OnInit {
  static resolve = [
    {
      token: 'workflowAccounts',
      resolveFn: workflowAccountsResolveFn,
      deps: [AccountModel, WorkflowManagerService]
    }
  ];

  @Input() service: IftttService;
  @Input() workflowAccounts: Account[];
  @Input() applet: Applet;

  @ViewChild('searchInput') searchInput: ElementRef;

  filteredAccounts: Account[];
  searchText = '';

  selectAllAccounts() {
    this.applet.accounts = [...this.workflowAccounts];
  }

  deSelectAllAccounts() {
    this.applet.accounts = [];
  }

  ngOnInit(): void {
    this.filterAccounts();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    });
  }

  trackById(index, account: Account) {
    return account.id;
  }

  filterAccounts() {
    if (!this.searchText) {
      this.filteredAccounts = [...this.workflowAccounts];
    } else {
      this.filteredAccounts = this.workflowAccounts.filter((account) => {
        return (
          lowerCaseIncludes(account.displayName, this.searchText) ||
          lowerCaseIncludes(account.username, this.searchText)
        );
      });
    }
  }
}
