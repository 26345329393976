import 'css-toggle-input/dist/css-toggle-input.css';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IftttAppletCollectionListComponent } from './ifttt-applet-collection-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { Nl2brModule } from '../../../../common/pipes/nl2br/nl2br.module';
import { AlertsModule } from '../../../../common/components/alerts/alerts.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    Nl2brModule,
    AlertsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.iftttAppletCollectionList',
          url: '/automation/:serviceName',
          component: IftttAppletCollectionListComponent,
          resolve: IftttAppletCollectionListComponent.resolve,
          params: {
            serviceName: 'activity.imported.incoming' // have to hard code this for AoT to work
          }
        }
      ]
    })
  ],
  declarations: [IftttAppletCollectionListComponent]
})
export class IftttAppletCollectionListModule {}
