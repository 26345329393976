import { ViewEncapsulation } from '@angular/core';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewChildren,
  QueryList,
  HostListener,
  Inject,
  OnDestroy,
  Injector,
  Input,
  ElementRef
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  addMinutes,
  endOfHour,
  startOfHour,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  differenceInDays
} from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Outbox, OutboxModel } from '@ui-resources-angular';
import { appInjector } from '../../../../app-injector';
import {
  CalendarView,
  CalendarEventTimesChangedEvent,
  CalendarEventTimesChangedEventType
} from '../../../../common/components/calendar';
// import { EventColor } from '../../../../common/components/calendar/calendar-utils';
import {
  EventColor,
  EventAction as CalendarEventAction,
  CalendarEvent,
  getEventsInPeriod2,
  MonthViewDay,
  MonthView,
  WeekView,
  WeekViewHourColumn,
  WeekDay
} from '../../../../common/components/calendar-utils';
import { Debounce } from '../../../../common/decorators';
import {
  PublisherActive,
  PUBLISHER_ACTIVE
} from '../../../../common/components/publisher/publisher-active';
import { companyPreferences } from '../../../common-resolves';
import { CompanyPreferences } from '../../../../common/services/company/company.service';
import { getAccountTypeIdFromName } from '../../../../common/enums';
import { ContextMenuComponent } from '../../../../common/components/context-menu/context-menu.component';
import {
  ContentCalendarService,
  MAX_POSTS_PER_LOAD,
  OutboxSearchParams,
  OutboxSearchResponse
} from './content-calendar.service';
import {
  Filter,
  Filters,
  FiltersComponent
} from '../../../../common/components/filters';
import { CalendarEventModalComponent } from './common/components/calendar-event-modal/calendar-event-modal.component';
import { ConfirmationModalComponent } from '../../../../common/components/confirmation-modal/confirmation-modal.component';
import {
  Campaign,
  CampaignsService
} from '../../../../common/services/api/campaigns';
import { DeviceService } from '../../../../common/services/device/device.service';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import { PostModalComponent } from './common/components/post-modal/post-modal.component';
import { WEEK_STARTS_ON } from '../../../../common/constants';
import { PostBoxComponent } from './common/components/post-box/post-box.component';

export const isDateWithinPeriod = (
  date,
  periodStartDate,
  periodEndDate?
): boolean => {
  return (
    Date.parse(date) >= Date.parse(periodStartDate) &&
    (periodEndDate ? Date.parse(date) < Date.parse(periodEndDate) : true)
  );
};

export interface PostsGroup {
  id: number;
  day: MonthViewDay;
  posts: Outbox[];
  events: CalendarEvent[];
  draggable: boolean;
}

export class CampaignEvent extends Campaign {
  start: Date;
  end: Date;
  colour: string;
  spansNextDay?: boolean;
  spansPreviousDay?: boolean;

  constructor(c: Campaign | any) {
    super(c);
    this.start = new Date(c.started_at);
    this.end = c.closed_at ? new Date(c.closed_at) : undefined;
    this.colour = c.colour;
  }
}
export class CustomEvent {
  id: number;
  start: Date;
  end: Date;
  title: string;
  colour: string;
  spansNextDay?: boolean;
  spansPreviousDay?: boolean;

  constructor(event: any) {
    this.id =
      event.id ||
      Math.random()
        .toString(36)
        .slice(2, 7)
        .split('')
        .reduce((a, b) => (a = (a << 5) - a + b.charCodeAt(0)) & a, 0);

    this.start = startOfDay(event.startsAt || event.start);
    this.end =
      !(event.endsAt || event.end) ||
      Date.parse(event.endsAt || event.end) === 0
        ? endOfDay(event.startsAt || event.start)
        : endOfDay(event.endsAt || event.end); // if endDate is unix time set it as startDate (one day event)

    this.title = event.title;
    this.colour = event.colour;
  }
}

export async function campaignsEventsResolveFn(): Promise<CampaignEvent[]> {
  const campaignsService = appInjector().get(CampaignsService);
  const campaigns = await campaignsService.getAll();
  const campaignEvents = campaigns.map((c) => new CampaignEvent(c));
  return campaignEvents;
}

export function customEventsResolveFn(
  companyPreferencesValue: CompanyPreferences
): CustomEvent[] {
  const parsedEvents = JSON.parse(
    companyPreferencesValue.company_preferences.custom_calendar_events
  );
  const customEvents = parsedEvents.map((e) => new CustomEvent(e));
  return customEvents;
}

@Component({
  selector: 'ssi-content-calendar',
  templateUrl: './content-calendar.component.html',
  styleUrls: ['./content-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentCalendarComponent implements OnInit, OnDestroy {
  static resolve = [
    companyPreferences,
    {
      token: 'customEvents',
      resolveFn: customEventsResolveFn,
      deps: ['companyPreferences']
    },
    {
      token: 'campaignEvents',
      resolveFn: campaignsEventsResolveFn,
      deps: []
    }
  ];

  @Input() companyPreferences: CompanyPreferences;
  @Input() customEvents: CustomEvent[] = [];
  @Input() campaignEvents: CampaignEvent[] = [];

  @ViewChild('filtersRef') filtersComponent: FiltersComponent;
  @ViewChild('calendarScrollTop') calendarScrollTop: ElementRef;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('postsPreviewMenu') postsPreviewMenu: ContextMenuComponent;
  @ViewChild('emptyCellActionsMenu') emptyCellActionsMenu: ContextMenuComponent;
  @ViewChildren('postBox') postBoxes: QueryList<PostBoxComponent>;

  destroyed$ = new Subject();

  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  WEEK_STARTS_ON = WEEK_STARTS_ON;
  // activeDayIsOpen: boolean = true;
  isMobile = false;
  humuhumunukunukuapuaa = true;
  useMonthViewTotals = false;
  loadingPreviewPosts = false;
  filtersVisible = false;
  activeFilters: Filter[] = [];
  showCampaigns = false;
  now = new Date();
  startOfToday = new Date(new Date().setUTCHours(0, 0, 0, 0));

  refresh = new Subject<void>();
  events: Array<CalendarEvent | any> = [];
  searchParams: OutboxSearchParams = {
    // the rest of the params will come from filters once filters component is initialized
    start_date: startOfMonth(new Date()),
    end_date: endOfMonth(new Date())
  } as OutboxSearchParams;
  filters: Filters;

  selectedHourOrDate: Date;

  campaignEventsByDay: { [dayDate: string]: CampaignEvent[] } = {};
  visibleCampaignEventsByDay: { [dayDate: string]: CampaignEvent[] } = {};

  customEventsByDay: { [dayDate: string]: CustomEvent[] } = {};
  visibleCustomEventsByDay: { [dayDate: string]: CustomEvent[] } = {};

  allCampaignAndCustomEventsVisible = false;

  headerHeight: number;

  constructor(
    protected state: StateService,
    protected injector: Injector,
    protected modal: NgbModal,
    @Inject(PUBLISHER_ACTIVE) public publisherActive: PublisherActive,
    protected service: ContentCalendarService,
    protected notificationService: NotificationService,
    protected campaignsService: CampaignsService,
    protected device: DeviceService,
    protected elementRef: ElementRef,
    protected outboxModel: OutboxModel
  ) {}

  async ngOnInit() {
    this.device.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this._setMobileMode(isMobile);
      });

    // console.log('campaignEvents: ', this.campaignEvents);
    // console.log('customEvents: ', this.customEvents);

    this.setDateFilters();
    // Initial data load will be fired once filters are initialized (from within onFilterApply())
    // await this.loadAndMapData();

    this.publisherActive
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter(({ isActive }) => !isActive))
      .subscribe(() => {
        this.loadAndMapData();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('window:resize', ['$event'])
  @Debounce(400, false)
  onResize(event) {
    this.postBoxes.forEach((postBox) => postBox.resizeButtonActions());
  }

  _setMobileMode(isMobile) {
    this.isMobile = isMobile;
    if (isMobile) {
      this.view = CalendarView.Day;
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    console.log('day clicked: ', date, events);
    if (isSameMonth(date, this.viewDate)) {
      // if (
      //   (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      //   events.length === 0
      // ) {
      //   this.activeDayIsOpen = false;
      // } else {
      //   this.activeDayIsOpen = true;
      // }
      this.viewDate = date;
    }
  }

  async eventTimesChanged({
    event,
    newStart,
    newEnd,
    day, // dropped on day - present when month view only
    allDay, // present when day and week view
    type
  }: CalendarEventTimesChangedEvent | any): Promise<void> {
    if (
      type !== CalendarEventTimesChangedEventType.Drag &&
      type !== CalendarEventTimesChangedEventType.Drop
    ) {
      // resizing events not supported
      return;
    }

    if (Date.parse(newStart) < new Date().getTime()) {
      this.cannotScheduleInThePastSnackbar();
      return;
    }

    if (this.view === CalendarView.Month && event.group) {
      const group: PostsGroup = event.group;

      if (!(await this.confirmRescheduling(group.posts.length))) {
        return;
      }

      Promise.all(
        group.posts.map((post) => {
          const daysDiff = differenceInDays(
            startOfDay(day.date),
            startOfDay(post.send_at)
          );
          const newDate = addDays(post.send_at, daysDiff);

          return post.changeScheduledTime(newDate);
        })
      ).then(() => {
        this.events = this.events.map((iEvent) => {
          if (group.events.indexOf(iEvent) > -1) {
            return {
              ...iEvent,
              start: newStart,
              end: newEnd
            };
          }

          return iEvent;
        });

        this.postsRescheduledSnackbarSuccess(group.posts.length);
      });
    } else {
      if (!(await this.confirmRescheduling(1))) {
        return;
      }

      event.metas.post.changeScheduledTime(newStart).then(() => {
        this.events = this.events.map((iEvent) => {
          if (iEvent === event) {
            return {
              ...event,
              start: newStart,
              end: newEnd
            };
          }

          return iEvent;
        });

        this.postsRescheduledSnackbarSuccess();
      });
    }
  }

  cannotScheduleInThePastSnackbar(): void {
    this.notificationService.open(
      `Cannot schedule in the past`,
      {
        class: 'ssi ssi-error',
        color: '#be3609'
      },
      2000
    );
  }

  postsRescheduledSnackbarSuccess(resscheduledPostsCount = 1): void {
    this.notificationService.open(
      !resscheduledPostsCount || resscheduledPostsCount === 1
        ? `Post has been rescheduled`
        : `${resscheduledPostsCount} posts have been rescheduled`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      2000
    );
  }

  confirmRescheduling(postsCount: number): Promise<boolean> {
    const confirmationModal = this.modal.open(ConfirmationModalComponent, {
      windowClass: 'orlo-modal'
    });
    // confirmationModal.componentInstance.negativeConfirmation = true;
    // confirmationModal.componentInstance.icon = 'ssi ssi-resolve-convo';
    confirmationModal.componentInstance.title = `Are you sure you want to reschedule ${
      postsCount > 1 ? postsCount + ' posts' : 'this post'
    }?`;
    // confirmationModal.componentInstance.info = 'Please confirm rescheduling';
    confirmationModal.componentInstance.cancelButton = `Cancel`;
    confirmationModal.componentInstance.confirmButton = `Yes please!`;

    return confirmationModal.result;
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  async setView(view: CalendarView) {
    this.view = view;

    this.setDateFilters();
    await this.loadAndMapData();
  }

  async setViewDate(viewDate: Date) {
    this.viewDate = viewDate;

    this.setDateFilters();
    await this.loadAndMapData();
  }

  getHiddenHourEventsCount(date: Date): number {
    return this.getHourEvents(date).length - 1;
  }

  getHourEvents(date: Date): CalendarEvent[] {
    const events = getEventsInPeriod2(null, {
      events: this.events,
      periodStart: startOfHour(date),
      periodEnd: endOfHour(date)
    });

    return events;
  }

  getDayEvents(date: Date): CalendarEvent[] {
    const events = getEventsInPeriod2(null, {
      events: this.events,
      periodStart: startOfDay(date),
      periodEnd: endOfDay(date)
    });

    return events;
  }

  getWeekEvents(date: Date): CalendarEvent[] {
    const events = getEventsInPeriod2(null, {
      events: this.events,
      periodStart: startOfWeek(date, { weekStartsOn: WEEK_STARTS_ON }),
      periodEnd: endOfWeek(date, { weekStartsOn: WEEK_STARTS_ON })
    });

    return events;
  }

  getMonthEvents(date: Date): CalendarEvent[] {
    const events = getEventsInPeriod2(null, {
      events: this.events,
      periodStart: startOfMonth(date),
      periodEnd: endOfMonth(date)
    });

    return events;
  }

  getTotalPostsCountLabel(): string {
    const firstLabelSegment = (events: CalendarEvent[]): string => {
      return events.length === 0
        ? `NO POSTS`
        : events.length === 1
        ? `${events.length} POST`
        : `${events.length} POSTS`;
    };

    if (this.view === CalendarView.Day) {
      return `${firstLabelSegment(this.events)} TODAY`;
    } else if (this.view === CalendarView.Week) {
      return `${firstLabelSegment(this.events)} THIS WEEK`;
    } else if (this.view === CalendarView.Month) {
      return `${firstLabelSegment(this.events)} THIS MONTH`;
    } else {
      return '';
    }
  }

  groupPostsByAccountTypeId(day: MonthViewDay): PostsGroup[] {
    const groups = [];
    day.events.forEach((event: any) => {
      const groupCreated = groups.find(
        (g) => g.id === event.metas.post.account.account_type_id
      );

      if (groupCreated) {
        groupCreated.posts.push(event.metas.post);
        groupCreated.events.push(event);
        groupCreated.draggable =
          groupCreated.draggable && event.metas.post.scheduled; // group is draggable if all posts within are in scheduled status
      } else {
        groups.push({
          id: event.metas.post.account.account_type_id,
          day,
          posts: [event.metas.post],
          events: [event],
          draggable: event.metas.post.scheduled
        });
      }
    });

    return groups;
  }

  trackByGroupId(group): number {
    return group.id;
  }

  createPostHere(hourSegmentOrDay: { date: Date }) {
    console.log('createPost: ', hourSegmentOrDay.date);
    this.selectedHourOrDate = hourSegmentOrDay.date;
    this.publisherActive.next({
      isActive: true,
      create: { schedules: [hourSegmentOrDay.date], accounts: [] }
    });
  }

  createDraft(hourSegmentOrDay: { date: Date }): void {
    console.log('draftPost: ', hourSegmentOrDay.date);
  }

  createPlaceholder(hourSegmentOrDay: { date: Date }): void {
    console.log('createPlaceholder: ', hourSegmentOrDay.date);
  }

  async openCreateEventModal(hourDateSegment?: { date: Date }) {
    const modal = this.modal.open(CalendarEventModalComponent, {
      windowClass: 'm-modal rounded-corners-15',
      centered: true
    });
    modal.componentInstance.startDate = hourDateSegment
      ? hourDateSegment.date
      : null;
    modal.componentInstance.events = this.customEvents;
    modal.componentInstance.companyPreferences = this.companyPreferences;
    modal.componentInstance.isMobile = this.isMobile;

    const updatedEvents = await modal.result;

    if (updatedEvents) {
      this.customEvents = updatedEvents;
      this.refresh.next();
    }
  }

  async openManageEventModal(event) {
    const modal = this.modal.open(CalendarEventModalComponent, {
      windowClass: 'm-modal rounded-corners-15',
      centered: true
    });
    modal.componentInstance.event = event ? event : null;
    modal.componentInstance.startDate = event ? event.start : null;
    modal.componentInstance.eventTitle = event ? event.title : '';
    modal.componentInstance.endDate = event ? event.end : null;
    modal.componentInstance.eventColour = event ? `rgb(${event.colour})` : '';
    modal.componentInstance.events = this.customEvents;
    modal.componentInstance.companyPreferences = this.companyPreferences;
    modal.componentInstance.isMobile = this.isMobile;

    const updatedEvents = await modal.result;

    if (updatedEvents) {
      this.customEvents = updatedEvents;
      this.refresh.next();
    }
  }

  createEvent(hourSegmentOrDay: { date: Date }): void {
    console.log('createEvent: ', hourSegmentOrDay);
  }

  setDateFilters(): void {
    if (this.view === CalendarView.Day) {
      this.searchParams.start_date = startOfDay(this.viewDate);
      this.searchParams.end_date = endOfDay(this.viewDate);
    } else if (this.view === CalendarView.Week) {
      this.searchParams.start_date = startOfWeek(this.viewDate, {
        weekStartsOn: WEEK_STARTS_ON
      });
      this.searchParams.end_date = endOfWeek(this.viewDate, {
        weekStartsOn: WEEK_STARTS_ON
      });
    } else {
      this.searchParams.start_date = startOfMonth(this.viewDate);
      this.searchParams.end_date = endOfMonth(this.viewDate);
    }
  }

  async loadAndMapData(): Promise<void> {
    if (!this.searchParams || !this.filters) {
      console.error(
        'Filters needs to be initialized before data can be loaded...'
      );
      return;
    }

    this.events = [];

    if (this.view === CalendarView.Month && this.useMonthViewTotals) {
      await this.loadAndMapMonthViewTotals();
    } else {
      const posts = await this.loadPosts(this.searchParams);
      this.attachPostsToEvents(posts);

      if (this.state.params.outboxId) {
        const existingPost = this.outboxModel.get(this.state.params.outboxId);
        if (existingPost) {
          this.openPostModal(existingPost);
        } else {
          this.outboxModel
            .findAll({ id: decodeURIComponent(this.state.params.outboxId) })
            .then((post) => {
              this.openPostModal(post);
            })
            .catch((err) => {
              console.warn(
                'User does not have the required permissions or item deleted'
              );
              this.state.go(
                'auth.marketing.contentCalendar',
                { outboxId: null },
                { reload: false }
              );
            });
        }
      }
    }

    this._scrollToFirstPost();
  }

  async openPostModal(post) {
    const modal = await this.modal.open(PostModalComponent, {
      windowClass: 'orlo-modal orlo-modal-1000',
      backdropClass: 'orlo-modal-backdrop'
    });
    modal.componentInstance.post = post;
    modal.result.finally(() => {
      this.state.go(
        'auth.marketing.contentCalendar',
        { outboxId: null },
        { reload: false }
      );
    });
  }

  async loadPosts(
    searchParams: OutboxSearchParams,
    offset = 0,
    limit = MAX_POSTS_PER_LOAD,
    posts: Outbox[] = []
  ): Promise<Outbox[]> {
    console.log(`Loading posts ${offset} + ${limit} ...`);

    const pagePosts = (
      await this.service.outboxSearch(searchParams, offset, limit)
    ).posts;

    // console.log('pagePosts:', pagePosts);

    const allPosts = [...posts, ...pagePosts];
    // console.log('allPosts:', allPosts);

    if (pagePosts.length === limit) {
      return await this.loadPosts(
        searchParams,
        offset + limit,
        limit,
        allPosts
      );
    }

    return allPosts;
  }

  attachPostsToEvents(posts: Outbox[]): void {
    this.events = posts.map((post) => {
      return {
        start: new Date(post.send_at),
        // end: addHours(startOfDay(new Date()), 3),
        title: post.text,
        // color: { ...colors.yellow },
        // actions: this.actions,
        // allDay: true,
        resizable: {
          beforeStart: false,
          afterEnd: false
        },
        draggable: post.scheduled,
        metas: {
          post
        }
      };
    });
  }

  async loadAndMapMonthViewTotals(): Promise<void> {
    const totalsPerDay = await this.service.getCalendarMonthTotals(
      this.searchParams
    );

    this.events = [];

    Object.keys(totalsPerDay).forEach((dayDate: string) => {
      const accountTypesGroup = totalsPerDay[dayDate];

      Object.keys(accountTypesGroup).forEach((accountTypeName: string) => {
        const accountTypeId = getAccountTypeIdFromName(accountTypeName);
        const event = {
          start: new Date(dayDate),
          // end: addHours(startOfDay(new Date()), 3),
          title: '',
          // color: { ...colors.yellow },
          // actions: this.actions,
          // allDay: true,
          resizable: {
            beforeStart: false,
            afterEnd: false
          },
          draggable: false,
          metas: {
            group: {
              account_type_id: accountTypeId,
              account_type_name: accountTypeName,
              postsCount: accountTypesGroup[accountTypeName]
            }
          }
        };

        this.events.push(event);
      });
    });
  }

  async onPostChipClick(
    event: MouseEvent,
    calEvent: CalendarEvent | any
  ): Promise<void> {
    if (this.loadingPreviewPosts) {
      return;
    }
    // hack to not mutate the original this.searchParams object
    const searchParams: OutboxSearchParams = JSON.parse(
      JSON.stringify(this.searchParams)
    );

    searchParams.start_date = startOfDay(calEvent.start);
    searchParams.end_date = endOfDay(calEvent.start);

    this.loadingPreviewPosts = true;
    const posts = await this.loadPosts(searchParams);
    this.loadingPreviewPosts = false;

    const postsToPreview = posts.filter(
      (p) => p.account.account_type_id === calEvent.metas.group.account_type_id
    );

    this.emptyCellActionsMenu.hide();
    this.postsPreviewMenu.hide();
    this.postsPreviewMenu.show(event, postsToPreview);
  }

  onMontViewhHeaderRefreshed(weekDays: WeekDay[]): void {
    // console.log('onMontViewhHeaderRefreshed: ', weekDays);
    // this.groupCampaignAndCustomEventsByDays(weekDays);
  }

  onMontViewhBodyRefreshed(monthView: MonthView): void {
    // console.log('onMontViewhBodyRefreshed: ', monthView);
    this.groupCampaignAndCustomEventsByDays(monthView.days);
  }

  onWeekViewHeaderRefreshed(weekDays: WeekDay[]): void {
    // console.log('onWeekViewHeaderRefreshed: ', weekDays);
    this.groupCampaignAndCustomEventsByDays(weekDays);
  }

  onWeekViewBodyRefreshed(weekView: WeekView): void {
    // console.log('onWeekViewBodyRefreshed: ', weekView);
    // this.groupCampaignAndCustomEventsByDays(weekView.hourColumns);
  }

  groupCampaignAndCustomEventsByDays(
    days: Array<MonthViewDay | WeekDay>
  ): void {
    this.campaignEvents.sort((a, b) =>
      a.end && b.end
        ? b.end.getTime() - a.end.getTime()
        : a.end && !b.end
        ? 1
        : !a.end && b.end
        ? -1
        : 0
    );
    this.customEvents.sort((a, b) =>
      a.end && b.end
        ? b.end.getTime() - a.end.getTime()
        : a.end && !b.end
        ? 1
        : !a.end && b.end
        ? -1
        : 0
    );

    days.forEach((day) => {
      const dayString = day.date.toString();
      const dayCampaignEvents = this.filterEventsByDay(
        this.campaignEvents,
        day.date,
        day
      );
      const dayCustomEvents = this.filterEventsByDay(
        this.customEvents,
        day.date,
        day
      );

      // this.campaignEventsByDay = {};
      this.campaignEventsByDay[dayString] = dayCampaignEvents.map((e, i) => {
        const event = { ...e }; // create new event object instance
        event.spansNextDay = this.eventSpansNextDay(day.date, event.end);
        event.spansPreviousDay = this.eventSpansPreviousDay(
          day.date,
          event.start
        );
        return event;
      });

      // this.customEventsByDay = {};
      this.customEventsByDay[dayString] = dayCustomEvents.map((e, i) => {
        const event = { ...e }; // create new event object instance
        event.spansNextDay = this.eventSpansNextDay(day.date, event.end);
        event.spansPreviousDay = this.eventSpansPreviousDay(
          day.date,
          event.start
        );
        return event;
      });
    });

    this.setVisibleCampaignAndCustomEvents();

    // console.log('campaignEventsByDay: ', this.campaignEventsByDay);
    // console.log('customEventsByDay: ', this.customEventsByDay);
  }

  eventSpansNextDay(dayDate: Date, eventEndDate: Date): boolean {
    return (
      startOfDay(eventEndDate).toString() !== startOfDay(dayDate).toString()
    );
  }
  eventSpansPreviousDay(dayDate: Date, eventStartDate: Date): boolean {
    return (
      startOfDay(eventStartDate).toString() !== startOfDay(dayDate).toString()
    );
  }

  filterEventsByDay(
    events: Array<CampaignEvent | CustomEvent>,
    dayDate: Date,
    day?: MonthViewDay | WeekDay
  ): any[] {
    if (
      day &&
      Object.hasOwnProperty((day as any).inMonth) &&
      !(day as any).inMonth
    ) {
      return [];
    }

    return events.filter((e) => {
      return isDateWithinPeriod(
        startOfDay(dayDate),
        startOfDay(e.start),
        e.end && endOfDay(e.end)
      );
    });
  }

  toggleShowCampaigns() {
    this.showCampaigns = !this.showCampaigns;
    this.setVisibleCampaignAndCustomEvents();
  }

  toggleShowMoreCampaignAndCustomEvents() {
    this.allCampaignAndCustomEventsVisible = !this
      .allCampaignAndCustomEventsVisible;
    this.setVisibleCampaignAndCustomEvents();
  }

  setVisibleCampaignAndCustomEvents(): void {
    const firstItemOrEmptyArray = (items: any[]): any[] => {
      return items.length ? [items[0]] : [];
    };

    Object.keys(this.campaignEventsByDay).forEach((dayKey) => {
      const dayEvents = this.campaignEventsByDay[dayKey];

      if (this.allCampaignAndCustomEventsVisible) {
        this.visibleCampaignEventsByDay[dayKey] = this.showCampaigns
          ? [...dayEvents]
          : firstItemOrEmptyArray(dayEvents);
      } else {
        this.visibleCampaignEventsByDay[dayKey] = this.showCampaigns
          ? firstItemOrEmptyArray(dayEvents)
          : [];
      }
    });

    Object.keys(this.customEventsByDay).forEach((dayKey) => {
      const dayEvents = this.customEventsByDay[dayKey];

      this.visibleCustomEventsByDay[dayKey] = this
        .allCampaignAndCustomEventsVisible
        ? [...dayEvents]
        : firstItemOrEmptyArray(dayEvents);
    });
  }

  hiddenCampaignAndCustomEventsCount(day: MonthViewDay | WeekDay): number {
    const dayStr = day.date.toString();

    const hiddenCampaignEventsCount =
      this.campaignEventsByDay[dayStr].length -
      this.visibleCampaignEventsByDay[dayStr].length;

    const hiddenCustomEventsCount =
      this.customEventsByDay[dayStr].length -
      this.visibleCustomEventsByDay[dayStr].length;

    return this.showCampaigns
      ? hiddenCampaignEventsCount + hiddenCustomEventsCount
      : hiddenCustomEventsCount;
  }

  handleEvent(eventType: string, event: any): void {}

  updateUrlParams(post) {
    this.state.go(
      'auth.marketing.contentCalendar',
      { outboxId: post.id },
      { reload: false }
    );
  }

  applyFilters(filters: Filters): void {
    this.filters = filters;
    const filtersSearchParams = this.filtersComponent.toApiParams();
    // console.log('filtersSearchParams: ', filtersSearchParams);

    this.searchParams = { ...this.searchParams, ...filtersSearchParams };
    console.log('searchParams: ', this.searchParams);
    this.loadAndMapData();
  }

  calculateHeaderHeight(event) {
    this.headerHeight = event;
  }

  private _scrollToFirstPost() {
    if (this.view === CalendarView.Month) {
      return;
    }
    setTimeout(() => {
      const postNodeList = this.elementRef.nativeElement.querySelectorAll(
        '.post-box-scroll'
      );
      if (!postNodeList.length) {
        this.calendarScrollTop.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        return;
      }
      let highestPostNode = postNodeList[0];
      let highestPosition = 0;
      postNodeList.forEach((node) => {
        const nodePosition = node.getBoundingClientRect();
        if (!highestPosition) {
          highestPosition = nodePosition.y;
        }
        if (nodePosition.y < highestPosition) {
          highestPosition = nodePosition.y;
          highestPostNode = node;
        }
      });
      // highestPostNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollTo({
        behavior: 'smooth',
        top: highestPostNode.getBoundingClientRect().y - this.headerHeight
      });
    });
  }
}
