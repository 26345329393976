import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ApiService, Colleague } from '../../../../../common/services/api';
import {
  API,
  Account,
  Outbox,
  OutboxMessage,
  OutboxMessageAttachment,
  OutboxPublisher,
  OutboxPublisherMention,
  filesToOutboxMessageAttachments,
  getOutboxMessageType,
  serialiseOutboxPublisherText
} from '@ui-resources-angular';
import { TranslationService } from '../../../../../common/services/translation/translation.service';
import { PredictedResponseService } from '../../../../../common/services/predicted-response/predicted-response.service';
import { format } from 'date-fns';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { ActivityReplyUser } from '../../../../../common/components/activity/activity.component';
import { FilestackFile } from '../../../../../common/services/filestack/filestack.service';
import { FsFile } from '../../../../../common/directives/file-uploader/file-uploader.service';
import { NotificationService } from '../../../../../common/services/notification/notification.service';

@Component({
  selector: 'ssi-validate-reply-box-content',
  templateUrl: './validate-reply-box-content.component.html',
  styleUrls: ['./validate-reply-box-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidateReplyBoxContentComponent implements OnInit {
  @Input() validation: Outbox;
  @Input() user: Colleague;
  @Input() selected: Array<Outbox>;
  @Input() selectMode = false;

  @Input() editMode = false;
  @Output() editModeChange = new EventEmitter<boolean>();

  @Output() addToSelected = new EventEmitter<void>();

  pipe = '&vert;';
  mentions: OutboxPublisherMention[] = [];
  translation: {
    translatedText: string;
    detectedSourceLanguage: string;
    activity: {
      account: Account;
      interaction: {
        content: string;
        entities: any;
      };
    };
  };
  tempAttachment: FsFile = null;
  savingEdit = false;

  signature: string;

  private _contentText: string = '';
  private _isPredictive: boolean = false;
  private _prediction: string = '';
  private _previousText: string = '';

  constructor(
    protected translationService: TranslationService,
    protected predictedResponseService: PredictedResponseService,
    protected asyncTracker: AsyncTrackerFactory,
    protected notificationService: NotificationService,
    protected api: ApiService
  ) {}

  ngOnInit(): void {
    this.mentions = this.validation.getMentions
      ? this.validation.getMentions()
      : [];
    console.log('Inbox validation:', this.validation);
  }

  isSelected(): boolean {
    return this.selected.map((item) => item.id).includes(this.validation.id);
  }

  onFileUploadSuccess(file: FsFile) {
    console.log('file:', file);
    this.validation.outbox_files = [file as any];
    this.tempAttachment = file;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    this.editModeChange.emit(this.editMode);
  }

  async saveReplyEdit(event: { text: string }) {
    return;
    this.savingEdit = true;

    const outboxMessage: OutboxMessage = await this.validation.toOutboxPublisherMessageFormat();
    outboxMessage.text = serialiseOutboxPublisherText(
      event.text,
      [],
      this.validation.account
    );
    delete outboxMessage['seq'];
    delete outboxMessage['legacy_text'];
    delete outboxMessage['send_at'];
    delete outboxMessage['targeting'];
    const newMessageType = getOutboxMessageType(
      outboxMessage.message_type,
      this.validation.outbox_files as any
    );

    outboxMessage.message_type = newMessageType;

    if (this.tempAttachment) {
      const attachments = filesToOutboxMessageAttachments(
        this.validation.account,
        [this.tempAttachment] as any
      );

      if (attachments && attachments.length > 0) {
        outboxMessage.attachments = attachments;
      }
    } else {
      outboxMessage.attachments = outboxMessage.attachments
        ? outboxMessage.attachments
        : [];
    }

    const body = { message: outboxMessage };
    const config: any = {
      params: {
        _method: 'PUT'
      }
    };

    const editReplyResponse = await this.api
      .post(`${this.api.url}/outbox_v2/indexOutboxv2`, body, config)
      .toPromise()
      .then(() => {
        this.updateReplyText(event.text);
        this.tempAttachment = null;
        this.toggleEditMode();
        this.savingEdit = false;
        this.notificationService.open(
          `You've successfully edited reply`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          4000
        );
      });
  }

  updateReplyText(text: string) {
    // this.replyTextChanged();
    // this.validation.text = text;
  }

  translateReplyText() {
    this.translationService
      .translateText({
        text: this.validation.text,
        target: this.translation.detectedSourceLanguage
      })
      .then((translations) => {
        this.validation.text = translations[0].translatedText;
      });
  }

  addPrivateReplyLink() {
    if (this.validation.account.socialNetwork.addPrivateReplyLinkAsAttachment) {
      this.validation['add_dm_reply_link'] = !this.validation[
        'add_dm_reply_link'
      ];
    } else {
      const replyLink: string = this.validation.account.socialNetwork.getPrivateReplyLink(
        this.validation.account
      );
      const replyText = this.validation.text || '';
      if (!replyText.includes(replyLink)) {
        this.validation.text = [replyText, replyLink]
          .filter((part) => !!part)
          .join(' ');
      }
    }
  }

  // ----
  // predictions stuff
  // ----

  public get messageTextValue(): string {
    return this.validation.text;
  }

  public set messageTextValue(value: string) {
    this.setResponse(value);
  }

  public get prediction(): string {
    return this._prediction;
  }

  public set prediction(value: string) {
    this._prediction = value;
  }

  public get isPredictive() {
    return this._isPredictive;
  }

  public async acceptPrediction(event: KeyboardEvent): Promise<boolean> {
    try {
      const prediction = String(this._prediction);
      const shouldContinue =
        !prediction.trim().length ||
        prediction.trim() === String(this.messageTextValue).trim();

      if (shouldContinue) {
        return false;
      }

      event.preventDefault();

      const shouldAddSpace =
        PredictedResponseService.CharactersRequiringSpaceSuffix.indexOf(
          prediction.substr(-1)
        ) === -1;
      const responseValue = prediction + (shouldAddSpace ? ' ' : '');

      const quantityOfWordsInPrediction: number = prediction
        .slice(this.messageTextValue.length)
        .trim()
        .split(' ').length;

      this.setResponse(responseValue);

      const storageKey = `ls` + format(new Date(), 'DD-MM-YYYY');
      const currentStoredValue: number =
        Number(window.localStorage.getItem(storageKey)) || 0;

      window.localStorage.setItem(
        storageKey,
        String(currentStoredValue + quantityOfWordsInPrediction)
      );

      await this.refreshPredictions(false);

      return true;
    } catch (error) {
      console.error('Error accepting prediction:', error);

      return false;
    }
  }

  public async onKeyDown(event: KeyboardEvent): Promise<boolean> {
    try {
      switch (event.key.toLowerCase()) {
        case 'arrowright':
          {
            const textarea = event.target as HTMLTextAreaElement;
            const cursor = textarea.selectionStart;
            const messageLength = this.validation.text.length;

            if (cursor >= messageLength) {
              return this.acceptPrediction(event);
            }
          }
          break;

        case 'tab':
          {
            return this.acceptPrediction(event);
          }
          break;
      }

      return true;
    } catch (error) {
      console.error('Error handling onKeyDown event:', error);

      return false;
    }
  }

  public async onKeyUp(event: KeyboardEvent): Promise<boolean> {
    try {
      this._previousText = this._contentText;
      this._contentText = this.validation.text;

      this.refreshPredictions(true);

      return true;
    } catch (error) {
      console.error('Error handling onKeyUp event:', error);

      return false;
    }
  }

  public async refreshPredictions(shouldFetchFreshPredictions: boolean) {
    try {
      // clear the prediction to reduce any visual lag
      // if e.g. lots of text is pasted in and the lookup is on a slow connection.

      this._prediction = '';

      if (!this.isPredictive) {
        return;
      }

      this._prediction = await this.predictedResponseService.getPredictionFor(
        this.validation.text,
        shouldFetchFreshPredictions
      );
      // .trim()
      // .replace(
      //   '{{name}}',
      //   this.activity.author.name
      //     ? this.activity.author.name.split(' ')[0]
      //     : ''
      // );

      return true;
    } catch (error) {
      console.error('Error refreshing the predictions', error);

      return false;
    }
  }

  public setResponse(text: string = '') {
    try {
      this.validation.text = text;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  // end of predictions stuff...
}
