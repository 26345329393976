import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LiveChatAssignAgentComponent } from './live-chat-assign-agent.component';
import { FormsModule } from '@angular/forms';
import { ButtonComponent, OrloComponentsModule } from 'orlo-components';
import { Live } from '@ng-bootstrap/ng-bootstrap/util/accessibility/live';

@NgModule({
  declarations: [LiveChatAssignAgentComponent],
  exports: [LiveChatAssignAgentComponent],
  entryComponents: [LiveChatAssignAgentComponent],
  imports: [CommonModule, FormsModule, OrloComponentsModule, TranslateModule]
})
export class LiveChatAssignAgentModule {}
