import { ViewEncapsulation } from '@angular/core';


import { Component, Input } from '@angular/core';

import { Activity } from '@ui-resources-angular';
import { AuditEventVerb, AuditEventsService } from '../../../services/api';

@Component({
  selector: 'ssi-message-attachments',
  templateUrl: './message-attachments.component.html',
  styleUrls: ['./message-attachments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageAttachmentsComponent {
  @Input() activity: Activity;
  @Input() attachments;

  constructor(protected auditEventsService: AuditEventsService) {}

  onMediaRevealed(activity: Activity) {
    activity.sensitiveContentRevealed = true;

    this.auditEventsService.createActivityEvent(
      activity.id,
      AuditEventVerb.SensitiveMediaViewed
    );
  }
}
