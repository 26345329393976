import { ViewEncapsulation } from '@angular/core';
import { Component, Input } from '@angular/core';


@Component({
  selector: 'ssi-stat-bar-tile',
  templateUrl: './stat-bar-tile.component.html',
  styles: [],
  styleUrls: ['./stat-bar-tile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatBarTileComponent {
  @Input() title: string;
  @Input() stat: string | number;
  @Input() iconClass: string;
  @Input() iconSize?: string | number;
  @Input() progressPercentage?: string;
  @Input() progressColor?: string;

  constructor() {}
}
