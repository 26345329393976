import { ViewEncapsulation } from '@angular/core';

import { Component, Input, OnInit } from '@angular/core';
import {
  OutboxPublisher,
  SocialNetworkLinkPreview,
  Account,
  OutboxPublisherLink,
  OutboxPublisherMention,
  OutboxPublisherFile
} from '@ui-resources-angular';
import { nl2br } from '../../../../util';
import { OutboxPostTextLinkifyPipe } from '../outbox-post-text-linkify/outbox-post-text-linkify.pipe';

@Component({
  selector: 'ssi-publisher-linkedin-post-preview',
  templateUrl: './publisher-linkedin-post-preview.component.html',
  styleUrls: ['./publisher-linkedin-post-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PublisherLinkedinPostPreviewComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() account: Account;
  @Input() text: string;
  @Input() preview: SocialNetworkLinkPreview;
  @Input() links: OutboxPublisherLink[];
  @Input() mentions: OutboxPublisherMention[];
  @Input() files: OutboxPublisherFile[];
  isTruncated = false;
  limit = 248;

  constructor(private outboxPostTextLinkifyPipe: OutboxPostTextLinkifyPipe) {}

  ngOnInit() {
    const formattedText = this.outboxPostTextLinkifyPipe.transform(
      nl2br(this.text),
      this.account,
      this.links,
      this.mentions
    );
    this.isTruncated = formattedText.length > this.limit;
  }
}
