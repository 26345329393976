import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ContentLibraryComponent } from './content-library.component';
import { EditFileModalComponent } from './edit-file-modal/edit-file-modal.component';
import { TooltipModule } from '../../../../common/directives/tooltip/tooltip.module';
import { EnlargeImageModule } from '../../../../common/directives/enlarge-image/enlarge-image.module';
import { ContextMenuModule } from '../../../../common/components/context-menu/context-menu.module';
import { TruncateModule } from '../../../../common/pipes/truncate/truncate.module';
import { PrettyBytesModule } from '../../../../common/pipes/pretty-bytes/pretty-bytes.module';
import { ConfirmActionModule } from '../../../../common/directives/tooltip/confirm-action/confirm-action.module';
import { MenuModule } from '../../../../common/components/menu/menu.module';
import { DragAndDropModule } from '../../../../common/components/draggable-droppable';
import { DateTimePickerModule } from '../../../../common/components/date-time-picker/date-time-picker.module';
import { OrloComponentsModule } from 'orlo-components';
import { PopupMessageModalModule } from '../../../../common/services/popup/popup-message-modal/popup-message-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.marketing.contentLibrary',
          url: '/asset-library',
          component: ContentLibraryComponent
        }
      ]
    }),
    TranslateModule,
    TooltipModule,
    EnlargeImageModule,
    ContextMenuModule,
    TruncateModule,
    PrettyBytesModule,
    ConfirmActionModule,
    MenuModule,
    DragAndDropModule,
    DateTimePickerModule,
    OrloComponentsModule,
    PopupMessageModalModule,
  ],
  declarations: [ContentLibraryComponent, EditFileModalComponent],
  entryComponents: [ContentLibraryComponent, EditFileModalComponent]
})
export class ContentLibraryModule {}
