import { ViewEncapsulation } from '@angular/core';


import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

export interface Item {
  id: string;
  label: string;
}

@Component({
  selector: 'ssi-social-push-mode-menu',
  templateUrl: './social-push-mode-menu.component.html',
  styleUrls: ['./social-push-mode-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SocialPushModeMenuComponent implements OnInit {
  @Input() items: Item[] = [];
  @Input() selectedItem: Item;

  @Output() itemChange = new EventEmitter<Item>();

  active = false;

  constructor() {}

  ngOnInit() {}

  select(item: Item): void {
    this.selectedItem = item;
    this.active = false;
    this.itemChange.emit(item);
  }
}
