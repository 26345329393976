import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { TooltipModule } from '@orlo/common/directives/tooltip/tooltip.module';
import { IconModule } from '@orlo/common/components/icon/icon.module';
import { DropdownSelect2Module } from '@orlo/common/components/dropdown-select-2/dropdown-select-2.module';

import { TagsInputModule } from '../tags-input/tags-input.module';
import { RadioListModule } from '../radio-list/radio-list.module';

import { ReportActionBarComponent } from './report-action-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { Checkbox2Module } from '@orlo/common/components/checkbox-2/checkbox-2.module';
import { SentimentAmendBoxModule } from '@orlo/common/components/sentiment-amend-box/sentiment-amend-box.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ObserveWidthAndHeightModule } from '@orlo/common/directives';

@NgModule({
  declarations: [ReportActionBarComponent],
  imports: [
    CommonModule,
    NgbModule,
    NgbDropdownModule,
    FormsModule,
    UIRouterModule,
    TooltipModule,
    IconModule,
    DropdownSelect2Module,
    TagsInputModule,
    RadioListModule,
    TranslateModule,
    Checkbox2Module,
    SentimentAmendBoxModule,
    OverlayModule,
    ObserveWidthAndHeightModule
  ],
  exports: [ReportActionBarComponent]
})
export class ReportActionBarModule {}
