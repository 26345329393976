import { ViewEncapsulation } from '@angular/core';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  DoCheck
} from '@angular/core';
import { User, UserModel, Note } from '@ui-resources-angular';
import { TranslateService } from '@ngx-translate/core';
import { you as youString } from '@orlo/library/constants/strings';

@Component({
  selector: 'ssi-notes',
  templateUrl: './notes.component.html',
  styles: [],
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotesComponent implements OnInit, DoCheck {
  @Input() notes: Note[];
  @Input() isExpanded = false;

  @Output() onDelete = new EventEmitter();

  authUser: User;
  orderedNotes: Note[];

  constructor(
    private translate: TranslateService,
    private userModel: UserModel
  ) {}

  ngOnInit() {
    console.log('notes: ', this.notes);
    this.userModel.getAuthUser().then((user) => {
      this.authUser = user;
    });
    if (this.notes) {
      this.orderedNotes = this.notes.sort(this.sortByCreated);
    }
  }

  ngDoCheck(): void {
    this.orderedNotes = this.notes && this.notes.sort(this.sortByCreated);
  }

  private sortByCreated(a, b) {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return dateA < dateB ? 1 : -1;
  }

  public getContentForNote(note) {
    if (!this.authUser || !this.translate || !note || !note.author) {
      return '';
    }

    if (note.author.id === this.authUser.id) {
      return `<span class="you">${youString}: </span>` + note.content;
    }

    return note.content;
  }

  public getDisplayNameForNote(note) {
    if (!this.authUser || !this.translate || !note || !note.author) {
      return '';
    }

    if (note.author.id === this.authUser.id) {
      return this.translate.instant('YOURSELF');
    }

    return note.author.fullName;
  }
}
