import { Component, OnInit, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { API } from '@ui-resources-angular';
import { decodeStateParams } from '@orlo/common/utils';

@Component({
  selector: 'ssi-manage-user-permissions',
  templateUrl: './manage-user-permissions.component.html'
})
export class ManageUserPermissionsComponent implements OnInit {
  @Input() userPermissions;

  searchText: string;
  decodedStateParams: { [key: string]: any };

  constructor(private state: StateService, private api: API) {}

  ngOnInit() {
    this.decodedStateParams = decodeStateParams(this.state.params);

    this.api
      .get('settings/userPermission', {
        params: { user_id: this.decodedStateParams.userId }
      })
      .then(({ data }) => (this.userPermissions = data));
  }

  togglePermission(params) {
    return this.api.post(
      'settings/userPermission',
      Object.assign({}, params, { user_id: this.decodedStateParams.userId })
    );
  }
}
