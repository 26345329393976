<div
  class="navbar navbar-inverse navbar-fixed-top navbar-expand-custom"
  role="navigation"
>
  <div class="container-fluid">
    <div class="navbar-header">
      <button
        *ngIf="!browserlessMode"
        type="button"
        [class.resource-guide-active]="showPendoResourceGuide"
        class="navbar-toggle"
        (click)="navbarCollapsed = !navbarCollapsed"
      >
        <i class="fa fa-bars fa-inverse"></i>
      </button>
      <a
        href="javascript:;"
        (click)="navbarCollapsed = true; goHome()"
        class="navbar-brand"
      >
        <ssi-icon
          icon="brand"
          height="32"
          width="49"
        ></ssi-icon>
      </a>
    </div>
    <div
      class="navbar-collapse"
      [ngbCollapse]="navbarCollapsed"
    >
      <ul class="nav navbar-nav">
        <li
          class="dropdown"
          [ngClass]="{show: isDropdownOpen('MANAGE')}"
        >
          <a
            class="dropdown-toggle"
            (click)="toggleDropdownOpen('MANAGE')"
          >
            <i class="ssi ssi-toolbar-manage nav-item-icon"></i>
            <span
              class="nav-item-label"
              translate="MANAGE"
            ></span>
            <span
              class="label label-primary label-circle"
              *ngIf="notificationManager.totalValidations > 0"
            >{{notificationManager.totalValidations}}
            </span>
            <i class="ssi ssi-keyboard-arrow-down nav-item-caret"></i>
          </a>
          <ul class="dropdown-menu">
            <li
              uiSrefActive="active"
              *ngFor="let dashboard of dashboards"
            >
              <a
                (click)="navbarCollapsed = true"
                uiSref="auth.dashboard.view"
                [uiParams]="{id: dashboard.id}"
              >
                <i class="fa fa-tachometer"></i> {{ dashboard.name }}
              </a>
            </li>


            <li class="hidden-on-small-screens">
              <a
                (click)="navbarCollapsed = true"
                uiSref="auth.dashboard.create"
              >
                <i class="fa fa-plus"></i> <span translate="CREATE_NEW_DASHBOARD"></span>
              </a>
            </li>

            <li class="divider">
            </li>

            <li uiSrefActive="active">
              <a uiSref="auth.iftttAppletCollectionList">
                <i class="fa fa-play"></i> <span translate="AUTOMATION"></span>
              </a>
            </li>
            <li
              uiSrefActive="active"
              *ngIf="permissions?.validatePosts"
            >
              <a uiSref="auth.marketing.validateReplies">
                <i class="ssi ssi-total-approvals-requested"></i> <span translate="APPROVE_REPLIES"></span>&nbsp;<span
                  *ngIf="notificationManager.totalUnvalidatedReplies > 0"
                >({{notificationManager.totalUnvalidatedReplies}})</span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              *ngIf="permissions?.validatePosts"
              uiSrefActive="active"
            >
              <a uiSref="auth.marketing.validatePosts">
                <i class="ssi ss-fw ssi-tick"></i>
                <span translate="APPROVE_POSTS"></span>&nbsp;<span
                  *ngIf="notificationManager.totalUnvalidatedPosts > 0">({{notificationManager.totalUnvalidatedPosts}})</span>
              </a>
            </li>
          </ul>
        </li>
        <li
          class="dropdown"
          *ngIf="permissions?.communicate"
          [ngClass]="{show: isDropdownOpen('MARKETING')}"
        >
          <a
            class="dropdown-toggle"
            (click)="toggleDropdownOpen('MARKETING')"
          >
            <i class="ssi ssi-communicate-new nav-item-icon"></i>

            <span
              class="nav-item-label"
              translate="MARKETING"
            ></span>

            <i class="ssi ssi-keyboard-arrow-down nav-item-caret"></i>
          </a>

          <ul class="dropdown-menu">
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
            >
              <a uiSref="auth.marketing.index">
                <i class="ssi ssi-campaign nav-item-icon"></i>
                <span>Campaign Overview</span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
            >
              <a uiSref="auth.marketing.advertising.index">
                <i class="ssi ssi-reach nav-item-icon"></i>
                <span translate="BOOSTING"></span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
              *ngIf="permissions?.contentCalendar"
            >
              <a uiSref="auth.marketing.contentCalendar">
                <i class="ssi ssi-calendar-add nav-item-icon"></i>
                <span translate="CONTENT_CALENDAR"></span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
            >
              <a uiSref="auth.marketing.outboxSearch">
                <i class="ssi ssi-outbox nav-item-icon"></i>
                <span translate="OUTBOX"></span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
              *ngIf="permissions?.contentGenerator"
            >
              <a uiSref="auth.marketing.contentGenerator">
                <i class="fa fa-line-chart fa-fw nav-item-icon"></i>
                <span translate="RSS_FEEDS"></span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
              *ngIf="permissions?.publisher"
            >
              <a uiSref="auth.marketing.contentLibrary">
                <i class="fa fa-folder-open nav-item-icon"></i>
                <span translate="ASSET_LIBRARY"></span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              *ngIf="permissions?.bulkUploadPosts"
              uiSrefActive="active"
            >
              <a uiSref="auth.marketing.bulkUpload">
                <i class="ssi ss-fw ssi-correct-upload nav-item-icon"></i>
                <span translate="BULK_UPLOAD"></span>
              </a>
            </li>
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
            >
              <a uiSref="auth.marketing.draftsLibrary">
                <i class="ssi ss-fw ssi-drafts nav-item-icon"></i>
                <span>Drafts Library</span>
              </a>
            </li>

          </ul>
        </li>

        <li
          *ngIf="permissions?.inbox"
          uiSrefActive="active"
        >
          <a
            (click)="navbarCollapsed = true"
            uiSref="auth.inbox"
          >
            <i class="ssi ssi-toolbar-inbox nav-item-icon nav-item-icon"></i>
            <span
              class="nav-item-label"
              translate="INBOX"
            ></span>
            <span
              class="label label-primary label-circle"
              *ngIf="!inboxUnreadCountHidden && notificationManager.totalInboxMessages > 0"
            >{{notificationManager.totalInboxMessages}}</span></a>
        </li>
        <li
          class="dropdown"
          *ngIf="! orloInsightsEnabled || orloInsightsEnabled && permissions?.insights"
          [ngClass]="{show: isDropdownOpen('INSIGHTS')}"
        >
          <a
            class="dropdown-toggle"
            (click)="toggleDropdownOpen('INSIGHTS')"
          >
            <i class="ssi ssi-orlo-insights nav-item-icon"></i>
            <span
              class="nav-item-label"
              translate="INSIGHTS"
            ></span>
            <i class="ssi ssi-keyboard-arrow-down nav-item-caret"></i>
          </a>

          <ul class="dropdown-menu">
            <li
              *ngIf="! orloInsightsEnabled || permissions?.insights"
              uiSrefActive="active"
            >
              <a uiSref="auth.insights.reports.home">
                <i class="ssi ssi-insights-reports"></i> My Reports
              </a>
            </li>
            <li
              *ngIf="! orloInsightsEnabled || permissions?.insights && permissions?.viewMonitoring"
              uiSrefActive="active"
            >
              <a uiSref="auth.monitoring.monitoringStreamsNew">
                <i class="ssi ssi-my-streams"></i> My Streams
              </a>
            </li>
            <li
              *ngIf="! orloInsightsEnabled || permissions?.insights && permissions?.manageMonitoring"
              uiSrefActive="active"
            >
              <a
                class="no-text-transform"
                uiSref="auth.monitoring.monitoringStreamBuilder.desc"
              >
                <i class="ssi ssi-orlo-insights"></i> Create a Stream
              </a>
            </li>
            <li
              *ngIf="user?.hasCompanyPermission('administer_company')"
              uiSrefActive="active"
            >
              <a
                class="no-text-transform"
                uiSref="auth.insights.theme-detectors"
              >
                <i class="ssi ssi-theme-alerts"></i> Theme Alerts
              </a>
            </li>
          </ul>
        </li>

        <li
          class="dropdown hidden-on-small-screens"
          *ngIf="permissions?.viewAnalytics"
          [ngClass]="{show: isDropdownOpen('ANALYTICS')}"
        >
          <a
            class="dropdown-toggle"
            (click)="toggleDropdownOpen('ANALYTICS')"
          >
            <i class="ssi ssi-toolbar-analyse nav-item-icon"></i>

            <span
              class="nav-item-label"
              translate="ANALYTICS"
            ></span>

            <i class="ssi ssi-keyboard-arrow-down nav-item-caret"></i>
          </a>

          <ul class="dropdown-menu">
            <li
              *ngIf="permissions?.viewMarketingAnalytics"
              uiSrefActive="active"
            >
              <a uiSref="auth.analytics.marketing.build">
                <i class="ssi ssi-marketing-report"></i> <span translate="MARKETING_REPORT"></span>
              </a>
            </li>

            <li uiSrefActive="active">
              <a uiSref="auth.analytics.adStats.build">
                <i class="ssi ssi-marketing-report"></i> <span translate="ADVERTISING_REPORT"></span>
              </a>
            </li>

            <li
              *ngIf="permissions?.viewEngagementAnalytics"
              uiSrefActive="active"
            >
              <a uiSref="auth.analytics.engagement.build">
                <i class="ssi ssi-engagement-report"></i> <span translate="INBOX_REPORT"></span>
              </a>
            </li>

            <li
              *ngIf="permissions?.viewSurveyReports"
              uiSrefActive="active"
            >
              <a uiSref="auth.analytics.survey.build">
                <i class="ssi ssi-survey"></i> <span translate="SURVEY_REPORT"></span>
              </a>
            </li>

            <!-- <li
              class="divider"
              *ngIf="permissions?.viewEngagementAnalytics && permissions?.viewMarketingAnalytics"
            ></li> -->

            <li
              *ngIf="permissions?.viewCampaignAnalytics"
              uiSrefActive="active"
            >
              <a uiSref="auth.analytics.campaign.index">
                <i class="ssi ssi-campaign"></i> <span translate="CAMPAIGN_REPORT"></span>
              </a>
            </li>

            <li
              *ngIf="permissions?.validatePosts && outboxValidationTagging"
              uiSrefActive="active"
            >
              <a uiSref="auth.analytics.disapprovals.build">
                <i class="ssi ssi-website-report-icon"></i> <span translate="POST_APPROVALS_REPORT"></span>
              </a>
            </li>

            <li
              class="divider"
              *ngIf="permissions?.viewCampaignAnalytics"
            ></li>

            <li
              *ngIf="permissions?.viewAccountReport"
              uiSrefActive="active"
            >
              <a uiSref="auth.analytics.account.build">
                <i class="ssi ssi-account-report"></i> <span translate="ACCOUNT_REPORT"></span>
              </a>
            </li>

            <li
              *ngIf="permissions?.viewTeamReport"
              uiSrefActive="active"
            >
              <a uiSref="auth.analytics.team.build">
                <i class="ssi ssi-team-report"></i> <span translate="TEAM_REPORT"></span>
              </a>
            </li>

            <li class="divider"></li>

            <li uiSrefActive="active">
              <a uiSref="auth.analytics.competitorAnalysis.index">
                <i class="ssi ssi-competitor-analysis"></i> <span translate="BENCHMARKING"></span>
              </a>
            </li>
          </ul>
        </li>

        <li
          class="dropdown workflows-selector"
          [ngClass]="{show: isDropdownOpen('WORKFLOWS')}"
        >
          <a
            class="dropdown-toggle"
            (click)="toggleDropdownOpen('WORKFLOWS')"
          >
            <i class="ssi ssi-toolbar-workflows nav-item-icon"></i>
            <span
              class="nav-item-label"
              *ngIf="!workflowManager.getCurrentId()"
              translate="WORKFLOWS"
            ></span>
            <span
              class="nav-item-label"
              *ngIf="workflowManager.getCurrentId()"
            >{{workflowModel.get(workflowManager.getCurrentId())?.name | slice:0:30}}</span>
            <i class="ssi ssi-keyboard-arrow-down nav-item-caret"></i>
          </a>
          <ul class="dropdown-menu">
            <li
              (click)="navbarCollapsed = true"
              uiSrefActive="active"
            >
              <a uiSref="auth.workflows">
                <i class="fa fa-folder-open-o"></i> <span translate="MANAGE_WORKFLOWS"></span>
              </a>
            </li>
            <li
              class="divider"
              *ngIf="workflows?.length > 0"
            ></li>
            <li *ngIf="workflows?.length > 0">
              <a
                href="javascript:;"
                (click)="changeWorkflow({id: 0})"
              >
                <i
                  class="fa"
                  [ngClass]="{'fa-circle': !currentWorkflow, 'fa-circle-o': currentWorkflow}"
                >
                </i>
                <span translate="ALL_ACCOUNTS"></span>
              </a>
            </li>
            <li *ngFor="let group of workflows | slice:0:15">
              <a
                href="javascript:;"
                (click)="changeWorkflow(group)"
              >
                <i
                  class="fa"
                  [ngClass]="{'fa-circle': group == currentWorkflow, 'fa-circle-o': group != currentWorkflow}"
                >
                </i>
                {{ group.name | slice:0:30 }}
              </a>
            </li>
            <li
              class="divider"
              *ngIf="workflows?.length > 15"
            ></li>
            <li
              *ngIf="workflows?.length > 15"
              uiSrefActive="active"
            >
              <a uiSref="auth.workflows">
                <i class="fa fa-folder-open-o"></i> <span translate="VIEW_ALL_WORKFLOWS"></span>
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <ul class="nav navbar-nav navbar-right">
        <li class="compose-post">
          <a
            (click)="navbarCollapsed = true"
            href="javascript:;"
            [ssiTriggerPublish]
          >
            <i class="ssi ssi-compose-post"></i>
            <span translate="COMPOSE_POST"></span>
          </a>
        </li>

        <li *ngIf="!showPendoResourceGuide">
          <button
            class="navbar-live-chat-toggle"
            data-pendo="Live Chat launcher"
            [class.active]="doesWidgetHaveUpdatedConversations"
            (click)="toggleLiveChat($event)"
          >
            <i class="ssi ssi-livechat"></i>
          </button>
        </li>

        <li *ngIf="newVersionAvailable">
          <a
            href="javascript:;"
            (click)="updateManager?.showNewVersionModal()"
          >
            <ssi-icon
              icon="update"
              height="20"
              width="20"
            ></ssi-icon>
          </a>
        </li>

        <li
          class="dropdown"
          [ngClass]="{show: isDropdownOpen('SETTINGS')}"
        >
          <a
            class="dropdown-toggle"
            (click)="toggleDropdownOpen('SETTINGS')"
          >
            <i class="ssi ssi-user navbar-right-action-icon"></i>
            <i class="ssi ssi-keyboard-arrow-down nav-item-caret"></i>
          </a>
          <ul class="dropdown-menu">
            <li uiSrefActive="active">
              <a uiSref="auth.settings.index">
                <i class="fa fa-cog"></i> <span translate="SETTINGS"></span>
              </a>
            </li>
            <li *ngIf="!showPendoResourceGuide">
              <a
                href="http://support.orlo.tech"
                target="_blank"
              >
                <i class="fa fa-question-circle"></i> <span translate="HELP"></span>
              </a>
            </li>
            <li *ngIf="!showPendoResourceGuide">
              <a
                href="https://portal.productboard.com/orlo"
                target="_blank"
              >
                <i class="fa fa-external-link"></i>
                <span translate="PRODUCT_PORTAL"></span>
              </a>
            </li>
            <li *ngIf="!onlineTrainingDisabled">
              <a
                href="https://support.orlo.tech/customer-training-videos"
                target="_blank"
              >
                <i class="fa fa-external-link"></i>
                <span translate="ONLINE_TRAINING"></span>
              </a>
            </li>
            <li uiSrefActive="active">
              <a
                uiSref="diagnostics"
                target="_blank"
              >
                <i class="fa fa-medkit"></i> <span translate="DIAGNOSTICS"></span>
              </a>
            </li>
            <li>
              <a
                href="https://orlo.statuspage.io"
                target="_blank"
              >
                <i class="fa fa-info-circle"></i>
                <span translate="ORLO_STATUS_PAGE"></span>
              </a>
            </li>
            <li
              class="divider"
              *ngIf="languages?.length > 1"
            ></li>
            <li
              class="dropdown-submenu"
              *ngIf="languages?.length > 1"
              [ngClass]="{show: isDropdownOpen('LANGUAGE')}"
            >
              <a
                href="javascript:;"
                (click)="toggleDropdownOpen('LANGUAGE')"
              ><i class="fa fa-globe"></i> <span translate="LANGUAGE"></span></a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li
                  *ngFor="let language of languages | sortBy: 'local_name'"
                  [ngClass]="{active: currentLanguage == language.code}"
                >
                  <a
                    href="javascript:;"
                    (click)="setLanguage(language.code)"
                  >
                    {{ language.local_name }}
                  </a>
                </li>
              </ul>
            </li>
            <li class="divider"></li>
            <li>
              <a (click)="logout()">
                <i class="fa fa-sign-out"></i>
                <span
                  translate="LOGOUT_AS__NAME_"
                  [translateParams]="{name: user?.fullName}"
                >
                </span>
              </a>
            </li>
          </ul>
        </li>
        <li
          *ngIf="showPendoResourceGuide"
          class="resource-guide-item"
        >
        </li>
      </ul>
    </div>
  </div>
</div>