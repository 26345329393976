import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ImageMimetype } from '@ui-resources-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { KeyValueObject } from '../../../../../common/util';

@Component({
  selector: 'ssi-edit-file-modal',
  templateUrl: './edit-file-modal.component.html',
  styles: [],
  styleUrls: ['./edit-file-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditFileModalComponent implements OnInit {
  @Input() resolve: {
    file: { name: string; type: ImageMimetype; metadata: KeyValueObject };
  };
  @ViewChild('editFileForm') editFileForm: NgForm;

  expiryDate: string;
  maxFilenameLength = 250;

  ImageMimetype = ImageMimetype;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  expiryDateChanged($event) {
    console.log('Asset expiryDateChanged $event:', $event);
  }

  onFileNameChange($event) {}

  removeAssetExpiration() {
    this.resolve.file.metadata.expiry_date = null;
  }
}
