import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AccountsComponent } from './accounts.component';
import { TranslateModule } from '@ngx-translate/core';
import { ListSelectModule } from '../../../../../../common/components/list-select/list-select.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.manageIftttApplet.serviceSelected.accounts',
          url: '/accounts',
          component: AccountsComponent,
          resolve: AccountsComponent.resolve
        }
      ]
    }),
    ListSelectModule
  ],
  declarations: [AccountsComponent]
})
export class AccountsModule {}
