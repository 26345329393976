<div class="campaign-dd-and-button-container"
     [class.no-selected-options]="!post.campaign"
     [class.can-manage-campaigns]="canManageCampaigns">
  <ssi-dropdown-select-2 size="lg"
                         name="campaigns"
                         [required]="true"
                         [multiple]="false"
                         [filterable]="true"
                         [filterFn]="campaignsFilterFn"
                         [closeOnSelect]="false"
                         [placeholdersKeyword]="'campaign'"
                         [getTooltipContent]="getTooltipContent"
                         [options]="filteredCampaigns"
                         [(ngModel)]="post.campaign"
                         (ngModelChange)="selectCampaignChange($event)">

    <ng-template ssiTemplateSelector="headButtonLabel"
                 let-toggle="toggle"
                 let-expanded="expanded">
      <div class="select-placeholder"
           *ngIf="!post.campaign">
        <span>Select a campaign for this post...</span>
      </div>
      <div class="selected-options-container">
        <div class="chips-container"
             *ngIf="post.campaign">
          <div class="chip">
            <i class="ssi ssi-campaign-small"></i>
            <span>{{post.campaign.name}}</span>
            <i class="ssi ssi-delete-close"
               (click)="$event.stopPropagation(); deselectCampaign();"></i>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template ssiTemplateSelector="optionIcon"
                 let-parentCampaign="option">
      <i *ngIf="parentCampaign._children"
         class="ssi ssi-arrow-down-new chevron expand-campaign-icon"
         [class.down]="parentCampaign._children.expanded"
         (click)="$event.stopPropagation(); expandCollapseCampaign(parentCampaign)"></i>
    </ng-template>

    <ng-template ssiTemplateSelector="expandedContent"
                 let-parentCampaign="option"
                 let-selected="selected">
      <ssi-list-select2 *ngIf="parentCampaign._children && parentCampaign._children.expanded"
                        name="{{parentCampaign.id}}-children"
                        [required]="true"
                        [multiple]="false"
                        [filterable]="false"
                        [getTooltipContent]="getTooltipContent"
                        [options]="parentCampaign._children.filteredOptions"
                        [(ngModel)]="post.campaign"
                        (ngModelChange)="selectCampaignChange($event);"
                        (onOptionToggle)="selectCampaignChange($event, parentCampaign)">

        <ng-template ssiTemplateSelector="optionIcon">
          <!-- Just so it keeps the space filled -->
        </ng-template>
      </ssi-list-select2>
    </ng-template>
  </ssi-dropdown-select-2>

  <button *ngIf="canManageCampaigns"
          type="button"
          class="g-btn g-btn-lg create-campaign-button"
          (click)="openNewCampaignModal()"><i class="ssi ssi-add-small"></i><span translate="NEW_CAMPAIGN"></span>
  </button>
</div>