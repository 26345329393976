<div class="cl-dropdown-select-e-container">
  <h4>E</h4>
  <a target="_blank"
     href="https://github.com/orlo/web-app/blob/master/src/apps/angular/modules/auth/component-library/cl-dropdown-select/cl-dropdown-select-e/cl-dropdown-select-e.component.html">
    View code on GitHub
  </a>
  <p>headButtonStyleVariant="default"; headButtonLabel template;</p>
  <p>LG size (width: 100%) - single-select version; optionIcon template; expandedContent template</p>

  <div class="campaign-dd-and-button-container"
       [class.no-selected-options]="!selectedCampaign">
    <ssi-dropdown-select-2 size="lg"
                           name="campaigns"
                           [required]="true"
                           [multiple]="false"
                           [filterable]="true"
                           [filterFn]="campaignsFilterFn"
                           [closeOnSelect]="false"
                           [placeholdersKeyword]="'campaign'"
                           [getTooltipContent]="getTooltipContent"
                           [options]="filteredCampaigns"
                           [(ngModel)]="selectedCampaign"
                           (ngModelChange)="selectedOptionsChanged($event)">

      <ng-template ssiTemplateSelector="headButtonLabel"
                   let-toggle="toggle"
                   let-expanded="expanded">
        <div class="select-placeholder"
             *ngIf="!selectedCampaign">
          <span>Select a campaign for this post...</span>
        </div>
        <div class="selected-options-container">
          <div class="chips-container"
               *ngIf="selectedCampaign">
            <div class="chip">
              <i class="ssi ssi-campaign-small"></i>
              <span>{{selectedCampaign.name}}</span>
              <i class="ssi ssi-delete-close"
                 (click)="$event.stopPropagation(); deselectCampaign();"></i>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template ssiTemplateSelector="optionIcon"
                   let-parentCampaign="option">
        <i *ngIf="parentCampaign._children"
           class="ssi ssi-arrow-down-new chevron expand-campaign-icon"
           [class.down]="parentCampaign._children.expanded"
           (click)="$event.stopPropagation(); expandCollapseCampaign(parentCampaign)"></i>
      </ng-template>

      <ng-template ssiTemplateSelector="expandedContent"
                   let-parentCampaign="option"
                   let-selected="selected">
        <ssi-list-select2 *ngIf="parentCampaign._children && parentCampaign._children.expanded"
                          name="{{parentCampaign.id}}-children"
                          [required]="true"
                          [multiple]="false"
                          [filterable]="false"
                          [getTooltipContent]="getTooltipContent"
                          [options]="parentCampaign._children.filteredOptions"
                          [(ngModel)]="selectedCampaign"
                          (ngModelChange)="selectedOptionsChanged($event);"
                          (onOptionToggle)="onChildCampaignToggle($event, parentCampaign)">

          <ng-template ssiTemplateSelector="optionIcon">
            <!-- Just so it keeps the space filled -->
          </ng-template>
        </ssi-list-select2>
      </ng-template>
    </ssi-dropdown-select-2>

    <button type="button"
            class="g-btn g-btn-lg create-campaign-button"><i class="ssi ssi-add-small"></i><span>New Campaign</span></button>
  </div>
</div>