import {
  DocumentReference,
  QueryDocumentSnapshot
} from '@firebase/firestore-types';
import { diff, merge } from '@orlo/library/helpers/objects';

// @todo: rename it to clarify this is a Firestore model.
// May be possible to merge to some extent with existing Model class
// in 'ui-resources', KISS for now.

// @todo replace with own model module, when time allows :D

export default class Model {
  protected _data: any;
  protected _id: string;
  private _reference: DocumentReference;

  private readonly _originalData: any;

  // we provide 'this' to allow for late binding static constructor
  static CreateFromQueryDocumentSnapshot<T>(
    this: {
      new (id: string, data: any, reference, relations: any): T;
    },
    snapshot
  ) {
    const snapshotData =
      typeof snapshot.data === 'function' ? snapshot.data() : snapshot.data;

    return new this(snapshot.id, snapshotData, snapshot.ref, undefined);
  }

  constructor(
    id: string = '',
    data: any = {},
    reference?: DocumentReference,
    relations: any = {}
  ) {
    this._originalData = data;
    this.setID(id)
      .setReference(reference)
      .setDataAndRelations(data, relations);
  }

  public get asParameters(): any {
    return Object.assign({ id: this.id }, this._data);
  }

  public get data(): any {
    return merge(this._data);
  }

  public get id(): any {
    return this._id;
  }

  public get reference(): DocumentReference {
    return this._reference;
  }

  public get uid(): any {
    return this._id;
  }

  public diff(comparison: Model): any {
    if (this instanceof Model && comparison instanceof Model) {
      return diff(this.data, comparison.data);
    }

    return {};
  }

  public setDataAndRelations(data: any, relations: any = {}): this {
    try {
      return this.setData(data)
        .setRelations(relations)
        .initialise();
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  public setRelations(relations: any): this {
    if (!relations) {
      return this;
    }

    for (const relation in relations) {
      if (this.hasOwnProperty(relation)) {
        this[relation] = relations[relation];
      }
    }

    return this;
  }

  protected initialise() {
    return this;
  }

  protected setData(data: any): this {
    // @todo: validation

    if (data.hasOwnProperty('id')) {
      delete data['id'];
    }

    this._data = data;

    return this;
  }

  protected async update(updates) {
    try {
      await this.reference.update(updates);
    } catch (error) {
      console.error(error);
    }
  }

  private setID(id: string): this {
    // @todo: validation

    this._id = id;

    return this;
  }

  private setReference(reference: DocumentReference): this {
    this._reference = reference;

    return this;
  }
}
