import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LiveChatOfflineAgentNoticeComponent } from './live-chat-offline-agent-notice.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LiveChatOfflineAgentNoticeComponent],

  exports: [LiveChatOfflineAgentNoticeComponent],

  imports: [CommonModule, TranslateModule]
})
export class LiveChatOfflineAgentNoticeModule {}
