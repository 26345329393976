import { ViewEncapsulation } from '@angular/core';

import { Component, Input, Output, EventEmitter } from '@angular/core';

import { FilestackFile } from '../../../../../common/services/filestack/filestack.service';

@Component({
  selector: 'ssi-pesdk-modal',
  templateUrl: './pesdk-modal.component.html',
  styleUrls: ['./pesdk-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PesdkModalComponent {
  @Output() exportedImage = new EventEmitter<FilestackFile>();
  @Input() isPngImage = false;
  @Input() imageUrl = '';

  constructor() {}

  receiveDataFromChild(img: FilestackFile) {
    this.exportedImage.emit(img);
  }
}
