import twitterText from 'twitter-text';
import { instagramIdToUrlSegment } from '@orlo/common/utils/instagram-id-to-url-segment';
import { Outbox } from './publish/services/outboxModel';
import { Activity, ActivityAuthor } from './activity/services/activityModel';
import { Account } from './account/services/accountModel';
import { api } from './core/services/api';
import { utils } from 'js-data';
import {
  OutboxFileType,
  OutboxPublisher,
  OutboxPublisherFile,
  OutboxPublisherLink,
  OutboxPublisherText
} from './publish/services/outboxPublisher';
import { MentionWithIndices } from 'twitter-text';
import {
  ISocialNetworkMediaRestrictions,
  MediaRestrictions
} from './publish/services/media-restrictions';
import { mapToIterable } from '@orlo/common/utils';

export const filestackApiKey = 'AoX6QFadvSNSgoYeN6D61z';

export const twitter = { txt: twitterText };

export const apiAuthHeader = 'X-Authorization';

export function getMimetypeFromUrl(url: string): Promise<string> {
  return api
    .get<{ data: { mime: string } }>('server/mimetype', { params: { url } })
    .then(({ data: { mime } }) => {
      return mime;
    });
}

export enum ImageMimetype {
  Jpeg = 'image/jpeg',
  Pjpeg = 'image/pjpeg',
  Png = 'image/png',
  Gif = 'image/gif'
}

export enum DocumentMimeType {
  Pdf = 'application/pdf'
}

export enum VideoMimetype {
  Mp4 = 'video/mp4',
  Mov = 'video/quicktime'
}

export interface SocialNetworkLinkPreview {
  url: string;
  title: string;
  description: string;
  image?: string;
  hostname: string;
}

function getLinkPreviewDefaults(
  link: OutboxPublisherLink
): SocialNetworkLinkPreview {
  const { url } = link.data;
  const a = document.createElement('a');
  a.href = url.startsWith('http') ? url : `http://${url}`;
  const { hostname } = a;
  const preview: SocialNetworkLinkPreview = {
    url,
    hostname,
    title: link.data.preview.title,
    description: link.data.preview.description
  };
  if (link.data.preview.images.length > 0) {
    preview.image = link.data.preview.images[0].url;
  }
  return preview;
}

function getMetaValue(
  meta: Array<{
    name: string;
    value: string;
  }>,
  name: string
) {
  try {
    if (!(!!meta && Array.isArray(meta))) {
      throw new Error(`value for 'meta value meta' is not in expected format.`);
    }

    const matchingTag = meta.find((tag) => tag.name === name);

    if (matchingTag) {
      return matchingTag.value;
    }
  } catch (error) {
    console.error(error);

    return null;
  }
}

function removeEmptyObjectValues(obj: object) {
  Object.entries(obj).forEach(([key, value]) => {
    if (!value) {
      delete obj[key];
    }
  });
  return obj;
}

function getOpenGraphLinkPreview(link: OutboxPublisherLink) {
  return removeEmptyObjectValues({
    title:
      getMetaValue(link.data.preview.meta, 'og:site_name') ||
      getMetaValue(link.data.preview.meta, 'og:title'),
    description: getMetaValue(link.data.preview.meta, 'og:description'),
    image: getMetaValue(link.data.preview.meta, 'og:image')
  });
}

function getTwitterLinkPreview(link: OutboxPublisherLink) {
  // TODO - validate twitter:image dimensions are >= 300x157 and <= 4096x4096
  // source: https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image
  return removeEmptyObjectValues({
    title: getMetaValue(link.data.preview.meta, 'twitter:title'),
    description: getMetaValue(link.data.preview.meta, 'twitter:description'),
    image: getMetaValue(link.data.preview.meta, 'twitter:image')
  });
}

function getFacebookOrGooglePlusLinkPreview(
  links: OutboxPublisherLink[]
): SocialNetworkLinkPreview | undefined {
  const lastLink = links[links.length - 1];
  if (lastLink) {
    return Object.assign(
      getLinkPreviewDefaults(lastLink),
      getOpenGraphLinkPreview(lastLink)
    );
  }
}

export interface SocialNetwork {
  icon: {
    // todo - move this under the brand key
    mobile: string;
    web: string;
  };
  brand: {
    color: string;
  };
  maxPostCharacters: {
    public: number;
    private: number;
    reply: number;
  };
  urlBases: {
    hashtag?: string;
    username?: string;
  };
  /** Had to introduce because of property names inconsistency for insights responses
   * See more at https://orlo.atlassian.net/browse/CT-3747?focusedCommentId=22741
   */
  publishKeyInsights: string; // todo - rename this to something more generic
  publishKey: string; // todo - rename this to something more generic
  /** Property to be used as a group name for more than one account under the same network, e.g.
   * Instagram, Instagram Graph and Instagram Business are all type of 'instagram' and can be used
   * within the code to check against instagram only, as long as we keep the old instagram accounts
   */
  logoImg: string;
  accountTypeGroupName?: string;
  accountTypeName: string;
  accountTypeLabel: string;
  accountTypeId: string; // unique identiefier for an account type, equals account_type_id
  addPrivateReplyLinkAsAttachment?: boolean;
  supportsSocialLists?: boolean; // todo - move this under another key
  // todo - move this under the publish group
  targeting?: {
    ages?: {
      min: number[];
      max: number[];
    };
    gender?: Array<{
      label: string;
      value: number;
    }>;
    relationshipStatus?: Array<{
      label: string;
      value: number;
    }>;
    city?(
      q: string,
      account: Account
    ): Promise<
      Array<{
        label: string;
        code: string;
        country: {
          code: string;
        };
      }>
    >;
    boundaries?: any;
  };
  // todo - move this under the brand key
  socialInteractions?: {
    like: {
      icon: {
        mobile: string;
        web: string;
      };
      text: string;
    };
    unlike: {
      icon: {
        mobile: string;
        web: string;
      };
      text: string;
    };
  };
  profile?: {
    isViewable: boolean;
  };
  publish?: {
    autocompleteProfilesAvailable?: boolean;
    features: {
      album?: boolean;
      targeting?: boolean;
      customiseLinkPreview?: boolean;
      videoCaptions?: boolean;
    };
    targeting?: {
      geoLocations(account: Account, q?: string, urn?: string): Promise<any>;
      validate(accounts: Account[], targeting: object): Promise<any>;
    };
    getLinkPreview(
      links: OutboxPublisherLink[],
      files?: ReadonlyArray<OutboxPublisherFile>
    ): SocialNetworkLinkPreview | undefined | void;
    getTextPreview?(text: OutboxPublisherText, post: OutboxPublisher): string;
    getUrlDisplayText?(url: string): string;
  };
  mediaRestrictions: ISocialNetworkMediaRestrictions;
  statistics: {
    connections: {
      account: boolean;
      outbox: boolean;
    };
    reach: {
      account: boolean;
      outbox: boolean;
    };
    clicks: {
      account: boolean;
      outbox: boolean;
    };
    messagesIn: {
      account: boolean;
      outbox: boolean;
    };
    messagesOut: {
      account: boolean;
      outbox: boolean;
    };
    engagementRate: {
      account: boolean;
      outbox: boolean;
    };
    impressions: {
      account: boolean;
      outbox: boolean;
    };
    shares: {
      account: boolean;
      outbox: boolean;
    };
    likes: {
      account: boolean;
      outbox: boolean;
    };
    comments: {
      account: boolean;
      outbox: boolean;
    };
  };
  activity?: {
    permissions?: string[];
    changeResponseAccount?: boolean;
    responseMedia?: {
      textOptional?: boolean;
      image: {
        public: boolean;
        private?: boolean;
      };
    };
    flattenPrivateThreads?: boolean;
    response?: {
      validate({ text: string }): { isValid: boolean; errors: any };
    };
    getLargerAuthorAvatar(author: ActivityAuthor): string;
  };
  account?: {
    auth: {
      popupOptions: {
        width: number;
        height: number;
      };
      logoutOfSocialNetwork?(): Promise<any>;
    };
    getExternalUrl(account: Account): string;
  };
  outbox: {
    threadReplies: boolean;
  };
  advertising?: {
    accountTypeName: string;
    accountTypeLabel: string;
    logoImg: string;
    features: {
      budgetSuggestion: boolean;
    };
  };
  inboundInitiated?: boolean; // whether agent can reach user (false) or need to wait for user to contact them (true)
  getExternalLink?(post: Outbox): string; // todo - move this under the outbox group
  getPrivateReplyLink?(account: Account): string; // todo - move this under the activity group
}

export const socialNetworkSettings: { [networkKey: string]: SocialNetwork } = {
  Facebook: {
    icon: {
      mobile: 'ion-social-facebook',
      web: 'fa fa-facebook'
    },
    brand: {
      color: '#3b5998'
    },
    maxPostCharacters: {
      public: 60000,
      private: 2000,
      reply: 8000
    },
    publishKey: 'facebook',
    publishKeyInsights: 'facebook',
    logoImg: 'https://www.orlo.app/assets/account-logos/facebook.png',
    accountTypeName: 'facebook',
    accountTypeLabel: 'Facebook',
    accountTypeId: '3',
    urlBases: {
      hashtag: 'https://www.facebook.com/hashtag/',
      username: 'https://facebook.com/'
    },
    getExternalLink(post: Outbox) {
      return `https://facebook.com/${post.social_id.slice(
        post.social_id.indexOf('_') + 1
      )}`;
    },
    getPrivateReplyLink(account: Account) {
      return `https://m.me/${account.social_id}`;
    },
    addPrivateReplyLinkAsAttachment: false,
    targeting: {
      ages: {
        min: [13, 15, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65],
        max: [15, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65]
      },
      gender: [
        {
          label: 'Male',
          value: 1
        },
        {
          label: 'Female',
          value: 2
        }
      ],
      relationshipStatus: [
        {
          label: 'Single',
          value: 1
        },
        {
          label: 'In a relationship',
          value: 2
        },
        {
          label: 'Married',
          value: 3
        },
        {
          label: 'Engaged',
          value: 4
        }
      ],
      city(q: string, account: Account) {
        return api
          .get<{
            data: {
              results: Array<{
                country_code: string;
                country_name: string;
                key: string;
                name: string;
                region: string;
                region_id: number;
                supports_city: boolean;
                supports_region: boolean;
                type: string;
              }>;
            };
          }>('outbox/facebookTargeting', {
            params: {
              q,
              type: 'adgeolocation',
              account_id: account.id
            }
          })
          .then(({ data }) => {
            return data.results
              .filter((result) => result.type === 'city')
              .map((result) => {
                return {
                  code: result.key,
                  label: `${result.name}, ${result.region}, ${result.country_name}`,
                  country: {
                    code: result.country_code
                  }
                };
              });
          });
      }
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    profile: {
      isViewable: true
    },
    publish: {
      autocompleteProfilesAvailable: true,
      features: {
        album: false,
        targeting: true,
        videoCaptions: true
      },
      getLinkPreview: getFacebookOrGooglePlusLinkPreview,
      getTextPreview(text: OutboxPublisherText, post: OutboxPublisher): string {
        const lastLink = text.links[text.links.length - 1];
        if (
          lastLink &&
          text.value.endsWith(lastLink.data.url) &&
          post.files.length === 0
        ) {
          return text.value.slice(0, lastLink.indices.start).trim();
        } else {
          return text.value;
        }
      }
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.facebook,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      permissions: ['delete-comment'],
      responseMedia: {
        textOptional: true,
        image: {
          public: true,
          private: true
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        if (!author || !author.id) {
          return '';
        }
        return author.avatar;
      },
      flattenPrivateThreads: true // Could do with bikeshedding this // what, take it for a snog ???
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://facebook.com/${account.social_id}`;
      },
      auth: {
        popupOptions: { width: 580, height: 400 }
      }
    },
    outbox: {
      threadReplies: false
    },
    advertising: {
      accountTypeName: 'Facebook Ads',
      accountTypeLabel: 'Meta Ads',
      logoImg: 'https://www.orlo.app/assets/account-logos/meta.png',
      features: {
        budgetSuggestion: false
      }
    }
  },
  'Facebook Group': {
    icon: {
      mobile: 'ion-social-facebook',
      web: 'fa fa-facebook'
    },
    brand: {
      color: '#3b5998'
    },
    maxPostCharacters: {
      public: 60000,
      private: 8000,
      reply: 60000
    },
    publishKey: 'facebook-group',
    publishKeyInsights: 'facebook-group',
    logoImg: 'https://www.orlo.app/assets/account-logos/facebook-group.png',
    accountTypeName: 'facebookgroup',
    accountTypeLabel: 'Facebook Group',
    accountTypeId: '15',
    urlBases: {
      hashtag: 'https://www.facebook.com/hashtag/',
      username: 'https://facebook.com/'
    },
    getExternalLink(post: Outbox) {
      return `https://facebook.com/${post.social_id.slice(
        post.social_id.indexOf('_') + 1
      )}`;
    },
    getPrivateReplyLink(account: Account) {
      return `https://m.me/${account.social_id}`;
    },
    addPrivateReplyLinkAsAttachment: false,
    targeting: {
      ages: {
        min: [13, 15, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65],
        max: [15, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65]
      },
      gender: [
        {
          label: 'Male',
          value: 1
        },
        {
          label: 'Female',
          value: 2
        }
      ],
      relationshipStatus: [
        {
          label: 'Single',
          value: 1
        },
        {
          label: 'In a relationship',
          value: 2
        },
        {
          label: 'Married',
          value: 3
        },
        {
          label: 'Engaged',
          value: 4
        }
      ],
      city(q: string, account: Account) {
        return api
          .get<{
            data: {
              results: Array<{
                country_code: string;
                country_name: string;
                key: string;
                name: string;
                region: string;
                region_id: number;
                supports_city: boolean;
                supports_region: boolean;
                type: string;
              }>;
            };
          }>('outbox/facebookTargeting', {
            params: {
              q,
              type: 'adgeolocation',
              account_id: account.id
            }
          })
          .then(({ data }) => {
            return data.results
              .filter((result) => result.type === 'city')
              .map((result) => {
                return {
                  code: result.key,
                  label: `${result.name}, ${result.region}, ${result.country_name}`,
                  country: {
                    code: result.country_code
                  }
                };
              });
          });
      }
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    profile: {
      isViewable: true
    },
    publish: {
      autocompleteProfilesAvailable: true,
      features: {
        album: false,
        targeting: true,
        videoCaptions: true
      },
      getLinkPreview: getFacebookOrGooglePlusLinkPreview,
      getTextPreview(text: OutboxPublisherText, post: OutboxPublisher): string {
        const lastLink = text.links[text.links.length - 1];
        if (
          lastLink &&
          text.value.endsWith(lastLink.data.url) &&
          post.files.length === 0
        ) {
          return text.value.slice(0, lastLink.indices.start).trim();
        } else {
          return text.value;
        }
      }
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.facebookgroup,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      permissions: ['delete-comment'],
      responseMedia: {
        image: {
          public: true
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        if (!author || !author.id) {
          return '';
        }
        return author.avatar;
      },
      flattenPrivateThreads: true // Could do with bikeshedding this // what, take it for a snog ???
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://facebook.com/${account.social_id}`;
      },
      auth: {
        popupOptions: { width: 580, height: 400 }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  Twitter: {
    icon: {
      mobile: 'ssi ssi-x-logo',
      web: 'ssi ssi-x-logo'
    },
    brand: {
      color: '#000'
    },
    maxPostCharacters: {
      public: 280,
      private: 10000,
      reply: 280
    },
    publishKey: 'twitter',
    publishKeyInsights: 'twitter',
    logoImg: 'https://www.orlo.app/assets/account-logos/x.png',
    accountTypeName: 'twitter',
    accountTypeLabel: 'X',
    accountTypeId: '2',
    urlBases: {
      hashtag: 'https://twitter.com/search?q=%23',
      username: 'https://twitter.com/'
    },
    getExternalLink(post: Outbox) {
      return `https://twitter.com/${post.account.username}/status/${post.social_id}`;
    },
    getPrivateReplyLink(account: Account) {
      return `https://twitter.com/messages/compose?recipient_id=${account.social_id}`;
    },
    addPrivateReplyLinkAsAttachment: true,
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-ios-heart-outline',
          web: 'fa fa-heart-o'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-ios-heart',
          web: 'ssi ssi-liked'
        },
        text: 'Unlike'
      }
    },
    profile: {
      isViewable: true
    },
    supportsSocialLists: true,
    publish: {
      autocompleteProfilesAvailable: true,
      features: {
        videoCaptions: true
      },
      getLinkPreview(
        links: OutboxPublisherLink[]
      ): SocialNetworkLinkPreview | undefined {
        const lastLink = links[links.length - 1];
        if (lastLink) {
          return Object.assign(
            getLinkPreviewDefaults(lastLink),
            getOpenGraphLinkPreview(lastLink),
            getTwitterLinkPreview(lastLink)
          );
        }
      },
      getTextPreview(text: OutboxPublisherText, post: OutboxPublisher): string {
        if (post.reply) {
          let { value } = text;
          [...post.mentions].reverse().forEach((mention) => {
            value =
              value.slice(0, mention.indices.start) +
              value.slice(mention.indices.end);
          });
          return value.trim();
        } else {
          return text.value;
        }
      },
      getUrlDisplayText(url: string) {
        return url.replace(/^https?:\/\//, '');
      }
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.twitter,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: false,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      changeResponseAccount: true,
      responseMedia: {
        textOptional: true,
        image: {
          public: true,
          private: true
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        if (!author || !author.avatar) {
          return '';
        }
        return author.avatar && author.avatar.replace('_bigger', '_200x200');
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://twitter.com/${account.username}`;
      },
      auth: {
        popupOptions: { width: 495, height: 645 }
      }
    },
    outbox: {
      threadReplies: true
    },
    advertising: {
      accountTypeName: 'Twitter Ads',
      accountTypeLabel: 'Twitter Ads',
      logoImg: 'https://www.orlo.app/assets/account-logos/x.png',
      features: {
        budgetSuggestion: false
      }
    }
  },
  LinkedIn: {
    icon: {
      mobile: 'ion-social-linkedin',
      web: 'fa fa-linkedin'
    },
    brand: {
      color: '#007bb6'
    },
    maxPostCharacters: {
      public: 3000,
      private: 600,
      reply: 1250
    },
    publishKey: 'linkedin',
    publishKeyInsights: 'linkedin',
    logoImg: 'https://www.orlo.app/assets/account-logos/linkedin.png',
    accountTypeName: 'linkedin',
    accountTypeLabel: 'LinkedIn',
    accountTypeId: '4',
    urlBases: {
      username: 'https://www.linkedin.com/company/'
    },
    getExternalLink(post: Outbox) {
      if (!post.social_id) {
        return;
      }
      if (post.social_id.startsWith('urn')) {
        return 'https://www.linkedin.com/feed/update/' + post.social_id;
      }
      if (!post.social_id.match(/^UPDATE\-\w+\-(\d+)$/)) {
        return;
      }
      const [, id] = post.social_id.match(/^UPDATE\-\w+\-(\d+)$/);
      return `https://www.linkedin.com/hp/update/${id}`;
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    publish: {
      autocompleteProfilesAvailable: true,
      features: {
        targeting: true,
        customiseLinkPreview: true,
        videoCaptions: true
      },
      targeting: {
        geoLocations(account: Account, q?: string, urn?: string) {
          if (q) {
            return api
              .get<{
                data: {
                  results: Array<{
                    displayText: string;
                    entity: string;
                  }>;
                };
              }>('outbox/linkedInTargeting', {
                params: {
                  q,
                  account_id: account.id
                }
              })
              .then(({ data }) => {
                return data.results.map((result) => {
                  return {
                    code: result.entity,
                    label: result.displayText
                  };
                });
              });
          } else if (urn) {
            return api
              .get<{
                data: {
                  results: Array<{
                    id: number;
                    defaultLocalizedName: {
                      value: string;
                    };
                  }>;
                };
              }>('outbox/linkedInTargeting', {
                params: {
                  urn,
                  account_id: account.id
                }
              })
              .then(({ data }) => {
                return data.results.map((result) => {
                  return {
                    code: urn,
                    label: result.defaultLocalizedName.value
                  };
                });
              });
          }
        },
        validate(accounts: Account[], targeting: object): Promise<any> {
          const accountTypeIds = accounts.map((account) => account.id);
          return api
            .post('outbox/validateLinkedInTargeting', {
              account_ids: accountTypeIds,
              targeting
            })
            .then(({ data }) => data);
        }
      },
      getLinkPreview(
        links: OutboxPublisherLink[],
        files: OutboxPublisherFile[]
      ): SocialNetworkLinkPreview | undefined {
        try {
          if (!(!!links && Array.isArray(links))) {
            throw new Error(
              `value for 'link preview links' is not in expected format.`
            );
          }

          if (links.length > 0) {
            const link = links[0];
            const result = Object.assign(
              getOpenGraphLinkPreview(link),
              getLinkPreviewDefaults(link)
            );
            if (files.length > 0 && files[0].type === OutboxFileType.Image) {
              result.image = files[0].url;
            } else if (link.data.preview.images.length > 0) {
              result.image =
                link.data.preview.images[
                  link.data.preview.selectedImageIndex
                ].url;
            }
            return result;
          }
        } catch (error) {
          console.error(error);

          return;
        }
      }
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.linkedin,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: false,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.linkedin.com/company-beta/${account.social_id}/`;
      },
      auth: {
        popupOptions: { width: 527, height: 582 }
      }
    },
    outbox: {
      threadReplies: false
    },
    activity: {
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      },
      responseMedia: {
        image: {
          public: true,
          private: false
        }
      }
    },
    advertising: {
      accountTypeName: 'LinkedIn Ads',
      accountTypeLabel: 'LinkedIn Ads',
      logoImg: 'https://www.orlo.app/assets/account-logos/linkedin.png',
      features: {
        budgetSuggestion: true
      }
    }
  },
  'LinkedIn Personal': {
    icon: {
      mobile: 'ion-social-linkedin',
      web: 'fa fa-linkedin'
    },
    brand: {
      color: '#007bb6'
    },
    maxPostCharacters: {
      public: 3000,
      private: 600,
      reply: 1250
    },
    publishKey: 'linkedin',
    publishKeyInsights: 'linkedin',
    logoImg: 'https://www.orlo.app/assets/account-logos/linkedin.png',
    accountTypeName: 'linkedinpersonal',
    accountTypeLabel: 'LinkedIn Personal',
    accountTypeId: '23',
    urlBases: {
      username: 'https://www.linkedin.com/in/'
    },
    getExternalLink(post: Outbox) {
      if (!post.social_id) {
        return;
      }
      if (post.social_id.startsWith('urn')) {
        return 'https://www.linkedin.com/feed/update/' + post.social_id;
      }
      if (!post.social_id.match(/^UPDATE\-\w+\-(\d+)$/)) {
        return;
      }
      const [, id] = post.social_id.match(/^UPDATE\-\w+\-(\d+)$/);
      return `https://www.linkedin.com/hp/update/${id}`;
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    publish: {
      autocompleteProfilesAvailable: true,
      features: {
        customiseLinkPreview: true,
        videoCaptions: true
      },
      getLinkPreview(
        links: OutboxPublisherLink[],
        files: OutboxPublisherFile[]
      ): SocialNetworkLinkPreview | undefined {
        try {
          if (!(!!links && Array.isArray(links))) {
            throw new Error(
              `value for 'link preview links' is not in expected format.`
            );
          }

          if (links.length > 0) {
            const link = links[0];
            const result = Object.assign(
              getOpenGraphLinkPreview(link),
              getLinkPreviewDefaults(link)
            );
            if (files.length > 0 && files[0].type === OutboxFileType.Image) {
              result.image = files[0].url;
            } else if (link.data.preview.images.length > 0) {
              result.image =
                link.data.preview.images[
                  link.data.preview.selectedImageIndex
                  ].url;
            }
            return result;
          }
        } catch (error) {
          console.error(error);

          return;
        }
      }
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.linkedin,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: false,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.linkedin.com/in/${account.username}`;
      },
      auth: {
        popupOptions: { width: 527, height: 582 }
      }
    },
    outbox: {
      threadReplies: false
    },
    activity: {
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      },
      responseMedia: {
        image: {
          public: true,
          private: false
        }
      }
    }
  },
  YouTube: {
    icon: {
      mobile: 'ion-social-youtube',
      web: 'fa fa-youtube-play'
    },
    brand: {
      color: '#FF0000'
    },
    maxPostCharacters: {
      public: 4000,
      private: 4000,
      reply: 4000
    },
    publishKey: 'youtube',
    publishKeyInsights: 'youtube',
    logoImg: 'https://www.orlo.app/assets/account-logos/youtube.png',
    accountTypeName: 'youtube',
    accountTypeLabel: 'YouTube',
    accountTypeId: '6',
    urlBases: {},
    getExternalLink(post: Outbox) {
      return `https://www.youtube.com/watch?v=${post.social_id}`;
    },
    activity: {
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    publish: {
      features: {
        videoCaptions: true
      },
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.youtube,
    statistics: {
      connections: {
        account: false,
        outbox: false
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: false,
        outbox: false
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: false,
        outbox: false
      },
      impressions: {
        account: false,
        outbox: false
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.youtube.com/channel/${account.social_id}`;
      },
      auth: {
        popupOptions: { width: 452, height: 633 }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  Instagram: {
    icon: {
      mobile: 'ion-social-instagram-outline',
      web: 'fa fa-instagram'
    },
    brand: {
      color: '#3f729b'
    },
    maxPostCharacters: {
      public: 2200,
      private: 1000,
      reply: 300
    },
    publishKey: 'instagram',
    publishKeyInsights: 'instagram',
    logoImg: 'https://www.orlo.app/assets/account-logos/instagram.png',
    accountTypeGroupName: 'instagram',
    accountTypeName: 'instagram',
    accountTypeLabel: 'Instagram Old',
    accountTypeId: '7',
    urlBases: {
      hashtag: 'https://instagram.com/explore/tags/',
      username: 'https://instagram.com/'
    },
    getExternalLink(post: Outbox) {
      const [, mediaId] = post.social_id.match(/^(\d+)_\d+$/);
      return `https://instagram.com/p/${instagramIdToUrlSegment(mediaId)}/`;
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-ios-heart-outline',
          web: 'fa fa-heart-o'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-ios-heart',
          web: 'ssi ssi-liked'
        },
        text: 'Unlike'
      }
    },
    profile: {
      isViewable: true
    },
    publish: {
      features: {},
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.instagram,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      responseMedia: {
        image: {
          public: false,
          private: true
        }
      },
      response: {
        validate({ text }: { text: string }) {
          const errors: any = {};
          if (text === text.toUpperCase()) {
            errors.uppercase = {};
          }

          const MAX_HASHTAGS = 4;
          const hashtags = twitterText.extractHashtags(text);
          if (hashtags.length > MAX_HASHTAGS) {
            errors.hashtags = {
              amount: {
                max: MAX_HASHTAGS,
                total: hashtags.length
              }
            };
          }

          const MAX_LINKS = 1;
          let linksCount = 0;
          const matchLink = /https?:\/\//g;
          let m;
          // tslint:disable-next-line
          while ((m = matchLink.exec(text))) {
            linksCount++;
          }
          if (linksCount > MAX_LINKS) {
            errors.links = {
              amount: {
                max: MAX_LINKS,
                total: linksCount
              }
            };
          }

          return { isValid: Object.keys(errors).length === 0, errors };
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.instagram.com/${account.username}/`;
      },
      auth: {
        popupOptions: { width: 500, height: 500 },
        logoutOfSocialNetwork() {
          return new utils.Promise((resolve) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = resolve;
            img.src = 'https://www.instagram.com/accounts/logout/';
            // fallback for if the image load events dont fire
            setTimeout(resolve, 2000);
          });
        }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  'Instagram Business': {
    icon: {
      mobile: 'ion-social-instagram-outline',
      web: 'fa fa-instagram'
    },
    brand: {
      color: '#3f729b'
    },
    maxPostCharacters: {
      public: 2200,
      private: 1000,
      reply: 2200
    },
    publishKey: 'instagram_graph',
    publishKeyInsights: 'instagram_graph',
    logoImg: 'https://www.orlo.app/assets/account-logos/instagram_graph.png',
    accountTypeGroupName: 'instagram',
    accountTypeName: 'instagrambusiness',
    accountTypeLabel: 'Instagram',
    accountTypeId: '12',
    urlBases: {
      hashtag: 'https://instagram.com/explore/tags/',
      username: 'https://instagram.com/'
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-ios-heart-outline',
          web: 'fa fa-heart-o'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-ios-heart',
          web: 'ssi ssi-liked'
        },
        text: 'Unlike'
      }
    },
    profile: {
      isViewable: true
    },
    publish: {
      features: {},
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.instagrambusiness,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      responseMedia: {
        image: {
          public: false,
          private: true
        }
      },
      response: {
        validate({ text }: { text: string }) {
          const errors: any = {};

          const MAX_HASHTAGS = 4;
          const hashtags = twitterText.extractHashtags(text);
          if (hashtags.length > MAX_HASHTAGS) {
            errors.hashtags = {
              amount: {
                max: MAX_HASHTAGS,
                total: hashtags.length
              }
            };
          }

          const MAX_LINKS = 1;
          let linksCount = 0;
          const matchLink = /https?:\/\//g;
          let m;
          // tslint:disable-next-line
          while ((m = matchLink.exec(text))) {
            linksCount++;
          }
          if (linksCount > MAX_LINKS) {
            errors.links = {
              amount: {
                max: MAX_LINKS,
                total: linksCount
              }
            };
          }

          return { isValid: Object.keys(errors).length === 0, errors };
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.instagram.com/${account.username}/`;
      },
      auth: {
        popupOptions: { width: 500, height: 500 },
        logoutOfSocialNetwork() {
          return new utils.Promise((resolve) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = resolve;
            img.src = 'https://www.instagram.com/accounts/logout/';
            // fallback for if the image load events dont fire
            setTimeout(resolve, 2000);
          });
        }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  'Live Chat': {
    icon: {
      mobile: 'ssi ssi-headphones',
      web: 'ssi ssi-headphones'
    },
    brand: {
      color: '#8fcac7'
    },
    maxPostCharacters: {
      public: 60000,
      private: 8000,
      reply: 60000
    },
    publishKey: 'livechat',
    publishKeyInsights: 'livechat',
    logoImg: 'https://www.orlo.app/assets/account-logos/live-chat.png',
    accountTypeName: 'livechat',
    accountTypeLabel: 'Live Chat',
    accountTypeId: '11',
    urlBases: {},
    addPrivateReplyLinkAsAttachment: false,
    profile: {
      isViewable: true
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.livechat,
    statistics: {
      connections: {
        account: false,
        outbox: false
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: false,
        outbox: false
      },
      messagesIn: {
        account: true,
        outbox: false
      },
      messagesOut: {
        account: false,
        outbox: false
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: false,
        outbox: false
      },
      shares: {
        account: false,
        outbox: false
      },
      likes: {
        account: false,
        outbox: false
      },
      comments: {
        account: false,
        outbox: false
      }
    },
    activity: {
      changeResponseAccount: true,
      responseMedia: {
        textOptional: true,
        image: {
          public: true
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    outbox: {
      threadReplies: false
    },
    publish: {
      features: {},
      getLinkPreview() {}
    }
  },
  'Review Trackers': {
    icon: {
      mobile: 'ssi ssi-reviews',
      web: 'ssi ssi-reviews'
    },
    brand: {
      color: '#12ACA4'
    },
    maxPostCharacters: {
      public: 2000,
      private: 2000,
      reply: 2000
    },
    publishKey: 'review_trackers',
    publishKeyInsights: 'review_trackers',
    logoImg: 'https://www.orlo.app/assets/account-logos/review_trackers.png',
    accountTypeName: 'reviewtrackers',
    accountTypeLabel: 'Reviews',
    accountTypeId: '17',
    urlBases: {},
    addPrivateReplyLinkAsAttachment: false,
    profile: {
      isViewable: true
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.reviewtrackers,
    statistics: {
      connections: {
        account: false,
        outbox: false
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: false,
        outbox: false
      },
      messagesIn: {
        account: false,
        outbox: false
      },
      messagesOut: {
        account: false,
        outbox: false
      },
      engagementRate: {
        account: false,
        outbox: false
      },
      impressions: {
        account: false,
        outbox: false
      },
      shares: {
        account: false,
        outbox: false
      },
      likes: {
        account: false,
        outbox: false
      },
      comments: {
        account: false,
        outbox: false
      }
    },
    activity: {
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    outbox: {
      threadReplies: false
    },
    publish: {
      features: {},
      getLinkPreview() {}
    },
    inboundInitiated: true
  },
  'Twilio SMS': {
    icon: {
      mobile: 'ssi ssi-sms',
      web: 'ssi ssi-sms'
    },
    brand: {
      color: '#14BAE3'
    },
    maxPostCharacters: {
      public: 1600,
      private: 1600,
      reply: 1600
    },
    publishKey: 'twilio-sms',
    publishKeyInsights: 'sms',
    logoImg: 'https://www.orlo.app/assets/account-logos/twilio-sms.png',
    accountTypeName: 'twiliosms',
    accountTypeLabel: 'SMS',
    accountTypeId: '13',
    urlBases: {},
    addPrivateReplyLinkAsAttachment: false,
    profile: {
      isViewable: true
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.twiliosms,
    statistics: {
      connections: {
        account: false,
        outbox: false
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: false,
        outbox: false
      },
      messagesIn: {
        account: true,
        outbox: false
      },
      messagesOut: {
        account: false,
        outbox: false
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: false,
        outbox: false
      },
      shares: {
        account: false,
        outbox: false
      },
      likes: {
        account: false,
        outbox: false
      },
      comments: {
        account: false,
        outbox: false
      }
    },
    activity: {
      changeResponseAccount: true,
      responseMedia: {
        textOptional: true,
        image: {
          public: true
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    outbox: {
      threadReplies: false
    },
    publish: {
      features: {},
      getLinkPreview() {}
    },
    inboundInitiated: true
  },
  'Twilio WhatsApp': {
    icon: {
      mobile: 'ssi ssi-whatsapp',
      web: 'ssi ssi-whatsapp'
    },
    brand: {
      color: '#25D366'
    },
    maxPostCharacters: {
      public: 1600,
      private: 1600,
      reply: 1600
    },
    publishKey: 'twilio-whatsapp',
    publishKeyInsights: 'whatsapp',
    logoImg: 'https://www.orlo.app/assets/account-logos/twilio-whatsapp.png',
    accountTypeName: 'twiliowhatsapp',
    accountTypeLabel: 'WhatsApp',
    accountTypeId: '14',
    urlBases: {},
    addPrivateReplyLinkAsAttachment: false,
    profile: {
      isViewable: true
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.twiliowhatsapp,
    statistics: {
      connections: {
        account: false,
        outbox: false
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: false,
        outbox: false
      },
      messagesIn: {
        account: true,
        outbox: false
      },
      messagesOut: {
        account: false,
        outbox: false
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: false,
        outbox: false
      },
      shares: {
        account: false,
        outbox: false
      },
      likes: {
        account: false,
        outbox: false
      },
      comments: {
        account: false,
        outbox: false
      }
    },
    activity: {
      changeResponseAccount: true,
      responseMedia: {
        textOptional: true,
        image: {
          public: true,
          private: true
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    outbox: {
      threadReplies: false
    },
    publish: {
      features: {},
      getLinkPreview() {}
    },
    inboundInitiated: true
  },
  TikTok: {
    icon: {
      mobile: 'ssi ssi-tik-tok',
      web: 'ssi ssi-tik-tok'
    },
    brand: {
      color: '#69C9D0'
    },
    maxPostCharacters: {
      public: 2200,
      private: 500,
      reply: 2200
    },
    publishKey: 'tiktok',
    publishKeyInsights: 'tiktok',
    logoImg: 'https://www.orlo.app/assets/account-logos/tiktok.png',
    accountTypeName: 'TikTok',
    accountTypeLabel: 'TikTok',
    accountTypeId: '16',
    urlBases: {},
    publish: {
      features: {},
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.tiktok,
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.tiktok.com/${account.username}/`;
      },
      auth: {
        popupOptions: { width: 500, height: 500 },
        logoutOfSocialNetwork() {
          return new utils.Promise((resolve) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = resolve;
            img.src = 'https://www.tiktok.com/logout/';
            // fallback for if the image load events dont fire
            setTimeout(resolve, 2000);
          });
        }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  'Nextdoor Agency': {
    icon: {
      mobile: 'ssi ssi-nextdoor',
      web: 'ssi ssi-nextdoor'
    },
    brand: {
      color: '#B8EB51'
    },
    maxPostCharacters: {
      public: 8192,
      private: 500,
      reply: 2200
    },
    publishKey: 'nextdoor',
    publishKeyInsights: 'nextdoor',
    logoImg: 'https://www.orlo.app/assets/account-logos/nextdoor.png',
    accountTypeName: 'nextdooragency',
    accountTypeLabel: 'Nextdoor',
    accountTypeId: '18',
    urlBases: {},
    publish: {
      features: {
        targeting: true
      },
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.nextdooragency,
    targeting: {
      boundaries(account_id: Account) {
        return api
          .get<{
            data: {
              results: Array<{
                name: string;
                geometry: string;
                geometry_id: number;
                group_id: number;
                total_members: string;
                type: string;
              }>;
            };
          }>('/outbox/nextdoorBoundaries', {
            params: {
              account_id
            }
          })
          .then(({ data }) => {
            return data.results;
          });
      }
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.nextdoor.com/profile/${account.social_id}`;
      },
      auth: {
        popupOptions: { width: 500, height: 500 },
        logoutOfSocialNetwork() {
          return null;
        }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  'TikTok Business': {
    icon: {
      mobile: 'ssi ssi-tik-tok',
      web: 'ssi ssi-tik-tok'
    },
    brand: {
      color: '#69C9D0'
    },
    maxPostCharacters: {
      public: 2200,
      private: 150,
      reply: 150
    },
    publishKey: 'tiktok_business',
    publishKeyInsights: 'tiktok_business',
    logoImg: 'https://www.orlo.app/assets/account-logos/tiktok.png',
    accountTypeName: 'TikTokBusiness',
    accountTypeLabel: 'TikTok Business',
    accountTypeId: '19',
    urlBases: {},
    publish: {
      features: {},
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.tiktokbusiness,
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.tiktok.com/${account.username}/`;
      },
      auth: {
        popupOptions: { width: 500, height: 500 },
        logoutOfSocialNetwork() {
          return new utils.Promise((resolve) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = resolve;
            img.src = 'https://www.tiktok.com/logout/';
            // fallback for if the image load events dont fire
            setTimeout(resolve, 2000);
          });
        }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  'Nextdoor Agency US': {
    icon: {
      mobile: 'ssi ssi-nextdoor',
      web: 'ssi ssi-nextdoor'
    },
    brand: {
      color: '#B8EB51'
    },
    maxPostCharacters: {
      public: 8192,
      private: 500,
      reply: 2200
    },
    publishKey: 'nextdoor_agency_us',
    publishKeyInsights: 'nextdoor_agency_us',
    logoImg: 'https://www.orlo.app/assets/account-logos/nextdoor.png',
    accountTypeName: 'NextdoorAgencyUS',
    accountTypeLabel: 'Nextdoor (US)',
    accountTypeId: '20',
    urlBases: {},
    publish: {
      features: {
        targeting: true
      },
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.nextdooragencyus,
    targeting: {
      boundaries(account_id: Account) {
        return api
          .get<{
            data: {
              results: Array<{
                name: string;
                geometry: string;
                geometry_id: number;
                group_id: number;
                total_members: string;
                type: string;
              }>;
            };
          }>('/outbox/nextdoorBoundaries', {
            params: {
              account_id
            }
          })
          .then(({ data }) => {
            return data.results;
          });
      }
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-thumbs-up'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-thumbsup',
          web: 'ssi ssi-fb-liked'
        },
        text: 'Unlike'
      }
    },
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: true,
        outbox: true
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: true,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      getLargerAuthorAvatar(author: ActivityAuthor) {
        return author.avatar;
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://www.nextdoor.com/profile/${account.social_id}`;
      },
      auth: {
        popupOptions: { width: 500, height: 500 },
        logoutOfSocialNetwork() {
          return null;
        }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  Threads: {
    icon: {
      mobile: 'ssi ssi-threads',
      web: 'ssi ssi-threads'
    },
    brand: {
      color: '#000'
    },
    maxPostCharacters: {
      public: 60000,
      private: 2000,
      reply: 8000
    },
    publishKey: 'Threads',
    publishKeyInsights: 'Threads',
    logoImg: 'https://www.orlo.app/assets/account-logos/threads.png',
    accountTypeName: 'Threads',
    accountTypeLabel: 'Threads',
    accountTypeId: '21',
    urlBases: {},
    getExternalLink(post: Outbox) {
      return `https://www.threads.net/post/${post.social_id}`;
    },
    addPrivateReplyLinkAsAttachment: false,
    targeting: {
      ages: {
        min: [13, 15, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65],
        max: [15, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65]
      },
      gender: [
        {
          label: 'Male',
          value: 1
        },
        {
          label: 'Female',
          value: 2
        }
      ],
      relationshipStatus: [
        {
          label: 'Single',
          value: 1
        },
        {
          label: 'In a relationship',
          value: 2
        },
        {
          label: 'Married',
          value: 3
        },
        {
          label: 'Engaged',
          value: 4
        }
      ]
    },
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-ios-heart-outline',
          web: 'fa fa-heart-o'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-ios-heart',
          web: 'ssi ssi-liked'
        },
        text: 'Unlike'
      }
    },
    profile: {
      isViewable: true
    },
    publish: {
      features: {
        targeting: true
      },
      getLinkPreview() {}
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.threads,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: false,
        outbox: false
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      permissions: [],
      responseMedia: {
        textOptional: false,
        image: {
          public: true,
          private: true
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        if (!author || !author.id) {
          return '';
        }
        return author.avatar;
      },
      flattenPrivateThreads: true // Could do with bikeshedding this // what, take it for a snog ???
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://threads.net/@${account.username}`;
      },
      auth: {
        popupOptions: { width: 580, height: 400 }
      }
    },
    outbox: {
      threadReplies: false
    }
  },
  Bluesky: {
    icon: {
      mobile: 'ssi ssi-bluesky',
      web: 'ssi ssi-bluesky'
    },
    brand: {
      color: '#1185fe'
    },
    maxPostCharacters: {
      public: 280,
      private: 10000,
      reply: 280
    },
    publishKey: 'bluesky',
    publishKeyInsights: 'bluesky',
    logoImg: 'https://www.orlo.app/assets/account-logos/bluesky.png',
    accountTypeName: 'bluesky',
    accountTypeLabel: 'Bluesky',
    accountTypeId: '22',
    urlBases: {
      hashtag: 'https://bsky.com/search?q=%23',
      username: 'https://bsky.com/'
    },
    getExternalLink(post: Outbox) {
      return `https://bsky.com/profile/${post.account.username}/post/${post.social_id}`;
    },
    addPrivateReplyLinkAsAttachment: true,
    socialInteractions: {
      like: {
        icon: {
          mobile: 'ion-ios-heart-outline',
          web: 'fa fa-heart-o'
        },
        text: 'Like'
      },
      unlike: {
        icon: {
          mobile: 'ion-ios-heart',
          web: 'ssi ssi-liked'
        },
        text: 'Unlike'
      }
    },
    profile: {
      isViewable: true
    },
    supportsSocialLists: true,
    publish: {
      autocompleteProfilesAvailable: true,
      features: {
        customiseLinkPreview: true,
        videoCaptions: true
      },
      getLinkPreview(
        links: OutboxPublisherLink[]
      ): SocialNetworkLinkPreview | undefined {
        const lastLink = links[links.length - 1];
        if (lastLink) {
          return Object.assign(
            getLinkPreviewDefaults(lastLink),
            getOpenGraphLinkPreview(lastLink)
          );
        }
      },
      getTextPreview(text: OutboxPublisherText, post: OutboxPublisher): string {
        if (post.reply) {
          let { value } = text;
          [...post.mentions].reverse().forEach((mention) => {
            value =
              value.slice(0, mention.indices.start) +
              value.slice(mention.indices.end);
          });
          return value.trim();
        } else {
          return text.value;
        }
      },
      getUrlDisplayText(url: string) {
        return url.replace(/^https?:\/\//, '');
      }
    },
    mediaRestrictions: MediaRestrictions.networkSpecific.bluesky,
    statistics: {
      connections: {
        account: true,
        outbox: true
      },
      reach: {
        account: false,
        outbox: false
      },
      clicks: {
        account: true,
        outbox: true
      },
      messagesIn: {
        account: true,
        outbox: true
      },
      messagesOut: {
        account: true,
        outbox: true
      },
      engagementRate: {
        account: true,
        outbox: true
      },
      impressions: {
        account: false,
        outbox: true
      },
      shares: {
        account: true,
        outbox: true
      },
      likes: {
        account: true,
        outbox: true
      },
      comments: {
        account: true,
        outbox: true
      }
    },
    activity: {
      changeResponseAccount: true,
      responseMedia: {
        textOptional: true,
        image: {
          public: true,
          private: false
        }
      },
      getLargerAuthorAvatar(author: ActivityAuthor) {
        if (!author || !author.avatar) {
          return '';
        }
        return author.avatar && author.avatar.replace('_bigger', '_200x200');
      }
    },
    account: {
      getExternalUrl(account: Account): string {
        return `https://bsky.com/profile/${account.username}`;
      },
      auth: {
        popupOptions: { width: 495, height: 645 }
      }
    },
    outbox: {
      threadReplies: false
    }
  }
};

export const socialNetworkSettingsIterable: SocialNetwork[] = mapToIterable(
  socialNetworkSettings
);
