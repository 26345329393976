import {
  API,
  Conversation,
  ConversationModel,
  ConversationStatus,
  ConversationVisibility,
  User,
  Account
} from '@ui-resources-angular';
import { TranslateService } from '@ngx-translate/core';
import { KeyValueObject } from '../../../util';
import { appInjector } from '@orlo/app-injector';
import {
  TeamsService,
  Team,
  ColleaguesService,
  Colleague
} from '../../../services/api';
import { AsyncTracker } from 'angular-async-tracker';
import {
  AbstractQuery,
  AbstractQueryHelperService,
  QueryPreset
} from '../../abstract-query-helper/abstract-query-helper.service';
import { Injector } from '@angular/core';
import {
  getAccountsWithViewInboxPermission,
  getSavedSortAndOrder,
  getTeamOptions,
  getUserOptions,
  InboxSortOption,
  OnInboxQuerySearch,
  getVisibilityFilter,
  AbstractInboxQuery,
  InboxQueryResultListItem,
  InboxQueryResultListItemModel,
  InboxQueryResultListItemType,
  InboxQueryResultGroupByType,
  addInboxQueryResultListDividers,
  InboxQueryType,
  NOT_ASSIGNED,
  localRefresh
} from './common';
import { getStatusFilter } from './common';
import { getAssignedFilter } from './common';
import { inboxPriorities } from '../../../constants';

enum InboxConversationQuerySortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

const sortByLatestMessage = (
  conversationA: Conversation,
  conversationB: Conversation
) => {
  return (
    new Date(conversationB.latest_message).getTime() -
    new Date(conversationA.latest_message).getTime()
  );
};

export const inboxConversationQuerySort: KeyValueObject<InboxSortOption> = {
  smart: {
    value: 'SMART',
    translateLabel: 'SMART_SORT',
    isDefault: false,
    groupBy: InboxQueryResultGroupByType.Smart,
    order: InboxConversationQuerySortOrder.Desc,
    sortResults(conversations: Conversation[]) {
      return conversations.sort(
        (conversationA: Conversation, conversationB: Conversation) => {
          const statusOrder = {
            [ConversationStatus.Unread]: 0,
            [ConversationStatus.Unactioned]: 1,
            [ConversationStatus.Actioned]: 2,
            [ConversationStatus.Resolved]: 3,
            [ConversationStatus.OnHold]: 4
          };
          const statusDiff =
            statusOrder[conversationA.status] -
            statusOrder[conversationB.status];
          if (statusDiff !== 0) {
            return statusDiff;
          } else {
            return sortByLatestMessage(conversationA, conversationB);
          }
        }
      );
    }
  },
  newest: {
    value: 'DATETIME',
    translateLabel: 'SORT_BY_NEWEST',
    isDefault: true,
    groupBy: InboxQueryResultGroupByType.Date,
    order: InboxConversationQuerySortOrder.Desc,
    sortResults(conversations: Conversation[]) {
      return conversations.sort(sortByLatestMessage);
    }
  },
  oldest: {
    value: 'DATETIME',
    translateLabel: 'SORT_BY_OLDEST',
    isDefault: false,
    groupBy: InboxQueryResultGroupByType.Date,
    order: InboxConversationQuerySortOrder.Asc,
    sortResults(conversations: Conversation[]) {
      return conversations.sort(sortByLatestMessage);
    }
  },
  priorityNewest: {
    value: 'PRIORITY',
    translateLabel: 'SORT_BY_HIGHEST_PRIORITY_NEWEST',
    isDefault: false,
    order: InboxConversationQuerySortOrder.Desc,
    groupBy: InboxQueryResultGroupByType.Priority
  },
  priorityOldest: {
    value: 'PRIORITY',
    translateLabel: 'SORT_BY_LOWEST_PRIORITY_NEWEST',
    isDefault: false,
    order: InboxConversationQuerySortOrder.Asc,
    groupBy: InboxQueryResultGroupByType.Priority
  },
  messageCountHighest: {
    value: 'MESSAGE_COUNT',
    translateLabel: 'SORT_BY_MESSAGE_COUNT_LARGEST',
    isDefault: false,
    order: InboxConversationQuerySortOrder.Desc,
    groupBy: InboxQueryResultGroupByType.MessageCount
  },
  messageCountLowest: {
    value: 'MESSAGE_COUNT',
    translateLabel: 'SORT_BY_MESSAGE_COUNT_SMALLEST',
    isDefault: false,
    order: InboxConversationQuerySortOrder.Asc,
    groupBy: InboxQueryResultGroupByType.MessageCount
  }
};

const conversationsValue = Symbol();

export class InboxConversationQuery extends AbstractInboxQuery {
  readonly type = InboxQueryType.Conversation;

  result: {
    [conversationsValue]: Conversation[];
    conversations: Conversation[];
    total: number;
    totalLoaded: number;
    list: InboxQueryResultListItem[];
  };

  presets = [
    {
      key: 'all',
      label: this.injector.get(TranslateService).instant('ALL_MESSAGES'),
      icon: 'ssi ssi-all-messages-new',
      query: {}
    },
    {
      key: 'assignedToMe',
      label: this.injector.get(TranslateService).instant('ASSIGNED_TO_ME'),
      icon: 'ssi ssi-assigned',
      query: {
        assignment: 'me_or_my_teams'
      }
    },
    {
      key: 'unactioned',
      label: this.injector.get(TranslateService).instant('UNACTIONED'),
      icon: 'ssi ssi-unactioned',
      query: {
        status: [ConversationStatus.Unactioned, ConversationStatus.Unread]
      }
    },
    {
      key: 'resolved',
      label: this.injector.get(TranslateService).instant('RESOLVED'),
      icon: 'ssi ssi-resolved',
      query: {
        status: [ConversationStatus.Resolved]
      }
    }
  ];

  readonly params = {
    sort: getSavedSortAndOrder(this.savedQuery, inboxConversationQuerySort)
      .sort,
    order: getSavedSortAndOrder(this.savedQuery, inboxConversationQuerySort)
      .order
  };

  constructor(
    injector: Injector,
    private allAccounts: Account[],
    colleagues: Colleague[],
    teams: Team[],
    authUser: User,
    private savedQuery: KeyValueObject,
    private onSearch: OnInboxQuerySearch,
    public customPresets: QueryPreset[]
  ) {
    super(injector);
    console.log('Debug should initiate Conversation again');

    const query = this;

    this.result = {
      [conversationsValue]: [],
      list: [],
      total: 0,
      totalLoaded: 0,
      get conversations(): Conversation[] {
        return this[conversationsValue];
      },
      set conversations(conversations: Conversation[]) {
        this[conversationsValue] = conversations;
        this.totalLoaded = conversations.length;
        const mappedConversations: InboxQueryResultListItem[] = conversations.map(
          (activity) => query.conversationToResultListItem(activity)
        );
        this.list = addInboxQueryResultListDividers(
          mappedConversations,
          inboxConversationQuerySort,
          query.params.sort,
          injector
        );
      }
    };

    const abstractQueryHelper = injector.get(AbstractQueryHelperService);
    const translate = this.injector.get(TranslateService);
    const colleaguesService = injector.get(ColleaguesService);
    const userOptions = getUserOptions(colleagues);
    const teamOptions = getTeamOptions(teams);

    this.filters = [
      abstractQueryHelper.getAccountsFilter({
        key: 'account_ids',
        allAccounts: getAccountsWithViewInboxPermission(allAccounts, authUser),
        matches(filterValue: string[], conversation: Conversation) {
          return filterValue.some(
            (accountId) => +accountId === +conversation.account_id
          );
        }
      }),
      abstractQueryHelper.getFromDateTimeFilter({
        key: 'since',
        matches(filterValue: Date, conversation: Conversation) {
          return new Date(conversation.latest_message) >= filterValue;
        }
      }),
      abstractQueryHelper.getToDateTimeFilter({
        key: 'until',
        matches(filterValue: Date, conversation: Conversation) {
          return new Date(conversation.oldest_message) <= filterValue;
        }
      }),
      getVisibilityFilter(
        {
          key: 'visibility',
          defaultValue: 'all',
          options: [
            {
              label: 'ALL_MESSAGES',
              value: 'all'
            },
            {
              label: 'PRIVATE_MESSAGES_ONLY',
              value: ConversationVisibility.Private
            },
            {
              label: 'PUBLIC_MESSAGES_ONLY',
              value: ConversationVisibility.Public
            }
          ],
          matches(
            filterValue: ConversationVisibility[],
            conversation: Conversation
          ) {
            return filterValue.includes(conversation.visibility);
          }
        },
        translate
      ),
      {
        key: 'priority',
        translateLabel: 'PRIORITY',
        type: 'selectMultiple',
        icon: 'ssi-sort',
        get defaultValue() {
          // pass undefined to the backend if nothing selected
          return undefined;
        },
        isDefault(selectedValues) {
          // nothing selected (undefined) is the default - backend will ignore priority filter in that case
          return selectedValues === undefined;
        },
        selectedText(values: number[]) {
          return Array.isArray(values)
            ? values
                .sort()
                .map((v) => this.options.find((o) => o.value === v).label)
                .join(', ')
            : '';
        },
        options: this.getPriorityFilterOptions(),
        matches(values, conversation: Conversation) {
          /* tslint:disable-next-line */
          return values.some((v) => conversation.priority == v);
        }
      },
      getStatusFilter(
        {
          key: 'status',
          options: [
            {
              label: 'UNREAD',
              value: ConversationStatus.Unread
            },
            {
              label: 'UNACTIONED',
              value: ConversationStatus.Unactioned
            },
            {
              label: 'ACTIONED',
              value: ConversationStatus.Actioned
            },
            {
              label: 'RESOLVED',
              value: ConversationStatus.Resolved
            },
            {
              label: 'ON_HOLD',
              value: ConversationStatus.OnHold
            }
          ],
          get defaultValue() {
            return this.options
              .filter(
                (option) =>
                  option.value !== ConversationStatus.Resolved &&
                  option.value !== ConversationStatus.OnHold
              )
              .map((option) => option.value);
          },
          getDefaultValue() {
            return this.options
              .filter(
                (option) =>
                  option.value !== ConversationStatus.Resolved &&
                  option.value !== ConversationStatus.OnHold
              )
              .map((option) => option.value);
          },
          isDefault(value) {
            return (
              value.length === 3 &&
              value.includes(ConversationStatus.Unread) &&
              value.includes(ConversationStatus.Unactioned) &&
              value.includes(ConversationStatus.Actioned)
            );
          },
          matches(
            filterValue: ConversationStatus[],
            conversation: Conversation
          ) {
            return filterValue.includes(conversation.status);
          },
          localRefresh: true
        },
        translate
      ),
      getAssignedFilter(
        {
          key: 'assignment',
          users: userOptions,
          teams: teamOptions,
          matches(filterValue, conversation: Conversation) {
            if (conversation.assignedToUser) {
              return filterValue === conversation.assignedToUser;
            } else if (conversation.assignedToTeam) {
              return (
                filterValue === conversation.assignedToTeam ||
                colleaguesService.store
                  .filterByTeam(conversation.assignedToTeam.id, true)
                  .includes(filterValue)
              );
            } else {
              return true;
            }
          },
          localRefresh: true
        },
        translate,
        authUser
      )
    ];
  }

  getPriorityFilterOptions() {
    const options = inboxPriorities.map((p) => {
      return {
        value: p,
        label: `P${p}`
      };
    });

    options.push({
      value: null,
      label: 'No priority set'
    });

    return options;
  }

  search(offset = 0, limit = 20, queryOverrides = {}) {
    const isNewSearch = offset === 0;
    if (isNewSearch) {
      this.result.conversations = [];
      this.updateQueryState();
    }
    this.apiQuery = { ...this.apiQuery, ...queryOverrides };
    this.updateQueryState();

    const params = Object.assign({}, this.apiQuery, this.params, {
      limit,
      offset
    });
    if (params.sort === 'SMART') {
      params.order = InboxConversationQuerySortOrder.Asc;
    }

    const api = this.injector.get(API);
    const conversationModel = this.injector.get(ConversationModel);

    const promise = api
      .post<{ data: { conversations: any[]; total: number } }>(
        'conversation/conversationSearch',
        params
      )
      .then(({ data: { conversations: results, total } }) => {
        const conversations = conversationModel.inject(
          results.map((result) => {
            result.author = result.author || { avatar: '' };
            result.media = result.media || [];
            return result;
          })
        );
        if (isNewSearch) {
          this.result.conversations = conversations;
        } else {
          this.result.conversations = [
            ...this.result.conversations,
            ...conversations
          ];
        }
        this.result.total = total;

        this.onSearch(
          Object.assign({}, this.apiQuery, {
            sort: this.params.sort,
            order: this.params.order
          }),
          { offset, limit }
        );
      });
    const tracker: AsyncTracker = isNewSearch
      ? this.loading.initial
      : this.loading.more;
    tracker.add(promise);
    return promise;
  }

  localRefresh() {
    const { records, totalResults } = localRefresh(
      this.filters,
      inboxConversationQuerySort,
      this.testResult.bind(this),
      this.params.sort,
      this.result.conversations,
      this.result.total
    );
    this.result.conversations = records;
    this.result.total = totalResults;
  }

  testResult(conversation: Conversation, filters = this.filters) {
    try {
      if (!(!!this.allAccounts && Array.isArray(this.allAccounts))) {
        throw new Error(
          `Value for 'conversation all accounts' not in expected format.`
        );
      }

      if (
        !this.allAccounts.find(
          (account) => +account.id === +conversation.account_id
        )
      ) {
        return false;
      }

      return filters
        .map((filter) => {
          return {
            filter,
            value: this.deserializeValue(filter, this.apiQuery[filter.key])
          };
        })
        .every(({ filter, value }) => {
          return filter.matches(value, conversation);
        });
    } catch (error) {
      return false;
    }
  }

  conversationToResultListItem(
    conversation: Conversation
  ): InboxQueryResultListItem {
    return this.injector
      .get(InboxQueryResultListItemModel)
      .injectOrReturnExisting({
        uid: `conversation-${conversation.id}`,
        type: InboxQueryResultListItemType.Conversation,
        result: {
          id: conversation.id,
          account: conversation.account,
          conversation,
          text: conversation.message_snippet,
          author: conversation.author,
          date: new Date(conversation.latest_message),
          responseCount: conversation.count_replies,
          get status() {
            // hack to ensure we can update the activity status and it will magically update here
            return conversation.status.toLowerCase();
          },
          visibility: conversation.visibility,
          media: {
            total: conversation.media ? conversation.media.length : 0,
            get image() {
              return conversation.mediaByType.image;
            },
            get video() {
              return conversation.mediaByType.video;
            },
            get document() {
              return conversation.mediaByType.document;
            }
          }
        }
      });
  }
}
