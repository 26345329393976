import { ViewEncapsulation } from '@angular/core';


import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DatePipe } from '@angular/common';

import { Options } from 'highcharts';
import Highcharts from 'highcharts';

import { Account } from '@ui-resources-angular';
import { networks } from '../../../../../../common/constants';
import { AccountTypeId } from '../../../../../../common/enums';
import { HighchartsHelperService } from '../../../../../../common/services/highcharts-helper/highcharts-helper.service';
import {
  NetworkSelectItem,
  networkSelectItems
} from '../../../common/social-network-select/social-network-select.component';
import {
  MarketingService,
  DateRanges,
  TimeSeriesStats
} from '../../marketing.service';

@Component({
  selector: 'ssi-organic-reach',
  templateUrl: './organic-reach.component.html',
  styles: [],
  styleUrls: ['./organic-reach.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrganicReachComponent implements OnInit {
  @Input() accounts: Account[] = [];
  @Input() dateRanges: DateRanges;
  @Input() stats: TimeSeriesStats;
  @Input() tagsToInclude: string[];
  @Input() tagsToExclude: string[];

  statsToDisplay: TimeSeriesStats;
  chartConfig: Options;
  currentTotal = 0;
  previousTotal = 0;
  loading = false;

  selectableNetworks: NetworkSelectItem[] = networkSelectItems; // to be filtered
  selectedNetwork: NetworkSelectItem = networkSelectItems[0];
  statsByNetwork: { [key: string]: TimeSeriesStats } = {};

  constructor(
    protected elementRef: ElementRef,
    protected translate: TranslateService,
    protected marketingService: MarketingService,
    protected highchartsHelper: HighchartsHelperService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.selectableNetworks = networkSelectItems.filter((n) => {
      return (
        this.accounts.some((a) => a.account_type_id === n.key) &&
        // No reach data is provided for Twitter, see CT-1656
        n.account_type_id !== AccountTypeId.Twitter
      );

      // const account = this.accounts.find((a) => a.account_type_id === n.key);
      // return (
      //   account &&
      //   account.socialNetwork.statistics.reach.account &&
      //   account.socialNetwork.statistics.reach.outbox
      // );
    });
    this.selectableNetworks.unshift(networkSelectItems[0]);
    this.selectedNetwork = this.selectableNetworks[0];

    // stats for 'all' network already loaded (in parent component) - store the value
    this.statsByNetwork[this.selectedNetwork.key] = this.stats;

    this.statsToDisplay = this.stats;
    this.setTotals();
    this.chartConfig = this.getChartConfig();
  }

  async onNetworkSelect(nsi: NetworkSelectItem): Promise<void> {
    const loadedSuccess = await this.loadAndStoreStatsForNetwork(nsi);
    if (loadedSuccess) {
      this.selectedNetwork = nsi;

      this.statsToDisplay = this.statsByNetwork[nsi.key];
      this.setTotals();
      this.chartConfig = this.getChartConfig();
    }
  }

  async loadAndStoreStatsForNetwork(nsi: NetworkSelectItem): Promise<boolean> {
    let loaded = true;

    if (!this.statsByNetwork[nsi.key]) {
      // if not already loaded (stored)
      this.loading = true;
      try {
        const networkStats = await this.marketingService.loadTimeSeries(
          this.accounts
            .filter((a) => a.account_type_id === nsi.key)
            .map((a) => a.id),
          this.dateRanges,
          this.tagsToInclude,
          this.tagsToExclude
        );

        this.statsByNetwork[nsi.key] = networkStats;
        loaded = true;
      } catch (e) {
        console.error('Error loading/mapping stats: ', e);
        loaded = false;
      }
      this.loading = false;
    }

    return loaded;
  }

  setTotals(): void {
    const currentPatchedValues = this.marketingService.patchTotalReachValues(
      this.statsToDisplay.current.total_reach.values
      // this.dateRanges.current.start,
      // this.dateRanges.current.end
    );

    this.currentTotal =
      Object.values(currentPatchedValues).reduce((a, b) => a + b, 0) /
      Object.keys(currentPatchedValues).length;

    if (this.statsToDisplay.previous) {
      const previousPatchedValues = this.marketingService.patchTotalReachValues(
        this.statsToDisplay.previous.total_reach.values
        // this.dateRanges.previous.start,
        // this.dateRanges.previous.end
      );

      this.previousTotal =
        Object.values(previousPatchedValues).reduce((a, b) => a + b, 0) /
        Object.keys(previousPatchedValues).length;
    }
  }

  getChartConfig(): any {
    const datePipe = this.datePipe;
    const reportStartTime = this.dateRanges.current.start;
    reportStartTime.setHours(1, 0, 0, 0);
    const timeDiff =
      reportStartTime.getTime() - this.dateRanges.previous.start.getTime();
    const series: any[] = [
      {
        data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
          this.statsToDisplay.current.total_reach,
          this.dateRanges.current
        ),
        // name: this.translate.instant(primaryLabel),
        name: 'REACH PER DAY',
        color: {
          // https://www.highcharts.com/docs/chart-design-and-style/colors#linear-gradients
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#D41D68'],
            [1, '#FF8BBA']
          ]
        }
      }
    ];

    if (this.statsToDisplay.previous) {
      series.push({
        data: this.highchartsHelper.apiIimeSeriesDataToHighcharts(
          this.statsToDisplay.previous.total_reach,
          this.dateRanges.current
        ),
        // name: this.translate.instant(secondaryLabel),
        name: 'PREVIOUS PERIOD',
        dashStyle: 'ShortDash',
        type: 'line',
        color: '#D41D68',
        marker: {
          enabled: false
        }
      });
    }

    return {
      loading: false,
      chart: {
        // https://www.highcharts.com/docs/chart-design-and-style/design-and-style#chart
        type: 'areaspline',
        zoomType: 'x',
        spacing: [60, 40, 20, 40]
      },
      series,
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: '#838EAB'
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Reach',
          margin: 30,
          style: {
            color: '#838EAB'
          }
        },
        labels: {
          style: {
            color: '#838EAB'
          }
        },
        gridLineWidth: 3,
        gridLineColor: '#F4F4FA'
      },
      tooltip: {
        formatter() {
          return `${
            this.series.index === 0
              ? datePipe.transform(this.x, 'EEEE, MMM d, yyyy')
              : datePipe.transform(
                  new Date(new Date(this.x).getTime() - timeDiff),
                  'EEEE, MMM d, yyyy'
                )
          } <br/> ${this.series.name}: ${this.y}`;
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        // maxHeight: 300,
        // width: 360,
        // align: 'bottom',
        // layout: 'horizontal',
        // verticalAlign: 'middle',
        // itemMarginBottom: 30,
        // y: 20,
        // useHTML: true,
        itemMarginTop: 30,
        // itemMarginRight: 80,
        itemStyle: {
          fontSize: '10px',
          fontWeight: '900',
          color: '#43537F',
          letterSpacing: '1px',
          fontFamily: 'Lato, Arial, sans-serif'
        }
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolSize: 15,
            symbolStroke: '#838EAB',
            x: 35,
            y: -35
          }
        }
      },
      accessibility: {
        description: ''
      },
      time: {
        useUTC: false
      }
      // plotOptions: {
      //   pie: {
      //     allowPointSelect: true,
      //     cursor: 'pointer',
      //     dataLabels: {
      //       enabled: false
      //     },
      //     showInLegend: true
      //   }
      // }
    };
  }
}
