import {
  Directive,
  HostListener,
  Input,
  EventEmitter,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  Renderer2
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PhraseConfirmModalComponent } from '../../components/phrase-confirm-modal/phrase-confirm-modal.component';

@Directive({
  selector: '[ssiBlurImage]'
})
export class BlurImageDirective implements OnInit, OnChanges {
  @Input('ssiBlurImage') blurImage = false;
  @Input('iconCoverOnly') iconCoverOnly = false;
  @Output('unblur') unblur = new EventEmitter<void>();

  constructor(
    protected modal: NgbModal,
    protected renderer: Renderer2,
    protected elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['blurImage']) {
      if (this.blurImage) {
        this.appendCoverElem(this.elementRef.nativeElement.parentElement);
        this.renderer.setStyle(
          this.elementRef.nativeElement,
          'filter',
          'blur(15px)'
        );
      } else {
        this.removeCoverElem(this.elementRef.nativeElement.parentElement);
        this.renderer.setStyle(this.elementRef.nativeElement, 'filter', 'none');
      }
    }
  }

  ngOnInit() {
    if (!(this.elementRef.nativeElement instanceof HTMLImageElement)) {
      throw new Error(
        'ssiBlurImage directive should be attached to IMG element..'
      );
    }

    this.appendCoverElemStyles();
  }

  appendCoverElem(parent: HTMLElement): HTMLElement {
    let coverElem = parent.querySelector('.g-sensitive-content-cover');
    if (coverElem) {
      return coverElem as HTMLElement;
    }

    parent.style['position'] = 'relative';

    coverElem = document.createElement('div');
    coverElem.className = 'g-sensitive-content-cover';

    let inner = `<i class="ssi ssi-sensitive-content"></i>`;
    if (!this.iconCoverOnly) {
      inner += '<span>Sensitive Content Detected</span>';
    }
    coverElem.innerHTML = inner;

    coverElem.addEventListener('click', this.onBlurredImageClick);

    parent.appendChild(coverElem);

    return coverElem as HTMLElement;
  }

  onBlurredImageClick = (event) => {
    if (!this.blurImage) {
      return;
    }

    event.stopPropagation();

    const modal = this.modal.open(PhraseConfirmModalComponent, {
      windowClass: 'orlo-modal'
    });
    modal.componentInstance.type = 'sensitiveContent';
    modal.result.then((confirmed) => {
      if (confirmed) {
        this.unblur.emit();
      }
    });
  };

  removeCoverElem(parent: Element): void {
    const coverElem = parent.querySelector('.g-sensitive-content-cover');
    if (coverElem) {
      coverElem.remove();
    }
  }

  appendCoverElemStyles(): void {
    const styleElem = document.createElement('style');
    styleElem.innerHTML = `
      .g-sensitive-content-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #FFF;
        font-family: Lato;
        font-size: 12px;
        font-weight: 900;
      }

      .g-sensitive-content-cover > span {
        margin-top: 10px;
      }
    `;

    document.head.appendChild(styleElem);
  }
}
