import { ViewEncapsulation } from '@angular/core';
import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';

import { ModalButton } from '@orlo/library/interfaces/modal-button';



@Component({
  selector: 'ssi-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnDestroy {
  @Input() public bodyContent?: string;
  @Input() public headerIcon?: string;
  @Input() public headerText?: string;
  @Input() public primaryButton?: ModalButton;
  @Input() public secondaryButton?: ModalButton;

  @Output()
  public isDestroyed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public componentReference: ComponentRef<ModalComponent>;

  private _isActive = true;

  public get iconForPrimaryButton(): string {
    return this.primaryButton.icon;
  }

  public get isActive(): boolean {
    return this._isActive;
  }

  public get textForPrimaryButton(): string {
    return this.primaryButton.text;
  }

  public get iconForSecondaryButton(): string {
    return this.secondaryButton.icon;
  }

  public get textForSecondaryButton(): string {
    return this.secondaryButton.text;
  }

  public ngOnDestroy() {
    this.isDestroyed.emit(true);
  }

  public async onClickPrimaryButton(event?: Event): Promise<void> {
    if (!this.primaryButton) {
      return;
    }

    // return true to prevent modal destruction.

    if (!(await this.primaryButton.action(event))) {
      // this.componentReference.destroy();
      this.markForDestruction();
    }
  }

  public async onClickSecondaryButton(event?: Event): Promise<void> {
    if (!this.secondaryButton) {
      return;
    }

    // return true to prevent modal destruction.

    if (!(await this.secondaryButton.action(event))) {
      // this.componentReference.destroy();
      this.markForDestruction();
    }
  }

  private markForDestruction() {
    this.componentReference.destroy();
    this._isActive = false;
  }
}
