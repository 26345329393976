import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-medal-score',
  templateUrl: './medal-score.component.html',
  styles: [],
  styleUrls: ['./medal-score.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MedalScoreComponent implements OnInit {
  @Input() place: number;
  @Input() color? = '#838EAB';
  @Input() variant? = 'small'; // 'big'
  @Input() circleColor? = '#FBF3E2';
  @Input() wreathColor? = '#F0B427';

  constructor() {}

  ngOnInit() {}
}
