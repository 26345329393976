import { ViewEncapsulation } from '@angular/core';

import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import moment from 'moment';

import { AccountModel, Account, Outbox } from '@ui-resources-angular';
import {
  isElementWithinViewport,
  mapToIterable
} from '../../../../../common/utils';
import { decodeStateParams } from '@orlo/common/utils';
import { Debounce } from '../../../../../common/decorators';
import {
  FileDownloadService,
  SpreadsheetService
} from '../../../../../common/services';
import {
  FileMimeTypes,
  GlobalLoaderService
} from '../../../../../common/directives';
import {
  compareToOptions,
  NetworkConst,
  networks,
  NetworksConst,
  networksIterable
} from '../../../../../common/constants';
import { AnalyticsService, LinkClicksData } from '../../analytics.service';
import {
  DisapprovalsService,
  RejectedPostsReportResponse
} from '../disapprovals.service';

@Component({
  selector: 'ssi-report',
  templateUrl: './report.component.html',
  styles: [],
  styleUrls: ['./report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportComponent implements OnInit {
  reportActions = this.getReportActions();
  navItems = this.getNavItems();
  activeNavItemTag = 'account-summary';

  accountIds: string[] = [];
  accounts: Account[] = [];

  summaryPerAccountType: {
    account_type_id: string;
    account_type_name: string;
    icon: string;
    disapprovals: number;
    accountsCount: number;
  }[];
  validationTags: string[];

  dateRanges: any;

  totalsStats: any;
  topTimesToPostStats: any;
  linkClicksLocationStats: { lat: number; lng: number }[];

  loading = false;

  rgbColors = [
    '18, 172, 164',
    '240, 180, 39',
    '255, 135, 184',
    '212, 29, 104',
    '66, 93, 236',
    '178, 198, 20',
    '88, 27, 166',
    '131, 142, 171',
    '137, 164, 234',
    '137, 218, 238'
  ];

  totalsByAccountTypeIterable: any[] = [];

  networksConst: NetworksConst = networks;
  networksConstIterable: NetworkConst[] = networksIterable;
  tags: {
    include_tags: string[];
    exclude_tags: string[];
  };

  decodedStateParams: { [key: string]: any };

  constructor(
    public state: StateService,
    protected elementRef: ElementRef,
    protected analyticsService: AnalyticsService,
    protected disapprovalsService: DisapprovalsService,
    protected spreadsheetService: SpreadsheetService,
    protected fileDownloadService: FileDownloadService,
    protected globalLoader: GlobalLoaderService,
    protected accountModel: AccountModel
  ) {}

  async ngOnInit(): Promise<void> {
    this.decodedStateParams = decodeStateParams(this.state.params);
    this.setAccounts();
    this.setDateRanges();
    this.setTags();
    await this.loadReports();
  }

  async loadReports(): Promise<void> {
    this.loading = true;
    try {
      const totalsStats = await this.disapprovalsService.loadTotals(
        this.accountIds,
        this.dateRanges,
        this.tags
      );

      this.totalsStats = totalsStats;
      console.log('totalsStats:', totalsStats);

      this.totalsByAccountTypeIterable = mapToIterable(
        totalsStats.totalsByAccountType
      );

      this.totalsByAccountTypeIterable.sort(
        (a, b) =>
          this.networksConst[a.account.account_type_name].order -
          this.networksConst[b.account.account_type_name].order
      );

      this.populateNavItemElements();
      this.loading = false;
    } catch (e) {
      console.error('Error loading/mapping stats: ', e);
      window.history.back();
      return;
    }
  }

  setAccounts(): void {
    this.accountIds = Array.isArray(this.decodedStateParams.accountIds)
      ? this.decodedStateParams.accountIds
      : [this.decodedStateParams.accountIds];

    this.accounts = this.accountModel
      .filter({
        where: { id: { in: this.accountIds } }
      })
      .sort((a1, a2) => Number(a1.id) - Number(a2.id));
  }

  setDateRanges(): void {
    this.dateRanges = {
      current: {
        start: new Date(this.decodedStateParams.startDate),
        end: new Date(this.decodedStateParams.endDate)
      }
    };
  }

  setTags(): void {
    this.tags = {
      include_tags: this.decodedStateParams.includeTags
        ? Array.isArray(this.decodedStateParams.includeTags)
          ? this.decodedStateParams.includeTags
          : [this.decodedStateParams.includeTags]
        : null,
      exclude_tags: this.decodedStateParams.excludeTags
        ? Array.isArray(this.decodedStateParams.excludeTags)
          ? this.decodedStateParams.excludeTags
          : [this.decodedStateParams.excludeTags]
        : null
    };
  }

  async reportDateChanged(dateRanges): Promise<void> {
    this.dateRanges.current.start = new Date(dateRanges.from);
    this.dateRanges.current.end = new Date(dateRanges.to);

    await this.loadReports();
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });

      this.setActiveNavItem();
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return [
      {
        label: 'Summary',
        tag: 'summary',
        element: undefined
      },
      {
        label: 'Network Summary',
        tag: 'network-summary',
        element: undefined
      },
      {
        label: 'Breakdown',
        tag: 'breakdown',
        element: undefined
      }
    ];
  }

  getReportActions(): Array<any> {
    return [
      // {
      //   icon: 'ssi-print',
      //   label: 'Export PDF',
      //   func: async () => {
      //     if (this.loading) {
      //       console.log('still loading');
      //       return;
      //     }
      //     this.openBrowserPrint();
      //   }
      // },
      // {
      //   icon: 'ssi-print',
      //   label: 'Download XLSX',
      //   func: this.downloadXlsx.bind(this)
      // },
      {
        icon: 'ssi-edit',
        label: 'Edit report',
        func: () => {
          this.state.go(
            'auth.analytics.disapprovals.build',
            this.decodedStateParams
          );
        }
      }
    ];
  }

  // async downloadXlsx(): Promise<void> {
  //   this.globalLoader.show();
  //   try {

  //     const spreadsheetData = this.disapprovalsService.createSpreadsheetExport(
  //       this.accounts,
  //       this.dateRanges,
  //       this.totalsStats,
  //     );

  //     console.log('spreadsheetData: ', spreadsheetData);

  //     const xlsx = await this.spreadsheetService.jsonToXlsx(spreadsheetData);

  //     const downloadConfig = {
  //       base64decode: true,
  //       filename: `Orlo - Rejected Posts report.xlsx`,
  //       mimetype: FileMimeTypes.Xlsx
  //     };

  //     this.fileDownloadService.download(xlsx, downloadConfig);
  //   } catch (e) {
  //     console.error('Error generating report: ', e);
  //   }
  //   this.globalLoader.hide();
  // }

  openBrowserPrint() {
    window.print();
  }
}
