import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-inbox-priority-indicator',
  templateUrl: './inbox-priority-indicator.component.html',
  styles: [],
  styleUrls: ['./inbox-priority-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InboxPriorityIndicatorComponent implements OnInit {
  @Input() priority: string; // numeric string
  @Input() type: 'dot' | 'badge' | 'text' = 'badge';
  @Input() size: 'S' | 'M' | 'L' = 'S';
  @Input() tooltipText = '';
  @Input() showSingleValue? = false;

  constructor() {}

  ngOnInit() {}
}
