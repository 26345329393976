import { ViewEncapsulation } from '@angular/core';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';


@Component({
  selector: 'ssi-stat-graph',
  templateUrl: './stat-graph.component.html',
  styles: [],
  styleUrls: ['./stat-graph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatGraphComponent implements OnInit, OnChanges {
  @Input() type: 'large' | 'large-thin' | 'default';
  @Input() totalNumber: number;
  @Input() activeNetwork: 'total' | '2' | '3' | '12';
  @Input() competitorName: string;
  @Input() competitorColour: string;
  @Input() isTop?: boolean;
  @Input() pieChartConfig;

  constructor() {}

  ngOnInit() {
    this._formatChartColours(this.pieChartConfig.series[0].data);
    this._sizeChart(this.pieChartConfig.chart, this._getChartSize(this.type));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pieChartConfig) {
      this._formatChartColours(this.pieChartConfig.series[0].data);
      this._sizeChart(this.pieChartConfig.chart, this._getChartSize(this.type));
    }
  }

  private _formatChartColours(data) {
    const colours = ['#3CC5D8', '#D41D68', '#F0B427', '#89A4EA'];
    data.forEach(
      (item) =>
        (item['color'] =
          colours[data.findIndex((Item) => Item.name === item.name)])
    );
  }

  private _sizeChart(chart, size) {
    chart.height = size;
    chart.width = size;
  }

  private _getChartSize(type) {
    switch (type) {
      case 'large':
        return 210;
        return;
      case 'large-thin':
      case 'default':
      default:
        return 160;
    }
  }
}
