<div class="row">
  <div class="col-sm-3">
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="form-group">
          <label>
            <i class="fa fa-folder-o"></i>&nbsp;<span translate="POST_TO_ACCOUNT"></span>
          </label>
          <ssi-account-selector
            [(ngModel)]="selectedAccount"
            (ngModelChange)="selectedAccountChanged()"
            [accounts]="accounts"
            [title]="'SELECT_AN_ACCOUNT_TO_POST_TO' | translate">
          </ssi-account-selector>
        </div>
        <ssi-content-generator-feed
          *ngIf="selectedAccount"
          [feeds]="activeFeeds"
          [selectedFeeds]="selectedFeeds"
          (onFeedChange)="selectedFeedsChange($event)"
          (onDelete)="deleteFeed($event)"
        ></ssi-content-generator-feed>
      </div>
    </div>
    <div class="panel panel-default" *ngIf="selectedAccount">
      <div class="panel-heading">
        <h3 class="panel-title" translate="ADD_NEW_FEED"></h3>
      </div>
      <form
        *ngIf="model"
        #newFeedForm="ngForm"
        class="panel-body"
        name="newFeedForm" 
        (submit)="createFeed(newFeedForm)"
      >
        <div class="form-group">
          <label translate="FEED_NAME" for="name"></label>
          <input
            type="text"
            required
            class="form-control"
            name="name"
            [(ngModel)]="model.name"
          >
        </div>
        <div class="form-group">
          <label for="url"><i class="fa fa-rss"></i>&nbsp;<span translate="RSS_URL"></span></label>
          <input
            type="url"
            required
            class="form-control"
            name="url"
            pattern="https?://.+"
            [(ngModel)]="model.url"
          >
        </div>
        <div class="form-group">
          <label for="account_id" translate="ACCOUNT"></label>
          <select
            class="form-control"
            name="account_id"
            [(ngModel)]="model.account_id"
          >
            <option value="" translate="ADD_FEED_TO_ALL_THE_ACCOUNTS"></option>
            <option
              [value]="selectedAccount.id"
              translate="ADD_FEED_TO_JUST_THIS_ACCOUNT"
            ></option>
          </select>
        </div>
        <div class="form-group text-right">
          <orlo-button
            state="primary"
            type="submit"
            [text]="'ADD_FEED' | translate"
            [disabled]="!newFeedForm.valid"
          ></orlo-button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-sm-9">
    <div *ngIf="!selectedAccount">
      <p class="cg-empty-text" translate="PLEASE_SELECT_AN_ACCOUNT_TO_GET_STARTED"></p>
    </div>
    <div *ngIf="selectedAccount">
      <div
        class="alert alert-info"
        *ngIf="!loading && totalFeedsFailed"
        translate="SORRY_BUT_WERE_HAVING_SOME_TROUBLE_LOADING_ARTICLES_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER">
      </div>

      <div *ngIf="loading">
        <br><br>
        <ssi-progress-bar
          [max]="activeFeeds.length"
          [progress]="totalFeedsLoaded"
          bgColor="#fff"
          color="#14bae3"
          [radius]="5"
          [thickness]="20"
        ></ssi-progress-bar>
        <br><br>
      </div>
      <div class="article-grid">
        <ng-container *ngFor="let article of articles">
          <ssi-content-generator-article
            [article]="article"
            [postToAccount]="selectedAccount"
            [campaignId]="campaignId"
          ></ssi-content-generator-article>
        </ng-container>
      </div>
    </div>
  </div>
</div>
