import { ViewEncapsulation } from '@angular/core';


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  InsightsService,
  InsightsSearchResponse,
  InsightsPost,
  sanitizeFiltersForAPI,
  dedupeAndMergeFiltersForAPI
} from '../../../../../../auth/insights/insights.service';
import { MonitoringStreamsService } from '../../../../../../../common/services/api';
import { DashboardWidget } from '../../../../../../../common/services/api/dashboard';
import { emotions } from '../../../../../../../common/constants';
import { trackByNestedProperty } from '../../../../../../../common/util';
import { InsightsFiltersService } from '../../../../../insights/common/components/global-filters/insights-filters.service';

@Component({
  selector: 'ssi-widget-monitoring-stream',
  templateUrl: './widget-monitoring-stream.component.html',
  styles: [],
  styleUrls: ['./widget-monitoring-stream.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetMonitoringStreamComponent implements OnInit {
  @Input() widget: DashboardWidget;
  @Output() loaded = new EventEmitter<boolean>();

  currentResponse: InsightsSearchResponse;
  results: InsightsPost[] = [];
  viewPortResults: InsightsPost[] = [];
  selectedResults: InsightsPost[] = [];

  loading = false;
  containerWidth = ''; // e.g. '1200px'
  columns = 1;
  trackByUid = trackByNestedProperty('data.id');
  readonly resultsPerPage = 20;

  constructor(
    protected insightsService: InsightsService,
    protected insightsFiltersService: InsightsFiltersService,
    protected monitoringStreamsService: MonitoringStreamsService
  ) {}

  async ngOnInit() {
    await this.load();
    this.loaded.emit(true);
  }

  async load(): Promise<void> {
    // console.log('load next page...');

    let globalFilters =
      this.widget &&
      this.widget.apiSearchParams &&
      this.widget.apiSearchParams.filters;
    const streamIds = ['1'];
    const onlyActiveFilters = await this.insightsFiltersService.getOnlyActiveFilters(
      globalFilters
    );
    globalFilters = sanitizeFiltersForAPI(Object.values(onlyActiveFilters));
    globalFilters = dedupeAndMergeFiltersForAPI(globalFilters);
    this.currentResponse = await this.insightsService.getPosts(
      globalFilters,
      streamIds,
      this.currentResponse ? this.currentResponse.next.offset : 0,
      this.resultsPerPage
    );

    this.results = [...this.results, ...this.currentResponse.results];
  }

  viewPortItemsUpdated(items: InsightsPost[]): void {
    // console.log('viewPortItemsUpdated: ', items);
    this.viewPortResults = items;
  }

  onContainerWidthChange(width: string): void {
    this.containerWidth = width;
    // const columnsNew = parseInt(width, 10) > 1400 ? 2 : 1;
    // if (columnsNew !== this.columns) {
    //   this.columns = columnsNew;
    //   this.currentResponse = undefined;
    //   this.load();
    // }
  }

  getHeight = () => {
    // hacky way to calucalte height based on number of columns,
    // i.e. just double the height if results should be rendered in 2 columns so virtual scroll keeps working
    // return this.columns * 262 + 10; // + margin

    return 262 + 10; // + margin
  };

  onPostToggleSelect(post: InsightsPost): void {
    if (this.isPostSelected(post)) {
      this.selectedResults.splice(this.selectedResults.indexOf(post), 1);
    } else {
      this.selectedResults.push(post);
    }
  }

  isPostSelected(post: InsightsPost): boolean {
    return this.selectedResults.indexOf(post) > -1;
  }

  validateEmotions(posts: InsightsPost[]): void {
    const emotionNotRecognizedPosts = posts.filter((p) => {
      return !!(
        p.data.insights &&
        p.data.insights.content.emotion.find((e) => !emotions[e])
      );
    });
    if (emotionNotRecognizedPosts.length) {
      emotionNotRecognizedPosts.forEach((p) => {
        console.error(
          'Emotions not recognized: ',
          p.data.insights && p.data.insights.content.emotion.join(', ')
        );
      });
    }
  }
}
