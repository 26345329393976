import { Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { favicon, MAXIMUM_WORKFLOW_ACCOUNTS, userAgent } from '../../constants';
import { AuthService } from '../auth/auth.service';
import { AccountModel, API, UserModel } from '@ui-resources-angular';
import { WorkflowManagerService } from '../workflow-manager/workflow-manager.service';
import { UserPreferencesService } from '../user-preferences/user-preferences.service';

interface Totals {
  unvalidated_posts?: number;
  unvalidated_replies?: number;
  new_inbox_messages?: number;
}

@Injectable()
export class NotificationManagerService {
  private totals: Totals = {};
  inboxUnreadCountHidden = false;

  get totalUnvalidatedPosts() {
    return this.totals.unvalidated_posts || 0;
  }

  set totalUnvalidatedPosts(value: number) {
    if (value < 0) {
      value = 0;
    }
    this.totals.unvalidated_posts = value;
    this.updateFavicon();
  }

  get totalInboxMessages() {
    return this.totals.new_inbox_messages || 0;
  }

  set totalInboxMessages(value: number) {
    if (value < 0) {
      value = 0;
    }
    this.totals.new_inbox_messages = value;
    this.updateFavicon();
  }

  get totalUnvalidatedReplies() {
    return this.totals.unvalidated_replies || 0;
  }

  set totalUnvalidatedReplies(value: number) {
    if (value < 0) {
      value = 0;
    }
    this.totals.unvalidated_replies = value;
  }

  get totalValidations() {
    return this.totals.unvalidated_replies + this.totals.unvalidated_posts || 0;
  }

  constructor(
    private title: Title,
    private auth: AuthService,
    private accountModel: AccountModel,
    private api: API,
    private injector: Injector,
    private userModel: UserModel,
    private userPreferencesService: UserPreferencesService
  ) {
    this.userModel.events.loggedIn.subscribe(({ token }) => {
      this.userPreferencesService.getPreferences().then((preferences) => {
        this.inboxUnreadCountHidden = JSON.parse(
          preferences.inbox_unread_count_hidden
        );
      });
    });
  }

  updateCurrentWorkflowTotals() {
    const workflowManager = this.injector.get(WorkflowManagerService);

    if (!this.auth.isAuthenticated(true)) {
      return Promise.reject('You are not logged in!');
    }

    return this.accountModel
      .findAccounts(workflowManager.getCurrentId())
      .then((accounts) => accounts.map((account) => +account.id))
      .then((workflowAccountIds) => {
        if (workflowAccountIds.length === 0) {
          return Promise.reject(
            'There are no accounts in the current workflow'
          );
        }

        if (workflowAccountIds.length > MAXIMUM_WORKFLOW_ACCOUNTS) {
          return Promise.reject(
            `There are more than the maximum amount of ${MAXIMUM_WORKFLOW_ACCOUNTS} accounts in the current workflow`
          );
        }

        return this.api.get('user/notifications', {
          params: {
            workflow_id: workflowManager.getCurrentId() || 'all',
            with: ['new_inbox_messages', 'unvalidated_posts'],
            v: 2
          },
          autoError: false,
          logRequest: false
        });
      })
      .then(({ data }) => Object.assign(this.totals, data))
      .then(() => {
        this.updateFavicon();
      });
  }

  private updateFavicon() {
    if (this.inboxUnreadCountHidden) {
      return;
    }

    const count = this.totalUnvalidatedPosts + this.totalInboxMessages;
    if (userAgent.getBrowser().name !== 'IE') {
      favicon.badge(count);
    } else {
      this.title.setTitle(`Orlo (${count})`);
    }
  }
}
