import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { API } from '@ui-resources-angular';
import { Campaign, Account } from '@ui-resources-angular';
import { Transition } from '@uirouter/angular';


import { Colleague } from '../../../../../common/services/api';
import {
  workflowAccounts,
  campaigns,
  colleagues
} from '../../../../common-resolves';
import { CampaignModelService } from '../../../../auth/marketing/index/campaign-model/campaign-model.service';
import { Subscription } from 'rxjs';

export async function objectivesFn(
  api: API,
  accounts: Account[],
  $transition: Transition
) {
  let objectives = [];
  let currentCampaign = null;

  if ($transition.params().query) {
    const urlParams = $transition.params().query.split('"');
    currentCampaign = urlParams.includes('campaign_id')
      ? urlParams[urlParams.indexOf('campaign_id') + 2]
      : null;
  }
  const params = {
    account_ids: accounts.map((account) => account.id),
    campaign_ids: [currentCampaign],
    sort: 'clicks'
  };
  await api.post('outbox/recommendedForPromotion', params).then((res) => {
    if (res.status === 200) {
      objectives = res.data.posts;
    }
  });
  return objectives;
}

@Component({
  selector: 'ssi-advertising-objectives',
  templateUrl: './advertising-objectives.component.html',
  styles: [],
  styleUrls: ['./advertising-objectives.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvertisingObjectivesComponent implements OnDestroy, OnInit {
  static resolve = [
    workflowAccounts,
    campaigns,
    colleagues,
    {
      token: 'objectives',
      resolveFn: objectivesFn,
      deps: [API, 'workflowAccounts', Transition]
    }
  ];

  @Input() objectives: any;
  @Input() workflowAccounts: Account[];
  @Input() campaigns: Campaign[];
  @Input() colleagues: Colleague[];

  $activeCampaignSub: Subscription;
  activeCampaignId;
  filter = 0;
  sort = 'clicks';

  constructor(
    private api: API,
    private campaignModelService: CampaignModelService,
    private $transition: Transition
  ) {}

  ngOnDestroy() {
    if (!!this.$activeCampaignSub) {
      this.$activeCampaignSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.$transition.params().query) {
      const urlParams = this.$transition.params().query.split('"');
      this.activeCampaignId = urlParams.includes('campaign_id')
        ? urlParams[urlParams.indexOf('campaign_id') + 2]
        : null;
    }

    this.$activeCampaignSub = this.campaignModelService.$activeCampaign.subscribe(
      (activeCampaign: Campaign) => {
        this.activeCampaignId = activeCampaign
          ? (activeCampaign as any).id
          : null;
        this.filterByCampaign(this.activeCampaignId);
      }
    );
  }

  async filterByCampaign(activeCampaignId) {
    const params = {
      account_ids: this.workflowAccounts.map((account) => account.id),
      campaign_ids: activeCampaignId && new Array(activeCampaignId),
      sort: 'clicks'
    };
    await this.api
      .post('outbox/recommendedForPromotion', params)
      .then((res) => {
        if (res.status === 200) {
          this.objectives = res.data.posts;
        }
      });
  }

  getFilteredAccounts(accountTypeId) {
    return this.workflowAccounts.filter((account) => {
      if (accountTypeId === 3) {
        return (
          account.account_type_id === '3' || account.account_type_id === '9'
        );
      } else if (accountTypeId === 4) {
        return (
          account.account_type_id === '4' || account.account_type_id === '8'
        );
      } else {
        return account.account_type_id === String(accountTypeId);
      }
    });
  }

  async sortFilterObjectives(sortBy, filterAccount) {
    sortBy ? (this.sort = sortBy) : (sortBy = this.sort);
    if (filterAccount === 0) {
      this.filter = filterAccount;
    }
    filterAccount
      ? (this.filter = filterAccount)
      : (filterAccount = this.filter);

    // LinkedIn doesn't support sort by reach
    if ([0, 4].includes(filterAccount) && sortBy === 'reach') {
      sortBy = 'clicks';
      this.sort = 'clicks';
    }

    const accounts = this.getFilteredAccounts(filterAccount);

    const params = {
      account_ids: Array.from(
        filterAccount === 0 ? this.workflowAccounts : accounts,
        (obj) => obj.id
      ),
      campaign_ids: this.activeCampaignId && new Array(this.activeCampaignId),
      sort: sortBy
    };
    await this.api
      .post('outbox/recommendedForPromotion', params)
      .then((res) => {
        if (res.status === 200) {
          this.objectives = res.data.posts;
        }
      });
  }

  filterAccount(account) {
    this.sortFilterObjectives(null, account);
  }

  deletePost(postID) {
    this.objectives = this.objectives.filter((post) => postID !== post.id);
  }
}
