import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ssi-menu-item',
  templateUrl: './menu-item.component.html',
  styles: [],
  styleUrls: ['./menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuItemComponent implements OnInit {
  @Input() label: string;
  @Input() iconClass: string;
  @Input() iconColor = '#14BAE3';

  constructor() {}

  ngOnInit() {}
}
