import { ViewEncapsulation } from '@angular/core';


import { Component, Input, OnInit } from '@angular/core';
import Highcharts, { Options } from 'highcharts';
import { AccountTypeStats, ReportData } from '../../campaign-analytics.service';
import {
  NetworkConst,
  networksIterable
} from '../../../../../../common/constants';
import {
  AccountTypeId,
  AccountTypeIdNameMap,
  AccountTypeName
} from '../../../../../../common/enums';

@Component({
  selector: 'ssi-posts-breakdown',
  templateUrl: './posts-breakdown.component.html',
  styles: [],
  styleUrls: ['./posts-breakdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostsBreakdownComponent implements OnInit {
  @Input() report: ReportData;

  networks: NetworkConst[] = networksIterable.filter(
    (n) => n.id !== AccountTypeId.InstagramOld
  );

  pieChartConfig: Options;

  constructor() {}

  ngOnInit(): void {
    this.pieChartConfig = this.formatDataForPostsPieChart(
      this.report.postsData
    );
  }

  private formatDataForPostsPieChart(postsData: any): any {
    const formattedData = postsData.account_type_stats
      .filter((n) => n.sum_comments > 0) // excluding networks with no data
      .map((network: AccountTypeStats) => {
        return {
          name: `${network.sum_comments}  |  ${
            network.account_type_id === AccountTypeId.Twitter ? AccountTypeName.X : AccountTypeIdNameMap[network.account_type_id]
          }`,
          y: network.sum_comments
        };
      });

    const colours = ['#425DEC', '#14BAE3', '#D41D68', '#F0B427', '#FB6340'];

    Highcharts.setOptions({
      colors: colours
    });
    return {
      chart: {
        type: 'pie',
        backgroundColor: null,
        marginTop: 0,
        spacingTop: 0
      },
      credits: {
        enabled: false
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginBottom: 20,
        useHTML: true,
        itemStyle: {
          fontSize: '14px',
          fontWeight: '900',
          color: '#101525',
          fontFamily: 'Lato, Arial, sans-serif'
        }
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      accessibility: {
        description: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [
        {
          name: 'Posts',
          colorByPoint: true,
          minPointSize: 10,
          innerSize: '50%',
          zMin: 0,
          data: formattedData
        }
      ],
      loading: false
    };
  }

  hideNetworkWithNoPosts = (network: NetworkConst) => {
    return (
      network &&
      this.report.postsData.perNetwork[network.id] &&
      this.report.postsData.perNetwork[network.id].count_posts > 0
    );
  };

  showOnlySupportingReach = (network: NetworkConst) => {
    return (
      network &&
      this.report.postsData.perNetwork[network.id] &&
      this.report.postsData.perNetwork[network.id].sum_reach > 0
    );
  };
}
